var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cblock el--borders-all el--rounded-bottom",class:{ disabled: _vm.disabled }},[_c('div',{staticClass:"cblock__internal addimagewrap"},[_vm._l((_vm.images),function(image,index){return _c('div',{key:image.name,staticClass:"addimage-imagezone"},[_c('div',{staticClass:"addimage-image",style:({
          backgroundImage: `url(${image.src})`,
        })},[(!_vm.readonly)?_c('Button',{staticClass:"addimages-image_btndelete",attrs:{"text":" ","size":"s-micro","color":"alertlines","icon":"x","is-loading":false,"loading-left":true},on:{"click":function($event){return _vm.deleteImage(image.name)}}}):_vm._e(),(!_vm.readonly)?_c('Button',{staticClass:"addimages-image_btnprimary",attrs:{"text":" ","size":"s-micro","color":"accentlines","icon":"tick","is-loading":false,"loading-left":true},on:{"click":function($event){return _vm.setMain(index)}}}):_vm._e()],1),_c('div',{staticClass:"mainphotolable"},[_vm._v(" "+_vm._s(_vm.$t('ImagesSelect:MainPhotoTitle'))+" ")])])}),(_vm.canAddMore && !_vm.readonly)?_c('div',{staticClass:"addimage-imagezone"},[_c('label',{class:{
          'addimage-button': true,
          'addimage-button--firstimg': _vm.isEmpty,
          'addimage-button--moreimg': !_vm.isEmpty,
        }},[_c('input',{attrs:{"type":"file","accept":"image/png, image/jpeg","multiple":"","disabled":_vm.disabled},on:{"change":_vm.fileSelected}})])]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }