<-- Translated -->
<template>
  <!--ПО ФАКТУ ОДНА, НУЖНО НА ОСНОВЕ ОДНОГО-->
  <!--Культуры - Добавить сорт (модалка)-->
  <div v-if="type === 'add'" class="modal modal--long">
    <div class="modalwrap2-topline">
      <div class="topline-minizone"></div>

      <Breadcrumps :data="[{ title: this.$t('modalGrocerySort:Title1') }]" />

      <div class="topline-minizone">
        <Button
          text=""
          size="s-standart"
          color="accentlightlines"
          icon="x"
          :is-loading="false"
          :loading-left="true"
          @click="$emit('input', false)"
        />
      </div>
    </div>

    <div class="modalwrap2-content">
      <div class="modalwrap3-inputs margintop--standart marginbottom--standart">
        <Input
          type="text"
          :placeholder="$t('modalGrocerySort:SortName.Placeholder')"
          :label="$t('modalGrocerySort:SortName.Label')"
          icon=""
          state=""
          size="max"
          color=""
          v-model="value"
          autofocus="true"
        />
      </div>

      <div class="modalwrap3-buttons">
        <Button
          :text="$t('modalGrocerySort:ButtonCancel')"
          size="wide"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="true"
          @click="$emit('input', false)"
        />
        <Button
          :text="$t('modalGrocerySort:ButtonSave')"
          size="wide"
          color="accent"
          icon="save"
          :is-loading="false"
          :loading-left="true"
          :disabled="isSaveButtonDisabled"
          @click="onSaveClick"
        />
      </div>
    </div>
  </div>

  <!--Культуры - Редактировать сорт (модалка)-->
  <div v-else class="modal modal--long">
    <div class="modalwrap2-topline">
      <div class="topline-minizone"></div>

      <Breadcrumps :data="[{ title: this.$t('modalGrocerySort:Title2') }]" />

      <div class="topline-minizone">
        <Button
          text=""
          size="s-standart"
          color="accentlightlines"
          icon="x"
          :is-loading="false"
          :loading-left="true"
          @click="$emit('input', false)"
        />
      </div>
    </div>

    <div class="modalwrap2-content">
      <div class="modalwrap3-inputs margintop--standart marginbottom--standart">
        <Input
          type="text"
          :placeholder="$t('modalGrocerySort:SortName.Placeholder')"
          :label="$t('modalGrocerySort:SortName.Label')"
          icon=""
          state=""
          size="max"
          color=""
          v-model="value"
          autofocus="true"
        />
      </div>

      <div class="modalwrap3-buttons">
        <Button
          :text="$t('modalGrocerySort:ButtonDelete')"
          size="wide"
          color="alertlines"
          icon="delete"
          :is-loading="false"
          :loading-left="true"
          @click="onDeleteClick"
        />
        <Button
          :text="$t('modalGrocerySort:ButtonCancel')"
          size="wide"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="true"
          @click="$emit('input', false)"
        />
        <Button
          :text="$t('modalGrocerySort:ButtonSave')"
          size="wide"
          color="accent"
          icon="save"
          :is-loading="false"
          :loading-left="true"
          :disabled="isSaveButtonDisabled"
          @click="onSaveClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import Input from '@/components/views/Input';
import Button from '@/components/views/Button';
export default {
  name: 'modalGrocerySort',
  components: { Button, Input, Breadcrumps },
  props: {
    type: {
      default: 'add',
    },
    editSort: {
      default: { name: '' },
    },
  },
  data() {
    return {
      value: this.editSort?.name || '',
    };
  },
  methods: {
    onSaveClick() {
      if (this.type === 'add') {
        this.$emit('addSort', {
          value: this.value,
        });
      } else {
        this.$emit('editSort', {
          name: this.value,
          id: this.editSort.id,
        });
      }

      this.$emit('input', false);
    },
    onDeleteClick() {
      this.$emit('deleteSort', {
        name: this.value,
        id: this.editSort.id,
      });
      this.$emit('input', false);
    },
  },
  computed: {
    isSaveButtonDisabled() {
      if (this.type === 'add') {
        if (String(this.value).trim() === '') {
          return true;
        } else {
          return false;
        }
      } else {
        if (String(this.value).trim() === '') {
          return true;
        } else {
          if (this.value === this.editSort.name) {
            return true;
          } else {
            return false;
          }
        }
      }
    },
  },
};
</script>

<style scoped></style>
