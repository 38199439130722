<template>
  <article class="main">
    <UserSideMenu />
    <router-view></router-view>
  </article>
</template>

<script>
import UserSideMenu from '@/pages/userproject/userComponents/UserSideMenu';

export default {
  name: 'UserMain',
  components: {
    UserSideMenu,
  },
  computed: {},
};
</script>

<style lang="scss" scoped></style>
