<-- Translated -->
<template>
  <div class="filter filter--oneline">
    <div class="filter-inputszone">
      <div class="filterwrap filterwrap--glossary-phases">
        <div class="filterwrap_line">
          <Input
            type="text"
            :placeholder="$t('FilterGlossaryPhases:Phase.Placeholder')"
            :label="$t('FilterGlossaryPhases:Phase.Label')"
            icon=""
            state=""
            size="long"
            color=""
            v-model="filter.name"
            @input="$emit('input', filter)"
          />
          <DropdownPlusSelectValue
            :placeholder="$t('FilterGlossaryPhases:Culture.Placeholder')"
            :label="$t('FilterGlossaryPhases:Culture.Label')"
            size="standart"
            color=""
            @input="$emit('input', filter)"
            dd-max-elements="7"
            :options="ddCultures"
            v-model="filter.culture"
          />
        </div>
      </div>
    </div>
    <div class="filter-clearbtnzone">
      <Button
        class="filter_buttonclear"
        text="очистить"
        size="s-standart"
        :color="isFilterActive ? 'accentlines' : 'graylines'"
        icon="backspace"
        :is-loading="false"
        :loading-left="true"
        @click="
          filter.name = '';
          filter.culture = null;
          $emit('input', filter);
        "
      />
    </div>
  </div>
</template>

<script>
import Input from '@/components/views/Input';
import Button from '@/components/views/Button';
import textHelper from '@/helpers/textHelper';
import DropdownPlusSelectValue from '@/components/views/wrappers/DropdownPlusSelectValue';
export default {
  name: 'FilterGlossaryPhases',
  components: {
    DropdownPlusSelectValue,
    Button,
    Input,
  },
  props: {
    value: {},
    cultures: [],
  },
  data() {
    return {
      filter: {
        name: '',
        culture: null,
        ...this.value,
      },
    };
  },
  computed: {
    isFilterActive() {
      let filterIsActive = false;
      for (let filterKey in this.filter) {
        if (this.filter[filterKey]) {
          filterIsActive = true;
          break;
        }
      }
      return filterIsActive;
    },
    ddCultures() {
      let res = []; //[{ text: 'Не выбрано', value: null }];
      if (this.cultures) {
        res = [
          //res[0],
          ...[...this.cultures]
            //.sort(textHelper.dynamicSort('name'))
            .map((val) => {
              return { text: val.name, value: val.id };
            }),
        ];
      }
      return res;
    },
  },
};
</script>

<style lang="scss" scoped></style>
