const analysisData = [
  {
    elementId: 1,
    demand: [500, 1000, 1125, 1250, 1500],
  },
  {
    elementId: 2,
    demand: [10, 20, 25, 30, 40],
  },
  {
    elementId: 3,
    demand: [97.5, 195, 225, 250, 310],
  },
  {
    elementId: 4,
    demand: [800, 1600, 1900, 2200, 2800],
  },
  {
    elementId: 5,
    demand: [90, 180, 210, 240, 300],
  },
  {
    elementId: 6,
    demand: [5, 10, 11, 13, 15],
  },
  {
    elementId: 7,
    demand: [0.3, 0.6, 0.7, 0.8, 1],
  },
  {
    elementId: 8,
    demand: [0.5, 1, 1.3, 1.5, 2],
  },
  {
    elementId: 9,
    demand: [2, 4, 5, 7, 10],
  },
  {
    elementId: 10,
    demand: [0.05, 0.1, 0.2, 0.3, 0.4],
  },
  {
    elementId: 11,
    demand: [0.5, 1, 2, 3, 5],
  },
  {
    elementId: 12,
    demand: [0.2, 0.4, 0.5, 0.7, 1],
  },
];

export const getOxydeName = (name) =>
  name
    .replace('K', 'K₂O')
    .replace('P', 'P₂O₅')
    .replace('S', 'SO₃')
    .replace(/Ca|Mg/, '$&O');

export const calcDemandWithAnalysis = (elementId, value, originalDemand) => {
  if (elementId > 12) return null;
  const { demand } = analysisData.find((e) => e.elementId === elementId);
  if (value <= demand[0]) {
    return originalDemand * 1.1;
  } else if (value < demand[1]) {
    return originalDemand;
  } else if (value < demand[2]) {
    return originalDemand * 0.7;
  } else if (value < demand[3]) {
    return originalDemand * 0.5;
  } else if (value < demand[4]) {
    return originalDemand * 0.3;
  } else {
    return 0;
  }
};
