import axios from '@/api/axios';
import { clientEntitiesKeys } from '@/store/modules/clients';

const getNewClientData = (geoBase = 0) =>
  axios
    .get('/clients/getNewClientData', { params: { geoBase } })
    .then((r) => r.data);

const getEditClientData = (
  id,
  geoBase = 0,
  byCode = false,
  forceUpdate = false
) =>
  axios
    .get('/clients/getEditClientData', {
      params: {
        [byCode ? 'code' : 'id']: id,
        geoBase,
        forceUpdate: forceUpdate ? true : undefined,
      },
    })
    .then(({ data }) => {
      if (data.clientZonesAnalyzes)
        data.clientZonesAnalyzes.sort((a, b) => a.date - b.date);
      clientEntitiesKeys.forEach((key) => (data[key] ??= []));
      data.clientZonesAnalyzes.forEach((a) => {
        if (a.date === 0) a.date = null;
      });
      data.clientAnalyzesElements.forEach((e) => {
        if (e.quantity === 0) e.quantity = null;
      });
      return data;
    });

const submitClientData = (payload) => {
  const formData = new FormData();

  payload.clientAnalyzesFiles
    .filter((f) => f.file)
    .forEach((fileEntry) => {
      formData.append('files', fileEntry.file);
      delete fileEntry.file;
      fileEntry.order = [...formData.keys()].length - 1;
    });

  payload.clientAnalyzesElements = payload.clientAnalyzesElements
    .filter(({ quantity }) => quantity !== '')
    .map((e) => ({ ...e, quantity: Number(e.quantity) }));

  formData.append('clientData', JSON.stringify(payload));
  return axios
    .post('/clients/updateClient', formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((r) => r.data);
};

const getClients = (
  timestamp = 0,
  timestampClients = 0,
  geoBaseTimestamp = 0
) =>
  axios
    .get('/clients/getClients', {
      params: {
        clients: timestampClients,
        geoBase: geoBaseTimestamp,
        cultures: timestamp,
        clientsCultures: timestamp,
      },
    })
    .then((r) => r.data);

const getClientsDuplicates = (payload) => {
  return axios.get('/clients/getClientsDuplicates', {
    params: {},
  });
};

export default {
  getNewClientData,
  submitClientData,
  getEditClientData,
  getClients,
  getClientsDuplicates,
};
