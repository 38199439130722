import * as analyseApi from '@/api/clientsArea/analyzes';
import { mutationTypes as geoBaseMutationTypes } from '@/store/modules/glossaryGeobase';
import i18n from '@/i18n';

//Translated

const state = {
  isAnalyzesLoading: false,
  isAnalyzesLoadingHasError: false,

  analyzes: [],
  analyzesDateCache: 0,
  analyzesTypes: [
    {
      id: 0,
      name: i18n.t('Vuex:clientsArea:analyzes:analyzesTypes.Name:0'),
      title: i18n.t('Vuex:clientsArea:analyzes:analyzesTypes.Title:0'),
      nameTranslate: 'Почвы',
      titleTranslate: 'Анализ почвы',
    },
    {
      id: 1,
      name: i18n.t('Vuex:clientsArea:analyzes:analyzesTypes.Name:1'),
      title: i18n.t('Vuex:clientsArea:analyzes:analyzesTypes.Title:1'),
      nameTranslate: 'Воды',
      titleTranslate: 'Анализ воды',
    },
    {
      id: 2,
      name: i18n.t('Vuex:clientsArea:analyzes:analyzesTypes.Name:2'),
      title: i18n.t('Vuex:clientsArea:analyzes:analyzesTypes.Title:2'),
      nameTranslate: 'Листа',
      titleTranslate: 'Анализ листа',
    },
    {
      id: 3,
      name: i18n.t('Vuex:clientsArea:analyzes:analyzesTypes.Name:3'),
      title: i18n.t('Vuex:clientsArea:analyzes:analyzesTypes.Title:3'),
      nameTranslate: 'Продукта',
      titleTranslate: 'Анализ продукта',
    },
  ],
  analyzesTypesMap: new Map(),
};

const getters = {
  analyzesClientTypes(state) {
    return state.analyzesTypes.map((v) => {
      return { text: v.name, value: v.id };
    });
  },
  analyzesClientTypesMapById(state) {
    return new Map(state.analyzesTypes.map((e) => [`${e.id}`, e]));
  },
};

export const mutationTypes = {
  getAnalyzesStart: '[client analyzes] getAnalyzes start',
  getAnalyzesSuccess: '[client analyzes] getAnalyzes success',
  getAnalyzesFailure: '[client analyzes] getAnalyzes failure',
};

export const actionTypes = {
  getAnalyzes: '[client analyzes] getAnalyzes',
};

const mutations = {
  [mutationTypes.getAnalyzesStart](state) {
    state.isAnalyzesLoading = true;
    state.isAnalyzesLoadingHasError = false;
    //state.products = null;
  },
  [mutationTypes.getAnalyzesSuccess](state, payload) {
    state.isAnalyzesLoadingHasError = false;
    state.isAnalyzesLoading = false;
    if (payload) {
      processAnalyzes(state, payload);
      //state.productsDateCache = new Date().getTime();
    }
  },
  [mutationTypes.getAnalyzesFailure](state) {
    state.isAnalyzesLoading = false;
    state.isAnalyzesLoadingHasError = true;
  },
  //--------------------------------------------------
};

function processAnalyzes(state, payload) {
  if (state.analyzesTypesMap.size === 0) {
    state.analyzesTypes.forEach((v) => {
      state.analyzesTypesMap.set(v.id, v);
    });
  }
  if (payload.analyzes) {
    state.analyzes = Object.freeze(
      payload.analyzes.sort((a, b) => {
        let res = b?.date_create - a?.date_create;
        if (res === 0 || !res) {
          res = b?.id - a?.id;
        }
        return res;
      })
    );
    state.analyzesDateCache = new Date().getTime();
  }
}

const actions = {
  async [actionTypes.getAnalyzes](context, payload) {
    context.commit(mutationTypes.getAnalyzesStart, payload);
    try {
      let response = await analyseApi.getAnalyzes({
        analyzes: context.state.analyzesDateCache,
        geoBase: context.rootState.glossaryGeobase.timestamp,
      });
      if (response.status !== 304) {
        context.commit(mutationTypes.getAnalyzesSuccess, response.data);
        if (response.data.geoBase) {
          context.commit(
            geoBaseMutationTypes.getGeobaseSuccess,
            response.data.geoBase,
            { root: true }
          );
        }
      }
    } catch (error) {
      console.log(error);
      context.commit(mutationTypes.getAnalyzesFailure);
    }
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};
