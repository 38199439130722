<template>
  <article
    class="chkset"
    :class="{
      'inptwrap--locked': disabled,
      'inptwrap--complete': isActive,
      'inptwrap--onlyshow': locked,
    }"
    :style="{ pointerEvents: locked ? 'none' : '' }"
  >
    <label class="lable">{{ title }}</label>

    <div class="blocklinewrap sets-wrap">
      <div class="sets-column">
        <CheckBox
          v-for="item in options1"
          :key="item.id"
          :text="item.name"
          @input="onInput(item, $event)"
          :disabled="disabled"
          color-checked="accentfill"
          color-un-checked="gray"
          :value="itemsIdSet.has(item.id)"
        />
      </div>
      <div class="sets-column">
        <CheckBox
          v-for="item in options2"
          :key="item.id"
          :text="item.name"
          @input="onInput(item, $event)"
          :disabled="disabled"
          color-checked="accentfill"
          color-un-checked="gray"
          :value="itemsIdSet.has(item.id)"
        />
      </div>
    </div>
  </article>
</template>

<script>
import CheckBox from '@/components/CheckBox';

export default {
  name: 'CheckSet',
  components: { CheckBox },
  props: {
    title: { default: '' },
    disabled: { default: false },
    locked: { type: Boolean, default: false },
    options: [],
    value: [],
    keyName: { default: 'id' },
  },
  computed: {
    options1() {
      return this.options.slice(
        0,
        this.options.length % 2 === 0
          ? this.options.length / 2
          : this.options.length / 2 + 1
      );
    },
    options2() {
      return this.options.slice(
        this.options.length % 2 === 0
          ? this.options.length / 2
          : this.options.length / 2 + 1
      );
    },
    itemsIdSet() {
      return new Set(
        this.value?.map((val) => {
          return val[this.keyName];
        })
      );
    },
    isActive() {
      return this.value?.length > 0;
    },
  },
  methods: {
    onInput(item, value) {
      let res = [];
      if (this.value instanceof Array) {
        res = [...this.value];
      }
      if (value) {
        if (
          !res.find((val) => {
            return val[this.keyName] === item?.id;
          })
        ) {
          res.push({ [this.keyName]: item.id });
        }
      } else {
        res = res.filter((val) => {
          return val[this.keyName] !== item?.id;
        });
      }
      this.$emit('input', res);
    },
  },
};
</script>

<style scoped></style>
