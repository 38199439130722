import axios from '@/api/axios';

const getAnalyzes = (payload) => {
  return axios.get('/analyzes/getAnalyzes', {
    params: {
      geoBase: payload?.geoBase ?? undefined,
      clients: payload?.clients ?? undefined,
      analyzes: payload?.analyzes ?? undefined,
    },
  });
};
const getNewAnalyseData = (payload) => {
  return axios.get('/analyzes/getNewAnalyseData', {
    params: {
      geoBase: payload?.geoBase ?? undefined,
      clients: payload?.clients ?? undefined,
    },
  });
};
const getEditAnalyseData = (payload) => {
  return axios.get('/analyzes/getEditAnalyseData', {
    params: {
      id: payload?.id,
      geoBase: payload?.geoBase ?? undefined,
      clients: payload?.clients ?? undefined,
    },
  });
};
const updateAnalyse = (payload) => {
  return axios.post('/analyzes/updateAnalyse', {
    analyseData: payload,
  });
};
const deleteAnalyse = (payload) => {
  return axios.post('/analyzes/updateAnalyse', {
    analyseData: payload,
    delete: true,
  });
};

export default {
  getAnalyzes,
  getNewAnalyseData,
  getEditAnalyseData,
  updateAnalyse,
  deleteAnalyse,
};
