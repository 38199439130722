<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('GlossaryTypeStimulationsEdit:ButtonCancel')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'TypesStimulation' })"
        />
        <Button
          :text="$t('GlossaryTypeStimulationsEdit:ButtonSave')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="isUpdating"
          :loading-left="true"
          :disabled="isSaveButtonDisabled"
          @click="onSave"
        />
      </div>
    </div>

    <div class="cwrap cwrap--level1 marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'edit' }"
          ></div>
          <div class="chead_text">
            {{ $t('GlossaryTypeStimulationsEdit:HeaderText') }}
          </div>
        </div>
      </div>
      <div
        class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                :placeholder="
                  $t('GlossaryTypeStimulationsEdit:Name.Placeholder')
                "
                :label="$t('GlossaryTypeStimulationsEdit:Name.Label')"
                size="long"
                v-model="newType.name"
                :state="inputsState"
                @input="validationState.name = true"
              />
            </div>
          </div>
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                class="typeStimulationTextArea"
                type="textarea2x"
                :placeholder="
                  $t('GlossaryTypeStimulationsEdit:Description.Placeholder')
                "
                :label="$t('GlossaryTypeStimulationsEdit:Description.Label')"
                size="long"
                :state="inputsState"
                v-model="newType.description"
              />
            </div>
          </div>
          <!--<div class="cblock__line">
            <div class="cblock__line-block">
              <RadioToggle
                type="disabled"
                :text1="$t('GlossaryTypeStimulationsEdit:Active:Hide')"
                :text2="$t('GlossaryTypeStimulationsEdit:Active:Active')"
                :label="$t('GlossaryTypeStimulationsEdit:Active:Label')"
                :reverse="true"
                :disabled="isLoading"
                :locked="isLoading ? false : !canEdit"
                v-model="newType.is_active"
              />
            </div>
          </div>-->
        </div>
      </div>
    </div>

    <Button
      v-if="canEdit"
      :text="$t('GlossaryTypeStimulationsEdit:ButtonSave')"
      size="standart"
      color="accent"
      icon="save"
      :is-loading="isUpdating"
      :loading-left="true"
      :disabled="isSaveButtonDisabled"
      @click="onSave"
    />

    <Modal
      v-if="showModalNotSaved"
      @backgroundClose="showModalNotSaved = false"
    >
      <ModalNotSaved
        v-model="showModalNotSaved"
        @confirm="onModalNotSavedConfirm"
      />
    </Modal>
  </article>
</template>

<script>
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import Breadcrumps from '@/components/views/Breadcrumps';
import routeNames from '@/router/routeNames';
import ModalNotSaved from '@/components/modals/modalNotSaved';
import Modal from '@/pages/Modal';
import { mapState } from 'vuex';
import { actionTypes } from '@/store/modules/glossaryTypesStimulation';

export default {
  name: 'GlossaryTypeStimulationsEdit',
  components: {
    Input,
    Button,
    Breadcrumps,
    Modal,
    ModalNotSaved,
  },
  data() {
    return {
      newType: {
        id: null,
        name: '',
        description: '',
        is_active: 1,
      },
      validationState: {
        name: true,
      },
      showModalNotSaved: false,
      nextRouteConfirm: null,
      nextRoute: null,
    };
  },
  methods: {
    onModalNotSavedConfirm() {
      if (this.nextRoute) {
        this.nextRouteConfirm = true;
        this.$router.push(this.nextRoute);
      }
    },
    onSave() {
      let valOk = true;
      if (!this.newType.name) {
        this.validationState.name = false;
        valOk = false;
      }
      if (!valOk) {
        return;
      }
      this.$store.dispatch(actionTypes.updateTypeStimulation, this.newType);
    },
  },
  computed: {
    ...mapState({
      canEdit: (state) => state.auth.currentUserRole?.claim_glossary_edit === 1,
    }),
    inputsState() {
      if (this.isLoading) return 'disabled';
      if (!this.canEdit) return 'disabled2';
      return this.validationState.name ? '' : 'error';
    },
    isSaveButtonDisabled() {
      let haveData = false;
      if (this.isEditType) {
        for (const key in this.newType) {
          if (
            // eslint-disable-next-line
            this.$store.state.glossaryTypesStimulation.editTypeStimulation.hasOwnProperty(
              key
            )
          ) {
            if (
              this.newType[key] !==
              this.$store.state.glossaryTypesStimulation.editTypeStimulation[
                key
              ]
            ) {
              haveData = true;
              break;
            }
          }
        }
      } else {
        if (this.newType.name) {
          haveData = true;
        }
      }

      return this.canEdit ? !haveData : true;
    },
    isLoading() {
      return (
        this.$store.state.glossaryTypesStimulation
          .isUpdateTypeStimulationLoading ||
        this.$store.state.glossaryTypesStimulation.isTypesStimulationLoading
      );
    },
    isUpdating() {
      return this.$store.state.glossaryTypesStimulation
        .isUpdateTypeStimulationLoading;
    },
    isDone() {
      return this.$store.state.glossaryTypesStimulation
        .isUpdateTypeStimulationDone;
    },
    isEditType() {
      //if(this.$store.state.admin.editUser){
      if (
        this.$store.state.glossaryTypesStimulation.editTypeStimulation &&
        this.$route.name === routeNames.editTypeStimulation
      ) {
        return true;
      }
      return false;
    },
    editType() {
      return this.$store.state.glossaryTypesStimulation.editTypeStimulation;
    },
    breadcrumpsArr() {
      if (this.$route.name === routeNames.editTypeStimulation)
        return [
          {
            title: this.$t('GlossaryTypeStimulationsEdit:Title1'),
            route: routeNames.typesStimulation,
          },
          { title: this.$t('GlossaryTypeStimulationsEdit:Title2') },
        ];
      else
        return [
          {
            title: this.$t('GlossaryTypeStimulationsEdit:Title1'),
            route: routeNames.typesStimulation,
          },
          { title: this.$t('GlossaryTypeStimulationsEdit:Title3') },
        ];
    },
  },
  watch: {
    isDone() {
      if (this.isDone) {
        if (
          !this.$store.state.glossaryTypesStimulation
            .isUpdateTypeStimulationHasError
        ) {
          this.nextRouteConfirm = true;
          this.$router.push({ name: routeNames.typesStimulation });
        } else {
          this.$awn.alert(
            this.$store.state.glossaryTypesStimulation
              .updateTypeStimulationErrorText
          );
          if (
            this.$store.state.glossaryTypesStimulation.updateTypeStimulationErrorText.includes(
              'name'
            )
          ) {
            this.validationState.name = true;
          }
        }
      }
    },
    editType() {
      if (this.editType) {
        for (const key in this.newType) {
          if (
            // eslint-disable-next-line
            this.$store.state.glossaryTypesStimulation.editTypeStimulation.hasOwnProperty(
              key
            )
          ) {
            this.newType[key] =
              this.$store.state.glossaryTypesStimulation.editTypeStimulation[
                key
              ];
          }
        }
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.nextRouteConfirm || !this.canEdit) {
      next();
    } else if (this.$route.name === routeNames.editTypeStimulation) {
      if (!this.isSaveButtonDisabled) {
        this.showModalNotSaved = true;
        this.nextRoute = to;
        next(false);
      } else {
        next();
      }
    } else {
      if (this.newType.name || this.newType.description) {
        this.showModalNotSaved = true;
        this.nextRoute = to;
        next(false);
      } else {
        next();
      }
    }
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss">
.typeStimulationTextArea {
  & textarea {
    height: 210px !important;
  }
}
</style>
