<!--Translated-->
<template>
  <table class="table--type2 table--defense">
    <thead>
      <tr>
        <th class="tbl-cellproblem">
          <div class="tbl_info">
            <div class="tbl_info-wrap">
              <div v-svg="{ name: 'badobject' }"></div>
              <div class="tbl_info-celltext">
                {{ $t('TemplatePestsTable:Headers:0') }}
              </div>
            </div>
          </div>
        </th>
        <th class="tbl-cellproduct">
          {{ $t('TemplatePestsTable:Headers:1') }}
        </th>
        <th class="tbl-cellinpt">{{ $t('TemplatePestsTable:Headers:2') }}</th>
        <th class="tbl-cellprice">{{ $t('TemplatePestsTable:Headers:3') }}</th>
      </tr>
    </thead>
    <template v-if="pests.length > 0">
      <TemplateRowPest
        v-for="pest in pests"
        :pest="pest"
        :phase="phase"
        :key="pest.id"
        :readonly="readonly"
      />
    </template>
    <tbody v-else>
      <tr class="row tbl_row--active row--message">
        <td class="tbl-cellproblem" colspan="4">
          <div>{{ $t('TemplatePestsTable:NoPests') }}</div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapState } from 'vuex';
import TemplateRowPest from './TemplateRowPest';

export default {
  name: 'TemplatePestsTable',
  components: { TemplateRowPest },
  props: {
    phase: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      pests(state) {
        return state.plantsCare.phasesPests
          .filter(({ phase_id }) => phase_id === this.phase.id)
          .map(({ pest_id }) =>
            state.plantsCare.pests.find(({ id }) => id === pest_id)
          )
          .sort((a, b) => a.category_id - b.category_id);
      },
    }),
  },
};
</script>
