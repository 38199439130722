import deepFreeze from 'deep-freeze';
import api from '@/api/clients';
import { staticData } from '@/store/modules/clients';
import { mutationTypes as geobaseMutationTypes } from './glossaryGeobase';

const state = {
  isLoading: false,
  error: null,
  clients: [],
  contacts: [],
  cultures: [],
  clientsCultures: [],
  timestamp: 0,
  timestampClients: 0,
};

const getters = {
  contactsAsMap(state) {
    const map = new Map();
    for (const contact of state.contacts) {
      const key = contact.contact_type_id === 2 ? 'emails' : 'phones';
      if (map.has(contact.client_id)) {
        const entry = map.get(contact.client_id);
        entry[key] ??= [];
        entry[key].push(contact.value);
      } else {
        map.set(contact.client_id, { [key]: [contact.value] });
      }
    }
    return map;
  },
  culturesToClients(state) {
    const result = Object.fromEntries(
      state.cultures.map((c) => [c.id, { ...c, clients: new Set() }])
    );
    for (const e of state.clientsCultures) {
      result[e.culture_id]?.clients.add(e.client_id);
    }
    return result;
  },
  clientsList(state, getters) {
    const geoMap = getters.geoPlainDataAsMap;
    const contacts = getters.contactsAsMap;
    return [...state.clients]
      .sort((a, b) => b.attention - a.attention)
      .map((client) => {
        const geo = geoMap.get(client.geo_id);
        const settlement = geo?.name;
        const geoChain = geo?.order?.map((e) => e.id) ?? [];
        const contact = contacts.get(client.id);
        const status = staticData.clientStatus[client.status_id];
        const type = staticData.clientType[client.type_id];
        const nameLowerCase = client.name?.toLowerCase() ?? '';
        const phones = contact?.phones?.join(', ') ?? '';
        let emails = contact?.emails?.join(', ') ?? '';
        if (client?.login?.includes('@') && !emails.includes(client.login)) {
          emails += emails.length ? ', ' : '';
          emails += client.login;
        }
        const emailsLowerCase = emails.toLowerCase();

        return {
          ...client,
          nameLowerCase,
          settlement,
          geoChain,
          status,
          type,
          phones,
          emails,
          emailsLowerCase,
        };
      });
  },
  getClientsMap(state, getters) {
    return new Map(
      getters.clientsList?.map((object) => {
        return [object.id, object];
      })
    );
  },
};

const mutationTypes = {
  setClients: '[clients list] set clients',
  getClientListStart: '[clients list] get clients start',
  getClientListFailure: '[clients list] get clients failure',
  getClientListSuccess: '[clients list] get clients success',
};

const actionTypes = {
  getClients: '[clients list] get clients',
};

const mutations = {
  [mutationTypes.setClients](state, payload) {
    state.clients = deepFreeze(payload);
    state.timestampClients = new Date().getTime();
  },
  [mutationTypes.getClientListStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.getClientListFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },
  [mutationTypes.getClientListSuccess](state, payload) {
    state.isLoading = false;
    state.error = null;

    if (payload.clients) {
      state.clients = deepFreeze(payload.clients);
      state.timestampClients = new Date().getTime();
    }
    if (payload.clientsContacts)
      state.contacts = deepFreeze(payload.clientsContacts);
    if (payload.cultures) state.cultures = deepFreeze(payload.cultures);
    if (payload.clientsCultures)
      state.clientsCultures = deepFreeze(payload.clientsCultures);

    state.timestamp = new Date().getTime();
  },
};

const actions = {
  async [actionTypes.getClients]({ commit, rootState }) {
    commit(mutationTypes.getClientListStart);
    try {
      const res = await api.getClients(
        state.timestamp,
        state.timestampClients,
        rootState.glossaryGeobase.timestamp
      );
      commit(geobaseMutationTypes.getGeobaseSuccess, res.geoBase);
      commit(mutationTypes.getClientListSuccess, res);
    } catch (error) {
      commit(mutationTypes.getClientListFailure, error);
    }
  },
};

export { mutationTypes, actionTypes };

export default {
  state,
  actions,
  mutations,
  getters,
};
