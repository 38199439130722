import axios from '@/api/axios';

const login = (payload) => {
  return axios.post('/auth/login', {
    login: payload.login,
    password: payload.password,
  });
};
const getCurrentUser = () => {
  return axios.post('/auth/getCurrentUser');
};
const setPassword = (payload) => {
  return axios.post('/auth/setPassword', payload);
};
const getCurrentUserFull = (payload) => {
  return axios.post('/auth/getCurrentUserFull', payload);
};

export default {
  login,
  getCurrentUser,
  setPassword,
  getCurrentUserFull,
};
