<!--Translated-->
<template>
  <!--УНИ ВЫ УВЕРЕНЫ - УДАЛИТЬ ПОЛЬЗОВАТЕЛЯ / РОЛЬ И Т.Д.-->
  <div class="modal">
    <div class="modalwrap2-topline">
      <div class="topline-minizone"></div>

      <Breadcrumps :data="[{ title: $t('UserModalChooseAnalyze:Title') }]" />

      <div class="topline-minizone">
        <Button
          text=""
          size="s-standart"
          color="accentlightlines"
          icon="x"
          :is-loading="false"
          :loading-left="true"
          @click="onModalClose"
        />
      </div>
    </div>

    <div class="modalwrap2-content">
      <div class="modalwrap3-inputs margintop--standart marginbottom--standart">
        <div class="marginbottom--micro">
          <Button
            :text="$t('UserAnalyzes:TableData:AnalyseType:1')"
            size="max"
            color="accentlightlines"
            icon="analyzesicon_water"
            @click="
              $router.push({
                name: 'UserAnalysesInstruction',
                params: { id: '1' },
              })
            "
          />
        </div>
        <div class="marginbottom--micro">
          <Button
            :text="$t('UserAnalyzes:TableData:AnalyseType:0')"
            size="max"
            color="accentlightlines"
            icon="analyzesicon_product"
            @click="
              $router.push({
                name: 'UserAnalysesInstruction',
                params: { id: '0' },
              })
            "
          />
        </div>
        <div class="marginbottom--micro">
          <Button
            :text="$t('UserAnalyzes:TableData:AnalyseType:2')"
            size="max"
            color="accentlightlines"
            icon="analyzesicon_leaves"
            @click="
              $router.push({
                name: 'UserAnalysesInstruction',
                params: { id: '2' },
              })
            "
          />
        </div>
        <div class="">
          <Button
            :text="$t('UserAnalyzes:TableData:AnalyseType:3')"
            size="max"
            color="accentlightlines"
            icon="analyzesicon_soil"
            @click="
              $router.push({
                name: 'UserAnalysesInstruction',
                params: { id: '3' },
              })
            "
          />
        </div>
      </div>

      <div class="modalwrap3-buttons">
        <Button
          :text="$t('UserModalChooseAnalyze:ButtonUndo')"
          size="max"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="true"
          @click="onModalClose"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import UserRegister from '@/pages/userproject/userComponents/UserRegister';
import UserLogin from '@/pages/userproject/userComponents/UserLogin';
import Input from '@/components/views/Input';
import Button from '@/components/views/Button';
import Modal from '@/pages/Modal';

export default {
  name: 'UserModalChooseAnalyze',
  components: {
    UserRegister,
    UserLogin,
    Input,
    Button,
    Modal,
    Breadcrumps,
  },
  methods: {
    onModalClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped></style>
