<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('UsersAddEdit:TopButtons:Cancel')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'Users' })"
        />
        <Button
          :text="$t('UsersAddEdit:TopButtons:Save')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="false"
          :loading-left="true"
          @click="onSave"
          :disabled="isSaveButtonDisabled"
          :isLoading="isLoading"
        />
      </div>
    </div>

    <div class="cwrap cwrap--level1 marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'settings' }"
          ></div>
          <div class="chead_text">{{ $t('UsersAddEdit:SectionMain') }}</div>
        </div>
      </div>
      <div
        class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                :placeholder="$t('UsersAddEdit:Login.Placeholder')"
                :label="$t('UsersAddEdit:Login.Label')"
                icon=""
                size=""
                color=""
                name="new-username"
                v-model="newUser.username"
                :state="inputsStateUsername"
                @input="validationState.username = true"
                :only-english-and-number="true"
              />

              <Input
                v-if="isShowPassword"
                type="password"
                :placeholder="$t('UsersAddEdit:Password.Placeholder')"
                :label="$t('UsersAddEdit:Password.Label')"
                icon=""
                size=""
                color=""
                name="new-pass"
                autocomplete="new-password"
                v-model="newUser.password"
                :state="inputsStateUsername"
                @input="validationState.password = true"
              />
              <Button
                v-if="isEditUser && !isClickOnShowPassword"
                :text="$t('UsersAddEdit:ButtonResetPassword')"
                size="wide"
                color="graylines"
                icon="key"
                :is-loading="false"
                :loading-left="false"
                :disabled="isLoading || isEditUserLoading || !canEdit"
                @click="isClickOnShowPassword = true"
              />

              <RadioToggle
                type="blocked"
                :text1="$t('UsersAddEdit:Status.Active')"
                :text2="$t('UsersAddEdit:Status.Blocked')"
                v-model="newUser.is_blocked"
                :active="isEditUser"
                :disabled="isLoading || isEditUserLoading"
                :locked="!canEdit"
              />
              <!--:label="$t('UsersAddEdit:Status.Label')"-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cwrap cwrap--level1 marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'edit' }"
          ></div>
          <div class="chead_text">{{ $t('UsersAddEdit:SectionUserData') }}</div>
        </div>
      </div>
      <div
        class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                :placeholder="$t('UsersAddEdit:Code.Placeholder')"
                :label="$t('UsersAddEdit:Code.Label')"
                size="long"
                v-model="newUser.pn"
                :state="inputsStatePn"
                @input="validationState.pn = true"
                :isLoading="isEditUserLoading"
              />
              <Input
                type="dropdown"
                :placeholder="$t('UsersAddEdit:Role.Placeholder')"
                :label="$t('UsersAddEdit:Role.Label')"
                size="standart"
                :dd-items="roleDDItems"
                @selected="
                  newUser.role_id = $event.item.value;
                  validationState.role_id = true;
                "
                :state="inputsStateRole_id"
                :dd-selected-index="ddRoleSelectedIndex"
                :isLoading="isEditUserLoading"
              />
            </div>
          </div>
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                :placeholder="$t('UsersAddEdit:Name.Placeholder')"
                :label="$t('UsersAddEdit:Name.Label')"
                size="standart"
                v-model="newUser.name"
                :state="inputsStateName"
                @input="validationState.name = true"
                :isLoading="isEditUserLoading"
              />
              <Input
                type="text"
                :placeholder="$t('UsersAddEdit:SurName.Placeholder')"
                :label="$t('UsersAddEdit:SurName.Label')"
                size="standart"
                v-model="newUser.full_name"
                :state="inputsStateName"
                @input="validationState.full_name = true"
                :isLoading="isEditUserLoading"
              />
              <Input
                type="dropdown"
                :placeholder="$t('UsersAddEdit:Gender.Placeholder')"
                :label="$t('UsersAddEdit:Gender.Label')"
                size="standart"
                :dd-items="[
                  { text: $t('UsersAddEdit:Gender.NotSelected'), value: null },
                  { text: $t('UsersAddEdit:Gender.Female'), value: 0 },
                  { text: $t('UsersAddEdit:Gender.Male'), value: 1 },
                ]"
                @selected="onSelectedGender"
                :state="inputsState"
                :dd-selected-index="ddGenderIdSelectedIndex"
                :isLoading="isEditUserLoading"
              />
            </div>
          </div>
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                :placeholder="$t('UsersAddEdit:NameEN.Placeholder')"
                :label="$t('UsersAddEdit:NameEN.Label')"
                size="standart"
                v-model="newUser.name_en"
                :state="inputsState"
                :isLoading="isEditUserLoading"
              />
              <Input
                type="text"
                :placeholder="$t('UsersAddEdit:SurnameEN.Placeholder')"
                :label="$t('UsersAddEdit:SurnameEN.Label')"
                :state="inputsState"
                size="standart"
                v-model="newUser.full_name_en"
                :isLoading="isEditUserLoading"
              />

              <InputDate
                type="dates"
                :placeholder="$t('UsersAddEdit:Date.NotSet')"
                :label-text="$t('UsersAddEdit:Date.Label')"
                :state="inputsState"
                :locked="!canEdit"
                size="standart"
                v-model="newUser.birth_date"
                :isLoading="isEditUserLoading"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cwrap cwrap--level1 marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'contacts' }"
          ></div>
          <div class="chead_text">
            {{ $t('UsersAddEdit:SectionUserContacts') }}
          </div>
        </div>
      </div>

      <div
        v-if="newUser.contacts.length === 0 && canEdit"
        class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
      >
        <div class="cblock__infoblock cblock__infoblock--nocontentyet">
          <p>{{ $t('UsersAddEdit:ContactsNotSetted') }}</p>
          <Button
            :text="$t('UsersAddEdit:ButtonAddContact')"
            size="wider"
            color="accent"
            icon="plus"
            :is-loading="false"
            :loading-left="false"
            :disabled="isLoading"
            @click="showModalAddContact = true"
            :isLoading="isEditUserLoading"
          />
        </div>
      </div>

      <div v-else class="cblock el--rounded-bottom">
        <Table
          :headers="tableHeaders"
          :data-arr="tableContactsArr"
          :table-loading="false"
          :loading-count-items="3"
          @editClick="onEditContact"
          :disabled="isLoading || isEditUserLoading"
        >
          <Button
            v-if="canEdit"
            :text="$t('UsersAddEdit:ButtonAddContact')"
            size="wider"
            color="accentlines"
            icon="plus"
            :is-loading="false"
            :loading-left="false"
            @click="showModalAddContact = true"
            :disabled="isLoading"
          />
        </Table>
      </div>
    </div>

    <!--БЛОК УДАЛИТЬ / ИНФО-->
    <div class="cwrap cwrap--level1 marginbottom--standart" v-if="false">
      <!--ЗАГОЛОВОК-->
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'delete' }"
          ></div>
          <div class="chead_text">{{ $t('UsersAddEdit:DeleteUserTitle') }}</div>
        </div>
      </div>

      <!--ОПИСАНИЕ-->
      <div class="cblock el--borders-all el--rounded-bottom">
        <div class="cblock__infoblock">
          <p>
            {{ $t('UsersAddEdit:DeleteUserMessage1') }}<br />
            {{ $t('UsersAddEdit:DeleteUserMessage2') }}
          </p>

          <Button
            :text="$t('UsersAddEdit:DeleteUserButton')"
            size="standart"
            color="alertlines"
            icon="userdelete"
            :is-loading="false"
            :loading-left="false"
          />
        </div>
      </div>
    </div>
    <Button
      v-if="canEdit"
      :text="$t('UsersAddEdit:TopButtons:Save')"
      size="standart"
      color="accent"
      icon="save"
      :is-loading="false"
      :loading-left="true"
      @click="onSave"
      :disabled="isSaveButtonDisabled"
      :isLoading="isLoading"
    />

    <Modal
      v-if="showModalNotSaved"
      @backgroundClose="showModalNotSaved = false"
    >
      <ModalNotSaved
        v-model="showModalNotSaved"
        @confirm="onModalNotSavedConfirm"
      />
    </Modal>

    <Modal
      v-if="showModalAddContact"
      @backgroundClose="showModalAddContact = false"
    >
      <ModalAddContact
        v-model="showModalAddContact"
        @addContact="onAddContact"
      />
    </Modal>

    <Modal
      v-if="showModalEditContact"
      @backgroundClose="showModalEditContact = false"
    >
      <ModalContactEdit
        v-model="showModalEditContact"
        @onSave="onEditSaveContact"
        @onDelete="onEditDeleteContact"
        :contact="editContact"
      />
    </Modal>
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import RadioToggle from '@/components/views/RadioToggle';
import InputDate from '@/components/views/InputDate';
import Modal from '@/pages/Modal';
import ModalNotSaved from '@/components/modals/modalNotSaved';
import { actionTypes } from '@/store/modules/admin';
import { mapState } from 'vuex';
import routeNames from '@/router/routeNames';
import ModalAddContact from '@/components/modals/administration/ModalContactAdd';
import Table from '@/components/tables/Table';
import textHelper from '@/helpers/textHelper';
import ModalContactEdit from '@/components/modals/administration/ModalContactEdit';

export default {
  name: 'UsersAddEdit',
  components: {
    ModalContactEdit,
    ModalAddContact,
    ModalNotSaved,
    Modal,
    InputDate,
    RadioToggle,
    Input,
    Button,
    Breadcrumps,
    Table,
  },
  data() {
    return {
      newUser: {
        username: '',
        password: '',
        is_blocked: 0,
        pn: '',
        role_id: null,
        name: '',
        name_en: '',
        full_name: '',
        full_name_en: '',
        gender_id: null,
        birth_date: null,
        contacts: [],
      },
      showModalNotSaved: false,
      nextRoute: null,
      nextRouteConfirm: false,
      validationState: {
        username: true,
        password: true,
        pn: true,
        name: true,
        full_name: true,
        role_id: true,
        birth_date: true,
      },
      showModalAddContact: false,
      showModalEditContact: false,
      editContact: null,
      ddRoleSelectedIndex: 0,
      ddGenderIdSelectedIndex: 0,
      isClickOnShowPassword: false,
    };
  },
  methods: {
    onModalNotSavedConfirm() {
      if (this.nextRoute) {
        this.nextRouteConfirm = true;
        this.$router.push(this.nextRoute);
      }
    },
    onSave() {
      let valOk = true;
      if (!this.newUser.username) {
        this.validationState.username = false;
        valOk = false;
      }
      if (!this.newUser.password && !this.isEditUser) {
        this.validationState.password = false;
        valOk = false;
      }
      if (!this.newUser.pn) {
        this.validationState.pn = false;
        valOk = false;
      }
      if (!this.newUser.name) {
        this.validationState.name = false;
        valOk = false;
      }
      if (!this.newUser.full_name) {
        this.validationState.full_name = false;
        valOk = false;
      }
      if (!this.newUser.role_id) {
        this.validationState.role_id = false;
        valOk = false;
      }
      if (!valOk) {
        return;
      }
      if (this.isEditUser) {
        this.$store.dispatch(actionTypes.setUpdateUser, {
          ...this.newUser,
          id: this.$store.state.admin.editUser.id,
        });
      } else {
        this.$store.dispatch(actionTypes.setUpdateUser, this.newUser);
      }
      //console.log(this.newUser)
    },
    onAddContact(contactData) {
      contactData.id = contactData.id || textHelper.generateRandomString(16);
      //console.log(this.newUser.contacts)
      this.newUser.contacts.push(contactData);
      //console.log(this.newUser.contacts)
      this.$nextTick(() => {
        let container = document.querySelector('body');
        //behavior: "smooth"
        container.scrollIntoView({ block: 'end' });
      });
    },
    onEditContact(id) {
      if (this.isLoading) {
        return;
      }
      let contactIndex = -1;
      for (let i = 0; i < this.newUser.contacts.length; i++) {
        if (this.newUser.contacts[i]?.id == id) {
          contactIndex = i;
          break;
        }
      }
      if (contactIndex > -1) {
        this.editContact = this.newUser.contacts[contactIndex];
        this.showModalEditContact = true;
      }
    },
    onEditSaveContact(contactData) {
      let contactIndex = -1;
      for (let i = 0; i < this.newUser.contacts.length; i++) {
        if (this.newUser.contacts[i]?.id == contactData.id) {
          contactIndex = i;
          break;
        }
      }
      if (contactIndex > -1) {
        this.$set(this.newUser.contacts, contactIndex, contactData);
      }
    },
    onEditDeleteContact(contactData) {
      let contactIndex = -1;
      for (let i = 0; i < this.newUser.contacts.length; i++) {
        if (this.newUser.contacts[i]?.id == contactData.id) {
          contactIndex = i;
          break;
        }
      }
      if (contactIndex > -1) {
        this.newUser.contacts.splice(contactIndex, 1);
      }
    },
    onSelectedGender(event) {
      this.newUser.gender_id = event.item.value;
      this.$set(this.newUser, 'gender_id', Number(event.item.value));
      this.ddGenderIdSelectedIndex = event.index;
    },
  },
  computed: {
    ...mapState({
      canEdit: (state) => state.auth.currentUserRole?.claim_users_edit === 1,
    }),
    ...Object.fromEntries(
      ['username', 'password', 'pn', 'name', 'full_name', 'role_id'].map(
        function (field) {
          return [
            `inputsState${field[0].toUpperCase() + field.slice(1)}`,
            function () {
              if (this.isLoading || this.isEditUserLoading) return 'disabled';
              if (!this.canEdit) return 'disabled2';
              return this.validationState[field] ? '' : 'error';
            },
          ];
        }
      )
    ),
    inputsState() {
      return this.isLoading || this.isEditUserLoading
        ? 'disabled'
        : this.canEdit
        ? ''
        : 'disabled2';
    },
    roleDDItems() {
      let arr = [{ text: 'Не выбрано', value: null }];
      if (this.$store.state.admin.roles) {
        this.$store.state.admin.roles.forEach((role, index) => {
          arr.push({ text: role.name, value: role.id });
          if (this.isEditUser) {
            if (this.newUser.role_id === role.id) {
              this.ddRoleSelectedIndex = index + 1;
            }
          }
        });
      }

      return arr;
    },
    isSaveButtonDisabled() {
      let haveData = false;
      if (!this.isEditUser) {
        for (const key in this.newUser) {
          if (this.newUser[key]) {
            if (this.newUser[key] instanceof Array) {
              if (this.newUser[key].length) {
                haveData = true;
              }
            } else {
              haveData = true;
            }
          }
        }
      } else {
        if (this.$store.state.admin.editUser) {
          for (const key in this.newUser) {
            if (key === 'password') {
              if (this.newUser.password) {
                haveData = true;
              } else {
                continue;
              }
            }
            if (key === 'birth_date') {
              if (
                new Date(this.newUser[key]).toLocaleDateString('ru-RU') !==
                new Date(
                  this.$store.state.admin.editUser[key]
                ).toLocaleDateString('ru-RU')
              ) {
                haveData = true;
              } else {
                continue;
              }
            }
            if (key === 'contacts') {
              if (this.$store.state.admin.editUser) {
                if (!this.$store.state.admin.editUser[key]) {
                  if (this.newUser[key].length) {
                    haveData = true;
                  }
                } else {
                  if (this.$store.state.admin.editUser[key]) {
                    if (
                      this.newUser[key].length !==
                      this.$store.state.admin.editUser[key].length
                    ) {
                      haveData = true;
                    } else {
                      for (let i = 0; i < this.newUser[key].length; i++) {
                        if (
                          this.newUser[key][i].id !==
                            this.$store.state.admin.editUser[key][i].id ||
                          this.newUser[key][i].contact_type_id !==
                            this.$store.state.admin.editUser[key][i]
                              .contact_type_id ||
                          this.newUser[key][i].value !==
                            this.$store.state.admin.editUser[key][i].value ||
                          this.newUser[key][i].comment !==
                            this.$store.state.admin.editUser[key][i].comment
                        ) {
                          haveData = true;
                          break;
                        }
                      }
                    }
                  }
                }
              }
            } else {
              if (this.newUser[key] !== this.$store.state.admin.editUser[key]) {
                haveData = true;
              }
            }
          }
        }
      }

      return this.canEdit ? !haveData : true;
    },
    tableHeaders() {
      const headers = [
        {
          text: this.$t('UsersAddEdit:TableHeaders:0'), //'Тип контакта',
          class: 'col-contacttype',
          width: '20%',
          type: 'contactType',
        },
        {
          text: this.$t('UsersAddEdit:TableHeaders:1'), //'Значение',
          class: 'col-contactvalue',
          width: '30%',
          type: 'text',
        },
        {
          text: this.$t('UsersAddEdit:TableHeaders:2'), //'Комментарий',
          class: 'col-contactdescription',
          width: '50%',
          type: 'text',
        },
      ];

      if (this.canEdit) {
        headers.push({
          text: '',
          class: 'col-edit',
          width: '',
          type: 'edit',
          icon: 'edit',
        });
      }

      return headers;
    },
    isLoading() {
      return this.$store.state.admin.isUpdateUserLoading;
    },
    isEditUserLoading() {
      return this.$store.state.admin.isEditUserDataLoading;
    },
    isDone() {
      return this.$store.state.admin?.isUpdateUserDone;
    },
    tableContactsArr() {
      let arr = [];
      this.newUser.contacts.forEach((val) => {
        let tr = [];
        // let contactType = null;
        if (
          this.$store.state.admin.contactTypesMapById.has(val.contact_type_id)
        ) {
          tr.push({
            text:
              this.$store.state.admin.contactTypesMapById.get(
                val.contact_type_id
              ).name ||
              this.$store.state.admin.contactTypesMapById.get(
                val.contact_type_id
              ).name_ru,
            icon: this.$store.state.admin.contactTypesMapById.get(
              val.contact_type_id
            ).icon,
          });
        } else {
          tr.push({ text: val.contact_type_id });
        }
        tr.push({ text: val.value });
        tr.push({ text: val.comment });
        if (this.canEdit) tr.push({ text: val.id });
        arr.push(tr);
      });
      return arr;
    },
    isEditUser() {
      //if(this.$store.state.admin.editUser){
      if (this.$store.state.admin.editUser) {
        return true;
      }
      return false;
    },
    editUser() {
      return this.$store.state.admin.editUser;
    },
    isShowPassword() {
      return !this.isEditUser || this.isClickOnShowPassword;
    },
    breadcrumpsArr() {
      if (this.$route.name === routeNames.editUser)
        return [
          { title: this.$t('UsersAddEdit:TitleUsers'), route: 'Users' },
          { title: this.$t('UsersAddEdit:TitleEdit') },
        ];
      else
        return [
          { title: this.$t('UsersAddEdit:TitleUsers'), route: 'Users' },
          { title: this.$t('UsersAddEdit:TitleNew') },
        ];
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.nextRouteConfirm || !this.canEdit) {
      next();
    } else if (this.$route.name !== routeNames.editUser) {
      if (
        this.newUser.username ||
        this.newUser.password ||
        this.newUser.pn ||
        this.newUser.name ||
        this.newUser.full_name ||
        this.newUser.contacts.length
      ) {
        this.showModalNotSaved = true;
        this.nextRoute = to;
        next(false);
      } else {
        next();
      }
    } else {
      if (this.isSaveButtonDisabled) {
        next();
      } else {
        this.showModalNotSaved = true;
        this.nextRoute = to;
        next(false);
      }
    }
  },
  watch: {
    isDone() {
      if (this.isDone) {
        if (!this.$store.state.admin.isUpdateUserHasError) {
          this.nextRouteConfirm = true;
          this.$router.push({ name: routeNames.users });
        } else {
          this.$awn.alert(this.$store.state.admin.updateUserErrorText);
          if (
            this.$store.state.admin.updateUserErrorText.includes('username')
          ) {
            this.validationState.username = false;
          }
        }
      }
    },
    editUser() {
      if (this.editUser.gender_id !== null) {
        this.ddGenderIdSelectedIndex = this.editUser.gender_id + 1;
      }
      if (this.isEditUser) {
        for (const key in this.newUser) {
          // eslint-disable-next-line
          if (this.$store.state.admin.editUser.hasOwnProperty(key)) {
            if (this.$store.state.admin.editUser[key] instanceof Array) {
              //this.newUser[key].splice(0,this.newUser[key].length);
              this.$set(this.newUser, key, []);
              if (this.$store.state.admin.editUser[key]) {
                for (
                  let i = 0;
                  i < this.$store.state.admin.editUser[key].length;
                  i++
                ) {
                  let o = { ...this.$store.state.admin.editUser[key][i] };
                  this.newUser[key].push(o);
                }
              }
            } else {
              this.newUser[key] = this.$store.state.admin.editUser[key];
            }
          }
        }
      }
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped></style>
