import articlesApi from '@/api/articles';
import i18n from '@/i18n';

const state = {
  isArticlesLoading: false,
  isArticlesLoadingHasError: false,
  isUpdateLoading: false,
  isUpdateLoadingHasError: false,
  isUpdateDone: false,
  isDeleteLoading: false,
  isDeleteDone: false,
  updatingResId: null,
  editArticle: null,
  articles: null,
  articlesDateCache: 0,
  articlesCategories: [
    { id: 0, name: i18n.t('UserHomePage:ArticlesCategories:Defence.Title') },
    { id: 1, name: i18n.t('UserHomePage:ArticlesCategories:Fertilizer.Title') },
    { id: 2, name: i18n.t('UserHomePage:ArticlesCategories:Irrigation.Title') },
    { id: 3, name: i18n.t('UserHomePage:ArticlesCategories:More.Title') },
  ],
  articlesCategoriesMap: new Map(),
  articlesTypes: [
    { id: 0, name: i18n.t('Vuex:articles:ArticlesTypes:0') }, //'Заглушка' },
    { id: 1, name: i18n.t('Vuex:articles:ArticlesTypes:1') }, //'Ссылка' },
    { id: 2, name: i18n.t('Vuex:articles:ArticlesTypes:2') }, //name: 'Текст' },
  ],
};

const getters = {
  articlesCategories(state) {
    return state.articlesCategories.map((v) => {
      return { text: v.name, value: v.id };
    });
  },
  articlesTypes(state) {
    return new Map(
      state.articlesTypes.map((v) => {
        return [v.id, v];
      })
    );
  },
};

export const mutationTypes = {
  getArticlesStart: '[analyzes] getArticles start',
  getArticlesSuccess: '[analyzes] getArticles success',
  getArticlesFailure: '[analyzes] getArticles failure',

  getEditArticleDataStart: '[analyzes] getEditArticleData start',
  getEditArticleDataSuccess: '[analyzes] getEditArticleData success',
  getEditArticleDataFailure: '[analyzes] getEditArticleData failure',

  updateArticleStart: '[analyzes] updateArticle start',
  updateArticleSuccess: '[analyzes] updateArticle success',
  updateArticleFailure: '[analyzes] updateArticle failure',

  deleteArticleStart: '[analyzes] deleteArticle start',
  deleteArticleSuccess: '[analyzes] deleteArticle success',
  deleteArticleFailure: '[analyzes] deleteArticle failure',
};

export const actionTypes = {
  getArticles: '[analyzes] getArticles',
  getEditArticleData: '[analyzes] getEditArticleData',
  updateArticle: '[analyzes] updateArticle',
  deleteArticle: '[analyzes] deleteArticle',
};

const mutations = {
  [mutationTypes.getArticlesStart](state) {
    state.isArticlesLoading = true;
    state.isArticlesLoadingHasError = false;
    state.isDeleteDone = false;
    //state.products = null;
  },
  [mutationTypes.getArticlesSuccess](state, payload) {
    state.isArticlesLoadingHasError = false;
    state.isArticlesLoading = false;
    if (payload) {
      processArticles(state, payload);
      //state.productsDateCache = new Date().getTime();
    }
  },
  [mutationTypes.getArticlesFailure](state) {
    state.isArticlesLoading = false;
    state.isArticlesLoadingHasError = true;
  },
  //--------------------------------------------------
  [mutationTypes.getEditArticleDataStart](state) {
    state.isArticlesLoading = true;
    state.isArticlesLoadingHasError = false;
    state.editArticle = null;
    state.isDeleteDone = false;
    //state.products = null;
  },
  [mutationTypes.getEditArticleDataSuccess](state, payload) {
    state.isArticlesLoadingHasError = false;
    state.isArticlesLoading = false;
    if (payload) {
      if (payload.article) {
        state.editArticle = payload.article;
      }
      //state.products = sortProducts(payload.products);
      //state.productsDateCache = new Date().getTime();
      //processProducts(state);
    }
  },
  [mutationTypes.getEditArticleDataFailure](state) {
    state.isArticlesLoading = false;
    state.isArticlesLoadingHasError = true;
  },
  //--------------------------------------------------
  [mutationTypes.updateArticleStart](state) {
    state.isUpdateLoading = true;
    state.isUpdateLoadingHasError = false;
    state.isUpdateDone = false;
    state.updatingResId = null;
    state.isDeleteDone = false;
    //state.products = null;
  },
  [mutationTypes.updateArticleSuccess](state, payload) {
    state.isUpdateLoadingHasError = false;
    state.isUpdateLoading = false;
    state.isUpdateDone = true;
    if (payload.status && payload.status === 'ok') {
      if (payload.id !== undefined) {
        state.updatingResId = payload.id;
      }
    } else {
      state.isUpdateLoadingHasError = true;
      //state.updateCultureErrorText = payload?.message || 'Error';
    }
  },
  [mutationTypes.updateArticleFailure](state) {
    state.isUpdateLoading = false;
    state.isUpdateDone = true;
    state.isUpdateLoadingHasError = true;
  },
  //--------------------------------------------------
  //--------------------------------------------------
  [mutationTypes.deleteArticleStart](state) {
    state.isUpdateLoading = true;
    state.isDeleteLoading = true;
    state.isUpdateLoadingHasError = false;
    state.isDeleteDone = false;
    state.isUpdateDone = false;
    state.updatingResId = null;
    //state.products = null;
  },
  [mutationTypes.deleteArticleSuccess](state, payload) {
    state.isUpdateLoadingHasError = false;
    state.isUpdateLoading = false;
    state.isDeleteLoading = false;
    state.isDeleteDone = true;
    state.isUpdateDone = true;
    if (payload.status && payload.status === 'ok') {
      if (payload.id !== undefined) {
        state.updatingResId = payload.id;
      }
    } else {
      state.isUpdateLoadingHasError = true;
      //state.updateCultureErrorText = payload?.message || 'Error';
    }
  },
  [mutationTypes.deleteArticleFailure](state) {
    state.isUpdateLoading = false;
    state.isDeleteLoading = false;
    state.isDeleteDone = true;
    state.isUpdateDone = true;
    state.isUpdateLoadingHasError = true;
  },
};

function processArticles(state, payload) {
  if (state.articlesCategoriesMap.size === 0) {
    state.articlesCategories.forEach((v) => {
      state.articlesCategoriesMap.set(v.id, v);
    });
  }
  if (payload.articles) {
    /*state.articles = Object.freeze(
            payload.articles.sort((a, b) => {
                let res = b?.date_create - a?.date_create;
                if (res === 0 || !res) {
                    res = b?.id - a?.id;
                }
                return res;
            })
        );*/
    state.articles = Object.freeze(payload.articles);
    state.articlesDateCache = new Date().getTime();
  }
}

const actions = {
  async [actionTypes.getEditArticleData](context, payload) {
    context.commit(mutationTypes.getEditArticleDataStart, payload);
    try {
      let response = await articlesApi.getEditArticleData({
        id: payload.id,
      });
      if (response.status !== 304) {
        context.commit(mutationTypes.getEditArticleDataSuccess, response.data);
      }
    } catch (error) {
      console.log(error);
      context.commit(mutationTypes.getEditArticleDataFailure);
    }
  },
  async [actionTypes.updateArticle](context, payload) {
    context.commit(mutationTypes.updateArticleStart, payload);
    try {
      let response = await articlesApi.updateArticle({
        article: payload,
      });
      if (response.status !== 304) {
        context.commit(mutationTypes.updateArticleSuccess, response.data);
      }
    } catch (error) {
      console.log(error);
      context.commit(mutationTypes.updateArticleFailure);
    }
  },
  async [actionTypes.deleteArticle](context, payload) {
    context.commit(mutationTypes.deleteArticleStart, payload);
    try {
      let response = await articlesApi.deleteArticle(payload);
      if (response.status !== 304) {
        context.commit(mutationTypes.deleteArticleSuccess, response.data);
      }
    } catch (error) {
      console.log(error);
      context.commit(mutationTypes.deleteArticleFailure);
    }
  },
  async [actionTypes.getArticles](context, payload) {
    context.commit(mutationTypes.getArticlesStart, payload);
    try {
      let response = await articlesApi.getArticles({
        articles: context.state.analyzesDateCache,
      });
      if (response.status !== 304) {
        context.commit(mutationTypes.getArticlesSuccess, response.data);
      }
    } catch (error) {
      console.log(error);
      context.commit(mutationTypes.getArticlesSuccess);
    }
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};
