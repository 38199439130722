<!--Translated-->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('UserSettings:Title') }]" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('UserSettings:ButtonUndo')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'UserHomePage' })"
        />
        <Button
          :text="$t('UserSettings:ButtonSave')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="isSubmitting"
          :loading-left="true"
          :disabled="!canSubmitClientForm"
          @click="saveClient"
        />
      </div>
    </div>

    <div class="cwrap">
      <!--Данные клиента-->
      <div
        class="cblock el--borders-all el--rounded-all marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                :placeholder="$t('UserSettings:Login.Placeholder')"
                :label="$t('UserSettings:Login.Label')"
                icon="contactmail"
                size="long"
                color=""
                name="new-username"
                v-model="client.login"
                :state="
                  isLoading ? 'disabled' : errorFields.login ? 'error' : ''
                "
                @input="errorFields.login = false"
              />

              <Input
                v-if="isSetPassword"
                type="password"
                :placeholder="$t('UserSettings:Password.Placeholder')"
                :label="$t('UserSettings:Password.Label')"
                icon=""
                size=""
                color=""
                name="new-pass"
                autocomplete="new-password"
                v-model="client.password"
                :state="isLoading ? 'disabled' : ''"
              />
              <Button
                v-if="!isSetPassword"
                :text="$t('UserSettings:PasswordResetButton')"
                size="wide"
                color="graylines"
                icon="key"
                @click="isSetPassword = true"
                :disabled="isLoading"
              />
            </div>
          </div>

          <div class="cblock__line">
            <div class="cblock__line-block">
              <!--Название-->
              <Input
                type="text"
                :placeholder="$t('UserSettings:Name.Placeholder')"
                :label="$t('UserSettings:Name.Label')"
                icon=""
                size="long"
                color=""
                v-model="client.name"
                :state="isLoading ? 'disabled' : ''"
              />

              <Input
                type="text"
                :placeholder="$t('UserSettings:Code.Placeholder')"
                :label="$t('UserSettings:Code.Label')"
                icon=""
                size="standart"
                color=""
                v-model="client.code"
                :state="
                  isLoading ? 'disabled' : errorFields.code ? 'error' : ''
                "
                @input="errorFields.code = false"
              />
            </div>
          </div>

          <!--ВЫБОР ГЕО-->
          <div class="cblock__line">
            <div class="cblock__line-block">
              <LocationSelect
                :geoEntries="geoMapped"
                v-model="client.geo_id"
                :freeSelect="true"
                :disabled="isLoading"
                ref="location"
              />
            </div>
          </div>

          <div class="cblock__line">
            <div class="cblock__line-block">
              <!--Адрес-->
              <Input
                class="inputsize--long3x"
                type="text"
                :placeholder="$t('UserSettings:Address.Placeholder')"
                :label="$t('UserSettings:Address.Label')"
                icon=""
                state=""
                size="long4x"
                color=""
                v-model="client.address"
                :state="isLoading ? 'disabled' : ''"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <TableUserContacts
      :contactTypes="contactTypes"
      :isLoading="isLoading"
      v-model="clientContacts"
    />
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import { mapGetters } from 'vuex';
import LocationSelect from '@/components/views/LocationSelect';
import TableUserContacts from '@/components/tables/TableUserContacts';
import { actionTypes } from '@/store/modules/clientsArea/myData';

export default {
  name: 'UserSettings',
  components: {
    TableUserContacts,
    LocationSelect,
    Button,
    Breadcrumps,
    Input,
  },
  data() {
    return {
      client: {
        login: '',
        password: '',
        name: '',
        code: '',
        geo_id: null,
        address: '',
      },
      clientContacts: [],
      isSetPassword: false,
      errorFields: {
        login: false,
        code: false,
      },
    };
  },
  methods: {
    saveClient() {
      this.$store.dispatch(actionTypes.setMyData, {
        myData: this.client,
        clientContacts: this.clientContacts,
      });
    },
  },
  computed: {
    ...mapGetters(['geoMapped']),
    isSubmitting() {
      return this.$store.state.myData.isMyDataUploading;
    },
    isLoading() {
      return this.isSubmitting || this.$store.state.myData.isMyDataLoading;
    },
    canSubmitClientForm() {
      if (this.serverClient) {
        if (
          this.client.name !== this.serverClient?.name ||
          this.client.code !== this.serverClient?.code ||
          this.client.address !== this.serverClient?.address ||
          this.client.login !== this.serverClient?.login ||
          this.client.geo_id !== this.serverClient?.geo_id ||
          this.client.password !== null
        ) {
          return true;
        }
        if (
          (this.clientContacts && !this.serverClientContacts) ||
          this.clientContacts.length !== this.serverClientContacts?.length
        ) {
          return true;
        }
        for (const contact of this.clientContacts) {
          if (
            !this.serverClientContacts.find((v) => {
              let res = true;
              for (const key of Object.keys(v)) {
                if (key !== 'id' && key !== 'client_id') {
                  if (v[key] !== contact[key]) {
                    res = false;
                  }
                }
              }
              return res;
            })
          ) {
            return true;
          }
        }
      }
      return false;
    },
    contactTypes() {
      return this.$store.state.myData.contactTypes ?? [];
    },
    serverClient() {
      return this.$store.state.myData.client;
    },
    serverClientContacts() {
      return this.$store.state.myData.clientContacts;
    },
    isMyDataUploadingDone() {
      return this.$store.state.myData.isMyDataUploadingDone;
    },
    isMyDataUploadingHasError() {
      return this.$store.state.myData.isMyDataUploadingHasError;
    },
  },
  watch: {
    serverClient: {
      handler(val) {
        if (val) {
          this.client.login = val?.login;
          this.client.address = val?.address;
          this.client.code = val?.code;
          this.client.name = val?.name;
          this.client.geo_id = val?.geo_id ?? null;
          this.client.password = null;
        }
      },
      immediate: true,
    },
    serverClientContacts: {
      handler(val) {
        if (val) {
          this.clientContacts?.splice(0, this.clientContacts.length);
          val.forEach((v) => {
            this.clientContacts.push({ ...v });
          });
        } else {
          this.clientContacts?.splice(0, this.clientContacts.length);
        }
      },
      immediate: true,
    },
    isMyDataUploadingDone(val) {
      if (val) {
        //this.$router.go();
        if (!this.isMyDataUploadingHasError) {
          this.isSetPassword = false;
          this.client.password = null;
          this.$store.dispatch(actionTypes.getMyData);
        }
      }
    },
    isMyDataUploadingHasError(val) {
      if (val) {
        this.$awn.alert(this.$store.state.myData.isMyDataUploadingErrorText);
        if (
          this.$store.state.myData.isMyDataUploadingErrorText
            ?.toLowerCase()
            .indexOf('login') >= 0
        ) {
          this.errorFields.login = true;
        }
        if (
          this.$store.state.myData.isMyDataUploadingErrorText
            ?.toLowerCase()
            .indexOf('code') >= 0
        ) {
          this.errorFields.code = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
