<!--Translated-->
<template>
  <Input
    type="dropdownPlus"
    :state="disabled ? 'disabled' : locked || disabled2 ? 'locked' : ''"
    v-bind="$attrs"
    :dd-items="ddItemsFiltered"
    :dd-selected-index="index"
    @selected="selected"
    v-model="filter"
    @ddClose="close"
    :ddMaxElements="ddMaxElements || '7'"
  />
</template>

<script>
import Input from '@/components/views/Input';

export default {
  props: [
    'options',
    'value',
    'disabled',
    'disabled2',
    'locked',
    'ddMaxElements',
  ],
  components: { Input },
  data() {
    return { index: 0, item: null, filter: '', history: [] };
  },
  methods: {
    selected({ index, item }, ignoreEvent = false) {
      this.index = index;
      this.item = item;

      if (
        this.history.length === 0 ||
        this.history.at(-1)?.item?.value !== item?.value
      ) {
        this.history.push({ index: index, item: item });
      }

      if (this.history.length > 2) {
        this.history = [this.history.at(-2), this.history.at(-1)];
      }
      if (index === 0) this.filter = '';

      if (!ignoreEvent) {
        this.$emit('input', index ? item : null);
      }
    },
    itemMatchFilter(item) {
      return (item.searchString ?? item.text)
        .toLowerCase()
        .includes(this.filter.toLowerCase().trim());
    },
    close() {
      if (!this.item) return;
      if (this.history.length > 0) {
        this.filter = this.history.at(-1).item.text;
        if (this.ddItemsFiltered.length > this.history.at(-1).index)
          this.selected(this.history.at(-1));
      }
    },
  },
  computed: {
    ddItems() {
      return [
        { value: null, text: this.$t('DropdownPlusSelect:NullValueText') },
        ...this.options,
      ];
    },
    ddItemsFiltered() {
      return [
        { value: null, text: this.$t('DropdownPlusSelect:NullValueText') },
        ...this.options.filter(this.itemMatchFilter),
      ];
    },
  },
  watch: {
    ddItemsFiltered(newItems) {
      const index = newItems.findIndex(
        ({ value }) => value === this.item?.value
      );
      this.index = index === -1 ? 0 : index;
    },
    filter(value) {
      if (value === '') {
        let item = this.ddItemsFiltered[0];
        this.selected({ index: 0, item });
      }
      this.$emit('filter', value);
    },
    value: {
      handler(item) {
        if (!item) {
          item = this.ddItemsFiltered[0];
          this.filter = '';
        }
        const index = this.ddItemsFiltered.findIndex(
          ({ value }) => value === item?.value
        );
        this.selected({ index, item }, true);
      },
      immediate: true,
    },
  },
};
</script>
