<template>
  <div id="app">
    <transition :name="transitionName" :css="!!this.transitionName">
      <router-view />
    </transition>
    <modal-confirm />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      transitionName: '',
      css: false,
    };
  },
  watch: {
    $route(to, from) {
      this.transitionName = '';
      if (
        from.name === 'Login' &&
        !(
          to.name.toLowerCase().startsWith('user') &&
          to.name.toLowerCase() !== 'users'
        )
      ) {
        this.transitionName = 'slide';
      }
      if (
        to.name === 'Login' &&
        !(
          from.name.toLowerCase().startsWith('user') &&
          from.name.toLowerCase() !== 'users'
        )
      ) {
        this.transitionName = 'slide-back';
      }
      if (
        from.name === 'UserStartscreen' &&
        to.name.toLowerCase().startsWith('user')
      ) {
        this.transitionName = 'slide';
      }
      if (
        to.name === 'UserStartscreen' &&
        from.name.toLowerCase().startsWith('user')
      ) {
        this.transitionName = 'slide-back';
      }
      console.log(this.transitionName, from, to);
    },
  },
};
</script>

<!--<style lang="scss" src="@/scss/normalize.scss"></style>-->
<style lang="scss">
@import '~@/scss/normalize.scss';
@import '~@/scss/vars.scss';
@import '~@/scss/programs';
@import '~@/scss/globals.scss';
@import '~@/scss/modal.scss';
@import '~@/scss/tables.scss';
@import '~@/scss/filtersandcreateinputs.scss';
@import '~@/scss/componentsets.scss';
@import '~@/scss/inpts.scss';
@import '~@/scss/print.scss';
@import '~@/scss/userproject.scss';
@import '~@/scss/editors.scss';

@import '~@/scss/fixes.scss';

@import '~@/scss/restylenotification.scss';

@import '~vue-wysiwyg/dist/vueWysiwyg.css';

@import '~@/scss/articles.scss';

#app {
  width: 100%;
  position: relative;
}

.slide-enter-active {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 100000;
  transition: all 0.75s ease-out;
}
.slide-leave-active {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 1000000;
  transition: all 0.75s ease-out;
}
.slide-enter-to {
  transform: translateY(0);
}
.slide-enter-from {
  transform: translateY(-100%);
}
.slide-leave-to {
  transform: translateY(-100%);
}
.slide-leave-from {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 1000000;
  transform: translateY(0);
}
//---------------------------------
.slide-back-enter-active {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 1000000;
  transition: all 0.5s;
}
.slide-back-leave-active {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 100000;
  transition: all 0.4s;
  overflow: hidden;
}
.slide-back-enter-to {
  transform: translateY(0);
}
.slide-back-enter {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 100000;
  transform: translateY(-100%);
  overflow: hidden;
}
.slide-back-leave-to {
}
.slide-back-leave {
}
//---------------------------------
</style>
