<!--Translated-->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('FertilizerPrograms:Title') }]" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('FertilizerPrograms:ButtonAdd')"
          size="standart"
          color="accent"
          icon="plus"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'NewFertilizerProgram' })"
          :disabled="!canEdit"
        />
      </div>
    </div>

    <div class="rightzone_block">
      <div class="filter">
        <div class="filter-inputszone">
          <div class="filterwrap filterwrap--clients">
            <div class="filterwrap_line">
              <Input
                type="text"
                :placeholder="$t('FertilizerPrograms:Filter:Name.Placeholder')"
                :label="$t('FertilizerPrograms:Filter:Name.Label')"
                icon="search"
                size="long"
                v-model="filters.name"
              />

              <DropdownSelectValue
                :placeholder="
                  $t('FertilizerPrograms:Filter:Creator.Placeholder')
                "
                :label="$t('FertilizerPrograms:Filter:Creator.Label')"
                size="standart"
                dd-max-elements="5"
                :notSelectedText="
                  $t('FertilizerPrograms:Filter:Creator.NotSelectedText')
                "
                :options="[
                  {
                    text: $t('FertilizerPrograms:Filter:Creator.Manager'),
                    value: 0,
                  },
                  {
                    text: $t('FertilizerPrograms:Filter:Creator.Client'),
                    value: 1,
                  },
                ]"
                v-model="filters.creatorType"
              />
            </div>

            <div class="filterwrap_line">
              <Input
                type="text"
                :placeholder="
                  $t('FertilizerPrograms:Filter:ClientName:Placeholder')
                "
                :label="$t('FertilizerPrograms:Filter:ClientName:Label')"
                size="long"
                v-model="filters.clientName"
              />
              <Input
                type="text"
                :placeholder="$t('FertilizerPrograms:Filter:Code:Placeholder')"
                :label="$t('FertilizerPrograms:Filter:Code:Label')"
                size="standart"
                v-model="filters.code"
              />
            </div>

            <div class="filterwrap_line">
              <CultureSelect
                ref="culture"
                :categories="categories"
                :cultures="cultures"
                :category="filters.cultureCategory"
                v-model="filters.culture"
                @categorySelect="filters.cultureCategory = $event"
              />
              <CultureAgeSelect v-model="filters.age" />
              <MonthYearSelect
                :placeholder="$t('FertilizerPrograms:Filter:Date:Placeholder')"
                :label-text="$t('FertilizerPrograms:Filter:Date:Label')"
                :year="filters.start.year"
                :month="filters.start.month"
                @yearSelected="filters.start.year = $event"
                @monthSelected="filters.start.month = $event"
              />
            </div>

            <div class="filterwrap_line">
              <LocationSelect
                ref="location"
                :geoEntries="geoMapped"
                :freeSelect="true"
                :type="2"
                v-model="filters.geo"
              />
            </div>
          </div>
        </div>

        <div class="filter-clearbtnzone">
          <Button
            class="filter_buttonclear"
            text="очистить"
            size="s-standart"
            icon="backspace"
            :is-loading="false"
            :loading-left="true"
            :color="filtersApplied ? 'accentlines' : 'graylines'"
            @click="reset"
          />
        </div>
      </div>
    </div>

    <div class="rightzone_table">
      <Table
        :headers="headers"
        :dataArr="rows"
        @editClick="editProgram"
        @printClick="printProgram"
        :tableLoading="isLoading"
        :loadingCountItems="10"
      >
        <template v-slot:warning-icons>
          <div v-svg="{ name: 'warningdiese' }"></div>
          <div v-svg="{ name: 'user' }"></div>
        </template>
      </Table>
    </div>

    <PrintModal
      v-if="selectedPrintId"
      @close="selectedPrintId = null"
      :selectedPrintId="selectedPrintId"
      :forNutrition="true"
    />
  </article>
</template>

<script>
import isEqual from 'lodash.isequal';
import { mapGetters, mapState } from 'vuex';

import routeNames from '@/router/routeNames';

import Breadcrumps from '@/components/views/Breadcrumps';
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import Table from '@/components/tables/Table';
import CultureSelect from '@/components/views/CultureSelect';
import LocationSelect from '@/components/views/LocationSelect';
import CultureAgeSelect from '@/components/views/CultureAgeSelect';
import MonthYearSelect from '@/components/views/MonthYearSelect';
import PrintModal from '@/components/modals/ModalPrint';

import { mapInputEntityWithMerge } from '@/components/adapters/mapInput';
import cloneDeep from 'lodash.clonedeep';
import { mutationTypes } from '@/store/modules/filtersState';
//При изменении фильтра не забыть изменить базовый в вьюъексе (сохранение состояния)
const defaultFilters = {
  name: '',
  creatorType: null,
  clientName: '',
  code: '',
  start: { year: null, month: null },
  cultureCategory: null,
  culture: null,
  age: null,
  geo: null,
};

export default {
  name: 'DefensePrograms',
  components: {
    Input,
    Button,
    Breadcrumps,
    Table,
    LocationSelect,
    DropdownSelectValue,
    CultureSelect,
    CultureAgeSelect,
    MonthYearSelect,
    PrintModal,
  },
  data() {
    return {
      filters: cloneDeep(defaultFilters),
      selectedPrintId: null,
    };
  },
  computed: {
    ...mapGetters(['geoMapped', 'geoPlainDataAsMap']),
    ...mapState({
      isLoading: (state) => state.nutritionProgramsList.isLoading,
      canEdit: (state) =>
        state.auth.currentUserRole?.claim_plants_nutrition_add === 1,
      programs: (state) =>
        state.nutritionProgramsList.programs.map((program) => {
          let start = null;
          if (program.period_start !== null) {
            const date = new Date(program.period_start);
            start = {
              year: date.getUTCFullYear(),
              month: date.getUTCMonth(),
            };
          }
          return { ...program, start };
        }),
      categories: (state) =>
        state.nutritionProgramsList.categoriesCultures.map(
          mapInputEntityWithMerge
        ),
      cultures: (state) =>
        state.nutritionProgramsList.cultures.map(mapInputEntityWithMerge),
    }),
    headers() {
      return [
        {
          text: '',
          class: 'col-warning',
          icon: 'warningdiese',
          type: 'warning',
        },
        {
          text: this.$t('FertilizerPrograms:TableHeaders:0'), //'Имя клиента',
          type: 'text',
          width: '22%',
        },
        {
          text: this.$t('FertilizerPrograms:TableHeaders:1'), //'Название',
          type: 'text',
          width: '22%',
        },
        {
          text: this.$t('FertilizerPrograms:TableHeaders:2'), //'Культура',
          type: 'text',
          width: '13%',
        },
        {
          text: this.$t('FertilizerPrograms:TableHeaders:3'), //'Возраст',
          type: 'text',
          width: '10%',
        },
        {
          text: this.$t('FertilizerPrograms:TableHeaders:4'), // Создатель
          type: 'text',
          width: '13%',
        },
        {
          text: this.$t('FertilizerPrograms:TableHeaders:5'), //'Площадь',
          type: 'text',
          width: '10%',
        },
        {
          text: this.$t('FertilizerPrograms:TableHeaders:6'), //'Начало',
          type: 'text',
          width: '9%',
        },
        {
          class: 'col-edit unicol-colordark',
          type: 'print',
          icon: 'print',
        },
        {
          class: 'col-edit',
          type: 'edit',
          icon: this.canEdit ? 'edit' : 'passwordshow',
        },
      ];
    },
    filtersApplied() {
      return !isEqual(this.filters, defaultFilters);
    },
    rows() {
      const f = this.filters;

      const mapper = (row) => ({
        ...row,
        culture: this.cultures.find(({ id }) => row.culture_id === id),
        regionName: this.geoPlainDataAsMap.get(row.geo_id)?.name,
        geoChain:
          this.geoPlainDataAsMap.get(row.geo_id)?.order?.map((e) => e.id) ?? [],
      });

      const mapToRow = (input) =>
        [
          input.creator_status,
          input.client_name,
          input.name,
          input.culture?.name ?? '-',
          input.culture_age,
          input.creatorName,
          input.place_area,
          input.start
            ? `${String(input.start.month + 1).padStart(2, '0')}.${String(
                input.start.year
              ).padStart(2, '0')}`
            : null,
          input.id,
          input.id,
        ].map((text, index) => ({
          text: index === 4 && text === 0 ? '< 1' : text,
        }));

      const filter = (row) =>
        row.name.includes(f.name) &&
        (f.creatorType === null
          ? true
          : row.creator_status === f.creatorType) &&
        (row.client_name.toLowerCase().includes(f.clientName.toLowerCase()) ||
          row.creatorName.toLowerCase().includes(f.clientName.toLowerCase())) &&
        (f.code ? String(row.client_code).includes(f.code) : true) &&
        (f.culture === null
          ? f.cultureCategory === null
            ? true
            : this.cultures
                .filter(({ category_id }) => category_id === f.cultureCategory)
                .some((c) => c?.id === row?.culture?.id)
          : f.culture === row.culture_id) &&
        (f.start.month === null && f.start.year === null
          ? true
          : f.start.year === null
          ? f.start.month === row.start?.month
          : f.start.month === null
          ? f.start.year === row.start?.year
          : f.start.year === row.start?.year &&
            f.start.month === row.start?.month) &&
        (f.age === null
          ? true
          : f.age === 11
          ? f.age <= row.culture_age
          : f.age === row.culture_age) &&
        (f.geo === null ? true : row.geoChain.includes(f.geo));

      return this.programs.map(mapper).filter(filter).map(mapToRow);
    },
  },
  methods: {
    reset() {
      this.filters = cloneDeep(defaultFilters);
      // console.log(JSON.stringify(this.filters.start));
      this.$refs.location.reset();
      this.$refs.culture.reset();
    },
    editProgram(id) {
      this.$router.push({
        name: routeNames.editFertilizerProgram,
        params: { id },
      });
    },
    printProgram(id) {
      this.selectedPrintId = id;
    },
  },
  watch: {
    filters: {
      handler(val) {
        this.$store.commit(mutationTypes.setFilterPlantsNutritionPrograms, {
          ...val,
        });
      },
      deep: true,
    },
  },
  created() {
    //console.log(this.filters);
    for (let filterKey in this.filters) {
      this.filters[filterKey] =
        this.$store.state.filtersState.filterPlantsNutritionPrograms[filterKey];
    }
  },
};
</script>
