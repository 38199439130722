<!-- Translated -->
<template>
  <div class="inpt-datewrap inpt-datewrap--noyear">
    <Input
      type="dropdown"
      :placeholder="$t('DayMonthSelect:Day')"
      :label="labelText"
      :state="inputsState"
      size="micro"
      :dd-items="dayItems"
      dd-max-elements="5"
      isForDate="true"
      @selected="input($event.index, month)"
      :dd-selected-index="day || 0"
    />
    <Input
      type="dropdown"
      :placeholder="$t('DayMonthSelect:Month')"
      label=""
      icon=""
      :state="inputsState"
      size="micro"
      color=""
      isForDate="true"
      :dd-items="[
        { text: $t('DayMonthSelect:Month'), value: null },
        { text: $t('DayMonthSelect:Months:0'), value: '0' },
        { text: $t('DayMonthSelect:Months:1'), value: '1' },
        { text: $t('DayMonthSelect:Months:2'), value: '2' },
        { text: $t('DayMonthSelect:Months:3'), value: '3' },
        { text: $t('DayMonthSelect:Months:4'), value: '4' },
        { text: $t('DayMonthSelect:Months:5'), value: '5' },
        { text: $t('DayMonthSelect:Months:6'), value: '6' },
        { text: $t('DayMonthSelect:Months:7'), value: '7' },
        { text: $t('DayMonthSelect:Months:8'), value: '8' },
        { text: $t('DayMonthSelect:Months:9'), value: '9' },
        { text: $t('DayMonthSelect:Months:10'), value: '10' },
        { text: $t('DayMonthSelect:Months:11'), value: '11' },
      ]"
      dd-max-elements="5"
      @selected="input(day, $event.index)"
      :dd-selected-index="month || 0"
    />
  </div>
</template>

<script>
import Input from '@/components/views/Input';

export default {
  name: 'DayMonthSelect',
  props: {
    day: {
      default: null,
    },
    month: {
      default: null,
    },
    disabled: {
      default: false,
    },
    locked: {
      default: false,
    },
    labelText: {
      default: '-',
    },
  },
  components: {
    Input,
  },
  computed: {
    inputsState() {
      return this.disabled ? 'disabled' : this.locked ? 'disabled2' : '';
    },
    dayItems() {
      let arr = [{ text: this.$t('DayMonthSelect:Day'), value: null }];
      for (let i = 0; i < this.maxDate; i++) {
        arr.push({ text: i + 1, value: i + 1 });
      }
      return arr;
    },
    maxDate() {
      const dates = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      return this.month === null ? 31 : dates[this.month - 1];
    },
  },
  watch: {
    maxDate(max) {
      if (this.day > max) this.input(max, this.month);
    },
  },
  methods: {
    input(day, month) {
      this.$emit(
        'input',
        [day, month].map((el) => el || null)
      );
    },
  },
};
</script>
