import analyseApi from '@/api/analyzes';
import { mutationTypes as clientsMutationTypes } from '@/store/modules/clientsList';
import { mutationTypes as geoBaseMutationTypes } from '@/store/modules/glossaryGeobase';
import i18n from '@/i18n';

//Translated

const state = {
  isAnalyzesLoading: false,
  isAnalyzesLoadingHasError: false,
  isUpdateLoading: false,
  isUpdateDone: false,
  isDeleteLoading: false,
  isDeleteDone: false,
  updatingResId: null,
  isUpdateLoadingHasError: false,
  editAnalyse: null,
  analyzes: null,
  analyzesDateCache: 0,
  analyzesTypes: [
    { id: 0, name: i18n.t('Vuex:AnalyzesTypes:0') }, //'Почвы' },
    { id: 1, name: i18n.t('Vuex:AnalyzesTypes:1') }, //'Воды' },
    { id: 2, name: i18n.t('Vuex:AnalyzesTypes:2') }, //'Листа' },
    { id: 3, name: i18n.t('Vuex:AnalyzesTypes:3') }, //'Продукта' },
  ],
  analyzesTypesMap: new Map(),
};

const getters = {
  analyzesTypes(state) {
    return state.analyzesTypes.map((v) => {
      return { text: v.name, value: v.id };
    });
  },
};

export const mutationTypes = {
  getAnalyzesStart: '[analyzes] getAnalyzes start',
  getAnalyzesSuccess: '[analyzes] getAnalyzes success',
  getAnalyzesFailure: '[analyzes] getAnalyzes failure',

  getNewAnalyseDataStart: '[analyzes] getNewAnalyseData start',
  getNewAnalyseDataSuccess: '[analyzes] getNewAnalyseData success',
  getNewAnalyseDataFailure: '[analyzes] getNewAnalyseData failure',

  getEditAnalyseDataStart: '[analyzes] getEditAnalyseData start',
  getEditAnalyseDataSuccess: '[analyzes] getEditAnalyseData success',
  getEditAnalyseDataFailure: '[analyzes] getEditAnalyseData failure',

  updateAnalyseStart: '[analyzes] updateAnalyse start',
  updateAnalyseSuccess: '[analyzes] updateAnalyse success',
  updateAnalyseFailure: '[analyzes] updateAnalyse failure',

  deleteAnalyseStart: '[analyzes] deleteAnalyse start',
  deleteAnalyseSuccess: '[analyzes] deleteAnalyse success',
  deleteAnalyseFailure: '[analyzes] deleteAnalyse failure',
};

export const actionTypes = {
  getAnalyzes: '[analyzes] getAnalyzes',
  getNewAnalyseData: '[analyzes] getNewAnalyseData',
  getEditAnalyseData: '[analyzes] getEditAnalyseData',
  updateAnalyse: '[analyzes] updateAnalyse',
  deleteAnalyse: '[analyzes] deleteAnalyse',
};

const mutations = {
  [mutationTypes.getNewAnalyseDataStart](state) {
    state.isAnalyzesLoading = true;
    state.isDeleteDone = false;
    state.isAnalyzesLoadingHasError = false;
    //state.products = null;
  },
  [mutationTypes.getNewAnalyseDataSuccess](state, payload) {
    state.isAnalyzesLoadingHasError = false;
    state.isAnalyzesLoading = false;
    if (payload) {
      //state.products = sortProducts(payload.products);
      //state.productsDateCache = new Date().getTime();
      //processProducts(state);
    }
  },
  [mutationTypes.getNewAnalyseDataFailure](state) {
    state.isAnalyzesLoading = false;
    state.isAnalyzesLoadingHasError = true;
  },
  //--------------------------------------------------
  [mutationTypes.getAnalyzesStart](state) {
    state.isAnalyzesLoading = true;
    state.isAnalyzesLoadingHasError = false;
    state.isDeleteDone = false;
    //state.products = null;
  },
  [mutationTypes.getAnalyzesSuccess](state, payload) {
    state.isAnalyzesLoadingHasError = false;
    state.isAnalyzesLoading = false;
    if (payload) {
      processAnalyzes(state, payload);
      //state.productsDateCache = new Date().getTime();
    }
  },
  [mutationTypes.getAnalyzesFailure](state) {
    state.isAnalyzesLoading = false;
    state.isAnalyzesLoadingHasError = true;
  },
  //--------------------------------------------------
  [mutationTypes.getEditAnalyseDataStart](state) {
    state.isAnalyzesLoading = true;
    state.isAnalyzesLoadingHasError = false;
    state.editAnalyse = null;
    state.isDeleteDone = false;
    //state.products = null;
  },
  [mutationTypes.getEditAnalyseDataSuccess](state, payload) {
    state.isAnalyzesLoadingHasError = false;
    state.isAnalyzesLoading = false;
    if (payload) {
      if (payload.analyse) {
        state.editAnalyse = payload.analyse;
      }
      //state.products = sortProducts(payload.products);
      //state.productsDateCache = new Date().getTime();
      //processProducts(state);
    }
  },
  [mutationTypes.getEditAnalyseDataFailure](state) {
    state.isAnalyzesLoading = false;
    state.isAnalyzesLoadingHasError = true;
  },
  //--------------------------------------------------
  [mutationTypes.updateAnalyseStart](state) {
    state.isUpdateLoading = true;
    state.isUpdateLoadingHasError = false;
    state.isUpdateDone = false;
    state.updatingResId = null;
    state.isDeleteDone = false;
    //state.products = null;
  },
  [mutationTypes.updateAnalyseSuccess](state, payload) {
    state.isUpdateLoadingHasError = false;
    state.isUpdateLoading = false;
    state.isUpdateDone = true;
    if (payload.status && payload.status === 'ok') {
      if (payload.id !== undefined) {
        state.updatingResId = payload.id;
      }
    } else {
      state.isUpdateLoadingHasError = true;
      //state.updateCultureErrorText = payload?.message || 'Error';
    }
  },
  [mutationTypes.updateAnalyseFailure](state) {
    state.isUpdateLoading = false;
    state.isUpdateDone = true;
    state.isUpdateLoadingHasError = true;
  },
  //--------------------------------------------------
  //--------------------------------------------------
  [mutationTypes.deleteAnalyseStart](state) {
    state.isUpdateLoading = true;
    state.isDeleteLoading = true;
    state.isUpdateLoadingHasError = false;
    state.isDeleteDone = false;
    state.isUpdateDone = false;
    state.updatingResId = null;
    //state.products = null;
  },
  [mutationTypes.deleteAnalyseSuccess](state, payload) {
    state.isUpdateLoadingHasError = false;
    state.isUpdateLoading = false;
    state.isDeleteLoading = false;
    state.isDeleteDone = true;
    state.isUpdateDone = true;
    if (payload.status && payload.status === 'ok') {
      if (payload.id !== undefined) {
        state.updatingResId = payload.id;
      }
    } else {
      state.isUpdateLoadingHasError = true;
      //state.updateCultureErrorText = payload?.message || 'Error';
    }
  },
  [mutationTypes.deleteAnalyseFailure](state) {
    state.isUpdateLoading = false;
    state.isDeleteLoading = false;
    state.isDeleteDone = true;
    state.isUpdateDone = true;
    state.isUpdateLoadingHasError = true;
  },
};

function processAnalyzes(state, payload) {
  if (state.analyzesTypesMap.size === 0) {
    state.analyzesTypes.forEach((v) => {
      state.analyzesTypesMap.set(v.id, v);
    });
  }
  if (payload.analyzes) {
    state.analyzes = Object.freeze(
      payload.analyzes.sort((a, b) => {
        let res = b?.date_create - a?.date_create;
        if (res === 0 || !res) {
          res = b?.id - a?.id;
        }
        return res;
      })
    );
    state.analyzesDateCache = new Date().getTime();
  }
}

const actions = {
  async [actionTypes.getNewAnalyseData](context, payload) {
    context.commit(mutationTypes.getNewAnalyseDataStart, payload);
    try {
      let response = await analyseApi.getNewAnalyseData({
        geoBase: context.rootState.glossaryGeobase.timestamp,
        clients: context.rootState.clientsList.timestampClients,
      });
      if (response.status !== 304) {
        context.commit(mutationTypes.getNewAnalyseDataSuccess, response.data);
        if (response.data.clients) {
          context.commit(
            clientsMutationTypes.setClients,
            response.data.clients,
            { root: true }
          );
        }
      }
    } catch (error) {
      console.log(error);
      context.commit(mutationTypes.getNewAnalyseDataFailure);
    }
  },
  async [actionTypes.getEditAnalyseData](context, payload) {
    context.commit(mutationTypes.getEditAnalyseDataStart, payload);
    try {
      let response = await analyseApi.getEditAnalyseData({
        id: payload.id,
        geoBase: context.rootState.glossaryGeobase.timestamp,
        clients: context.rootState.clientsList.timestampClients,
      });
      if (response.status !== 304) {
        context.commit(mutationTypes.getEditAnalyseDataSuccess, response.data);
        if (response.data.clients) {
          context.commit(
            clientsMutationTypes.setClients,
            response.data.clients,
            { root: true }
          );
        }
      }
    } catch (error) {
      console.log(error);
      context.commit(mutationTypes.getEditAnalyseDataFailure);
    }
  },
  async [actionTypes.updateAnalyse](context, payload) {
    context.commit(mutationTypes.updateAnalyseStart, payload);
    try {
      let response = await analyseApi.updateAnalyse({
        analyse: payload,
      });
      if (response.status !== 304) {
        context.commit(mutationTypes.updateAnalyseSuccess, response.data);
      }
    } catch (error) {
      console.log(error);
      context.commit(mutationTypes.updateAnalyseFailure);
    }
  },
  async [actionTypes.deleteAnalyse](context, payload) {
    context.commit(mutationTypes.deleteAnalyseStart, payload);
    try {
      let response = await analyseApi.deleteAnalyse({
        analyse: payload,
      });
      if (response.status !== 304) {
        context.commit(mutationTypes.deleteAnalyseSuccess, response.data);
      }
    } catch (error) {
      console.log(error);
      context.commit(mutationTypes.deleteAnalyseFailure);
    }
  },
  async [actionTypes.getAnalyzes](context, payload) {
    context.commit(mutationTypes.getAnalyzesStart, payload);
    try {
      let response = await analyseApi.getAnalyzes({
        analyzes: context.state.analyzesDateCache,
        geoBase: context.rootState.glossaryGeobase.timestamp,
        clients: context.rootState.clientsList.timestampClients,
      });
      if (response.status !== 304) {
        context.commit(mutationTypes.getAnalyzesSuccess, response.data);
        if (response.data.clients) {
          context.commit(
            clientsMutationTypes.setClients,
            response.data.clients,
            { root: true }
          );
        }
        if (response.data.geoBase) {
          context.commit(
            geoBaseMutationTypes.getGeobaseSuccess,
            response.data.geoBase,
            { root: true }
          );
        }
      }
    } catch (error) {
      console.log(error);
      context.commit(mutationTypes.getAnalyzesFailure);
    }
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};
