<template>
  <div class="cwrap cwrap--level1 marginbottom--standart">
    <ClientTableHeader
      :title="title"
      iconName="phases_flask"
      :isShown="isShown"
      @click="isShown = !isShown"
    />

    <div class="cblock el--rounded-bottom" v-show="isShown">
      <Table
        class="cblock__internal-table"
        :headers="headers"
        :dataArr="rows"
        :tableLoading="isLoading"
        :loadingCountItems="10"
        @editClick="editAnalysis"
      >
      </Table>
      <Button
        class="margintop--standart"
        :text="$t('ClientAnalysesTable:ButtonNewText')"
        size="wide"
        color="accentlines"
        icon="plus"
        @click="createAnalysis"
        :disabled="!clientId"
      />
    </div>
  </div>
</template>

<script>
import * as dateHelper from '@/helpers/dateHelper';
import * as analyzesHelper from '@/helpers/analyzesHelper';
import { mapState, mapGetters } from 'vuex';

import routeNames from '@/router/routeNames';

import Button from '@/components/views/Button';
import Table from '@/components/tables/Table';
import ClientTableHeader from './ClientTableHeader';

export default {
  name: 'ClientAnalysesTable',
  components: { Button, Table, ClientTableHeader },
  data() {
    return { isShown: false, selectedPrintId: null };
  },
  computed: {
    ...mapGetters(['geoPlainDataAsMap']),
    ...mapState({
      isLoading: (state) => state.clients.isLoading,
      canEdit: (state) => state.auth.currentUserRole?.claim_analyse_edit === 1,
      analyses: (state) => state.clients.clientAnalyzeOrders ?? [],
      types: (state) => state.analyzes.analyzesTypes,
      cultures: (state) => state.clients.cultures,
      clientId: (state) => state.clients.client?.id ?? null,
      clientName: (state) => state.clients.client?.name ?? '',
    }),
    headers() {
      return [
        {
          text: this.$t('AnalyzesList:TableHeaders:0'), //'Дата заявки',
          class: 'tbl-celldate',
          width: '15%',
          type: 'text',
        },
        {
          text: this.$t('AnalyzesList:TableHeaders:1'), //'Тип анализа',
          class: 'tbl-cellanalyzetype',
          width: '35%',
          type: 'text',
        },

        {
          text: this.$t('AnalyzesList:TableHeaders:4'), //'Статус',
          class: 'col-tbl-cellanalyzestatus',
          width: '50%',
          type: 'text',
        },
        {
          class: 'col-edit',
          type: 'edit',
          icon: this.canEdit ? 'edit' : 'passwordshow',
        },
      ];
    },
    rows() {
      return this.analyses.map((a) => {
        const date = dateHelper.formatDate(a.date_create) ?? '';
        const typeText =
          this.types.find(({ id }) => id === a.type_id)?.name ?? '';
        return [
          { text: date },
          {
            text: typeText,
            id: a.type_id,
          },
          {
            text: a.date_create ? analyzesHelper.getAnalyseStatus(a) : '',
            id: analyzesHelper.getAnalyseStatusId(a),
          },
          { text: a.id, id: a.id },
        ];
      });
    },
    title() {
      return `${this.clientName} - ${this.$t('ClientAnalysesTable:Title')} (${
        this.analyses.length
      })`;
    },
  },
  methods: {
    editAnalysis(id) {
      const routeData = this.$router.resolve({
        name: routeNames.editAnalyse,
        params: { id },
      });
      window.open(routeData.href, '_blank');
    },
    createAnalysis() {
      const routeData = this.$router.resolve({
        name: routeNames.newAnalyse,
        query: { forClient: this.clientId },
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
