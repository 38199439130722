<!--Translated-->
<template>
  <article>
    <!--Селектор Гео-->
    <div class="cwrap artclwrap artclwrap--geo el--borders-all el--rounded-all">
      <div class="artclhead marginbottom--micro">
        <div class="artclhead-num marginright--micro">03.</div>
        <div class="artclhead-text">
          მიიტანეთ ნიმუში კარტლისის უახლოეს სერვის ცენტრში
        </div>
      </div>

      <div class="artclblockgeo">
        <DropdownSelectValue
          class="marginleft--standart"
          :label="$t('GuideAnalysisGeo:SelectRegion.Label') + ':'"
          size=""
          :options="regionsArr"
          v-model="selectedRegion"
          :placeholder="$t('GuideAnalysisGeo:SelectRegion.Placeholder')"
        />
        <Button
          class="marginleft--standart"
          text=""
          size="s-standart"
          :color="selectedRegion ? 'accentlines' : 'graylightlines'"
          @click="selectedRegion = null"
          icon="retry"
        />

        <div class="artclblockgeo-decorimg marginright--standart"></div>
      </div>

      <div
        class="marginleft--standart margintop--standart marginright--standart"
      >
        <!--Список Гео / Добавить блок нет результатов!!!!!!-->
        <div
          class="cwrap artclwrap artclwrap--geolist marginleft--standart marginright--standart marginbottom--standart"
        >
          <div class="artclblockgeolist">
            <!--Список Адресов в файле Addresses-->

            <div
              v-for="(sc, index) in filteredServiceCenters"
              :key="index"
              class="artclblockgeolist-block el--borders-all el--rounded-all"
            >
              <div class="artclblockgeolist-block_linewrap">
                <div class="artclblockgeolist-block_line flex-between">
                  <div class="artclblockgeolist-block_city">{{ sc.name }}</div>
                  <!--<div class="artclblockgeolist-block_time">
                    <div
                      class="vsvg vsvg&#45;&#45;standart marginright&#45;&#45;nano"
                      v-svg="{ name: 'time' }"
                    ></div>
                    <div>08:00 - 17:00</div>
                  </div>-->
                </div>
                <div
                  class="artclblockgeolist-block_line artclblockgeolist-block_address"
                >
                  {{ sc.address }}
                </div>
              </div>

              <div class="artclblockgeolist-block_linewrap">
                <div>
                  <div
                    class="artclblockgeolist-block_line artclblockgeolist-block_phone"
                  >
                    <div
                      class="vsvg vsvg--standart marginright--nano"
                      v-svg="{ name: 'contactphone' }"
                    ></div>
                    <a :href="'tel:' + sc.phone.trim()"> {{ sc.phone }} </a>
                  </div>
                </div>
                <div class="artclblockgeolist-block_logo"></div>
              </div>
            </div>
          </div>
        </div>

        <!--Картинка-->
        <div
          class="cwrap artclwrap marginleft--standart marginright--standart marginbottom--standart artclwrap--imgdecor"
        >
          <div class="artclblockmedia">
            <div class="article-media el--rounded-all">
              <img
                src="@/assets/userpanel/img/userarticles/articleanalysis/analyzeguidepicture.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import Input from '@/components/views/Input';
import Button from '@/components/views/Button';
import LocationSelect from '@/components/views/LocationSelect';
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';

export default {
  name: 'GuideAnalysisGeo',
  components: { DropdownSelectValue, LocationSelect, Button, Input },
  data() {
    return {
      serviceCenters: [
        {
          name: 'შპს კარტლის აგროსისტემები',
          address: 'კოსტავას ქ. №75გ',
          phone: '+995 511 13 53 58',
          region: 'თბილისი',
        },
        {
          name: 'თბილისის სერვის ცენტრი',
          address: 'დიდუბის ავტოსადგური (ოკრიბა)',
          phone: '+995 591 34 30 91',
          region: 'თბილისი',
        },
        {
          name: 'ზუგდიდის სერვის ცენტრი',
          address: 'მარჯვენა სანაპიროს ქ.14',
          phone: '+995 591 51 05 66',
          region: 'სამეგრელო-ზემო სვანეთი',
        },
        {
          name: 'ქვიტირის სერვის ცენტრი',
          address: 'სოფ.ქვიტირი',
          phone: '+995 598 80 87 08',
          region: 'იმერეთი',
        },
        {
          name: 'გორის სერვის ცენტრი',
          address: ' სოფ.კარალეთი',
          phone: '+995 595 00 62 55',
          region: 'შიდა ქართლი',
        },
        {
          name: 'ახალქალაქის სერვის ცენტრი',
          address: 'ახალქალაქი',
          phone: '+995 599 40 24 13',
          region: 'სამცხე-ჯავახეთი',
        },
        {
          name: 'საგარეჯოს სერვის ცენტრი',
          address: 'კახეთის გზატკეცილი #14',
          phone: '+995 591 10 00 26',
          region: 'კახეთი',
        },
        {
          name: 'გურჯაანის სერვის ცენტრი',
          address: 'ზარდიაშვილის ქუჩა #42',
          phone: '+995 595 51 76 30',
          region: 'კახეთი',
        },
        {
          name: 'წნორის სერვის ცენტრი',
          address: 'წნორი, დავით აღმაშენებლის 36ა',
          phone: '+995 599 03 77 06',
          region: 'კახეთი',
        },
        {
          name: 'თელავის სერვის ცენტრი',
          address: ' სოფ. ვარდისუბანი',
          phone: '+995 599 03 77 13',
          region: 'კახეთი',
        },
        {
          name: 'ლაგოდეხის სერვის ცენტრი',
          address: 'სოფ. ცოდნისკარი',
          phone: '+995 599 03 77 06',
          region: 'კახეთი',
        },
        {
          name: 'მარნეულის სერვის ცენტრი',
          address: 'სოფ. თამარისის დასაწყისი',
          phone: '+995 599 03 77 10',
          region: 'ქვემო ქართლი',
        },
        {
          name: 'ამბროლაურის სერვის ცენტრი',
          address: 'ვაჟა ფშაველას 6',
          phone: '+995 591 06 68 15',
          region: 'რაჭა-ლეჩხუმი ქვემო სვანეთი',
        },
        {
          name: 'სამტრედიის სერვის ცენტრი',
          address: 'ილია ჭავჭავაძის N46',
          phone: '+995 599 03 77 14',
          region: 'იმერეთი',
        },
        {
          name: 'აბაშის სერვის ცენტრი',
          address: 'თავისუფლების ქ. 64',
          phone: '+995 599 03 77 14',
          region: 'სამეგრელო-ზემო სვანეთი',
        },
      ],
      selectedRegion: null,
    };
  },
  computed: {
    regionsArr() {
      return Array.from(
        new Set(this.serviceCenters.map((val) => val.region))
      ).map((value, index) => {
        return { value: value, text: value };
      });
    },
    filteredServiceCenters() {
      if (this.selectedRegion) {
        return this.serviceCenters.filter(
          (value) => value.region === this.selectedRegion
        );
      } else {
        return this.serviceCenters;
      }
    },
  },
};
</script>

<style scoped></style>
