<!--Translated-->
<template>
  <div>
    <div
      class="rightzone_blockwithhead client_table_contacts"
      v-if="contacts.length === 0"
    >
      <div class="rightzone_blockwithhead-head">
        <div class="vsvg vsvg--standart" v-svg="{ name: 'contacts' }"></div>
        {{ $t('TableUserContacts:HeaderTable') }}
      </div>
      <div class="rightzone_blockwithhead-content">
        <div class="nocontentyet">
          <p>
            {{ $t('TableUserContacts:LoadingText1') + ' ' }}
            {{
              isLoading
                ? $t('TableUserContacts:LoadingText2')
                : $t('TableUserContacts:LoadingText3')
            }}
          </p>
          <Button
            v-if="!readonly"
            :text="$t('TableUserContacts:ButtonAddContact')"
            size="wider"
            color="accent"
            icon="plus"
            :is-loading="false"
            :loading-left="false"
            :disabled="isLoading"
            @click="showModal(null)"
          />
        </div>
      </div>
    </div>

    <div class="rightzone_table" v-else>
      <Table
        :headers="tableHeaders"
        :headerText="$t('TableUserContacts:HeaderTable')"
        :data-arr="tableRows"
        @editClick="showModal"
        :table-loading="isLoading"
        :loading-count-items="contacts?.length ?? 1"
      >
        <Button
          v-if="!readonly"
          :text="$t('TableUserContacts:ButtonAddContact')"
          size="wider"
          color="accentlines"
          icon="plus"
          @click="showModal(null)"
          :disabled="isLoading"
        />
      </Table>
    </div>
    <Modal v-if="modalShown" @backgroundClose="modalShown = false">
      <ModalContacts
        :contact="editingContact"
        :index="editingIndex"
        :contactTypes="contactTypes"
        @close="modalShown = false"
        @change="onContactsChange"
        @delete="onContactDelete"
      />
    </Modal>
  </div>
</template>

<script>
import Button from '@/components/views/Button';
import Table from '@/components/tables/Table';
import Modal from '@/pages/Modal';
import ModalContacts from '@/components/modals/ModalContacts2';

export default {
  name: 'TableUserContacts',
  components: { Button, Table, Modal, ModalContacts },
  model: {
    prop: 'contacts',
    event: 'change',
  },
  props: {
    isLoading: { type: Boolean, default: false },
    contacts: { type: Array, default: () => [] },
    contactTypes: { type: Array, required: true },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      modalShown: false,
      editingIndex: null,
    };
  },
  computed: {
    tableHeaders() {
      const headers = [
        {
          text: this.$t('TableUserContacts:TableHeaders:0'), //'Тип контакта',
          class: 'col-contacttype',
          width: '20%',
          type: 'contactType',
        },
        {
          text: this.$t('TableUserContacts:TableHeaders:1'), //'Значение',
          class: 'col-contactvalue',
          width: '30%',
          type: 'text',
        },
        {
          text: this.$t('TableUserContacts:TableHeaders:2'), //'Комментарий',
          class: 'col-contactdescription',
          width: '50%',
          type: 'text',
        },
      ];

      if (!this.readonly) {
        headers.push({
          text: '',
          class: 'col-edit',
          width: '',
          type: 'edit',
          icon: 'edit',
        });
      }

      return headers;
    },
    tableRows() {
      return this.contacts.map((contact, index) => {
        const contactData = this.contactTypes.find(
          (e) => e.id === contact.contact_type_id
        );
        return [
          {
            icon: contactData.icon,
            text: contactData.name || contactData.name_ru,
          },
          { text: contact.value },
          { text: contact.comment },
          { text: index },
        ];
      });
    },
    editingContact() {
      return this.contacts[this.editingIndex];
    },
  },
  methods: {
    showModal(index) {
      if (this.isLoading) return;
      this.editingIndex = index;
      this.modalShown = true;
    },
    onContactsChange(payload) {
      const contact = {
        contact_type_id: payload.type,
        value: payload.value,
        comment: payload.comment,
      };
      const isNew = !Number.isInteger(payload.id);
      if (isNew) {
        this.$emit('change', [...this.contacts, contact]);
      } else {
        const copy = [...this.contacts];
        copy[payload.id] = contact;
        this.$emit('change', copy);
      }
    },
    onContactDelete(index) {
      this.$emit(
        'change',
        this.contacts.filter((_, i) => i !== index)
      );
    },
  },
};
</script>
