<template>
  <article class="rightzone rightzone--article">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />
    </div>


    <!-- Как выбрать удобрение
    https://drive.google.com/file/d/1FLUhNSauFNadBpUbnv623Y0wg7-XCuwD/view?usp=sharing
    -->
    <div class="cwrap artclwrap  el--borders-all el--rounded-all marginbottom--standart">

      <div class="artclhead">

        <div class="artclhead-text">
          როგორ შევარჩიოთ სასუქი? - Как выбрать удобрение
        </div>
      </div>

      <div class="artclblock">

        <div class="margintop--standart">
          <GuideAnalysisFiles/>
        </div>

      </div>

    </div>


    <!-- Основные элементы питания
    https://drive.google.com/file/d/1wOUtl5PAoDqOrjNkCG8kcge_OfdjMTlb/view?usp=sharing
    -->
    <div class="cwrap artclwrap  el--borders-all el--rounded-all marginbottom--standart">

      <div class="artclhead">

        <div class="artclhead-text">
          ძირითადი საკვები ელემენტები - Основные элементы питания
        </div>
      </div>

      <div class="artclblock">

        <div class="margintop--standart">
          <GuideAnalysisFiles/>
        </div>

      </div>

    </div>


    <!-- Что должно быть сделано При высоких значениях pH и ЕС?
    https://drive.google.com/file/d/1UcHvZx2dh6fhO8JHubCwMx4dLnPhQitd/view?usp=sharing
    -->
    <div class="cwrap artclwrap  el--borders-all el--rounded-all marginbottom--standart">

      <div class="artclhead">

        <div class="artclhead-text">
          რა უნდა გაკეთდეს მაღალი pH-ისა და EC-ს დროს? - Что должно быть сделано При высоких значениях pH и ЕС?
        </div>
      </div>

      <div class="artclblock">

        <div class="margintop--standart">
          <GuideAnalysisFiles/>
        </div>

      </div>

    </div>



    <!-- Где купить?
    https://drive.google.com/file/d/1Yfta_8nq5oVf3Li10R9eLmHFd1U7ajV7/view?usp=sharing
    -->
    <div class="cwrap artclwrap  el--borders-all el--rounded-all marginbottom--standart">

      <div class="artclhead">
        <div class="artclhead-text">
          სად შევიძინო?! - где купить?
        </div>
      </div>

      <div class="artclblock">

        <div class="margintop--standart">
          <GuideAnalysisFiles/>
        </div>

      </div>

    </div>



      <div
          class="cwrap artclwrap artclwrap--buttons el--borders-all el--rounded-all margintop--standart"
      >

        <div class="artclhead">
          <div class="artclhead-text">
            Создайте, или закажите программу удобрений
          </div>
        </div>

        <div class="artclblock">
          <div class="artclblock-text">
            <p>
              Вы можете заказать программу удобрений у нашего менеджера, или создать ее самостоятельно с помощью интерфейса создания программ удобрений
            </p>
          </div>

          <div class="artclblock-buttons">
            <Button
                text="Cоздать программу удобрений"
                size="standart"
                color="accentlines"
                icon="linkto"
                @click="$router.push({ name: 'UserFertilizers' })"/>

          </div>
        </div>
      </div>


    <!--Футтер-->
    <UserFooter />
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import UserFooter from '@/pages/userproject/userComponents/UserFooter';
import LocationSelect from "@/components/views/LocationSelect";
import Button from "@/components/views/Button";
import GuideAnalysisGeo from "@/pages/userproject/userArticles/Components/GuideAnalysisGeo";
import GuideAnalysisTrack from "@/pages/userproject/userArticles/Components/GuideAnalysisTrack";
import GuideAnalysisFiles from "@/pages/userproject/userArticles/Components/ComponentFile";
import routeNames from "@/router/routeNames";

export default {
  name: 'UserGuideFertilizer',
  components: {
    GuideAnalysisFiles,
    GuideAnalysisTrack,
    GuideAnalysisGeo,
    LocationSelect,
    UserFooter,
    Breadcrumps,
    Button
  },
  computed: {
    breadcrumpsArr() {
      return [
        { title: 'Удобрения', route: this.routeBack },
        { title:  'Информация по питанию'},
      ];
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/vars.scss';





</style>
