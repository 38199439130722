<template>
  <article class="rightzone rightzone--article">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />
    </div>

    <!-- П.1 Анализ почвы-->
    <div class="cwrap artclwrap el--borders-all el--rounded-all marginbottom--standart">

      <div class="artclhead">
        <div class="artclhead-text">
          DemoArticle
        </div>
      </div>



      <div class="artclblock">

        <div class="artclblock-textWYSIWYG">
          <p>
            Абзац Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi eum ev
            eniet quis quisquam quos. A ad aliquid assumenda cupiditate dignissimos
            distinctio dolore dolorem doloremque dolores dolorum eligendi eos est
            მაღალი ტუტიანობის მქონე ნიადაგებში შეიძლება განვითარდეს საკვები ელემენტების: თუთიის,
            სპილენძის, ბორის და მანგანუმის დეფიციტი. ძალიან მაღალი ტუტიანობის მქონე ნიადაგებში
            (როდესაც pH მაღალია 9-ზე) ვითარდება ნატრიუმის ტოქსიკურობა, მისი მაღალი შემცველობის გამო.
            მნიშვნელოვანია ნიადაგის pH - ის რეგულარული შემოწმება. საჭიროა დროულად მოხდეს ნიადაგის
            რეაქციის არესთან დაკავშირებული პრობლემის იდენტიფიცირება, რადგან მასთან ბრძოლა
            ძვირადღირებულია და აგრეთვე რთულია გრძელვადიან პერსპექტივაში საკვები ელემენტების
            ნაკლებობის მუდმივი გასწორება. ტექნოლოგიურ სოფლის მეურნეობაში არსებობს ნიადაგის რეაქციის არესთან დაკავშირებული პრობლემების გადაწყვეტის საშუალებები.
            მაღალი ტუტიანობის შემთხვევაში კარტლისი გთავაზობთ პროდუქციას, რომელიც გაგიზრდით ნიადაგის
          </p>
          <p>
            Абзац  მაღალი ტუტიანობის მქონე ნიადაგებში შეიძლება განვითარდეს საკვები ელემენტების: თუთიის,
            სპილენძის, ბორის და მანგანუმის დეფიციტი. ძალიან მაღალი ტუტიანობის მქონე ნიადაგებში
            (როდესაც pH მაღალია 9-ზე) ვითარდება ნატრიუმის ტოქსიკურობა, მისი მაღალი შემცველობის გამო.
            მნიშვნელოვანია ნიადაგის pH - ის რეგულარული შემოწმება. საჭიროა დროულად მოხდეს ნიადაგის
            რეაქციის არესთან დაკავშირებული პრობლემის იდენტიფიცირება, რადგან მასთან ბრძოლა
            ძვირადღირებულია და აგრეთვე რთულია გრძელვადიან პერსპექტივაში საკვები ელემენტების
            ნაკლებობის მუდმივი გასწორება. ტექნოლოგიურ სოფლის მეურნეობაში არსებობს ნიადაგის რეაქციის არესთან დაკავშირებული პრობლემების გადაწყვეტის საშუალებები.
            მაღალი ტუტიანობის შემთხვევაში კარტლისი გთავაზობთ პროდუქციას, რომელიც გაგიზრდით ნიადაგის
            architecto consequatur consequuntur delectus deleniti ducimus ea earum esse harum hic
            id, in incidunt ipsum labore libero magni molestias natus nesciunt perspiciatis recusanda
            e reprehenderit rerum soluta sunt. Animi aspernatur blanditiis consequatur corporis deserun
            t dolore ea inventore rem? Accusamus asperiores aspernatur cupiditate, eaque fuga id ipsum la
            boriosam minus molestiae nisi nostrum odio quas, qui quibusdam quos ratione sapiente sequi sim
          </p>
          <h1>H1 Тут начинается зона редактора, а эти хеады дублируются для понимания лайнхая и отступов</h1>
          <h1>H1 ორება. ტექნოლოგიურ სოფლის მეურნეობაში არსებობს ნიადაგის რეაქციის არესთან და<br> ტექნოლოგიურ სოფლის მეურნეობაში არსებობს ნიადაგის რეაქციის არესთან</h1>
          <h2>H2 Тут начинается зона редактора, а эти хеады дублируются для понимания лайнхая и отступов</h2>
          <h2>H2 Тут начинается зона редактора, а эти хеады дублируются для<br> понимания лайнхая и отступов</h2>
          <h3>H3 Тут начинается зона редактора, а эти хеады дублируются для понимания лайнхая и отступов</h3>
          <h3>H3 Тут начинается зона редактора, а эти хеады дублируются для<br> понимания лайнхая и отступов</h3>
          <h4>H4 - H1 Тут начинается зона редактора, а эти хеады дублируются для понимания лайнхая и отступов</h4>
          <h4>H4 - H1 Тут начинается зона редактора, а эти хеады дублируются<br> для понимания лайнхая и отступов</h4>
          <h5>H5 - H2 Тут начинается зона редактора, а эти хеады дублируются для понимания лайнхая и отступов</h5>
          <h5>H5 - H2 Тут начинается зона редактора, а эти хеады дублируются<br> для понимания лайнхая и отступов</h5>
          <h6>H6 - H3 Тут начинается зона редактора, а эти хеады дублируются для понимания лайнхая и отступов</h6>
          <h6>H6 - H3 Тут начинается зона редактора, а эти хеады дублируются<br> для понимания лайнхая и отступов</h6>

          <p>
            Абзац Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi eum ev
            eniet quis quisquam quos. A ad aliquid assumenda cupiditate dignissimos
            distinctio dolore dolorem doloremque dolores dolorum eligendi eos est
            exercitationem, hic illo illum in inventore laborum molestiae molest
            e reprehenderit rerum soluta sunt. Animi aspernatur blanditiis consequatur corporis deserun
            t dolore ea inventore rem? Accusamus asperiores aspernatur cupiditate, eaque fuga id ipsum la
            boriosam minus molestiae nisi nostrum odio quas, qui quibusdam quos ratione sapiente sequi sim
          </p>
          <p>
            Абзац  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi eum ev
            eniet quis quisquam quos. A ad aliquid assumenda cupiditate dignissimos
            distinctio dolore dolorem doloremque dolores dolorum eligendi eos est
            exercitationem, hic illo illum in inventore laborum molestiae molest
            ias, necessitatibus nobis nostrum odit officia omnis quae quis quisquam rerum
            sunt tempora totam veniam voluptate voluptates! Aliquam, aliquid amet,
            architecto consequatur consequuntur delectus deleniti ducimus ea earum esse harum hic
            id, in incidunt ipsum labore libero magni molestias natus nesciunt perspiciatis recusanda
            e reprehenderit rerum soluta sunt. Animi aspernatur blanditiis consequatur corporis deserun
            t dolore ea inventore rem? Accusamus asperiores aspernatur cupiditate, eaque fuga id ipsum la
            boriosam minus molestiae nisi nostrum odio quas, qui quibusdam quos ratione sapiente sequi sim
          </p>
          <p>
            Абзац Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi eum ev
          </p>
          <h2>Хеад2 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, temporibus.</h2>
          <p>
            Абзац Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi eum ev
          </p>


          <p>
            Абзац Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam, laborum.
            <a href="">Ссылка в тексте.</a>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam, laborum.
            <b> Жир в тексте Lorem ipsum dolor sit amet, consectetur adipisicing elit.</b>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam, laborum.
            <i>Курсив в тексте Lorem ipsum dolor sit amet, consectetur adipisicing elit.</i>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam, laborum.
            <u>Подчеркнутый в тексте Lorem ipsum dolor sit amet, consectetur adipisicing elit.</u>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam, laborum.

          </p>
          <br><br>
          <a href="">
            <b>Жирный в ссылке</b> <i>Курсив в ссылке</i> <u>Подчеркнутый в ссылке</u><br>
          </a>

          <p>
            <b>Жирный. Lorem ipsum.</b><br>
            <i>Курсив. Lorem ipsum.</i><br>
            <u>Подчеркнутый. Lorem ipsum.</u><br>
            <a href="">Ссылка.Lorem ipsum.</a>
          </p>
          <span>
            СПАН Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi eum ev
            eniet quis quisquam quos. A ad aliquid assumenda cupiditate dignissimos
            distinctio dolore dolorem doloremque dolores dolorum eligendi eos est
            exercitationem, hic illo illum in inventore laborum molestiae molest
            e reprehenderit rerum soluta sunt. Animi aspernatur blanditiis consequatur corporis deserun
            t dolore ea inventore rem? Accusamus asperiores aspernatur cupiditate, eaque fuga id ipsum la
            boriosam minus molestiae nisi nostrum odio quas, qui quibusdam quos ratione sapiente sequi sim
          </span><br>
          <span>
            СПАН после БР  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi eum ev
            eniet quis quisquam quos. A ad aliquid assumenda cupiditate dignissimos
            distinctio dolore dolorem doloremque dolores dolorum eligendi eos est
            exercitationem, hic illo illum in inventore laborum molestiae molest
            ias, necessitatibus nobis nostrum odit officia omnis quae quis quisquam rerum
            sunt tempora totam veniam voluptate voluptates! Aliquam, aliquid amet,
            architecto consequatur consequuntur delectus deleniti ducimus ea earum esse harum hic
            id, in incidunt ipsum labore libero magni molestias natus nesciunt perspiciatis recusanda
            e reprehenderit rerum soluta sunt. Animi aspernatur blanditiis consequatur corporis deserun
            t dolore ea inventore rem? Accusamus asperiores aspernatur cupiditate, eaque fuga id ipsum la
            boriosam minus molestiae nisi nostrum odio quas, qui quibusdam quos ratione sapiente sequi sim
          </span>
          <p>
            Абзац Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi eum ev
          </p>

          <ul>
            <li>
              НЕ нум список Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa, perspiciatis.
            </li>
          </ul>
          <ul>
            <li>
              Не нум список Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa, perspiciatis.
            </li>
            <li>
              Lorem ipsum dolo. Ipsa, perspiciatis.
            </li>
            <li>
              Lorem i ტექნოლოგიურ სოფლის მეურნეობაში არსებობს ნიადაგის რეაქციის არესთან
            </li>
            <li>
              ტექნოლოგიურ სოფლის მეურნეობაში არსებობს ნიადაგის რეაქციის არესთან
            </li>
            <li>
              ტექნოლოგიურ სოფლის მეუს რეაქციის არესთან
            </li>
            <li>
              ტექნოლოგიურ სოფლის მეურნეობაში არსებობს ნიადაგის რეაქციის არესთან
            </li>
          </ul>

          <ol>
            <li>
              Нум. Список 1. Lorem ipsum dolor sit.
            </li>
          </ol>
          <ol>
            <li>
              Нум. Список 2.
            </li>
            <li>
              Lorem ipsum dolor sit.
            </li>
            <li>
              Lorem ipsum dolor sit.
            </li>
          </ol>
          <p>
            Абзац Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi eum ev
          </p>
          <img src="~@/assets/userpanel/img/usermain/articlesection1.jpg">
          <p>это "p", если убрать отсутпа не будет вообще, а если без текста - то не очень большой</p>
          <img src="~@/assets/userpanel/img/usermain/articlesection1.jpg">
          <p>
            Абзац Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi eum ev
          </p>



    </div>

        <div class="artclblock-media">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/wHMIgZXDA1o" frameborder="0" allowfullscreen></iframe>
          </div>
        </div>


        <!--Блок Файл ПДФ-->
        <div class="margintop--standart">

          <ComponentFile
              text="Инструкции по отбору проб листьев"
              icon="filepdf"
              link="https://drive.google.com/file/d/1ZeURruADZxq9Wbz6psDN6YaohZ8AGOCk/view?usp=sharing"
          />

        </div>
    </div>
    </div>



    <!--Футтер-->
    <UserFooter />
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import UserFooter from '@/pages/userproject/userComponents/UserFooter';
import LocationSelect from "@/components/views/LocationSelect";
import Button from "@/components/views/Button";
import GuideAnalysisGeo from "@/pages/userproject/userArticles/Components/GuideAnalysisGeo";
import GuideAnalysisTrack from "@/pages/userproject/userArticles/Components/GuideAnalysisTrack";
import ComponentFile from "@/pages/userproject/userArticles/Components/ComponentFile";
import routeNames from "@/router/routeNames";

export default {
  name: 'DemoArticle',
  components: {
    ComponentFile,
    GuideAnalysisTrack,
    GuideAnalysisGeo,
    LocationSelect,
    UserFooter,
    Breadcrumps,
    Button
  },
  computed: {
    breadcrumpsArr() {
      return [
        { title: 'DemoArticle', route: routeNames.userAnalyzes },
        { title:  'DemoArticle - Анализ почвы'},
      ];
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/vars.scss';





</style>
