<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('Roles:Title') }]" />

      <div class="rightzone_top-buttonswrap">
        <Button
          v-if="isShowAddButton"
          :text="$t('Roles:ButtonAdd')"
          size="standart"
          color="accent"
          icon="plus"
          :is-loading="false"
          :loading-left="true"
          @click="$router.push({ name: 'NewRole' })"
        />
      </div>
    </div>

    <div class="rightzone_table">
      <Table
        :headers="tableHeaders"
        :data-arr="dataArr"
        :table-loading="isTableLoading"
        :loading-count-items="5"
        @editClick="onEditClick"
      />
    </div>
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import Table from '@/components/tables/Table';
import routeNames from '@/router/routeNames';
import { mapState } from 'vuex';

export default {
  name: 'AppRoles',
  components: {
    Table,
    Button,
    Breadcrumps,
  },
  methods: {
    onEditClick(roleId) {
      let role = this.$store.state.admin.rolesMapById.get(roleId);
      if (role) {
        this.$router.push({
          name: routeNames.editRole,
          params: { id: role.id },
        });
      }
    },
  },
  computed: {
    ...mapState({
      canEdit: (state) => state.auth.currentUserRole?.claim_users_edit === 1,
    }),
    tableHeaders() {
      return [
        {
          text: this.$t('Roles:TableHeaders:0'),
          class: 'col--accent',
          width: '28%',
          type: 'text',
        },
        {
          text: this.$t('Roles:TableHeaders:1'),
          class: 'col-roledescription',
          width: '52%',
          type: 'text',
        },
        {
          text: this.$t('Roles:TableHeaders:2'),
          class: 'col-roleuserscount',
          width: '20%',
          type: 'text',
        },
        {
          text: '',
          class: 'col-edit',
          width: '',
          type: 'edit',
          icon: this.canEdit ? 'edit' : 'passwordshow',
        },
      ];
    },
    dataArr() {
      let arr = [];

      if (this.$store.state.admin.roles) {
        this.$store.state.admin.roles.forEach((role) => {
          let tr = [];
          tr.push({ text: role.name ? role.name : '' });
          tr.push({ text: role.description ? role.description : '' });
          tr.push({ text: role.count_users ? role.count_users : '0' });
          tr.push({ text: role.id ? role.id : '' });

          arr.push(tr);
        });
      }
      return arr;
    },
    isTableLoading() {
      return this.$store.state.admin.isRolesLoading;
    },
    isShowAddButton() {
      return this.canEdit;
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped></style>
