<template>
  <Modal v-if="isShow" @backgroundClose="handleClickOverlay(false)">
    <ModalNotSaved
      :value="isShow"
      @confirm="handleClickOverlay(true)"
      @input="handleClickOverlay($event)"
      :message="dialog.message"
      :left-button-params="dialog.buttons.left"
      :right-button-params="dialog.buttons.right"
    />
  </Modal>
</template>

<script>
import Vue from 'vue';
import { events } from './events';
import Modal from '@/pages/Modal';
import ModalNotSaved from '@/components/modals/modalNotSaved';

Vue.directive('focus', {
  inserted: function (el) {
    el.focus();
  },
});
const Component = {
  name: 'ModalConfirm',
  components: { Modal, ModalNotSaved },
  data() {
    return {
      isShow: false,
      dialog: {
        title: '',
        message: '',
        buttons: {},
      },
      params: {},
    };
  },
  methods: {
    resetState() {
      this.dialog = {
        title: '',
        message: '',
        buttons: {},
        callback: () => {},
      };
    },

    handleClickOverlay(res) {
      this.isShow = false;

      if (this.params.callback) {
        this.params.callback(res);
      }
    },

    open(params) {
      this.resetState();
      this.params = params;
      this.isShow = true;
      // set params to dialog state
      Object.entries(params).forEach((param) => {
        if (typeof param[1] == typeof this.dialog[param[0]]) {
          this.dialog[param[0]] = param[1];
        }
      });
    },
  },
  mounted() {
    if (!document) return;
    events.$on('open', this.open);
    events.$on('close', () => {
      this.handleClickOverlay(true);
    });
  },
  beforeDestroy() {},
};
export default Component;
</script>
