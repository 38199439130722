<template>
  <label
    :class="{ 'component-wrapinput': !$attrs.locked }"
    @click="onLabelClick"
    :style="$attrs.disabled || $attrs.locked ? 'pointer-events: none' : ''"
  >
    <DropdownSelectValue
      v-bind="[$props, $attrs]"
      v-on="$listeners"
      :class="{ 'inptwrap--tableelements': !$attrs.locked }"
      type="dropdown"
      placeholder="-"
      label="-"
      size="max"
      dd-max-elements="6"
      :disableNull="true"
      :disableCompletedClass="true"
    />
    <div
      class="component-wrapinput_subinputarrow"
      v-if="!$attrs.locked"
      ref="arrow"
    >
      <div
        class="vsvg chead_icon"
        v-svg="{ name: 'arrowdown' }"
        style="pointer-events: none"
      />
    </div>
  </label>
</template>

<script>
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';

export default {
  name: 'ArrowDropdown',
  components: { DropdownSelectValue },
  methods: {
    onLabelClick(event) {
      if (!(event.target === this.$refs.arrow)) {
        event.preventDefault();
      }
    },
  },
};
</script>
