<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('GlossaryCategories:Title') }]" />

      <div class="rightzone_top-buttonswrap">
        <Button
          v-if="isShowAddButton"
          :text="$t('GlossaryCategories:ButtonAdd')"
          size="standart"
          color="accent"
          icon="plus"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'NewCategory' })"
        />
      </div>
    </div>
    <div class="rightzone_table">
      <Table
        :headers="tableHeaders"
        :data-arr="dataArr"
        :table-loading="isTableLoading"
        :loading-count-items="5"
        @editClick="onEditClick"
      />
    </div>
  </article>
</template>

<script>
import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';
import Table from '@/components/tables/Table';
import routeNames from '@/router/routeNames';
import { mapState } from 'vuex';

export default {
  name: 'GlossaryCategories',
  components: { Table, Breadcrumps, Button },
  methods: {
    onEditClick(categoryId) {
      let category =
        this.$store.state.glossaryCategoriesCultures.categoriesCulturesMapById.get(
          categoryId
        );
      if (category) {
        this.$router.push({
          name: routeNames.editCategory,
          params: { id: category.id },
        });
      }
    },
  },
  computed: {
    ...mapState({
      canEdit: (state) => state.auth.currentUserRole?.claim_glossary_edit === 1,
    }),
    tableHeaders() {
      return [
        {
          text: this.$t('GlossaryCategories:TableHeaders:0'), //'Название',
          class: 'col--accent',
          width: '20%',
          type: 'text',
        },
        {
          text: this.$t('GlossaryCategories:TableHeaders:1'), //'Описание',
          class: 'col-roledescription',
          width: '80%',
          type: 'text',
        },
        // {
        //   text: this.$t('GlossaryCategories:TableHeaders:2'), //'Статус',
        //   class: 'col-status',
        //   width: '',
        //   type: 'status',
        // },
        {
          text: '',
          class: 'col-edit',
          width: '',
          type: 'edit',
          icon: this.canEdit ? 'edit' : 'passwordshow',
        },
      ];
    },
    isTableLoading() {
      return this.$store.state.glossaryCategoriesCultures
        .isCategoriesCulturesLoading;
    },
    dataArr() {
      let arr = [];

      if (this.$store.state.glossaryCategoriesCultures.categoriesCultures) {
        this.$store.state.glossaryCategoriesCultures.categoriesCultures.forEach(
          (categoryCulture) => {
            let tr = [];
            tr.push({ text: categoryCulture.name ? categoryCulture.name : '' });
            tr.push({
              text: categoryCulture.description
                ? categoryCulture.description
                : '',
            });
            // tr.push({ text: categoryCulture.is_active === 1 ? 'on' : 'off' });
            tr.push({ text: categoryCulture.id ? categoryCulture.id : '' });
            arr.push(tr);
          }
        );
      }
      return arr;
    },
    isShowAddButton() {
      return (
        this.$store.state.auth?.currentUserRole?.claim_glossary_edit || false
      );
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped></style>
