<!--Translated-->
<template>
  <div class="cblock__line phase_dashline">
    <div class="cblock__line-block">
      <div
        class="phase_dashline-sqr phase_dashline-sqr--img el--borders-all el--rounded-all"
      >
        <img v-if="image" :src="image" />
        <div v-else v-svg="{ name: 'photo' }"></div>
      </div>

      <div
        class="phase_dashline-sqr phase_dashline-sqr--info el--borders-all el--rounded-all"
      >
        <div class="phase_dashline-sqr__ico">
          <div v-svg="{ name: 'phases_flower' }"></div>
        </div>
        <div class="phase_dashline-sqr__name">
          {{ $t('TemplatePhaseHeader:Name') }}
        </div>
        <div class="tbl_info phase_dashline-sqr__content">
          <div class="tbl_info-wrap phase_dashline-sqr--longtext">
            <span style="text-align: center"> {{ phase.name }} </span>
            <div class="tablepreview">
              <div class="tablepreview_textblock">
                <h4>{{ phase.name }}</h4>
                <p>{{ phase.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="phase_dashline-sqr phase_dashline-sqr--info el--borders-all el--rounded-all"
      >
        <div class="phase_dashline-sqr__ico">
          <div v-svg="{ name: 'phases_timetable' }"></div>
        </div>
        <div class="phase_dashline-sqr__name">
          {{ $t('TemplatePhaseHeader:Period:Header') }}
        </div>
        <div class="phase_dashline-sqr__content">
          <div class="phase_dashline--longtext">
            <div>
              <div>
                <span>{{ $t('TemplatePhaseHeader:Period:From') }}: </span>
                <span>{{ period.start }}</span>
              </div>
              <div>
                <span>{{ $t('TemplatePhaseHeader:Period:To') }}: </span>
                <span>{{ period.end }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="phase_dashline-sqr phase_dashline-sqr--info el--borders-all el--rounded-all"
      >
        <div class="phase_dashline-sqr__ico">
          <div v-svg="{ name: 'phases_flask' }"></div>
        </div>
        <div class="phase_dashline-sqr__name">
          {{ $t('TemplatePhaseHeader:Period:Volume') }}
        </div>
        <div class="phase_dashline-sqr__content">
          <InputNumber
            v-if="!readonly"
            placeholder="0.0"
            label="-"
            size="micro"
            v-model="waterPhase"
            :state="isSaving ? 'disabled' : ''"
          />
          <span v-else> {{ waterPhase || '0.0' }} </span>
        </div>
      </div>

      <div
        v-if="price"
        class="phase_dashline-sqr phase_dashline-sqr--info el--borders-all el--rounded-all"
      >
        <div class="phase_dashline-sqr__ico">
          <div v-svg="{ name: 'phases_money' }"></div>
        </div>
        <div class="phase_dashline-sqr__name">
          {{ $t('TemplatePhaseHeader:Period:Price') }}
        </div>
        <div class="phase_dashline-sqr__content">
          <span>{{ price }}</span>
        </div>
      </div>
    </div>
    <div class="cblock__line-block cblock__line-block--vertical">
      <Button
        class="btn--chevrontogle"
        size="s-standart"
        :color="expanded ? 'accent' : 'accentlightlines'"
        :icon="expanded ? 'chevrondown' : 'chevronup'"
        :key="expanded"
        @click="$emit('toogle')"
      />
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { ka } from 'date-fns/locale';

import { mapState } from 'vuex';
import { serverUrl } from '@/api/axios.js';

import { mutationTypes } from '@/store/modules/plantsCare';
import Button from '@/components/views/Button';
import InputNumber from '@/components/views/wrappers/InputNumberWithCast';

export default {
  name: 'TemplatePhaseHeader',
  props: {
    phase: { type: Object, required: true },
    expanded: { type: Boolean, required: true },
    price: { type: String },
    readonly: { type: Boolean, default: false },
  },
  components: { Button, InputNumber },
  computed: {
    ...mapState({
      isSaving: (state) => state.plantsCare.isSaving,
      image(state) {
        const img = state.plantsCare.images.find(
          (img) =>
            img.object_id === this.phase.id && img.is_main && img.type === 1
        );
        return img
          ? `${serverUrl}${img.name}`.replace('uploads/', 'uploads/thumbs/')
          : null;
      },
      waterPhaseGet(state) {
        const phase = state.plantsCare.templateWaterPhases.find(
          ({ phase_id }) => phase_id === this.phase.id
        );
        return phase?.quantity ?? null;
      },
    }),
    waterPhase: {
      get() {
        return this.waterPhaseGet;
      },
      set(value) {
        this.$store.commit(mutationTypes.upsertWaterPhase, {
          id: this.phase.id,
          quantity: value,
        });
      },
    },
    period() {
      const start = format(
        new Date(2020, this.phase.start_month - 1, this.phase.start_day),
        'd MMMM',
        { locale: ka }
      );
      const end = format(
        new Date(2020, this.phase.end_month - 1, this.phase.end_day),
        'd MMMM',
        { locale: ka }
      );

      return { start, end };
    },
  },
};
</script>
