<-- Translated -->
<template>
  <div class="filter filter--oneline">
    <div class="filter-inputszone">
      <div class="filterwrap filterwrap--glossary-culture">
        <div class="filterwrap_line">
          <Input
            type="text"
            :placeholder="$t('FilterGlossaryCulture:Name.Placeholder')"
            :label="$t('FilterGlossaryCulture:Name.Label')"
            icon=""
            state=""
            size="long"
            color=""
            v-model="filter.name"
            @input="$emit('input', filter)"
          />

          <!-- <Input
            type="dropdown"
            placeholder="Не выбрано"
            label="Категория"
            icon=""
            state=""
            size="standart"
            color=""
            :dd-items="ddCategories"
            @selected="
              filter.category_id = $event.item.value;
              ddCategorySelectedIndex = $event.index;
              $emit('input', filter);
            "
            :dd-selected-index="ddCategorySelectedIndex"
            dd-max-elements="6"
          />-->
          <DropdownSelectValue
            :placeholder="$t('FilterGlossaryCulture:Category.Placeholder')"
            :label="$t('FilterGlossaryCulture:Category.Label')"
            size="standart"
            color=""
            @selected="$emit('input', filter)"
            dd-max-elements="6"
            :options="ddCategories"
            v-model="filter.category_id"
          />
        </div>
      </div>
    </div>
    <div class="filter-clearbtnzone">
      <Button
        class="filter_buttonclear"
        text="очистить"
        size="s-standart"
        :color="isFilterActive ? 'accentlines' : 'graylines'"
        icon="backspace"
        :is-loading="false"
        :loading-left="true"
        @click="clearFilter"
      />
    </div>
  </div>
</template>

<script>
import Input from '@/components/views/Input';
import Button from '@/components/views/Button';
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';
export default {
  name: 'FilterGlossaryCulture',
  components: {
    DropdownSelectValue,
    Button,
    Input,
  },
  props: {
    value: { default: {} },
  },
  data() {
    return {
      filter: {
        name: '',
        category_id: '',
        ...this.value,
      },
      ddCategorySelectedIndex: 0,
    };
  },
  computed: {
    ddCategories() {
      let arr = []; //[{ text: 'Не выбрано', value: null }];
      if (this.$store.state.glossaryCategoriesCultures.categoriesCultures) {
        this.$store.state.glossaryCategoriesCultures.categoriesCultures.forEach(
          (category) => {
            arr.push({ text: category.name, value: category.id });
          }
        );
      }
      return arr;
    },
    isFilterActive() {
      let filterIsActive = false;
      for (let filterKey in this.filter) {
        if (this.filter[filterKey]) {
          filterIsActive = true;
          break;
        }
      }
      return filterIsActive;
    },
  },
  methods: {
    clearFilter() {
      this.filter.name = '';
      this.filter.category_id = '';
      this.ddCategorySelectedIndex = 0;

      this.$emit('input', this.filter);
    },
  },
};
</script>

<style lang="scss" scoped></style>
