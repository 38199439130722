import { render, staticRenderFns } from "./DropdownSelectValue.vue?vue&type=template&id=00fdb0c8&scoped=true&"
import script from "./DropdownSelectValue.vue?vue&type=script&lang=js&"
export * from "./DropdownSelectValue.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00fdb0c8",
  null
  
)

export default component.exports