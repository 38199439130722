<template>
  <div class="modalbackgroundWrap">
    <div class="modalbackground" @click="$emit('backgroundClose')"></div>
    <slot></slot>
  </div>
</template>

<script>
import browserHelper from '@/helpers/browserHelper';

export default {
  name: 'AppModal',

  data() {
    return {
      hideScroll: false,
    };
  },
  methods: {},
  beforeMount() {
    if (document.body.style.overflowY !== 'hidden') {
      this.hideScroll = true;
      document.body.style.overflowY = 'hidden';
      document.body.style.paddingRight =
        browserHelper.getScrollbarWidth() + 'px';
    }
  },
  beforeDestroy() {
    if (this.hideScroll) {
      document.body.style.overflowY = 'scroll';
      document.body.style.paddingRight = '0';
    }
  },
};
</script>
