<!--Translated-->
<template>
  <div
    class="tablepreview"
    ref="tooltip"
    :style="`opacity: ${visible ? 1 : 0};`"
  >
    <div
      v-if="visible"
      class="tablepreview_textblock tablepreview_textblock--list"
    >
      <h4 style="cursor: pointer">
        {{ product.name }}
      </h4>
      <p>
        <span>{{ $t('HoverTooltipFertilizer:Vendor') }} - </span>
        <span>{{ manufacturerName }}</span>
      </p>
      <p>
        <span>{{ $t('HoverTooltipFertilizer:Country') }} - </span
        ><span> {{ countryName.trim() }} </span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'UserHoverTooltipProduct',
  props: { product: { type: Object, required: true } },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    ...mapState({
      manufacturer(state) {
        return state.userNutrition.manufacturers?.find(
          ({ id }) => id === this.product.manufacturer_id
        );
      },
      countryName(state) {
        return this.manufacturer
          ? state.userNutrition.manufacturersCountries?.find(
              ({ id }) => id === this.product.manufacturer_country_id
            )?.name ?? '...'
          : '...';
      },
    }),
    manufacturerName() {
      return this.manufacturer?.name ?? '...';
    },
  },
  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      this.visible = entries[0].isIntersecting;
    });
    this.observer.observe(this.$refs.tooltip);
  },
  beforeDestroy() {
    this.observer?.unobserve(this.$refs.tooltip);
  },
};
</script>
