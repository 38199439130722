<!--Translated-->
<template>
  <Modal>
    <div class="modal modal--max modal--fertilizerhelp modal--analyzes">
      <div class="modalwrap2-topline marginbottom--mini">
        <div class="topline-minizone"></div>

        <Breadcrumps :data="[{ title: $t('FertilizerModal:Title') }]" />

        <div class="topline-minizone">
          <Button
            size="s-standart"
            color="accentlightlines"
            icon="x"
            :is-loading="false"
            :loading-left="true"
            @click="$emit('close')"
          />
        </div>
      </div>

      <div class="decorline"></div>

      <div class="modalwrap2-content">
        <div class="analyzes-list">
          <table class="table--type1 margintop--standart">
            <thead>
              <tr>
                <th>{{ $t('FertilizerModal:Header') }}</th>
                <th v-for="el in elements" :key="el.id">
                  {{ el.name }}
                </th>
              </tr>
            </thead>
            <tr v-for="(phase, weekIndex) in phases" :key="phase.id">
              <td>
                {{ weekIndex + 1 }}.
                {{ formatPeriod(phase.phase_start, phase.phase_end) }}
              </td>
              <td
                v-for="(element, index) in phasesElements[weekIndex]"
                :key="index"
                :style="element.isMax ? 'color:#629b33;font-weight:bold;' : ''"
              >
                {{ element.value > 0 ? round(element.value) : '' }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { format } from 'date-fns';
import { mapGetters, mapState } from 'vuex';
import Modal from '@/pages/Modal';
import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';

export default {
  name: 'ModalFertilizerElements',
  components: {
    Button,
    Breadcrumps,
    Modal,
  },
  computed: {
    ...mapGetters([
      'nutritionPhasesProductsAsMap',
      'nutritionProductsFertilizerElementsByProduct',
    ]),
    ...mapState({
      elements: (state) =>
        state.nutrition.elements.filter(({ id }) => id !== 13 && id !== 14),
      phases: (state) => state.nutrition.programPhases,
    }),
    phasesElements() {
      const quantityByPhase = Object.fromEntries(
        this.elements.map(({ id }) => [
          id,
          Object.fromEntries(this.phases.map((phase) => [phase.id, 0])),
        ])
      );
      const phases = this.phases.map((phase) => {
        const elementsMap = Object.fromEntries(
          this.elements.map(({ id }) => [id, 0])
        );

        this.nutritionPhasesProductsAsMap[phase.id]
          .map((p) => ({
            ...p,
            elements:
              this.nutritionProductsFertilizerElementsByProduct[p.product_id],
          }))
          .filter((p) => p.elements?.length > 0)
          .forEach((p) => {
            for (const el of p.elements) {
              elementsMap[el.element_id] +=
                ((p.count_per_ha ?? 0) * el.quantity) / 100;
            }
          });

        for (const element of Object.keys(elementsMap)) {
          quantityByPhase[element][phase.id] = elementsMap[element];
        }

        return elementsMap;
      });

      for (const [key, value] of Object.entries(quantityByPhase)) {
        const max = Math.max(...Object.values(value));
        const weeks = Object.entries(quantityByPhase[key])
          .filter(([, val]) => val > 0 && val === max)
          .map(([key]) => key);
        quantityByPhase[key] = weeks;
      }

      return phases.map((elements, index) => {
        const phase = this.phases[index];
        for (const key of Object.keys(elements)) {
          elements[key] = {
            value: elements[key],
            isMax: quantityByPhase[key].includes(String(phase.id)),
          };
        }
        return elements;
      });
    },
  },
  methods: {
    formatPeriod(start, end) {
      return `${format(new Date(start), 'dd.MM.yyyy')} - ${format(
        new Date(end),
        'dd.MM.yyyy'
      )}`;
    },
    round(num) {
      return Math.round(num * 10_000) / 10_000;
    },
  },
};
</script>
