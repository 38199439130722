import axios from '@/api/axios';

import { serverKeysProgram } from '@/store/modules/nutritionPrograms';
import cloneDeep from 'lodash.clonedeep';

const utcToLocal = (utc) => {
  const utcDate = new Date(utc);
  const day = utcDate.getUTCDate();
  const month = utcDate.getUTCMonth();
  const year = utcDate.getUTCFullYear();
  return new Date(year, month, day).getTime();
};

const localToUtc = (local) => {
  const localDate = new Date(local);
  const day = localDate.getDate();
  const month = localDate.getMonth();
  const year = localDate.getFullYear();
  return new Date(Date.UTC(year, month, day)).getTime();
};

const getNewProgramData = async (timestamp, geoBase, clients) => {
  const { data } = await axios.get('plantsNutrition/getNewProgramData', {
    params: {
      ...Object.fromEntries(serverKeysProgram.map((key) => [key, timestamp])),
      geoBase,
      clients,
    },
  });
  serverKeysProgram.forEach((key) => (data[key] ??= []));
  return data;
};

const getEditProgramData = async (id, timestamp, geoBase, clients) => {
  const { data } = await axios.get('plantsNutrition/getEditProgramData', {
    params: {
      id,
      ...Object.fromEntries(serverKeysProgram.map((key) => [key, timestamp])),
      geoBase,
      clients,
    },
  });
  serverKeysProgram.forEach((key) => (data[key] ??= []));
  if (data.program.period_start === 0) data.program.period_start = null;
  if (data.program.period_end === 0) data.program.period_end = null;
  // if (data.program.period_start)
  //   data.program.period_start = utcToLocal(data.program.period_start);
  // if (data.program.period_end)
  //   data.program.period_end = utcToLocal(data.program.period_end);
  if (data.programPhases) {
    data.programPhases.forEach((phase) => {
      phase.phase_start = utcToLocal(phase.phase_start);
      phase.phase_end = utcToLocal(phase.phase_end);
    });
  }
  return data;
};

const getClientData = (id) => {
  const controller = new AbortController();
  const promise = axios
    .get('plantsNutrition/getClientData', {
      signal: controller.signal,
      params: { id },
    })
    .then((res) => res.data);
  return { controller, promise };
};

const getCultureData = (id) => {
  const controller = new AbortController();
  const promise = axios
    .get('plantsNutrition/getCultureData', {
      signal: controller.signal,
      params: { id },
    })
    .then((res) => res.data);
  return { controller, promise };
};

const saveProgramData = async (pd) => {
  const programData = cloneDeep(pd);
  const { programPhases } = programData;
  // program.period_start = localToUtc(program.period_start);
  // program.period_end = localToUtc(program.period_end);
  programPhases.forEach((phase) => {
    phase.phase_start = localToUtc(phase.phase_start);
    phase.phase_end = localToUtc(phase.phase_end);
  });

  return axios
    .post('plantsNutrition/updateProgram', { programData })
    .then((res) => res.data);
};

const getPrograms = async (programs = 0, geoBase = 0) =>
  axios
    .get('plantsNutrition/getPrograms', { params: { programs, geoBase } })
    .then((res) => res.data);

const getDefaultProducts = () =>
  axios.get('plantsNutrition/getDefaultProducts').then((res) => res.data);

const getNewDefaultProductData = () =>
  axios.get('plantsNutrition/getNewDefaultProductData').then((res) => res.data);

const getEditDefaultProductData = (id) =>
  axios
    .get('plantsNutrition/getEditDefaultProductData', { params: { id } })
    .then((res) => res.data);

const updateDefaultProduct = (defaultProduct) =>
  axios
    .post('plantsNutrition/updateDefaultProduct', { defaultProduct })
    .then((res) => res.data);

export default {
  getNewProgramData,
  getEditProgramData,
  getClientData,
  getCultureData,
  saveProgramData,
  getPrograms,
  getDefaultProducts,
  getNewDefaultProductData,
  getEditDefaultProductData,
  updateDefaultProduct,
};
