<!-- Translated -->
<template>
  <article
    class="rightzone rightzone--clientedit"
    ref="client"
    style="padding-bottom: 500px"
  >
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('ClientsEdit:ButtonsCancel')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'Clients' })"
        />
        <Button
          :text="$t('ClientsEdit:ButtonsSave')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="isSubmiting"
          :loading-left="true"
          :disabled="!canSubmitClientForm || !canEdit"
          @click="saveClient"
        />
      </div>
    </div>

    <ClientWarning v-if="isEditing" :canDismiss="canEdit" />
    <ClientDataForm :isEditing="isEditing" :readonly="!canEdit" />
    <ClientContacts :isEditing="isEditing" :readonly="!canEdit" />
    <ClientCulture
      v-for="(culture, index) in cultures"
      :key="culture.id"
      :culture="culture"
      :count="cultures.length"
      :readonly="!canEdit"
      :isFirst="index === 0"
    />
    <ClientAddCulture :isEditing="isEditing" v-if="canEdit" />

    <ClientProgramsTable v-if="canSeeDefensePrograms" />
    <ClientNutritionProgramsTable v-if="canSeeFertilizerPrograms" />
    <ClientAnalysesTable v-if="canSeeAnalyses" />

    <Button
      v-if="canEdit"
      :text="$t('ClientsEdit:ButtonsSave')"
      size="standart"
      color="accent"
      icon="save"
      :is-loading="isSubmiting"
      :loading-left="true"
      :disabled="!canSubmitClientForm"
      @click="saveClient"
    />

    <Modal
      v-if="showModalNotSaved"
      @backgroundClose="showModalNotSaved = false"
    >
      <ModalNotSaved
        v-model="showModalNotSaved"
        @confirm="modalNotSavedConfirm"
      />
    </Modal>
  </article>
</template>

<script>
import throttle from 'lodash.throttle';

import routeNames from '@/router/routeNames';
import { mapGetters, mapState } from 'vuex';
import { actionTypes } from '@/store/modules/clients';

import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';
import Modal from '@/pages/Modal';
import ModalNotSaved from '@/components/modals/modalNotSaved';
import ClientDataForm from './components/ClientDataForm';
import ClientContacts from './components/ClientContacts';
import ClientAddCulture from './components/ClientAddCulture';
import ClientCulture from './components/ClientCulture';
import ClientWarning from './components/ClientWarning';
import ClientProgramsTable from './components/ClientProgramsTable';
import ClientNutritionProgramsTable from './components/ClientNutritionProgramsTable';
import ClientAnalysesTable from './components/ClientAnalysesTable';

export default {
  name: 'ClientsEdit',
  components: {
    Modal,
    Breadcrumps,
    Button,
    ClientDataForm,
    ClientContacts,
    ClientAddCulture,
    ClientCulture,
    ModalNotSaved,
    ClientNutritionProgramsTable,
    ClientProgramsTable,
    ClientAnalysesTable,
    ClientWarning,
  },
  data() {
    return {
      showModalNotSaved: false,
      nextRouteConfirm: null,
      nextRoute: null,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.clients.isLoading,
      isSubmiting: (state) => state.clients.isSubmiting,
      isSearching: (state) => state.clients.isSearching,
      lastSaveTime: (state) => state.clients.lastSaveTime,
      lastAddedItemId: (state) => state.clients.lastAddedItemId,
      redirectId: (state) => state.clients.redirectId,
      updatedId: (state) => state.clients.updatedId,
      cultures: (state) => state.clients.clientCultures,
      error: (state) => state.clients.error,
      usedSearch(state) {
        return this.isEditing || state.clients.usedSearch;
      },
      canEdit: (state) => state.auth.currentUserRole?.claim_clients_edit === 1,
      canSeeDefensePrograms: (state) =>
        state.auth.currentUserRole?.claim_plants_care_see === 1,
      canSeeFertilizerPrograms: (state) =>
        state.auth.currentUserRole?.claim_plants_nutrition_see === 1,
      canSeeAnalyses: (state) =>
        state.auth.currentUserRole?.claim_analyse_see === 1,
    }),
    ...mapGetters([
      'geoMapped',
      'canSubmitClientForm',
      'clientDataWasEdited',
      'clientFormWasEdited',
    ]),
    breadcrumpsArr() {
      return [
        { title: this.$t('ClientsEdit:Title:0'), route: 'Clients' },
        {
          title: this.isEditing
            ? this.$t('ClientsEdit:Title:1')
            : this.$t('ClientsEdit:Title:2'),
        },
      ];
    },
    isEditing() {
      return this.$route.name === routeNames.editClient;
    },

    headers_analyzes() {
      return [
        {
          text: this.$t('AnalyzesList:TableHeaders:0'), //'Дата заявки',
          class: 'tbl-celldate',
          width: '15%',
          type: 'text',
        },
        {
          text: this.$t('AnalyzesList:TableHeaders:1'), //'Тип анализа',
          class: 'tbl-cellanalyzetype',
          width: '35%',
          type: 'text',
        },
        /*{
          text: this.$t('AnalyzesList:TableHeaders:2'), //'Имя, фамилия',
          class: 'tbl-cellname',
          width: '42%',
          type: 'text',
        },*/
        /*        {
          text: this.$t('AnalyzesList:TableHeaders:3'), //'ID клиента',
          class: 'tbl-cellid',
          width: '30%',
          type: 'text',
        },*/
        {
          text: this.$t('AnalyzesList:TableHeaders:4'), //'Статус',
          class: 'col-tbl-cellanalyzestatus',
          width: '50%',
          type: 'text',
        },
        {
          class: 'col-edit',
          type: 'edit',
          icon: this.canEdit ? 'edit' : 'passwordshow',
        },
      ];
    },
  },
  methods: {
    saveClient() {
      this.$store.dispatch(actionTypes.submitClientData);
    },
    modalNotSavedConfirm() {
      if (this.nextRoute) {
        this.nextRouteConfirm = true;
        this.$router.push(this.nextRoute);
      }
    },
    onScroll: throttle(function () {
      const levels = [1, 2, 3];
      for (const level of levels) {
        const levelNodes = document.querySelectorAll(`.level${level}`);
        for (const node of levelNodes) {
          const { y } = node.getBoundingClientRect();
          const position = y - (level - 1) * node.offsetHeight;
          if (position < 1) {
            node.classList.add('is-pinned');
          } else {
            node.classList.remove('is-pinned');
          }
        }
        const fixer = document.getElementById('fix-scroll-jump');
        if (fixer) {
          const { y } = fixer.getBoundingClientRect();
          if (y >= 0) fixer.style.height = '1px';
          else fixer.style.height = 0;
        }
      }
    }, 100),
  },
  watch: {
    error: {
      handler(error) {
        if (error) {
          this.$awn.alert(String(error));
          throw error;
        }
      },
      immediate: true,
    },
    lastSaveTime(time) {
      if (!time) return;
      this.$confirm({
        message:
          this.$t('ClientsEdit:ModalSaved:Title1') +
          '<br/>' +
          this.$t('ClientsEdit:ModalSaved:Title2'),
        //'Изменения сохранены.<br/> Вы хотите перейти на страницу со списком всех клиентов?',
        buttons: {
          left: {
            text: this.$t('ClientsEdit:ModalSaved:Ok'), //'Перейти',
            icon: 'tick',
            color: 'accentlines',
          },
          right: {
            text: this.$t('ClientsEdit:ModalSaved:Cancel'), //'Остаться',
            icon: 'cancel',
            color: 'graylines',
          },
        },
        callback: (confirm) => {
          this.nextRouteConfirm = true;
          if (confirm) {
            this.$router.push({ name: routeNames.clients });
          } else {
            if (this.updatedId) {
              this.$router.push({
                name: routeNames.editClient,
                params: { id: this.updatedId },
              });
            }
          }
        },
      });
    },
    lastAddedItemId(id) {
      setTimeout(() => {
        const el = document.querySelector(`[data-item-id="${id}"]`);
        if (!el) return;
        const parent = el.parentElement.querySelector('.cblock');
        el.classList.add('attention-to-new-item');
        setTimeout(() => el.classList.remove('attention-to-new-item'), 1000);
        parent.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 50);
    },
    redirectId(id) {
      if (id) {
        this.nextRouteConfirm = true;
        this.$router.push({ name: routeNames.editClient, params: { id } });
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      this.nextRouteConfirm ||
      !this.canEdit ||
      !(this.clientDataWasEdited || this.clientFormWasEdited)
    ) {
      next();
    } else {
      this.showModalNotSaved = true;
      this.nextRoute = to;
      next(false);
    }
  },
  created() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="scss">
.is-pinned.level1 {
  z-index: 1999 !important;
}
.is-pinned.level2 {
  z-index: 1998 !important;
}
.is-pinned.level3 {
  z-index: 1997 !important;
}
.attention-to-new-item {
  background-color: rgb(255, 250, 200);
}
.chead {
  transition: background-color 1000ms;
}
</style>
