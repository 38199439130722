var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cblock__line-block"},[_c('DropdownSelect',{attrs:{"placeholder":_vm.locked
        ? _vm.$t('LocationSelect:NotSelected')
        : _vm.$t('LocationSelect:SelectCountry.Placeholder'),"label":_vm.$t('LocationSelect:SelectCountry.Label'),"size":"standart","options":_vm.countries,"disabled":_vm.disabled,"locked":_vm.locked},model:{value:(_vm.values.country),callback:function ($$v) {_vm.$set(_vm.values, "country", $$v)},expression:"values.country"}}),(_vm.type > 0)?_c(_vm.freeSelect && _vm.type === 1 ? 'DropdownPlusSelect' : 'DropdownSelect',{tag:"component",attrs:{"placeholder":_vm.locked
        ? _vm.$t('LocationSelect:NotSelected')
        : _vm.$t('LocationSelect:SelectRegion.Placeholder'),"label":_vm.$t('LocationSelect:SelectRegion.Label'),"size":"standart","options":_vm.regions,"disabled":_vm.regionsDisabled,"locked":_vm.locked},model:{value:(_vm.values.region),callback:function ($$v) {_vm.$set(_vm.values, "region", $$v)},expression:"values.region"}}):_vm._e(),(_vm.type > 1)?_c(_vm.freeSelect && _vm.type === 2 ? 'DropdownPlusSelect' : 'DropdownSelect',{tag:"component",attrs:{"placeholder":_vm.locked
        ? _vm.$t('LocationSelect:NotSelected')
        : _vm.$t('LocationSelect:SelectMunicipalitet.Placeholder'),"label":_vm.$t('LocationSelect:SelectMunicipalitet.Label'),"size":"standart","options":_vm.municipalities,"disabled":_vm.municipalitiesDisabled,"locked":_vm.locked},model:{value:(_vm.values.municipality),callback:function ($$v) {_vm.$set(_vm.values, "municipality", $$v)},expression:"values.municipality"}}):_vm._e(),(_vm.type > 2)?_c(_vm.freeSelect && _vm.type > 2 ? 'DropdownPlusSelect' : 'DropdownSelect',{tag:"component",attrs:{"placeholder":_vm.locked
        ? _vm.$t('LocationSelect:NotSelected')
        : _vm.$t('LocationSelect:SelectCityVillage.Placeholder'),"label":_vm.$t('LocationSelect:SelectCityVillage.Label'),"size":"standart","options":_vm.settlements,"disabled":_vm.settlementsDisabled,"locked":_vm.locked,"useDdItemsCut":true},model:{value:(_vm.values.settlement),callback:function ($$v) {_vm.$set(_vm.values, "settlement", $$v)},expression:"values.settlement"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }