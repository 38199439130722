import { render, staticRenderFns } from "./ModalSettingsPagePassword.vue?vue&type=template&id=4bbc2912&scoped=true&"
import script from "./ModalSettingsPagePassword.vue?vue&type=script&lang=js&"
export * from "./ModalSettingsPagePassword.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bbc2912",
  null
  
)

export default component.exports