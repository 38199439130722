const state = {
  /*Элементы*/
  elements: null,
  elementsMapById: null,
};
export const mutationTypes = {
  setElementsSuccess: '[glossary] setElements success',
};
export const actionTypes = {};
const mutations = {
  [mutationTypes.setElementsSuccess](state, payload) {
    state.elements = payload.elements;
    processElements(state);
  },
};
function processElements(state) {
  state.elementsMapById = new Map();
  if (state.elements && state.elements instanceof Array) {
    state.elements.forEach((element) => {
      state.elementsMapById.set(element.id, element);
    });
  }
}

const actions = {};
export default {
  state,
  actions,
  mutations,
};
