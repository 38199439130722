<-- Translated -->
<template>
  <div class="filter filter--oneline">
    <div class="filter-inputszone">
      <div class="filterwrap filterwrap--glossary-harmfulobjects">
        <div class="filterwrap_line">
          <Input
            type="text"
            :placeholder="$t('FilterGlossaryBadObjects:Name.Placeholder')"
            :label="$t('FilterGlossaryBadObjects:Name.Label')"
            icon=""
            state=""
            size="long"
            color=""
            v-model="filter.name"
            @input="$emit('input', filter)"
          />

          <!--<Input
            type="dropdown"
            placeholder="Выберите..."
            label="Категория"
            icon=""
            state=""
            size="standart"
            color=""
            :dd-items="ddItems"
            @selected="
              filter.category_id = $event.item.value;
              ddCategorySelectedIndex = $event.index;
              $emit('input', filter);
            "
            :dd-selected-index="ddCategorySelectedIndex"
          />-->
          <DropdownSelectValue
            :placeholder="$t('FilterGlossaryBadObjects:Category.Placeholder')"
            :label="$t('FilterGlossaryBadObjects:Category.Label')"
            size="standart"
            color=""
            @selected="$emit('input', filter)"
            dd-max-elements="6"
            :options="ddItems"
            v-model="filter.category_id"
          />
        </div>
      </div>
    </div>
    <div class="filter-clearbtnzone">
      <Button
        class="filter_buttonclear"
        text="очистить"
        size="s-standart"
        color="graylines"
        icon="backspace"
        :is-loading="false"
        :loading-left="true"
        :color="isFilterActive ? 'accentlines' : 'graylines'"
        @click="
          filter.name = '';
          filter.category_id = null;
          ddCategorySelectedIndex = 0;
          $emit('input', filter);
        "
      />
    </div>
  </div>
</template>

<script>
import Input from '@/components/views/Input';
import Button from '@/components/views/Button';
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';
export default {
  name: 'FilterGlossaryBadObjects',
  components: {
    DropdownSelectValue,
    Button,
    Input,
  },
  props: ['categoriesPests', 'value'],
  data() {
    return {
      filter: {
        name: '',
        category_id: null,
        ...this.value,
      },
      ddCategorySelectedIndex: 0,
    };
  },
  computed: {
    ddItems() {
      let arr = []; //[{ text: 'Не выбрано', value: null }];
      this.categoriesPests?.forEach((val) => {
        arr.push({ text: val.name, value: val.id });
      });
      return arr;
    },
    isFilterActive() {
      let filterIsActive = false;
      for (let filterKey in this.filter) {
        if (this.filter[filterKey]) {
          filterIsActive = true;
          break;
        }
      }
      return filterIsActive;
    },
  },
};
</script>

<style lang="scss" scoped></style>
