<!--Translated-->
<template>
  <div class="modalwrap1 modalwrap1--login">
    <div class="modalwrap2-logo">
      <img class="login_logo" src="@/assets/loginpage/login_logo.svg" />
    </div>

    <form class="modalwrap2-content" method="post" @submit.prevent="">
      <div class="modalwrap3-inputs">
        <Input
          class="marginbottom--mini"
          type="text"
          :placeholder="$t('UserRegister:Email.Placeholder')"
          label="-"
          icon="contactmail"
          size="max"
          color=""
          :state="isLoading ? 'disabled' : validateEmail ? '' : 'error'"
          v-model="login"
          @input="validateEmail = true"
        />

        <Input
          class="marginbottom--mini"
          type="password"
          :placeholder="$t('UserRegister:Password.Placeholder')"
          label="-"
          icon="password"
          size="max"
          color=""
          :state="isLoading ? 'disabled' : validatePassword ? '' : 'error'"
          v-model="password1"
          @input="validatePassword = true"
        />

        <Input
          class="marginbottom--mini"
          type="password"
          :placeholder="$t('UserRegister:Password2.Placeholder')"
          label="-"
          icon="password"
          size="max"
          color=""
          :state="isLoading ? 'disabled' : validatePassword ? '' : 'error'"
          v-model="password2"
          @input="validatePassword = true"
        />

        <CheckBox
          class="this-checkbox-marginleft"
          :text="$t('UserRegister:CheckSave')"
          color-checked="accentfill"
          color-un-checked="gray"
          :disabled="isLoading"
        />

        <CheckBoxUserAgreeLink
          class="this-checkbox-marginleft margintop--mini"
          color-checked="accentfill"
          color-un-checked="gray"
          :disabled="isLoading"
          v-model="userAgreeTerms"
        />

        <!--<CheckBox
            class="this-checkbox-marginleft margintop&#45;&#45;mini"
            text="Я принимаю условия <a href=\"dvdvd\">ewfeef</a> лицензионного соглашения"
            color-checked="accentfill"
            color-un-checked="accent"
            :disabled="isLoading"
        />-->
      </div>

      <div class="modalwrap3-buttons">
        <Button
          class="margintop--mini"
          :text="$t('UserRegister:ButtonRegister')"
          size="max"
          color="accent"
          icon="tick"
          :loading-left="true"
          :is-loading="isLoading"
          :disabled="!userAgreeTerms"
          @click="onRegister"
        />
      </div>
    </form>
  </div>
</template>

<script>
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import CheckBox from '@/components/CheckBox';
import textHelper from '@/helpers/textHelper';
import * as adminApi from '@/api/clientsArea/admin';
import { actionTypes as geoActions } from '@/store/modules/glossaryGeobase';
import CheckBoxUserAgreeLink from '@/components/specialuse/CheckBoxUserAgreeLink';

export default {
  name: 'UserRegister',
  components: {
    CheckBoxUserAgreeLink,
    CheckBox,
    Input,
    Button,
  },
  data() {
    return {
      validateEmail: true,
      validatePassword: true,
      password1: '',
      password2: '',
      login: '',
      isLoading: false,
      userAgreeTerms: false,
    };
  },
  methods: {
    async onRegister() {
      if (!textHelper.validateEmail(this.login)) {
        this.validateEmail = false;
        this.$awn.alert(this.$t('UserRegister:Error:Email'));
        return;
      }
      if (this.password1 !== this.password2) {
        this.validatePassword = false;
        this.$awn.alert(this.$t('UserRegister:Error:Password1'));
        return;
      }
      if (this.password1.length < 6) {
        this.validatePassword = false;
        this.$awn.alert(this.$t('UserRegister:Error:Password2'));
        return;
      }
      try {
        //this.request.isLoading = true;
        this.isLoading = true;
        let response = await adminApi.checkLogin(this.login);
        this.isLoading = false;
        if (response.data.status === 'ok') {
          this.$emit('onRegister1Ok', {
            login: this.login,
            password: this.password1,
          });
        } else {
          this.validateEmail = false;
          this.$awn.alert(this.$t('UserRegister:Error:Login'));
        }
        //this.request.isLoading = false;
      } catch (e) {
        //this.request.hasError = true;
        //this.request.isLoading = false;
        this.isLoading = false;
        console.log(e);
      }
    },
  },
  created() {
    this.$store.dispatch(geoActions.getGeobase);
  },
};
</script>

<style lang="scss" scoped></style>
