<!--Translated-->
<template>
  <article class="rightzone rightzone--userarticleslist">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />
    </div>

    <LoaderBlock v-if="isLoading && articles?.length === 0" />

    <template v-else>
      <div
        class="articleslist cblock__internalpadding el--borders-all el--rounded-all"
      >
        <div
          v-for="article in articles"
          :key="article.id"
          class="articleslist_line"
        >
          <p @click="onArticleClick(article.id)">{{ article?.title }}</p>
        </div>
      </div>
    </template>
    <UserFooter />
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import UserFooter from '@/pages/userproject/userComponents/UserFooter';
import routeNames from '@/router/routeNames';
import userArticles, {
  actionTypes,
} from '@/store/modules/clientsArea/articles';
import { mapGetters } from 'vuex';
import LoaderBlock from '@/components/blocks/LoaderBlock';

export default {
  name: 'UserArticlesList',
  components: {
    LoaderBlock,
    UserFooter,
    Breadcrumps,
  },
  methods: {
    onArticleClick(id) {
      if (this.$store.state.userArticles.articlesMapById.has(id)) {
        switch (
          this.$store.state.userArticles.articlesMapById.get(id)?.type_id
        ) {
          case 1: {
            if (this.$store.state.userArticles.articlesMapById.get(id)?.link) {
              window.open(
                this.$store.state.userArticles.articlesMapById.get(id)?.link,
                '_blank'
              );
            }
            break;
          }
          case 2: {
            this.$router.push({
              name: routeNames.userArticle,
              params: { id: this.$route.params?.id, articleId: id },
            });
            break;
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters(['geoCategoriesArticlesMapByStringId']),
    breadcrumpsArr() {
      return [
        {
          title: this.$t('UserArticlesList:Title'),
          route: routeNames.userHomePage,
        },
        {
          title:
            this.geoCategoriesArticlesMapByStringId.get(
              this.$route.params?.id ?? null
            )?.name ?? '',
        },
      ];
    },
    articles() {
      let articles = this.$store.state.userArticles.articles.filter(
        (ar) => String(ar?.category_id) === this.$route.params?.id
      );
      return articles ?? [];
    },
    isLoading() {
      return this.$store.state.userArticles.isArticlesLoading;
    },
  },
  created() {
    this.$store.dispatch(actionTypes.getArticles);
  },
};
</script>

<style lang="scss">
@import '~@/scss/vars.scss';
</style>
