import * as instructionsApi from '@/api/clientsArea/instructions';

const state = {
  instructions: [],
  instructionsFilesLinks: [],
  timestampInstructions: 0,
  isInstructionsLoading: false,
  isInstructionsLoadingHasError: false,
};

const getters = {
  /*geoCategoriesArticlesMapById(state, getters) {
    return new Map(state.categoriesArticles.map((e) => [e.id, e]));
  },*/
};

export const mutationTypes = {
  getInstructionsStart: '[client instructions] getInstructions start',
  getInstructionsSuccess: '[client instructions] getInstructions success',
  getInstructionsFailure: '[client instructions] getInstructions failure',
};
export const actionTypes = {
  getInstructions: '[client instructions] getInstructions',
};

const mutations = {
  [mutationTypes.getInstructionsStart](state) {
    state.isInstructionsLoading = true;
    state.isInstructionsLoadingHasError = false;
    //state.products = null;
  },
  [mutationTypes.getInstructionsSuccess](state, payload) {
    state.isInstructionsLoading = false;
    if (payload && payload?.instructions) {
      state.instructions = payload.instructions || state.instructions;
      state.instructionsFilesLinks =
        payload.instructionsFilesLinks || state.instructionsFilesLinks;
      state.timestampInstructions = new Date().getTime();
      //processArticles(state);
    }
  },
  [mutationTypes.getArticlesFailure](state) {
    state.isInstructionsLoading = false;
    state.isInstructionsLoadingHasError = true;
  },
};

/*function processArticles(state) {
  state.articlesMapById = new Map();
  state.articles.forEach((val) => {
    state.articlesMapById.set(val.id, val);
  });
}*/

const actions = {
  async [actionTypes.getInstructions](context, payload) {
    context.commit(mutationTypes.getInstructionsStart, payload);
    try {
      let response = await instructionsApi.getInstructions({
        instructions: state.timestampInstructions,
      });
      if (response.status !== 304) {
        context.commit(mutationTypes.getInstructionsSuccess, response.data);
      }
    } catch (error) {
      console.log(error);
      context.commit(mutationTypes.getInstructionsFailure);
    }
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
