export const monthNames = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сенрябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export function formatDate(date) {
  if (date) {
    return new Date(date).toLocaleDateString('ru-RU');
    /*.toLocaleString('ru-RU', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
      .replace(',', '');*/
  } else return null;
}
