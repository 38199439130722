<-- Translated -->
<template>
  <div class="cblock__files margintop--standart">
    <div
      class="file"
      v-for="file in files"
      :key="file.id"
      @click="fileClicked(file.id)"
    >
      <div class="file__icon">
        <div class="vsvg vsvg--standart" v-svg="{ name: 'pdffile' }"></div>
        <div class="file_remove"></div>
      </div>
      <div class="file__textzone">
        <div class="file__name">
          {{ file.name || $t('ClientZoneAnalysisFiles:NoName') }}
        </div>
        <div class="file__bottom">
          <div class="file__date">
            Добавлен
            {{ new Date(file.date_create).toLocaleDateString('ru-RU') }}
          </div>
          <div class="file__delete" v-if="!locked" @click="removeFile(file.id)">
            Удалить
          </div>
        </div>
      </div>
    </div>

    <label class="file file--add" v-if="!locked">
      <div class="file__icon">
        <div class="vsvg vsvg--standart" v-svg="{ name: 'plus' }"></div>
      </div>
      <div class="file__textzone">
        <div class="file__name">
          {{ $t('ClientZoneAnalysisFiles:AddPdf') }}
          <input
            type="file"
            accept="application/pdf"
            multiple
            @change="fileSelected"
            :disabled="false"
          />
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { serverUrl } from '@/api/axios.js';
import { mutationTypes } from '@/store/modules/clients';

export default {
  name: 'ClientZoneAnalysisFiles',
  props: {
    locked: { type: Boolean, required: true },
    analysisId: { type: [Number, String], required: true },
  },
  computed: mapState({
    files(state) {
      return state.clients.clientAnalyzesFiles.filter(
        (f) => f.analyse_id === this.analysisId
      );
    },
  }),
  methods: {
    fileSelected(event) {
      for (const file of event.target.files) {
        const alreadyAdded = this.files.some((f) => f.file === file);
        const wrongExtension = !file.name.endsWith('.pdf');
        if (!alreadyAdded && !wrongExtension) {
          this.$store.commit(mutationTypes.addFile, {
            file,
            analysisId: this.analysisId,
          });
        }
      }
    },
    removeFile(id) {
      this.$store.commit(mutationTypes.removeFile, id);
    },
    fileClicked(id) {
      const file = this.files.find((f) => f.id === id);
      if (file.link) window.open(`${serverUrl}${file.link}`, '_blank');
    },
  },
};
</script>

<style scoped>
input[type='file'] {
  display: none;
}
</style>
