<-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps
        :data="[
          { title: $t('GlossaryRegions:Title'), route: routeBack },
          { title },
        ]"
      />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('GlossaryRegionsNew:ButtonCancel')"
          size="standart"
          color="graylines"
          icon="cancel"
          @click="$router.push('/regions')"
        />

        <Button
          :text="$t('GlossaryRegionsNew:ButtonSave')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="false"
          :loading-left="true"
          :disabled="!canSubmit"
          @click="save"
          :isLoading="isUpdating"
        />
      </div>
    </div>

    <div class="cwrap cwrap--level1 marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'edit' }"
          ></div>
          <div class="chead_text">{{ title }}</div>
        </div>
      </div>

      <div class="cblock el--borders-all el--rounded-bottom">
        <div class="cblock__internal">
          <div class="cblock__line" v-if="type > 0">
            <div class="cblock__line-block">
              <Input
                type="dropdown"
                :placeholder="$t('GlossaryRegions:Country.Placeholder')"
                :label="$t('GlossaryRegions:Country.Label')"
                size="long"
                :dd-items="countries"
                @selected="countrySelect"
                v-model="selectedCountry"
                :ddSelectedIndex="selectedCountryIndex"
                :state="isLoading || isUpdating ? 'disabled' : ''"
              />
            </div>
          </div>

          <div class="cblock__line" v-if="type > 1">
            <div class="cblock__line-block">
              <Input
                type="dropdown"
                :placeholder="$t('GlossaryRegions:Region.Placeholder')"
                :label="$t('GlossaryRegions:Region.Label')"
                size="long"
                :dd-items="regions"
                @selected="regionSelect"
                v-model="selectedRegion"
                :ddSelectedIndex="selectedRegionIndex"
                :state="isLoading || isUpdating ? 'disabled' : ''"
              />
            </div>
          </div>

          <div class="cblock__line" v-if="type > 2">
            <div class="cblock__line-block">
              <Input
                type="dropdown"
                :placeholder="$t('GlossaryRegions:Municipalitet.Placeholder')"
                :label="$t('GlossaryRegions:Municipalitet.Label')"
                size="long"
                :dd-items="municipalities"
                @selected="municipalitySelect"
                v-model="selectedMunicipality"
                :ddSelectedIndex="selectedMunicipalityIndex"
                :state="isLoading || isUpdating ? 'disabled' : ''"
              />
            </div>
          </div>

          <div class="cblock__line" v-if="type > 2">
            <div class="cblock__line-block">
              <RadioToggle
                name="settlementType"
                :text1="$t('GlossaryRegions:City')"
                :text2="$t('GlossaryRegions:Village')"
                :label="$t('GlossaryRegions:Type')"
                isTypeBlocked=" "
                isTypeDisabled=" "
                v-model="settlementType"
                :disabled="isLoading || isUpdating"
              />
            </div>
          </div>

          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                :placeholder="$t('GlossaryRegionsNew:NewText')"
                :label="geoName"
                v-model="name"
                size="long"
                :state="isLoading || isUpdating ? 'disabled' : ''"
              />
            </div>
          </div>

          <!--<div class="cblock__line">
            <div class="cblock__line-block">
              <RadioToggle
                name="isActive"
                :text1="$t('GlossaryRegionsNew:Active:Hide')"
                :text2="$t('GlossaryRegionsNew:Active:Active')"
                :label="$t('GlossaryRegionsNew:Active:Label')"
                reverse="true"
                v-model="is_active"
                :disabled="isLoading || isUpdating"
              />
            </div>
          </div>-->
        </div>
      </div>
    </div>
    <Button
      :text="$t('GlossaryRegionsNew:ButtonSave')"
      size="standart"
      color="accent"
      icon="save"
      :is-loading="false"
      :loading-left="true"
      :disabled="!canSubmit"
      @click="save"
      :isLoading="isUpdating"
    />
  </article>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { actionTypes } from '@/store/modules/glossaryGeobase';
import routeNames from '@/router/routeNames';
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import RadioToggle from '@/components/views/RadioToggle';
import { mapGeoEntity } from './GlossaryRegions';
import { keys } from './GlossaryRegionsEdit';

export default {
  name: 'GlossaryRegionsNew',
  components: { RadioToggle, Input, Button, Breadcrumps },
  data() {
    return {
      routeBack: routeNames.regions,
      is_active: true,
      type: null,
      name: '',
      selectedCountryIndex: 0,
      selectedRegionIndex: 0,
      selectedMunicipalityIndex: 0,
      selectedCountry: null,
      selectedRegion: null,
      selectedMunicipality: null,
      settlementType: null,
      notSelectedItem: {
        value: null,
        text: this.$t('GlossaryRegions:NotSelected'),
      },
    };
  },
  created() {
    this.type = ['country', 'region', 'municipality', 'settlement'].findIndex(
      (e) => e === this.$route.params.type
    );
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.glossaryGeobase.isGeobaseLoading,
      hasLoadingError: (state) =>
        state.glossaryGeobase.isGeobaseLoadingHasError,
      canEdit: (state) => state.auth.currentUserRole?.claim_glossary_edit,
      serverData: (state) => state.glossaryGeobase.geobaseRaw,
      isUpdating: (state) => state.glossaryGeobase.isGeobaseUpdating,
      isUpdatingDone: (state) => state.glossaryGeobase.isGeobaseUpdatingDone,
      updateError: (state) => state.glossaryGeobase.geobaseUpdatingError,
    }),
    ...mapGetters(['geoPlainData']),
    typeTexts() {
      return [
        this.$t('GlossaryRegionsNew:Arr:0'), //'Страна',
        this.$t('GlossaryRegionsNew:Arr:1'), //'Регион',
        this.$t('GlossaryRegionsNew:Arr:2'), //'Муниципалитет',
        this.$t('GlossaryRegionsNew:Arr:3'), //'Населенный пункт',
        this.$t('GlossaryRegionsNew:Arr:3'), //'Населенный пункт',
      ];
    },
    typeTextsSelect() {
      return this.typeTexts.map((e) =>
        e
          .toLowerCase()
          .replace(
            this.$t('GlossaryRegionsNew:Arr:Country1'),
            this.$t('GlossaryRegionsNew:Arr:Country2')
          )
          .replace(
            this.$t('GlossaryRegionsNew:Arr:1'),
            this.$t('GlossaryRegionsNew:Arr:Region2')
          )
          .replace(
            this.$t('GlossaryRegionsNew:Arr:2'),
            this.$t('GlossaryRegionsNew:Arr:Mun2')
          )
          .replace(
            this.$t('GlossaryRegionsNew:Arr:3'),
            this.$t('GlossaryRegionsNew:Arr:NP2')
          )
      );
    },
    title() {
      return `${this.typeTextsSelect[this.type]} ${this.$t(
        'GlossaryRegionsNew:Title'
      )} `;
    },
    geoName() {
      return this.typeTexts[this.type];
    },
    closestParentsType() {
      return this.type === 4 ? 2 : this.type - 1;
    },
    parent_id() {
      if (this.closestParentsType === 2)
        return this.selectedMunicipality?.value;
      else if (this.closestParentsType === 1) return this.selectedRegion?.value;
      else if (this.closestParentsType === 0)
        return this.selectedCountry?.value;
      return null;
    },
    canSubmit() {
      return (
        this.name &&
        (this.parent_id || this.type === 0) &&
        (this.type > 2 ? this.settlementType !== null : true)
      );
    },
    countries() {
      if (this.type === 0) return null;
      return [
        this.notSelectedItem,
        ...this.geoPlainData
          .filter((e) => e.type === 0)
          .map((e) => mapGeoEntity(e)),
      ];
    },
    regions() {
      if (this.type === 1) return null;
      return [
        this.notSelectedItem,
        ...this.findChildrenById(this.selectedCountry?.value).map((e) =>
          mapGeoEntity(e)
        ),
      ];
    },
    municipalities() {
      if (this.type === 2) return null;
      return [
        this.notSelectedItem,
        ...this.findChildrenById(this.selectedRegion?.value).map((e) =>
          mapGeoEntity(e)
        ),
      ];
    },
  },
  watch: {
    settlementType(val) {
      if (val === 0) this.type = 3;
      if (val === 1) this.type = 4;
    },
    updateError(val) {
      console.error(val);
    },
    hasLoadingError() {
      console.error('has loading error');
    },
    isUpdatingDone() {
      if (this.isUpdatingDone) {
        this.nextRouteConfirm = true;
        this.$router.push({ name: routeNames.regions });
      }
    },
  },
  methods: {
    findChildrenById(id) {
      return this.geoPlainData.find((e) => e.id === id)?.children || [];
    },
    countrySelect({ index, item }) {
      if (this.selectedCountry?.value !== item?.value) {
        this.selectedCountry = item;
        this.selectedCountryIndex = index;
        this.clearRegionSelect();
        this.clearMunicipalitySelect();
      }
    },
    clearCountrySelect() {
      this.selectedCountry = null;
      this.selectedCountryIndex = 0;
    },
    regionSelect({ item, index }) {
      if (this.selectedRegion?.value !== item?.value) {
        this.selectedRegion = item;
        this.selectedRegionIndex = index;
        this.clearMunicipalitySelect();
      }
    },
    clearRegionSelect() {
      this.selectedRegion = null;
      this.selectedRegionIndex = 0;
    },
    municipalitySelect({ item, index }) {
      if (this.selectedMunicipality?.value !== item?.value) {
        this.selectedMunicipality = item;
        this.selectedMunicipalityIndex = index;
      }
    },
    clearMunicipalitySelect() {
      this.selectedMunicipality = null;
      this.selectedMunicipalityIndex = 0;
    },
    save() {
      if (this.isUpdating) return;
      const payload = Object.fromEntries(keys.map((k) => [k, this[k]]));
      this.$store.dispatch(actionTypes.updateGeobase, payload);
    },
  },
};
</script>
