<!--Translated-->
<template>
  <article class="rightzone rightzone--usernanalyzeslist">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('UserAnalyzes:Title') }]" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('UserAnalyzes:ButtonNew')"
          size="wide"
          color="accent"
          icon="plus"
          @click="showModalChooseAnalyzeType = true"
        />
      </div>
    </div>

    <LoaderBlock v-if="isLoading" />

    <template v-else>
      <template v-if="analyzes.length > 0">
        <!--Фильтр-->
        <div class="cwrap marginbottom--standart">
          <div class="cblock el--rounded-all el--borders-all">
            <div class="filter filter--oneline">
              <div class="filter-inputszone">
                <div class="filterwrap">
                  <div class="filterwrap_line">
                    <DropdownSelectValue
                      type="dropdown"
                      :placeholder="
                        $t('UserAnalyzes:Filter:SelectType.Placeholder')
                      "
                      :label="$t('UserAnalyzes:Filter:SelectType.Label')"
                      icon=""
                      state=""
                      size="standart"
                      color=""
                      dd-max-elements="5"
                      :options="analyzesClientTypes ?? []"
                      v-model="filter.analyseTypeId"
                    />
                    <DropdownSelectValue
                      type="dropdown"
                      :placeholder="
                        $t('UserAnalyzes:Filter:SelectStatus.Placeholder')
                      "
                      :label="$t('UserAnalyzes:Filter:SelectStatus.Label')"
                      icon=""
                      state=""
                      size="long"
                      color=""
                      :dd-max-elements="7"
                      :options="[
                        {
                          text: $t('UserAnalyzes:Filter:SelectStatus.Option:0'),
                          value: 0,
                        },
                        {
                          text: $t('UserAnalyzes:Filter:SelectStatus.Option:1'),
                          value: 1,
                        },
                        {
                          text: $t('UserAnalyzes:Filter:SelectStatus.Option:2'),
                          value: 2,
                        },
                        {
                          text: $t('UserAnalyzes:Filter:SelectStatus.Option:3'),
                          value: 3,
                        },
                        {
                          text: $t('UserAnalyzes:Filter:SelectStatus.Option:4'),
                          value: 4,
                        },
                        {
                          text: $t('UserAnalyzes:Filter:SelectStatus.Option:5'),
                          value: 5,
                        },
                      ]"
                      v-model="filter.analyseStatus"
                    />
                  </div>
                </div>
              </div>
              <div class="filter-clearbtnzone">
                <Button
                  class="filter_buttonclear"
                  text=""
                  size="s-standart"
                  :color="isFilterActive ? 'accentlines' : 'graylines'"
                  icon="backspace"
                  :is-loading="false"
                  :loading-left="true"
                  @click="onClearFilter"
                />
              </div>
            </div>
          </div>
        </div>

        <!--Список анализов-->
        <div class="cwrap marginbottom--standart">
          <div class="cblock marginbottom--micro">
            <div
              class="useranalyzes_item useranalyzes_item--head el--borders-all el--rounded-all"
            >
              <div class="useranalyzes_item-type">
                <span>{{ $t('UserAnalyzes:TableHeader:Type') }}</span>
              </div>

              <div class="useranalyzes_item-wrap">
                <div class="useranalyzes_item-date">
                  <span>{{ $t('UserAnalyzes:TableHeader:Date') }}</span>
                </div>

                <div class="useranalyzes_item-status">
                  <span>{{ $t('UserAnalyzes:TableHeader:Status') }}</span>
                </div>

                <div class="useranalyzes_item-linkto">
                  <span>{{ $t('UserAnalyzes:TableHeader:Link') }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="cblock">
            <div
              v-for="analyse in filteredAnalyzes"
              :key="analyse.id"
              class="useranalyzes_item el--borders-all el--rounded-all marginbottom--micro"
              :class="{
                'useranalyzes_item--ready':
                  analyzesHelper.isAnalyseStatusDone(analyse),
              }"
            >
              <div v-if="analyse.type_id === 1" class="useranalyzes_item-type">
                <div class="vsvg" v-svg="{ name: 'analyzesicon_water' }"></div>
                <div class="chead_text">
                  {{ $t('UserAnalyzes:TableData:AnalyseType:1') }}
                </div>
              </div>
              <div v-if="analyse.type_id === 3" class="useranalyzes_item-type">
                <div
                  class="vsvg"
                  v-svg="{ name: 'analyzesicon_product' }"
                ></div>
                <div class="chead_text">
                  {{ $t('UserAnalyzes:TableData:AnalyseType:3') }}
                </div>
              </div>
              <div v-if="analyse.type_id === 0" class="useranalyzes_item-type">
                <div class="vsvg" v-svg="{ name: 'analyzesicon_soil' }"></div>
                <div class="chead_text">
                  {{ $t('UserAnalyzes:TableData:AnalyseType:0') }}
                </div>
              </div>
              <div v-if="analyse.type_id === 2" class="useranalyzes_item-type">
                <div class="vsvg" v-svg="{ name: 'analyzesicon_leaves' }"></div>
                <div class="chead_text">
                  {{ $t('UserAnalyzes:TableData:AnalyseType:2') }}
                </div>
              </div>

              <div class="useranalyzes_item-wrap">
                <div class="useranalyzes_item-date">
                  <span>{{
                    dateHelper.formatDate(analyse.date_create) ?? ''
                  }}</span>
                </div>

                <div class="useranalyzes_item-status">
                  <div>
                    {{
                      analyse.date_create
                        ? analyzesHelper.getAnalyseStatus(analyse)
                        : ''
                    }}
                  </div>
                </div>

                <div class="useranalyzes_item-linkto">
                  <div
                    v-if="analyzesHelper.isAnalyseStatusDone(analyse)"
                    class="vsvg"
                    v-svg="{ name: 'linkto' }"
                    @click="$router.push({ name: 'UserMyCultures' })"
                  ></div>
                  <div v-else class="vsvg" v-svg="{ name: 'waiting' }"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template>
        <div
          v-if="analyzes.length === 0"
          class="cboloc ifnotdatayet--textmsg margintop--standart marginbottom--standart"
        >
          <div class="ifnotdatayet--textmsg_text">
            {{ $t('UserAnalyzes:TableData:NoData') }}
          </div>
        </div>
        <!--Заглушка-->
        <div class="cwrap marginbottom--standart">
          <div class="cblock ifnotdatayet--useranalyses">
            <div class="el--borders-all el--rounded-all marginright--standart">
              <div>
                <img
                  alt="logo"
                  src="@/assets/userpanel/img/useranalyses/water.png"
                />
              </div>
              <p>{{ $t('UserAnalyzes:TableData:AnalyseType:1') }}</p>
              <Button
                :text="$t('UserAnalyzes:ButtonOrder')"
                color="accentlines"
                size="h-standart"
                @click="
                  $router.push({
                    name: 'UserAnalysesInstruction',
                    params: { id: '1' },
                  })
                "
              />
            </div>
            <div class="el--borders-all el--rounded-all marginright--standart">
              <div>
                <img
                  alt="logo"
                  src="@/assets/userpanel/img/useranalyses/soil.png"
                />
              </div>
              <p>{{ $t('UserAnalyzes:TableData:AnalyseType:0') }}</p>
              <Button
                :text="$t('UserAnalyzes:ButtonOrder')"
                color="accentlines"
                size="h-standart"
                @click="
                  $router.push({
                    name: 'UserAnalysesInstruction',
                    params: { id: '0' },
                  })
                "
              />
            </div>

            <div class="el--borders-all el--rounded-all marginright--standart">
              <div>
                <img
                  alt="logo"
                  src="@/assets/userpanel/img/useranalyses/leave.png"
                />
              </div>
              <p>{{ $t('UserAnalyzes:TableData:AnalyseType:2') }}</p>
              <Button
                :text="$t('UserAnalyzes:ButtonOrder')"
                color="accentlines"
                size="h-standart"
                @click="
                  $router.push({
                    name: 'UserAnalysesInstruction',
                    params: { id: '2' },
                  })
                "
              />
            </div>

            <div class="el--borders-all el--rounded-all">
              <div>
                <img
                  alt="logo"
                  src="@/assets/userpanel/img/useranalyses/product.png"
                />
              </div>
              <p>{{ $t('UserAnalyzes:TableData:AnalyseType:3') }}</p>
              <Button
                :text="$t('UserAnalyzes:ButtonOrder')"
                color="accentlines"
                size="h-standart"
                @click="
                  $router.push({
                    name: 'UserAnalysesInstruction',
                    params: { id: '3' },
                  })
                "
              />
            </div>
          </div>
        </div>
      </template>
    </template>

    <!--    Модальное при клике на овощ "Другое"-->
    <div class="modalbackground" v-if="showModalChooseAnalyzeType">
      <div class="modalbackgroundWrap">
        <UserModalChooseAnalyze @close="showModalChooseAnalyzeType = false" />
      </div>
    </div>
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import UserModalChooseAnalyze from '@/pages/userproject/userComponents/UserModalChooseAnalyze';
import { mapGetters } from 'vuex';
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';
import LoaderBlock from '@/components/blocks/LoaderBlock';
import * as dateHelper from '@/helpers/dateHelper';
import * as analyzesHelper from '@/helpers/analyzesHelper';

export default {
  name: 'UserAnalyzes',
  components: {
    LoaderBlock,
    DropdownSelectValue,
    UserModalChooseAnalyze,
    Input,
    Button,
    Breadcrumps,
  },
  data() {
    return {
      showModalChooseAnalyzeType: false,
      filter: {
        isActive: false,
        analyseTypeId: null,
        analyseStatus: null,
      },
      dateHelper: dateHelper,
      analyzesHelper: analyzesHelper,
    };
  },
  methods: {
    onClearFilter() {
      this.filter.analyseStatus = null;
      this.filter.analyseTypeId = null;
    },
  },
  computed: {
    ...mapGetters(['analyzesClientTypes']),
    isFilterActive() {
      return (
        this.filter.analyseStatus !== null || this.filter.analyseTypeId !== null
      );
    },
    isLoading() {
      return this.$store.state.clientAnalyzes.isAnalyzesLoading;
    },
    analyzes() {
      return this.$store.state.clientAnalyzes.analyzes;
    },
    filteredAnalyzes() {
      let filteredAnalyzes = this.analyzes;
      if (this.filter.isActive) {
        filteredAnalyzes = this.analyzes.filter((analyse) => {
          if (this.filter.analyseTypeId !== null) {
            if (analyse.type_id !== this.filter.analyseTypeId) {
              return false;
            }
          }
          if (this.filter.analyseStatus !== null) {
            if (
              analyzesHelper.getAnalyseStatusId(analyse) !==
              this.filter.analyseStatus
            ) {
              return false;
            }
          }
          return true;
        });
      }
      return filteredAnalyzes;
    },
  },
  watch: {
    isFilterActive() {
      this.filter.isActive = this.isFilterActive;
    },
  },
};
</script>

<style lang="scss" scoped></style>
