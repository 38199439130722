<!--Translated-->
<template>
  <div class="cwrap cwrap--total marginbottom--standart">
    <div class="cblock cblock--totalprice el--borders-all el--rounded-all">
      <div class="cblock__line">
        <div class="cblock__line-block">
          <span>{{ $t('ProgramTables:Sum') }} {{ calcMethodShort }} -</span>
          <span>₾ {{ totalCost }} </span>
        </div>
      </div>
    </div>
    <div v-if="pestsProductsMapped.length">
      <div
        class="chead el--borders-top el--borders-sides el--rounded-top margintop--standart"
      >
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'badobject' }"
          ></div>
          <div class="chead_text">
            {{ $t('ProgramTables:ListPests:Title') }}
          </div>
        </div>
      </div>

      <div class="cblock el--rounded-bottom">
        <table class="table--type1 table--withhead table--paycheck">
          <thead>
            <tr>
              <th class="tbl-cellproduct">
                {{ $t('ProgramTables:ListPests:Name') }}
              </th>
              <th class="col">{{ $t('ProgramTables:ListPests:Pack') }}</th>
              <th class="col" v-if="isWater">
                {{ $t('ProgramTables:ListPests:Volume1') }}
                {{ calcMethodShort }}
                {{ $t('ProgramTables:ListPests:Volume2') }}
              </th>
              <th class="col">
                {{ $t('ProgramTables:ListPests:Volume3') }} 1
                {{ $t('ProgramTables:ListPests:Volume4') }}
              </th>
              <th class="col">
                {{ $t('ProgramTables:ListPests:Volume3') }} {{ area }}
                {{ $t('ProgramTables:ListPests:Volume4') }}
              </th>
              <th class="col">{{ $t('ProgramTables:ListPests:Count') }}</th>
              <th class="col">{{ $t('ProgramTables:ListPests:Avg') }}</th>
            </tr>
          </thead>

          <tbody>
            <tr class="row" v-for="row in pestsProductsMapped" :key="row.id">
              <td>{{ row.product.name }}</td>
              <td>{{ row.product.picking }}</td>
              <td v-if="isWater">{{ formatNumber(row.quantity) }}</td>
              <td>{{ formatNumber(row.totalVolume) }}</td>
              <td>{{ formatNumber(row.forArea) }}</td>
              <td>{{ formatNumber(row.packages) }}</td>
              <td>{{ row.packagesInt.toLocaleString('ru-RU') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="stimulatorProductsMapped.length">
      <div
        class="chead el--borders-top el--borders-sides el--rounded-top margintop--standart"
      >
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'stimulator' }"
          ></div>
          <div class="chead_text">
            {{ $t('ProgramTables:ListStimulations:Title') }}
          </div>
        </div>
      </div>

      <div class="cblock el--rounded-bottom">
        <table class="table--type1 table--withhead table--paycheck">
          <thead>
            <tr>
              <th class="tbl-cellproduct">
                {{ $t('ProgramTables:ListStimulations:Name') }}
              </th>
              <th class="col">
                {{ $t('ProgramTables:ListStimulations:Pack') }}
              </th>
              <th class="col" v-if="isWater">
                {{ $t('ProgramTables:ListPests:Volume1') }}
                {{ calcMethodShort }}
                <!--fixme-->
                {{ $t('ProgramTables:ListPests:Volume2') }}
              </th>
              <th class="col">
                {{ $t('ProgramTables:ListPests:Volume3') }} 1
                {{ $t('ProgramTables:ListPests:Volume4') }}
              </th>
              <th class="col">
                {{ $t('ProgramTables:ListPests:Volume3') }} {{ area }}
                {{ $t('ProgramTables:ListPests:Volume4') }}
              </th>
              <th class="col">
                {{ $t('ProgramTables:ListStimulations:Count') }}
              </th>
              <th class="col">
                {{ $t('ProgramTables:ListStimulations:Avg') }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              class="row"
              v-for="row in stimulatorProductsMapped"
              :key="row.id"
            >
              <td>{{ row.product.name }}</td>
              <td>{{ row.product.picking }}</td>
              <td v-if="isWater">{{ formatNumber(row.quantity) }}</td>
              <td>{{ formatNumber(row.totalVolume) }}</td>
              <td>{{ formatNumber(row.forArea) }}</td>
              <td>{{ formatNumber(row.packages) }}</td>
              <td>{{ row.packagesInt.toLocaleString('ru-RU') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="cwrap">
      <div
        class="chead el--borders-top el--borders-sides el--rounded-top margintop--standart"
      >
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'paycheck' }"
          ></div>
          <div class="chead_text">
            {{ $t('ProgramTables:FooterTable:Header') }}
          </div>
        </div>
      </div>

      <div
        class="cblock cblock--analizetable el--rounded-bottom el--borders-sides el--borders-bottom"
      >
        <div class="cblock__internal">
          <div class="cblock__line paycheckfinal">
            <div class="paycheckfinal_block paycheckfinal_block--dates">
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('ProgramTables:FooterTable:DateCreate') }}
                </div>
                <div class="paycheckfinal_block-textdata">{{ createdAt }}</div>
              </div>
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('ProgramTables:FooterTable:DateEdit') }}
                </div>
                <div class="paycheckfinal_block-textdata">{{ updatedAt }}</div>
              </div>
            </div>

            <div class="el--decorlinevertical"></div>
            <div class="paycheckfinal_block">
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('ProgramTables:FooterTable:SumDollar') }}
                </div>
                <div class="paycheckfinal_block-textdata">
                  {{
                    Number.isFinite(totalPriceUSD)
                      ? '$ ' + formatNumber(totalPriceUSD)
                      : '-'
                  }}
                </div>
              </div>
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('ProgramTables:FooterTable:SumEuro') }}
                </div>
                <div class="paycheckfinal_block-textdata">
                  {{
                    Number.isFinite(totalPriceEU)
                      ? '€ ' + formatNumber(totalPriceEU)
                      : '-'
                  }}
                </div>
              </div>
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('ProgramTables:FooterTable:SumGel') }}
                </div>
                <div
                  class="paycheckfinal_block-textdata"
                  v-if="isFinite(totalPrice)"
                >
                  {{
                    Number.isFinite(totalPrice)
                      ? '₾ ' + formatNumber(totalPrice)
                      : '-'
                  }}
                </div>
              </div>
            </div>

            <div class="el--decorlinevertical"></div>
            <div class="paycheckfinal_block paycheckfinal_block--finalprice">
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('ProgramTables:FooterTable:Discount') }}
                </div>
                <div class="paycheckfinal_block-textdata">
                  {{ program.client_discount || 0 }}%
                </div>
              </div>
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('ProgramTables:FooterTable:DiscountSum') }}
                </div>
                <div class="paycheckfinal_block-textdata">
                  ₾
                  {{
                    formatNumber(
                      (totalPrice / 100) * (program.client_discount || 0)
                    )
                  }}
                </div>
              </div>
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('ProgramTables:FooterTable:SumFinal') }}
                </div>
                <div class="paycheckfinal_block-textdata">
                  ₾
                  {{
                    formatNumber(
                      totalPrice -
                        (totalPrice / 100) * (program.client_discount || 0)
                    )
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="el__decorline margintop--standart"></div>
          <div class="cblock__line">
            <div class="cblock__line-block">
              <InputNumber
                placeholder="0.0"
                label="Курс доллара"
                size="mini"
                v-model="exchangeUSD"
                :state="inputsState"
              />
              <InputNumber
                placeholder="0.0"
                label="Курс евро"
                size="mini"
                v-model="exchangeEU"
                :state="inputsState"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { mapState, mapGetters } from 'vuex';
import InputNumber from '@/components/views/wrappers/InputNumberWithCast';
import { mutationTypes } from '@/store/modules/programs';
import { staticData } from '@/store/modules/plantsCare';

export default {
  name: 'ProgramTables',
  components: { InputNumber },
  computed: {
    ...mapGetters(['plantsCareProductsAsMap', 'programPricesMap']),
    ...mapState({
      canEdit: (state) =>
        state.auth.currentUserRole?.claim_plants_care_add === 1,
      isSaving: (state) => state.programs.isSaving,
      program: (state) => state.programs.program,
      products: (state) => state.plantsCare.products,
      calcMethod: (state) => state.programs.program.calculation_method_id,
      area: (state) => state.programs.program.place_area,
      phases: (state) => state.programs.programPhases,
      pestsProducts: (state) => state.programs.programPestsProducts,
      stimulatorProducts: (statte) =>
        statte.programs.programTypesStimulationProducts,
      createdAt(state) {
        return state.programs.program.date_create
          ? format(
              new Date(state.programs.program.date_create),
              'dd.MM.yyyy HH:MM'
            )
          : this.$t('ProgramTables:Data:NotSaved');
      },
      updatedAt: (state) =>
        state.programs.program.date_update
          ? format(
              new Date(state.programs.program.date_update),
              'dd.MM.yyyy HH:MM'
            )
          : '-',
    }),
    isWater() {
      return this.calcMethod < 3;
    },
    calcMethodShort() {
      return staticData.calculationMethodsShort[this.calcMethod];
    },
    totalCost() {
      return this.formatNumber(
        Object.values(this.programPricesMap).reduce((a, b) => a + b, 0)
      );
    },
    pestsProductsMapped() {
      return this.mapRows(this.pestsProducts);
    },
    stimulatorProductsMapped() {
      return this.mapRows(this.stimulatorProducts, false);
    },
    totalPrice() {
      return [
        ...this.pestsProductsMapped,
        ...this.stimulatorProductsMapped,
      ].reduce((a, b) => a + b.totalPrice, 0);
    },
    exchangeUSD: {
      get() {
        return this.program.rate_dollar;
      },
      set(rate) {
        this.$store.commit(mutationTypes.setRateUSD, rate);
      },
    },
    exchangeEU: {
      get() {
        return this.program.rate_euro;
      },
      set(rate) {
        this.$store.commit(mutationTypes.setRateEU, rate);
      },
    },
    totalPriceUSD() {
      return this.totalPrice / this.exchangeUSD;
    },
    totalPriceEU() {
      return this.totalPrice / this.exchangeEU;
    },
    inputsState() {
      return this.isSaving ? 'disabled' : this.canEdit ? '' : 'disabled2';
    },
  },
  methods: {
    formatNumber(num) {
      return num.toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    round(num) {
      const result = Math.round(num);
      return result === 0 && num > 0 ? 1 : result;
    },
    mapRows(list, forPests = true) {
      const result = [];
      for (const phase of this.phases) {
        const products = list.filter(
          (pp) =>
            pp.program_phase_id === phase.id &&
            pp.is_selected &&
            pp.quantity > 0
        );
        for (const objPropuct of products) {
          let product = result.find((p) => p.id === objPropuct.product_id);
          if (!product) {
            product = { id: objPropuct.product_id, quantity: 0 };
            result.push(product);
          }
          product.quantity += objPropuct.quantity;
        }
      }
      return result
        .map((p) => ({
          ...p,
          product: this.plantsCareProductsAsMap[p.id],
        }))
        .map((e) => this.calc(e, forPests))
        .filter(({ packagesInt }) => Number.isSafeInteger(packagesInt));
    },
    calc(input, forPests) {
      let totalVolume = input.quantity;
      if (this.isWater) {
        totalVolume = this.phases.reduce((acc, cur) => {
          let q = 0;
          const list = forPests ? this.pestsProducts : this.stimulatorProducts;
          const products = list.filter(
            (pp) =>
              pp.program_phase_id === cur.id &&
              pp.product_id === input.id &&
              pp.is_selected === 1
          );
          for (const product of products) {
            const divider = this.calcMethod === 2 ? 1000 : 100;
            q += (product.quantity * cur.volume_per_ha) / divider;
          }
          return acc + q;
        }, 0);
      }

      const forArea = totalVolume * this.area;
      const packages = forArea / input.product.picking;
      const packagesInt = this.round(packages);
      return {
        ...input,
        totalVolume,
        forArea,
        packages,
        packagesInt,
        totalPrice: packagesInt * input.product.price,
      };
    },
  },
};
</script>
