<!--Translated-->
<template>
  <div ref="tooltip" :style="`opacity: ${visible ? 1 : 0};`">
    <div v-if="visible" class="tablepreview">
      <div class="tablepreview_imgslider" v-if="images && images.length">
        <div class="slider-imgzone">
          <img style="height: 100%; width: 100%" :src="currentImage" />
        </div>
        <div class="slider-control">
          <Button
            size="s-micro"
            icon="chevronleft"
            color="transpgray"
            :is-loading="false"
            :loading-left="true"
            @click="goPrev"
            :disabled="images.length < 2"
          />
          <div class="slider-control_counter">
            {{ counterString }}
          </div>
          <Button
            size="s-micro"
            icon="chevronright"
            color="transpgray"
            :is-loading="false"
            :loading-left="true"
            @click="goNext"
            :disabled="images.length < 2"
          />
        </div>
      </div>
      <div class="tablepreview_textblock">
        <h4 v-if="header">{{ header }}</h4>
        <p v-if="text">{{ text }}</p>
      </div>
      <div class="tablepreview_linkbutton" v-if="link">
        <a target="_blank" :href="link">{{ $t('HoverTooltip:Details') }}...</a>
      </div>
    </div>
  </div>
</template>

<script>
import { serverUrl } from '@/api/axios.js';
import Button from '@/components/views/Button';

export default {
  name: 'ProgramsHoverToolpit',
  props: {
    header: { type: String },
    text: { type: String },
    link: { type: String },
    images: { type: Array },
  },
  components: { Button },
  data() {
    return {
      visible: false,
      imageIndex: 0,
    };
  },
  computed: {
    imagesMapped() {
      return this.images.map((img) => ({
        ...img,
        url: `${serverUrl}${img.name}`.replace('uploads/', 'uploads/thumbs/'),
      }));
    },
    currentImage() {
      return this.imagesMapped[this.imageIndex].url;
    },
    counterString() {
      return this.images.length === 1
        ? '1'
        : `${this.imageIndex + 1}/${this.images.length}`;
    },
  },
  methods: {
    goPrev() {
      let newIndex = this.imageIndex - 1;
      if (newIndex < 0) newIndex = this.images.length - 1;
      this.imageIndex = newIndex;
    },
    goNext() {
      let newIndex = this.imageIndex + 1;
      if (newIndex > this.images.length - 1) newIndex = 0;
      this.imageIndex = newIndex;
    },
  },
  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      this.visible = entries[0].isIntersecting;
    });
    this.observer.observe(this.$refs.tooltip);
  },
  beforeDestroy() {
    this.observer?.unobserve(this.$refs.tooltip);
  },
};
</script>
