const getStorage = (forceLS = false) => {
  return forceLS
    ? localStorage
    : JSON.parse(localStorage.getItem('useLS')) ?? false
    ? localStorage
    : sessionStorage;
};

export const clearStorage = (full = false) => {
  let useLS = getItem('useLS', true);
  localStorage.clear();
  sessionStorage.clear();
  if (!full) setItem('useLS', useLS, true);
};

export const setStorage = (useLS) => {
  setItem('useLS', useLS, true);
};

export const getItem = (key, forceLs = false) => {
  try {
    return JSON.parse(getStorage(forceLs).getItem(key));
  } catch (e) {
    return null;
  }
};

export const setItem = (key, data, forceLs = false) => {
  try {
    getStorage(forceLs).setItem(key, JSON.stringify(data));
  } catch (e) {
    console.error('Error in setting data to localStorage', e);
  }
};
