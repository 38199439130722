import axios from '@/api/axios';

export const getUsers = () => {
  return axios.get('/admin/getUsers');
};
export const getNewUsersData = () => {
  return axios.get('/admin/getNewUsersData');
};
export const updateUser = (payload) => {
  return axios.post('/admin/updateUser', { userData: payload });
};
export const getEditUserData = (payload) => {
  return axios.get('/admin/getEditUserData', { params: { id: payload } });
};
export const getRoles = () => {
  return axios.get('/admin/getRoles');
};
export const updateRole = (payload) => {
  return axios.post('/admin/updateRole', { roleData: payload });
};
export const updateCurrentUser = (payload) => {
  return axios.post('/admin/updateCurrentUser', { userData: payload });
};
export const getBanners = () =>
  axios.get('/banners/getBanners').then((r) => r.data);

export const updateBanner = (payload, file) => {
  const formData = new FormData();
  if (file) formData.append('images', file);
  formData.append('banner', JSON.stringify(payload));
  return axios
    .post('/banners/updateBanner', formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((r) => r.data);
};

/*
export default {
    getUsers
}*/
