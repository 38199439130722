<-- Translated -->
<template>
  <!--УНИ ВЫЙТИ БЕЗ СОХРАНЕНИЯ-->
  <div class="modal">
    <div class="modalwrap2-topline">
      <div class="topline-minizone"></div>

      <Breadcrumps :data="[{ title: breadCrumpsTitle }]" />

      <div class="topline-minizone">
        <Button
          text=""
          size="s-standart"
          color="accentlightlines"
          icon="x"
          :is-loading="false"
          :loading-left="true"
          @click="$emit('input', false)"
        />
      </div>
    </div>

    <div class="modalwrap2-content">
      <div v-if="contacts" class="modalwrap3-contactlist margintop--standart">
        <div
          v-for="(contact, index) in contacts"
          :key="index"
          class="contactlist_line"
        >
          <div class="contactlist_iconzone">
            <div v-if="contact.icon" v-svg="{ name: contact.icon }"></div>
          </div>
          <div class="contactlist_textzone">
            <a>{{ contact.value }}</a>
            <div v-if="contact.comment">{{ contact.comment }}</div>
          </div>
        </div>
      </div>
      <!-- Тут если нету контактов!!! -->
      <div v-else class="modalwrap3-nocontacts" />
    </div>
  </div>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';

export default {
  name: 'ModalContacts',
  components: { Button, Breadcrumps },
  props: {
    value: { default: false },
    user: {
      default: {},
    },
  },
  computed: {
    breadCrumpsTitle() {
      //console.log(this.user)
      if (this.user && this.user.username) {
        return this.$t('ModalContacts:Title') + ' ' + this.user.username;
      }
      return this.$t('ModalContacts:Title');
    },
    contacts() {
      let arr = [];
      if (this.user && this.user.contacts) {
        this.user.contacts.forEach((value) => {
          arr.push({
            value: value.value ? value.value : '',
            icon: value.contact_type?.icon ? value.contact_type.icon : '',
            comment: value.comment ? value.comment : '',
          });
        });
      }
      return arr;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
