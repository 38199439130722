<!--Translated-->
<template>
  <Modal @backgroundClose="$emit('close')">
    <div class="modal modal--long modal--chooseplace">
      <div class="modalwrap2-topline">
        <div class="topline-minizone">
          <Button
            v-if="showClientLink"
            :text="$t('ModalChoosePlace:ButtonClient')"
            size="s-standart"
            color="graylines"
            icon="user"
            @click="$emit('goToClient')"
          />
        </div>
        <Breadcrumps :data="[{ title: $t('ModalChoosePlace:Title') }]" />
        <div class="topline-minizone">
          <Button
            size="s-standart"
            color="accentlightlines"
            icon="x"
            @click="$emit('close')"
          />
        </div>
      </div>

      <div class="modalwrap2-content">
        <div class="modalwrap3-scrollbox margintop--micro">
          <div class="chooseplace">
            <div
              class="chooseplace-block"
              v-for="option in options"
              :key="option.id"
            >
              <!--инфа о культуре-->
              <div class="chooseplace-cultureline">
                <div class="chooseplace-cultureline_culturename">
                  <div v-svg="{ name: 'agriculture' }"></div>
                  <span> {{ option.cultureName }}</span>
                </div>
                <div class="chooseplace-cultureline_culturedata flex-between">
                  <div v-if="option.age" class="marginleft--micro">
                    <span>{{ $t('ModalChoosePlace:Age') }} </span>
                    <span>{{ option.age > 10 ? '11+' : option.age }}</span>
                  </div>
                  <div v-if="option.area" class="marginleft--micro">
                    <span>{{ $t('ModalChoosePlace:Area') }} </span>
                    <span
                      >{{ option.area }} {{ $t('ModalChoosePlace:Ha') }}</span
                    >
                  </div>
                </div>
              </div>

              <div class="chooseplace-place">
                <div class="chooseplace-placeline">
                  <span v-if="option.geoChain[3]">{{
                    option.geoChain[3]
                  }}</span>
                  <span v-if="option.geoChain[3]"> / </span>
                  <span v-if="option.geoChain[2]">
                    {{ option.geoChain[2] }}</span
                  >
                  <span v-if="option.geoChain[2]"> / </span>
                  <span v-if="option.geoChain[1]">{{
                    option.geoChain[1]
                  }}</span>
                  <span v-if="option.geoChain[1]"> / </span>
                  <span v-if="option.geoChain[0]">{{
                    option.geoChain[0]
                  }}</span>
                </div>

                <div class="chooseplace-zoneslist">
                  <!--Первая зона = "все зоны", только для защиты и только если больше 1 зоны,
                  для фертилизера ее не будет, т.к. там нужна зона с анализом-->
                  <div class="chooseplace-zoneline">
                    <div class="chooseplace-zoneline_zonename">
                      <span>{{ $t('ModalChoosePlace:SelectAllZones') }}</span>
                    </div>
                    <Button
                      :text="$t('ModalChoosePlace:SelectButton')"
                      size="standart"
                      color="accent"
                      @click="select({ ...option, zone: null })"
                    />
                  </div>
                  <div
                    class="chooseplace-zoneline"
                    v-for="(zone, index) in option.zones"
                    :key="index"
                  >
                    <div class="chooseplace-zoneline_zonename">
                      <span
                        >{{ $t('ModalChoosePlace:Zone') }} {{ index + 1 }} -
                      </span>
                      <span>
                        {{ zone.name || $t('ModalChoosePlace:ZoneNoName') }}
                      </span>
                      <span v-if="showAnalyses"
                        >({{ makeZoneString(zone) }})
                      </span>
                    </div>
                    <Button
                      :text="$t('ModalChoosePlace:SelectButton')"
                      size="standart"
                      color="accent"
                      @click="select({ ...option, zone })"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';

import Modal from '@/pages/Modal';
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';

export default {
  name: 'ModalChoosePlace',
  components: {
    Button,
    Breadcrumps,
    Modal,
  },
  props: {
    clientPlaces: { type: Array, required: true },
    clientCultures: { type: Array, required: true },
    clientZones: { type: Array, required: true },
    cultures: { type: Array, required: true },
    clientZonesAnalyzes: { type: Array, required: false },
    showClientLink: { type: Boolean, default: true },
  },
  computed: {
    ...mapGetters(['geoPlainDataAsMap']),
    options() {
      const options = [];
      for (const culture of this.clientCultures) {
        const cultureData = this.cultures.find(
          (c) => c.id === culture.culture_id
        );
        if (!cultureData) continue;

        const places = this.clientPlaces.filter(
          (place) => place.client_culture_id === culture.id
        );

        for (const place of places) {
          if (!place.geo_id) continue;
          const entry = {
            id: '_p' + place.id,
            cultureId: cultureData.id,
            cultureName: cultureData.name,
            geoId: place.geo_id,
            area: place.area,
            plantsPerHa: place.count_plants_per_ha,
            age: place.year_of_boarding
              ? new Date().getUTCFullYear() - place.year_of_boarding + 1
              : null,
            geoChain: this.geoPlainDataAsMap
              .get(place.geo_id)
              .text.split(' / ')
              .reverse(),
            placeText: this.geoPlainDataAsMap
              .get(place.geo_id)
              .text.split(' / ')
              .reverse()
              .join(' / '),
            zones: this.clientZones
              .filter((zone) => zone.place_id === place.id)
              .map((z) => {
                const zone = { id: z.id, name: z.name };
                if (this.showAnalyses) {
                  const analyses = this.clientZonesAnalyzes.filter(
                    (a) => a.zone_id === z.id
                  );
                  zone.lastAnalysisDate = analyses.sort(
                    (a, b) => b.date - a.date
                  )[0]?.date;
                  if (analyses.length > 0 && !zone.lastAnalysisDate) {
                    zone.lastAnalysisDate = 'not specified';
                  }
                }
                return zone;
              }),
          };

          options.push(entry);
        }
      }
      return options;
    },
    showAnalyses() {
      return Array.isArray(this.clientZonesAnalyzes);
    },
  },
  methods: {
    select(val) {
      this.$emit('selected', val);
      this.$emit('close');
    },
    makeZoneString(zone) {
      return zone.lastAnalysisDate
        ? this.$t('ModalChoosePlace:LastAnalyse') +
            ' ' +
            (zone.lastAnalysisDate === 'not specified'
              ? this.$t('ModalChoosePlace:NoDate')
              : new Date(zone.lastAnalysisDate).toLocaleDateString('ru-RU'))
        : this.$t('ModalChoosePlace:NoAnalyzes');
    },
  },
};
</script>
