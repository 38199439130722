<!-- Translated -->
<template>
  <div class="cwrap cwrap--level1 marginbottom--standart">
    <div class="chead el--borders-top el--borders-sides el--rounded-top">
      <div class="chead__line-block">
        <div
          class="vsvg vsvg--standart chead_icon"
          v-svg="{ name: 'edit' }"
        ></div>
        <div class="chead_text">{{ $t('ClientDataForm:HeaderText') }}</div>
      </div>
    </div>

    <div
      class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
    >
      <div class="cblock__internal">
        <div class="cblock__line">
          <div class="cblock__line-block">
            <Input
              type="text"
              :placeholder="$t('ClientDataForm:Id.Placeholder')"
              :label="$t('ClientDataForm:Id.Label')"
              v-model="code"
              :state="inputsState"
            />

            <ClientSearchButton v-if="!isEditing" />

            <template v-else>
              <Input
                :class="{ 'inptwrap--onlyshow': !isLoading }"
                state="disabled"
                type="text"
                :label="$t('ClientDataForm:DateUpdate.Label')"
                :value="updatedAt"
              />
              <ClientUpdateButton :id="client.id" v-if="!readonly" />
            </template>
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <Input
              type="text"
              :placeholder="$t('ClientDataForm:Name.Placeholder')"
              :label="$t('ClientDataForm:Name.Label')"
              size="long"
              v-model="name"
              :state="inputsLockedState"
            />
            <Input
              v-if="isEditing"
              type="text"
              :label="$t('UserSettings:Login.Label')"
              size="standart"
              :value="client?.login"
              :state="disabled ? 'disabled' : 'disabled2'"
            />
            <!-- TODO: check why input not updating -->
            <template v-if="isEditing && !readonly && client?.login">
              <Input
                v-if="isShowPassword"
                type="password"
                :placeholder="$t('UsersAddEdit:Password.Placeholder')"
                :label="$t('UsersAddEdit:Password.Label')"
                size="standart"
                v-model="password"
                :state="disabled ? 'disabled' : ''"
              />
              <Button
                v-else
                :text="$t('UsersAddEdit:ButtonResetPassword')"
                size="wide"
                color="graylines"
                icon="key"
                :is-loading="false"
                :loading-left="false"
                :disabled="disabled"
                @click="isShowPassword = true"
              />
            </template>
          </div>
        </div>

        <div class="cblock__line">
          <LocationSelect
            ref="locationSelect"
            :geoEntries="geoMapped"
            v-model="geoId"
            :free-select="true"
            :disabled="locked"
            :locked="readonly"
            @chainChanged="placesChainChanged"
          />
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <Input
              class="inputsize--long3x"
              type="text"
              :placeholder="$t('ClientDataForm:Address.Placeholder')"
              :label="$t('ClientDataForm:Address.Label')"
              size="long"
              v-model="address"
              :state="inputsLockedState"
            />
            <DropdownSelect
              :placeholder="$t('ClientDataForm:Status.Placeholder')"
              :label="$t('ClientDataForm:Status.Label')"
              size="s-standart"
              :options="statuses"
              v-model="status"
              :disabled="locked"
              :locked="readonly"
            />

            <DropdownSelect
              :placeholder="$t('ClientDataForm:Type.Placeholder')"
              :label="$t('ClientDataForm:Type.Label')"
              size="s-standart"
              :options="types"
              v-model="type"
              :disabled="locked"
              :locked="readonly"
            />
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <InputNumber
              size="standart"
              :placeholder="$t('ClientDataForm:Limit.Placeholder')"
              :label="$t('ClientDataForm:Limit.Label')"
              v-model="creditLimit"
              :disabled="locked"
              :state="locked ? 'disabled' : ''"
              :locked="readonly"
            />
            <!-- TODO translate me -->
            <InputNumber
              size="standart"
              :placeholder="$t('ClientDataForm:CreditDataText')"
              :label="$t('ClientDataForm:CreditDataText')"
              :value="totalCredit"
              :disabled="locked"
              :state="locked ? 'disabled' : ''"
              :locked="true"
            />
            <!-- TODO translate me -->
            <InputNumber
              size="standart"
              :placeholder="$t('ClientDataForm:CreditCalc')"
              :label="$t('ClientDataForm:CreditCalc')"
              :value="creditLeft"
              :disabled="locked"
              :state="locked ? 'disabled' : ''"
              :locked="true"
            />

            <Input
              type="text"
              :placeholder="$t('ClientDataForm:Discount.Placeholder')"
              :label="$t('ClientDataForm:Discount.Label')"
              size="micro"
              :state="locked ? 'disabled' : 'disabled2'"
              :value="discount"
            />
          </div>
        </div>
        <div class="cblock__line">
          <div class="cblock__line-block">
            <Input
              type="textarea"
              :placeholder="$t('ClientDataForm:Comment.Placeholder')"
              :label="$t('ClientDataForm:Comment.Label')"
              size="long3x"
              v-model="comment"
              :state="inputsLockedState"
            />
          </div>
          <div class="cblock__line-block flex-end">
            <Button
              v-if="!readonly"
              class="filter_buttonclear"
              text="очистить"
              size="s-standart"
              icon="backspace"
              :color="canReset ? 'accentlines' : 'graylines'"
              :is-loading="false"
              :loading-left="true"
              :disabled="locked"
              @click="reset"
            />
          </div>
        </div>

        <!-- <div class="cblock__line">
          <div class="cblock__line-block">
            <RadioToggle
              type="disabled"
              :text1="$t('ClientDataForm:Active.Text1')"
              :text2="$t('ClientDataForm:Active.Text2')"
              :label="$t('ClientDataForm:Active.Label')"
              :reverse="true"
              :disabled="locked"
              :locked="locked ? false : readonly"
              v-model="isActive"
            />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mutationTypes, staticData } from '@/store/modules/clients';

import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
// import RadioToggle from '@/components/views/RadioToggle';
import DropdownSelect from '@/components/views/wrappers/DropdownSelect';
import InputNumber from '@/components/views/wrappers/InputNumberWithCast';
import LocationSelect from '@/components/views/LocationSelect';
import ClientSearchButton from './ClientSearchButton';
import ClientUpdateButton from './ClientUpdateButton';

export default {
  name: 'ClientDataForm',
  components: {
    Input,
    Button,
    // RadioToggle,
    DropdownSelect,
    InputNumber,
    LocationSelect,
    ClientSearchButton,
    ClientUpdateButton,
  },
  props: {
    isEditing: { type: Boolean, required: true },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return { chainChanged: false, isShowPassword: false };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.clients.isLoading,
      isSearching: (state) => state.clients.isSearching,
      client: (state) => state.clients.client,
      storePassword: (state) => state.clients.password,
      usedSearch(state) {
        return this.isEditing || state.clients.usedSearch;
      },
    }),
    ...mapGetters(['geoMapped', 'clientFormWasEdited']),
    disabled() {
      return this.isLoading || this.isSearching;
    },
    locked() {
      return this.disabled || !this.usedSearch;
    },
    inputsState() {
      return this.disabled ? 'disabled' : this.readonly ? 'disabled2' : '';
    },
    inputsLockedState() {
      return this.locked ? 'disabled' : this.readonly ? 'disabled2' : '';
    },
    discount() {
      return `${this.client.discount ?? 0}%`;
    },
    password: {
      get() {
        return this.storePassword;
      },
      set(password) {
        this.$store.commit(mutationTypes.setPassword, password);
      },
    },
    geoId: {
      get() {
        return this.client.geo_id;
      },
      set(id) {
        this.$store.commit(mutationTypes.setGeoId, id);
      },
    },
    code: {
      get() {
        return this.client.code;
      },
      set(code) {
        this.$store.commit(mutationTypes.setCode, code);
      },
    },
    name: {
      get() {
        return this.client.name;
      },
      set(name) {
        this.$store.commit(mutationTypes.setName, name);
      },
    },
    address: {
      get() {
        return this.client.address;
      },
      set(address) {
        this.$store.commit(mutationTypes.setAddress, address);
      },
    },
    types() {
      return staticData.clientType.map((text, value) => ({ text, value }));
    },
    type: {
      get() {
        return this.types.find((e) => e.value === this.client.type_id) ?? null;
      },
      set(type) {
        this.$store.commit(mutationTypes.setType, type?.value ?? null);
      },
    },
    statuses() {
      return staticData.clientStatus.map((text, value) => ({ text, value }));
    },
    status: {
      get() {
        return (
          this.statuses.find((e) => e.value === this.client.status_id) ?? null
        );
      },
      set(status) {
        this.$store.commit(mutationTypes.setStatus, status?.value ?? null);
      },
    },
    creditLimit: {
      get() {
        return this.client.credit_limit;
      },
      set(value) {
        this.$store.commit(mutationTypes.setCreditLimit, value);
      },
    },
    totalCredit() {
      return this.client.credit ?? 0;
    },
    creditLeft() {
      return (this.creditLimit ?? 0) - this.totalCredit;
    },
    comment: {
      get() {
        return this.client.comment;
      },
      set(comment) {
        this.$store.commit(mutationTypes.setComment, comment);
      },
    },
    isActive: {
      get() {
        return this.client.is_active;
      },
      set(isActive) {
        this.$store.commit(mutationTypes.setIsActive, isActive);
      },
    },
    updatedAt() {
      return this.client?.update_date
        ? new Date(this.client.update_date).toLocaleString('ru-RU')
        : '';
    },
    canReset() {
      return this.clientFormWasEdited || this.chainChanged;
    },
  },
  methods: {
    reset() {
      this.$store.commit(mutationTypes.resetClient);
      if (!this.isEditing) this.$refs.locationSelect.reset();
    },
    placesChainChanged(chain) {
      this.chainChanged = !this.isEditing && chain !== '- / - / - / -';
    },
  },
};
</script>
