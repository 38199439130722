<-- Translated -->
<template>
  <div class="analyzes-list tablewrap tablewrap--analysiselements">
    <table class="table--type1 table--analisys margintop--standart">
      <thead>
        <tr>
          <th style="width: 10%">
            {{ $t('ClientZoneAnalysesTotal:TableHeader') }}
          </th>
          <th v-for="el in elements" :key="el.id">
            {{ el.name }}
          </th>
        </tr>
      </thead>

      <tbody>
        <template v-for="data in tables">
          <tr class="test" :key="data.id">
            <td style="" class="cell-element-modalanalyzestable">
              {{ data.header }}
            </td>
            <td
              v-for="(unit, unitIndex) in data.units"
              :key="unitIndex"
              style=""
              class="cell-element-modalanalyzestable"
            >
              {{ getUnitsText(unitIndex, unit) }}
            </td>
          </tr>
          <tr v-for="(row, i) in data.rows" :key="data.id + i">
            <td v-for="(td, i2) in row" :key="data.id + i + i2">
              {{ td }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { units } from '@/pages/programs/elementMeasureUnits.js';

export default {
  name: 'ClientZoneAnalysisTotal',
  props: { analyses: { type: Array, required: true } },
  computed: {
    ...mapState({
      elements: (state) => state.clients.elements,
      clientElements: (state) => state.clients.clientAnalyzesElements,
    }),
    tables() {
      return this.analyses.map(({ id, date }) => ({
        id: String(id),
        header: date ? new Date(date).toLocaleDateString('ru-RU') : '…',
        units: this.elements.map(
          (el) =>
            this.clientElements.find(
              (e) => e.analyse_id === id && e.element_id === el.id
            )?.units_id ?? ''
        ),
        rows: [...Array(2)].map((_, depth) => [
          `${this.$t('ClientZoneAnalysesTotal:TableHeader')} ${depth + 1}`,
          ...this.elements.map(
            (el) =>
              this.clientElements.find(
                (e) =>
                  e.analyse_id === id &&
                  e.depth === depth &&
                  e.element_id === el.id
              )?.quantity ?? ''
          ),
        ]),
      }));
    },
  },
  methods: {
    getUnitsText(index, id) {
      // pH
      if (index === 12) return '';
      // EC
      if (index === 13) return id === 1 ? 'μS/cm' : 'ms/cm';
      return units.find((u) => u.id === id)?.text;
    },
  },
};
</script>
