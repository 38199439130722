var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{on:{"backgroundClose":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"modal modal--long"},[_c('div',{staticClass:"modalwrap2-topline"},[_c('div',{staticClass:"topline-minizone"}),_c('Breadcrumps',{attrs:{"data":[
          {
            title: _vm.editingId
              ? _vm.$t('GlossaryLinkedPestsModal:TitleEdit')
              : _vm.headerAdd || this.$t('GlossaryLinkedPestsModal:TitleAdd'),
          },
        ]}}),_c('div',{staticClass:"topline-minizone"},[_c('Button',{attrs:{"size":"s-standart","color":"accentlightlines","icon":"x","is-loading":false,"loading-left":true},on:{"click":function($event){return _vm.$emit('close')}}})],1)],1),_c('div',{staticClass:"modalwrap2-content"},[_c('div',{staticClass:"modalwrap3-inputs margintop--standart marginbottom--standart"},[_c('DropdownSelect',{attrs:{"placeholder":_vm.$t('GlossaryLinkedPestsModal:Category.Placeholder'),"label":_vm.$t('GlossaryLinkedPestsModal:Category.Label'),"options":_vm.inputCategories,"dd-max-elements":"6"},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}}),_c('DropdownPlusSelect',{attrs:{"label":_vm.$t('GlossaryLinkedPestsModal:Name.Label'),"placeholder":_vm.$t('GlossaryLinkedPestsModal:Name.Placeholder'),"size":"long","autofocus":true,"options":_vm.pestsOfSelectedCategory,"dd-max-elements":"6"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}})],1),_c('div',{staticClass:"modalwrap3-buttons"},[(_vm.editingId)?_c('Button',{attrs:{"text":_vm.$t('GlossaryLinkedPestsModal:ButtonDelete'),"size":"wide","color":"alertlines","icon":"delete","is-loading":false,"loading-left":true,"disabled":!_vm.canDelete},on:{"click":_vm.remove}}):_vm._e(),_c('Button',{attrs:{"text":_vm.$t('GlossaryLinkedPestsModal:ButtonCancel'),"size":"wide","color":"graylines","icon":"cancel","is-loading":false,"loading-left":true},on:{"click":function($event){return _vm.$emit('close')}}}),_c('Button',{attrs:{"text":_vm.$t('GlossaryLinkedPestsModal:ButtonSave'),"size":"wide","color":"accent","icon":"save","is-loading":false,"loading-left":true,"disabled":!_vm.canSave},on:{"click":_vm.save}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }