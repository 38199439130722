<!--Translated-->
<template>
  <div
    class="cwrap cwrap--total marginbottom--standart"
    v-if="unTotalProductList.length"
  >
    <div class="cblock cblock--totalprice el--borders-all el--rounded-all">
      <div class="cblock__line">
        <div class="cblock__line-block">
          <span>{{ $t('FertilizerProgramTables:Title') }} -</span>
          <span>₾ {{ formatNumber(totalPrice) }}</span>
        </div>
      </div>
    </div>

    <div>
      <div
        class="chead el--borders-top el--borders-sides el--rounded-top margintop--standart"
      >
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'fertilizer' }"
          />
          <div class="chead_text">
            {{ $t('FertilizerProgramTables:Title2') }}
          </div>
        </div>
      </div>

      <div class="cblock el--rounded-bottom">
        <table class="table--type1 table--withhead table--paycheck">
          <thead>
            <tr>
              <th class="tbl-cellproduct">
                {{ $t('FertilizerProgramTables:Table:Headers:0') }}
              </th>
              <th class="col">
                {{ $t('FertilizerProgramTables:Table:Headers:1') }}
              </th>
              <th class="col">
                {{ $t('FertilizerProgramTables:Table:Headers:2.1') }}
                / 1
                {{ $t('FertilizerProgramTables:Table:Headers:2.2') }}
              </th>
              <th class="col">
                {{ $t('FertilizerProgramTables:Table:Headers:2.1') }} /
                {{ area }} {{ $t('FertilizerProgramTables:Table:Headers:2.2') }}
              </th>
              <th class="col">
                {{ $t('FertilizerProgramTables:Table:Headers:4') }}
              </th>
              <th class="col">
                {{ $t('FertilizerProgramTables:Table:Headers:5') }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="row in unTotalProductList"
              :key="row.productLink.id"
              class="row"
            >
              <td>{{ row.productLink.name }}</td>
              <td>{{ row.productLink.picking }}</td>
              <td>{{ formatNumber(row.totalPerHa) }}</td>
              <td>{{ formatNumber(row.totalForArea) }}</td>
              <td>
                {{ formatNumber(row.packages) }}
              </td>
              <td>{{ row.packagesInt }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="cwrap">
      <div
        class="chead el--borders-top el--borders-sides el--rounded-top margintop--standart"
      >
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'paycheck' }"
          ></div>
          <div class="chead_text">
            {{ $t('FertilizerProgramTables:Result') }}
          </div>
        </div>
      </div>

      <div
        class="cblock cblock--analizetable el--rounded-bottom el--borders-sides el--borders-bottom"
      >
        <div class="cblock__internal">
          <div class="cblock__line paycheckfinal">
            <div class="paycheckfinal_block paycheckfinal_block--dates">
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('FertilizerProgramTables:DateCreate') }}
                </div>
                <div class="paycheckfinal_block-textdata">{{ createdAt }}</div>
              </div>
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('FertilizerProgramTables:DateEdit') }}
                </div>
                <div class="paycheckfinal_block-textdata">{{ updatedAt }}</div>
              </div>
            </div>

            <div class="el--decorlinevertical"></div>
            <div class="paycheckfinal_block">
              <div
                :style="{
                  visibility: totalPriceUSD ? 'visible' : 'hidden',
                }"
                class="paycheckfinal_block-line"
              >
                <div class="paycheckfinal_block-textkey">
                  {{ $t('FertilizerProgramTables:SumDollar') }}
                </div>
                <div class="paycheckfinal_block-textdata">
                  {{
                    Number.isFinite(totalPriceUSD)
                      ? '$ ' + formatNumber(totalPriceUSD)
                      : '-'
                  }}
                </div>
              </div>
              <div
                :style="{
                  visibility: totalPriceEU ? 'visible' : 'hidden',
                }"
                class="paycheckfinal_block-line"
              >
                <div class="paycheckfinal_block-textkey">
                  {{ $t('FertilizerProgramTables:SumEuro') }}
                </div>
                <div class="paycheckfinal_block-textdata">
                  {{
                    Number.isFinite(totalPriceEU)
                      ? '€ ' + formatNumber(totalPriceEU)
                      : '-'
                  }}
                </div>
              </div>
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('FertilizerProgramTables:SumGel') }}
                </div>
                <div class="paycheckfinal_block-textdata">
                  {{
                    Number.isFinite(totalPriceRounded)
                      ? '₾ ' + formatNumber(totalPriceRounded)
                      : '-'
                  }}
                </div>
              </div>
            </div>

            <div class="el--decorlinevertical"></div>
            <div class="paycheckfinal_block paycheckfinal_block--finalprice">
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('FertilizerProgramTables:Discount') }}
                </div>
                <div class="paycheckfinal_block-textdata">
                  {{ program.client_discount || 0 }}%
                </div>
              </div>
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('FertilizerProgramTables:DiscountSum') }}
                </div>
                <div class="paycheckfinal_block-textdata">
                  ₾
                  {{
                    formatNumber(
                      (totalPriceRounded / 100) * (program.client_discount || 0)
                    )
                  }}
                </div>
              </div>
              <div class="paycheckfinal_block-line">
                <div class="paycheckfinal_block-textkey">
                  {{ $t('FertilizerProgramTables:ResultSum') }}
                </div>
                <div class="paycheckfinal_block-textdata">
                  ₾
                  {{
                    formatNumber(
                      totalPriceRounded -
                        (totalPriceRounded / 100) *
                          (program.client_discount || 0)
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'UserFertilizerProgramTables',
  computed: {
    ...mapGetters(['unTotalProductList']),
    ...mapState({
      isSaving: (state) => state.userNutrition.isSaving,
      program: (state) => state.userNutrition.program,
      area: (state) => state.userNutrition.program.place_area,
      createdAt: (state) =>
        state.userNutrition.program.date_create
          ? format(
              new Date(state.userNutrition.program.date_create),
              'dd.MM.yyyy HH:MM'
            )
          : 'არ არის შენახული',
      updatedAt: (state) =>
        state.userNutrition.program.date_update
          ? format(
              new Date(state.userNutrition.program.date_update),
              'dd.MM.yyyy HH:MM'
            )
          : '-',
    }),
    totalPrice() {
      return this.unTotalProductList.reduce(
        (a, b) => a + (b.productLink.price ?? 0) * b.packages,
        0
      );
    },
    totalPriceRounded() {
      return this.unTotalProductList.reduce(
        (a, b) => a + (b.productLink.price ?? 0) * b.packagesInt,
        0
      );
    },
    totalPriceUSD() {
      return this.program.rate_dollar
        ? this.totalPriceRounded / this.program.rate_dollar
        : null;
    },
    totalPriceEU() {
      return this.program.rate_euro
        ? this.totalPriceRounded / this.program.rate_euro
        : null;
    },
  },
  methods: {
    formatNumber(num) {
      return num.toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>
