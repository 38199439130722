import deepFreeze from 'deep-freeze';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import api from '@/api/plantsCare';
import { mutationTypes as geobaseMutationTypes } from './glossaryGeobase';
import i18n from '@/i18n';
// import { diff } from 'deep-object-diff';
//translated

const newTemplateFileds = [
  'categoriesCultures',
  'cultures',
  'pests',
  'phases',
  'typesStimulation',
  'phasesTypesStimulation',
  'images',
  'phasesPests',
  'products',
  'productsPests',
  'productsTypesStimulation',
  'manufacturers',
  'manufacturersCountries',
];

const defaultTemplate = {
  geo_id: null,
  culture_id: null,
  culture_age: null,
  calculation_method_id: null,
  name: '',
  description: '',
  is_active: true,
  is_default: false,
  date_create: null,
  date_update: null,
};

const editableKeys = [
  'templatePestsProducts',
  'templateTypesStimulationProducts',
  'templateWaterPhases',
];

const createState = () => ({
  isLoading: false,
  isSaving: false,
  error: null,
  timestamp: 0,
  productsTimestamp: 0,
  lastSavedId: null,
  serverData: {
    template: { ...defaultTemplate },
    ...Object.fromEntries(editableKeys.map((key) => [key, []])),
  },
  template: { ...defaultTemplate },
  ...Object.fromEntries(newTemplateFileds.map((key) => [key, []])),
  templatePestsProducts: [],
  templateTypesStimulationProducts: [],
  templateWaterPhases: [],
});

const state = createState();

const staticData = {
  calculationMethods: {
    1: i18n.t('Vuex:plantsCare:CalculationMethods:1'), //'На 100 литров воды',
    2: i18n.t('Vuex:plantsCare:CalculationMethods:2'), //'На 1000 литров воды',
    3: i18n.t('Vuex:plantsCare:CalculationMethods:3'), //'На 1 га',
  },
  calculationMethodsShort: {
    1: i18n.t('Vuex:plantsCare:CalculationMethodsShort:1'), //'100 л',
    2: i18n.t('Vuex:plantsCare:CalculationMethodsShort:2'), //'1000 л',
    3: i18n.t('Vuex:plantsCare:CalculationMethodsShort:3'), //'1 га',
  },
};

const mutationTypes = {
  setDefaultState: '[plants care] set default state',
  resetState: '[plants care] reset state',

  getNewTemplateDataStart: '[plants care] getNewTemplateData start',
  getNewTemplateDataSuccess: '[plants care] getNewTemplateData success',
  getNewTemplateDataFailure: '[plants care] getNewTemplateData failure',

  templateSavingStart: '[plants care] template saving start',
  templateSavingFailure: '[plants care] template saving failure',
  templateSavingSuccess: '[plants care] template saving success',

  deleteId: '[plants care] delete id',
  setGeoId: '[plants care] set geo id',
  setCultureId: '[plants care] set culture id',
  setAge: '[plants care] set age',
  setCalculationMethod: '[plants care] set calculation method',
  setName: '[plants care] set name',
  setDescription: '[plants care] set description',
  setActive: '[plants care] set is active',
  setDefault: '[plants care] set is default',
  setProducts: '[plants care] set products',

  upsertWaterPhase: '[plants care] upsert water phase',
  upsertPestProduct: '[plants care] upsert pest product',
  upsertStimulationType: '[plants care] upsert stimulation type',

  garbageCollect: '[plants care] garbage collect',
};

const actionTypes = {
  getNewTemplateData: '[plants care] getNewTemplateData',
  saveTemplateData: '[plants care] save template data',
  deleteTemplate: '[plants care] delete template',
};

const getters = {
  plantsCareProductsAsMap(state) {
    return Object.fromEntries(state.products.map((p) => [p.id, p]));
  },
  plantsCarePestsProductsAsMap(state) {
    return Object.fromEntries(
      state.templatePestsProducts.map((p) => [
        `phase_${p.phase_id}_pest_${p.pest_id}_product_${p.product_id}`,
        p,
      ])
    );
  },
  plantsCareSTypesProductsAsMap(state) {
    return Object.fromEntries(
      state.templateTypesStimulationProducts.map((p) => [
        `phase_${p.phase_id}_stype_${p.type_stimulation_id}_product_${p.product_id}`,
        p,
      ])
    );
  },
  plantsCareServerFormatted(state) {
    const filter = (obj) => Boolean(obj.quantity);
    const mapper = (obj) => ({
      ...obj,
      quantity: Number(obj.quantity),
    });

    return {
      template: state.template,
      templateWaterPhases: state.templateWaterPhases.filter(filter).map(mapper),
      templatePestsProducts: state.templatePestsProducts
        .filter(filter)
        .map(mapper),
      templateTypesStimulationProducts: state.templateTypesStimulationProducts
        .filter(filter)
        .map(mapper),
    };
  },
  plantsCareTemplateFormValid({ template }) {
    return (
      template.name !== '' &&
      template.geo_id !== null &&
      template.culture_id !== null &&
      template.calculation_method_id !== null
    );
  },
  plantsCareWasEdited(state, getters) {
    // console.log(diff(state.serverData, getters.plantsCareServerFormatted));
    return !isEqual(state.serverData, getters.plantsCareServerFormatted);
  },
};

const mutations = {
  [mutationTypes.setDefaultState](state) {
    Object.assign(state, createState());
  },
  [mutationTypes.resetState](state) {
    state.isLoading = false;
    state.error = null;
    state.template = { ...defaultTemplate };
    editableKeys.forEach((key) => (state[key] = []));
  },

  [mutationTypes.getNewTemplateDataStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.getNewTemplateDataFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },
  [mutationTypes.getNewTemplateDataSuccess](state, payload) {
    state.isLoading = false;
    state.error = null;
    state.timestamp = new Date().getTime();
    if (payload.products) state.productsTimestamp = new Date().getTime();
    newTemplateFileds.forEach((key) => {
      if (payload[key]?.length) state[key] = payload[key];
    });
    ['template', ...editableKeys].forEach((key) => {
      if (payload[key]) {
        state[key] = cloneDeep(payload[key]);
        state.serverData[key] = cloneDeep(payload[key]);
      }
    });
  },
  [mutationTypes.setProducts](
    state,
    { products, manufacturers, manufacturersCountries }
  ) {
    state.products = cloneDeep(products);
    state.manufacturers = cloneDeep(manufacturers);
    state.manufacturersCountries = cloneDeep(manufacturersCountries);
    state.productsTimestamp = new Date().getTime();
  },

  [mutationTypes.deleteId](state) {
    delete state.template.id;
  },
  [mutationTypes.setGeoId]({ template }, payload) {
    template.geo_id = payload;
  },
  [mutationTypes.setCultureId]({ template }, payload) {
    template.culture_id = payload;
  },
  [mutationTypes.setAge]({ template }, payload) {
    template.culture_age = payload > 11 ? 11 : payload;
  },
  [mutationTypes.setCalculationMethod]({ template }, payload) {
    template.calculation_method_id = payload === null ? null : Number(payload);
  },
  [mutationTypes.setName]({ template }, payload) {
    template.name = payload;
  },
  [mutationTypes.setDescription]({ template }, payload) {
    template.description = payload;
  },
  [mutationTypes.setActive]({ template }, payload) {
    template.is_active = payload;
  },
  [mutationTypes.setDefault]({ template }, payload) {
    template.is_default = payload;
  },

  [mutationTypes.upsertWaterPhase](state, { id, quantity }) {
    const phase = state.templateWaterPhases.find(
      ({ phase_id }) => phase_id === id
    );
    if (phase) {
      phase.quantity = quantity;
    } else {
      state.templateWaterPhases.push({ phase_id: id, quantity });
    }
  },
  [mutationTypes.upsertPestProduct](state, payload) {
    const pestProduct = state.templatePestsProducts.find(
      (p) =>
        p.phase_id === payload.phase_id &&
        p.pest_id === payload.pest_id &&
        p.product_id === payload.product_id
    );
    if (pestProduct) {
      pestProduct.quantity = payload.quantity;
    } else {
      state.templatePestsProducts.push(payload);
    }
  },
  [mutationTypes.upsertStimulationType](state, payload) {
    const sType = state.templateTypesStimulationProducts.find(
      (s) =>
        s.phase_id === payload.phase_id &&
        s.type_stimulation_id === payload.type_stimulation_id &&
        s.product_id === payload.product_id
    );
    if (sType) {
      sType.quantity = payload.quantity;
    } else {
      state.templateTypesStimulationProducts.push(payload);
    }
  },

  [mutationTypes.templateSavingStart](state) {
    state.isSaving = true;
    state.error = null;
  },
  [mutationTypes.templateSavingFailure](state, payload) {
    state.error = payload;
    state.isSaving = false;
  },
  [mutationTypes.templateSavingSuccess](state, { id, serverData }) {
    state.isSaving = false;
    state.error = null;
    if (id) state.lastSavedId = id;
    if (serverData) state.serverData = cloneDeep(serverData);
  },

  [mutationTypes.garbageCollect](state) {
    const phaseIds = state.phases
      .filter(({ culture_id }) => culture_id === state.template.culture_id)
      .map((p) => p.id);
    editableKeys.forEach((key) => {
      state[key] = state[key].filter(({ phase_id }) =>
        phaseIds.includes(phase_id)
      );
    });
  },
};

const actions = {
  async [actionTypes.getNewTemplateData](
    { commit, state, rootState },
    payload
  ) {
    commit(mutationTypes.getNewTemplateDataStart);
    try {
      const timestampGeobase = rootState.glossaryGeobase.timestamp;
      const ts = [state.timestamp, timestampGeobase, state.productsTimestamp];
      const res = await (payload
        ? api.getEditTemplateData(payload, ...ts)
        : api.getNewTemplateData(...ts));
      if (res.status === 'ok') {
        commit(mutationTypes.getNewTemplateDataSuccess, deepFreeze(res));
        if (res.geoBase) {
          commit(
            geobaseMutationTypes.getGeobaseSuccess,
            deepFreeze(res.geoBase),
            {
              root: true,
            }
          );
        }
      } else {
        throw Error(res.message || 'Unknown error');
      }
    } catch (err) {
      commit(mutationTypes.getNewTemplateDataFailure, err);
    }
  },
  async [actionTypes.saveTemplateData]({ commit, getters }) {
    commit(mutationTypes.templateSavingStart);
    commit(mutationTypes.garbageCollect);
    try {
      const res = await api.saveTemplateData(getters.plantsCareServerFormatted);
      if (res.status === 'ok')
        commit(mutationTypes.templateSavingSuccess, {
          id: res.id,
          serverData: getters.plantsCareServerFormatted,
        });
      else throw Error(res.message || 'Unknown error');
    } catch (err) {
      commit(mutationTypes.templateSavingFailure, err);
    }
  },
  async [actionTypes.deleteTemplate]({ commit, state }) {
    commit(mutationTypes.templateSavingStart);
    try {
      const res = await api.saveTemplateData({
        delete: true,
        template: { id: state.template.id },
      });
      if (res.status !== 'ok') throw Error(res.message || 'Unknown error');
      commit(mutationTypes.templateSavingSuccess, {});
    } catch (err) {
      commit(mutationTypes.templateSavingFailure, err);
    }
  },
};

export { mutationTypes, actionTypes, newTemplateFileds, staticData };

export default {
  state,
  actions,
  mutations,
  getters,
};
