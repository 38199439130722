<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('Products:Title') }]" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('Products:ButtonAdd')"
          size="standard"
          color="accent"
          icon="plus"
          :is-loading="false"
          :loading-left="false"
          :disabled="!canCreate"
          @click="$router.push({ name: 'NewProduct' })"
        />
      </div>
    </div>

    <div class="rightzone_block">
      <FilterProducts v-model="filter" />
    </div>

    <div class="rightzone_table">
      <Table
        :headers="tableHeaders"
        :data-arr="dataArr"
        :table-loading="isTableLoading"
        :loading-count-items="10"
        @editClick="onEditClick"
      />
    </div>
  </article>
</template>

<script>
import FilterProducts from '@/components/filtres/FilterProducts';
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import Table from '@/components/tables/Table';
import routeNames from '@/router/routeNames';
import { mapState } from 'vuex';
import { mutationTypes } from '@/store/modules/filtersState';

export default {
  name: 'AppProducts',
  components: {
    Button,
    Breadcrumps,
    FilterProducts,
    Table,
  },
  data() {
    return {
      filter: {
        nameOrId: '',
        activeSubstance: '',
        typeProduct: null,
        typePest: null,
        typeStimulation: null,
        typeFertilizer: null,
        manufacture: null,
        manufactureCountry: null,
      },
    };
  },
  methods: {
    onEditClick(clientId) {
      if (clientId) {
        this.$router.push({
          name: routeNames.editProduct,
          params: { id: clientId },
        });
      }
    },
  },
  computed: {
    /*filter: {
      get() {
        return this.$store.state.filtersState.filterProducts;
      },
      set(payload) {
        this.$store.commit(mutationTypes.setFilterProducts, payload);
      },
    },*/
    ...mapState({
      canCreate: (state) =>
        state.auth.currentUserRole?.claim_products_add === 1,
      canEdit: (state) => state.auth.currentUserRole?.claim_products_edit === 1,
    }),
    tableHeaders() {
      return [
        {
          text: '',
          class: 'col-warning',
          icon: 'warningdiese',
          width: '15%',
          type: 'warning',
        },
        {
          text: this.$t('Products:TableHeaders:0'), //'Код',
          width: '15%',
          type: 'text',
        },
        {
          text: this.$t('Products:TableHeaders:1'), //'Название',
          width: '30%',
          type: 'text',
        },
        {
          text: this.$t('Products:TableHeaders:2'), //'Тип',
          width: '15%',
          type: 'text',
        },
        {
          text: this.$t('Products:TableHeaders:3'), //'Расход на 100 л',
          width: '10%',
          type: 'text',
        },
        {
          text: this.$t('Products:TableHeaders:4'), //'Расход на 1 га',
          width: '10%',
          type: 'text',
        },
        {
          text: this.$t('Products:TableHeaders:5'), //'Упаковка',
          width: '10%',
          type: 'text',
        },
        {
          text: this.$t('Products:TableHeaders:6'), //'Стоимость',
          width: '10%',
          type: 'text',
        },
        {
          text: this.$t('Products:TableHeaders:7'), //'Статус',
          class: 'col-status',
          width: '',
          type: 'status',
        },
        {
          text: '',
          class: 'col-edit',
          width: '',
          type: 'edit',
          icon: this.canEdit ? 'edit' : 'passwordshow',
        },
      ];
    },
    isTableLoading() {
      return this.$store.state.products.isProductsLoading;
    },
    dataArr() {
      let arr = [];

      if (this.$store.state.products.products) {
        this.$store.state.products.products.forEach((product) => {
          let tr = [];
          tr.push({ text: product.attention });
          tr.push({ text: product.code ? product.code : '' });
          tr.push({ text: product.name ? product.name : '' });
          tr.push({
            text: this.$store.state.products.productsCategoriesMapById.get(
              product?.type_id
            )
              ? this.$store.state.products.productsCategoriesMapById.get(
                  product.type_id
                )
              : '',
          });
          tr.push({
            text: product.quantity_per_100_litre
              ? product.quantity_per_100_litre
              : '',
          });
          tr.push({
            text: product.quantity_per_ha ? product.quantity_per_ha : '',
          });
          tr.push({ text: product.picking ? product.picking : '' });
          tr.push({ text: product.price ? product.price : '' });
          tr.push({ text: product.is_active === 1 ? 'on' : 'off' });
          tr.push({ text: product.id ? product.id : '' });

          let filterIsActive = false;
          for (let filterKey in this.filter) {
            if (
              this.filter[filterKey] !== null &&
              this.filter[filterKey] !== ''
            ) {
              filterIsActive = true;
              break;
            }
          }
          if (filterIsActive) {
            let filerRes = true;
            if (this.filter.nameOrId !== null) {
              if (
                !(
                  `${product.name}`
                    .toLowerCase()
                    .includes(this.filter.nameOrId.toLowerCase()) ||
                  `${product.code}`
                    .toLowerCase()
                    .includes(this.filter.nameOrId.toLowerCase())
                )
              ) {
                filerRes = false;
              }
            }
            if (filerRes && this.filter.activeSubstance !== null) {
              if (
                !`${product.active_substance}`
                  .toLowerCase()
                  .includes(this.filter.activeSubstance.toLowerCase())
              ) {
                filerRes = false;
              }
            }
            if (filerRes && this.filter.typeProduct !== null) {
              //console.log(product.type_id, this.filter.typeProduct);
              if (product.type_id !== this.filter.typeProduct) {
                filerRes = false;
              }
              if (filerRes && this.filter.typePest !== null) {
                if (product.sub_type_id !== this.filter.typePest) {
                  filerRes = false;
                }
              }
              if (filerRes && this.filter.typeStimulation !== null) {
                //if (product.sub_type_id !== this.filter.typeStimulation) {
                if (
                  !this.$store.state.products
                    .productsTypesStimulationSetMapByProductId ||
                  !this.$store.state.products.productsTypesStimulationSetMapByProductId.has(
                    product.id
                  ) ||
                  !this.$store.state.products.productsTypesStimulationSetMapByProductId
                    .get(product.id)
                    .has(this.filter.typeStimulation)
                ) {
                  filerRes = false;
                }
              }
              if (filerRes && this.filter.typeFertilizer !== null) {
                // if (product.sub_type_id !== this.filter.typeFertilizer) {
                if (
                  !this.$store.state.products
                    .productsFertilizersSetMapByProductId ||
                  !this.$store.state.products.productsFertilizersSetMapByProductId.has(
                    product.id
                  ) ||
                  !this.$store.state.products.productsFertilizersSetMapByProductId
                    .get(product.id)
                    .has(this.filter.typeFertilizer)
                ) {
                  filerRes = false;
                }
              }
            }
            if (filerRes && this.filter.manufacture !== null) {
              //console.log(product.type_id, this.filter.typeProduct);
              if (product.manufacturer_id !== this.filter.manufacture) {
                filerRes = false;
              }
            }
            if (filerRes && this.filter.manufactureCountry !== null) {
              if (
                product.manufacturer_country_id !==
                this.filter.manufactureCountry
              ) {
                filerRes = false;
              }
            }

            if (filerRes) {
              arr.push(tr);
            }
          } else {
            arr.push(tr);
          }
        });
      }
      return arr;
    },
  },
  watch: {
    filter: {
      handler(val) {
        this.$store.commit(mutationTypes.setFilterProducts, { ...val });
      },
      deep: true,
    },
  },
  created() {
    for (let filterKey in this.filter) {
      this.filter[filterKey] =
        this.$store.state.filtersState.filterProducts[filterKey];
    }
  },
};
</script>

<style lang="scss" scoped></style>
