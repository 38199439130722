import Vue from 'vue';

Vue.directive('svg', {
  bind: function (el, binding) {
    const basePath = 'assets/icons/';

    if (binding.value) {
      if (binding.value instanceof Object) {
        if (binding.value.name) {
          el.innerHTML = require(`@/${basePath}${binding.value.name}.svg?raw`);
        }
      }
    } else if (binding.arg) {
      el.innerHTML = require(`@/${basePath}${binding.arg}.svg?raw`);
    }

    let dataName = el.getAttributeNames()[0];
    el.querySelectorAll('*').forEach((elChild) => {
      elChild.setAttribute(dataName, '');
    });
    el.classList.add('vsvg-auto');
  },
});
