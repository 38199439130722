<template>
  <div class="rightzone_table">
    <table class="table--type2">
      <thead>
        <tr>
          <th class="tbl-cellproduct">{{ title }}</th>
          <th width="10%" style="text-align: center">
            {{ $t('DefaultProductsTable:Header:2') }}
          </th>
          <th class="col-edit unicol-colordark">
            <div v-svg="{ name: 'edit' }"></div>
          </th>
        </tr>
      </thead>

      <tbody v-if="products.length">
        <tr class="row" v-for="product in products" :key="product.id">
          <td class="tbl-cellproduct">
            <div class="tbl_info">
              <div class="tbl_info-wrap">
                <div
                  id="force-red"
                  v-if="!product.productRef.is_active"
                  v-svg="{ name: 'warningtriangle' }"
                />
                <div v-else v-svg="{ name: 'info' }"></div>
                <div class="tablepreview">
                  <div
                    class="tablepreview_textblock tablepreview_textblock--list"
                  >
                    <h4
                      @click="goToProductPage(product.productRef.id)"
                      style="cursor: pointer"
                    >
                      {{ product.productRef.name }}
                      <span
                        v-if="!product.productRef.is_active"
                        style="color: red"
                        >{{
                          $t('HoverTooltipProduct:ProductIsNotActive')
                        }}</span
                      >
                    </h4>
                    <p>
                      <span
                        >{{ $t('HoverTooltipProduct:Manufacturer') }} -
                      </span>
                      <span>{{ product.manufacturer }}</span>
                    </p>

                    <p>
                      <span>{{ $t('HoverTooltipProduct:Country') }} - </span>
                      <span> {{ product.country.trim() }} </span>
                    </p>
                    <p>
                      <span>{{ $t('HoverTooltipProduct:Substance') }} - </span>
                      <span>{{
                        product.productRef.active_substance || '...'
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="tbl_info-celltext">
                {{ product.productRef.name }}
              </div>
            </div>
          </td>

          <td style="text-align: center">{{ product.count_per_ha }}</td>
          <td class="col-edit" @click="editDefaultProduct(product.id)">
            <div v-svg="{ name: 'edit' }"></div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="row">
          <td colspan="3">
            <div class="nocontentyet">
              {{ $t('DefaultProductsTable:NoProducts') }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import routeNames from '@/router/routeNames';

export default {
  name: 'DefaultProductsTable',
  props: {
    title: { type: String, required: true },
    products: { type: Array, default: () => [] },
  },
  methods: {
    editDefaultProduct(id) {
      this.$router.push({
        name: routeNames.editFertilizerDefault,
        params: { id },
      });
    },
    goToProductPage(id) {
      const routeData = this.$router.resolve({
        name: routeNames.editProduct,
        params: { id },
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
