<!--Translated-->
<template>
  <div class="inpt-datewrap" ref="wrapper">
    <DropdownSelect
      v-bind="defaults"
      :placeholder="$t('InputDate:SelectDay')"
      :notSelectedText="$t('InputDate:SelectDay')"
      :label="labelText"
      :options="days"
      v-model="selected.day"
      @ddStateChange="onDdStateChange"
    />
    <DropdownSelect
      v-bind="defaults"
      :placeholder="$t('InputDate:SelectMonth')"
      :notSelectedText="$t('InputDate:SelectMonth')"
      :options="months"
      v-model="selected.month"
      @ddStateChange="onDdStateChange"
    />
    <DropdownSelect
      v-bind="defaults"
      :placeholder="$t('InputDate:SelectYear')"
      :notSelectedText="$t('InputDate:SelectYear')"
      :options="years"
      v-model="selected.year"
      @ddStateChange="onDdStateChange"
    />
  </div>
</template>

<script>
import DropdownSelect from '@/components/views/wrappers/DropdownSelect';

export default {
  name: 'InputDate',
  props: {
    value: {
      default: null,
    },
    state: {
      default: '',
    },
    locked: {
      type: Boolean,
      default: false,
    },
    labelText: {
      default: '-',
    },
    scrollToDropdown: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DropdownSelect,
  },
  data() {
    return {
      selected: { day: null, month: null, year: null },
    };
  },
  computed: {
    defaults() {
      return {
        state: this.state,
        disabled: this.state === 'disabled',
        locked: this.locked,
        size: 'micro',
        isForDate: 'true',
        ddMaxElements: '5',
      };
    },
    years() {
      const currentYear = new Date().getUTCFullYear();
      return [...Array(100)]
        .map((_, index) => currentYear - index)
        .map((year) => ({ text: String(year), value: year }));
    },
    months() {
      // prettier-ignore
      return [
        this.$t('InputDate:Month:1'), this.$t('InputDate:Month:2'), this.$t('InputDate:Month:3'),
        this.$t('InputDate:Month:4'), this.$t('InputDate:Month:5'), this.$t('InputDate:Month:6'),
        this.$t('InputDate:Month:7'), this.$t('InputDate:Month:8'), this.$t('InputDate:Month:9'),
        this.$t('InputDate:Month:10'), this.$t('InputDate:Month:11'), this.$t('InputDate:Month:12'),
        ].map((month, index) => ({ text: month, value: index }));
    },
    maxDays() {
      const daysInFebruary =
        this.selected.year === null || this.selected.year.value % 4 === 0
          ? 29
          : 28;
      // prettier-ignore
      const daysInMonth = [31, daysInFebruary, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      return this.selected.month?.value ?? null
        ? daysInMonth[this.selected.month.value]
        : 31;
    },
    days() {
      return [...Array(this.maxDays)].map((_, index) => ({
        text: String(index + 1),
        value: index + 1,
      }));
    },
  },
  watch: {
    selected: {
      handler(selected) {
        const values = Object.values(selected).map((v) => v?.value ?? null);
        if (values.some((val) => val === null)) {
          this.$emit('input', null);
        } else {
          const [day, month, year] = values;
          if (day > this.maxDays) {
            this.selected.day = this.days[this.days.length - 1];
          } else {
            this.$emit('input', Date.UTC(year, month, day));
          }
        }
      },
      deep: true,
    },
    value: {
      handler(value) {
        if (typeof value === 'string') {
          value = new Date(value).getTime();
        }

        if (Number.isInteger(value)) {
          const date = new Date(value);
          this.selected.day = this.days.find(
            (d) => d.value === date.getUTCDate()
          );
          this.selected.month = this.months.find(
            (m) => m.value === date.getUTCMonth()
          );
          this.selected.year = this.years.find(
            (y) => y.value === date.getUTCFullYear()
          );
        }
      },
      immediate: true,
    },
  },
  methods: {
    onDdStateChange() {
      if (this.scrollToDropdown) {
        this.$nextTick(() =>
          this.$refs.wrapper
            .querySelector('.inptdroplist_wrap')
            ?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        );
      }
    },
  },
};
</script>
