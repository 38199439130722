<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps
        :data="[
          { title: $t('AdminInstructions:Title'), route: 'Instructions' },
          { title: $t('AdminInstructions:Title2') },
        ]"
      />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('AdminArticleInstructionEditor:ButtonCancel')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'Instructions' })"
        />
        <Button
          :text="$t('AdminArticleInstructionEditor:ButtonSave')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="isUpdating"
          :loading-left="true"
          :disabled="isSaveButtonDisabled"
          @click="onSave"
        />
      </div>
    </div>

    <div class="cwrap marginbottom--standart">
      <div
        class="cblock el--rounded-all el--borders-all marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line">
            <div class="cblock__line-block">
              <AppInput
                type="text"
                :label="$t('AdminArticleInstructionEditor:CategoryText')"
                :placeholder="categoryText"
                color="accent"
                size="long3x"
                state="disabled2"
              />
            </div>

            <div class="cblock__line-block"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Редактировать ЧАСТЬ 1 -->
    <div class="cwrap marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'edit' }"
          ></div>
          <div class="chead_text">
            {{ $t('AdminArticleInstructionEditor:Edit1') }}
          </div>
        </div>
      </div>
      <div
        class="cblock cblock el--rounded-bottom el--borders-all marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line">
            <div class="cblock__line-block">
              <AppInput
                type="text"
                :label="$t('AdminArticleInstructionEditor:Youtube.Label')"
                :placeholder="
                  $t('AdminArticleInstructionEditor:Youtube.Placeholder')
                "
                color=""
                icon="link"
                size="max"
                v-model="instruction0.link_youtube"
                :state="isLoading || isUpdating ? 'disabled' : ''"
              />
              <Button
                text="очистить"
                size="s-standart"
                color="graylines"
                icon="backspace"
                :disabled="isLoading || isUpdating"
                @click="instruction0.link_youtube = ''"
              />
            </div>
          </div>

          <div class="cblock__line">
            <div class="cblock__line-block wysiwyg">
              <wysiwyg v-model="instruction0.text" />
            </div>
          </div>

          <div
            v-for="(link, index) in instructionFiles0"
            :key="index"
            class="cblock__line cblock__line--addlink"
          >
            <div class="cblock__line-block">
              <AppInput
                type="text"
                color="accent"
                :label="`${$t('AdminArticleInstructionEditor:LinkNum')}${
                  index + 1
                } - ${$t('AdminArticleInstructionEditor:LinkText')}`"
                :placeholder="
                  $t('AdminArticleInstructionEditor:LinkTextPlaceholder')
                "
                size="long"
                v-model="link.title"
                :state="isLoading || isUpdating ? 'disabled' : ''"
              />
              <AppInput
                type="text"
                color="accent"
                :label="`${$t('AdminArticleInstructionEditor:LinkNum')}${
                  index + 1
                } - ${$t('AdminArticleInstructionEditor:LinkText2')}`"
                :placeholder="
                  $t('AdminArticleInstructionEditor:LinkText2Placeholder')
                "
                icon="link"
                size="long"
                v-model="link.link"
                :state="isLoading || isUpdating ? 'disabled' : ''"
              />
              <!--<Button
              text="Добавить еще"
              size="auto"
              color="accentlightlines"
              icon="plus"
            />-->
              <Button
                text="Удалить"
                size="s-standart"
                color="graylinesalert"
                icon="delete"
                @click="deleteFileLink(0, index)"
                :disabled="isUpdating || isLoading"
              />
            </div>
          </div>
          <div class="cblock__line">
            <Button
              v-if="instructionFiles0.length < 8"
              :text="$t('AdminArticleInstructionEditor:AddButtonLink')"
              size="auto"
              color="accentlightlines"
              icon="plus"
              @click="addLink(0)"
              :disabled="isLoading || isUpdating"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Редактировать ЧАСТЬ 2 -->
    <div class="cwrap marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'edit' }"
          ></div>
          <div class="chead_text">
            {{ $t('AdminArticleInstructionEditor:Edit2') }}
          </div>
        </div>
      </div>
      <div
        class="cblock cblock el--rounded-bottom el--borders-all marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line">
            <div class="cblock__line-block">
              <AppInput
                type="text"
                :label="$t('AdminArticleInstructionEditor:Youtube.Label')"
                :placeholder="
                  $t('AdminArticleInstructionEditor:Youtube.Placeholder')
                "
                color=""
                icon="link"
                size="max"
                v-model="instruction1.link_youtube"
                :state="isLoading || isUpdating ? 'disabled' : ''"
              />
              <Button
                text="очистить"
                size="s-standart"
                color="graylines"
                icon="backspace"
                @click="instruction1.link_youtube = ''"
                :disabled="isUpdating || isLoading"
              />
            </div>
          </div>

          <div class="cblock__line">
            <div class="cblock__line-block wysiwyg">
              <wysiwyg v-model="instruction1.text" />
            </div>
          </div>
          <div
            v-for="(link, index) in instructionFiles1"
            :key="index"
            class="cblock__line cblock__line--addlink"
          >
            <div class="cblock__line-block">
              <AppInput
                type="text"
                color="accent"
                :label="`${$t('AdminArticleInstructionEditor:LinkNum')}${
                  index + 1
                } - ${$t('AdminArticleInstructionEditor:LinkText')}`"
                :placeholder="
                  $t('AdminArticleInstructionEditor:LinkTextPlaceholder')
                "
                size="long"
                v-model="link.title"
                :state="isLoading || isUpdating ? 'disabled' : ''"
              />
              <AppInput
                type="text"
                color="accent"
                :label="`${$t('AdminArticleInstructionEditor:LinkNum')}${
                  index + 1
                } - ${$t('AdminArticleInstructionEditor:LinkText2')}`"
                :placeholder="
                  $t('AdminArticleInstructionEditor:LinkText2Placeholder')
                "
                icon="link"
                size="long"
                v-model="link.link"
                :state="isLoading || isUpdating ? 'disabled' : ''"
              />
              <!--<Button
              text="Добавить еще"
              size="auto"
              color="accentlightlines"
              icon="plus"
            />-->
              <Button
                text="Удалить"
                size="s-standart"
                color="graylinesalert"
                icon="delete"
                @click="deleteFileLink(1, index)"
                :disabled="isLoading || isUpdating"
              />
            </div>
          </div>
          <div class="cblock__line">
            <Button
              v-if="instructionFiles1.length < 8"
              :text="$t('AdminArticleInstructionEditor:AddButtonLink')"
              size="auto"
              color="accentlightlines"
              icon="plus"
              @click="addLink(1)"
              :disabled="isLoading || isUpdating"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="cwrap">
      <div class="cblock">
        <div class="cblock__line">
          <div class="cblock__line-block">
            <Button
              :text="$t('AdminArticleInstructionEditor:ButtonSave')"
              size="auto"
              color="accent"
              icon="save"
              :disabled="isSaveButtonDisabled"
              @click="onSave"
            />
            <!--            <Button
              text="Открыть статью"
              size="auto"
              color="accentlightlines"
              icon="linkto"
            />-->
          </div>
          <div class="cblock__line-block flex-end">
            <!--            <Button
              text="Очистить всё"
              size="auto"
              color="graylinesalert"
              icon="backspace"
            />-->
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { createConfirmCallback } from '@/helpers/confirmModalHelper';

import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import AppInput from '@/components/views/Input';
import RadioToggle from '@/components/views/RadioToggle';
import CheckBox from '@/components/CheckBox';
import CheckToggle from '@/components/views/CheckToggle';
import { actionTypes } from '@/store/modules/instructions';
import routeNames from '@/router/routeNames';

export default {
  name: 'AdminArticleInstructionsEditor',
  components: {
    CheckToggle,
    CheckBox,
    RadioToggle,
    AppInput,
    Button,
    Breadcrumps,
  },
  data() {
    return {
      state: {
        nextRouteConfirm: false,
        nextRoute: null,
      },
      instruction0: {
        id: null,
        category_id: null,
        block_id: 0,
        title: '',
        link_youtube: '',
        link_image: '',
        text: '',
      },
      instruction1: {
        id: null,
        category_id: null,
        block_id: 0,
        title: '',
        link_youtube: '',
        link_image: '',
        text: '',
      },
      instructionFiles0: [],
      instructionFiles1: [],
    };
  },
  computed: {
    categoryText() {
      let text = '';
      if (this.$route.params?.id >= 0 && this.$route.params?.id < 4) {
        text = this.$t(
          'UserAnalyzes:TableData:AnalyseType:' + this.$route.params.id
        );
      }
      return text;
    },
    categoryId() {
      return this.$route.params?.id;
    },
    editInstruction0() {
      return this.$store.state.instructions.editInstruction0;
    },
    editInstruction1() {
      return this.$store.state.instructions.editInstruction1;
    },
    editInstructionFiles0() {
      return this.$store.state.instructions.editInstructionFiles0;
    },
    editInstructionFiles1() {
      return this.$store.state.instructions.editInstructionFiles1;
    },
    isLoading() {
      return this.$store.state.instructions.isInstructionsLoading;
    },
    isUpdating() {
      return this.$store.state.instructions.isUpdateLoading;
    },
    isUpdateDone() {
      return this.$store.state.instructions.isUpdateDone;
    },
    isSaveButtonDisabled() {
      if (this.isLoading || this.isUpdating) return true;
      if (
        this.instruction0.title !== this.editInstruction0.title ||
        this.instruction0.text !== this.editInstruction0.text ||
        this.instruction0.link_image !== this.editInstruction0.link_image ||
        this.instruction0.link_youtube !== this.editInstruction0.link_youtube ||
        this.instruction0.block_id !== this.editInstruction0.block_id ||
        this.instruction0.category_id !== this.editInstruction0.category_id
      ) {
        console.log('1');
        return false;
      }
      if (
        this.instruction1.title !== this.editInstruction1.title ||
        this.instruction1.text !== this.editInstruction1.text ||
        this.instruction1.link_image !== this.editInstruction1.link_image ||
        this.instruction1.link_youtube !== this.editInstruction1.link_youtube ||
        this.instruction1.block_id !== this.editInstruction1.block_id ||
        this.instruction1.category_id !== this.editInstruction1.category_id
      ) {
        console.log('2');
        return false;
      }
      if (this.instructionFiles0.length !== this.editInstructionFiles0.length) {
        return false;
      }
      if (this.instructionFiles1.length !== this.editInstructionFiles1.length) {
        return false;
      }
      for (let i = 0; i < this.instructionFiles0.length; i++) {
        if (
          this.instructionFiles0[i].link !==
            this.editInstructionFiles0[i].link ||
          this.instructionFiles0[i].title !==
            this.editInstructionFiles0[i].title
        ) {
          return false;
        }
      }
      for (let i = 0; i < this.instructionFiles1.length; i++) {
        if (
          this.instructionFiles1[i].link !==
            this.editInstructionFiles1[i].link ||
          this.instructionFiles1[i].title !==
            this.editInstructionFiles1[i].title
        ) {
          return false;
        }
      }

      return true;
    },
  },
  methods: {
    deleteFileLink(blockId, fileLinkIndex) {
      this['instructionFiles' + blockId].splice(fileLinkIndex, 1);
    },
    addLink(blockId) {
      this['instructionFiles' + blockId].push({
        block_id: blockId,
        instruction_id: this['instruction' + blockId].id,
        title: '',
        link: '',
        type_id: 0,
      });
    },
    onSave() {
      if (this.instruction0.text === '<br>') {
        this.instruction0.text = '';
      }
      if (this.instruction1.text === '<br>') {
        this.instruction1.text = '';
      }
      this.$store.dispatch(actionTypes.updateInstruction, {
        instructions: [this.instruction0, this.instruction1],
        files_links: [...this.instructionFiles0, ...this.instructionFiles1],
      });
    },
  },
  watch: {
    editInstruction0: {
      handler(val) {
        if (val) {
          this.instruction0.id = val.id;
          this.instruction0.title = val.title;
          this.instruction0.text = val.text;
          this.instruction0.category_id = val.category_id;
          this.instruction0.block_id = val.block_id;
          this.instruction0.link_youtube = val.link_youtube;
          this.instruction0.link_image = val.link_image;
        } else {
          this.instruction0 = {
            id: null,
            category_id: null,
            block_id: 0,
            title: '',
            link_youtube: '',
            link_image: '',
            text: '',
          };
        }
      },
      immediate: true,
    },
    editInstruction1: {
      handler(val) {
        if (val) {
          this.instruction1.id = val.id;
          this.instruction1.title = val.title;
          this.instruction1.text = val.text;
          this.instruction1.category_id = val.category_id;
          this.instruction1.block_id = val.block_id;
          this.instruction1.link_youtube = val.link_youtube;
          this.instruction1.link_image = val.link_image;
        } else {
          this.instruction0 = {
            id: null,
            category_id: null,
            block_id: 1,
            title: '',
            link_youtube: '',
            link_image: '',
            text: '',
          };
        }
      },
      immediate: true,
    },
    editInstructionFiles0: {
      handler(val) {
        if (val) {
          this.instructionFiles0 = [];
          val.forEach((v) => {
            this.instructionFiles0.push({ ...v });
          });
        } else {
          this.instructionFiles0.clear();
        }
      },
      immediate: true,
    },
    editInstructionFiles1: {
      handler(val) {
        if (val) {
          this.instructionFiles1 = [];
          val.forEach((v) => {
            this.instructionFiles1.push({ ...v });
          });
        } else {
          this.instructionFiles1.clear();
        }
      },
      immediate: true,
    },
    isUpdateDone() {
      if (this.isUpdateDone)
        this.$confirm({
          message:
            this.$t('AdminArticlesEditor:ModalSaved:Text:0') +
            '<br/>' +
            this.$t('AdminArticlesEditor:ModalSaved:Text:1'),
          buttons: {
            left: {
              text: this.$t('AnalyzesEdit:ModalSaved:ButtonOk'), //'Перейти',
              icon: 'tick',
              color: 'accentlines',
            },
            right: {
              text: this.$t('AnalyzesEdit:ModalSaved:ButtonUndo'), //'Остаться',
              icon: 'cancel',
              color: 'graylines',
            },
          },
          callback: (confirm) => {
            if (confirm) {
              this.state.nextRouteConfirm = true;
              this.$router.push({ name: routeNames.instructions });
            } else {
              if (this.$route.name === routeNames.editInstruction) {
                this.state.nextRouteConfirm = true;
                this.$router.go();
              } else {
                if (this.$store.state.instructions?.updatingResId) {
                  this.state.nextRouteConfirm = true;
                  this.$router.push({
                    name: routeNames.editInstruction,
                    params: {
                      id: this.$store.state.instructions?.updatingResId,
                    },
                  });
                } else {
                  this.state.nextRouteConfirm = true;
                  this.$router.go();
                }
              }
            }
          },
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.state.nextRouteConfirm) {
      next();
    } else if (this.$route.name === routeNames.editInstruction) {
      if (!this.isSaveButtonDisabled) {
        this.$confirm({
          message:
            this.$t('AnalyzesEdit:ModalNotSaved:Text:0') +
            '<br>' +
            this.$t('AnalyzesEdit:ModalNotSaved:Text:1'),
          callback: createConfirmCallback(next),
        });
      } else {
        next();
      }
    } else {
      next(false);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/vars.scss';
</style>
