<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('ClientsDuplicates:Title') }]" />
    </div>

    <div class="rightzone_block">
      <div class="filter">
        <div class="filter-inputszone">
          <div class="filterwrap filterwrap--clients">
            <div class="filterwrap_line">
              <Input
                type="text"
                :placeholder="
                  $t('ClientsDuplicates:Filter:Duplicate.Placeholder')
                "
                :label="$t('ClientsDuplicates:Filter:Duplicate.Label')"
                size="max"
                v-model.trim="filter.duplicate"
              />
            </div>
          </div>
        </div>

        <div class="filter-clearbtnzone">
          <Button
            class="filter_buttonclear"
            text="очистить"
            size="s-standart"
            icon="backspace"
            :color="filterIsActive ? 'accentlines' : 'graylines'"
            :is-loading="false"
            :loading-left="true"
            @click="reset"
          />
        </div>
      </div>
    </div>

    <div class="rightzone_table">
      <Table
        :headers="headers"
        :dataArr="rows"
        @linkToClick="linkToClient"
        :tableLoading="request.isLoading"
        :loadingCountItems="10"
      />
    </div>
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import Table from '@/components/tables/Table';

import routeNames from '@/router/routeNames';
import { staticData } from '@/store/modules/clients';
import { mutationTypes } from '@/store/modules/filtersState';
import clientsApi from '@/api/clients';

export default {
  name: 'ClientsDuplicates',
  components: {
    Input,
    Table,
    Button,
    Breadcrumps,
  },
  data() {
    return {
      filter: {
        duplicate: '',
      },
      data: {
        clients: [],
        clientsMapById: new Map(),
        clientsDuplicatesMap: new Map(),
      },
      request: {
        isLoading: false,
        hasError: false,
      },
      preparedRows: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          type: 'warning',
          icon: 'warningdiese',
          class: 'col-warning',
        },
        {
          text: '№', //'Телефон',
          type: 'duplicate',
          width: '7%',
        },
        {
          text: this.$t('ClientsDuplicates:Table:DuplicateHeaderText'), //'Телефон',
          type: 'text',
          width: '20%',
        },
        {
          text: this.$t('Clients:TableHeaders:0'), //'ID код',
          type: 'text',
          width: '13%',
        },
        {
          text: this.$t('Clients:TableHeaders:1'), //'Имя клиента',
          type: 'text',
          width: '34%',
        },

        {
          text: this.$t('Clients:TableHeaders:4'), //'Юр. статус',
          type: 'text',
          width: '13%',
        },
        {
          text: this.$t('Clients:TableHeaders:5'), //'Тип',
          type: 'text',
          width: '13%',
        },
        {
          text: '',
          class: 'col-edit',
          type: 'linkto',
          icon: 'linkto',
        },
      ];
    },
    statuses() {
      return staticData.clientStatus;
    },
    types() {
      return staticData.clientType;
    },
    rows() {
      let isFilterActive = this.filter.duplicate ? true : false;
      if (isFilterActive) {
        return [
          ...this.preparedRows.filter((value) =>
            value[2]?.text
              ?.toLowerCase()
              .includes(this.filter.duplicate?.toLowerCase())
          ),
        ].map((value, index, array) => {
          if (index === 0) {
            value[0].duplicate = false;
          } else {
            if (array[index - 1][2].text === value[2].text) {
              value[0].duplicate = array[index - 1][0].duplicate;
            } else {
              value[0].duplicate = !array[index - 1][0].duplicate;
            }
          }
          return value;
        });
      } else
        return this.preparedRows.map((value, index, array) => {
          if (index === 0) {
            value[0].duplicate = false;
          } else {
            if (array[index - 1][2].text === value[2].text) {
              value[0].duplicate = array[index - 1][0].duplicate;
            } else {
              value[0].duplicate = !array[index - 1][0].duplicate;
            }
          }
          return value;
        });
    },
    filterIsActive() {
      return !!this.filter.duplicate;
    },
  },
  watch: {
    filter: {
      handler() {
        this.$store.commit(mutationTypes.setFilterClientsDuplicates, {
          ...this.filter,
        });
      },
      deep: true,
    },
  },
  methods: {
    prepareMetaData() {
      this.preparedRows = [];
      if (this.data.clientsDuplicatesMap && this.data.clients) {
        this.data.clientsMapById = new Map();
        this.data.clients?.forEach((client) => {
          this.data.clientsMapById.set(client.id, client);
        });
        let tmpI = 0;
        for (const [key, value] of this.data.clientsDuplicatesMap) {
          tmpI++;
          if (value instanceof Array) {
            value?.forEach((v) => {
              if (this.data.clientsMapById.has(v)) {
                let tmpClient = this.data.clientsMapById.get(v);
                this.preparedRows.push([
                  { text: tmpClient?.attention ?? 0, duplicate: tmpI % 2 },
                  { text: tmpI },
                  { text: key ?? '' },
                  { text: tmpClient?.code ?? '' },
                  { text: tmpClient?.name ?? '' },
                  {
                    text:
                      tmpClient?.status ??
                      this.statuses[tmpClient?.status] ??
                      this.$t('Clients:DataNotSet'),
                  },
                  {
                    text:
                      tmpClient?.type ??
                      this.statuses[tmpClient?.type] ??
                      this.$t('Clients:DataNotSet'),
                  },
                  { text: tmpClient?.id, value: tmpClient?.id },
                ]);
              }
            });
          }
        }
      }
    },
    linkToClient(id) {
      const routeData = this.$router.resolve({
        name: routeNames.editClient,
        params: { id: id },
      });
      window.open(routeData.href, '_blank');
    },
    reset() {
      this.filter.duplicate = '';
    },
  },
  async created() {
    for (let filterKey in this.filter) {
      this.filter[filterKey] =
        this.$store.state.filtersState.filterClientsDuplicates[filterKey];
    }
    try {
      /*
      return this.$store.state.filtersState.filterPhases;
      },
      set(payload) {
        this.$store.commit(mutationTypes.setFilterPhases, payload);
      },
      */

      this.request.isLoading = true;
      let response = await clientsApi.getClientsDuplicates();
      if (response.data.status === 'ok') {
        this.request.hasError = false;
        this.data.clients = response.data.clients ?? [];
        this.data.clientsDuplicatesMap = new Map(
          JSON.parse(response.data.clientsDuplicatesMap ?? new Map())
        );
        this.prepareMetaData();
      } else {
        this.request.hasError = true;
      }
      this.request.isLoading = false;
    } catch (e) {
      this.request.hasError = true;
      this.request.isLoading = false;
      console.log(e);
    }
  },
};
</script>
