<template>
  <Input
    type="number"
    v-bind="$attrs"
    v-model="shadowValue"
    @blur="$emit('blur')"
    @focus="$emit('focus')"
    :maxLength="7"
  />
</template>

<script>
import Input from '@/components/views/Input';

export default {
  name: 'InputNumberWithCast',
  components: { Input },
  props: ['value'],
  data() {
    return {
      oldTextValue: '',
      prevEmitted: null,
    };
  },
  computed: {
    shadowValue: {
      get() {
        return this.oldTextValue;
      },
      set(val) {
        const valueToEmit = val === '' || val === null ? null : Number(val);

        const valueAsText = String(val);
        const notDotAfterFirstZero =
          valueAsText.length > 1 &&
          valueAsText[0] === '0' &&
          valueAsText[1] !== '.';

        if (
          (valueToEmit === 0 && /0\.0+$/.test(this.oldTextValue)) ||
          notDotAfterFirstZero
        ) {
          return;
        }

        if (valueToEmit !== this.prevEmitted) {
          this.oldTextValue = val === null ? '' : val;
          this.$emit('input', valueToEmit);
          this.prevEmitted = valueToEmit;
        }
      },
    },
  },
  watch: {
    value: {
      handler(val) {
        this.shadowValue = val;
      },
      immediate: true,
    },
  },
};
</script>
