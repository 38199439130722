<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('AdminArticles:Title') }]" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('AdminArticles:ButtonAdd')"
          size="standart"
          color="accent"
          icon="plus"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'NewArticle' })"
        />
      </div>
    </div>

    <div class="rightzone_block">
      <div class="filter">
        <div class="filter-inputszone">
          <div class="filterwrap filterwrap--adwbanners">
            <div class="filterwrap_line">
              <Input
                type="text"
                :placeholder="$t('AdminArticles:Filter:Name.Placeholder')"
                :label="$t('AdminArticles:Filter:Name.Label')"
                icon=""
                state=""
                size="long"
                color=""
                v-model="filter.name"
              />
              <DropdownSelectValue
                type="dropdown"
                :placeholder="$t('AdminArticles:Filter:Category.Placeholder')"
                :label="$t('AdminArticles:Filter:Category.Label')"
                size="standart"
                v-model="filter.category"
                :options="[
                  {
                    text: $t('UserHomePage:ArticlesCategories:Defence.Title'),
                    value: 0,
                  },
                  {
                    text: $t(
                      'UserHomePage:ArticlesCategories:Fertilizer.Title'
                    ),
                    value: 1,
                  },
                  {
                    text: $t(
                      'UserHomePage:ArticlesCategories:Irrigation.Title'
                    ),
                    value: 2,
                  },
                  {
                    text: $t('UserHomePage:ArticlesCategories:More.Title'),
                    value: 3,
                  },
                ]"
              />

              <DropdownSelectValue
                :placeholder="$t('AdminArticles:Filter:Type:Placeholder')"
                :label="$t('AdminArticles:Filter:Type:Label')"
                size="standart"
                :options="articlesTypes"
                v-model="filter.type"
              />
            </div>
          </div>
        </div>

        <div class="filter-clearbtnzone">
          <Button
            class="filter_buttonclear"
            text="очистить"
            size="s-standart"
            :color="filterIsActive ? 'accentlines' : 'graylines'"
            icon="backspace"
            :is-loading="false"
            :loading-left="true"
            @click="clearFilter"
          />
        </div>
      </div>
    </div>

    <div class="rightzone_table">
      <Table
        :headers="tableHeaders"
        :data-arr="dataArr"
        :table-loading="isTableLoading"
        :loading-count-items="10"
        @editClick="onEditClick"
      />
    </div>
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import Input from '@/components/views/Input';
import Button from '@/components/views/Button';
import Table from '@/components/tables/Table';
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';
import { mutationTypes } from '@/store/modules/filtersState';
import routeNames from '@/router/routeNames';

export default {
  name: 'AdminArticles',
  components: {
    DropdownSelectValue,
    Input,
    Button,
    Breadcrumps,
    Table,
  },
  data() {
    return {
      filter: {
        name: '',
        category: null,
        type: null,
      },
    };
  },
  computed: {
    articlesTypes() {
      return this.$store.state.articles.articlesTypes.map((v) => {
        return { text: v.name, value: v.id };
      });
    },
    tableHeaders() {
      return [
        {
          text: this.$t('AdminArticles:Filter:TableHeaders:0'),
          width: '65%',
          type: 'text',
        },
        {
          text: this.$t('AdminArticles:Filter:TableHeaders:1'),
          width: '20%',
          type: 'text',
        },
        {
          text: this.$t('AdminArticles:Filter:TableHeaders:2'),
          width: '15%',
          type: 'text',
        },
        {
          text: '',
          class: 'col-edit',
          width: '',
          type: 'edit',
          icon: 'edit',
        },
      ];
    },
    filterIsActive() {
      let filterIsActive = false;
      for (let filterKey in this.filter) {
        if (this.filter[filterKey] !== null && this.filter[filterKey] !== '') {
          filterIsActive = true;
          break;
        }
      }
      return filterIsActive;
    },
    dataArr() {
      let arr = [];

      if (this.$store.state.articles.articles) {
        this.$store.state.articles.articles.forEach((article) => {
          let tr = [];
          tr.push({ text: article.title ? article.title : '' });
          tr.push({
            text: this.$store.state.articles.articlesCategoriesMap.get(
              article?.category_id
            )
              ? this.$store.state.articles.articlesCategoriesMap.get(
                  article.category_id
                )?.name
              : '',
          });
          tr.push({
            text: this.$store.getters.articlesTypes.get(article?.type_id)
              ? this.$store.getters.articlesTypes.get(article.type_id)?.name
              : '',
          });
          tr.push({ text: article.id ? article.id : '' });

          if (this.filterIsActive) {
            let filerRes = true;
            if (this.filter.name !== null) {
              if (
                !`${article?.title}`
                  ?.toLowerCase()
                  .includes(this.filter.name.toLowerCase())
              ) {
                filerRes = false;
              }
            }
            if (filerRes && this.filter.category !== null) {
              if (article.category_id !== this.filter.category) {
                filerRes = false;
              }
            }
            if (filerRes && this.filter.type !== null) {
              //console.log(product.type_id, this.filter.typeProduct);
              if (article.type_id !== this.filter.type) {
                filerRes = false;
              }
            }

            if (filerRes) {
              arr.push(tr);
            }
          } else {
            arr.push(tr);
          }
        });
      }
      return arr;
    },
    isTableLoading() {
      return this.$store.state.articles.isArticlesLoading;
    },
  },
  methods: {
    onEditClick(typeId) {
      this.$router.push({
        name: routeNames.editArticle,
        params: { id: typeId },
      });
    },
    clearFilter() {
      this.filter.name = '';
      this.filter.category = null;
      this.filter.type = null;
    },
  },
  watch: {
    filter: {
      handler(val) {
        this.$store.commit(mutationTypes.setFilterArticles, { ...val });
      },
      deep: true,
    },
  },
  created() {
    for (let filterKey in this.filter) {
      this.filter[filterKey] =
        this.$store.state.filtersState.filterArticles[filterKey];
    }
  },
};
</script>

<style scoped></style>
