<!--Translated-->
<template>
  <div
    :class="{ 'phasewrap-temporary': phase.is_temporary }"
    ref="phase"
    :style="isSaving ? 'filter: saturate(0.2);' : ''"
  >
    <div
      v-if="phase.is_temporary"
      class="chead el--borders-top el--borders-sides el--rounded-top margintop--standart"
    >
      <div class="chead__line-block">
        <div
          class="vsvg vsvg--standart chead_icon"
          v-svg="{ name: 'time' }"
        ></div>
        <div class="chead_text">
          {{ $t('ProgramPhase:Title') }} - {{ phase.name }}
        </div>
      </div>
    </div>

    <div
      class="cblock phase phase--opened el--borders-all el--rounded-all"
      :class="{
        'phase--complete': isActive,
        'margintop--standart': !phase.is_temporary,
      }"
    >
      <PhaseHeader
        :phase="phase"
        :expanded="expanded"
        @toogle="expanded = !expanded"
        @click="buttonAction"
        :readonly="!canEdit"
      />

      <div
        v-if="phase.is_temporary && expanded"
        class="cblock__line phase_comment"
      >
        <div class="cblock__line-block">
          <Input
            type="text"
            :placeholder="$t('ProgramPhase:Comment.Placeholder')"
            label="-"
            size="max"
            v-model="comment"
            :state="canEdit ? '' : 'disabled2'"
          />
        </div>
      </div>

      <div class="cblock__internalpadding phase_tableswrap" v-if="expanded">
        <PhasePestsTable :phase="phase" @addPest="showModalPest = true" />
        <div
          v-if="phase.is_temporary"
          class="phasetemporary-btn margintop--micro marginbottom--standart"
        >
          <Button
            v-if="hasPestsProducts && canEdit"
            :text="$t('ProgramPhase:ButtonAdd')"
            size="standart"
            color="accentlines"
            icon="plus"
            @click="showModalPest = true"
          />
        </div>
        <PhaseTypesStimulationsTable
          :phase="phase"
          @addStimulationType="showModalTs = true"
        />
        <div
          v-if="phase.is_temporary"
          class="phasetemporary-btn margintop--micro"
        >
          <Button
            v-if="hasSTypesProducts && canEdit"
            :text="$t('ProgramPhase:ButtonAdd')"
            size="standart"
            color="accentlines"
            icon="plus"
            @click="showModalTs = true"
          />
        </div>

        <div class="flex-end">
          <Button
            class="btn--chevrontogle"
            size="s-standart"
            :color="expanded ? 'accentlightlines' : 'accent'"
            :icon="expanded ? 'chevronup' : 'chevrondown'"
            :key="expanded"
            @click="collapseAndScroll"
          />
        </div>
      </div>
    </div>

    <AddItemModal
      v-if="showModalTs"
      :items="typesStimulation"
      :selectedItems="selectedStimulationTypes"
      :headerAdd="$t('ProgramPhase:ModalAddItem:Header')"
      :label="$t('ProgramPhase:ModalAddItem:Label')"
      :placeholder="$t('ProgramPhase:ModalAddItem:Placeholder')"
      @close="showModalTs = false"
      @save="addStimulationType"
      @remove="() => null"
    />
    <AddPestModal
      v-if="showModalPest"
      :pests="pests"
      :inputCategories="categoriesPests"
      :selectedPests="selectedPests"
      :headerAdd="$t('ProgramPhase:ModalAddPest:Header')"
      :placeholder="$t('ProgramPhase:ModalAddPest:Placeholder')"
      @close="showModalPest = false"
      @save="addPest"
      @remove="() => null"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { mutationTypes } from '@/store/modules/programs';
import { mapInputEntityWithMerge } from '@/components/adapters/mapInput';

import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import PhaseHeader from './ProgramPhaseHeader';
import PhasePestsTable from './ProgramPestsTable';
import PhaseTypesStimulationsTable from './ProgramTypesStimulationsTable';
import AddItemModal from '@/pages/glossary/GlossaryLinkedItemsModal';
import AddPestModal from '@/pages/glossary/GlossaryLinkedPestsModal';

export default {
  name: 'ProgramPhase',
  props: {
    phase: { type: Object, required: true },
  },
  components: {
    Button,
    Input,
    PhaseHeader,
    PhasePestsTable,
    PhaseTypesStimulationsTable,
    AddPestModal,
    AddItemModal,
  },
  data() {
    return {
      showModalTs: false,
      showModalPest: false,
      expanded: false,
    };
  },
  computed: {
    ...mapGetters(['programPricesMap']),
    ...mapState({
      canEdit: (state) =>
        state.auth.currentUserRole?.claim_plants_care_add === 1,
      isSaving: (state) => state.programs.isSaving,
      lastInsertedPhaseId: (state) => state.programs.lastInsertedPhaseId,
      typesStimulation: (state) =>
        state.programs.typesStimulation
          .filter((ts) =>
            state.programs.productsTypesStimulation.some(
              ({ type_stimulation_id }) => type_stimulation_id === ts.id
            )
          )
          .map(mapInputEntityWithMerge),
      selectedStimulationTypes(state) {
        return [
          ...new Set(
            state.programs.programTypesStimulationProducts
              .filter(
                ({ program_phase_id }) => program_phase_id === this.phase.id
              )
              .map((ts) => ts.type_stimulation_id)
          ),
        ];
      },
      selectedPests(state) {
        return [
          ...new Set(
            state.programs.programPestsProducts.filter(
              ({ program_phase_id }) => program_phase_id === this.phase.id
            )
          ),
        ];
      },
      pests: (state) =>
        state.programs.pests
          .filter(({ id }) =>
            state.programs.productsPests.some(({ pest_id }) => pest_id === id)
          )
          .map(mapInputEntityWithMerge),
      categoriesPests: (state) =>
        state.glossaryPests.categoriesPests.map(mapInputEntityWithMerge),
      hasPestsProducts(state) {
        return state.programs.programPestsProducts.some(
          (p) => p.program_phase_id === this.phase.id
        );
      },
      hasSTypesProducts(state) {
        return state.programs.programTypesStimulationProducts.some(
          (p) => p.program_phase_id === this.phase.id
        );
      },
    }),
    isActive() {
      return this.programPricesMap[this.phase.id] > 0;
    },
    comment: {
      get() {
        return this.phase.comment;
      },
      set(comment) {
        this.$store.commit(mutationTypes.setComment, {
          id: this.phase.id,
          comment,
        });
      },
    },
  },
  created() {
    if (this.lastInsertedPhaseId === this.phase.id) {
      this.expanded = true;
    }
  },
  mounted() {
    if (this.lastInsertedPhaseId === this.phase.id) {
      this.$refs.phase.scrollIntoView({ behavior: 'smooth' });
    }
  },
  methods: {
    buttonAction(id) {
      this.$store.commit(
        this.phase.is_temporary
          ? mutationTypes.removeTemporaryPhase
          : mutationTypes.addTemporaryPhase,
        id
      );
    },
    addPest(id) {
      this.$store.commit(mutationTypes.addPest, {
        id,
        phaseId: this.phase.id,
      });
    },
    addStimulationType(id) {
      this.$store.commit(mutationTypes.addStimulationType, {
        id,
        phaseId: this.phase.id,
      });
    },
    collapseAndScroll() {
      this.expanded = false;
      this.$refs.phase.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>
