<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('FertilizerDefaultEdit:ButtonCancel')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: routeBack })"
        />
        <Button
          :text="$t('FertilizerDefaultEdit:ButtonSave')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="isUpdating"
          :loading-left="true"
          :disabled="!canSave"
          @click="onSave"
        />
      </div>
    </div>

    <div class="cwrap marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'edit' }"
          ></div>
          <div class="chead_text">
            {{ $t('FertilizerDefaultEdit:HeaderTop') }}
          </div>
        </div>
      </div>
      <div
        class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line">
            <div class="cblock__line-block">
              <DropdownSelectValue
                :placeholder="$t('FertilizerDefaultEdit:Condition.Placeholder')"
                :label="$t('FertilizerDefaultEdit:Condition.Label')"
                size="standart"
                :options="options"
                v-model="product.condition_id"
                :disabled="disabled"
              />
              <DropdownPlusSelectValue
                :placeholder="$t('FertilizerDefaultEdit:Product.Placeholder')"
                :label="$t('FertilizerDefaultEdit:Product.Label')"
                size="max"
                :options="products"
                v-model="product.product_id"
                :disabled="disabled"
              />
              <InputNumber
                type="text"
                placeholder="0"
                :label="$t('FertilizerDefaultEdit:Volume.Label')"
                size="Standart"
                v-model="product.count_per_ha"
                :state="disabled ? 'disabled' : ''"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--БЛОК - удалить-->
    <div v-if="isEditing" class="cwrap marginbottom--standart">
      <!--ЗАГОЛОВОК-->
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'x' }"
          ></div>
          <div class="chead_text">
            {{ $t('FertilizerDefaultEdit:DeleteHeader') }}
          </div>
        </div>
      </div>

      <!--ОПИСАНИЕ-->
      <div class="cblock el--borders-all el--rounded-bottom">
        <div class="cblock__infoblock">
          <p>
            {{ $t('FertilizerDefaultEdit:DeleteText1') }}<br />
            {{ $t('FertilizerDefaultEdit:DeleteText2') }}
          </p>

          <Button
            :text="$t('FertilizerDefaultEdit:ButtonDelete')"
            size="wider"
            color="alertlines"
            icon="x"
            @click="deleteProduct"
            :disabled="disabled"
          />
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import Button from '@/components/views/Button';
import InputNumber from '@/components/views/wrappers/InputNumberWithCast';
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';
import DropdownPlusSelectValue from '@/components/views/wrappers/DropdownPlusSelectValue';
import Breadcrumps from '@/components/views/Breadcrumps';
import routeNames from '@/router/routeNames';

import api from '@/api/nutrition';
import isEqual from 'lodash.isequal';
import { createConfirmCallback } from '@/helpers/confirmModalHelper';

const defaultProduct = {
  id: undefined,
  condition_id: null,
  product_id: null,
  count_per_ha: null,
  // count_per_plant: null,
};

export default {
  name: 'FertilizerDefaultEdit',
  components: {
    Button,
    Breadcrumps,
    DropdownSelectValue,
    DropdownPlusSelectValue,
    InputNumber,
  },
  data() {
    return {
      routeBack: routeNames.fertilizerDefaultList,
      isLoading: false,
      isUpdating: false,
      condition: null,
      productsFertilizer: [],
      product: { ...defaultProduct },
      serverProduct: { ...defaultProduct },
      nextRouteConfirm: false,
      options: [
        { text: this.$t('FertilizerDefaultEdit:ConditionType0'), value: 0 },
        { text: this.$t('FertilizerDefaultEdit:ConditionType1'), value: 1 },
      ],
    };
  },
  methods: {
    async onSave() {
      this.isUpdating = true;
      try {
        const res = await api.updateDefaultProduct(this.product);
        if (res.status === 'error') {
          throw Error(
            res.message || this.$t('FertilizerDefaultEdit:ErrorSave')
          );
        } else {
          if (this.isEditing) {
            this.serverProduct = { ...this.product };
          } else {
            this.nextRouteConfirm = true;
            this.$router.push({
              name: routeNames.editFertilizerDefault,
              params: { id: res.id },
            });
          }
        }
      } catch (error) {
        this.$awn.alert(String(error));
      } finally {
        this.isUpdating = false;
      }
    },
    deleteProduct() {
      this.$confirm({
        message: this.$t('FertilizerDefaultEdit:ModalDelete:Message'),
        buttons: {
          left: {
            text: this.$t('ProgramPestsTable:ConfirmDeleteModal:ButtonLeft'),
            icon: 'delete',
          },
          right: {
            text: this.$t('ProgramPestsTable:ConfirmDeleteModal:ButtonRight'),
            icon: 'cancel',
          },
        },
        callback: async (confirm) => {
          if (!confirm) return;
          this.isUpdating = true;
          try {
            const res = await api.updateDefaultProduct({
              id: this.product.id,
              delete: true,
            });
            if (res.status === 'error') {
              throw Error(
                res.message || this.$t('FertilizerDefaultEdit:DeleteErrorText')
              );
            }
          } catch (error) {
            this.$awn.alert(String(error));
          } finally {
            this.isUpdating = false;
          }
          this.nextRouteConfirm = true;
          this.$router.push({ name: this.routeBack });
        },
      });
    },
  },
  computed: {
    isEditing() {
      return this.$route.name === routeNames.editFertilizerDefault;
    },
    disabled() {
      return this.isLoading || this.isUpdating;
    },
    products() {
      return this.productsFertilizer
        .filter((p) => p.is_active || this.product.product_id === p.id)
        .map((p) => ({ value: p.id, text: p.name }));
    },
    canSave() {
      return (
        !this.isLoading &&
        !this.isUpdating &&
        ['condition_id', 'product_id', 'count_per_ha'].every(
          (key) => this.product[key] !== null
        ) &&
        !isEqual(this.product, this.serverProduct)
      );
    },
    breadcrumpsArr() {
      return [
        {
          title: this.$t('FertilizerDefaultEdit:Title.0'),
          route: routeNames.fertilizerDefaultList,
        },
        {
          title: this.isEditing
            ? this.$t('FertilizerDefaultEdit:Title.1')
            : this.$t('FertilizerDefaultEdit:Title.2'),
        },
      ];
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.canSave || this.nextRouteConfirm) {
      next();
    } else {
      this.$confirm({
        message:
          this.$t('DefenseProgramEdit:ModalRoute:Message1') +
          '<br />' +
          this.$t('DefenseProgramEdit:ModalRoute:Message2'),
        buttons: {
          left: {
            text: this.$t('DefenseProgramEdit:ModalRoute:ButtonOk'),
            icon: 'delete',
            color: 'alertlines',
          },
          right: {
            text: this.$t('DefenseProgramEdit:ModalRoute:ButtonCancel'),
            icon: 'cancel',
            color: 'accentlightlines',
          },
        },
        callback: createConfirmCallback(next),
      });
    }
  },
  async created() {
    this.isLoading = true;
    try {
      const apiCall = this.isEditing
        ? api.getEditDefaultProductData(this.$route.params.id)
        : api.getNewDefaultProductData();
      const res = await apiCall;
      this.productsFertilizer = res.productsFertilizer;
      if (res.defaultProduct) {
        this.product = { ...res.defaultProduct };
        this.serverProduct = { ...res.defaultProduct };
      }
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
