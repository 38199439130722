<template>
  <dropdown-plus-select v-bind="$attrs" :options="options" v-model="value1" />
</template>

<script>
import DropdownPlusSelect from '@/components/views/wrappers/DropdownPlusSelect';

export default {
  name: 'DropdownPlusSelectValue',
  components: { DropdownPlusSelect },
  props: ['value', 'options'],
  computed: {
    value1: {
      get() {
        return this.options.find((v) => v.value === this.value) ?? null;
      },
      set(newValue) {
        this.$emit(
          'input',
          newValue === null
            ? null
            : this.options.find(({ value }) => value === newValue.value)
                ?.value ?? null
        );
      },
    },
  },
  watch: {
    //Автосброс дропдауна, если пытаемся установить значение, которого не существует
    value: {
      handler(valueInc) {
        if (!this.options.find(({ value }) => value === valueInc)) {
          this.value1 = null;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
