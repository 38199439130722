<template>
  <div class="cblock el--borders-all el--rounded-bottom" :class="{ disabled }">
    <div class="cblock__internal addimagewrap">
      <div class="addimage-imagezone" v-for="image in images" :key="image.name">
        <div
          class="addimage-image"
          :style="{
            backgroundImage: `url(${image.src})`,
          }"
        >
          <Button
            v-if="!readonly"
            class="addimages-image_btndelete"
            text=" "
            size="s-micro"
            color="alertlines"
            icon="x"
            :is-loading="false"
            :loading-left="true"
            @click="deleteImage(image.name)"
          />
        </div>

        <!--Тут нужно поправить-->
        <!--А еще вынести в пропс, что использовать дд или нет, т.к. у фазы тоже есть фото и там это не нужно -->
        <DropdownPlusSelectValue
          class="inptwrap--type-dropdown-mini-h"
          size="max"
          ddMaxElements="5"
          :placeholder="$t('ImagesSelectMeta:SelectCulture.Placeholder')"
          :notSelectedText="$t('ImagesSelectMeta:SelectCulture.Placeholder')"
          :options="cultures"
          :value="image.meta_id"
          @input="imageCultureSelected(image.name, $event)"
        />
      </div>

      <div class="addimage-imagezone" v-if="canAddMore && !readonly">
        <label
          :class="{
            'addimage-button': true,
            'addimage-button--firstimg': isEmpty,
            'addimage-button--moreimg': !isEmpty,
          }"
        >
          <input
            type="file"
            accept="image/png, image/jpeg"
            multiple
            @change="fileSelected"
            :disabled="disabled"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/views/Button';
import DropdownPlusSelectValue from '@/components/views/wrappers/DropdownPlusSelectValue';
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'FilesSelect',
  components: { DropdownPlusSelectValue, Button },
  model: { prop: 'images' },
  props: {
    maxImages: { type: Number, default: 30 },
    images: { type: Array, default: () => [] },
    cultures: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  computed: {
    isEmpty() {
      return this.images.length === 0;
    },
    canAddMore() {
      return this.images.length < this.maxImages;
    },
  },
  methods: {
    fileSelected(event) {
      const copy = [...this.images];
      for (const file of event.target.files) {
        const alreadyInList = copy.some((f) => f.name === file.name);
        if (!alreadyInList && copy.length < this.maxImages) {
          copy.push({
            type: 'new',
            name: file.name,
            file,
            src: window.URL.createObjectURL(file),
            meta_id: null,
          });
        }
      }
      event.target.value = '';
      this.$emit('input', copy);
    },
    imageCultureSelected(name, id) {
      this.$emit(
        'input',
        this.images.map((f) => (f.name === name ? { ...f, meta_id: id } : f))
      );
    },
    deleteImage(name) {
      this.$emit(
        'input',
        this.images.filter((f) => f.name !== name)
      );
    },
  },
  watch: {
    cultures: {
      handler(cultures) {
        const allIds = this.images
          .map((i) => i.meta_id)
          .filter(Number.isInteger);
        const unlinkedCultures = new Set(
          cultures
            .map(({ value }) => value)
            .filter((id) => !allIds.includes(id))
        );
        if (unlinkedCultures.size) {
          const imagesCopy = cloneDeep(this.images);
          for (const img of imagesCopy) {
            if (unlinkedCultures.has(img.meta_id)) img.meta_id = null;
          }
          this.$emit('input', imagesCopy);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
label {
  display: inline-block;
}
input[type='file'] {
  display: none;
}
.addimage-image {
  background-size: cover;
}
.disabled > * {
  pointer-events: none;
  filter: grayscale(1);
  opacity: 0.5;
}
</style>
