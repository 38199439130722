<!--Translated-->
<template>
  <!--УНИ ВЫ УВЕРЕНЫ - УДАЛИТЬ ПОЛЬЗОВАТЕЛЯ / РОЛЬ И Т.Д.-->
  <Modal @backgroundClose="$emit('close')">
    <div class="modal">
      <div class="modalwrap2-topline">
        <div class="topline-minizone"></div>

        <Breadcrumps :data="[{ title: $t('ModalPrint:Title') }]" />

        <div class="topline-minizone">
          <Button
            text=""
            size="s-standart"
            color="accentlightlines"
            icon="x"
            :is-loading="false"
            :loading-left="true"
            @click="$emit('close')"
          />
        </div>
      </div>

      <div class="modalwrap2-content">
        <div class="modalwrap3-text">
          <p class="text-mini" v-if="!forNutrition && !clientArea">
            {{ $t('ModalPrint:MainText1') }}
          </p>
          <p class="text-mini" v-else>
            {{ $t('ModalPrint:MainText2') }}
          </p>
        </div>

        <div>
          <article class="chkset chkset--print">
            <div class="blocklinewrap sets-wrap">
              <div class="sets-column--full sets-column--scroll">
                <CheckBox
                  v-if="!forNutrition && !clientArea"
                  :text="$t('ModalPrint:Substance')"
                  color-checked="accentfill"
                  color-un-checked="gray"
                  v-model="substance"
                />
                <CheckBox
                  :text="$t('ModalPrint:Vendor')"
                  color-checked="accentfill"
                  color-un-checked="gray"
                  v-model="manufacturer"
                />
                <CheckBox
                  :text="$t('ModalPrint:Country')"
                  color-checked="accentfill"
                  color-un-checked="gray"
                  v-model="country"
                />
              </div>
            </div>
          </article>
        </div>

        <div class="modalwrap3-text margintop--standart marginbottom--standart">
          <div v-if="substance || manufacturer || country" class="warningline">
            <div
              class="vsvg vsvg--standart chead_icon"
              v-svg="{ name: 'warningtriangle' }"
            />
            <div>{{ $t('ModalPrint:WarningText') }}</div>
          </div>
        </div>

        <div class="modalwrap3-buttons">
          <Button
            :text="$t('ModalPrint:ButtonCancel')"
            size="wide"
            color="graylines"
            icon="cancel"
            :is-loading="false"
            :loading-left="true"
            @click="$emit('close')"
          />

          <Button
            :text="$t('ModalPrint:ButtonPrint')"
            size="wide"
            color="accent"
            icon="print"
            :is-loading="false"
            :loading-left="true"
            @click="openPrintPage"
          />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';
import Modal from '@/pages/Modal';
import CheckBox from '@/components/CheckBox';

import routeNames from '@/router/routeNames';

export default {
  name: 'modalPrint',
  props: {
    selectedPrintId: { type: Number, required: true },
    forNutrition: { type: Boolean, default: false },
    clientArea: { type: Boolean, default: false },
  },
  components: { Button, CheckBox, Breadcrumps, Modal },
  data() {
    return { substance: false, manufacturer: false, country: false };
  },
  methods: {
    openPrintPage() {
      const routes = {
        printProgramFertilizers: this.clientArea
          ? routeNames.userPrintProgramFertilizers
          : routeNames.printProgramFertilizers,
        printProgramDefense: this.clientArea
          ? routeNames.userPrintProgramDefense
          : routeNames.printProgramDefense,
      };
      const routeData = this.$router.resolve({
        name: this.forNutrition
          ? routes.printProgramFertilizers
          : routes.printProgramDefense,
        params: {
          id: this.selectedPrintId,
        },
        query: {
          substance:
            this.clientArea || this.forNutrition ? undefined : this.substance,
          manufacturer: this.manufacturer,
          country: this.country,
        },
      });
      window.open(routeData.href, '_blank');
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/vars.scss';

.chkset .chktext {
  max-width: 40ch !important;
}

.warningline {
  display: flex;
  align-items: center;
  justify-content: center;

  & > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      width: $iconSizeStandart - 0px;
      min-width: $iconSizeStandart - 0px;
      height: $iconSizeStandart - 0px;

      & * {
        fill: $colorWarning;
      }
    }
  }

  & > div:last-child {
    color: $colorWarning;
    font-size: $fontsizeStandart;
    line-height: $lineFontsizeStandart;
    padding-left: $marginMicro;
  }
}

@media screen and (max-width: 1800px) {
  .warningline {
    & > div:first-child {
      //width: $iconSizeMicro-k1600;
      //height: $iconSizeMicro-k1600;

      & svg {
        width: $iconSizeStandart-k1600 - 0px;
        min-width: $iconSizeStandart-k1600 - 0px;
        height: $iconSizeStandart-k1600 - 0px;
      }
    }

    & > div:last-child {
      font-size: $fontsizeStandart-k1600;
      line-height: $lineFontsizeStandart-k1600;
      padding-left: $marginMicro-k1600;
    }
  }
}

@media screen and (max-width: 1420px) {
  .warningline {
    & > div:first-child {
      //width: $iconSizeMicro-k1600;
      //height: $iconSizeMicro-k1600;

      & svg {
        width: $iconSizeStandart-k1360 - 0px;
        min-width: $iconSizeStandart-k1360 - 0px;
        height: $iconSizeStandart-k1360 - 0px;
      }
    }

    & > div:last-child {
      font-size: $fontsizeStandart-k1360;
      line-height: $lineFontsizeStandart-k1360;
      padding-left: $marginMicro-k1360;
    }
  }
}
</style>
