export default {
  users: 'Users',
  newUser: 'NewUser',
  editUser: 'EditUser',
  roles: 'Roles',
  newRole: 'NewRole',
  editRole: 'EditRole',
  categories: 'Categories',
  newCategory: 'NewCategory',
  editCategory: 'EditCategory',
  newCulture: 'NewCulture',
  editCulture: 'EditCulture',
  cultures: 'Cultures',
  badObjects: 'BadObjects',
  editPest: 'EditPest',
  newPest: 'NewPest',
  phases: 'Phases',
  editPhase: 'EditPhase',
  newPhase: 'NewPhase',
  regions: 'Regions',
  newRegion: 'NewRegion',
  editRegion: 'EditRegion',
  typesStimulation: 'TypesStimulation',
  newTypeStimulation: 'NewTypeStimulation',
  editTypeStimulation: 'EditTypeStimulation',
  products: 'Products',
  newProduct: 'NewProduct',
  editProduct: 'EditProduct',
  clients: 'Clients',
  newClient: 'NewClient',
  editClient: 'EditClient',
  clientsDuplicates: 'ClientsDuplicates',
  defenseTemplates: 'DefenseTemplates',
  newDefenseTemplate: 'NewDefenseTemplate',
  editDefenseTemplate: 'EditDefenseTemplate',
  defensePrograms: 'DefensePrograms',
  newDefenseProgram: 'NewDefenseProgram',
  editDefenseProgram: 'EditDefenseProgram',
  fertilizerPrograms: 'FertilizerPrograms',
  newFertilizerProgram: 'NewFertilizerProgram',
  editFertilizerProgram: 'EditFertilizerProgram',
  printProgramDefense: 'PrintProgramDefense',
  printProgramFertilizers: 'PrintProgramFertilizers',
  printPage: 'PrintPage',
  analyzes: 'Analyzes',
  editAnalyse: 'EditAnalyse',
  newAnalyse: 'NewAnalyse',
  fertilizerDefaultList: 'fertilizerDefaultList',
  newFertilizerDefault: 'NewFertilizerDefault',
  editFertilizerDefault: 'EditFertilizerDefault',
  demoIcons: 'DemoIcons',
  settingsPage: 'SettingsPage',

  newAdminAdwBanner: 'newAdminAdwBanner',
  editAdminAdwBanner: 'editAdminAdwBanner',
  adminAdwBanners: 'AdminAdwBanners',

  articles: 'Articles',
  newArticle: 'NewArticle',
  editArticle: 'EditArticle',
  instructions: 'Instructions',
  editInstruction: 'EditInstruction',

  //====== userproject ======\\
  userMain: 'UserMain',
  userStartscreen: 'UserStartscreen',

  //User Home and Articles
  userHomePage: 'UserHomePage',
  userArticle: 'UserArticle',
  userArticlesList: 'UserArticlesList',
  userArticlesList1: 'UserArticlesList1',
  userArticlesList3: 'UserArticlesList3',
  userArticlesList2: 'UserArticlesList2',
  userArticlesList4: 'UserArticlesList4',

  //User Static Guides
  userAnalysesInstruction: 'UserAnalysesInstruction',
  userAnalysesInstruction1: 'UserAnalysesInstruction1',
  userAnalysesInstruction2: 'UserAnalysesInstruction2',
  userAnalysesInstruction3: 'UserAnalysesInstruction3',
  userAnalysesInstruction4: 'UserAnalysesInstruction4',
  userGuideFertilizer: 'UserGuideFertilizer',

  //
  userMyCultures: 'UserMyCultures',
  userAnalyzes: 'UserAnalyzes',
  userSettings: 'UserSettings',

  //User Programs
  userDefence: 'UserDefence',
  userDefenceProgramCreate: 'UserDefenceProgramCreate',
  userDefenceProgramEdit: 'UserDefenceProgramEdit',
  userFertilizers: 'UserFertilizers',
  userFertilizerProgramCreate: 'UserFertilizerProgramCreate',
  userFertilizerProgramEdit: 'UserFertilizerProgramEdit',
  userPrintProgramDefense: 'UserPrintProgramDefense',
  userPrintProgramFertilizers: 'UserPrintProgramFertilizers',

  //====== ============ ======\\
};
