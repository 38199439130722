<-- Translated -->
<template>
  <div class="filter">
    <div class="filter-inputszone">
      <div class="filterwrap filterwrap--clients">
        <!--Вид анализа, Статус-->
        <div class="filterwrap_line">
          <DropdownSelectValue
            type="dropdown"
            :placeholder="$t('FilterAnalyzesList:AnalyzeType.Placeholder')"
            :label="$t('FilterAnalyzesList:AnalyzeType.Label')"
            icon=""
            state=""
            size="standart"
            color=""
            dd-max-elements="5"
            :options="analyzesTypes ?? []"
            v-model="filter.analyseTypeId"
          />
          <DropdownSelectValue
            type="dropdown"
            :placeholder="$t('FilterAnalyzesList:AnalyzeStatus.Placeholder')"
            :label="$t('FilterAnalyzesList:AnalyzeStatus.Label')"
            icon=""
            state=""
            size="long"
            color=""
            :dd-max-elements="7"
            :options="[
              {
                text: $t('FilterAnalyzesList:AnalyzeStatus.Values:0'), //'Образец получен'
                value: 0,
              },
              {
                text: $t('FilterAnalyzesList:AnalyzeStatus.Values:1'), //'Доставлен в центральный офис'
                value: 1,
              },
              {
                text: $t('FilterAnalyzesList:AnalyzeStatus.Values:2'), //'Образец обрабатывается'
                value: 2,
              },
              {
                text: $t('FilterAnalyzesList:AnalyzeStatus.Values:3'), //'Доставлен в любораторию',
                value: 3,
              },
              {
                text: $t('FilterAnalyzesList:AnalyzeStatus.Values:4'), //'Анализируется',
                value: 4,
              },
              {
                text: $t('FilterAnalyzesList:AnalyzeStatus.Values:5'), //'Готов',
                value: 5,
              },
            ]"
            v-model="filter.analyseStatus"
          />
        </div>

        <!--ID, имя клиента-->
        <div class="filterwrap_line">
          <Input
            type="text"
            :placeholder="$t('FilterAnalyzesList:ClientName.Placeholder')"
            :label="$t('FilterAnalyzesList:ClientName.Label')"
            icon=""
            state=""
            size="long"
            color=""
            v-model="filter.clientName"
          />
          <Input
            type="text"
            :placeholder="$t('FilterAnalyzesList:ClientId.Placeholder')"
            :label="$t('FilterAnalyzesList:ClientId.Label')"
            icon=""
            state=""
            size="Standart"
            color=""
            v-model="filter.clientCode"
          />
        </div>

        <!--Страна/Регион-->
        <div class="filterwrap_line">
          <LocationSelect
            :geoEntries="geoMapped"
            v-model="filter.geo"
            :freeSelect="true"
            ref="location"
          />
        </div>
      </div>
    </div>

    <div class="filter-clearbtnzone">
      <Button
        class="filter_buttonclear"
        text="очистить"
        size="s-standart"
        :color="isFilterActive ? 'accentlines' : 'graylines'"
        icon="backspace"
        :is-loading="false"
        :loading-left="true"
        @click="onClearFilter"
      />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';

import Input from '@/components/views/Input';
import Button from '@/components/views/Button';
import LocationSelect from '@/components/views/LocationSelect';
import { mapGetters } from 'vuex';
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';

export default {
  name: 'FilterAnalyzesList',
  components: { DropdownSelectValue, Button, Input, LocationSelect },
  props: ['value'],
  data() {
    return {
      filter: {
        isActive: false,
        geo: null,
        analyseTypeId: null,
        analyseStatus: null,
        clientName: '',
        clientCode: '',
        ...this.value,
      },
    };
  },
  methods: {
    onFilterEdited: debounce(
      function () {
        this.$emit('input', this.filter);
      },
      200,
      { leading: true }
    ),
    onClearFilter() {
      this.filter.analyseStatus = null;
      this.filter.analyseTypeId = null;
      this.filter.geo = null;
      this.filter.clientCode = '';
      this.filter.clientName = '';
      this.$refs.location.reset();
    },
  },
  computed: {
    ...mapGetters(['geoMapped', 'analyzesTypes']),
    isFilterActive() {
      return (
        this.filter.analyseStatus !== null ||
        this.filter.analyseTypeId !== null ||
        this.filter.geo !== null ||
        this.filter.clientCode !== '' ||
        this.filter.clientName !== ''
      );
    },
  },
  watch: {
    'filter.geo'() {
      this.onFilterEdited();
    },
    'filter.analyseTypeId'() {
      this.onFilterEdited();
    },
    'filter.analyseStatus'() {
      this.onFilterEdited();
    },
    'filter.clientName'() {
      this.onFilterEdited();
    },
    'filter.clientCode'() {
      this.onFilterEdited();
    },
    isFilterActive() {
      this.filter.isActive = this.isFilterActive;
    },
  },
};
</script>

<style scoped></style>
