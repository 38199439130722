var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"radiotogglewrap",class:{
    'radiotogglewrap--active': _vm.isActive,
    'radiotogglewrap--disabled': _vm.disabled,
    'radiotogglewrap--onlyshow': _vm.disabled ? false : _vm.locked,
  },style:(_vm.locked ? 'pointer-events:none' : ''),on:{"click":function($event){_vm.activeD = true}}},[(!_vm.noLabel)?_c('label',{staticClass:"lable"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('fieldset',{staticClass:"inputradiotoggle",class:{
      'inputradiotoggle--withblocked': _vm.isTypeBlocked,
      'inputradiotoggle--withdisabled': _vm.isTypeDisabled,
    }},[(!_vm.reverse)?[_c('label',{staticClass:"radiotoggle"},[_c('input',{attrs:{"type":"radio","name":_vm.name,"disabled":_vm.disabled},domProps:{"checked":_vm.value == 0},on:{"change":function($event){return _vm.onChange(0, $event.returnValue)}}}),_c('span',[_vm._v(" "+_vm._s(_vm.text1)+" ")])]),_c('label',{staticClass:"radiotoggle"},[_c('input',{attrs:{"type":"radio","name":_vm.name,"disabled":_vm.disabled},domProps:{"checked":_vm.value == 1},on:{"change":function($event){return _vm.onChange(1, $event.returnValue)}}}),_c('span',[_vm._v(" "+_vm._s(_vm.text2)+" ")])])]:[_c('label',{staticClass:"radiotoggle"},[_c('input',{attrs:{"type":"radio","name":_vm.name,"disabled":_vm.disabled},domProps:{"checked":_vm.value == 1},on:{"change":function($event){if($event.target !== $event.currentTarget)return null;return _vm.onChange(1, $event.returnValue)}}}),_c('span',[_vm._v(" "+_vm._s(_vm.text2)+" ")])]),_c('label',{staticClass:"radiotoggle"},[_c('input',{attrs:{"type":"radio","name":_vm.name,"disabled":_vm.disabled},domProps:{"checked":_vm.value == 0},on:{"change":function($event){return _vm.onChange(0, $event.returnValue)}}}),_c('span',[_vm._v(" "+_vm._s(_vm.text1)+" ")])])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }