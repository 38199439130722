import axios from '@/api/axios';

export const getArticles = (payload) => {
  return axios.get('/clientsArea/articles/getArticles', {
    params: {
      articles: payload?.articles ?? 0,
    },
  });
};
export const getArticle = (payload) => {
  return axios.get('/clientsArea/articles/getArticle', {
    params: {
      id: payload?.id,
    },
  });
};
