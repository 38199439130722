<!--Translated-->
<template>
  <div class="modal modal--long">
    <div class="modalwrap2-topline">
      <div class="topline-minizone"></div>
      <Breadcrumps
        :data="[
          {
            /*title: `${
              isEdit
                ? $t('ModalContacts2:Title:Change')
                : $t('ModalContacts2:Title:Add')
            } ${$t('ModalContacts2:Title:Contact')}`,*/
            title: `${
              isEdit
                ? $t('ModalContacts2:Title:Change') +
                  ' ' +
                  $t('ModalContacts2:Title:Contact')
                : $t('ModalContacts2:Title:FullAdd')
            }`,
          },
        ]"
      />
      <div class="topline-minizone">
        <Button
          size="s-standart"
          color="accentlightlines"
          icon="x"
          @click="$emit('close')"
        />
      </div>
    </div>

    <div class="modalwrap2-content">
      <div class="modalwrap3-inputs margintop--standart marginbottom--standart">
        <DropdownSelect
          :placeholder="$t('ModalContacts2:SelectType.Placeholder')"
          :label="$t('ModalContacts2:SelectType.Label')"
          :disableNull="true"
          :options="types"
          v-model="type"
        />
        <Input
          type="text"
          :placeholder="$t('ModalContacts2:SelectValue.Placeholder')"
          :label="$t('ModalContacts2:SelectValue.Label')"
          size="long"
          v-model="value"
        />
      </div>
      <div class="modalwrap3-inputs marginbottom--standart">
        <Input
          type="text"
          :placeholder="$t('ModalContacts2:Comment.Placeholder')"
          :label="$t('ModalContacts2:Comment.Label')"
          size="max"
          v-model="comment"
        />
      </div>

      <div class="modalwrap3-buttons">
        <Button
          v-if="isEdit"
          :text="$t('ModalContacts2:ButtonDelete')"
          size="wide"
          color="alertlines"
          icon="delete"
          @click="remove"
        />
        <Button
          :text="$t('ModalContacts2:ButtonUndo')"
          size="wide"
          color="graylines"
          icon="cancel"
          @click="$emit('close')"
        />
        <Button
          :text="$t('ModalContacts2:ButtonSave')"
          size="wide"
          color="accent"
          icon="save"
          :disabled="!canSave"
          @click="save"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import DropdownSelect from '@/components/views/wrappers/DropdownSelect';

export default {
  name: 'ModalContacts2',
  props: {
    contact: { type: Object, default: null },
    index: { type: Number, default: null },
    contactTypes: { type: Array, required: true },
  },
  components: { Input, Button, Breadcrumps, DropdownSelect },
  data() {
    return { type: null, value: '', comment: '' };
  },
  created() {
    if (this.isEdit) {
      this.type = this.types.find(
        (e) => e.value === this.contact.contact_type_id
      );
      this.value = this.contact.value;
      this.comment = this.contact.comment;
    }
  },
  computed: {
    isEdit() {
      return Boolean(this.contact);
    },
    types() {
      return this.contactTypes.map((e) => ({
        value: e.id,
        icon: e.icon,
        text: e.name || e.name_ru,
      }));
    },
    canSave() {
      return Number.isInteger(this.type?.value) && this.value.length;
    },
  },
  methods: {
    save() {
      this.$emit('change', {
        id: this.index,
        type: this.type?.value,
        value: this.value,
        comment: this.comment,
      });
      this.$emit('close');
    },
    remove() {
      this.$emit('delete', this.index);
      this.$emit('close');
    },
  },
};
</script>
