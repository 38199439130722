<-- Translated -->
<template>
  <div class="filter">
    <div class="filter-inputszone">
      <div class="filterwrap filterwrap--users">
        <div class="filterwrap_line">
          <Input
            type="text"
            :placeholder="$t('FilterUsers:InputId.Placeholder')"
            :label="$t('FilterUsers:InputId.Label')"
            icon=""
            state=""
            size="standart"
            color=""
            v-model="filter.pn"
            @input="$emit('input', filter)"
          />
          <Input
            type="text"
            :placeholder="$t('FilterUsers:InputName.Placeholder')"
            :label="$t('FilterUsers:InputName.Label')"
            icon=""
            state=""
            size="standart"
            color=""
            v-model="filter.name"
            @input="$emit('input', filter)"
          />
          <Input
            type="text"
            :placeholder="$t('FilterUsers:InputSurname.Placeholder')"
            :label="$t('FilterUsers:InputSurname.Label')"
            icon=""
            state=""
            size="standart"
            color=""
            v-model="filter.full_name"
            @input="$emit('input', filter)"
          />
        </div>
        <div class="filterwrap_line">
          <Input
            type="text"
            :placeholder="$t('FilterUsers:InputLogin.Placeholder')"
            :label="$t('FilterUsers:InputLogin.Label')"
            icon=""
            state=""
            size="standart"
            color=""
            v-model="filter.username"
            @input="$emit('input', filter)"
          />
          <Input
            type="dropdown"
            :placeholder="$t('FilterUsers:InputStatus.Placeholder')"
            :label="$t('FilterUsers:InputStatus.Label')"
            icon=""
            state=""
            size="standart"
            color=""
            :dd-items="[
              { text: $t('FilterUsers:InputStatus.NotSelected'), value: null },
              { text: $t('FilterUsers:InputStatus.Active'), value: '0' },
              { text: $t('FilterUsers:InputStatus.Blocked'), value: '1' },
            ]"
            @selected="
              filter.status = $event.item.value;
              ddStatusSelectedIndex = $event.index;
              $emit('input', filter);
            "
            :dd-selected-index="ddStatusSelectedIndex"
          />
          <Input
            type="dropdown"
            :placeholder="$t('FilterUsers:InputRole.Placeholder')"
            :label="$t('FilterUsers:InputRole.Label')"
            icon=""
            state=""
            size="standart"
            color=""
            :dd-items="roleDDItems"
            @selected="
              filter.role = $event.item.value;
              ddRoleSelectedIndex = $event.index;
              $emit('input', filter);
            "
            :dd-selected-index="ddRoleSelectedIndex"
          />
        </div>
      </div>
    </div>

    <div class="filter-clearbtnzone">
      <Button
        class="filter_buttonclear"
        text="очистить"
        size="s-standart"
        :color="isFilterActive ? 'accentlines' : 'graylines'"
        icon="backspace"
        :is-loading="false"
        :loading-left="true"
        @click="clearFilter"
      />
    </div>
  </div>
</template>

<script>
import Input from '@/components/views/Input';
import Button from '@/components/views/Button';
export default {
  name: 'FilterUsers',
  components: {
    Button,
    Input,
  },
  props: {
    value: {
      default: {},
    },
  },
  data() {
    return {
      filter: {
        pn: '',
        name: '',
        full_name: '',
        username: '',
        status: '',
        role: '',
        ...this.value,
      },
      ddStatusSelectedIndex: 0,
      ddRoleSelectedIndex: 0,
    };
  },
  computed: {
    roleDDItems() {
      let arr = [
        { text: this.$t('FilterUsers:InputStatus.NotSelected'), value: null },
      ];
      if (this.$store.state.admin.roles) {
        this.$store.state.admin.roles.forEach((role) => {
          arr.push({ text: role.name, value: role.id });
        });
      }
      return arr;
    },
    isFilterActive() {
      let filterIsActive = false;
      for (let filterKey in this.filter) {
        if (this.filter[filterKey]) {
          filterIsActive = true;
          break;
        }
      }
      return filterIsActive;
    },
  },
  methods: {
    clearFilter() {
      this.filter.status = '';
      this.filter.pn = '';
      this.filter.username = '';
      this.filter.name = '';
      this.filter.full_name = '';
      this.filter.role = '';
      this.ddStatusSelectedIndex = 0;
      this.ddRoleSelectedIndex = 0;

      this.$emit('input', this.filter);
    },
  },
};
</script>

<style lang="scss" scoped></style>
