import axios from '@/api/axios';
import { clientEntitiesKeys } from '@/store/modules/clientsArea/clients';

const getEditClientData = (geoBase = 0) =>
  axios
    .get('/clientsArea/admin/getMyCultures', {
      params: {
        geoBase,
      },
    })
    .then(({ data }) => {
      if (data.clientZonesAnalyzes)
        data.clientZonesAnalyzes.sort((a, b) => a.date - b.date);
      clientEntitiesKeys.forEach((key) => (data[key] ??= []));
      data.clientZonesAnalyzes.forEach((a) => {
        if (a.date === 0) a.date = null;
      });
      data.clientAnalyzesElements.forEach((e) => {
        if (e.quantity === 0) e.quantity = null;
      });
      return data;
    });

const submitClientData = (payload) => {
  const formData = new FormData();

  payload.clientAnalyzesFiles
    .filter((f) => f.file)
    .forEach((fileEntry) => {
      formData.append('files', fileEntry.file);
      delete fileEntry.file;
      fileEntry.order = [...formData.keys()].length - 1;
    });

  payload.clientAnalyzesElements = payload.clientAnalyzesElements
    .filter(({ quantity }) => quantity !== '')
    .map((e) => ({ ...e, quantity: Number(e.quantity) }));

  formData.append('clientData', JSON.stringify(payload));
  return axios
    .post('/clientsArea/admin/setMyCultures', formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((r) => r.data);
};

export default {
  submitClientData,
  getEditClientData,
};
