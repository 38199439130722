<template>
  <Input
    v-bind="$attrs"
    :value="value"
    :state="disabled ? 'disabled' : locked ? 'disabled2' : ''"
    @input="input"
  />
</template>

<script>
import Input from '@/components/views/Input';

export default {
  components: { Input },
  props: ['value', 'disabled', 'locked'],
  methods: {
    input(value) {
      if (Number.isInteger(Number(value))) this.$emit('input', Number(value));
    },
  },
};
</script>
