var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal modal--long"},[_c('div',{staticClass:"modalwrap2-topline"},[_c('div',{staticClass:"topline-minizone"}),_c('Breadcrumps',{attrs:{"data":[
        {
          /*title: `${
            isEdit
              ? $t('ModalContacts2:Title:Change')
              : $t('ModalContacts2:Title:Add')
          } ${$t('ModalContacts2:Title:Contact')}`,*/
          title: `${
            _vm.isEdit
              ? _vm.$t('ModalContacts2:Title:Change') +
                ' ' +
                _vm.$t('ModalContacts2:Title:Contact')
              : _vm.$t('ModalContacts2:Title:FullAdd')
          }`,
        },
      ]}}),_c('div',{staticClass:"topline-minizone"},[_c('Button',{attrs:{"size":"s-standart","color":"accentlightlines","icon":"x"},on:{"click":function($event){return _vm.$emit('close')}}})],1)],1),_c('div',{staticClass:"modalwrap2-content"},[_c('div',{staticClass:"modalwrap3-inputs margintop--standart marginbottom--standart"},[_c('DropdownSelect',{attrs:{"placeholder":_vm.$t('ModalContacts2:SelectType.Placeholder'),"label":_vm.$t('ModalContacts2:SelectType.Label'),"disableNull":true,"options":_vm.types},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('Input',{attrs:{"type":"text","placeholder":_vm.$t('ModalContacts2:SelectValue.Placeholder'),"label":_vm.$t('ModalContacts2:SelectValue.Label'),"size":"long"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('div',{staticClass:"modalwrap3-inputs marginbottom--standart"},[_c('Input',{attrs:{"type":"text","placeholder":_vm.$t('ModalContacts2:Comment.Placeholder'),"label":_vm.$t('ModalContacts2:Comment.Label'),"size":"max"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),_c('div',{staticClass:"modalwrap3-buttons"},[(_vm.isEdit)?_c('Button',{attrs:{"text":_vm.$t('ModalContacts2:ButtonDelete'),"size":"wide","color":"alertlines","icon":"delete"},on:{"click":_vm.remove}}):_vm._e(),_c('Button',{attrs:{"text":_vm.$t('ModalContacts2:ButtonUndo'),"size":"wide","color":"graylines","icon":"cancel"},on:{"click":function($event){return _vm.$emit('close')}}}),_c('Button',{attrs:{"text":_vm.$t('ModalContacts2:ButtonSave'),"size":"wide","color":"accent","icon":"save","disabled":!_vm.canSave},on:{"click":_vm.save}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }