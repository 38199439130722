<template>
  <div class="chkwrap-block chkwrap-block--withlink">
    <div
      class="chkwrap"
      :class="{ 'chkwrap--disabled': disabled, [colorClass]: true }"
      @click="onClick"
    >
      <div class="chkbox">
        <div v-svg="{ name: 'tick' }"></div>
      </div>

      <div class="chktext">
        {{ $t('CheckBoxUserAgreeLink:Text1') }}
        <a
          href="https://drive.google.com/file/d/1Bl_hu8FEzONxr0xMZnLPqDKV3ZVQqE51/view"
          target="_blank"
          @click.stop=""
          >{{ $t('CheckBoxUserAgreeLink:LinkText') }}</a
        >
        <!--{{ $t('CheckBoxUserAgreeLink:Text1') }}-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckBoxUserAgreeLink',
  props: {
    disabled: {
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    value: { default: false },
    colorChecked: {
      type: String,
      default: 'accentfill',
    },
    colorUnChecked: {
      type: String,
      default: 'accent',
    },
  },
  data() {
    return {
      checked: this.value === 1 || this.value === true,
    };
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.checked = !this.checked;
        this.$emit('input', this.checked);
      }
    },
  },
  computed: {
    colorClass() {
      if (this.checked) {
        return `chkwrap--checked-${this.colorChecked}`;
      } else {
        return `chkwrap--nocheck-${this.colorUnChecked}`;
      }
    },
  },
  watch: {
    value() {
      this.checked = this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/vars.scss';

/*.chkwrap-block {
  display: flex;
}

.chkwrap {
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;

  & .chkbox {
    background-color: transparent;
    border: $borderButton solid $colorLinesGrayLight;
    border-radius: $borderRadiusMicro;
    box-sizing: border-box;
    height: 24px;
    width: 24px;
    min-width: 24px;

    & svg {
      display: none;
      width: 100%;
      height: 100%;
      padding: 2px;
      box-sizing: border-box;
    }

    & .svgfill {
      fill: $colorGrayLight;
    }
  }

  & .chktext {
    max-width: 22ch;
    font-size: $fontsizeInput;
    line-height: $lineFontsizeInput;
    margin-left: $marginMicro;
    color: $colorTextGrayLight;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

//Состояния
.chkwrap--checked {
  & .chkbox {
    background-color: transparent;
    border: $borderButton solid $colorLinesGrayLight;

    & svg {
      display: block;
    }

    & .svgfill {
      fill: $colorGrayLight;
    }
  }

  & .chktext {
    color: $colorTextGrayLight;
  }
}
.chkwrap--disabled {
  cursor: auto;

  & .chkbox {
    background-color: transparent !important;
    border: $borderButton solid $colorLinesGrayLight !important;

    & .svgfill {
      fill: $colorGrayLight !important;
    }
  }

  & .chktext {
    color: $colorTextGrayLight !important;
  }
}

//Цвета базовые
.chkwrap--nocheck-accent {
  & .chkbox {
    background-color: $colorWhite;
    border: $borderButton solid $colorLinesAccent;
  }

  & .chktext {
    color: $colorTextAccent;
  }

  &:hover {
    & .chkbox {
      background-color: $colorWhite;
      border: $borderButton solid $colorLinesAccentDark;
    }

    & .chktext {
      color: $colorTextAccentDark;
    }
  }
}
.chkwrap--nocheck-accentlight {
  & .chkbox {
    background-color: $colorWhite;
    border: $borderButton solid $colorLinesAccentLight;
  }

  & .chktext {
    color: $colorTextAccentLight;
  }

  &:hover {
    & .chkbox {
      background-color: $colorWhite;
      border: $borderButton solid $colorLinesAccent;
    }

    & .chktext {
      color: $colorTextAccent;
    }
  }
}
.chkwrap--nocheck-gray {
  & .chkbox {
    background-color: $colorWhite;
    border: $borderButton solid $colorLinesGray;
  }

  & .chktext {
    color: $colorTextGray;
  }

  &:hover {
    & .chkbox {
      background-color: $colorWhite;
      border: $borderButton solid $colorLinesAccent;
    }

    & .chktext {
      color: $colorTextAccent;
    }
  }
}
.chkwrap--nocheck-graylight {
  & .chkbox {
    background-color: $colorWhite;
    border: $borderButton solid $colorLinesGrayLight;
  }

  & .chktext {
    color: $colorTextGrayLight;
  }

  &:hover {
    & .chkbox {
      background-color: $colorWhite;
      border: $borderButton solid $colorLinesAccent;
    }

    & .chktext {
      color: $colorTextAccent;
    }
  }
}

//Цвета чекед
.chkwrap--checked-accent {
  & .chkbox {
    background-color: $colorWhite;
    border: $borderButton solid $colorLinesAccent;

    & svg {
      display: block;
    }

    & .svgfill {
      fill: $colorAccent;
    }
  }

  & .chktext {
    color: $colorTextAccent;
  }

  &:hover {
    & .chkbox {
      background-color: $colorWhite;
      border: $borderButton solid $colorLinesAccent;

      & svg {
        display: block;
      }

      & .svgfill {
        fill: $colorAccent;
      }
    }

    & .chktext {
      color: $colorTextAccent;
    }
  }
}
.chkwrap--checked-accentlight {
  & .chkbox {
    background-color: $colorWhite;
    border: $borderButton solid $colorLinesAccentLight;
    & svg {
      display: block;
    }
    & .svgfill {
      fill: $colorAccentLight;
    }
  }

  & .chktext {
    color: $colorTextAccentLight;
  }

  &:hover {
    & .chkbox {
      background-color: $colorWhite;
      border: $borderButton solid $colorLinesAccentLight;
      & svg {
        display: block;
      }
      & .svgfill {
        fill: $colorAccentLight;
      }
    }

    & .chktext {
      color: $colorTextAccentLight;
    }
  }
}
.chkwrap--checked-gray {
  & .chkbox {
    background-color: $colorWhite;
    border: $borderButton solid $colorLinesGray;
    & svg {
      display: block;
    }
    & .svgfill {
      fill: $colorGray;
    }
  }

  & .chktext {
    color: $colorTextGray;
  }

  &:hover {
    & .chkbox {
      background-color: $colorWhite;
      border: $borderButton solid $colorLinesGray;
      & svg {
        display: block;
      }
      & .svgfill {
        fill: $colorGray;
      }
    }
    & .chktext {
      color: $colorTextGray;
    }
  }
}
.chkwrap--checked-graylight {
  & .chkbox {
    background-color: $colorWhite;
    border: $borderButton solid $colorLinesGrayLight;
    & svg {
      display: block;
    }
    & .svgfill {
      fill: $colorGrayLight;
    }
  }

  & .chktext {
    color: $colorTextGrayLight;
  }

  &:hover {
    & .chkbox {
      background-color: $colorWhite;
      border: $borderButton solid $colorLinesGrayLight;
      & svg {
        display: block;
      }
      & .svgfill {
        fill: $colorGrayLight;
      }
    }

    & .chktext {
      color: $colorTextGrayLight;
    }
  }
}

.chkwrap--checked-accentfill {
  & .chkbox {
    background-color: $colorAccent;
    border: $borderButton solid $colorLinesAccent;

    & svg {
      display: block;
    }

    & .svgfill {
      fill: $colorWhite;
    }
  }

  & .chktext {
    color: $colorTextAccent;
  }

  &:hover {
    & .chkbox {
      background-color: $colorAccent;
      border: $borderButton solid $colorLinesAccent;

      & svg {
        display: block;
      }

      & .svgfill {
        fill: $colorWhite;
      }
    }

    & .chktext {
      color: $colorTextAccent;
    }
  }
}

.chkwrap--colorempty-accentlight {
  & .chkbox {
    background-color: transparent;
    border: $borderButton solid $colorLinesAccentLight;

    & .svgfill {
      fill: $colorAccent;
    }
  }

  & .chktext {
    color: $colorTextGrayLight;
  }
}
.chkwrap--colorempty-accentlight {
  & .chkbox {
    background-color: transparent;
    border: $borderButton solid $colorLinesAccentLight;

    & .svgfill {
      fill: $colorAccent;
    }
  }

  & .chktext {
    color: $colorTextGrayLight;
  }
}

@media screen and (max-width: 1800px) {
  .chkwrap {
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;

    & .chkbox {
      border-radius: $borderRadiusMicro-k1600;
      height: 24px * $k1600;
      width: 24px * $k1600;
      min-width: 24px * $k1600;

      & svg {
        padding: 2px;
      }
    }

    & .chktext {
      font-size: $fontsizeInput-k1600;
      line-height: $lineFontsizeInput-k1600;
      margin-left: $marginMicro-k1600;
    }
  }
}

@media screen and (max-width: 1420px) {
  .chkwrap {
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;

    & .chkbox {
      border-radius: $borderRadiusMicro-k1360;
      height: 24px * $k1360;
      width: 24px * $k1360;
      min-width: 24px * $k1360;

      & svg {
        padding: 2px;
      }
    }

    & .chktext {
      max-width: 20ch;
      font-size: $fontsizeInput-k1360;
      line-height: $lineFontsizeInput-k1360;
      margin-left: $marginMicro-k1360;
    }
  }
}*/
</style>
