<!--Translated-->
<template>
  <div class="rightzone_table" v-if="!templateFetching">
    <LoaderBlock v-if="templatesSearching" />
    <div v-else class="cwrap marginbottom--standart">
      <div class="cblock_bigtext">
        {{ $t('TemplatesList:NoTemplates1') }}
        <!--<br />
        {{ $t('TemplatesList:NoTemplates2') }}-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mutationTypes } from '@/store/modules/clientsArea/programs';

import LoaderBlock from '@/components/blocks/LoaderBlock';

export default {
  name: 'UserTemplatesList',
  components: { LoaderBlock },
  props: { templates: { type: Array, required: true } },
  computed: {
    ...mapGetters(['geoPlainDataAsMap']),
    ...mapState({
      cultures: (state) => state.userDefence.cultures,
      templatesSearching: (state) => state.userDefence.templatesSearching,
      templateFetching: (state) => state.userDefence.templateFetching,
    }),
    headers() {
      return [
        {
          text: '',
          class: 'col-warning',
          icon: 'warningdiese',
          type: 'warning',
        },
        {
          text: this.$t('TemplatesList:Table:Headers:0'),
          width: '85%',
          type: 'text',
        },
        {
          text: this.$t('TemplatesList:Table:Headers:1'),
          class: '',
          width: '15%',
          type: 'select',
        },
      ];
    },
    rows() {
      return [...this.templates]
        .sort((a, b) => b.is_default - a.is_default)
        .map((t) =>
          [t.is_default, t.name, t.id].map((text) => ({
            text,
          }))
        );
    },
  },
  methods: {
    selectTemplate(id) {
      this.$store.commit(mutationTypes.selectTemplate, Number(id));
    },
  },
};
</script>
