<!--Translated-->
<template>
  <div class="cwrap">
    <div class="cblock el--borders-all el--rounded-all marginbottom--standart">
      <div class="cblock__internal">
        <div class="cblock__line">
          <div class="cblock__line-block">
            <div class="inpt-datewrap inpt-dates inpt-dates--noyear1">
              <DropdownSelect
                size="micro"
                isForDate="true"
                ddMaxElements="5"
                :placeholder="
                  $t('FertilizerProgramPeriodSelection:Year.Placeholder')
                "
                :label="$t('FertilizerProgramPeriodSelection:Start')"
                :notSelectedText="
                  $t('FertilizerProgramPeriodSelection:Year.NotSelected')
                "
                :resetValueOnOptionsChange="true"
                :options="yearsStart"
                v-model="selectedYearStart"
                :disabled="disabled"
                :locked="locked"
              />
              <DropdownSelect
                size="micro"
                isForDate="true"
                ddMaxElements="5"
                :placeholder="
                  $t('FertilizerProgramPeriodSelection:Month.Placeholder')
                "
                :notSelectedText="
                  $t('FertilizerProgramPeriodSelection:Month.NotSelected')
                "
                :disabled="disabled || !selectedYearStart"
                :locked="locked"
                :resetValueOnOptionsChange="true"
                :options="monthsStart"
                v-model="selectedMonthStart"
              />
              <DropdownSelect
                size="micro"
                isForDate="true"
                ddMaxElements="5"
                :placeholder="
                  $t('FertilizerProgramPeriodSelection:Day.Placeholder')
                "
                :notSelectedText="
                  $t('FertilizerProgramPeriodSelection:Day.NotSelected')
                "
                :resetValueOnOptionsChange="true"
                :options="daysStart"
                :disabled="disabled || selectedMonthStart === null"
                :locked="locked"
                v-model="selectedDayStart"
              />
            </div>
            <div class="inpt-datewrap inpt-dates inpt-dates--noyear1">
              <DropdownSelect
                size="micro"
                isForDate="true"
                ddMaxElements="5"
                :placeholder="
                  $t('FertilizerProgramPeriodSelection:Year.Placeholder')
                "
                :label="$t('FertilizerProgramPeriodSelection:End')"
                :notSelectedText="
                  $t('FertilizerProgramPeriodSelection:Year.NotSelected')
                "
                :resetValueOnOptionsChange="true"
                :options="yearsEnd"
                v-model="selectedYearEnd"
                :disabled="disabled"
                :locked="locked"
              />
              <DropdownSelect
                size="micro"
                isForDate="true"
                ddMaxElements="5"
                :placeholder="
                  $t('FertilizerProgramPeriodSelection:Month.Placeholder')
                "
                :notSelectedText="
                  $t('FertilizerProgramPeriodSelection:Month.NotSelected')
                "
                :disabled="disabled || !selectedYearEnd"
                :locked="locked"
                :resetValueOnOptionsChange="true"
                :options="monthsEnd"
                v-model="selectedMonthEnd"
              />
              <DropdownSelect
                size="micro"
                isForDate="true"
                ddMaxElements="5"
                :placeholder="
                  $t('FertilizerProgramPeriodSelection:Day.Placeholder')
                "
                :notSelectedText="
                  $t('FertilizerProgramPeriodSelection:Day.NotSelected')
                "
                :resetValueOnOptionsChange="true"
                :options="daysEnd"
                :disabled="disabled || selectedMonthEnd === null"
                :locked="locked"
                v-model="selectedDayEnd"
              />
            </div>
          </div>
          <div class="cblock__line-block flex-end">
            <Button
              text=""
              size="s-standart"
              color="accentlightlines"
              icon="info"
              @click="modalShown = true"
              :disabled="!hasPhases"
            />
          </div>
        </div>
        <div class="cblock__line">
          <div class="cblock__line-block">
            <Input
              type="text"
              :placeholder="
                $t('FertilizerProgramPeriodSelection:Comment.Placeholder')
              "
              :label="$t('FertilizerProgramPeriodSelection:Comment.Label')"
              size="max"
              v-model="comment"
              :state="disabled ? 'disabled' : locked ? 'disabled2' : ''"
            />
          </div>
        </div>
      </div>
    </div>
    <FertilizerModal v-if="modalShown" @close="modalShown = false" />
  </div>
</template>

<script>
import {
  getDate,
  isMonday,
  isSunday,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
} from 'date-fns';
import { ka, ru } from 'date-fns/locale';

import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import DropdownSelect from '@/components/views/wrappers/DropdownSelectValue';
import FertilizerModal from './FertilizerModal';

import { mutationTypes } from '@/store/modules/nutritionPrograms';
import { mapState } from 'vuex';

const mapYear = (year) => ({
  text: String(year),
  value: year,
});

/*const mapMonth = (month) => ({
  text: ru.localize.month(month),
  value: month,
});*/

const mapDay = (day) => {
  return {
    text: String(day),
    value: day,
  };
};

const firstYear = 2022;
const yearsRange = [...Array(100)].map((_, index) => firstYear + index);
const monthsRange = [...Array(12)].map((_, m) => m);

export default {
  name: 'FertilizerProgramPeriodSelection',
  components: { Input, Button, DropdownSelect, FertilizerModal },
  props: {
    period: { type: Object },
    disabled: { type: Boolean, default: false },
    locked: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedYearStart: null,
      selectedYearEnd: null,
      selectedMonthStart: null,
      selectedMonthEnd: null,
      selectedDayStart: null,
      selectedDayEnd: null,
      modalShown: false,
    };
  },
  computed: {
    ...mapState({
      hasPhases: (state) => Boolean(state.nutrition.programPhases.length),
    }),
    yearsStart() {
      return yearsRange
        .filter((year) =>
          this.selectedYearEnd === null
            ? true
            : year <= this.selectedYearEnd && year >= this.selectedYearEnd - 1
        )
        .map(mapYear);
    },
    yearsEnd() {
      return yearsRange
        .filter((year) =>
          this.selectedYearStart === null
            ? true
            : year >= this.selectedYearStart &&
              year <= this.selectedYearStart + 1
        )
        .map(mapYear);
    },
    monthsStart() {
      return monthsRange
        .filter((month) =>
          this.selectedYearEnd > this.selectedYearStart ||
          this.selectedMonthEnd === null
            ? true
            : month <= this.selectedMonthEnd
        )
        .map(this.mapMonth);
    },
    monthsEnd() {
      return monthsRange
        .filter((month) =>
          this.selectedYearEnd > this.selectedYearStart ||
          this.selectedMonthStart === null
            ? true
            : month >= this.selectedMonthStart
        )
        .map(this.mapMonth);
    },
    daysStart() {
      if (this.selectedYearStart === null || this.selectedMonthStart === null) {
        return [];
      }
      const date = new Date(this.selectedYearStart, this.selectedMonthStart);
      return eachDayOfInterval({
        start: startOfMonth(date),
        end: endOfMonth(date),
      })
        .filter(isMonday)
        .map(getDate)
        .filter((day) =>
          this.selectedYearEnd > this.selectedYearStart ||
          this.selectedMonthEnd > this.selectedMonthStart ||
          this.selectedDayEnd === null
            ? true
            : day <= this.selectedDayEnd
        )
        .map(mapDay);
    },
    daysEnd() {
      if (this.selectedYearEnd === null || this.selectedMonthEnd === null) {
        return [];
      }
      const date = new Date(this.selectedYearEnd, this.selectedMonthEnd);
      return eachDayOfInterval({
        start: startOfMonth(date),
        end: endOfMonth(date),
      })
        .filter(isSunday)
        .map(getDate)
        .filter((day) =>
          this.selectedYearEnd > this.selectedYearStart ||
          this.selectedMonthEnd > this.selectedMonthStart ||
          this.selectedDayStart === null
            ? true
            : day >= this.selectedDayStart
        )
        .map(mapDay);
    },
    comment: {
      get() {
        return this.$store.state.nutrition.program.period_description;
      },
      set(comment) {
        this.$store.commit(mutationTypes.setPeriodComment, comment);
      },
    },
  },
  watch: {
    period: {
      handler(value) {
        if (value) this.update();
      },
      immediate: true,
    },
    ...Object.fromEntries(
      [
        'selectedDayStart',
        'selectedDayEnd',
        'selectedMonthStart',
        'selectedMonthEnd',
        'selectedYearStart',
        'selectedYearEnd',
      ].map((key) => [
        key,
        function () {
          this.emitEvent();
        },
      ])
    ),
  },
  methods: {
    update() {
      Object.entries(this.period).forEach(([k, v]) => (this[k] = v ?? null));
    },
    emitEvent() {
      if (this.selectedDayStart !== null && this.selectedDayEnd !== null) {
        const copy = { ...this.$data };
        delete copy.modalShown;
        this.$emit('change', copy);
      }
    },
    mapMonth(month) {
      return {
        text:
          this.$i18n.locale === 'ru'
            ? ru.localize.month(month)
            : ka.localize.month(month),
        value: month,
      };
    },
  },
};
</script>
