<template>
  <article class="rightzone rightzone--userarticleslist">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />
    </div>

    <div class="articleslist cblock__internalpadding">
      <div class=" ">
        <div class="articleslist_line">
          <p>Основные элементы питания</p>
        </div>
        <div class="articleslist_line">
          <p>Как правильно выбрать удобрение?</p>
        </div>
        <div class="articleslist_line">
          <p>Что такое стимуляторы растений?</p>
        </div>
        <div class="articleslist_line">
          <p>Что делать при высоком pH и EC почвы?</p>
        </div>
        <div class="articleslist_line">
          <p>Принципы создания программы питания и стимуляции растений</p>
        </div>
        <div class="articleslist_line">
          <p>Каталоги</p>
        </div>
        <div class="articleslist_line">
          <p>Где купить</p>
        </div>
        <div class="articleslist_line">
          <p>Часто задаваемые вопросы</p>
        </div>
      </div>
    </div>

    <UserFooter />
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import UserFooter from '@/pages/userproject/userComponents/UserFooter';
import routeNames from "@/router/routeNames";

export default {
  name: 'UserArticlesList1',
  components: {
    UserFooter,
    Breadcrumps,
  },
  computed: {
    breadcrumpsArr() {
      return [
        { title: 'Статьи', route: routeNames.userHomePage },
        { title:  'Питание растений'},
      ];
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/vars.scss';
</style>
