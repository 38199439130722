<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('RolesAddEdit:TopButtons:Cancel')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'Roles' })"
        />
        <Button
          :text="$t('RolesAddEdit:TopButtons:Save')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="isLoading"
          :loading-left="true"
          :disabled="isSaveButtonDisabled"
          @click="onSave"
        />
      </div>
    </div>

    <div class="cwrap cwrap--level1 marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'settings' }"
          ></div>
          <div class="chead_text">{{ $t('RolesAddEdit:HeaderText') }}</div>
        </div>
      </div>
      <div
        class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                :placeholder="$t('RolesAddEdit:RoleName.Placeholder')"
                :label="$t('RolesAddEdit:RoleName.Label')"
                icon=""
                :state="nameInputState"
                size=""
                color=""
                v-model="newRole.name"
                @input="validationNameError = false"
              />
              <Input
                type="text"
                :placeholder="$t('RolesAddEdit:RoleDescription.Placeholder')"
                :label="$t('RolesAddEdit:RoleDescription.Label')"
                icon=""
                :state="roleInputState"
                size="max"
                color=""
                v-model="newRole.description"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="cwrap cwrap--level1 marginbottom--standart"
      :disabled="isLoading || isEditRoleLoading"
    >
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'edit' }"
          ></div>
          <div class="chead_text">{{ $t('RolesAddEdit:HeaderText2') }}</div>
        </div>
      </div>

      <div
        class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
      >
        <div class="cblock__internal">
          <ul class="list list--userrights">
            <li class="list-block">
              <div class="list-block_htext">
                {{ $t('RolesAddEdit:PermissionUsers:Text') }}
              </div>

              <ul class="list-block_list">
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim('claim_users_see', !newRole.claim_users_see)
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionUsers:See') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_users_see"
                      @input="setClaim('claim_users_see', $event)"
                      :disabled="
                        isLoading ||
                        isEditRoleLoading ||
                        !canEdit ||
                        newRole.claim_users_edit
                      "
                    />
                  </div>
                </li>
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim('claim_users_edit', !newRole.claim_users_edit)
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionUsers:Edit') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_users_edit"
                      @input="setClaim('claim_users_edit', $event)"
                      :disabled="isLoading || isEditRoleLoading || !canEdit"
                    />
                  </div>
                </li>
              </ul>
            </li>

            <li class="list-block">
              <div class="list-block_htext">
                {{ $t('RolesAddEdit:PermissionBanners:Text') }}
              </div>
              <ul class="list-block_list">
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_banners_edit',
                          !newRole.claim_banners_edit
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionBanners:Edit') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_banners_edit"
                      @input="setClaim('claim_banners_edit', $event)"
                      :disabled="isLoading || isEditRoleLoading || !canEdit"
                    />
                  </div>
                </li>
              </ul>
            </li>

            <li class="list-block">
              <div class="list-block_htext">
                {{ $t('RolesAddEdit:PermissionArticles:Text') }}
              </div>
              <ul class="list-block_list">
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_articles_edit',
                          !newRole.claim_articles_edit
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionArticles:Edit') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_articles_edit"
                      @input="setClaim('claim_articles_edit', $event)"
                      :disabled="isLoading || isEditRoleLoading || !canEdit"
                    />
                  </div>
                </li>
              </ul>
            </li>
            <li class="list-block">
              <div class="list-block_htext">
                {{ $t('RolesAddEdit:PermissionInstructions:Text') }}
              </div>
              <ul class="list-block_list">
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_instructions_edit',
                          !newRole.claim_instructions_edit
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionInstructions:Edit') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_instructions_edit"
                      @input="setClaim('claim_instructions_edit', $event)"
                      :disabled="isLoading || isEditRoleLoading"
                    />
                  </div>
                </li>
              </ul>
            </li>

            <li class="list-block">
              <div class="list-block_htext">
                {{ $t('RolesAddEdit:PermissionGloss:Text') }}
              </div>
              <ul class="list-block_list">
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_glossary_see',
                          !newRole.claim_glossary_see
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionGloss:See') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_glossary_see"
                      @input="setClaim('claim_glossary_see', $event)"
                      :disabled="
                        isLoading ||
                        isEditRoleLoading ||
                        !canEdit ||
                        newRole.claim_glossary_edit
                      "
                    />
                  </div>
                </li>
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_glossary_edit',
                          !newRole.claim_glossary_edit
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionGloss:Edit') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_glossary_edit"
                      @input="setClaim('claim_glossary_edit', $event)"
                      :disabled="isLoading || isEditRoleLoading || !canEdit"
                    />
                  </div>
                </li>
              </ul>
            </li>

            <li class="list-block">
              <div class="list-block_htext">
                {{ $t('RolesAddEdit:PermissionAnalyzes:Text') }}
              </div>
              <ul class="list-block_list">
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_analyse_see',
                          !newRole.claim_analyse_see
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionAnalyzes:See') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_analyse_see"
                      @input="setClaim('claim_analyse_see', $event)"
                      :disabled="
                        isLoading ||
                        isEditRoleLoading ||
                        !canEdit ||
                        newRole.claim_analyse_edit
                      "
                    />
                  </div>
                </li>
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_analyse_edit',
                          !newRole.claim_analyse_edit
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionAnalyzes:Edit') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_analyse_edit"
                      @input="setClaim('claim_analyse_edit', $event)"
                      :disabled="isLoading || isEditRoleLoading || !canEdit"
                    />
                  </div>
                </li>
              </ul>
            </li>

            <li class="list-block">
              <div class="list-block_htext">
                {{ $t('RolesAddEdit:PermissionProducts:Text') }}
              </div>
              <ul class="list-block_list">
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_products_see',
                          !newRole.claim_products_see
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionProducts:See') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_products_see"
                      @input="setClaim('claim_products_see', $event)"
                      :disabled="
                        isLoading ||
                        isEditRoleLoading ||
                        !canEdit ||
                        newRole.claim_products_add
                      "
                    />
                  </div>
                </li>
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_products_add',
                          !newRole.claim_products_add
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionProducts:Add') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_products_add"
                      @input="setClaim('claim_products_add', $event)"
                      :disabled="
                        isLoading ||
                        isEditRoleLoading ||
                        !canEdit ||
                        newRole.claim_products_edit
                      "
                    />
                  </div>
                </li>
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_products_edit',
                          !newRole.claim_products_edit
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionProducts:Edit') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_products_edit"
                      @input="setClaim('claim_products_edit', $event)"
                      :disabled="isLoading || isEditRoleLoading || !canEdit"
                    />
                  </div>
                </li>
              </ul>
            </li>

            <li class="list-block">
              <div class="list-block_htext">
                {{ $t('RolesAddEdit:PermissionClients:Text') }}
              </div>
              <ul class="list-block_list">
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_clients_see',
                          !newRole.claim_clients_see
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionClients:See') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_clients_see"
                      @input="setClaim('claim_clients_see', $event)"
                      :disabled="
                        isLoading ||
                        isEditRoleLoading ||
                        !canEdit ||
                        newRole.claim_clients_add
                      "
                    />
                  </div>
                </li>
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_clients_add',
                          !newRole.claim_clients_add
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionClients:Add') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_clients_add"
                      @input="setClaim('claim_clients_add', $event)"
                      :disabled="
                        isLoading ||
                        isEditRoleLoading ||
                        !canEdit ||
                        newRole.claim_clients_edit
                      "
                    />
                  </div>
                </li>
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_clients_edit',
                          !newRole.claim_clients_edit
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionClients:Edit') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_clients_edit"
                      @input="setClaim('claim_clients_edit', $event)"
                      :disabled="
                        isLoading ||
                        isEditRoleLoading ||
                        !canEdit ||
                        newRole.claim_clients_duplicates_edit
                      "
                    />
                  </div>
                </li>
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_clients_duplicates_edit',
                          !newRole.claim_clients_duplicates_edit
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionClientsDuplicates:Edit') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_clients_duplicates_edit"
                      @input="setClaim('claim_clients_duplicates_edit', $event)"
                      :disabled="isLoading || isEditRoleLoading"
                    />
                  </div>
                </li>
              </ul>
            </li>

            <li class="list-block">
              <div class="list-block_htext">
                {{ $t('RolesAddEdit:PermissionPlantsCare:Text') }}
              </div>
              <ul class="list-block_list">
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_plants_care_see',
                          !newRole.claim_plants_care_see
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionPlantsCare:See') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_plants_care_see"
                      @input="setClaim('claim_plants_care_see', $event)"
                      :disabled="
                        isLoading ||
                        isEditRoleLoading ||
                        !canEdit ||
                        newRole.claim_plants_care_add
                      "
                    />
                  </div>
                </li>
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_plants_care_add',
                          !newRole.claim_plants_care_add
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionPlantsCare:Add') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_plants_care_add"
                      @input="setClaim('claim_plants_care_add', $event)"
                      :disabled="
                        isLoading ||
                        isEditRoleLoading ||
                        !canEdit ||
                        newRole.claim_plants_care_edit
                      "
                    />
                  </div>
                </li>
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_plants_care_edit',
                          !newRole.claim_plants_care_edit
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionPlantsCare:Edit') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_plants_care_edit"
                      @input="setClaim('claim_plants_care_edit', $event)"
                      :disabled="isLoading || isEditRoleLoading || !canEdit"
                    />
                  </div>
                </li>
              </ul>
            </li>

            <li class="list-block">
              <div class="list-block_htext">
                {{ $t('RolesAddEdit:PermissionPlantsNutrition:Text') }}
              </div>
              <ul class="list-block_list">
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_plants_nutrition_see',
                          !newRole.claim_plants_nutrition_see
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionPlantsNutrition:See') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_plants_nutrition_see"
                      @input="setClaim('claim_plants_nutrition_see', $event)"
                      :disabled="
                        isLoading ||
                        isEditRoleLoading ||
                        !canEdit ||
                        newRole.claim_plants_nutrition_add
                      "
                    />
                  </div>
                </li>
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_plants_nutrition_add',
                          !newRole.claim_plants_nutrition_add
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionPlantsNutrition:Add') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_plants_nutrition_add"
                      @input="setClaim('claim_plants_nutrition_add', $event)"
                      :disabled="
                        isLoading ||
                        isEditRoleLoading ||
                        !canEdit ||
                        newRole.claim_plants_nutrition_edit
                      "
                    />
                  </div>
                </li>
                <li class="list-block_listline">
                  <div>
                    <span class="listcircleicon"> </span>
                    <span
                      @click="
                        setClaim(
                          'claim_plants_nutrition_edit',
                          !newRole.claim_plants_nutrition_edit
                        )
                      "
                    >
                      {{ $t('RolesAddEdit:PermissionPlantsNutrition:Edit') }}
                    </span>
                  </div>
                  <div>
                    <CheckBox
                      :value="newRole.claim_plants_nutrition_edit"
                      @input="setClaim('claim_plants_nutrition_edit', $event)"
                      :disabled="isLoading || isEditRoleLoading || !canEdit"
                    />
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <Button
      :text="$t('RolesAddEdit:ButtonSave')"
      size="standart"
      color="accent"
      icon="save"
      :is-loading="isLoading"
      :loading-left="true"
      :disabled="isSaveButtonDisabled"
      @click="onSave"
      class="marginbottom--standart"
    />
    <!--БЛОК УДАЛИТЬ / ИНФО-->
    <div
      class="cwrap cwrap--level1 marginbottom--standart"
      v-if="isShowDeleteRole"
    >
      <!--ЗАГОЛОВОК-->
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'delete' }"
          ></div>
          <div class="chead_text">
            {{ $t('RolesAddEdit:DeleteBlock:Header') }}
          </div>
        </div>
      </div>

      <!--ОПИСАНИЕ-->
      <div class="cblock el--borders-all el--rounded-bottom">
        <div class="cblock__infoblock">
          <p>
            {{ $t('RolesAddEdit:DeleteBlock:Message1') }}<br />
            {{ $t('RolesAddEdit:DeleteBlock:Message2') }}
          </p>

          <Button
            :text="$t('RolesAddEdit:DeleteBlock:ButtonDelete')"
            size="standart"
            color="alertlines"
            icon="userdelete"
            :is-loading="isLoading"
            :loading-left="true"
            @click="onDelete"
          />
        </div>
      </div>
    </div>

    <Modal
      v-if="showModalNotSaved"
      @backgroundClose="showModalNotSaved = false"
    >
      <ModalNotSaved
        v-model="showModalNotSaved"
        @confirm="onModalNotSavedConfirm"
      />
    </Modal>
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import CheckBox from '@/components/CheckBox';
import routeNames from '@/router/routeNames';
import ModalNotSaved from '@/components/modals/modalNotSaved';
import Modal from '@/pages/Modal';
import { actionTypes } from '@/store/modules/admin';
import { mapState } from 'vuex';

export default {
  name: 'RolesAddEdit',
  components: { CheckBox, Input, Button, Breadcrumps, ModalNotSaved, Modal },
  data() {
    return {
      newRole: {
        id: null,
        name: '',
        description: '',
        claim_users_see: 0,
        claim_users_edit: 0,
        claim_glossary_see: 0,
        claim_glossary_edit: 0,
        claim_products_see: 0,
        claim_products_add: 0,
        claim_products_edit: 0,
        claim_clients_see: 0,
        claim_clients_add: 0,
        claim_clients_edit: 0,
        claim_clients_duplicates_edit: 0,
        claim_plants_care_see: 0,
        claim_plants_care_add: 0,
        claim_plants_care_edit: 0,
        claim_plants_nutrition_see: 0,
        claim_plants_nutrition_add: 0,
        claim_plants_nutrition_edit: 0,
        claim_analyse_see: 0,
        claim_analyse_edit: 0,
        claim_banners_edit: 0,
        claim_articles_edit: 0,
        claim_instructions_edit: 0,
      },
      showModalNotSaved: false,
      nextRoute: null,
      validationNameError: false,
    };
  },
  methods: {
    setClaim(claim, value) {
      if (!this.canEdit) return;
      //console.log(claim);
      //console.log(value);
      switch (claim) {
        case 'claim_users_see': {
          if (this.newRole.claim_users_edit) {
            value = 1;
          }
          break;
        }
        case 'claim_users_edit': {
          if (value) {
            this.newRole.claim_users_see = 1;
          }
          break;
        }
        case 'claim_glossary_see': {
          if (this.newRole.claim_glossary_edit) {
            value = 1;
          }
          break;
        }
        case 'claim_glossary_edit': {
          if (value) {
            this.newRole.claim_glossary_see = 1;
          }
          break;
        }
        case 'claim_analyse_see': {
          if (this.newRole.claim_analyse_edit) {
            value = 1;
          }
          break;
        }
        case 'claim_analyse_edit': {
          if (value) {
            this.newRole.claim_analyse_see = 1;
          }
          break;
        }
        case 'claim_products_see': {
          if (
            this.newRole.claim_products_edit ||
            this.newRole.claim_products_add
          ) {
            value = 1;
          }
          break;
        }
        case 'claim_products_add': {
          if (this.newRole.claim_products_edit) {
            value = 1;
          }
          if (value) {
            this.newRole.claim_products_see = 1;
          }
          break;
        }
        case 'claim_products_edit': {
          if (value) {
            this.newRole.claim_products_add = 1;
            this.newRole.claim_products_see = 1;
          }
          break;
        }
        case 'claim_clients_see': {
          if (
            this.newRole.claim_clients_edit ||
            this.newRole.claim_clients_add
          ) {
            value = 1;
          }
          break;
        }
        case 'claim_clients_add': {
          if (this.newRole.claim_clients_edit) {
            value = 1;
          }
          if (value) {
            this.newRole.claim_clients_see = 1;
          }
          break;
        }
        case 'claim_clients_edit': {
          if (this.newRole.claim_clients_duplicates_edit) {
            value = 1;
          }
          if (value) {
            this.newRole.claim_clients_add = 1;
            this.newRole.claim_clients_see = 1;
          }
          break;
        }
        case 'claim_clients_duplicates_edit': {
          if (value) {
            this.newRole.claim_clients_add = 1;
            this.newRole.claim_clients_see = 1;
            this.newRole.claim_clients_edit = 1;
          }
          break;
        }
        case 'claim_plants_care_see': {
          if (
            this.newRole.claim_plants_care_edit ||
            this.newRole.claim_plants_care_add
          ) {
            value = 1;
          }
          break;
        }
        case 'claim_plants_care_add': {
          if (this.newRole.claim_plants_care_edit) {
            value = 1;
          }
          if (value) {
            this.newRole.claim_plants_care_see = 1;
          }
          break;
        }
        case 'claim_plants_care_edit': {
          if (value) {
            this.newRole.claim_plants_care_add = 1;
            this.newRole.claim_plants_care_see = 1;
          }
          break;
        }
        case 'claim_plants_nutrition_see': {
          if (
            this.newRole.claim_plants_nutrition_edit ||
            this.newRole.claim_plants_nutrition_add
          ) {
            value = 1;
          }
          break;
        }
        case 'claim_plants_nutrition_add': {
          if (this.newRole.claim_plants_nutrition_edit) {
            value = 1;
          }
          if (value) {
            this.newRole.claim_plants_nutrition_see = 1;
          }
          break;
        }
        case 'claim_plants_nutrition_edit': {
          if (value) {
            this.newRole.claim_plants_nutrition_add = 1;
            this.newRole.claim_plants_nutrition_see = 1;
          }
          break;
        }
      }
      if (value) {
        this.newRole[claim] = 1;
      } else {
        this.newRole[claim] = 0;
      }
    },
    onModalNotSavedConfirm() {
      if (this.nextRoute) {
        this.nextRouteConfirm = true;
        this.$router.push(this.nextRoute);
      }
    },
    onSave() {
      let valOk = true;
      if (!this.newRole.name) {
        valOk = false;
      }
      if (!valOk) {
        return;
      }
      //if(this.isEditUser){
      //this.$store.dispatch(actionTypes.setUpdateUser,{...this.newUser,id:this.$store.state.admin.editUser.id});
      //} else {
      this.$store.dispatch(actionTypes.setUpdateRole, this.newRole);
      //}
      //console.log(this.newUser)
    },
    onDelete() {
      this.$store.dispatch(actionTypes.setUpdateRole, {
        ...this.newRole,
        delete: true,
      });
    },
  },
  computed: {
    ...mapState({
      canEdit: (state) => state.auth.currentUserRole?.claim_users_edit === 1,
    }),
    nameInputState() {
      if (this.isLoading || this.isEditRoleLoading) return 'disabled';
      if (this.validationNameError) return 'error';
      return this.canEdit ? '' : 'disabled2';
    },
    roleInputState() {
      if (this.isLoading || this.isEditRoleLoading) return 'disabled';
      return this.canEdit ? '' : 'disabled2';
    },
    showButtonEdit() {
      return this.$route.name === routeNames.newRole ? false : true;
    },
    isSaveButtonDisabled() {
      let haveData = false;
      if (this.isEditRole) {
        for (const key in this.newRole) {
          // eslint-disable-next-line
          if (this.$store.state.admin.editRole.hasOwnProperty(key)) {
            if (this.newRole[key] !== this.$store.state.admin.editRole[key]) {
              haveData = true;
              break;
            }
          }
        }
      } else {
        if (this.newRole.name) {
          haveData = true;
        }
      }

      return this.canEdit ? !haveData : true;
    },
    isLoading() {
      return this.$store.state.admin.isUpdateRoleLoading;
    },
    isDone() {
      return this.$store.state.admin?.isUpdateRoleDone;
    },
    isEditRole() {
      //if(this.$store.state.admin.editUser){
      if (
        this.$store.state.admin.editRole &&
        this.$route.name === routeNames.editRole
      ) {
        return true;
      }
      return false;
    },
    editRole() {
      return this.$store.state.admin.editRole;
    },
    isEditRoleLoading() {
      return this.$store.state.admin.isRolesLoading;
    },
    isShowDeleteRole() {
      if (this.isEditRole && this.editRole && this.editRole.count_users === 0) {
        return true;
      } else {
        return false;
      }
    },
    breadcrumpsArr() {
      if (this.$route.name === routeNames.editRole)
        return [
          { title: this.$t('RolesAddEdit:TitleRoles'), route: 'Roles' },
          { title: this.$t('RolesAddEdit:TitleEdit') },
        ];
      else
        return [
          { title: this.$t('RolesAddEdit:TitleRoles'), route: 'Roles' },
          { title: this.$t('RolesAddEdit:TitleNew') },
        ];
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.nextRouteConfirm || !this.canEdit) {
      next();
    } else if (this.$route.name === routeNames.editRole) {
      if (!this.isSaveButtonDisabled) {
        this.showModalNotSaved = true;
        this.nextRoute = to;
        next(false);
      } else {
        next();
      }
    } else {
      if (this.newRole.name || this.newRole.description) {
        this.showModalNotSaved = true;
        this.nextRoute = to;
        next(false);
      } else {
        next();
      }
    }
  },
  watch: {
    isDone() {
      if (this.isDone) {
        if (!this.$store.state.admin.isUpdateRoleHasError) {
          this.nextRouteConfirm = true;
          try {
            if (this.newRole.id === this.$store.state.auth.currentUserRole.id) {
              window.location.reload();
            } else {
              this.$router.push({ name: routeNames.roles });
            }
          } catch (e) {
            console.log(e);
            this.$router.push({ name: routeNames.roles });
          }
        } else {
          this.$awn.alert(this.$store.state.admin.updateRoleErrorText);
          if (this.$store.state.admin.updateRoleErrorText.includes('ole')) {
            this.validationNameError = true;
          }
        }
      }
    },
    editRole() {
      if (this.isEditRole) {
        for (const key in this.newRole) {
          // eslint-disable-next-line
          if (this.$store.state.admin.editRole.hasOwnProperty(key)) {
            this.newRole[key] = this.$store.state.admin.editRole[key];
          }
        }
      }
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/vars.scss';

.list {
  padding: 0;
  margin: 0;
}
.list-block {
  list-style: none;
  border-top: 1px solid $colorLinesAccentLight;
  padding-top: $marginMini;

  &:first-child {
    border-top: none;
    padding-top: 0;
  }

  &:last-child {
    & .list-block_listline:last-child {
      margin-bottom: 0;
    }
  }
}
.list-block_htext {
  color: $colorTextAccent;
  font-size: $fontsizeBigButtons;
  line-height: $lineFontsizeBigButtons;
  margin-bottom: $marginMini;
  font-weight: $fontNormal;
}
.list-block_list {
  padding-left: 0;
}
.list-block_listline {
  display: flex;
  list-style: none;
  color: $colorTextGray;
  font-size: $fontsizeStandart;
  line-height: $lineFontsizeStandart;
  margin-bottom: $marginMini;
  //line-height: $fontsizeStandart;
  cursor: pointer;
  user-select: none;

  & > div {
    width: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  & div:first-child {
    width: 40%;
    min-width: 50ch; //380px;
  }
}
.listcircleicon {
  display: inline-block;
  width: 12px; //$fontsizeStandart;
  height: 12px; //$fontsizeStandart;
  min-width: 12px;
  min-height: 12px;
  border-radius: 12px;
  background-color: $colorGrayLight;
  margin-right: $marginMicro;
}
</style>
