<!--Translated-->
<template>
  <div class="cblock el--rounded-all el--borders-all">
    <div class="cblock__internalpadding">
      <div class="loaderblock">
        <div class="loaderwrap">
          <span class="loader2 loader2--dark"></span>
        </div>
        <div class="loader-text">{{ $t('LoaderBlock:LoadingText') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoaderBlock',
  components: {},
  props: {
    fatality: { type: Boolean, default: false },
    showButton: { type: Boolean, default: true },
  },
};
</script>
