<!--Translated-->
<template>
  <div class="cwrap--level2">
    <ClientItemHeader
      class="level2 chead el--borders-sides"
      iconName="farm"
      :text="
        $t('ClientPlace:Header:Text1') +
        ' - ' +
        (placesChain || $t('ClientPlace:Header:Text2'))
      "
      :id="place.id"
      type="place"
    />

    <div
      v-show="isShown"
      class="cblock el--borders-all"
      :class="{ 'el--rounded-bottom': isLast }"
    >
      <div class="cblock__internal">
        <div class="cblock__line">
          <LocationSelect
            :geoEntries="geoMapped"
            @chainChanged="placesChainChanged"
            :free-select="true"
            v-model="geoId"
          />
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <Input
              type="text"
              :placeholder="$t('ClientPlace:InputName.Placeholder')"
              :label="$t('ClientPlace:InputName.Label')"
              size="long"
              v-model="name"
            />
            <InputNumber
              :placeholder="$t('ClientPlace:InputArea.Placeholder')"
              :label="$t('ClientPlace:InputArea.Label')"
              size="standart"
              v-model="area"
            />
            <InputNumber
              type="text"
              :placeholder="$t('ClientPlace:InputCountPlants.Placeholder')"
              :label="$t('ClientPlace:InputCountPlants.Label')"
              size="standart"
              v-model="plantsPerHa"
            />
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <DropdownSelect
              v-if="!noYear"
              :placeholder="$t('ClientPlace:SelectYear.Placeholder')"
              :label="$t('ClientPlace:SelectYear.Label')"
              size="standart"
              :options="years"
              v-model="yearOfBoarding"
            />

            <Input
              v-if="!noYear"
              placeholder="0"
              :label="$t('ClientPlace:PlantAge.Label')"
              size="mini"
              v-model="age"
              state="disabled2"
            />

            <DropdownSelect
              type="dropdown"
              :placeholder="$t('ClientPlace:IrrigationType.Placeholder')"
              :label="$t('ClientPlace:IrrigationType.Label')"
              size="standart"
              :options="inputIrrigationSystems"
              v-model="irrigationSystemType"
            />

            <InputDate
              placeholder=""
              :label-text="$t('ClientPlace:IrrigationDate.Label')"
              size="Standart"
              v-model="irrigationSystemDate"
            />
          </div>
        </div>

        <ClientPlacesCodes :placeId="place.id" />

        <div class="el__decorline margintop--standart" />
        <div class="cblock__line">
          <div class="cblock__line-block">
            <Button
              :text="$t('ClientPlace:ButtonAddZone')"
              size="wider"
              color="accent"
              icon="map"
              @click="addZone"
            />
          </div>
          <div class="cblock__line-block flex-end">
            <Button
              :text="$t('ClientPlace:ButtonDeletePlace')"
              size="standart"
              color="alertlines"
              icon="delete"
              :is-loading="false"
              :loading-left="true"
              @click="deletePlace"
              :disabled="count < 2"
            />
          </div>
        </div>
      </div>
    </div>

    <ClientZone
      v-for="zone in zones"
      :key="zone.id"
      :zone="zone"
      :count="zones.length"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mutationTypes, staticData } from '@/store/modules/clientsArea/clients';
import { confirmDelete } from '@/pages/productsclients/confirmDelete';

import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import InputDate from '@/components/views/InputDate';
import InputNumber from '@/components/views/wrappers/InputNumberWithCast';
import DropdownSelect from '@/components/views/wrappers/DropdownSelect';
import LocationSelect from '@/components/views/LocationSelect';
import ClientItemHeader from './ClientItemHeader';
import ClientZone from './ClientZone';
import ClientPlacesCodes from './ClientPlacesCodes';

export default {
  name: 'Client2Place',
  components: {
    Button,
    Input,
    InputDate,
    InputNumber,
    DropdownSelect,
    LocationSelect,
    ClientItemHeader,
    ClientZone,
    ClientPlacesCodes,
  },
  props: {
    place: { type: Object, required: true },
    count: { type: Number, required: true },
    noYear: { type: Boolean, default: false },
  },
  data() {
    return {
      placesChain: '',
    };
  },
  computed: {
    ...mapState({
      isShown(state) {
        return state.clients2.expandedHeadersStatuses[`place_${this.place.id}`];
      },
      isLast() {
        const thisItem = this.client2DataHierarchy.find(
          ({ id }) => id === this.place.id
        );
        const nextItem = this.client2DataHierarchy.find(
          ({ order }) => order === thisItem.order + 1
        );
        return nextItem === undefined || nextItem.type === 'culture';
      },
      zones(state) {
        return state.clients2.clientPlacesZones.filter(
          (zone) => zone.place_id === this.place.id
        );
      },
    }),
    ...mapGetters(['geoMapped', 'client2DataHierarchy']),
    geoId: {
      get() {
        return this.place.geo_id;
      },
      set(geoId) {
        this.$store.commit(mutationTypes.setPlaceGeoId, {
          id: this.place.id,
          geoId,
        });
      },
    },
    name: {
      get() {
        return this.place.name;
      },
      set(name) {
        this.$store.commit(mutationTypes.setPlaceName, {
          id: this.place.id,
          name,
        });
      },
    },
    area: {
      get() {
        return this.place.area;
      },
      set(area) {
        this.$store.commit(mutationTypes.setPlaceArea, {
          id: this.place.id,
          area,
        });
      },
    },
    yearOfBoarding: {
      get() {
        return (
          this.years.find((e) => e.value === this.place.year_of_boarding) ??
          null
        );
      },
      set(input) {
        this.$store.commit(mutationTypes.setPlaceYearOfBoarding, {
          id: this.place.id,
          year: input?.value,
        });
      },
    },
    plantsPerHa: {
      get() {
        return this.place.count_plants_per_ha;
      },
      set(value) {
        this.$store.commit(mutationTypes.setPlacePlantsPerHa, {
          id: this.place.id,
          value,
        });
      },
    },
    irrigationSystemType: {
      get() {
        return this.inputIrrigationSystems.find(
          ({ value }) => value === this.place.irrigation_system_type_id
        );
      },
      set(type) {
        this.$store.commit(mutationTypes.setPlaceIrrigationSystemType, {
          id: this.place.id,
          typeId: type?.value ?? null,
        });
      },
    },
    irrigationSystemDate: {
      get() {
        return this.place.irrigation_system_date;
      },
      set(date) {
        this.$store.commit(mutationTypes.setPlaceIrrigationSystemDate, {
          id: this.place.id,
          date,
        });
      },
    },
    years() {
      const year = new Date().getFullYear();
      const years = [...Array(11)].map((_, age) => ({
        text: `${year - age}`,
        value: year - age,
      }));
      // TODO translate me
      years.at(-1).text += ' ან უფრო ადრე';

      if (
        this.place.year_of_boarding &&
        !years.some(({ value }) => value === this.place.year_of_boarding)
      ) {
        years.push({
          // TODO translate me
          text: this.place.year_of_boarding + ' ან უფრო ადრე',
          value: this.place.year_of_boarding,
        });
      }

      return years;
    },
    age() {
      const age = this.place.year_of_boarding
        ? new Date().getFullYear() - this.place.year_of_boarding + 1
        : '';
      return age + (age > 10 ? '+' : '');
    },
    inputIrrigationSystems() {
      return staticData.irrigationSystemType.map((text, index) => ({
        text,
        value: index,
      }));
    },
  },
  methods: {
    addZone(highlight = true) {
      this.$store.commit(mutationTypes.addBlankZone, {
        placeId: this.place.id,
        highlight,
      });
    },
    deletePlace() {
      confirmDelete.call(
        this,
        this.$t('ClientPlace:ModalConfirmDelete'),
        mutationTypes.deletePlace,
        this.place
      );
    },
    placesChainChanged(value) {
      this.placesChain = value;
    },
  },
  watch: {
    zones: {
      handler({ length }) {
        if (length === 0) this.addZone(false);
      },
      immediate: true,
    },
  },
};
</script>
