import * as adminApi from '@/api/clientsArea/admin';
import { mutationTypes as geoBaseMutationTypes } from '@/store/modules/glossaryGeobase';

const state = {
  client: null,
  clientContacts: [],
  contactTypes: null,
  isMyDataLoading: false,
  isMyDataLoadingHasError: false,
  isMyDataUploading: false,
  isMyDataUploadingDone: false,
  isMyDataUploadingHasError: false,
  isMyDataUploadingErrorText: '',
};

export const mutationTypes = {
  getMyDataStart: '[client myData] getMyData start',
  getMyDataSuccess: '[client myData] getMyData success',
  getMyDataFailure: '[client myData] getMyData failure',
  setMyDataStart: '[client myData] setMyData start',
  setMyDataSuccess: '[client myData] setMyData success',
  setMyDataFailure: '[client myData] setMyData failure',
};
export const actionTypes = {
  getMyData: '[client myData] getMyData',
  setMyData: '[client myData] setMyData',
};

const mutations = {
  [mutationTypes.getMyDataStart](state) {
    state.isMyDataLoading = true;
    state.isMyDataLoadingHasError = false;
    //state.products = null;
  },
  [mutationTypes.getMyDataSuccess](state, payload) {
    state.isMyDataLoading = false;
    if (payload) {
      state.client = payload.client;
      state.clientContacts = payload.clientContacts || [];
      state.contactTypes = payload.contactTypes;
      //processArticles(state);
    }
  },
  [mutationTypes.getMyDataFailure](state) {
    state.isMyDataLoading = false;
    state.isMyDataLoadingHasError = true;
  },
  [mutationTypes.setMyDataStart](state) {
    state.isMyDataUploading = true;
    state.isMyDataUploadingDone = false;
    state.isMyDataUploadingHasError = false;
    state.isMyDataUploadingErrorText = '';
  },
  [mutationTypes.setMyDataSuccess](state, payload) {
    state.isMyDataUploading = false;
    state.isMyDataUploadingDone = true;
    if (payload.status === 'error') {
      state.isMyDataUploadingHasError = true;
      state.isMyDataUploadingErrorText =
        payload?.message ?? 'Ошибка сохранения';
    }
  },
  [mutationTypes.setMyDataFailure](state) {
    state.isMyDataUploading = false;
    state.isMyDataUploadingDone = true;
    state.isMyDataUploadingHasError = true;
    state.isMyDataUploadingErrorText = 'Ошибка сохранения';
  },
};

function processArticles(state) {
  state.articlesMapById = new Map();
  state.articles.forEach((val) => {
    state.articlesMapById.set(val.id, val);
  });
}

const actions = {
  async [actionTypes.getMyData](context, payload) {
    context.commit(mutationTypes.getMyDataStart, payload);
    try {
      let response = await adminApi.getMyData({
        geoBase: context.rootState.glossaryGeobase.timestamp,
      });
      if (response.status !== 304) {
        context.commit(mutationTypes.getMyDataSuccess, response.data);
        if (response.data.geoBase) {
          context.commit(
            geoBaseMutationTypes.getGeobaseSuccess,
            response.data.geoBase,
            { root: true }
          );
        }
      }
    } catch (error) {
      console.log(error);
      context.commit(mutationTypes.getMyDataFailure);
    }
  },
  async [actionTypes.setMyData](context, payload) {
    context.commit(mutationTypes.setMyDataStart, payload);
    try {
      let response = await adminApi.setMyData(payload);
      if (response.status !== 304) {
        context.commit(mutationTypes.setMyDataSuccess, response.data);
      }
    } catch (error) {
      console.log(error);
      context.commit(mutationTypes.setMyDataFailure);
    }
  },
};
export default {
  state,
  mutations,
  actions,
};
