<!--Translated-->
<template>
  <DropdownSelectValue
    :placeholder="$t('CalcMethodSelect:Select.Placeholder')"
    :label="$t('CalcMethodSelect:Select.Label')"
    :options="calculationMethods"
    v-model="calcMethod"
    :disabled="disabled"
    :locked="locked"
  />
</template>

<script>
import { staticData } from '@/store/modules/plantsCare';
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';

export default {
  name: 'CalcMethodSelect',
  components: { DropdownSelectValue },
  props: ['value', 'disabled', 'locked'],
  computed: {
    calculationMethods() {
      return Object.entries(staticData.calculationMethods).map(
        ([id, text]) => ({ id, value: Number(id), text })
      );
    },
    calcMethod: {
      get() {
        return this.value;
      },
      set(method) {
        this.$emit('input', method);
      },
    },
  },
};
</script>
