<!--Translated-->
<template>
  <article>
    <div
      class="cwrap artclwrap artclwrap--buttons el--borders-all el--rounded-all margintop--standart"
    >
      <div class="artclhead">
        <div class="artclhead-num marginright--micro">04.</div>
        <div class="artclhead-text">
          {{ $t('GuideAnalysisTrack:Title') }}
        </div>
      </div>

      <div class="artclblock">
        <div class="artclblock-text">
          <p>{{ $t('GuideAnalysisTrack:MainText') }}</p>
        </div>

        <div class="artclblock-buttons">
          <Button
            :text="$t('GuideAnalysisTrack:Button:GoToAnalyzes')"
            size="wider"
            color="accentlines"
            icon="linkto"
            @click="$router.push({ name: 'UserAnalyzes' })"
          />
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import Input from '@/components/views/Input';
import Button from '@/components/views/Button';

export default {
  name: 'GuideAnalysisTrack',
  components: { Button, Input },
  methods: {},
};
</script>

<style scoped></style>
