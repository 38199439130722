import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { nanoid } from 'nanoid';
import deepFreeze from 'deep-freeze';
// import { diff } from 'deep-object-diff';

import api from '@/api/plantsCare';
import { mutationTypes as geobaseMutationTypes } from './glossaryGeobase';
import { mutationTypes as clientsMutaionTypes } from './clientsList';
import { mutationTypes as plantsCareMutationTypes } from './plantsCare';

const serverKeysProgram = [
  'categoriesCultures',
  'cultures',
  'culturesSorts',
  'images',
  'manufacturers',
  'manufacturersCountries',
  'pests',
  'typesStimulation',
  'phases',
  'phasesTypesStimulation',
  'phasesPests',
  'productsPests',
  'productsTypesStimulation',
];

const serverKeysClient = [
  'clientPlaces',
  'clientCultures',
  'clientPlacesZones',
];

const editableKeysProgram = [
  'programCulturesSorts',
  'programPhases',
  'programPestsProducts',
  'programTypesStimulationProducts',
];

const defaultProgram = {
  name: '',
  description: '',
  client_id: null,
  client_name: null,
  client_type: null,
  client_discount: 0,
  client_code: null,
  geo_id: null,
  zone_name: '',
  place_area: null,
  culture_id: null,
  culture_age: null,
  calculation_method_id: null,
  template_id: null,
  template_name: '',
  rate_dollar: null,
  rate_euro: null,
};

const createState = () => ({
  isLoading: false,
  isSaving: false,
  isClientFetching: false,
  clientFetchAbortController: null,
  templatesSearching: false,
  templatesSearchAbortController: null,
  templateFetching: false,
  templateFetchAbortController: null,
  error: null,
  timestamp: 0,
  serverData: {
    program: { ...defaultProgram },
    ...Object.fromEntries(editableKeysProgram.map((key) => [key, []])),
  },
  program: { ...defaultProgram },
  ...Object.fromEntries(
    [...serverKeysProgram, ...editableKeysProgram].map((key) => [key, []])
  ),
  client: null,
  ...Object.fromEntries(serverKeysClient.map((key) => [key, []])),
  templates: [],
  selectedTemplate: null,
  savedRedirectId: null,
  lastInsertedPhaseId: null,
});

const state = createState();

const mutationTypes = {
  resetState: '[plants care p] reset state',

  programFetchingStart: '[plants care p] program fetching start',
  programFetchingFailure: '[plants care p] program fetching failure',
  programFetchingSuccess: '[plants care p] program fetching success',

  setClientCode: '[plants care p] set program code',
  setProgramName: '[plants care p] set program name',
  setProgramDescription: '[plants care p] set program description',
  setClientName: '[plants care p] set client name',
  setClientType: '[plants care p] set client type',
  setClientId: '[plants care p] set client id',
  setClientDiscount: '[plants care p] set client discount',
  setProgramGeo: '[plants care p] set program geo',
  setZoneName: '[plants care p] set zone name',
  setPlaceArea: '[plants care p] set place area',
  setCultureId: '[plants care p] set culture id',
  setAge: '[plants care p] set age',
  setCalculationMethod: '[plants care p] set calculation method',
  setCultureSorts: '[plants care p] set culture sorts',
  setRateEU: '[plants care p] set EU exchange rate',
  setRateUSD: '[plants care p] set USD exchange rate',
  deleteId: '[plants care p] delete id',

  clientFetchingStart: '[plants care p] client fetching start',
  clientFetchingFailure: '[plants care p] client fetching failure',
  clientFetchingSuccess: '[plants care p] client fetching success',
  clientFetchingSetAbortController:
    '[plants care p] client fetching set abort controller',
  resetClient: '[plants care p] reset client',

  templatesSearchStart: '[plants care p] tempaltes search start',
  templatesSearchFailure: '[plants care p] tempaltes search failure',
  templatesSearchSuccess: '[plants care p] tempaltes search success',
  templatesSearchSetAbortController:
    '[plants care p]  tempaltes search set abort controller',
  selectTemplate: '[plants care p] select template',

  templateFetchingStart: '[plants care p] template fetching start',
  templateFetchingFailure: '[plants care p] template fetching failure',
  templateFetchingSuccess: '[plants care p] template fetching success',

  setSelectedProduct: '[plants care p] set selected product',

  addTemporaryPhase: '[plants care p] add temporary phase',
  removeTemporaryPhase: '[plants care p] remove temporary phase',
  addPest: '[plants care p] add pest',
  addStimulationType: '[plants care p] add stimulation type',
  removePest: '[plants care p] remove pest',
  removeStimulationType: '[plants care p] remove stimulation type',

  setComment: '[plants care p] set comment',
  setWaterPhase: '[plants care p] set water phase',
  setPestProductQuantity: '[plants care p] set pest product quantity',
  setStimulationTypeProductQuantity:
    '[plants care p] set stimulation type product quantity',

  saveProgramStart: '[plants care p] save program start',
  saveProgramFailure: '[plants care p] save program failure',
  saveProgramSuccess: '[plants care p] save program success',
};

const actionTypes = {
  fetchProgram: '[plants care p] fetch program',
  fetchClient: '[plant care p] fetch client',
  templatesSearch: '[plants care p] tempaltes search',
  fetchTemplate: '[plants care p] fetch template',
  saveProgram: '[plants care p] save program',
  deleteProgram: '[plants care p] delete program',
};

const getters = {
  programPestsProductsAsMap(state) {
    return Object.fromEntries(
      state.programPestsProducts.map((p) => [
        `phase_${p.program_phase_id}_pest_${p.pest_id}_product_${p.product_id}`,
        p,
      ])
    );
  },
  programSTypesProductsAsMap(state) {
    return Object.fromEntries(
      state.programTypesStimulationProducts.map((p) => [
        `phase_${p.program_phase_id}_stype_${p.type_stimulation_id}_product_${p.product_id}`,
        p,
      ])
    );
  },
  programPricesMap(state, { plantsCareProductsAsMap }) {
    return Object.fromEntries(
      state.programPhases.map((phase) => [
        phase.id,
        [
          ...state.programPestsProducts,
          ...state.programTypesStimulationProducts,
        ]
          .filter(
            (product) =>
              product.program_phase_id === phase.id && product.is_selected
          )
          .reduce((acc, cur) => {
            const product = plantsCareProductsAsMap[cur.product_id];
            const multiplier = product.picking
              ? product.price / product.picking
              : 0;
            return acc + (cur.quantity ?? 0) * multiplier;
          }, 0),
      ])
    );
  },
  programsCanSave(state) {
    // console.log(
    //   diff(state.serverData, {
    //     program: state.program,
    //     ...Object.fromEntries(
    //       editableKeysProgram.map((key) => [key, state[key]])
    //     ),
    //   })
    // );
    return (
      state.program.name &&
      state.programPhases.length &&
      state.program.place_area &&
      !isEqual(state.serverData, {
        program: state.program,
        ...Object.fromEntries(
          editableKeysProgram.map((key) => [key, state[key]])
        ),
      })
    );
  },
};

const mutations = {
  [mutationTypes.resetState](state) {
    state.isLoading = false;
    state.error = null;
    state.program = { ...defaultProgram };
    editableKeysProgram.forEach((key) => (state[key] = []));
    state.selectedTemplate = null;
    state.templates = [];
    state.client = null;
  },
  [mutationTypes.programFetchingStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.programFetchingFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },
  [mutationTypes.programFetchingSuccess](state, payload) {
    state.isLoading = false;
    state.error = null;
    state.timestamp = new Date().getTime();

    serverKeysProgram.forEach((key) => {
      if (payload[key]?.length) state[key] = payload[key];
    });

    ['program', ...editableKeysProgram].forEach((key) => {
      if (payload[key]) {
        state[key] = cloneDeep(payload[key]);
        state.serverData[key] = cloneDeep(payload[key]);
      }
    });
  },

  [mutationTypes.setProgramName](state, payload) {
    state.program.name = payload;
  },
  [mutationTypes.setProgramDescription](state, payload) {
    state.program.description = payload;
  },
  [mutationTypes.setClientCode](state, payload) {
    state.program.client_code = payload;
  },
  [mutationTypes.setClientName](state, payload) {
    state.program.client_name = payload;
  },
  [mutationTypes.setClientType](state, payload) {
    state.program.client_type = payload;
  },
  [mutationTypes.setClientId](state, payload) {
    state.program.client_id = payload;
  },
  [mutationTypes.setClientDiscount](state, payload) {
    state.program.client_discount = payload ?? 0;
  },
  [mutationTypes.setProgramGeo](state, payload) {
    state.program.geo_id = payload;
  },
  [mutationTypes.setZoneName](state, payload) {
    state.program.zone_name = payload;
  },
  [mutationTypes.setPlaceArea](state, payload) {
    state.program.place_area = payload;
  },
  [mutationTypes.setCultureId](state, payload) {
    state.program.culture_id = payload;
  },
  [mutationTypes.setAge]({ program }, payload) {
    program.culture_age = payload > 11 ? 11 : payload;
  },
  [mutationTypes.setCalculationMethod]({ program }, payload) {
    program.calculation_method_id = payload === null ? null : Number(payload);
  },
  [mutationTypes.setCultureSorts](state, payload) {
    state.programCulturesSorts = payload.map((sort_id) => ({ sort_id }));
  },
  [mutationTypes.setRateEU](state, payload) {
    state.program.rate_euro = payload;
  },
  [mutationTypes.setRateUSD](state, payload) {
    state.program.rate_dollar = payload;
  },
  [mutationTypes.deleteId](state) {
    delete state.program.id;
    delete state.program.client_id;
    delete state.program.client_discount;
  },

  [mutationTypes.clientFetchingStart](state) {
    state.isClientFetching = true;
    state.error = null;
  },
  [mutationTypes.clientFetchingFailure](state, payload) {
    if (payload?.message === 'canceled') return;
    state.error = payload;
    state.isClientFetching = false;
  },
  [mutationTypes.clientFetchingSuccess](state, payload) {
    state.error = null;
    state.isClientFetching = false;
    ['client', ...serverKeysClient].forEach(
      (key) => (state[key] = payload[key] ?? [])
    );
  },
  [mutationTypes.clientFetchingSetAbortController](state, payload) {
    state.clientFetchAbortController?.abort('canceled');
    state.clientFetchAbortController = payload;
  },
  [mutationTypes.resetClient](state) {
    state.client = null;
    serverKeysClient.forEach((key) => (state[key] = []));
  },

  [mutationTypes.templatesSearchStart](state) {
    state.templatesSearching = true;
    state.error = null;
  },
  [mutationTypes.templatesSearchFailure](state, payload) {
    if (payload?.message === 'canceled') return;
    state.templatesSearching = false;
    state.error = payload;
  },
  [mutationTypes.templatesSearchSuccess](state, payload) {
    state.templatesSearching = false;
    state.templates = payload;
    state.selectedTemplate = null;
    if (state.templates.length === 1) {
      state.selectedTemplate = state.templates[0];
      state.program.template_id = state.templates[0].id;
      state.program.template_name = state.templates[0].name ?? '';
    }
  },
  [mutationTypes.templatesSearchSetAbortController](state, payload) {
    state.templatesSearchAbortController?.abort('canceled');
    state.templatesSearchAbortController = payload;
  },
  [mutationTypes.selectTemplate](state, payload) {
    if (payload === null) {
      state.selectedTemplate = null;
      editableKeysProgram.forEach((key) => (state[key] = []));
      state.program.template_id = null;
      state.program.template_name = null;
      return;
    }
    const template = state.templates.find(({ id }) => id === payload);
    if (template) {
      state.selectedTemplate = template;
      state.program.template_id = template.id;
      state.program.template_name = template.name ?? '';
    }
  },

  [mutationTypes.templateFetchingStart](state) {
    state.templateFetching = true;
    state.error = null;
  },
  [mutationTypes.templateFetchingFailure](state, payload) {
    state.templateFetching = false;
    state.error = payload;
  },
  [mutationTypes.templateFetchingSuccess](state, payload) {
    serverKeysProgram.forEach(
      (key) => payload[key] && (state[key] = payload[key])
    );
    const phases = payload.phases.map((p) => ({
      ...p,
      id: nanoid(),
      originalId: p.id,
      is_temporary: 0,
    }));

    state.programPestsProducts = [];
    state.programTypesStimulationProducts = [];
    for (const phase of phases) {
      const water = payload.templateWaterPhases.find(
        (wp) => wp.phase_id === phase.originalId
      )?.quantity;
      if (water) phase.volume_per_ha = water;

      phase.main_image =
        payload.images.find(
          (img) =>
            img.is_main && img.object_id === phase.originalId && img.type === 1
        )?.name ?? null;

      const pestsProducts = payload.templatePestsProducts
        .filter(({ phase_id }) => phase_id === phase.originalId)
        .map((pp) => ({
          id: nanoid(),
          program_phase_id: phase.id,
          pest_id: pp.pest_id,
          product_id: pp.product_id,
          quantity: pp.quantity,
          is_selected: 0,
        }));
      state.programPestsProducts = [
        ...state.programPestsProducts,
        ...pestsProducts,
      ];

      const tsProducts = payload.templateTypesStimulationProducts
        .filter(({ phase_id }) => phase_id === phase.originalId)
        .map((tsp) => ({
          id: nanoid(),
          program_phase_id: phase.id,
          type_stimulation_id: tsp.type_stimulation_id,
          product_id: tsp.product_id,
          quantity: tsp.quantity,
          is_selected: 0,
        }));
      state.programTypesStimulationProducts = [
        ...state.programTypesStimulationProducts,
        ...tsProducts,
      ];
    }

    state.programPhases = phases.filter(
      ({ id }) =>
        state.programPestsProducts.some((p) => p.program_phase_id === id) ||
        state.programTypesStimulationProducts.some(
          (p) => p.program_phase_id === id
        )
    );
    state.templateFetching = false;
  },

  [mutationTypes.setSelectedProduct](
    state,
    { object_id, product_id, program_phase_id, forStimulationType }
  ) {
    const list = forStimulationType
      ? state.programTypesStimulationProducts
      : state.programPestsProducts;

    const product = list.find(
      (p) =>
        p.product_id === product_id &&
        p.program_phase_id === program_phase_id &&
        object_id === (forStimulationType ? p.type_stimulation_id : p.pest_id)
    );
    product.is_selected = product.is_selected ? 0 : 1;
  },

  [mutationTypes.addTemporaryPhase](state, payload) {
    let index = state.programPhases.findIndex(({ id }) => id === payload);
    const phase = {
      ...state.programPhases[index],
      id: nanoid(),
      is_temporary: 1,
      comment: '',
      volume_per_ha: null,
    };
    while (state.programPhases[index + 1]?.is_temporary === 1) {
      index += 1;
    }
    state.programPhases.splice(index + 1, 0, phase);
    state.lastInsertedPhaseId = phase.id;
  },
  [mutationTypes.removeTemporaryPhase](state, payload) {
    state.programPhases = state.programPhases.filter(
      ({ id }) => id !== payload
    );
    state.programPestsProducts = state.programPestsProducts.filter(
      ({ program_phase_id }) => program_phase_id !== payload
    );
    state.programTypesStimulation = state.productsTypesStimulation.filter(
      ({ program_phase_id }) => program_phase_id !== payload
    );
  },
  [mutationTypes.addPest](state, { id, phaseId }) {
    state.productsPests
      .filter((e) => e.pest_id === id)
      .forEach(({ pest_id, product_id }) =>
        state.programPestsProducts.push({
          program_phase_id: phaseId,
          pest_id,
          product_id,
          quantity: undefined,
          is_selected: 1,
          // TODO: price
        })
      );
  },
  [mutationTypes.addStimulationType](state, { id, phaseId }) {
    state.productsTypesStimulation
      .filter((e) => e.type_stimulation_id === id)
      .forEach(({ type_stimulation_id, product_id }) =>
        state.programTypesStimulationProducts.push({
          program_phase_id: phaseId,
          type_stimulation_id,
          product_id,
          quantity: undefined,
          is_selected: 1,
          // TODO: price
        })
      );
  },
  [mutationTypes.removePest](state, { id, phaseId }) {
    state.programPestsProducts = state.programPestsProducts.filter(
      (p) => !(p.pest_id === id && p.program_phase_id === phaseId)
    );
  },
  [mutationTypes.removeStimulationType](state, { id, phaseId }) {
    state.programTypesStimulationProducts =
      state.programTypesStimulationProducts.filter(
        (p) => !(p.type_stimulation_id === id && p.program_phase_id === phaseId)
      );
  },

  [mutationTypes.setComment](state, { id, comment }) {
    state.programPhases.find((p) => p.id === id).comment = comment;
  },
  [mutationTypes.setWaterPhase](state, { id, value }) {
    state.programPhases.find((p) => p.id === id).volume_per_ha = value;
  },
  [mutationTypes.setPestProductQuantity](
    state,
    { product_id, pest_id, program_phase_id, quantity }
  ) {
    state.programPestsProducts.find(
      (p) =>
        p.product_id === product_id &&
        p.pest_id === pest_id &&
        p.program_phase_id === program_phase_id
    ).quantity = quantity;
  },
  [mutationTypes.setStimulationTypeProductQuantity](
    state,
    { product_id, type_stimulation_id, program_phase_id, quantity }
  ) {
    state.programTypesStimulationProducts.find(
      (p) =>
        p.product_id === product_id &&
        p.type_stimulation_id === type_stimulation_id &&
        p.program_phase_id === program_phase_id
    ).quantity = quantity;
  },

  [mutationTypes.saveProgramStart](state) {
    state.isSaving = true;
    state.error = null;
  },
  [mutationTypes.saveProgramFailure](state, payload) {
    state.isSaving = false;
    state.error = payload;
  },
  [mutationTypes.saveProgramSuccess](state, payload) {
    state.isSaving = false;
    state.error = null;
    state.savedRedirectId = payload;
    state.serverData = cloneDeep(
      Object.fromEntries(
        ['program', ...editableKeysProgram].map((key) => [key, state[key]])
      )
    );
  },
};

const actions = {
  async [actionTypes.fetchProgram]({ commit, rootState }, payload) {
    commit(mutationTypes.programFetchingStart);
    try {
      const timestampGeobase = rootState.glossaryGeobase.timestamp;
      const timestampClients = rootState.clientsList.timestampClients;
      const res = await (payload
        ? api.getEditProgramData(
            payload,
            state.timestamp,
            timestampGeobase,
            timestampClients
          )
        : api.getNewProgramData(
            state.timestamp,
            timestampGeobase,
            timestampClients
          ));

      if (res.status === 'ok') {
        if (res.programPhases) {
          res.programPhases.sort((a, b) => a.order - b.order);
        }

        commit(mutationTypes.programFetchingSuccess, deepFreeze(res));
        if (res.geoBase) {
          commit(
            geobaseMutationTypes.getGeobaseSuccess,
            deepFreeze(res.geoBase),
            {
              root: true,
            }
          );
        }

        if (res.clients) {
          commit(clientsMutaionTypes.setClients, res.clients, { root: true });
        }

        if (res.products?.length) {
          commit(plantsCareMutationTypes.setProducts, res, {
            root: true,
          });
        }
      } else {
        throw Error(res.message || 'Unknown error');
      }
    } catch (err) {
      commit(mutationTypes.programFetchingFailure, err);
    }
  },
  async [actionTypes.fetchClient]({ commit }, payload) {
    commit(mutationTypes.clientFetchingStart);
    try {
      const { controller, promise } = api.getClientData(payload);
      commit(mutationTypes.clientFetchingSetAbortController, controller);
      const res = await promise;

      if (res.status === 'ok') {
        if (res.client === null) throw Error('Client not found');
        commit(mutationTypes.clientFetchingSuccess, deepFreeze(res));
      } else {
        throw Error(res.message || 'Unknown error');
      }
    } catch (err) {
      commit(mutationTypes.clientFetchingFailure, err);
    }
  },
  async [actionTypes.templatesSearch]({ commit }, payload) {
    commit(mutationTypes.templatesSearchStart);
    try {
      const { controller, promise } = api.findTemplates(payload);
      commit(mutationTypes.templatesSearchSetAbortController, controller);
      const res = await promise;
      if (res.status === 'ok') {
        commit(
          mutationTypes.templatesSearchSuccess,
          Object.freeze(res.templates ?? [])
        );
      } else throw Error(res.message || 'Unknown error');
    } catch (err) {
      commit(mutationTypes.templatesSearchFailure, err);
    }
  },
  async [actionTypes.fetchTemplate]({ commit, rootState }, payload) {
    commit(mutationTypes.templateFetchingStart);
    try {
      const res = await api.getEditTemplateData(
        payload,
        0,
        Number(new Date()),
        rootState.plantsCare.productsTimestamp
      );
      if (res.status === 'ok') {
        commit(mutationTypes.templateFetchingSuccess, deepFreeze(res));
        if (res.products?.length) {
          commit(plantsCareMutationTypes.setProducts, res, {
            root: true,
          });
        }
      } else throw Error(res.message || 'Unknown error');
    } catch (err) {
      commit(mutationTypes.templateFetchingFailure, err);
    }
  },
  async [actionTypes.saveProgram]({ commit, state }) {
    commit(mutationTypes.saveProgramStart);
    try {
      const payload = Object.fromEntries(
        ['program', ...editableKeysProgram].map((key) => [key, state[key]])
      );
      payload.programPhases = payload.programPhases.map((p, i) => ({
        ...p,
        order: i + 1,
      }));
      const res = await api.saveProgramData(payload);
      if (res.status === 'ok') {
        commit(mutationTypes.saveProgramSuccess, res.id);
      } else throw Error(res.message || 'Unknown error');
    } catch (err) {
      commit(mutationTypes.saveProgramFailure, err);
    }
  },
  async [actionTypes.deleteProgram]({ commit, state }) {
    commit(mutationTypes.saveProgramStart);
    try {
      const res = await api.saveProgramData({
        delete: true,
        program: { id: state.program.id },
      });
      if (res.status !== 'ok') throw Error(res.message || 'Unknown error');
      commit(mutationTypes.saveProgramSuccess, {});
    } catch (err) {
      commit(mutationTypes.saveProgramFailure, err);
    }
  },
};

export { mutationTypes, actionTypes, serverKeysProgram };

export default {
  state,
  actions,
  mutations,
  getters,
};
