import authApi from '@/api/auth';
import {
  clearStorage,
  setItem,
  setStorage,
} from '@/helpers/persistanceStorage';
import router from '@/main';

const state = {
  isSubmitting: false,
  isSubmittingUpdate: false,
  isLoggedIn: null,
  isLoading: false,
  currentUser: null,
  currentUserRole: null,
  isLoginFormHasError: false,
};

import { mutationTypes as adminMutationTypes } from './admin';
import * as adminApi from '../../api/admin';

export const mutationTypes = {
  loginStart: '[auth] Login start',
  loginSuccess: '[auth] Login success',
  loginFailure: '[auth] Login failure',

  loginResetForm: '[auth] Login reset form',

  logout: '[auth] logout',

  currentUserStart: '[auth] currentUser start',
  currentUserSuccess: '[auth] currentUser success',
  currentUserFailure: '[auth] currentUser failure',

  currentUserStartFull: '[auth] currentUserFull start',
  currentUserSuccessFull: '[auth] currentUserFull success',
  currentUserFailureFull: '[auth] currentUserFull failure',

  setPasswordStart: '[auth] setPassword start',
  setPasswordSuccess: '[auth] setPassword success',
  setPasswordFailure: '[auth] setPassword failure',

  setCurrentUserStart: '[auth] setCurrentUser start',
  setCurrentUserSuccess: '[auth] setCurrentUser success',
  setCurrentUserFailure: '[auth] setCurrentUser failure',
};
export const actionTypes = {
  login: '[auth] Login',
  logout: '[auth] logout',
  getCurrentUser: '[auth] getCurrentUser',
  getCurrentUserFull: '[auth] getCurrentUserFull',
  setPassword: '[auth] setPassword',
  setCurrentUser: '[auth] setCurrentUser',
};
export const getterTypes = {
  currentUser: '[auth] currentUser',
  isLoggedIn: '[auth] isLoggedIn',
};
const getters = {
  [getterTypes.currentUser]: (state) => {
    return state.currentUser;
  },
  [getterTypes.isLoggedIn]: (state) => {
    return Boolean(state.isLoggedIn);
  },
};
const mutations = {
  [mutationTypes.loginStart](state) {
    state.isSubmitting = true;
    state.isLoginFormHasError = false;
  },
  [mutationTypes.loginSuccess](state, payload) {
    state.isSubmitting = false;
    state.isLoggedIn = true;
    state.currentUser = payload.user;
    state.currentUserRole = payload.role;
  },
  [mutationTypes.loginFailure](state) {
    state.isSubmitting = false;
    state.isLoginFormHasError = true;
  },
  [mutationTypes.logout](state) {
    state.currentUser = null;
    state.isLoggedIn = false;
  },
  [mutationTypes.loginResetForm](state) {
    state.isLoginFormHasError = false;
  },

  [mutationTypes.currentUserStart](state) {
    state.isSubmitting = true;
    //state.isLoginFormHasError = false
  },
  [mutationTypes.currentUserSuccess](state, payload) {
    state.isSubmitting = false;
    state.isLoggedIn = true;
    state.currentUser = payload.user;
    state.currentUserRole = payload.role;
  },
  [mutationTypes.currentUserFailure](state) {
    state.isSubmitting = false;
    //state.isLoginFormHasError = true
  },
  [mutationTypes.currentUserStartFull](state) {
    state.isSubmitting = true;
    //state.isLoginFormHasError = false
  },
  [mutationTypes.currentUserSuccessFull](state, payload) {
    state.isSubmitting = false;
    state.isLoggedIn = true;
    state.currentUser = payload.user;
    state.currentUserRole = payload.role;
  },
  [mutationTypes.currentUserFailureFull](state) {
    state.isSubmitting = false;
    //state.isLoginFormHasError = true
  },

  [mutationTypes.setPasswordStart](state) {
    state.isSubmitting = true;
    state.isLoginFormHasError = false;
  },
  [mutationTypes.setPasswordSuccess](state, payload) {
    state.isSubmitting = false;
    state.isLoggedIn = true;
    state.currentUser = payload.user;
    state.currentUserRole = payload.role;
  },
  [mutationTypes.setPasswordFailure](state) {
    state.isSubmitting = false;
    state.isLoginFormHasError = true;
  },

  [mutationTypes.setCurrentUserStart](state) {
    state.isSubmitting = true;
    state.isSubmittingUpdate = true;
    state.isLoginFormHasError = false;
  },
  [mutationTypes.setCurrentUserSuccess](state, payload) {
    state.isSubmitting = false;
    state.isSubmittingUpdate = false;
    state.isLoggedIn = true;
    if (payload) {
      state.currentUser = payload.user;
      state.currentUserRole = payload.role;
    }
  },
  [mutationTypes.setCurrentUserFailure](state) {
    state.isSubmitting = false;
    state.isSubmittingUpdate = false;
    state.isLoginFormHasError = true;
  },
};
const actions = {
  async [actionTypes.login](context, payload) {
    context.commit(mutationTypes.loginStart);
    try {
      let response = await authApi.login(payload);
      context.commit(mutationTypes.loginSuccess, response.data);
      clearStorage();
      setStorage(payload.rememberMe);
      setItem('token', response.data.token);
      //await router.push('/');
      if (response.data?.user?.is_standard_password === 0) {
        await router.push('/newpassword');
      } else {
        await router.push('/users');
      }
    } catch (error) {
      //console.log(error.response.status);
      context.commit(mutationTypes.loginFailure);
    }
  },
  async [actionTypes.logout](context) {
    setItem('token', '');
    clearStorage(true);
    context.commit(mutationTypes.logout);
    await router.push({ name: 'Login' });
  },
  async [actionTypes.getCurrentUser](context, payload) {
    context.commit(mutationTypes.currentUserStart);
    try {
      let response = await authApi.getCurrentUser(payload);
      context.commit(mutationTypes.currentUserSuccess, response.data);
      if (response.data?.user?.is_standard_password === 0) {
        await router.push('/newpassword');
      }
      //setItem('token', response.data.token);
      //await router.push('/');
    } catch (error) {
      //console.log(error.response.status);
      context.commit(mutationTypes.currentUserFailure);
    }
  },
  async [actionTypes.setPassword](context, payload) {
    context.commit(mutationTypes.setPasswordStart);
    try {
      let response = await authApi.setPassword(payload);
      context.commit(mutationTypes.setPasswordSuccess, response.data);
      if (response.data?.user?.is_standard_password === 1) {
        await router.push('/users');
      }
    } catch (error) {
      context.commit(mutationTypes.setPasswordFailure);
    }
  },
  async [actionTypes.getCurrentUserFull](context, payload) {
    context.commit(mutationTypes.currentUserStartFull);
    try {
      let response = await authApi.getCurrentUserFull(payload);
      context.commit(adminMutationTypes.getNewUserDataSuccess, response.data);
      context.commit(mutationTypes.currentUserSuccessFull, response.data);
      if (response.data?.user?.is_standard_password === 0) {
        await router.push('/newpassword');
      }
      //setItem('token', response.data.token);
      //await router.push('/');
    } catch (error) {
      //console.log(error.response.status);
      context.commit(mutationTypes.currentUserFailureFull);
    }
  },
  async [actionTypes.setCurrentUser](context, payload) {
    context.commit(mutationTypes.setCurrentUserStart);
    try {
      let response = await adminApi.updateCurrentUser(payload);
      context.commit(mutationTypes.setCurrentUserSuccess, response.data);
    } catch (error) {
      context.commit(mutationTypes.setCurrentUserFailure);
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
