import deepFreeze from 'deep-freeze';

import api from '@/api/plantsCare';
import { mutationTypes as geobaseMutationTypes } from './glossaryGeobase';

const createState = () => ({
  timestamp: 0,
  isLoading: false,
  error: null,
  templates: [],
  categoriesCultures: [],
  cultures: [],
});

const state = createState();

const mutationTypes = {
  fetchStart: '[plants care] templates fetch start',
  fetchSuccess: '[plants care] templates fetch success',
  fetchFailure: '[plants care] templates fetch failure',
};

const actionTypes = {
  fetchTemplates: '[plants care] fetch templates',
};

const mutations = {
  [mutationTypes.fetchStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.fetchFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },
  [mutationTypes.fetchSuccess](state, payload) {
    state.timestamp = new Date().getTime();
    if (payload.templates) state.templates = payload.templates;
    if (payload.categoriesCultures)
      state.categoriesCultures = payload.categoriesCultures;
    if (payload.cultures) state.cultures = payload.cultures;
    state.error = null;
    state.isLoading = false;
  },
};

const actions = {
  async [actionTypes.fetchTemplates]({ commit, rootState }) {
    commit(mutationTypes.fetchStart);
    try {
      const res = await api.getTemplates(
        state.timestamp,
        rootState.glossaryGeobase.timestamp
      );
      if (res.status === 'ok') {
        commit(mutationTypes.fetchSuccess, deepFreeze(res));
        if (res.geoBase) {
          commit(
            geobaseMutationTypes.getGeobaseSuccess,
            deepFreeze(res.geoBase),
            {
              root: true,
            }
          );
        }
      } else {
        throw Error(res.message || 'Unknown error');
      }
    } catch (err) {
      commit(mutationTypes.fetchFailure, err);
    }
  },
};

export { mutationTypes, actionTypes };

export default {
  state,
  actions,
  mutations,
};
