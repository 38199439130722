const units = [
  { text: 'mg/kg', id: 1 },
  { text: 'meq/100g', id: 2 },
  { text: 'mg/100g', id: 3 },
  { text: 'ppm', id: 4 },
  { text: '%', id: 5 },
  { text: 'P₂O₅ mg/100g', id: 6 },
  { text: 'K₂O mg/100g', id: 7 },
  { text: 'mg/100g', id: 8 },
];

const elementPossibleUnits = [
  {
    elementId: 1,
    units: [
      { id: 3, multiplier: 10 },
      { id: 4, multiplier: 1 },
      { id: 5, multiplier: 10_000 },
    ],
  },
  {
    elementId: 2,
    units: [
      { id: 2, multiplier: 155 },
      { id: 3, multiplier: 10 },
      { id: 4, multiplier: 1 },
      { id: 5, multiplier: 10_000 },
      { id: 6, multiplier: 4.367 },
    ],
  },
  {
    elementId: 3,
    units: [
      { id: 2, multiplier: 390 },
      { id: 3, multiplier: 10 },
      { id: 4, multiplier: 1 },
      { id: 5, multiplier: 10_000 },
      { id: 7, multiplier: 8.264 },
    ],
  },
  {
    elementId: 4,
    units: [
      { id: 2, multiplier: 200 },
      { id: 3, multiplier: 10 },
      { id: 4, multiplier: 1 },
      { id: 5, multiplier: 10_000 },
    ],
  },
  {
    elementId: 5,
    units: [
      { id: 2, multiplier: 121.5 },
      { id: 3, multiplier: 10 },
      { id: 4, multiplier: 1 },
      { id: 5, multiplier: 10_000 },
    ],
  },
  {
    elementId: 6,
    units: [
      { id: 2, multiplier: 160 },
      { id: 3, multiplier: 10 },
      { id: 4, multiplier: 1 },
      { id: 5, multiplier: 10_000 },
    ],
  },
  {
    elementId: 7,
    units: [
      { id: 3, multiplier: 10 },
      { id: 4, multiplier: 1 },
      { id: 5, multiplier: 10_000 },
    ],
  },
  {
    elementId: 8,
    units: [
      { id: 3, multiplier: 10 },
      { id: 4, multiplier: 1 },
      { id: 5, multiplier: 10_000 },
    ],
  },
  {
    elementId: 9,
    units: [
      { id: 3, multiplier: 10 },
      { id: 4, multiplier: 1 },
      { id: 5, multiplier: 10_000 },
    ],
  },
  {
    elementId: 10,
    units: [
      { id: 3, multiplier: 10 },
      { id: 4, multiplier: 1 },
      { id: 5, multiplier: 10_000 },
    ],
  },
  {
    elementId: 11,
    units: [
      { id: 3, multiplier: 10 },
      { id: 4, multiplier: 1 },
      { id: 5, multiplier: 10_000 },
    ],
  },
  {
    elementId: 12,
    units: [
      { id: 3, multiplier: 10 },
      { id: 4, multiplier: 1 },
      { id: 5, multiplier: 10_000 },
    ],
  },
];

export { units, elementPossibleUnits };
