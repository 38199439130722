<-- Translated -->
<template>
  <Modal @backgroundClose="$emit('close')">
    <div class="modal modal--long">
      <div class="modalwrap2-topline">
        <div class="topline-minizone"></div>
        <Breadcrumps
          :data="[
            {
              title: editingId
                ? $t('GlossaryLinkedPestsModal:TitleEdit')
                : headerAdd || this.$t('GlossaryLinkedPestsModal:TitleAdd'),
            },
          ]"
        />
        <div class="topline-minizone">
          <Button
            size="s-standart"
            color="accentlightlines"
            icon="x"
            :is-loading="false"
            :loading-left="true"
            @click="$emit('close')"
          />
        </div>
      </div>

      <div class="modalwrap2-content">
        <div
          class="modalwrap3-inputs margintop--standart marginbottom--standart"
        >
          <DropdownSelect
            :placeholder="$t('GlossaryLinkedPestsModal:Category.Placeholder')"
            :label="$t('GlossaryLinkedPestsModal:Category.Label')"
            v-model="selectedCategory"
            :options="inputCategories"
            dd-max-elements="6"
          />

          <DropdownPlusSelect
            :label="$t('GlossaryLinkedPestsModal:Name.Label')"
            :placeholder="$t('GlossaryLinkedPestsModal:Name.Placeholder')"
            size="long"
            :autofocus="true"
            v-model="selectedItem"
            :options="pestsOfSelectedCategory"
            dd-max-elements="6"
          />
        </div>

        <div class="modalwrap3-buttons">
          <Button
            v-if="editingId"
            :text="$t('GlossaryLinkedPestsModal:ButtonDelete')"
            size="wide"
            color="alertlines"
            icon="delete"
            :is-loading="false"
            :loading-left="true"
            :disabled="!canDelete"
            @click="remove"
          />
          <Button
            :text="$t('GlossaryLinkedPestsModal:ButtonCancel')"
            size="wide"
            color="graylines"
            icon="cancel"
            :is-loading="false"
            :loading-left="true"
            @click="$emit('close')"
          />
          <Button
            :text="$t('GlossaryLinkedPestsModal:ButtonSave')"
            size="wide"
            color="accent"
            icon="save"
            :is-loading="false"
            :loading-left="true"
            :disabled="!canSave"
            @click="save"
          />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/pages/Modal';
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import DropdownSelect from '@/components/views/wrappers/DropdownSelect';
import DropdownPlusSelect from '@/components/views/wrappers/DropdownPlusSelect';

const mapEntiny = (e) => ({
  value: e.id,
  text: e.name,
});

export default {
  components: {
    Modal,
    Breadcrumps,
    Button,
    DropdownSelect,
    DropdownPlusSelect,
  },
  props: {
    editingId: { type: Number, default: null },
    pests: { type: Array, default: () => [] },
    inputCategories: { type: Array, default: () => [] },
    selectedPests: { type: Array, default: () => [] },
    headerAdd: { type: String },
  },
  data() {
    return {
      selectedItem: null,
      selectedCategory: null,
    };
  },
  computed: {
    pestsOfSelectedCategory() {
      return this.pests
        .filter((pest) =>
          this.selectedCategory
            ? pest.category_id === this.selectedCategory.value
            : true
        )
        .map(mapEntiny)
        .map((e) => ({
          ...e,
          exists: this.selectedPests.some(({ pest_id }) => pest_id === e.value),
        }));
    },
    canSave() {
      return this.selectedItem !== null && !this.selectedItem.exists;
    },
    canDelete() {
      return this.editingId === this.selectedItem?.value;
    },
  },
  methods: {
    save() {
      if (this.editingId) {
        this.$emit('edit', this.editingId, this.selectedItem.value);
      } else {
        this.$emit('save', this.selectedItem.value);
      }
      this.$emit('close');
    },
    remove() {
      this.$emit('remove', this.editingId);
      this.$emit('close');
    },
  },
  created() {
    if (this.editingId) {
      const pest = this.pests.find(({ id }) => id === this.editingId);
      this.selectedCategory = this.inputCategories.find(
        ({ value }) => value === pest.category_id
      );
      this.selectedItem = this.pestsOfSelectedCategory.find(
        ({ value }) => value == this.editingId
      );
    }
  },
};
</script>
