<!-- Translated -->
<template>
  <div>
    <div class="chead el--borders-top el--borders-sides el--rounded-top">
      <div class="chead__line-block">
        <div
          class="vsvg vsvg--standart chead_icon"
          v-svg="{ name: 'pesticidebug' }"
        ></div>
        <div class="chead_text">{{ $t('GlossaryLinkedPests:MainText') }}</div>
      </div>
    </div>
    <div
      class="cblock el--borders-all el--rounded-bottom"
      v-if="selectedPests.length === 0"
    >
      <div class="cblock__infoblock cblock__infoblock--nocontentyet">
        <slot name="description" />
        <Button
          v-if="!readonly"
          :text="$t('GlossaryLinkedPests:ButtonAdd')"
          size="wider"
          color="accent"
          icon="plus"
          :disabled="!canAdd || disabled"
          @click="add"
        />
      </div>
    </div>

    <div class="cblock el--borders-sides" v-if="selectedPests.length > 9">
      <div class="cblock__internal">
        <div class="cblock__line">
          <div class="cblock__line-block">
            <DropdownSelect
              :options="inputCategories"
              v-model="selectedCategory"
              :placeholder="$t('GlossaryLinkedPests:CategoryNotselected')"
              :label="$t('GlossaryLinkedPests:CategoryLabel')"
              :disabled="isLoading"
            />
            <Input
              type="text"
              :placeholder="$t('GlossaryLinkedPests:Pest.Placeholder')"
              :label="$t('GlossaryLinkedPests:Pest.Label')"
              size="long"
              v-model="categoryName"
              :state="isLoading ? 'disabled' : ''"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="cblock el--rounded-bottom" v-if="selectedPests.length">
      <Table
        :headers="tablePestsHeaders"
        :data-arr="dataArrPests"
        :table-loading="isLoading"
        :loading-count-items="3"
        :disabled="disabled"
        @editClick="edit"
      >
        <Button
          v-if="!disabled && !readonly"
          :text="
            canAdd
              ? $t('GlossaryLinkedPests:ButtonAdd')
              : $t('GlossaryLinkedPests:PestsFull')
          "
          :title="canAdd ? '' : $t('GlossaryLinkedPests:PestsFinish')"
          size="wider"
          color="accentlines"
          icon="plus"
          :is-loading="false"
          :loading-left="false"
          @click="add"
          :disabled="!canAdd || isLoading"
        />
      </Table>
    </div>

    <AddPestModal
      v-if="(!multiMode || editingPestId !== null) && showModal"
      :pests="pests"
      :inputCategories="inputCategories"
      :selectedPests="selectedPests"
      :editingId="editingPestId"
      @close="showModal = false"
      @save="newPest"
      @remove="removePest"
      @edit="swapPests"
    />
    <AddPestModalMulti
      v-if="multiMode && editingPestId === null && showModal"
      :pests="pests"
      :inputCategories="inputCategories"
      :selectedPests="selectedPests"
      :editingId="editingPestId"
      @close="showModal = false"
      @save="newPest"
      @saveMulti="newPests"
      @remove="removePest"
      @edit="swapPests"
    />
  </div>
</template>

<script>
import Input from '@/components/views/Input';
import Button from '@/components/views/Button';
import Table from '@/components/tables/Table';
import DropdownSelect from '@/components/views/wrappers/DropdownSelect';
import AddPestModal from './GlossaryLinkedPestsModal';
import AddPestModalMulti from './GlossaryLinkedPestsModalMulti';

import { mapState } from 'vuex';
import isEqual from 'lodash.isequal';

export default {
  name: 'GlossaryPhasesEditLinkedPests',
  components: {
    Input,
    Button,
    Table,
    AddPestModal,
    DropdownSelect,
    AddPestModalMulti,
  },
  model: { prop: 'selectedPests' },
  props: {
    pests: { type: Array, default: () => [] },
    selectedPests: { type: Array, default: () => [] },
    isLoading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    multiMode: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      showModal: false,
      editingPestId: null,
      selectedCategory: null,
      categoryName: '',
    };
  },
  computed: {
    ...mapState({
      categories: (state) => state.glossaryPests.categoriesPests,
    }),
    tablePestsHeaders() {
      const headers = [
        {
          text: this.$t('GlossaryLinkedPests:TableHeades:0'), //'Категория',
          class: 'col-culturesort',
          width: '30%',
          type: 'text',
        },
        {
          text: this.$t('GlossaryLinkedPests:TableHeades:1'), //'Вредные объекты',
          class: 'col-culturesort',
          width: '70%',
          type: 'text',
        },
      ];

      if (!this.readonly) {
        headers.push({
          text: '',
          class: 'col-edit',
          width: '',
          type: 'edit',
          icon: 'edit',
        });
      }

      return headers;
    },
    inputCategories() {
      return this.categories.map((e) => ({
        value: e.id,
        text: e.name,
      }));
    },
    pestsById() {
      const entries = this.pests.map((pest) => [
        pest.id,
        {
          ...pest,
          category: this.categories.find(({ id }) => id === pest.category_id),
        },
      ]);
      return Object.fromEntries(entries);
    },
    dataArrPests() {
      const filterByCategory = (pest) =>
        this.selectedCategory
          ? pest.category.id === this.selectedCategory.value
          : true;
      const filterByName = (pest) =>
        //pest.name.toLowerCase().startsWith(this.categoryName.toLowerCase());
        pest.name.toLowerCase().includes(this.categoryName.toLowerCase());

      return this.selectedPests
        .map(({ pest_id }) => this.pestsById[pest_id])
        .filter(filterByCategory)
        .filter(filterByName)
        .map((pest) => [
          { text: pest.category.name },
          { text: pest.name },
          { text: pest.id },
        ]);
    },
    canAdd() {
      return this.pests.length > this.selectedPests.length;
    },
  },
  methods: {
    add() {
      this.editingPestId = null;
      this.showModal = true;
    },
    edit(id) {
      this.editingPestId = id;
      this.showModal = true;
    },
    newPest(id) {
      this.$emit('input', [...this.selectedPests, { pest_id: id }]);
    },
    newPests(tmpPests) {
      this.$emit('input', [
        ...tmpPests.map((v) => {
          return {
            pest_id: v,
          };
        }),
      ]);
    },
    removePest(id) {
      this.$emit(
        'input',
        this.selectedPests.filter(({ pest_id }) => pest_id !== id)
      );
    },
    swapPests(oldId, newId) {
      this.$emit('input', [
        ...this.selectedPests.filter(({ pest_id }) => pest_id !== oldId),
        { pest_id: newId },
      ]);
    },
  },
  watch: {
    pests(value, oldValue) {
      if (isEqual(value, oldValue)) return;
      const ids = value.map((pest) => pest.id);
      this.$emit(
        'input',
        this.selectedPests.filter(({ pest_id }) => ids.includes(pest_id))
      );
    },
  },
};
</script>
