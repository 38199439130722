<!--Translated-->
<template>
  <div class="cwrap">
    <div class="chead el--borders-top el--borders-sides el--rounded-top">
      <div class="chead__line-block">
        <div
          class="vsvg vsvg--standart chead_icon"
          v-svg="{ name: 'edit' }"
        ></div>
        <div class="chead_text">{{ $t('TemplateDescription:Header') }}</div>
      </div>
    </div>

    <div
      class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
    >
      <div class="cblock__internal">
        <div class="cblock__line">
          <div class="cblock__line-block">
            <LocationSelect
              :geoEntries="geoMapped"
              v-model="geoId"
              :freeSelect="true"
              :type="2"
              :disabled="disabled"
              :locked="readonly"
            />
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <CultureSelect
              :categories="categories"
              :cultures="cultures"
              v-model="cultureId"
              :disabled="disabled"
              :locked="readonly"
            />
            <CultureAgeSelect
              v-model="age"
              :disabled="disabled"
              :locked="readonly || selectedOneYearCulture"
            />
            <CalcMethodSelect
              v-model="calcMethod"
              :disabled="disabled"
              :locked="readonly"
            />
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <Input
              type="text"
              :placeholder="$t('TemplateDescription:Name.Placeholder')"
              :label="$t('TemplateDescription:Name.Label')"
              size="long"
              v-model="name"
              :state="disabled ? 'disabled' : readonly ? 'disabled2' : ''"
            />
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <Input
              type="textarea"
              :placeholder="$t('TemplateDescription:Description.Placeholder')"
              :label="$t('TemplateDescription:Description.Label')"
              size="long"
              v-model="description"
              :state="disabled ? 'disabled' : readonly ? 'disabled2' : ''"
            />
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <RadioToggle
              :text1="$t('TemplateDescription:Active:Hide')"
              :text2="$t('TemplateDescription:Active:Active')"
              :reverse="true"
              v-model="isActive"
              :disabled="disabled"
              :locked="disabled ? false : readonly"
              :no-label="true"
            />
            <!--:label="$t('TemplateDescription:Active:Label')"-->
            <div
              class="radiotogglewrap"
              :class="{
                'radiotogglewrap--disabled': disabled,
                'radiotogglewrap--onlyshow': disabled ? false : readonly,
                'radiotogglewrap--active': !disabled && !readonly && isDefault,
              }"
              :style="{ pointerEvents: readonly ? 'none' : '' }"
            >
              <!--<label class="lable disabled">
                {{ $t('TemplateDescription:Default:Label') }}
              </label>-->
              <fieldset class="inputradiotoggle">
                <label class="radiotoggle">
                  <CheckBox
                    :text="$t('TemplateDescription:Default:Text')"
                    color-checked="accentfill"
                    color-un-checked="gray"
                    v-model="isDefault"
                    :disabled="disabled"
                  />
                </label>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import Input from '@/components/views/Input';
import RadioToggle from '@/components/views/RadioToggle';
import LocationSelect from '@/components/views/LocationSelect';
import CultureSelect from '@/components/views/CultureSelect';
import CheckBox from '@/components/CheckBox';
import CultureAgeSelect from '@/components/views/CultureAgeSelect';
import CalcMethodSelect from '@/components/views/CalcMethodSelect';

import { staticData } from '@/store/modules/plantsCare';
import { mapInputEntityWithMerge } from '@/components/adapters/mapInput';
import { mutationTypes } from '@/store/modules/plantsCare';

export default {
  name: 'TemplateDescription',
  components: {
    RadioToggle,
    Input,
    CheckBox,
    LocationSelect,
    CultureSelect,
    CultureAgeSelect,
    CalcMethodSelect,
  },
  props: {
    readonly: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.plantsCare.isLoading,
      isSaving: (state) => state.plantsCare.isSaving,
      categories: (state) =>
        state.plantsCare.categoriesCultures.map(mapInputEntityWithMerge),
      cultures: (state) =>
        state.plantsCare.cultures.map(mapInputEntityWithMerge),
      template: (state) => state.plantsCare.template,
      selectedOneYearCulture(state) {
        return Boolean(
          state.plantsCare.cultures.find((c) => c.id === this.cultureId)
            ?.only_one_year
        );
      },
    }),
    ...mapGetters(['geoMapped']),
    disabled() {
      return this.isLoading || this.isSaving;
    },
    calculationMethods() {
      return Object.entries(staticData.calculationMethods).map(
        ([id, text]) => ({ id, value: id, text })
      );
    },
    geoId: {
      get() {
        return this.template.geo_id;
      },
      set(id) {
        this.$store.commit(mutationTypes.setGeoId, id);
      },
    },
    cultureId: {
      get() {
        return this.template.culture_id;
      },
      set(id) {
        this.$store.commit(mutationTypes.setCultureId, id);
      },
    },
    age: {
      get() {
        return this.template.culture_age ?? null;
      },
      set(age) {
        this.$store.commit(mutationTypes.setAge, age);
      },
    },
    calcMethod: {
      get() {
        return this.template.calculation_method_id;
      },
      set(value) {
        this.$store.commit(
          mutationTypes.setCalculationMethod,
          value ? Number(value) : value
        );
      },
    },
    name: {
      get() {
        return this.template.name;
      },
      set(name) {
        this.$store.commit(mutationTypes.setName, name);
      },
    },
    description: {
      get() {
        return this.template.description;
      },
      set(description) {
        this.$store.commit(mutationTypes.setDescription, description);
      },
    },
    isActive: {
      get() {
        return this.template.is_active;
      },
      set(value) {
        this.$store.commit(mutationTypes.setActive, value);
      },
    },
    isDefault: {
      get() {
        return this.template.is_default;
      },
      set(value) {
        this.$store.commit(mutationTypes.setDefault, value);
      },
    },
  },
  watch: {
    selectedOneYearCulture: {
      handler(val) {
        if (val) this.age = 1;
      },
      immediate: true,
    },
  },
};
</script>
