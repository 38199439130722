<!--Translated-->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('UserMyCultures:Title') }]" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('UserMyCultures:ButtonUndo')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'UserHomePage' })"
        />
        <Button
          :text="$t('UserMyCultures:ButtonSave')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="isSubmiting"
          :loading-left="true"
          :disabled="!client2CanSave"
          @click="saveClient"
        />
      </div>
    </div>

    <LoaderBlock v-if="isLoading" />
    <template v-else>
      <!-- TODO: translate me -->
      <WarningBlockAudio
        v-if="showManual"
        :phaseVisible="true"
        :trackNumber="2"
        :info-button-visible="false"
        @dismiss="showManual = false"
      />
      <ClientCulture
        v-for="(culture, index) in cultures"
        :key="culture.id"
        :culture="culture"
        :count="cultures.length"
        :isFirst="index === 0"
      />
      <ClientAddCulture />
    </template>

    <Modal
      v-if="showModalNotSaved"
      @backgroundClose="showModalNotSaved = false"
    >
      <ModalNotSaved
        v-model="showModalNotSaved"
        @confirm="modalNotSavedConfirm"
      />
    </Modal>
  </article>
</template>

<script>
import throttle from 'lodash.throttle';

import { mapGetters, mapState } from 'vuex';
import {
  actionTypes,
  mutationTypes,
} from '@/store/modules/clientsArea/clients';

import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';
import Modal from '@/pages/Modal';
import ModalNotSaved from '@/components/modals/modalNotSaved';
import ClientAddCulture from './userCultures/ClientAddCulture';
import ClientCulture from './userCultures/ClientCulture';
import LoaderBlock from '@/components/blocks/LoaderBlock';
import WarningBlockAudio from '@/components/views/WarningblockAudio';

export default {
  name: 'UserMyCultures',
  components: {
    Modal,
    Breadcrumps,
    Button,
    ClientAddCulture,
    ClientCulture,
    ModalNotSaved,
    LoaderBlock,
    WarningBlockAudio,
  },
  data() {
    return {
      showModalNotSaved: false,
      nextRouteConfirm: null,
      nextRoute: null,
      showManual: true,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.clients2.isLoading,
      isSubmiting: (state) => state.clients2.isSubmiting,
      lastSaveTime: (state) => state.clients2.lastSaveTime,
      lastAddedItemId: (state) => state.clients2.lastAddedItemId,
      cultures: (state) => state.clients2.clientCultures,
      error: (state) => state.clients2.error,
    }),
    ...mapGetters(['geoMapped', 'client2CanSave']),
  },
  methods: {
    saveClient() {
      this.$store.dispatch(actionTypes.submitClientData);
    },
    modalNotSavedConfirm() {
      if (this.nextRoute) {
        this.nextRouteConfirm = true;
        this.$router.push(this.nextRoute);
      }
    },
    onScroll: throttle(function () {
      const levels = [1, 2, 3];
      for (const level of levels) {
        const levelNodes = document.querySelectorAll(`.level${level}`);
        for (const node of levelNodes) {
          const { y } = node.getBoundingClientRect();
          const position = y - (level - 1) * node.offsetHeight;
          if (position < 1) {
            node.classList.add('is-pinned');
          } else {
            node.classList.remove('is-pinned');
          }
        }
        const fixer = document.getElementById('fix-scroll-jump');
        if (fixer) {
          const { y } = fixer.getBoundingClientRect();
          if (y >= 0) fixer.style.height = '1px';
          else fixer.style.height = 0;
        }
      }
    }, 100),
  },
  watch: {
    isLoading() {
      this.onScroll();
    },
    error: {
      handler(error) {
        if (error) {
          this.$awn.alert(String(error));
          throw error;
        }
      },
      immediate: true,
    },
    lastSaveTime(time) {
      if (!time) return;
      this.$awn.success(this.$t('UserMyCultures:Notifications:Success'), {
        durations: { global: 1000 },
      });
      this.$store.commit(mutationTypes.setDefaultState);
      this.$store.dispatch(actionTypes.getClientData);
    },
    lastAddedItemId(id) {
      setTimeout(() => {
        const el = document.querySelector(`[data-item-id="${id}"]`);
        if (!el) return;
        const parent = el.parentElement.querySelector('.cblock');
        el.classList.add('attention-to-new-item');
        setTimeout(() => el.classList.remove('attention-to-new-item'), 1000);
        parent.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 50);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.nextRouteConfirm || !this.client2CanSave) {
      next();
    } else {
      this.showModalNotSaved = true;
      this.nextRoute = to;
      next(false);
    }
  },
  created() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="scss">
.is-pinned.level1 {
  z-index: 1999 !important;
}
.is-pinned.level2 {
  z-index: 1998 !important;
}
.is-pinned.level3 {
  z-index: 1997 !important;
}
.attention-to-new-item {
  background-color: rgb(255, 250, 200);
}
.chead {
  transition: background-color 1000ms;
}
</style>
