<!--Translated-->
<template>
  <div class="modalwrap1 modalwrap1--login">
    <div class="modalwrap2-logo">
      <img class="login_logo" src="@/assets/loginpage/login_logo.svg" />
    </div>

    <form class="modalwrap2-content" method="post" @submit.prevent="">
      <div class="modalwrap3-inputs">
        <Input
          class="marginbottom--mini"
          type="text"
          :placeholder="$t('UserLogin:Email.Placeholder')"
          label="-"
          icon="contactmail"
          :state="loginState"
          size="max"
          color=""
          v-model="login"
          @click="onInputPress"
        />

        <Input
          class="marginbottom--mini"
          type="password"
          :placeholder="$t('UserLogin:Password.Placeholder')"
          label="-"
          icon="password"
          :state="passwordState"
          size="max"
          color=""
          v-model="password"
          @click="onInputPress"
        />

        <CheckBox
          class="this-checkbox-marginleft"
          :text="$t('UserLogin:CheckSave')"
          v-model="rememberMe"
          :disabled="isFormLoading"
          color-checked="accentfill"
          color-un-checked="accent"
        />
      </div>

      <div class="modalwrap3-buttons">
        <Button
          class="margintop--mini"
          :text="$t('UserLogin:ButtonEnter')"
          size="max"
          color="accent"
          icon="key"
          :is-loading="isFormLoading"
          :loading-left="true"
          @click="onLoginButtonPress"
        />
      </div>
    </form>
  </div>
</template>

<script>
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import CheckBox from '@/components/CheckBox';
import * as adminApi from '@/api/clientsArea/admin';
import {
  clearStorage,
  setItem,
  setStorage,
} from '@/helpers/persistanceStorage';

export default {
  name: 'UserLogin',
  components: {
    CheckBox,
    Input,
    Button,
  },
  data() {
    return {
      login: '',
      password: '',
      rememberMe: true,

      isLoading: false,
      isLoginFormHasError: false,
    };
  },
  computed: {
    loginState() {
      if (this.isLoginFormHasError) {
        return 'error';
      }
      if (this.isLoading) {
        return 'disabled';
      }
      return '';
    },
    passwordState() {
      if (this.isLoginFormHasError) {
        return 'error';
      }
      if (this.isLoading) {
        return 'disabled';
      }
      return '';
    },
    isFormLoading() {
      if (this.isLoading) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async onLoginButtonPress() {
      this.isLoading = true;
      try {
        let result = await adminApi.login({
          login: this.login,
          password: this.password,
          rememberMe: this.rememberMe,
        });
        if (result.response?.status !== 200) {
          if (result?.status === 200 && result.data?.status === 'ok') {
            //Отключено из-за чата
            //clearStorage();
            setStorage(this.rememberMe);
            setItem('token', result.data?.token);
            this.$router.push({ name: 'UserHomePage' });
          } else {
            this.$awn.alert(
              result.data?.error
                ? this.$t('UserLogin:Error') + ': ' + result.data?.error
                : this.$t('UserLogin:Error')
            );
            this.isLoginFormHasError = true;
          }
        } else {
          this.$awn.alert(
            result.data?.error
              ? this.$t('UserLogin:Error') + ': ' + result.data?.error
              : this.$t('UserLogin:Error')
          );
          this.isLoginFormHasError = true;
        }
      } catch (e) {
        console.log(e);
      }
      this.isLoading = false;
    },
    onInputPress() {
      this.isLoginFormHasError = false;
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped></style>
