<template>
  <article class="rightzone rightzone--settings">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('SettingsPage:TitleText') }]" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('SettingsPage:ButtonCancel')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          :disabled="isSaveButtonDisabled"
          @click="reload"
        />
        <Button
          :text="$t('SettingsPage:ButtonSave')"
          size="standart"
          color="accent"
          icon="save"
          :loading-left="true"
          @click="onSave"
          :disabled="isSaveButtonDisabled"
          :isLoading="isLoadingUpdate"
        />
      </div>
    </div>

    <!--    Логин, Пароль-->
    <div class="cwrap marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'settings' }"
          ></div>
          <div class="chead_text">{{ $t('SettingsPage:HeaderMainText') }}</div>
        </div>
      </div>
      <div
        class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                :placeholder="newUser?.username"
                :label="$t('SettingsPage:Login.Lable')"
                icon=""
                size=""
                color=""
                name="new-username"
                state="disabled2"
              />

              <Input
                v-if="isSetPassword"
                type="password"
                :placeholder="$t('SettingsPage:Password.Placeholder')"
                :label="$t('SettingsPage:Password.Label')"
                icon=""
                size=""
                color=""
                name="new-pass"
                autocomplete="new-password"
                v-model="newUser.password"
              />
              <Button
                v-if="!isSetPassword"
                :text="$t('SettingsPage:ResetPasswordButton')"
                size="wide"
                color="graylines"
                icon="key"
                @click="isSetPassword = true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    &lt;!&ndash;    Warningblock&ndash;&gt;
    <div class="warningblock">
      <div class="rightzone_block warningblock-internal">
        <div class="warningblock-colorline"></div>
        <div class="warningblock-iconzone marginleft&#45;&#45;standart marginright&#45;&#45;mini">
          <div v-svg="{ name: 'warningtriangle' }"></div>
        </div>
        <div class="warningblock-text">
          Для изменения основных данных обратитесь к администратору
        </div>
      </div>
    </div>-->

    <!--    Инфо о пользователе-->
    <div class="cwrap marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'edit' }"
          ></div>
          <div class="chead_text">{{ $t('SettingsPage:HeaderUserText') }}</div>
        </div>
      </div>
      <div
        class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                :placeholder="newUser?.pn"
                :label="$t('SettingsPage:UserId.Label')"
                size="long"
                state="disabled2"
              />
            </div>
          </div>
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                :placeholder="newUser?.name"
                :label="$t('SettingsPage:Name.Label')"
                size="standart"
                state="disabled2"
              />
              <Input
                type="text"
                :placeholder="newUser?.full_name"
                :label="$t('SettingsPage:Fullname.Label')"
                size="standart"
                state="disabled2"
              />
              <InputDate
                type="dates"
                :placeholder="$t('SettingsPage:Date.Placeholder')"
                :label-text="$t('SettingsPage:Date.Label')"
                :state="isLoading || isEditUserLoading ? 'disabled' : ''"
                size="standart"
                v-model="newUser.birth_date"
                :isLoading="isEditUserLoading"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    Табл Контакты-->
    <div class="cwrap marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'contacts' }"
          ></div>
          <div class="chead_text">{{ $t('SettingsPage:HeaderContacts') }}</div>
        </div>
      </div>

      <div
        v-if="newUser.contacts.length === 0"
        class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
      >
        <div class="cblock__infoblock cblock__infoblock--nocontentyet">
          <p>{{ $t('SettingsPage:NoContactsData') }}</p>
          <Button
            :text="$t('SettingsPage:ButtonAddContact')"
            size="wider"
            color="accent"
            icon="plus"
            :is-loading="false"
            :loading-left="true"
            :disabled="isLoading"
            @click="showModalAddContact = true"
            :isLoading="isEditUserLoading"
          />
        </div>
      </div>

      <div v-else class="cblock el--rounded-bottom">
        <Table
          :headers="tableHeaders"
          :data-arr="tableContactsArr"
          :table-loading="false"
          :loading-count-items="3"
          @editClick="onEditContact"
          :disabled="isLoading || isEditUserLoading"
        >
          <Button
            :text="$t('SettingsPage:ButtonAddContact')"
            size="wider"
            color="accentlines"
            icon="plus"
            :is-loading="false"
            :loading-left="false"
            @click="showModalAddContact = true"
            :disabled="isLoading"
          />
        </Table>
      </div>
    </div>

    <Modal
      v-if="showModalNotSaved"
      @backgroundClose="showModalNotSaved = false"
    >
      <ModalNotSaved
        v-model="showModalNotSaved"
        @confirm="onModalNotSavedConfirm"
      />
    </Modal>

    <Modal
      v-if="showModalAddContact"
      @backgroundClose="showModalAddContact = false"
    >
      <ModalAddContact
        v-model="showModalAddContact"
        @addContact="onAddContact"
      />
    </Modal>

    <Modal
      v-if="showModalEditContact"
      @backgroundClose="showModalEditContact = false"
    >
      <ModalContactEdit
        v-model="showModalEditContact"
        @onSave="onEditSaveContact"
        @onDelete="onEditDeleteContact"
        :contact="editContact"
      />
    </Modal>
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import RadioToggle from '@/components/views/RadioToggle';
import InputDate from '@/components/views/InputDate';
import Modal from '@/pages/Modal';
import ModalNotSaved from '@/components/modals/modalNotSaved';
import ModalAddContact from '@/components/modals/administration/ModalContactAdd';
import Table from '@/components/tables/Table';
import textHelper from '@/helpers/textHelper';
import ModalContactEdit from '@/components/modals/administration/ModalContactEdit';
import ModalSettingsPagePassword from '@/components/modals/administration/ModalSettingsPagePassword';
import { actionTypes as authActionTypes } from '@/store/modules/auth';

export default {
  name: 'SettingsPage',
  components: {
    ModalSettingsPagePassword,
    ModalContactEdit,
    ModalAddContact,
    ModalNotSaved,
    Modal,
    InputDate,
    RadioToggle,
    Input,
    Button,
    Breadcrumps,
    Table,
  },
  data() {
    return {
      newUser: {
        username: '',
        password: '',
        is_blocked: 0,
        pn: '',
        role_id: null,
        name: '',
        name_en: '',
        full_name: '',
        full_name_en: '',
        gender_id: null,
        birth_date: null,
        contacts: [],
      },
      showModalNotSaved: false,
      validationState: {
        username: true,
        password: true,
        pn: true,
        name: true,
        full_name: true,
        role_id: true,
        birth_date: true,
      },
      isSetPassword: false,
      showModalAddContact: false,
      showModalEditContact: false,
      showModalNewPassword: false,
      editContact: null,
      isClickOnShowPassword: false,
    };
  },
  methods: {
    onModalNotSavedConfirm() {
      if (this.nextRoute) {
        this.nextRouteConfirm = true;
        this.$router.push(this.nextRoute);
      }
    },
    onSave() {
      if (this.isSetPassword) {
        if (this.newUser.password.length < 6) {
          this.$awn.alert(this.$t('SettingsPage:ErrorPasswordMessage'));
          return;
        }
      }
      if (this.isEditUser) {
        this.$store.dispatch(authActionTypes.setCurrentUser, this.newUser);
      }
    },
    onAddContact(contactData) {
      contactData.id = contactData.id || textHelper.generateRandomString(16);
      //console.log(this.newUser.contacts)
      this.newUser.contacts.push(contactData);
      //console.log(this.newUser.contacts)
      this.$nextTick(() => {
        let container = document.querySelector('body');
        //behavior: "smooth"
        container.scrollIntoView({ block: 'end' });
      });
    },
    onEditContact(id) {
      if (this.isLoading) {
        return;
      }
      let contactIndex = -1;
      for (let i = 0; i < this.newUser.contacts.length; i++) {
        if (this.newUser.contacts[i]?.id == id) {
          contactIndex = i;
          break;
        }
      }
      if (contactIndex > -1) {
        this.editContact = this.newUser.contacts[contactIndex];
        this.showModalEditContact = true;
      }
    },
    onEditSaveContact(contactData) {
      let contactIndex = -1;
      for (let i = 0; i < this.newUser.contacts.length; i++) {
        if (this.newUser.contacts[i]?.id == contactData.id) {
          contactIndex = i;
          break;
        }
      }
      if (contactIndex > -1) {
        this.$set(this.newUser.contacts, contactIndex, contactData);
      }
    },
    onEditDeleteContact(contactData) {
      let contactIndex = -1;
      for (let i = 0; i < this.newUser.contacts.length; i++) {
        if (this.newUser.contacts[i]?.id == contactData.id) {
          contactIndex = i;
          break;
        }
      }
      if (contactIndex > -1) {
        this.newUser.contacts.splice(contactIndex, 1);
      }
    },
    reload() {
      //this.$router.go();
      this.isSetPassword = false;
      this.newUser.password = '';
      this.copyUser();
    },
    copyUser() {
      if (this.isEditUser) {
        for (const key in this.newUser) {
          // eslint-disable-next-line
          if (this.$store.state.auth.currentUser.hasOwnProperty(key)) {
            if (this.$store.state.auth.currentUser[key] instanceof Array) {
              //this.newUser[key].splice(0,this.newUser[key].length);
              this.$set(this.newUser, key, []);
              if (this.$store.state.auth.currentUser[key]) {
                for (
                  let i = 0;
                  i < this.$store.state.auth.currentUser[key].length;
                  i++
                ) {
                  let o = { ...this.$store.state.auth.currentUser[key][i] };
                  this.newUser[key].push(o);
                }
              }
            } else {
              this.newUser[key] = this.$store.state.auth.currentUser[key];
            }
          }
        }
      }
    },
  },
  computed: {
    isSaveButtonDisabled() {
      let haveData = false;
      if (this.$store.state.auth.currentUser) {
        for (const key in this.newUser) {
          if (key === 'password') {
            if (this.newUser.password) {
              haveData = true;
            } else {
              continue;
            }
          }
          if (key === 'birth_date') {
            if (
              new Date(this.newUser[key]).toLocaleDateString('ru-RU') !==
              new Date(
                this.$store.state.auth.currentUser[key]
              ).toLocaleDateString('ru-RU')
            ) {
              haveData = true;
            } else {
              continue;
            }
          }
          if (key === 'contacts') {
            if (this.$store.state.auth.currentUser) {
              if (!this.$store.state.auth.currentUser[key]) {
                if (this.newUser[key].length) {
                  haveData = true;
                }
              } else {
                if (this.$store.state.auth.currentUser[key]) {
                  if (
                    this.newUser[key].length !==
                    this.$store.state.auth.currentUser[key].length
                  ) {
                    haveData = true;
                  } else {
                    for (let i = 0; i < this.newUser[key].length; i++) {
                      if (
                        this.newUser[key][i].id !==
                          this.$store.state.auth.currentUser[key][i].id ||
                        this.newUser[key][i].contact_type_id !==
                          this.$store.state.auth.currentUser[key][i]
                            .contact_type_id ||
                        this.newUser[key][i].value !==
                          this.$store.state.auth.currentUser[key][i].value ||
                        this.newUser[key][i].comment !==
                          this.$store.state.auth.currentUser[key][i].comment
                      ) {
                        haveData = true;
                        break;
                      }
                    }
                  }
                }
              }
            }
          } else {
            if (this.newUser[key] !== this.$store.state.auth.currentUser[key]) {
              haveData = true;
            }
          }
        }
      }

      return !haveData;
    },
    tableHeaders() {
      return [
        {
          text: this.$t('SettingsPage:TableContactsHeaders:0'), //'Тип контакта',
          class: 'col-contacttype',
          width: '20%',
          type: 'contactType',
        },
        {
          text: this.$t('SettingsPage:TableContactsHeaders:1'), //'Значение',
          class: 'col-contactvalue',
          width: '30%',
          type: 'text',
        },
        {
          text: this.$t('SettingsPage:TableContactsHeaders:2'), //'Комментарий',
          class: 'col-contactdescription',
          width: '50%',
          type: 'text',
        },
        {
          text: '',
          class: 'col-edit',
          width: '',
          type: 'edit',
          icon: 'edit',
        },
      ];
    },
    isLoading() {
      return this.$store.state.auth.isSubmitting;
    },
    isLoadingUpdate() {
      return this.$store.state.auth.isSubmittingUpdate;
    },
    isEditUserLoading() {
      return this.$store.state.auth.isSubmitting;
    },
    isDone() {
      return this.$store.state.auth?.isSubmitting;
    },
    tableContactsArr() {
      let arr = [];
      this.newUser.contacts.forEach((val) => {
        let tr = [];
        // let contactType = null;
        if (
          this.$store.state.admin.contactTypesMapById.has(val.contact_type_id)
        ) {
          tr.push({
            text:
              this.$store.state.admin.contactTypesMapById.get(
                val.contact_type_id
              ).name ||
              this.$store.state.admin.contactTypesMapById.get(
                val.contact_type_id
              ).name_ru,
            icon: this.$store.state.admin.contactTypesMapById.get(
              val.contact_type_id
            ).icon,
          });
        } else {
          tr.push({ text: val.contact_type_id });
        }
        tr.push({ text: val.value });
        tr.push({ text: val.comment });
        tr.push({ text: val.id });
        arr.push(tr);
      });
      return arr;
    },
    isEditUser() {
      //if(this.$store.state.admin.editUser){
      if (this.$store.state.auth.currentUser) {
        return true;
      }
      return false;
    },
    editUser() {
      return this.$store.state.auth.currentUser;
    },
    isShowPassword() {
      return !this.isEditUser || this.isClickOnShowPassword;
    },
  },
  watch: {
    editUser() {
      this.copyUser();
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.copyUser();
  },
};
</script>

<style lang="scss" scoped></style>
