<template>

  <div class="modal">
    <div class="modalwrap2-topline">
      <div class="topline-minizone"></div>

      <Breadcrumps :data="[{ title: 'Изменить пароль' }]" />

      <div class="topline-minizone">
        <Button
          text=""
          size="s-standart"
          color="accentlightlines"
          icon="x"

        />
      </div>
    </div>

    <div class="modalwrap2-content ">
      <div class="modalwrap3-inputs marginbottom--standart">
        <Input
          class="margintop--standart"
          type="password"
          placeholder="Введите новый пароль"
          label="-"
          icon=""
          state=""
          size="max"
          color=""

        />
        <Input
            class="margintop--standart"
            type="password"
            placeholder="Повторите пароль"
            label="-"
            icon=""
            state=""
            size="max"
            color=""

        />
      </div>

      <div class="modalwrap3-buttons">
        <Button
          text="Отмена"
          size="wide"
          color="graylines"
          icon="cancel"

        />
        <Button
          text="Сохранить"
          size="wide"
          color="accent"
          icon="save"

        />
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/views/Input';
import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';

export default {
  name: 'ModalSettingsPagePassword',
  components: { Input, Button, Breadcrumps },
  props: {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style lang="scss" scoped></style>
