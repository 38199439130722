import authApi from '@/api/auth';
import { clearStorage, setItem } from '@/helpers/persistanceStorage';
import router from '@/main';
import routeNames from '@/router/routeNames';

const state = {
  currentUser: null,
  isLoggedIn: false,
};

export const mutationTypes = {
  logout: '[client auth] logout',
};
export const actionTypes = {
  logout: '[client auth] logout',
  getCurrentUser: '[client auth] getCurrentUser',
};
const mutations = {
  [mutationTypes.logout](state) {
    state.currentUser = null;
    state.isLoggedIn = false;
  },
};
const actions = {
  async [actionTypes.logout](context) {
    setItem('token', '');
    //Отключено из-за чата
    //clearStorage(true);
    context.commit(mutationTypes.logout);
    await router.push({ name: routeNames.userStartscreen });
  },
};

export default {
  state,
  actions,
  mutations,
};
