<!--Translated-->
<template>
  <Modal @backgroundClose="$emit('close')">
    <div class="modal modal--long">
      <div class="modalwrap2-topline">
        <div class="topline-minizone"></div>
        <Breadcrumps
          :data="[{ title: $t('FertilizerAddProductModal:Title') }]"
        />
        <div class="topline-minizone">
          <Button
            size="s-standart"
            color="accentlightlines"
            icon="x"
            :is-loading="false"
            :loading-left="true"
            @click="$emit('close')"
          />
        </div>
      </div>

      <div class="modalwrap2-content">
        <div
          class="modalwrap3-inputs margintop--standart marginbottom--standart"
        >
          <DropdownSelect
            :placeholder="
              $t('FertilizerAddProductModal:ProductType.Placeholder')
            "
            :label="$t('FertilizerAddProductModal:ProductType.Label')"
            v-model="selectedCategory"
            :options="categories"
            dd-max-elements="6"
          />

          <DropdownPlusSelect
            :label="$t('FertilizerAddProductModal:ProductSelect.Placeholder')"
            :placeholder="$t('FertilizerAddProductModal:ProductSelect.Label')"
            size="long"
            :autofocus="true"
            v-model="selectedItem"
            :options="selectableProducts"
            dd-max-elements="6"
          />
        </div>

        <div class="modalwrap3-buttons">
          <Button
            :text="$t('FertilizerAddProductModal:ButtonCancel')"
            size="wide"
            color="graylines"
            icon="cancel"
            :is-loading="false"
            :loading-left="true"
            @click="$emit('close')"
          />
          <Button
            :text="$t('FertilizerAddProductModal:ButtonSave')"
            size="wide"
            color="accent"
            icon="save"
            :is-loading="false"
            :loading-left="true"
            :disabled="!canSave"
            @click="save"
          />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';

import Modal from '@/pages/Modal';
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import DropdownSelect from '@/components/views/wrappers/DropdownSelect';
import DropdownPlusSelect from '@/components/views/wrappers/DropdownPlusSelect';

import { mapInputEntityWithMerge } from '@/components/adapters/mapInput';

export default {
  name: 'FertilizedAddProductModal',
  components: {
    Modal,
    Breadcrumps,
    Button,
    DropdownSelect,
    DropdownPlusSelect,
  },
  props: {
    editingId: { type: [Number, String], required: true },
    alreadyAdded: { type: Array, required: true },
  },
  data() {
    return {
      selectedItem: null,
      selectedCategory: null,
    };
  },
  computed: {
    ...mapState({
      categories: (state) =>
        state.products.productsFertilizersSubcategories.map(
          mapInputEntityWithMerge
        ),
      products: (state) =>
        state.nutrition.productsFertilizer
          .filter((p) => p.is_active)
          .map(mapInputEntityWithMerge),
      subCategoryRelations: (state) =>
        state.nutrition.productsFertilizerSubcategories,
    }),
    productsOfSelectedCategory() {
      if (this.selectedCategory === null) return this.products;

      const relations = this.subCategoryRelations
        .filter(
          ({ fertilizer_subcategory_id }) =>
            fertilizer_subcategory_id === this.selectedCategory.value
        )
        .map((e) => e.product_id);

      return this.products.filter(({ id }) => relations.includes(id));
    },
    selectableProducts() {
      return this.productsOfSelectedCategory.map((p) => ({
        ...p,
        exists: this.alreadyAdded.includes(p.id),
      }));
    },
    canSave() {
      return this.selectedItem !== null && !this.selectedItem.exists;
    },
  },
  methods: {
    save() {
      this.$emit('save', this.selectedItem.value);
      this.$emit('close');
    },
  },
};
</script>
