<!--Translated-->
<template>
  <div class="warningblock warningblock--normal warningblock--selectedtemplate">
    <div class="rightzone_block warningblock-internal">
      <div class="warningblock-colorline"></div>

      <div class="warningblock-iconzone marginleft--standart marginright--mini">
        <div v-svg="{ name: 'bulb' }"></div>
      </div>
      <div class="warningblock-text">
        <span class="message-text1">
          {{ $t('SelectedTemplateBlock:Message1') }}
        </span>
        <span class="message-text2">«{{ text }}»</span>
      </div>
    </div>
    <div class="warningblock-buttonzone marginleft--standart" v-if="canCancel">
      <Button
        :text="$t('SelectedTemplateBlock:ButtonCancel')"
        size="standart"
        color="graylines"
        icon="cancel"
        @click="$emit('click')"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/views/Button';
export default {
  name: 'WarningBlock2green',
  components: { Button },
  props: {
    text: { type: String, default: '' },
    canCancel: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/vars.scss';

.warningblock--selectedtemplate {
  & .rightzone_block {
    margin-bottom: 0;
  }

  .message-text1 {
    font-size: $fontsizeStandart;
    line-height: $lineFontsizeStandart;
    font-weight: $fontNormal;
    color: $colorTextAccent;
  }

  .message-text2 {
    font-size: $fontsizeStandart;
    line-height: $lineFontsizeStandart;
    font-weight: $fontBold;
    color: $colorTextAccent;
  }
}

@media screen and (max-width: 1800px) {
  .warningblock--selectedtemplate {
    & .rightzone_block {
      margin-bottom: 0;
    }

    .message-text1 {
      font-size: $fontsizeStandart-k1600;
      line-height: $lineFontsizeStandart-k1600;
    }

    .message-text2 {
      font-size: $fontsizeStandart-k1600;
      line-height: $lineFontsizeStandart-k1600;
    }
  }
}

@media screen and (max-width: 1420px) {
  .warningblock--selectedtemplate {
    & .rightzone_block {
      margin-bottom: 0;
    }

    .message-text1 {
      font-size: $fontsizeStandart-k1360;
      line-height: $lineFontsizeStandart-k1360;
    }

    .message-text2 {
      font-size: $fontsizeStandart-k1360;
      line-height: $lineFontsizeStandart-k1360;
    }
  }
}
</style>
