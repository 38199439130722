<template>
  <article>
    <div class="cwrap cwrap--phase-program marginbottom--micro">
      <ProgramPhase v-for="phase in phases" :phase="phase" :key="phase.id" />
    </div>

    <ProgramTables v-if="phases.length && hasArea" />
  </article>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import ProgramPhase from './ProgramPhase';
import ProgramTables from './ProgramTables';

export default {
  name: 'DefenseProgram',
  components: {
    ProgramPhase,
    ProgramTables,
  },
  computed: {
    ...mapGetters(['programsCanSave']),
    ...mapState({
      isLoading: (state) => state.programs.templateFetching,
      phases: (state) => state.programs.programPhases,
      hasArea: (state) => state.programs.program.place_area > 0,
    }),
  },
};
</script>
