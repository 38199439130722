<!--Translated-->
<template>
  <div class="cwrap">
    <div class="chead el--borders-top el--borders-sides el--rounded-top">
      <div class="chead__line-block">
        <div
          class="vsvg vsvg--standart chead_icon"
          v-svg="{ name: 'edit' }"
        ></div>
        <div class="chead_text">
          {{ $t('UserDefenceProgramDescription:Header') }}
        </div>
      </div>
    </div>

    <div
      class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
    >
      <div class="cblock__internal">
        <div class="cblock__line">
          <div class="cblock__line-block">
            <Input
              type="text"
              :placeholder="
                $t('UserDefenceProgramDescription:Name.Placeholder')
              "
              :label="$t('UserDefenceProgramDescription:Name.Label')"
              size="long"
              v-model="programName"
              :state="disabled ? 'disabled' : ''"
            />
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <Input
              type="text"
              :placeholder="
                $t('UserDefenceProgramDescription:Comment.Placeholder')
              "
              :label="$t('UserDefenceProgramDescription:Comment.Label')"
              size="long4x"
              v-model="programDescription"
              :state="disabled ? 'disabled' : ''"
            />
          </div>
        </div>

        <div class="cblock__line" v-if="clientPlaces.length > 0">
          <div class="cblock__line-block margintop--micro">
            <Button
              :text="$t('UserDefenceProgramDescription:ButtonSelect')"
              size="standart2x"
              color="accent"
              icon="map"
              @click="showModalChoosePlace = true"
              :disabled="disabled"
            />
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <LocationSelect
              :geoEntries="geoMapped"
              :freeSelect="true"
              v-model="geoId"
              :disabled="disabled"
              :locked="templateFetching"
            />
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <Input
              type="text"
              :placeholder="
                $t('UserDefenceProgramDescription:Zone.Placeholder')
              "
              :label="$t('UserDefenceProgramDescription:Zone.Label')"
              size="long"
              v-model="zoneName"
              :state="disabled ? 'disabled' : ''"
            />

            <InputNumber
              :placeholder="
                $t('UserDefenceProgramDescription:Area.Placeholder')
              "
              :label="$t('UserDefenceProgramDescription:Area.Label')"
              size="standart"
              v-model="placeArea"
              :state="disabled ? 'disabled' : ''"
            />

            <Input
              type="text"
              placeholder="0"
              :label="$t('UserDefenceProgramDescription:Discount')"
              :state="disabled ? 'disabled' : 'disabled2'"
              size="standart"
              :value="clientDiscount"
            />
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <CultureSelect
              :categories="cultureCategories"
              :cultures="cultures"
              v-model="cultureId"
              :disabled="disabled"
              :locked="templateFetching"
            />
            <CultureAgeSelect
              v-model="age"
              :disabled="disabled"
              :locked="templateFetching || selectedOneYearCulture"
            />
            <CalcMethodSelect
              v-model="calcMethod"
              :disabled="disabled"
              :locked="templateFetching"
            />
          </div>
        </div>

        <div class="cblock__line" v-if="sorts.length">
          <div class="cblock__line-block">
            <ChksetSort
              :options="sorts"
              v-model="selectedSorts"
              :label="$t('UserDefenceProgramDescription:Sort')"
              :disabled="isLoading || isSaving || templateFetching"
            />
          </div>
        </div>
      </div>
    </div>

    <ModalChoosePlace
      v-if="showModalChoosePlace"
      v-model="showModalChoosePlace"
      :clientPlaces="clientPlaces"
      :clientCultures="clientCultures"
      :clientZones="clientZones"
      :cultures="culturesRaw"
      @close="showModalChoosePlace = false"
      @selected="clientPlaceSelected"
      :showClientLink="false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { staticData } from '@/store/modules/clients';
import {
  mutationTypes,
  actionTypes,
} from '@/store/modules/clientsArea/programs';
import { mapInputEntityWithMerge } from '@/components/adapters/mapInput';
import routeNames from '@/router/routeNames';

import Input from '@/components/views/Input';
import InputNumber from '@/components/views/wrappers/InputNumberWithCast';
import Button from '@/components/views/Button';
import ChksetSort from '@/components/sets/ChksetSorts';
import LocationSelect from '@/components/views/LocationSelect';
import ModalChoosePlace from '@/components/modals/programs/ModalChoosePlace';
import CultureSelect from '@/components/views/CultureSelect';
import CultureAgeSelect from '@/components/views/CultureAgeSelect';
import CalcMethodSelect from '@/components/views/CalcMethodSelect';

export default {
  name: 'UserDefenceProgramDescription',
  components: {
    Input,
    InputNumber,
    Button,
    ChksetSort,
    LocationSelect,
    ModalChoosePlace,
    CultureSelect,
    CultureAgeSelect,
    CalcMethodSelect,
  },
  data() {
    return {
      clientsFilter: '',
      clientsFiltered: [],
      selectedClient: null,
      ignoreNextReset: false,
      showModalChoosePlace: false,
      skipNextQueryWatcher: false,
    };
  },
  computed: {
    ...mapGetters(['geoMapped', 'geoEntities']),
    ...mapState({
      isLoading: (state) => state.userDefence.isLoading,
      isSaving: (state) => state.userDefence.isSaving,
      hasPhases: (state) => Boolean(state.userDefence.programPhases.length),
      program: (state) => state.userDefence.program,
      clientPlaces: (state) => state.userDefence.clientPlaces,
      clientCultures: (state) => state.userDefence.clientCultures,
      clientZones: (state) => state.userDefence.clientPlacesZones,
      culturesRaw: (state) => state.userDefence.cultures,
      templateFetching: (state) => state.userDefence.templateFetching,
      templatesSearching: (state) => state.userDefence.templatesSearching,
      client: (state) => state.userDefence.client,
      cultureCategories: (state) =>
        state.userDefence.categoriesCultures.map(mapInputEntityWithMerge),
      cultures: (state) =>
        state.userDefence.cultures.map(mapInputEntityWithMerge),
      templatesQuery: ({ userDefence }) => {
        const keys = [
          'culture_id',
          'culture_age',
          'calculation_method_id',
          'geo_id',
        ];
        return keys.some((key) => userDefence.program[key] === null)
          ? null
          : Object.fromEntries(
              keys.map((key) => [key, userDefence.program[key]])
            );
      },
      templatesQuerySerialized() {
        return JSON.stringify(this.templatesQuery);
      },
      sorts(state) {
        return state.userDefence.culturesSorts
          .filter((sort) => sort.culture_id === this.cultureId)
          .map((sort) => ({
            ...sort,
            text: sort.name,
            value: sort.id,
          }));
      },
      selectedSortsGet: (state) =>
        state.userDefence.programCulturesSorts.map((s) => s.sort_id),
    }),
    selectedOneYearCulture() {
      return Boolean(
        this.culturesRaw.find((c) => c.id === this.cultureId)?.only_one_year
      );
    },
    clientDiscount: {
      get() {
        return this.program.client_discount;
      },
      set(value) {
        this.$store.commit(mutationTypes.setClientDiscount, value);
      },
    },
    selectedSorts: {
      get() {
        return this.selectedSortsGet;
      },
      set(value) {
        this.$store.commit(mutationTypes.setCultureSorts, value);
      },
    },
    programName: {
      get() {
        return this.program.name;
      },
      set(name) {
        this.$store.commit(mutationTypes.setProgramName, name);
      },
    },
    programDescription: {
      get() {
        return this.program.description;
      },
      set(value) {
        this.$store.commit(mutationTypes.setProgramDescription, value);
      },
    },
    clientName: {
      get() {
        return this.program.client_name;
      },
      set(name) {
        this.$store.commit(mutationTypes.setClientName, name);
      },
    },
    clientCode: {
      get() {
        return this.program.client_code;
      },
      set(code) {
        this.$store.commit(mutationTypes.setClientCode, code);
      },
    },
    clientTypes() {
      return staticData.clientType.map((text, value) => ({ text, value }));
    },
    clientType: {
      get() {
        return this.program.client_type;
      },
      set(type) {
        this.$store.commit(mutationTypes.setClientType, type);
      },
    },
    geoId: {
      get() {
        return this.program.geo_id;
      },
      set(id) {
        this.$store.commit(mutationTypes.setProgramGeo, id);
      },
    },
    zoneName: {
      get() {
        return this.program.zone_name;
      },
      set(name) {
        this.$store.commit(mutationTypes.setZoneName, name);
      },
    },
    placeArea: {
      get() {
        return this.program.place_area;
      },
      set(area) {
        this.$store.commit(mutationTypes.setPlaceArea, area);
      },
    },
    cultureId: {
      get() {
        return this.program.culture_id;
      },
      set(id) {
        this.$store.commit(mutationTypes.setCultureId, id);
      },
    },
    age: {
      get() {
        return this.program.culture_age ?? null;
      },
      set(age) {
        this.$store.commit(mutationTypes.setAge, age);
      },
    },
    calcMethod: {
      get() {
        return this.program.calculation_method_id;
      },
      set(value) {
        this.$store.commit(
          mutationTypes.setCalculationMethod,
          value ? Number(value) : value
        );
      },
    },
    disabled() {
      return this.isLoading || this.isSaving || this.templateFetching;
    },
    isEditing() {
      return this.$route.name === routeNames.userDefenceProgramEdit;
    },
  },
  methods: {
    clientPlaceSelected(place) {
      this.$store.commit(mutationTypes.setProgramGeo, place.geoId);
      this.$store.commit(mutationTypes.setZoneName, place.zone?.name ?? '');
      this.$store.commit(mutationTypes.setCultureId, place.cultureId);
      this.$store.commit(mutationTypes.setPlaceArea, place.area ?? null);
      this.$store.commit(mutationTypes.setAge, place.age ?? null);
    },
    fetchTemplates(query) {
      if (query) {
        // if (this.$route.name === routeNames.userDefenceProgramEdit) return;
        const isCountry = this.geoEntities
          .map((e) => e.data.id)
          .includes(this.geoId);
        if (!isCountry)
          this.$store.dispatch(actionTypes.templatesSearch, query);
      } else {
        this.$store.commit(mutationTypes.templatesSearchSuccess, []);
      }
    },
  },
  watch: {
    templatesQuerySerialized(query, oldQuery) {
      // console.log({ query, oldQuery });
      if (this.skipNextQueryWatcher) {
        this.skipNextQueryWatcher = false;
        return;
      }
      if (this.hasPhases) {
        this.$confirm({
          message: this.$t(
            'UserDefenceProgramDescription:ModalChangePhase:Message'
          ),
          buttons: {
            left: {
              text: this.$t(
                'UserDefenceProgramDescription:ModalChangePhase:ButtonLeft'
              ),
              icon: 'delete',
              color: 'alertlines',
            },
            right: {
              text: this.$t(
                'UserDefenceProgramDescription:ModalChangePhase:ButtonRight'
              ),
              icon: 'cancel',
              color: 'graylines',
            },
          },
          callback: (confirm) => {
            if (confirm) {
              this.$store.commit(mutationTypes.selectTemplate, null);
              this.fetchTemplates(JSON.parse(query));
            } else {
              this.skipNextQueryWatcher = true;
              const oldValues = JSON.parse(oldQuery);
              if (oldValues) {
                this.$store.commit(
                  mutationTypes.setProgramGeo,
                  oldValues.geo_id
                );
                this.$store.commit(mutationTypes.setAge, oldValues.culture_age);
                this.$store.commit(
                  mutationTypes.setCalculationMethod,
                  oldValues.calculation_method_id
                );
                this.$store.commit(
                  mutationTypes.setCultureId,
                  oldValues.culture_id
                );
              }
            }
          },
        });
      } else {
        this.fetchTemplates(JSON.parse(query));
      }
    },
    selectedOneYearCulture: {
      handler(val) {
        if (val) this.age = 1;
      },
      immediate: true,
    },
    // templatesQuery: {
    //   handler(query) {
    //     if (query) {
    //       if (this.$route.name === routeNames.userDefenceProgramEdit) return;
    //       const isCountry = this.geoEntities
    //         .map((e) => e.data.id)
    //         .includes(this.geoId);
    //       if (!isCountry)
    //         this.$store.dispatch(actionTypes.templatesSearch, query);
    //     } else {
    //       this.$store.commit(mutationTypes.templatesSearchSuccess, []);
    //     }
    //   },
    //   immediate: true,
    // },
  },
};
</script>
