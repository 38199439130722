var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal"},[_c('div',{staticClass:"modalwrap2-topline"},[_c('div',{staticClass:"topline-minizone"}),_c('Breadcrumps',{attrs:{"data":[{ title: _vm.$t('UserModalChooseAnalyze:Title') }]}}),_c('div',{staticClass:"topline-minizone"},[_c('Button',{attrs:{"text":"","size":"s-standart","color":"accentlightlines","icon":"x","is-loading":false,"loading-left":true},on:{"click":_vm.onModalClose}})],1)],1),_c('div',{staticClass:"modalwrap2-content"},[_c('div',{staticClass:"modalwrap3-inputs margintop--standart marginbottom--standart"},[_c('div',{staticClass:"marginbottom--micro"},[_c('Button',{attrs:{"text":_vm.$t('UserAnalyzes:TableData:AnalyseType:1'),"size":"max","color":"accentlightlines","icon":"analyzesicon_water"},on:{"click":function($event){return _vm.$router.push({
              name: 'UserAnalysesInstruction',
              params: { id: '1' },
            })}}})],1),_c('div',{staticClass:"marginbottom--micro"},[_c('Button',{attrs:{"text":_vm.$t('UserAnalyzes:TableData:AnalyseType:0'),"size":"max","color":"accentlightlines","icon":"analyzesicon_product"},on:{"click":function($event){return _vm.$router.push({
              name: 'UserAnalysesInstruction',
              params: { id: '0' },
            })}}})],1),_c('div',{staticClass:"marginbottom--micro"},[_c('Button',{attrs:{"text":_vm.$t('UserAnalyzes:TableData:AnalyseType:2'),"size":"max","color":"accentlightlines","icon":"analyzesicon_leaves"},on:{"click":function($event){return _vm.$router.push({
              name: 'UserAnalysesInstruction',
              params: { id: '2' },
            })}}})],1),_c('div',{},[_c('Button',{attrs:{"text":_vm.$t('UserAnalyzes:TableData:AnalyseType:3'),"size":"max","color":"accentlightlines","icon":"analyzesicon_soil"},on:{"click":function($event){return _vm.$router.push({
              name: 'UserAnalysesInstruction',
              params: { id: '3' },
            })}}})],1)]),_c('div',{staticClass:"modalwrap3-buttons"},[_c('Button',{attrs:{"text":_vm.$t('UserModalChooseAnalyze:ButtonUndo'),"size":"max","color":"graylines","icon":"cancel","is-loading":false,"loading-left":true},on:{"click":_vm.onModalClose}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }