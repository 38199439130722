import axios from '@/api/axios';

const getCategoriesCultures = () => {
  return axios.get('/glossary/getCategoriesCultures');
};
const updateCategoriesCultures = (payload) => {
  return axios.post('/glossary/updateCategoriesCultures', {
    categoryData: payload,
  });
};

const getTypesStimulation = () => {
  return axios.get('/glossary/getTypesStimulation');
};
const updateTypeStimulation = (payload) => {
  return axios.post('/glossary/updateTypeStimulation', {
    typeStimulationData: payload,
  });
};

const getCultures = () => {
  return axios.get('/glossary/getCultures');
};
const getNewCultureData = () => {
  return axios.get('/glossary/getNewCultureData');
};
const updateCulture = (payload) => {
  return axios.post('/glossary/updateCulture', { cultureData: payload });
};
const getEditCultureData = (payload) => {
  return axios.get('/glossary/getEditCultureData', { params: { id: payload } });
};

const getPhases = () => {
  return axios.get('/glossary/getPhases');
};

const getPests = () => {
  return axios.get('/glossary/getPests');
};

const getGeobase = (timestamp = 0) =>
  axios
    .get('/glossary/getGeoBase', { params: { geoBase: timestamp } })
    .then((r) => r.data.geoBase);
const updateGeobase = (geoBaseData) =>
  axios.post('/glossary/updateGeoBase', { geoBaseData }).then((r) => r.data);

const getNewPhasesData = () =>
  axios.get('/glossary/getNewPhasesData').then((r) => r.data);
const getEditPhaseData = (id) =>
  axios
    .get('/glossary/getEditPhaseData', { params: { id } })
    .then(({ data }) => {
      ['images', 'phasePests', 'phaseTypesStimulation'].forEach(
        (key) => (data[key] = data[key] ?? [])
      );
      return data;
    });
const updatePhase = (payload, files) => {
  const formData = new FormData();
  files.forEach((file) => formData.append('images', file));
  formData.append('phaseData', JSON.stringify(payload));
  return axios
    .post('/glossary/updatePhase', formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((r) => r.data);
};

const getNewPestData = () =>
  axios.get('glossary/getNewPestsData').then((r) => r.data);
const getEditPestData = (id) =>
  axios
    .get('/glossary/getEditPestsData', { params: { id } })
    .then(({ data }) => {
      data.culturePests ??= [];
      data.images ??= [];
      data.pest.description ??= '';
      data.pest.link ??= '';
      return data;
    });
const updatePest = (payload, files) => {
  const formData = new FormData();
  files.forEach((file) => formData.append('images', file));
  formData.append('pestData', JSON.stringify(payload));
  return axios
    .post('/glossary/updatePest', formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((r) => r.data);
};

export default {
  getCategoriesCultures,
  updateCategoriesCultures,
  getTypesStimulation,
  updateTypeStimulation,
  getCultures,
  updateCulture,
  getNewCultureData,
  getEditCultureData,
  getGeobase,
  updateGeobase,
  getNewPhasesData,
  getEditPhaseData,
  updatePhase,
  getNewPestData,
  getEditPestData,
  updatePest,
  getPhases,
  getPests,
};
