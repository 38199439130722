<!--Translated-->
<template>
  <div class="cwrap cwrap--level1 marginbottom--standart">
    <div
      class="chead el--borders-top el--borders-sides el--rounded-top"
      style="z-index: 1"
    >
      <div class="chead__line-block">
        <div
          class="vsvg vsvg--standart chead_icon"
          v-svg="{ name: 'agriculture' }"
        ></div>
        <div class="chead_text">{{ $t('ClientAddCulture:HeaderText') }}</div>
      </div>
    </div>

    <div class="cblock el--borders-all el--rounded-bottom">
      <div class="cblock__infoblock cblock__infoblock--nocontentyet">
        <p v-if="client2HasEmptyCultures">
          {{ $t('ClientAddCulture:Info1') }}
        </p>
        <p v-else>{{ $t('ClientAddCulture:Info2') }}</p>
        <Button
          :text="$t('ClientAddCulture:ButtonAddCulture')"
          size="wider"
          color="accent"
          icon="plus"
          @click="addCulture"
          :disabled="isLoading || isSubmiting"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mutationTypes } from '@/store/modules/clientsArea/clients';
import Button from '@/components/views/Button';

export default {
  name: 'Client2AddCulture',
  components: { Button },
  computed: {
    ...mapState({
      isLoading: (state) => state.clients2.isLoading,
      isSubmiting: (state) => state.clients2.isSubmiting,
    }),
    ...mapGetters(['client2HasEmptyCultures']),
  },
  methods: {
    addCulture() {
      this.$store.commit(mutationTypes.addBlankCulture);
    },
  },
};
</script>
