<!-- Translated -->
<template>
  <div class="cwrap cwrap--level1 marginbottom--standart">
    <div class="chead el--borders-top el--borders-sides el--rounded-top">
      <div class="chead__line-block">
        <div
          class="vsvg vsvg--standart chead_icon"
          v-svg="{ name: 'agriculture' }"
        ></div>
        <div class="chead_text">{{ $t('GlossaryLinkedCultures:Title') }}</div>
      </div>
    </div>
    <div
      class="cblock el--borders-all el--rounded-bottom"
      v-if="selectedCultures.length === 0"
    >
      <div class="cblock__infoblock cblock__infoblock--nocontentyet">
        <slot name="description" />
        <Button
          v-if="!readonly"
          :text="
            canAdd
              ? $t('GlossaryLinkedCultures:ButtonAdd')
              : $t('GlossaryLinkedCultures:ButtonFull')
          "
          :title="canAdd ? '' : $t('GlossaryLinkedCultures:ButtonFullTitle')"
          size="wider"
          color="accent"
          icon="plus"
          :disabled="!canAdd"
          @click="add"
        />
      </div>
    </div>

    <div class="cblock el--borders-sides" v-if="selectedCultures.length > 9">
      <div class="cblock__internal">
        <div class="cblock__line">
          <div class="cblock__line-block">
            <DropdownSelect
              :options="inputCategories"
              v-model="selectedCategory"
              :placeholder="$t('GlossaryLinkedCultures:Category.Placeholder')"
              :label="$t('GlossaryLinkedCultures:Category.Label')"
              :disabled="isLoading"
            />
            <Input
              type="text"
              :placeholder="$t('GlossaryLinkedCultures:Name.Placeholder')"
              :label="$t('GlossaryLinkedCultures:Name.Label')"
              size="long"
              v-model="cultureName"
              :state="isLoading ? 'disabled' : ''"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="cblock el--rounded-bottom" v-if="selectedCultures.length">
      <Table
        :headers="tableHeaders"
        :data-arr="dataArr"
        :table-loading="isLoading"
        :loading-count-items="3"
        @editClick="edit"
      >
        <Button
          v-if="!readonly"
          :text="$t('GlossaryLinkedCultures:ButtonAdd')"
          size="wider"
          color="accentlines"
          icon="plus"
          :is-loading="false"
          :loading-left="false"
          @click="add"
          :disabled="!canAdd || isLoading || isSaving"
        />
      </Table>
    </div>

    <AddCultureModal
      v-if="showModal"
      :cultures="cultures"
      :inputCategories="inputCategories"
      :selectedCultures="selectedCultures"
      :editingId="editingCultureId"
      @close="showModal = false"
      @save="newCulture"
      @remove="removeCulture"
      @edit="swapCultures"
    />
  </div>
</template>

<script>
import Input from '@/components/views/Input';
import Button from '@/components/views/Button';
import Table from '@/components/tables/Table';
import DropdownSelect from '@/components/views/wrappers/DropdownSelect';
import AddCultureModal from './GlossaryLinkedCulturesModal';

export default {
  name: 'GlossaryLinkedCultures',
  components: {
    Input,
    Button,
    Table,
    AddCultureModal,
    DropdownSelect,
  },
  model: { prop: 'selectedCultures' },
  props: {
    categories: { type: Array, default: () => [] },
    cultures: { type: Array, default: () => [] },
    selectedCultures: { type: Array, default: () => [] },
    isLoading: { type: Boolean, default: false },
    isSaving: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      showModal: false,
      editingCultureId: null,
      selectedCategory: null,
      cultureName: '',
    };
  },
  computed: {
    tableHeaders() {
      const headers = [
        {
          text: this.$t('GlossaryLinkedCultures:TableHeaders:0'), //'Категория',
          class: 'col-culturesort',
          width: '30%',
          type: 'text',
        },
        {
          text: this.$t('GlossaryLinkedCultures:TableHeaders:1'), //'Культура',
          class: 'col-culturesort',
          width: '70%',
          type: 'text',
        },
      ];

      if (!this.readonly) {
        headers.push({
          text: '',
          class: 'col-edit',
          width: '',
          type: 'edit',
          icon: 'edit',
        });
      }

      return headers;
    },
    inputCategories() {
      return this.categories.map((e) => ({
        value: e.id,
        text: e.name,
      }));
    },
    culturesById() {
      const entries = this.cultures.map((culture) => [
        culture.id,
        {
          ...culture,
          category: this.categories.find(
            ({ id }) => id === culture.category_id
          ),
        },
      ]);
      return Object.fromEntries(entries);
    },
    dataArr() {
      const filterByCategory = (e) =>
        this.selectedCategory
          ? e.category.id === this.selectedCategory.value
          : true;
      const filterByName = (e) =>
        e.name.toLowerCase().includes(this.cultureName.toLowerCase());

      return this.selectedCultures
        .map(({ culture_id }) => this.culturesById[culture_id])
        .filter(filterByCategory)
        .filter(filterByName)
        .map((culture) => [
          { text: culture.category.name },
          { text: culture.name },
          { text: culture.id },
        ]);
    },
    canAdd() {
      return this.cultures.length > this.selectedCultures.length;
    },
  },
  methods: {
    add() {
      this.editingCultureId = null;
      this.showModal = true;
    },
    edit(id) {
      this.editingCultureId = id;
      this.showModal = true;
    },
    newCulture(id) {
      this.$emit('input', [...this.selectedCultures, { culture_id: id }]);
    },
    removeCulture(id) {
      this.$emit(
        'input',
        this.selectedCultures.filter(({ culture_id }) => culture_id !== id)
      );
    },
    swapCultures(oldId, newId) {
      this.$emit('input', [
        ...this.selectedCultures.filter(
          ({ culture_id }) => culture_id !== oldId
        ),
        { culture_id: newId },
      ]);
    },
  },
};
</script>
