<!--Translated-->
<template>
  <div class="cwrap cwrap--level1 marginbottom--standart">
    <div
      class="chead el--borders-top el--borders-sides el--rounded-top"
      style="z-index: 1"
    >
      <div class="chead__line-block">
        <div
          class="vsvg vsvg--standart chead_icon"
          v-svg="{ name: 'agriculture' }"
        ></div>
        <div class="chead_text">{{ $t('ClientAddCulture:HeaderText') }}</div>
      </div>
    </div>

    <div class="cblock el--borders-all el--rounded-bottom">
      <div class="cblock__infoblock cblock__infoblock--nocontentyet">
        <p v-if="clientHasEmptyCultures">
          {{ $t('ClientAddCulture:Info1') }}
        </p>
        <p v-else>{{ $t('ClientAddCulture:Info2') }}</p>
        <Button
          :text="$t('ClientAddCulture:ButtonAddCulture')"
          size="wider"
          color="accent"
          icon="plus"
          @click="addCulture"
          :disabled="disabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mutationTypes } from '@/store/modules/clients';
import Button from '@/components/views/Button';

export default {
  name: 'ClientAddCulture',
  components: { Button },
  props: { isEditing: { type: Boolean, required: true } },
  computed: {
    ...mapState({
      isLoading: (state) => state.clients.isLoading,
      isSearching: (state) => state.clients.isSearching,
      usedSearch(state) {
        return this.isEditing || state.clients.usedSearch;
      },
    }),
    ...mapGetters(['clientHasEmptyCultures']),
    disabled() {
      return (
        this.isLoading ||
        this.isSearching ||
        this.clientHasEmptyCultures ||
        !this.usedSearch
      );
    },
  },
  methods: {
    addCulture() {
      this.$store.commit(mutationTypes.addBlankCulture);
    },
  },
};
</script>
