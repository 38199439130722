<template>
  <article class="userfooter">
    <div class="sidemenu-logo">
      <img alt="logo" src="@/assets/sidemenu/sidemenu_logo.svg" />
    </div>
    <p>Copyright© 2022 Cartlis Agrosystems</p>
    <div class="userfooter_icons">
      <a href="http://www.cartlis.ge/" target="_blank">
        <div class="vsvg" v-svg="{ name: 'contactwebsite' }"></div>
      </a>
      <a href="https://www.youtube.com/user/cartlis" target="_blank">
        <div class="vsvg" v-svg="{ name: 'contactyoutube' }"></div>
      </a>
      <a href="https://www.linkedin.com/company/cartlis/" target="_blank">
        <div class="vsvg" v-svg="{ name: 'contactlinked' }"></div>
      </a>
      <a href="https://www.facebook.com/cartlis/" target="_blank">
        <div class="vsvg" v-svg="{ name: 'contactfacebook' }"></div>
      </a>
      <a href="https://www.instagram.com/cartlisi/" target="_blank">
        <div class="vsvg" v-svg="{ name: 'contactinstagram' }"></div>
      </a>
    </div>
  </article>
</template>

<script>
export default {
  name: 'UserFooter',
};
</script>

<style lang="scss" scoped>
@import '~@/scss/vars.scss';

.userfooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $marginStandart 0;
  border-top: 1px solid $colorLinesAccentLight;
  margin-top: $heightStandart;

  & p {
    padding-top: $marginNano2;
    font-size: $fontsizeStandart;
    line-height: $lineFontsizeStandart;
    text-align: center;
    color: $colorGray;
  }

  & .sidemenu-logo {
    max-height: $heightStandart;
    width: 360px;

    & img {
      max-height: $heightStandart;
      transform: translateY(-$borderButton) translateX($borderButton * 2);
      //margin-bottom: 12px;
    }
  }

  & .userfooter_icons {
    display: flex;

    & > a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      background-color: $colorLinesGrayLight;//$colorAccent;
      border-radius: 50%;
      margin-left: 24px;

      & > div {
        width: $iconSizeStandart;
        height: $iconSizeStandart;

        & svg {
          width: inherit;
          height: inherit;
        }
        & svg * {
          fill: $colorGray;
        }
      }

      &:hover{
        background-color: $colorAccent;

        & > div svg * {
            fill: $colorWhite
        }
      }
    }
  }
}

@media screen and (max-width: 1800px) {
  .userfooter {
    padding: $marginStandart-k1600 0;
    margin-top: $heightStandart-k1600;

    & p {
      font-size: $fontsizeStandart-k1600;
      line-height: $lineFontsizeStandart-k1600;
      padding-top: $marginNano2-k1600;
    }

    & .sidemenu-logo {
      max-height: $heightStandart-k1600;
      width: 360px * $k1600;

      & img {
        max-height: $heightStandart-k1600;
        transform: translateY(-$borderButton-k1600) translateX($borderButton-k1600 * 2);
      }
    }

    & .userfooter_icons {

      & > a {
        width: 48px * $k1600;
        height: 48px * $k1600;
        margin-left: 24px * $k1600;

        & > div {
          width: $iconSizeStandart-k1600;
          height: $iconSizeStandart-k1600;
        }
      }
    }
  }
}

@media screen and (max-width: 1420px) {
  .userfooter {
    padding: $marginStandart-k1360 0;
    margin-top: $heightStandart-k1360;

    & p {
      font-size: $fontsizeStandart-k1360;
      line-height: $lineFontsizeStandart-k1360;
      padding-top: $marginNano2-k1360;
    }

    & .sidemenu-logo {
      max-height: $heightStandart-k1360;
      width: 360px * $k1360;

      & img {
        max-height: $heightStandart-k1360;
        transform: translateY(-$borderButton-k1360) translateX($borderButton-k1360 * 2);
      }
    }

    & .userfooter_icons {

      & > a {
        width: 48px * $k1360;
        height: 48px * $k1360;
        margin-left: 24px * $k1360;

        & > div {
          width: $iconSizeStandart-k1360;
          height: $iconSizeStandart-k1360;
        }
      }
    }
  }
}

@media screen and (max-width: 1219px) {
}
</style>
