<-- Translated -->
<template>
  <!--ДОБАВИТЬ КОНТАКТ-->
  <div class="modal modal--long">
    <div class="modalwrap2-topline">
      <div class="topline-minizone"></div>

      <Breadcrumps :data="[{ title: $t('ModalContactAdd:Title') }]" />

      <div class="topline-minizone">
        <Button
          text=""
          size="s-standart"
          color="accentlightlines"
          icon="x"
          :is-loading="false"
          :loading-left="true"
          @click="$emit('input', false)"
        />
      </div>
    </div>

    <div class="modalwrap2-content">
      <div class="modalwrap3-inputs margintop--standart marginbottom--standart">
        <Input
          type="dropdown"
          :placeholder="$t('ModalContactAdd:ContactType.Placeholder')"
          :label="$t('ModalContactAdd:ContactType.Label')"
          icon=""
          state=""
          size=""
          color=""
          :dd-items="contactTypesDDItems"
          @selected="contactType = $event.item.value"
          dd-max-elements="6"
        />
        <Input
          type="text"
          :placeholder="$t('ModalContactAdd:Value.Placeholder')"
          :label="$t('ModalContactAdd:Value.Label')"
          icon=""
          state=""
          size="long"
          color=""
          v-model="contactValue"
        />
      </div>
      <div class="modalwrap3-inputs marginbottom--standart">
        <Input
          type="text"
          :placeholder="$t('ModalContactAdd:Comment.Placeholder')"
          :label="$t('ModalContactAdd:Comment.Label')"
          icon=""
          state=""
          size="max"
          color=""
          v-model="contactComment"
        />
      </div>

      <div class="modalwrap3-buttons">
        <Button
          :text="$t('ModalContactAdd:ButtonCancel')"
          size="wide"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="true"
          @click="$emit('input', false)"
        />
        <Button
          :text="$t('ModalContactAdd:ButtonSave')"
          size="wide"
          color="accent"
          icon="save"
          :is-loading="false"
          :loading-left="true"
          :disabled="!contactValue"
          @click="onSaveClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/views/Input';
import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';

export default {
  name: 'ModalAddContact',
  components: { Input, Button, Breadcrumps },
  data() {
    return {
      contactType: 0,
      contactValue: '',
      contactComment: '',
    };
  },
  computed: {
    contactTypesDDItems() {
      let arr = [];
      if (this.$store.state.admin.contactTypes) {
        this.$store.state.admin.contactTypes.forEach((item) => {
          arr.push({
            text: item.name || item.name_ru,
            value: item.id,
            icon: item.icon,
          });
        });
      }
      return arr;
    },
  },
  methods: {
    onSaveClick() {
      this.$emit('addContact', {
        contact_type_id: this.contactType,
        value: this.contactValue,
        comment: this.contactComment,
      });
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
