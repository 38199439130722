<template>
  <div class="cwrap cwrap--level1 marginbottom--standart">
    <ClientTableHeader
      :title="title"
      iconName="fertilizer"
      :isShown="isShown"
      @click="isShown = !isShown"
    />

    <div class="cblock el--rounded-bottom" v-show="isShown">
      <Table
        class="cblock__internal-table"
        :headers="headers"
        :dataArr="rows"
        :tableLoading="isLoading"
        :loadingCountItems="10"
        @editClick="editProgram"
        @printClick="printProgram"
      >
      </Table>
      <Button
        class="margintop--standart"
        :text="$t('ClientNutritionProgramsTable:ButtonNewText')"
        size="wide"
        color="accentlines"
        icon="plus"
        @click="createProgram"
        :disabled="!clientId"
      />
    </div>
    <PrintModal
      v-if="selectedPrintId"
      @close="selectedPrintId = null"
      :selectedPrintId="selectedPrintId"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import routeNames from '@/router/routeNames';

import Button from '@/components/views/Button';
import Table from '@/components/tables/Table';
import ClientTableHeader from './ClientTableHeader';
import PrintModal from '@/components/modals/ModalPrint';

export default {
  name: 'ClientNutritionProgramsTable',
  components: { Button, Table, ClientTableHeader, PrintModal },
  data() {
    return { isShown: false, selectedPrintId: null };
  },
  computed: {
    ...mapGetters(['geoPlainDataAsMap']),
    ...mapState({
      isLoading: (state) => state.clients.isLoading,
      canEdit: (state) =>
        state.auth.currentUserRole?.claim_plants_nutrition_add === 1,
      programs: (state) => state.clients.clientPlantsNutritionPrograms ?? [],
      cultures: (state) => state.clients.cultures,
      clientId: (state) => state.clients.client?.id ?? null,
      clientName: (state) => state.clients.client?.name ?? '',
    }),
    headers() {
      return [
        {
          text: this.$t('FertilizerPrograms:TableHeaders:1'), //'Название',
          type: 'text',
          width: '44%',
        },
        {
          text: this.$t('FertilizerPrograms:TableHeaders:2'), //'Культура',
          type: 'text',
          width: '13%',
        },
        {
          text: this.$t('FertilizerPrograms:TableHeaders:3'), //'Возраст',
          type: 'text',
          width: '10%',
        },
        {
          text: this.$t('FertilizerPrograms:TableHeaders:4'), //'Место',
          type: 'text',
          width: '13%',
        },
        {
          text: this.$t('FertilizerPrograms:TableHeaders:5'), //'Площадь',
          type: 'text',
          width: '10%',
        },
        {
          text: this.$t('FertilizerPrograms:TableHeaders:6'), //'Начало',
          type: 'text',
          width: '9%',
        },
        {
          class: 'col-edit unicol-colordark',
          type: 'print',
          icon: 'print',
        },
        {
          class: 'col-edit',
          type: 'edit',
          icon: this.canEdit ? 'edit' : 'passwordshow',
        },
      ];
    },
    rows() {
      return this.programs.map((p) =>
        [
          p.name,
          this.cultures.find(({ id }) => p.culture_id === id)?.name ?? '',
          p.culture_age,
          this.geoPlainDataAsMap.get(p.geo_id)?.name,
          p.place_area,
          p.period_start
            ? new Date(p.period_start).toLocaleDateString('ru-RU', {
                month: 'numeric',
                day: 'numeric',
              })
            : null,
          p.id,
          p.id,
        ].map((text) => ({ text }))
      );
    },
    title() {
      return `${this.clientName} - ${this.$t(
        'ClientNutritionProgramsTable:Title'
      )} (${this.programs.length})`;
    },
  },
  methods: {
    editProgram(id) {
      const routeData = this.$router.resolve({
        name: routeNames.editFertilizerProgram,
        params: { id },
      });
      window.open(routeData.href, '_blank');
    },
    printProgram(id) {
      this.selectedPrintId = id;
    },
    createProgram() {
      const routeData = this.$router.resolve({
        name: routeNames.newFertilizerProgram,
        query: { forClient: this.clientId },
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
