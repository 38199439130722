<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps
        :data="[
          { title: $t('GlossaryBadObjectsEdit:Title1'), route: routeBack },
          {
            title: isEditing
              ? $t('GlossaryBadObjectsEdit:Title2')
              : $t('GlossaryBadObjectsEdit:Title3'),
          },
        ]"
      />
      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('GlossaryBadObjectsEdit:ButtonCancel')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          :disabled="isUpdating"
          @click="$router.push({ name: routeBack })"
        />
        <Button
          :text="$t('GlossaryBadObjectsEdit:ButtonSave')"
          size="standart"
          color="accent"
          icon="save"
          :isLoading="isUpdating"
          :loading-left="isUpdating"
          :disabled="!canSubmit || !wasEdited || !canEdit"
          @click="submit(routeBack)"
        />
      </div>
    </div>

    <div class="cwrap cwrap--level1 marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'edit' }"
          ></div>
          <div class="chead_text">
            {{ $t('GlossaryBadObjectsEdit:HeaderText') }}
          </div>
        </div>
      </div>

      <div class="cblock el--borders-all el--rounded-bottom">
        <div class="cblock__internal">
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                :placeholder="$t('GlossaryBadObjectsEdit:Name.Placeholder')"
                :label="$t('GlossaryBadObjectsEdit:Name.Label')"
                size="long"
                v-model.trim="pestData.name"
                :state="inputsState"
              />
              <DropdownSelect
                :placeholder="$t('GlossaryBadObjectsEdit:Category.Placeholder')"
                :label="$t('GlossaryBadObjectsEdit:Category.Label')"
                dd-max-elements="7"
                :options="inputCategories"
                v-model="selectedCategory"
                :disabled="inputDisabled"
                :locked="!canEdit"
              />

              <!--<RadioToggle
                :text1="$t('GlossaryBadObjectsEdit:Active:Hide')"
                :text2="$t('GlossaryBadObjectsEdit:Active:Active')"
                :label="$t('GlossaryBadObjectsEdit:Active:Label')"
                :reverse="true"
                v-model="pestData.is_active"
                :disabled="inputDisabled"
                :locked="!canEdit"
              />-->
            </div>
          </div>

          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="textarea2x"
                :placeholder="
                  $t('GlossaryBadObjectsEdit:Description.Placeholder')
                "
                :label="$t('GlossaryBadObjectsEdit:Description.Label')"
                size="long"
                v-model="pestData.description"
                :state="inputsState"
              />
            </div>
          </div>

          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                :placeholder="$t('GlossaryBadObjectsEdit:Link.Placeholder')"
                :label="$t('GlossaryBadObjectsEdit:Link.Label')"
                size="long"
                v-model="pestData.link"
                :state="inputsState"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cwrap cwrap--level1 marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'photo' }"
          ></div>
          <div class="chead_text">
            {{ $t('GlossaryBadObjectsEdit:Link.Image') }}
          </div>
        </div>
      </div>
      <ImagesSelect
        v-model="images"
        :cultures="selectedCultures"
        :disabled="inputDisabled"
        :readonly="!canEdit"
      />
    </div>

    <LinkedCultures
      :categories="staticData.categoryCultures"
      :cultures="staticData.cultures"
      :isLoading="isLoading"
      :isSaving="isUpdating"
      :readonly="!canEdit"
      v-model="culturePests"
    />

    <Button
      v-if="canEdit"
      :text="$t('GlossaryBadObjectsEdit:ButtonSave')"
      size="standart"
      color="accent"
      icon="save"
      :isLoading="isUpdating"
      :loading-left="isUpdating"
      :disabled="!canSubmit || !wasEdited"
      @click="submit(routeBack)"
    />

    <Modal
      v-if="showModalNotSaved"
      @backgroundClose="showModalNotSaved = false"
    >
      <ModalNotSaved
        v-model="showModalNotSaved"
        @confirm="onModalNotSavedConfirm"
      />
    </Modal>
  </article>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import api from '@/api/glossary';
import routeNames from '@/router/routeNames';
import { mapState } from 'vuex';
import { mapServerImage } from '@/components/adapters/mapImage';
import { mapInputEntityUnary } from '@/components/adapters/mapInput';

import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import DropdownSelect from '@/components/views/wrappers/DropdownSelect';
// import RadioToggle from '@/components/views/RadioToggle';
import Breadcrumps from '@/components/views/Breadcrumps';
import ImagesSelect from '@/components/views/ImagesSelectMeta';
import LinkedCultures from './GlossaryLinkedCultures.vue';
import Modal from '@/pages/Modal';
import ModalNotSaved from '@/components/modals/modalNotSaved';

export default {
  name: 'GlossaryBadObjectsEdit',
  components: {
    Breadcrumps,
    // RadioToggle,
    Input,
    Button,
    DropdownSelect,
    ImagesSelect,
    LinkedCultures,
    Modal,
    ModalNotSaved,
  },
  data() {
    const defaultPest = {
      category_id: null,
      name: '',
      description: '',
      link: '',
      is_active: 1,
    };

    return {
      isLoading: true,
      isUpdating: false,
      selectedCategory: null,
      images: [],
      culturePests: [],
      pestData: {
        ...defaultPest,
      },
      staticData: {
        categoryCultures: [],
        cultures: [],
      },
      serverData: {
        pest: { ...defaultPest },
        images: [],
        culturePests: [],
      },
      routeBack: routeNames.badObjects,
      routeNew: routeNames.newPest,
      showModalNotSaved: false,
      nextRouteConfirm: false,
      nextRoute: null,
    };
  },
  computed: {
    ...mapState({
      inputCategories: (state) =>
        state.glossaryPests.categoriesPests.map(mapInputEntityUnary),
      canEdit: (state) => state.auth.currentUserRole?.claim_glossary_edit === 1,
    }),
    inputsState() {
      return this.inputDisabled ? 'disabled' : this.canEdit ? '' : 'disabled2';
    },
    isEditing() {
      return this.$route.name === routeNames.editPest;
    },
    isFormValid() {
      const pest = this.pestData;
      return Boolean(pest.name && pest.category_id);
    },
    wasEdited() {
      const formEdited = !isEqual(this.serverData.pest, this.pestData);
      const imagesEdited =
        this.images.some(({ type }) => type === 'new') ||
        !isEqual(this.images, this.serverData.images);
      console.log('why', isEqual(this.images, this.serverData.images));
      const culturesEdited = !isEqual(
        [...this.serverData.culturePests].sort(),
        [...this.culturePests].sort()
      );

      return formEdited || imagesEdited || culturesEdited;
    },
    inputDisabled() {
      return this.isLoading || this.isUpdating;
    },
    canSubmit() {
      return !this.inputDisabled && this.isFormValid;
    },
    selectedCultures() {
      return this.staticData.cultures
        .filter(({ id }) =>
          this.culturePests.some(({ culture_id }) => culture_id === id)
        )
        .map(mapInputEntityUnary);
    },
  },
  created() {
    window.scrollTo(0, 0);
    const apiCall = this.isEditing
      ? api.getEditPestData(this.$route.params.id)
      : api.getNewPestData();

    apiCall
      .then((data) => {
        if (data.images) {
          data.images.sort((a, b) => a.meta_id - b.meta_id);
          data.images = data.images.map(mapServerImage);
        }

        for (const obj of [this.staticData, this.serverData])
          for (const key of Object.keys(obj))
            if (data[key]) obj[key] = data[key];

        if (this.isEditing) {
          this.pestData = { ...data.pest };
          this.images = cloneDeep(data.images);
          this.culturePests = cloneDeep(data.culturePests);
        }
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    async submit(nextRoute) {
      if (this.isUpdating) return;
      const images = this.images.map((img, i) => ({
        name: img.name,
        id: img.id,
        meta_id: img.meta_id ?? null,
        is_main: i === 0 ? 1 : 0,
      }));
      const payload = {
        pest: this.pestData,
        culturePests: this.culturePests,
        images,
      };

      this.isUpdating = true;
      api
        .updatePest(payload, this.images.map((img) => img.file).filter(Boolean))
        .finally(() => {
          this.isUpdating = false;
          if (nextRoute) {
            this.nextRouteConfirm = true;
            this.$router.push({ name: nextRoute });
          } else window.location.reload();
        });
    },
    onModalNotSavedConfirm() {
      if (this.nextRoute) {
        this.nextRouteConfirm = true;
        this.$router.push(this.nextRoute);
      }
    },
  },
  watch: {
    $route() {
      this.$router.go();
    },
    selectedCategory(entry) {
      this.pestData.category_id = entry?.value ?? null;
    },
    'pestData.category_id': {
      handler(id) {
        if (id) {
          this.selectedCategory = this.inputCategories.find(
            ({ value }) => value === id
          );
        }
      },
      immediate: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.nextRouteConfirm || !this.wasEdited || !this.canEdit) {
      next();
    } else {
      this.showModalNotSaved = true;
      this.nextRoute = to;
      next(false);
    }
  },
};
</script>
