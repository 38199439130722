<!-- Translated -->
<template>
  <TableUserContacts
    :contactTypes="contactTypes"
    :isLoading="disabled || !usedSearch"
    :readonly="readonly"
    v-model="contacts"
  />
</template>

<script>
import { mapState } from 'vuex';
import { mutationTypes } from '@/store/modules/clients';
import TableUserContacts from '@/components/tables/TableUserContacts';

export default {
  name: 'ClientContacts',
  components: { TableUserContacts },
  props: {
    isEditing: { type: Boolean, required: true },
    readonly: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      disabled: (state) => state.clients.isLoading || state.clients.isSearching,
      contactTypes: (state) => state.clients.contactTypes,
      usedSearch(state) {
        return this.isEditing || state.clients.usedSearch;
      },
    }),
    contacts: {
      get() {
        return this.$store.state.clients.clientContacts;
      },
      set(value) {
        this.$store.commit(mutationTypes.updateContacts, value);
      },
    },
  },
};
</script>
