<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps
        :data="[
          { title: $t('GlossaryPhasesEdit:Title1'), route: routeBack },
          {
            title: isEditing
              ? $t('GlossaryPhasesEdit:Title2')
              : $t('GlossaryPhasesEdit:Title3'),
          },
        ]"
      />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('GlossaryPhasesEdit:ButtonCancel')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          :disabled="isUpdating"
          @click="$router.push({ name: routeBack })"
        />

        <Button
          :text="$t('GlossaryPhasesEdit:ButtonSave')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="false"
          :isLoading="isUpdating"
          :loading-left="isUpdating"
          @click="submit(routeBack)"
          :disabled="!canSubmit || !wasEdited || !canEdit"
        />
      </div>
    </div>

    <div class="cwrap cwrap--level1 marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'edit' }"
          ></div>
          <div class="chead_text">
            {{ $t('GlossaryPhasesEdit:HeaderDescription') }}
          </div>
        </div>
      </div>
      <div
        class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                size="long"
                :placeholder="$t('GlossaryPhasesEdit:Name.Placeholder')"
                :label="$t('GlossaryPhasesEdit:Name.Label')"
                v-model="phaseData.name"
                :state="inputsState"
              />

              <!-- <RadioToggle
                type="disabled"
                :text1="$t('GlossaryPhasesEdit:Active:Hide')"
                :text2="$t('GlossaryPhasesEdit:Active:Active')"
                :label="$t('GlossaryPhasesEdit:Active:Label')"
                :reverse="true"
                :disabled="inputDisabled"
                :locked="inputDisabled ? false : !canEdit"
                v-model="phaseData.is_active"
              /> -->
            </div>
          </div>

          <div class="cblock__line">
            <div class="cblock__line-block">
              <DropdownSelect
                :placeholder="$t('GlossaryPhasesEdit:Category.Placeholder')"
                :label="$t('GlossaryPhasesEdit:Category.Label')"
                v-model="selectedCategoryCulture"
                :options="inputCategoryCultures"
                :disabled="inputDisabled"
                :locked="!canEdit"
              />
              <DropdownSelect
                :placeholder="$t('GlossaryPhasesEdit:Culture.Placeholder')"
                :label="$t('GlossaryPhasesEdit:Culture.Label')"
                v-model="selectedCulture"
                :options="inputCultures"
                :disabled="inputDisabled || !selectedCategoryCulture"
                :locked="!canEdit"
              />

              <InputInteger
                size="mini"
                :placeholder="$t('GlossaryPhasesEdit:Volume.Placeholder')"
                :label="$t('GlossaryPhasesEdit:Volume.Label')"
                v-model="phaseData.volume_per_ha"
                :disabled="inputDisabled"
                :locked="!canEdit"
              />
            </div>
          </div>

          <div class="cblock__line">
            <div class="cblock__line-block">
              <InputDate
                class="inpt-dates inpt-dates--noyear1"
                :placeholder="$t('GlossaryPhasesEdit:Date.Placeholder')"
                :label-text="$t('GlossaryPhasesEdit:Date.Start')"
                :day="phaseData.start_day"
                :month="phaseData.start_month"
                @input="phaseStartSelected"
                :disabled="inputDisabled"
                :locked="!canEdit"
              />
              <InputDate
                :placeholder="$t('GlossaryPhasesEdit:Date.Placeholder')"
                :label-text="$t('GlossaryPhasesEdit:Date.End')"
                :day="phaseData.end_day"
                :month="phaseData.end_month"
                @input="phaseEndSelected"
                :disabled="inputDisabled"
                :locked="!canEdit"
              />
            </div>
          </div>

          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="textarea2x"
                :placeholder="$t('GlossaryPhasesEdit:Description.Placeholder')"
                :label="$t('GlossaryPhasesEdit:Description.Label')"
                size="long"
                v-model="phaseData.description"
                :state="inputsState"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cwrap cwrap--level1 marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'photo' }"
          ></div>
          <div class="chead_text">
            {{ $t('GlossaryPhasesEdit:HeaderImage') }}
          </div>
        </div>
      </div>
      <ImagesSelect
        v-model="images"
        :disabled="inputDisabled"
        :readonly="!canEdit"
      />
    </div>

    <div class="cwrap cwrap--level1 marginbottom--standart">
      <LinkedPests
        :isLoading="isLoading || isUpdating"
        :pests="pestsOfCulture"
        v-model="phasePests"
        :multi-mode="true"
        :readonly="!canEdit"
      >
        <template v-slot:description>
          <p v-if="!pestsOfCulture.length">
            {{ $t('GlossaryPhasesEdit:NoPests') }}
          </p>
          <p v-else>{{ $t('GlossaryPhasesEdit:AddPestsText') }}</p>
        </template>
      </LinkedPests>
    </div>

    <div class="cwrap cwrap--level1 marginbottom--standart">
      <LinkedItems
        :isLoading="isLoading || isUpdating"
        :items="inputStimulations"
        v-model="selectedStimulationsIds"
        :addButtonText="$t('GlossaryPhasesEdit:TypeStim.AddText')"
        :placeholder="$t('GlossaryPhasesEdit:TypeStim.Placeholder')"
        :readonly="!canEdit"
      >
        <template v-slot:header>
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'pesticideother' }"
          />
          <div class="chead_text">
            {{ $t('GlossaryPhasesEdit:HeaderTypes') }}
          </div>
        </template>
        <template v-slot:description>
          <p>{{ $t('GlossaryPhasesEdit:AddTypesText') }}</p>
        </template>
      </LinkedItems>
    </div>

    <!--БЛОК УДАЛИТЬ / ИНФО-->
    <div class="cwrap marginbottom--standart" v-if="!isLoading && isEditing">
      <!--ЗАГОЛОВОК-->
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'delete' }"
          ></div>
          <div class="chead_text">
            {{ $t('GlossaryPhasesEdit:DeleteHeader') }}
          </div>
        </div>
      </div>

      <!--ОПИСАНИЕ-->
      <div class="cblock el--borders-all el--rounded-bottom">
        <div class="cblock__infoblock">
          <p>
            {{ $t('GlossaryPhasesEdit:DeleteText') }}
          </p>

          <Button
            :text="$t('GlossaryPhasesEdit:ButtonDelete')"
            size="standart"
            color="alertlines"
            icon="userdelete"
            :is-loading="isUpdating"
            :loading-left="false"
            @click="onDeleteClick"
          />
        </div>
      </div>
    </div>

    <div style="display: flex" v-if="canEdit">
      <Button
        :text="$t('GlossaryPhasesEdit:ButtonSave2')"
        color="accent"
        icon="save"
        :is-loading="isUpdating"
        :loading-left="isUpdating"
        :disabled="!canSubmit || !wasEdited"
        @click="submit(routeNew)"
      />
      <Button
        style="margin-left: 20px"
        :text="$t('GlossaryPhasesEdit:ButtonSave3')"
        size="standart"
        color="accent"
        icon="save"
        :is-loading="false"
        :isLoading="isUpdating"
        :loading-left="isUpdating"
        @click="submit(routeBack)"
        :disabled="!canSubmit || !wasEdited"
      />
    </div>

    <Modal
      v-if="showModalNotSaved"
      @backgroundClose="showModalNotSaved = false"
    >
      <ModalNotSaved
        v-model="showModalNotSaved"
        @confirm="onModalNotSavedConfirm"
      />
    </Modal>
  </article>
</template>

<script>
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import api from '@/api/glossary';
import routeNames from '@/router/routeNames';
import { mapServerImage } from '@/components/adapters/mapImage';
import { mapInputEntityUnary } from '@/components/adapters/mapInput';
import { mapState } from 'vuex';

import Input from '@/components/views/Input';
import DropdownSelect from '@/components/views/wrappers/DropdownSelect';
import InputInteger from '@/components/views/wrappers/InputInteger';
import Button from '@/components/views/Button';
// import RadioToggle from '@/components/views/RadioToggle';
import Breadcrumps from '@/components/views/Breadcrumps';
import InputDate from '@/components/views/DayMonthSelect';
import ImagesSelect from '@/components/views/ImagesSelect';
import LinkedPests from './GlossaryLinkedPests.vue';
import LinkedItems from './GlossaryLinkedItems';
import Modal from '@/pages/Modal';
import ModalNotSaved from '@/components/modals/modalNotSaved';

export default {
  name: 'GlossaryPhasesEdit',
  components: {
    InputDate,
    Breadcrumps,
    // RadioToggle,
    Button,
    Input,
    ImagesSelect,
    DropdownSelect,
    InputInteger,
    LinkedPests,
    LinkedItems,
    Modal,
    ModalNotSaved,
  },
  data() {
    const defaultPhase = {
      culture_id: null,
      name: '',
      volume_per_ha: null,
      description: '',
      start_day: null,
      start_month: null,
      end_day: null,
      end_month: null,
      is_active: 1,
    };

    return {
      isLoading: true,
      isUpdating: false,
      selectedCategoryCulture: null,
      selectedCulture: null,
      images: [],
      phasePests: [],
      phaseTypesStimulation: [],
      phaseData: {
        ...defaultPhase,
      },
      serverData: {
        phase: { ...defaultPhase },
        images: [],
        phasePests: [],
        phaseTypesStimulation: [],
      },
      staticData: {
        categoryCultures: [],
        cultures: [],
        pests: [],
        culturePests: [],
        typesStimulation: [],
      },
      routeBack: routeNames.phases,
      routeNew: routeNames.newPhase,
      showModalNotSaved: false,
      nextRouteConfirm: null,
      nextRoute: null,
    };
  },
  created() {
    window.scrollTo(0, 0);
    const apiCall = this.isEditing
      ? api.getEditPhaseData(this.$route.params.id)
      : api.getNewPhasesData();

    apiCall
      .then((data) => {
        if (data.images) data.images = data.images.map(mapServerImage);

        for (const obj of [this.staticData, this.serverData])
          for (const key of Object.keys(obj))
            if (data[key]) obj[key] = data[key];

        if (this.isEditing) {
          this.phaseData = { ...data.phase };
          this.images = cloneDeep(data.images);
          this.phasePests = cloneDeep(data.phasePests);
          this.phaseTypesStimulation = cloneDeep(data.phaseTypesStimulation);
        } else if (this.$route.query.starting_culture) {
          const id = Number(this.$route.query.starting_culture);
          this.$router.replace({ query: undefined });
          this.phaseData.culture_id = id;
        }
      })
      .finally(() => (this.isLoading = false));
  },
  computed: {
    ...mapState({
      canEdit: (state) => state.auth.currentUserRole?.claim_glossary_edit === 1,
    }),
    inputsState() {
      if (this.isLoading) return 'disabled';
      if (!this.canEdit) return 'disabled2';
      return this.inputDisabled ? 'disabled' : '';
    },
    isEditing() {
      return this.$route.name === routeNames.editPhase;
    },
    isFormValid() {
      const phase = this.phaseData;
      return Boolean(
        !this.isLoading &&
          phase.culture_id &&
          phase.name &&
          [
            phase.start_day,
            phase.end_day,
            phase.volume_per_ha,
            phase.start_month,
            phase.end_month,
            phase.is_active,
          ].every(Number.isInteger)
      );
    },
    wasEdited() {
      const formEdited = !isEqual(this.serverData.phase, this.phaseData);
      const imagesEdited =
        this.images.length !== this.serverData.images.length ||
        this.images.some((image) => image.type === 'new') ||
        this.images.find((img) => img.is_main)?.name !==
          this.serverData.images.find((img) => img.is_main)?.name;
      const pestsEdited = !isEqual(
        [...this.serverData.phasePests].sort(),
        [...this.phasePests].sort()
      );
      const stimulationsEdited = !isEqual(
        this.serverData.phaseTypesStimulation
          .map((e) => e.type_stimulation_id)
          .sort(),
        [...this.selectedStimulationsIds].sort()
      );

      return formEdited || imagesEdited || pestsEdited || stimulationsEdited;
    },
    inputDisabled() {
      return this.isLoading || this.isUpdating;
    },
    canSubmit() {
      return !this.inputDisabled && this.isFormValid;
    },
    inputCategoryCultures() {
      return this.staticData.categoryCultures.map(mapInputEntityUnary);
    },
    inputCultures() {
      return this.staticData.cultures
        .filter((e) => e.category_id === this.selectedCategoryCulture?.value)
        .map(mapInputEntityUnary);
    },
    pestsOfCulture() {
      const ids = this.staticData.culturePests
        .filter(({ culture_id }) => culture_id === this.phaseData.culture_id)
        .map((e) => e.pest_id);
      return this.staticData.pests.filter((pest) => ids.includes(pest.id));
    },
    inputStimulations() {
      return this.staticData.typesStimulation.map(mapInputEntityUnary);
    },
    selectedStimulationsIds: {
      get() {
        return this.phaseTypesStimulation.map((e) => e.type_stimulation_id);
      },
      set(val) {
        this.phaseTypesStimulation = val.map((id) => ({
          type_stimulation_id: id,
        }));
      },
    },
  },
  methods: {
    phaseStartSelected([day, month]) {
      this.phaseData.start_day = day;
      this.phaseData.start_month = month;
    },
    phaseEndSelected([day, month]) {
      this.phaseData.end_day = day;
      this.phaseData.end_month = month;
    },
    async submit(nextRoute) {
      if (this.isUpdating) return;
      const images = this.images.map((img) => ({
        name: img.name,
        is_main: img.is_main,
        id: img.id,
      }));
      const payload = {
        phase: this.phaseData,
        phasePests: this.phasePests,
        phaseTypesStimulation: this.phaseTypesStimulation,
        images,
      };

      // TODO убать
      payload.phaseTypesStimulation = payload.phaseTypesStimulation.map(
        (e) =>
          this.serverData.phaseTypesStimulation.find(
            (f) => f.type_stimulation_id === e.type_stimulation_id
          ) || e
      );
      //

      this.isUpdating = true;
      api
        .updatePhase(
          payload,
          this.images.map((img) => img.file).filter(Boolean)
        )
        .finally(() => {
          this.isUpdating = false;
          if (nextRoute) {
            this.nextRouteConfirm = true;
            const query =
              nextRoute === routeNames.newPhase
                ? { starting_culture: this.phaseData.culture_id }
                : undefined;
            this.$router.push({
              name: nextRoute,
              query,
            });
          } else {
            this.$router.go();
          }
        });
    },
    onModalNotSavedConfirm() {
      if (this.nextRoute) {
        this.nextRouteConfirm = true;
        this.$router.push(this.nextRoute);
      }
    },
    onDeleteClick() {
      this.$confirm({
        message:
          this.$t('GlossaryPhasesEdit:ModalDeleteText1') +
          '<br>' +
          this.$t('GlossaryPhasesEdit:ModalDeleteText2'),
        buttons: {
          left: {
            text: this.$t('GlossaryPhasesEdit:ModalDeleteButtonLeft'), //'Удалить',
          },
          right: {
            text: this.$t('GlossaryPhasesEdit:ModalDeleteButtonRight'), //'Отмена',
          },
        },
        callback: (confirm) => {
          if (confirm) {
            this.deletePhase();
          }
        },
      });
    },
    deletePhase() {
      this.phaseData.delete = true;
      this.submit(this.routeBack);
    },
  },
  watch: {
    $route(to, from) {
      if (!Object.keys(from.query).includes('starting_culture'))
        this.$router.go();
    },
    selectedCulture(entry) {
      this.phaseData.culture_id = entry?.value ?? null;
    },
    'phaseData.culture_id': {
      handler(id) {
        if (!id) return;
        const culture = this.staticData.cultures.find((c) => c.id === id);
        const category = this.staticData.categoryCultures.find(
          (cc) => cc.id === culture?.category_id
        );
        this.selectedCategoryCulture = this.inputCategoryCultures.find(
          ({ value }) => value === category?.id
        );
        this.selectedCulture = this.inputCultures.find(
          ({ value }) => value === culture?.id
        );
      },
      immediate: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.nextRouteConfirm || !this.wasEdited || !this.canEdit) {
      next();
    } else {
      this.showModalNotSaved = true;
      this.nextRoute = to;
      next(false);
    }
  },
};
</script>
