import axios from '@/api/axios';

export const getAnalyzes = (payload) => {
  return axios.get('/clientsArea/analyzes/getAnalyzes', {
    params: {
      geoBase: payload?.geoBase ?? undefined,
      analyzes: payload?.analyzes ?? undefined,
    },
  });
};
