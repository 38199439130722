<-- Translated -->
<template>
  <Modal @backgroundClose="$emit('close')">
    <div class="modal modal--long">
      <div class="modalwrap2-topline">
        <div class="topline-minizone"></div>
        <Breadcrumps
          :data="[
            {
              title: editingId
                ? $t('GlossaryLinkedCulturesModal:Title1')
                : $t('GlossaryLinkedCulturesModal:Title2'),
            },
          ]"
        />
        <div class="topline-minizone">
          <Button
            size="s-standart"
            color="accentlightlines"
            icon="x"
            :is-loading="false"
            :loading-left="true"
            @click="$emit('close')"
          />
        </div>
      </div>

      <div class="modalwrap2-content">
        <div
          class="modalwrap3-inputs margintop--standart marginbottom--standart"
        >
          <DropdownSelect
            :placeholder="
              $t('GlossaryLinkedCulturesModal:Category.Placeholder')
            "
            :label="$t('GlossaryLinkedCulturesModal:Category.Label')"
            v-model="selectedCategory"
            :options="inputCategories"
          />

          <DropdownPlusSelect
            :label="$t('GlossaryLinkedCulturesModal:Name.Placeholder')"
            :placeholder="$t('GlossaryLinkedCulturesModal:Name.Label')"
            size="long"
            :autofocus="true"
            v-model="selectedItem"
            :options="culturesOfSelectedCategory"
            dd-max-elements="5"
          />
        </div>

        <div class="modalwrap3-buttons">
          <Button
            v-if="editingId"
            :text="$t('GlossaryLinkedCulturesModal:ButtonDelete')"
            size="wide"
            color="alertlines"
            icon="delete"
            :is-loading="false"
            :loading-left="true"
            :disabled="!canDelete"
            @click="remove"
          />
          <Button
            :text="$t('GlossaryLinkedCulturesModal:ButtonCancel')"
            size="wide"
            color="graylines"
            icon="cancel"
            :is-loading="false"
            :loading-left="true"
            @click="$emit('close')"
          />
          <Button
            :text="$t('GlossaryLinkedCulturesModal:ButtonSave')"
            size="wide"
            color="accent"
            icon="save"
            :is-loading="false"
            :loading-left="true"
            :disabled="!canSave"
            @click="save"
          />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapInputEntityUnary } from '@/components/adapters/mapInput.js';

import Modal from '@/pages/Modal';
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import DropdownSelect from '@/components/views/wrappers/DropdownSelect';
import DropdownPlusSelect from '@/components/views/wrappers/DropdownPlusSelect';

export default {
  name: 'GlossaryLinkedCulturesModal',
  components: {
    Modal,
    Breadcrumps,
    Button,
    DropdownSelect,
    DropdownPlusSelect,
  },
  props: {
    editingId: { type: Number, default: null },
    cultures: { type: Array, default: () => [] },
    inputCategories: { type: Array, default: () => [] },
    selectedCultures: { type: Array, default: () => [] },
  },
  data() {
    return {
      selectedItem: null,
      selectedCategory: null,
    };
  },
  computed: {
    culturesOfSelectedCategory() {
      return this.cultures
        .filter((culture) =>
          this.selectedCategory
            ? culture.category_id === this.selectedCategory.value
            : true
        )
        .map(mapInputEntityUnary)
        .map((e) => ({
          ...e,
          exists: this.selectedCultures.some(
            ({ culture_id }) => culture_id === e.value
          ),
        }));
    },
    canSave() {
      return this.selectedItem !== null && !this.selectedItem.exists;
    },
    canDelete() {
      return this.editingId === this.selectedItem?.value;
    },
  },
  methods: {
    save() {
      if (this.editingId) {
        this.$emit('edit', this.editingId, this.selectedItem.value);
      } else {
        this.$emit('save', this.selectedItem.value);
      }
      this.$emit('close');
    },
    remove() {
      this.$emit('remove', this.editingId);
      this.$emit('close');
    },
  },
  created() {
    if (this.editingId) {
      const culture = this.cultures.find(({ id }) => id === this.editingId);
      this.selectedCategory = this.inputCategories.find(
        ({ value }) => value === culture.category_id
      );
      this.selectedItem = this.culturesOfSelectedCategory.find(
        ({ value }) => value == this.editingId
      );
    }
  },
};
</script>
