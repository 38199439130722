<-- Translated -->
<template>
  <div class="modalwrap1 modalwrap1--login">
    <div class="modalwrap2-logo">
      <img class="login_logo" src="@/assets/loginpage/login_logo.svg" />
    </div>

    <form class="modalwrap2-content" method="post" @submit.prevent="">
      <div class="modalwrap3-inputs">
        <Input
          class="marginbottom--mini"
          type="text"
          :placeholder="$t('Login:InputLogin')"
          label="-"
          icon="user"
          :state="loginState"
          size="max"
          color=""
          v-model="login"
          @click="onInputPress"
        />

        <Input
          class="marginbottom--mini"
          type="password"
          :placeholder="$t('Login:InputPassword')"
          label="-"
          icon="password"
          :state="passwordState"
          size="max"
          color=""
          v-model="password"
          @click="onInputPress"
        />

        <CheckBox
          class="this-checkbox-marginleft"
          :text="$t('Login:CheckSave')"
          v-model="rememberMe"
          :disabled="isFormLoading"
          color-checked="accentfill"
          color-un-checked="accent"
        />
      </div>

      <div class="modalwrap3-buttons">
        <Button
          class="margintop--mini"
          :text="$t('Login:ButtonEnter')"
          size="max"
          color="accent"
          icon="key"
          :is-loading="isFormLoading"
          :loading-left="true"
          @click="onLoginButtonPress"
        />
      </div>
    </form>
  </div>
</template>

<script>
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import CheckBox from '@/components/CheckBox';
import { actionTypes, mutationTypes } from '@/store/modules/auth';

export default {
  name: 'AppLogin',
  components: {
    CheckBox,
    Input,
    Button,
  },
  data() {
    return {
      login: '',
      password: '',
      rememberMe: true,
    };
  },
  computed: {
    loginState() {
      if (this.$store.state.auth.isLoginFormHasError) {
        return 'error';
      }
      if (this.$store.state.auth.isSubmitting) {
        return 'disabled';
      }
      return '';
    },
    passwordState() {
      if (this.$store.state.auth.isLoginFormHasError) {
        return 'error';
      }
      if (this.$store.state.auth.isSubmitting) {
        return 'disabled';
      }
      return '';
    },
    isFormLoading() {
      if (this.$store.state.auth.isSubmitting) {
        return true;
      }
      return false;
    },
  },
  methods: {
    onLoginButtonPress() {
      //console.log(`login: ${this.login}, password: ${this.password}`);
      this.$store
        .dispatch(actionTypes.login, {
          login: this.login,
          password: this.password,
          rememberMe: this.rememberMe,
        })
        .then(() => {
          //this.$router.push({name: 'globalFeed'})
        });
    },
    onInputPress() {
      this.$store.commit(mutationTypes.loginResetForm);
    },
  },
  watch: {
    /*login(){
      this.$store.commit(mutationTypes.loginResetForm);
    },
    password(){
      this.$store.commit(mutationTypes.loginResetForm);
    }*/
  },
};
</script>

<style lang="scss" scoped></style>
