<!--Translated-->
<template>
  <div class="inpt-datewrap inpt-datewrap--noyear">
    <DropdownSelectValue
      v-bind="defaults"
      :label="$t('MonthYearSelect:Date.Label')"
      :placeholder="$t('MonthYearSelect:Date.Placeholder')"
      :notSelectedText="$t('MonthYearSelect:Date.NotSelectedText')"
      :options="months"
      v-model="selected.month"
    />
    <DropdownSelectValue
      v-bind="defaults"
      :placeholder="$t('MonthYearSelect:Year.Placeholder')"
      :notSelectedText="$t('MonthYearSelect:Year.NotSelectedText')"
      :options="years"
      v-model="selected.year"
    />
  </div>
</template>

<script>
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';
import { ru, ka } from 'date-fns/locale';

export default {
  name: 'MonthYearSelect',
  props: {
    year: {
      default: null,
    },
    month: {
      default: null,
    },
    disabled: {
      default: false,
    },
    labelText: {
      default: '-',
    },
  },
  components: {
    DropdownSelectValue,
  },
  data() {
    return {
      selected: { month: null, year: null },
    };
  },
  computed: {
    defaults() {
      return {
        state: this.state,
        disabled: this.state === 'disabled',
        locked: this.locked,
        size: 'micro',
        isForDate: 'true',
        ddMaxElements: '5',
      };
    },
    months() {
      return [...Array(12)].map((_, m) => ({
        text:
          this.$i18n.locale === 'ru'
            ? ru.localize.month(m)
            : ka.localize.month(m),
        value: m,
      }));
    },
    years() {
      const currentYear = new Date().getFullYear();
      return [...Array(100)]
        .map((_, index) => currentYear - index)
        .map((year) => ({ text: String(year), value: year }));
    },
  },
  watch: {
    year: {
      handler(year) {
        this.selected = { ...this.selected, year };
      },
      immediate: true,
    },
    month: {
      handler(month) {
        this.selected = { ...this.selected, month };
      },
      immediate: true,
    },
    'selected.year'(year) {
      this.$emit('yearSelected', year);
    },
    'selected.month'(month) {
      this.$emit('monthSelected', month);
    },
  },
};
</script>
