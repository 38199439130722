<template>
  <div>
    <!--Добавить systempage&#45;&#45;bcg2-a для анимации опасити-->
    <article class="systempage systempage--bcg2">
      <div class="systempage_center">
        <slot> </slot>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'SystemPage',
};
</script>

<style lang="scss" scoped>
@import '~@/scss/vars.scss';

.systempage {
  width: 100%;
  min-height: 100vh;
  background-color: $colorWhite;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.systempage--bcg1 {
  background-image: url('~@/assets/loginpage/login_bcg.jpg');
}
.systempage--bcg2 {
  background-image: url('~@/assets/loginpage/login_bcg.jpg');
  background-color: rgba(255, 255, 255, 0.5);
  background-blend-mode: overlay;
}
.systempage--bcg2-a {
  animation-duration: 3s;
  animation-name: anim;
  animation-timing-function: ease-in-out;
  //animation-delay: 1s;
}

.systempage--bcg6 {
  background-image: url('~@/assets/loginpage/login_bcg.jpg');
  background-color: rgba(255, 255, 255, 0.5);
  background-blend-mode: overlay;
}


@keyframes anim {
  0% {
    background-color: rgba(255, 255, 255, 1);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.6);
  }
}

.systempage_center {
  display: flex;
  justify-content: center;
  align-items: center;

  background: $colorWhite;
  //backdrop-filter: blur(5px);

  border: 2px solid $colorLinesAccentLight;
  box-sizing: border-box;
  border-radius: $bordeRadiusBig;

  //min-height: 740px;
  filter: drop-shadow(0px 20px 60px rgba(0, 0, 0, 0.05));
  padding: $marginStandart;
  padding-bottom: $marginStandart * 2;
}
</style>
