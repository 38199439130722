<!--Translated-->
<template>
  <div class="cblock__line phase_dashline">
    <div class="cblock__line-block">
      <div
        class="phase_dashline-sqr phase_dashline-sqr--img el--borders-all el--rounded-all"
      >
        <img v-if="image" :src="image" />
        <div v-else v-svg="{ name: 'photo' }"></div>
      </div>

      <div
        class="phase_dashline-sqr phase_dashline-sqr--info el--borders-all el--rounded-all"
      >
        <div class="phase_dashline-sqr__ico">
          <div v-svg="{ name: 'phases_flower' }"></div>
        </div>
        <div class="phase_dashline-sqr__name">
          {{ $t('ProgramPhaseHeader:Name') }}
        </div>
        <div class="tbl_info phase_dashline-sqr__content">
          <div class="tbl_info-wrap phase_dashline-sqr--longtext">
            <span style="text-align: center"> {{ phase.name }} </span>
            <div class="tablepreview">
              <div class="tablepreview_textblock">
                <h4>{{ phase.name }}</h4>
                <p>{{ phase.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="phase_dashline-sqr phase_dashline-sqr--info el--borders-all el--rounded-all"
      >
        <div class="phase_dashline-sqr__ico">
          <div v-svg="{ name: 'phases_timetable' }"></div>
        </div>
        <div class="phase_dashline-sqr__name">
          {{ $t('ProgramPhaseHeader:Period') }}
        </div>
        <div class="phase_dashline-sqr__content">
          <div class="phase_dashline--longtext">
            <div>
              <div>
                <span style="overflow: hidden">
                  {{ $t('ProgramPhaseHeader:PhasePeriodFrom') }}
                </span>
                <span>{{ period.start }}</span>
              </div>
              <div>
                <span style="overflow: hidden">
                  {{ $t('ProgramPhaseHeader:PhasePeriodTo') }}
                </span>
                <span>{{ period.end }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="phase_dashline-sqr phase_dashline-sqr--info el--borders-all el--rounded-all"
      >
        <div class="phase_dashline-sqr__ico">
          <div v-svg="{ name: 'phases_flask' }"></div>
        </div>
        <div class="phase_dashline-sqr__name">
          {{ $t('ProgramPhaseHeader:Volume') }}
        </div>
        <div class="phase_dashline-sqr__content">
          <span v-if="!phase.is_temporary">
            <span>{{ phase.volume_per_ha }}</span>
          </span>
          <InputNumber
            v-else
            placeholder="0.0"
            label="-"
            size="micro"
            v-model="waterPhase"
            :state="isSaving ? 'disabled' : ''"
          />
        </div>
      </div>

      <div
        class="phase_dashline-sqr phase_dashline-sqr--info el--borders-all el--rounded-all"
      >
        <div class="phase_dashline-sqr__ico">
          <div v-svg="{ name: 'phases_money' }"></div>
        </div>
        <div class="phase_dashline-sqr__name">
          {{ $t('ProgramPhaseHeader:Price') }}
          <br />
          {{ calcMethodShort }}
          {{ isWater ? $t('ProgramTables:ListPests:Volume2') : '' }}
        </div>
        <div class="phase_dashline-sqr__content">
          <span>{{ price }}</span>
        </div>
      </div>
    </div>
    <div class="cblock__line-block cblock__line-block--vertical">
      <Button
        class="btn--chevrontogle"
        size="s-standart"
        :color="expanded ? 'accent' : 'accentlightlines'"
        :icon="expanded ? 'chevrondown' : 'chevronup'"
        :key="expanded"
        @click="$emit('toogle')"
      />
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { ka } from 'date-fns/locale';

import { mapState, mapGetters } from 'vuex';
import { serverUrl } from '@/api/axios.js';

import { mutationTypes } from '@/store/modules/clientsArea/programs';
import Button from '@/components/views/Button';
import InputNumber from '@/components/views/wrappers/InputNumberWithCast';
import { staticData } from '@/store/modules/plantsCare';

export default {
  name: 'UserProgramPhaseHeader',
  props: {
    phase: { type: Object, required: true },
    expanded: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  components: { Button, InputNumber },
  computed: {
    ...mapGetters(['udPricesMap']),
    ...mapState({
      isSaving: (state) => state.userDefence.isSaving,
      isWater: (state) => state.userDefence.program.calculation_method_id !== 3,
      calcMethod: (state) => state.programs.program.calculation_method_id,
    }),
    isWater() {
      return this.calcMethod < 3;
    },
    calcMethodShort() {
      return staticData.calculationMethodsShort[this.calcMethod];
    },
    image() {
      return this.phase.main_image
        ? `${serverUrl}${this.phase.main_image}`.replace(
            'uploads/',
            'uploads/thumbs/'
          )
        : null;
    },
    period() {
      const start = format(
        new Date(2020, this.phase.start_month - 1, this.phase.start_day),
        'd MMMM',
        { locale: ka }
      );
      const end = format(
        new Date(2020, this.phase.end_month - 1, this.phase.end_day),
        'd MMMM',
        { locale: ka }
      );
      return { start, end };
    },
    price() {
      return this.udPricesMap[this.phase.id]?.toFixed(2) || '0.00';
    },
    waterPhase: {
      get() {
        return this.phase.volume_per_ha;
      },
      set(value) {
        this.$store.commit(mutationTypes.setWaterPhase, {
          id: this.phase.id,
          value,
        });
      },
    },
  },
};
</script>
