<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('AdminAdwBanners:title') }]" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('AdminAdwBanners:ButtonAdd')"
          size="standart"
          color="accent"
          icon="plus"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: routeNewBanner })"
        />
      </div>
    </div>

    <div class="rightzone_block">
      <div class="filter">
        <div class="filter-inputszone">
          <div class="filterwrap filterwrap--adwbanners" style="display: flex">
            <div class="filterwrap_line">
              <LocationSelect
                :geoEntries="geoMapped"
                v-model="filters.geo"
                :freeSelect="true"
                :type="1"
                ref="location"
              />

              <!--Вид баннера-->
              <DropdownSelectValue
                :placeholder="$t('AdminAdwBanners:SelectType.Placeholder')"
                :label="$t('AdminAdwBanners:SelectType.Label')"
                size="standart"
                dd-max-elements="3"
                :options="[
                  { text: $t('AdminAdwBanners:SelectType.Value:0'), value: 0 },
                  { text: $t('AdminAdwBanners:SelectType.Value:1'), value: 1 },
                ]"
                v-model="filters.type"
              />
            </div>
          </div>
        </div>

        <div class="filter-clearbtnzone">
          <Button
            class="filter_buttonclear"
            text="очистить"
            size="s-standart"
            icon="backspace"
            :is-loading="false"
            :loading-left="true"
            @click="clearFilters"
            :color="
              filters.geo || filters.type !== null ? 'accentlines' : 'graylines'
            "
          />
        </div>
      </div>
    </div>

    <br />
    <div class="rightzone_table">
      <Table
        class="table-adminarticleinstructions"
        :headers="tableHeaders"
        :data-arr="dataArr"
        :table-loading="isLoading"
        :loading-count-items="10"
        @editClick="goToBanner"
      />
    </div>
  </article>
</template>

<script>
import { getBanners } from '@/api/admin';
import { mapGetters } from 'vuex';
import routeNames from '@/router/routeNames';

import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import Table from '@/components/tables/Table';
import LocationSelect from '@/components/views/LocationSelect';
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';
import { mutationTypes } from '@/store/modules/filtersState';

export default {
  name: 'AdminAdwBanners',
  components: {
    Button,
    Breadcrumps,
    Table,
    LocationSelect,
    DropdownSelectValue,
  },
  data() {
    return {
      routeNewBanner: routeNames.newAdminAdwBanner,
      isLoading: true,
      banners: [],
      filters: {
        geo: null,
        type: null,
      },
    };
  },
  computed: {
    ...mapGetters(['geoMapped', 'geoPlainDataAsMap']),
    tableHeaders() {
      return [
        {
          text: this.$t('AdminAdwBanners:TableHeaders:0'),
          type: 'text',
          width: '50%',
        },
        {
          text: this.$t('AdminAdwBanners:TableHeaders:1'),
          type: 'text',
        },
        {
          text: this.$t('AdminAdwBanners:TableHeaders:2'),
          type: 'text',
        },
        {
          text: this.$t('AdminAdwBanners:TableHeaders:3'),
          class: 'col-status',
          type: 'status',
        },
        {
          text: '',
          class: 'col-edit',
          type: 'edit',
          icon: 'edit',
        },
      ];
    },
    dataArr() {
      return this.banners
        .map((banner) => ({
          ...banner,
          geoChain:
            this.geoPlainDataAsMap
              .get(banner.geo_id)
              ?.order?.map((e) => e.id) ?? [],
        }))
        .filter(
          (banner) =>
            (this.filters.geo
              ? banner.geoChain.includes(this.filters.geo)
              : true) &&
            (this.filters.type === null
              ? true
              : this.filters.type === 0
              ? banner.geoChain.length === 1
              : banner.geoChain.length > 1)
        )
        .map((banner) =>
          [
            banner.name,
            this.geoPlainDataAsMap.get(banner.geo_id)?.name ??
              this.$t('AdminAdwBanners:GeoMap.AllCountries'),
            this.geoPlainDataAsMap.get(banner.geo_id)?.type
              ? this.$t('AdminAdwBanners:SelectType.Value:0') //'Для региона'
              : this.$t('AdminAdwBanners:SelectType.Value:1'), //'Общий',
            banner.is_active ? 'on' : 'off',
            banner.id,
          ].map((text) => ({
            text,
          }))
        );
    },
  },
  methods: {
    clearFilters() {
      this.filters.geo = null;
      this.filters.type = null;
      this.$refs.location.reset();
    },
    goToBanner(id) {
      this.$router.push({
        name: routeNames.editAdminAdwBanner,
        params: { id },
      });
    },
  },
  watch: {
    filters: {
      handler(val) {
        this.$store.commit(mutationTypes.setFilterBanners, { ...val });
      },
      deep: true,
    },
  },
  created() {
    getBanners()
      .then(({ banners }) => (this.banners = banners))
      .catch((err) => this.$awn.alert(String(err)))
      .finally(() => (this.isLoading = false));

    for (let filterKey in this.filters) {
      this.filters[filterKey] =
        this.$store.state.filtersState.filterBanners[filterKey];
    }
  },
};
</script>

<style lang="scss" scoped></style>
