<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('GlossaryTypeStimulations:Title') }]" />

      <div class="rightzone_top-buttonswrap">
        <Button
          v-if="isShowAddButton"
          :text="$t('GlossaryTypeStimulations:ButtonAdd')"
          size="standart"
          color="accent"
          icon="plus"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'NewTypeStimulation' })"
        />
      </div>
    </div>

    <div class="rightzone_table">
      <Table
        :headers="tableHeaders"
        :data-arr="dataArr"
        :table-loading="isTableLoading"
        :loading-count-items="8"
        @editClick="onEditClick"
      />
    </div>
  </article>
</template>

<script>
import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';
import routeNames from '@/router/routeNames';
import Table from '@/components/tables/Table';
import { mapState } from 'vuex';

export default {
  name: 'GlossaryTypeStimulations',
  components: {
    Breadcrumps,
    Button,
    Table,
  },
  methods: {
    onEditClick(typeId) {
      let type =
        this.$store.state.glossaryTypesStimulation.typesStimulationMapById.get(
          typeId
        );
      if (type) {
        this.$router.push({
          name: routeNames.editTypeStimulation,
          params: { id: type.id },
        });
      }
    },
  },
  computed: {
    ...mapState({
      canEdit: (state) => state.auth.currentUserRole?.claim_glossary_edit === 1,
    }),
    tableHeaders() {
      return [
        {
          text: this.$t('GlossaryTypeStimulations:Headers:0'), //'Название',
          class: 'col--accent',
          width: '20%',
          type: 'text',
        },
        {
          text: this.$t('GlossaryTypeStimulations:Headers:1'), //'Описание',
          class: 'col-roledescription',
          width: '80%',
          type: 'text',
        },
        // {
        //   text: this.$t('GlossaryTypeStimulations:Headers:2'), //'Статус',
        //   class: 'col-status',
        //   width: '',
        //   type: 'status',
        // },
        {
          text: '',
          class: 'col-edit',
          width: '',
          type: 'edit',
          icon: this.canEdit ? 'edit' : 'passwordshow',
        },
      ];
    },
    isTableLoading() {
      return this.$store.state.glossaryTypesStimulation
        .isTypesStimulationLoading;
    },
    dataArr() {
      let arr = [];

      if (this.$store.state.glossaryTypesStimulation.typesStimulation) {
        this.$store.state.glossaryTypesStimulation.typesStimulation.forEach(
          (typeStimulation) => {
            let tr = [];
            tr.push({ text: typeStimulation.name ? typeStimulation.name : '' });
            tr.push({
              text: typeStimulation.description
                ? typeStimulation.description
                : '',
            });
            // tr.push({ text: typeStimulation.is_active === 1 ? 'on' : 'off' });
            tr.push({ text: typeStimulation.id ? typeStimulation.id : '' });
            arr.push(tr);
          }
        );
      }
      return arr;
    },
    isShowAddButton() {
      return (
        this.$store.state.auth?.currentUserRole?.claim_glossary_edit || false
      );
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped></style>
