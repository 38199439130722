import axios from '@/api/axios';

export const getArticles = (payload) => {
  return axios.get('/articles/getArticles', {
    params: {
      articles: payload?.articles ?? undefined,
    },
  });
};
export const getEditArticleData = (payload) => {
  return axios.get('/articles/getEditArticleData', {
    params: {
      id: payload?.id,
    },
  });
};
export const updateArticle = (payload) => {
  return axios.post('/articles/updateArticle', payload);
};
export const deleteArticle = (payload) => {
  payload.delete = true;
  return axios.post('/articles/updateArticle', {
    article: payload,
  });
};

export default {
  getArticles,
  getEditArticleData,
  updateArticle,
  deleteArticle,
};
