<template>
  <div
    class="radiotogglewrap"
    :class="{
      'radiotogglewrap--active': isActive,
      'radiotogglewrap--disabled': disabled,
      'radiotogglewrap--onlyshow': disabled ? false : locked,
    }"
    :style="locked ? 'pointer-events:none' : ''"
    @click="activeD = true"
  >
    <label class="lable" v-if="!noLabel">
      {{ label }}
    </label>
    <fieldset
      class="inputradiotoggle"
      :class="{
        'inputradiotoggle--withblocked': isTypeBlocked,
        'inputradiotoggle--withdisabled': isTypeDisabled,
      }"
    >
      <template v-if="!reverse">
        <label class="radiotoggle">
          <input
            type="radio"
            :name="name"
            :checked="value == 0"
            @change="onChange(0, $event.returnValue)"
            :disabled="disabled"
          />
          <span>
            {{ text1 }}
          </span>
        </label>

        <label class="radiotoggle">
          <input
            type="radio"
            :name="name"
            :checked="value == 1"
            @change="onChange(1, $event.returnValue)"
            :disabled="disabled"
          />
          <span>
            {{ text2 }}
          </span>
        </label>
      </template>
      <template v-else>
        <label class="radiotoggle">
          <input
            type="radio"
            :name="name"
            :checked="value == 1"
            @change.self="onChange(1, $event.returnValue)"
            :disabled="disabled"
          />
          <span>
            {{ text2 }}
          </span>
        </label>

        <label class="radiotoggle">
          <input
            type="radio"
            :name="name"
            :checked="value == 0"
            @change="onChange(0, $event.returnValue)"
            :disabled="disabled"
          />
          <span>
            {{ text1 }}
          </span>
        </label>
      </template>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: 'RadioToggle',
  props: {
    name: {
      type: String,
      default: 'name',
    },
    type: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    text1: {
      type: String,
      default: '',
    },
    text2: {
      type: String,
      default: '',
    },
    value: {
      default: 0,
    },
    active: {
      default: false,
    },
    disabled: {
      default: false,
    },
    locked: {
      default: false,
    },
    reverse: {
      default: false,
    },
  },
  data() {
    return {
      activeD: this.active,
    };
  },
  methods: {
    onChange(el, val) {
      if (this.disabled) {
        return;
      }
      if (val) {
        this.$emit('input', el);
      }
    },
  },
  computed: {
    isTypeBlocked() {
      return this.type === 'blocked';
    },
    isTypeDisabled() {
      return this.type === 'disabled';
    },
    isActive() {
      return !!(this.activeD || this.active);
    },
  },
};
</script>
