<-- Translated -->
<template>
  <Modal @backgroundClose="$emit('close')">
    <div class="modal">
      <div class="modalwrap2-topline">
        <div class="topline-minizone"></div>
        <Breadcrumps
          :data="[
            {
              title: editingId
                ? this.$t('GlossaryLinkedPestsModalMulti:TitleEdit')
                : headerAdd ||
                  this.$t('GlossaryLinkedPestsModalMulti:TitleAdd'),
            },
          ]"
        />
        <div class="topline-minizone">
          <Button
            size="s-standart"
            color="accentlightlines"
            icon="x"
            :is-loading="false"
            :loading-left="true"
            @click="$emit('close')"
          />
        </div>
      </div>

      <div class="modalwrap2-content">
        <div class="modalwrap3-inputs margintop--standart flex-between">
          <DropdownSelect
            :placeholder="
              $t('GlossaryLinkedPestsModalMulti:Category.Placeholder')
            "
            :label="$t('GlossaryLinkedPestsModalMulti:Category.Label')"
            v-model="selectedCategory"
            :options="inputCategories"
            dd-max-elements="6"
          />

          <AppInput
            :label="$t('GlossaryLinkedPestsModalMulti:Name.Label')"
            type="text"
            :placeholder="$t('GlossaryLinkedPestsModalMulti:Name.Placeholder')"
            :autofocus="true"
            v-model="findText"
            size=""
          />
        </div>

        <div class="modalwrap3-inputs marginbottom--standart">
          <ChksetBadObjects
            :options="pestsOfAllFilter"
            v-model="tmpSelectedPests"
          />
        </div>

        <div class="modalwrap3-buttons">
          <Button
            v-if="editingId"
            :text="$t('GlossaryLinkedPestsModalMulti:ButtonDelete')"
            size="wide"
            color="alertlines"
            icon="delete"
            :is-loading="false"
            :loading-left="true"
            :disabled="!canDelete"
            @click="remove"
          />
          <Button
            :text="$t('GlossaryLinkedPestsModalMulti:ButtonCancel')"
            size="wide"
            color="graylines"
            icon="cancel"
            :is-loading="false"
            :loading-left="true"
            @click="$emit('close')"
          />
          <Button
            :text="$t('GlossaryLinkedPestsModalMulti:ButtonSave')"
            size="wide"
            color="accent"
            icon="save"
            :is-loading="false"
            :loading-left="true"
            :disabled="!canSave"
            @click="save"
          />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/pages/Modal';
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import DropdownSelect from '@/components/views/wrappers/DropdownSelect';
import DropdownPlusSelect from '@/components/views/wrappers/DropdownPlusSelect';
import ChksetBadObjects from '@/components/sets/ChksetBadObjects';
import AppInput from '@/components/views/Input';

const mapEntiny = (e) => ({
  value: e.id,
  text: e.name,
});

export default {
  components: {
    AppInput,
    ChksetBadObjects,
    Modal,
    Breadcrumps,
    Button,
    DropdownSelect,
    DropdownPlusSelect,
  },
  props: {
    editingId: { type: Number, default: null },
    pests: { type: Array, default: () => [] },
    inputCategories: { type: Array, default: () => [] },
    selectedPests: { type: Array, default: () => [] },
    headerAdd: { type: String },
  },
  data() {
    return {
      selectedItem: null,
      selectedCategory: null,
      tmpSelectedPests: [...this.selectedPests.map((v) => v.pest_id)],
      findText: '',
    };
  },
  computed: {
    pestsOfSelectedCategory() {
      return this.pests
        .filter((pest) =>
          this.selectedCategory
            ? pest.category_id === this.selectedCategory.value
            : true
        )
        .map(mapEntiny)
        .map((e) => ({
          ...e,
          selected: this.selectedPests.some(
            ({ pest_id }) => pest_id === e.value
          ),
        }));
    },
    pestsOfAllFilter() {
      return this.pests
        .filter((pest) =>
          this.selectedCategory
            ? pest.category_id === this.selectedCategory.value
            : true
        )
        .filter((pest) =>
          this.findText
            ? pest.name.toLowerCase().indexOf(this.findText.trim()) >= 0
            : true
        )
        .map(mapEntiny)
        .map((e) => ({
          ...e,
          selected: this.selectedPests.some(
            ({ pest_id }) => pest_id === e.value
          ),
        }));
    },
    canSave() {
      return !this.arraysEqual(
        this.tmpSelectedPests
          .map((v) => {
            v.value;
          })
          .sort(),
        this.selectedPests
          .map((v) => {
            v.value;
          })
          .sort()
      );
    },
    canDelete() {
      return this.editingId === this.selectedItem?.value;
    },
  },
  methods: {
    save() {
      if (this.editingId) {
        this.$emit('edit', this.editingId, this.selectedItem.value);
      } else {
        this.$emit('saveMulti', this.tmpSelectedPests);
      }
      this.$emit('close');
    },
    remove() {
      this.$emit('remove', this.editingId);
      this.$emit('close');
    },
    arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    },
  },
  created() {},
};
</script>
