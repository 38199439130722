<-- Translated -->
<template>
  <div
    class="cwrap cwrap--level1 marginbottom--standart chead__copy_paste_icons_parent"
  >
    <div class="chead el--borders-top el--borders-sides el--rounded-top">
      <div class="chead__line-block">
        <div
          class="vsvg vsvg--standart chead_icon"
          v-svg="{ name: 'elements' }"
        ></div>
        <div class="chead_text">
          {{ title }}
        </div>
      </div>
      <div
        class="chead__line-block chead__line-block--iconbuttons chead__copy_paste_icons_wrap"
      >
        <div
          class="vsvg vsvg--standart chead_icon"
          v-svg="{ name: 'copy1' }"
          title="копировать"
          @click="copy"
        ></div>
        <div
          v-if="!locked"
          class="vsvg vsvg--standart chead_icon"
          v-svg="{ name: 'paste' }"
          title="вставить"
          @click="paste"
        ></div>
      </div>
    </div>
    <div class="cblock el--rounded-bottom marginbottom--standart">
      <Table
        :headers="tableElementsHeaders"
        :data-arr="dataArrElements"
        :table-loading="isLoading"
        :loading-count-items="1"
        :disable-pagination="true"
        :disable-hover="true"
        :disabled="disabled"
        :locked="locked"
        @input="onSetElementValue"
      />
    </div>
  </div>
</template>

<script>
import Table from '@/components/tables/Table';
import copyToClipboard from 'copy-to-clipboard';

export default {
  name: 'TableElements',
  components: { Table },
  props: {
    isLoading: { default: false },
    title: { default: '' },
    elements: {
      default() {
        return [];
      },
    },
    value: {
      default() {
        return [];
      },
    },
    disabled: {
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    elementsWithOxid: {
      default: false,
    },
  },
  computed: {
    tableElementsHeaders() {
      let headers = [
        {
          text: this.$t('TableElements:Headers:Name'), //'Название',
          class: 'col-year bold',
          width: '7.69%',
          type: 'text',
        },
      ];
      if (this.elements && this.elements.length > 0) {
        this.elements.forEach((element) => {
          headers.push({
            text: this.elementsWithOxid
              ? element.name
                  .replace('K', 'K₂O')
                  .replace('P', 'P₂O₅')
                  .replace('S', 'SO₃')
                  .replace(/Ca|Mg/, '$&O')
              : element.name,
            class: 'col-element',
            width: '7.69%',
            type: 'input',
          });
        });
      } else {
        ['', '', '', '', '', '', '', '', '', '', ''].forEach(() => {
          headers.push({
            text: '',
            class: 'col-element',
            width: '7.69%',
            type: 'input',
          });
        });
      }

      return headers;
    },
    valueMapByElementId() {
      let map = new Map();
      this.value.forEach((val) => {
        map.set(val.element_id, val);
      });
      return map;
    },
    dataArrElements: {
      get() {
        let arr = [];

        arr.push({ text: this.$t('TableElements:Data') });
        if (this.elements) {
          this.elements.forEach((element) => {
            arr.push({
              text: this.valueMapByElementId.has(element?.id)
                ? this.valueMapByElementId.get(element?.id).quantity
                : '',
              id: element?.id,
            });
          });
        }

        return [arr];
      },
      set(newData) {
        let res = [];
        this.elements.forEach((element, index) => {
          res.push({
            element_id: element.id,
            quantity: newData[0][index + 1]?.text,
            //? Number(newData[0][index + 1]?.text)
            // : null,
          });
        });
        this.$emit('input', res);
      },
    },
  },
  methods: {
    onSetElementValue(payload) {
      let tmp = this.dataArrElements;
      tmp[payload.row][payload.column].text = payload.value;
      this.dataArrElements = tmp;
    },
    copy() {
      let data = '';
      if (this.value) {
        this.value.forEach((v) => {
          data += v?.quantity + ';';
        });
        data = data.slice(0, data.length - 1);
      }
      copyToClipboard(data);
      this.$awn.success(this.$t('TableElements:Copy'));
    },
    async paste() {
      const text = window.prompt(this.$t('TableElements:Enter'));
      if (text) {
        let data = text.split(';');
        if (data.length === 12) {
          //console.log(data);
          data.forEach((v, index) => {
            this.onSetElementValue({ row: 0, column: index + 1, value: v });
          });
          this.$awn.success(this.$t('TableElements:Paste'));
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.chead__copy_paste_icons_parent {
  & .chead__copy_paste_icons_wrap {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear;
  }
  &:hover .chead__copy_paste_icons_wrap {
    visibility: visible;
    opacity: 1;
  }
}
</style>
