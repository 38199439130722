import i18n from '@/i18n';

//translated

export function getAnalyseStatus(analyse) {
  let status = '';
  if (analyse?.date_create === null) {
    return i18n.t('analyzesHelper:AnalyseStatus:0'); //'Новый';
  }
  if (analyse?.date_create !== null) {
    let startOfDayAnalyse = new Date(analyse.date_create).setHours(0, 0, 0, 0);
    let startOfDayToday = new Date().setHours(0, 0, 0, 0);
    let diff = (startOfDayToday - startOfDayAnalyse) / (1000 * 60 * 60 * 24);
    if (diff <= 2) {
      status = i18n.t('analyzesHelper:AnalyseStatus:1'); //'Образец получен';
    }
    if (diff >= 2) {
      status = i18n.t('analyzesHelper:AnalyseStatus:2'); //'Доставлен в центральный офис';
    }
    if (diff >= 6) {
      status = i18n.t('analyzesHelper:AnalyseStatus:3'); //'Образец обрабатывается';
    }
    if (diff === 9) {
      status = i18n.t('analyzesHelper:AnalyseStatus:4'); //'Доставлен в лабораторию';
    }
    if (diff > 9) {
      status = `${i18n.t('analyzesHelper:AnalyseStatus:5.1')} ${
        24 - diff
      } ${i18n.t('analyzesHelper:AnalyseStatus:5.2')}`; //`Образец анализируется. Будет готов через ${24 - diff} дней`;
    }
    if (diff >= 24) {
      status = i18n.t('analyzesHelper:AnalyseStatus:6'); //'Готов';
    }
  }
  return status;
}
export function getAnalyseStatusId(analyse) {
  let status = -1;
  if (analyse?.date_create === null) {
    return i18n.t('analyzesHelper:AnalyseStatus:0'); //'Новый';
  }
  if (analyse?.date_create !== null) {
    let startOfDayAnalyse = new Date(analyse.date_create).setHours(0, 0, 0, 0);
    let startOfDayToday = new Date().setHours(0, 0, 0, 0);
    let diff = (startOfDayToday - startOfDayAnalyse) / (1000 * 60 * 60 * 24);
    if (diff <= 2) {
      status = 0;
    }
    if (diff >= 2) {
      status = 1;
    }
    if (diff >= 6) {
      status = 2;
    }
    if (diff === 9) {
      status = 3;
    }
    if (diff > 9) {
      status = 4;
    }
    if (diff >= 24) {
      status = 5;
    }
  }
  return status;
}
export function isAnalyseStatusDone(analyse) {
  return getAnalyseStatusId(analyse) === 5;
  /*let status = false;
  if (analyse?.date_create === null) {
    return false;
  }
  if (analyse?.date_create !== null) {
    let startOfDayAnalyse = new Date(analyse.date_create).setHours(0, 0, 0, 0);
    let startOfDayToday = new Date().setHours(0, 0, 0, 0);
    let diff = (startOfDayToday - startOfDayAnalyse) / (1000 * 60 * 60 * 24);
    if (diff <= 0) {
      status = false;
    }
    if (diff >= 1) {
      status = false;
    }
    if (diff === 3) {
      status = false;
    }
    if (diff > 3) {
      status = false;
    }
    if (diff >= 18) {
      status = true;
    }
  }
  return status;*/
}
