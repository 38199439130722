<!-- Translated -->
<template>
  <div>
    <div class="chead el--borders-top el--borders-sides el--rounded-top">
      <div class="chead__line-block">
        <slot name="header" />
      </div>
    </div>
    <div
      class="cblock el--borders-all el--rounded-bottom"
      v-if="selectedItems.length === 0"
    >
      <div class="cblock__infoblock cblock__infoblock--nocontentyet">
        <slot name="description" />

        <Button
          v-if="!readonly"
          :text="addButtonText"
          size="wider"
          color="accent"
          icon="plus"
          :disabled="!canAdd"
          @click="add"
        />
      </div>
    </div>

    <div class="cblock el--rounded-bottom" v-if="dataArr.length">
      <Table
        :headers="headers"
        :data-arr="dataArr"
        :table-loading="isLoading"
        :loading-count-items="3"
        @editClick="edit"
      >
        <Button
          v-if="!readonly"
          :text="
            canAdd ? addButtonText : $t('GlossaryLinkedItems:NothingToAdd')
          "
          size="wider"
          color="accentlines"
          icon="plus"
          :is-loading="false"
          :loading-left="false"
          @click="add"
          :disabled="!canAdd || isLoading"
        />
      </Table>
    </div>

    <AddItemModal
      v-if="showModal"
      :items="items"
      :selectedItems="selectedItems"
      :editingId="editingId"
      :label="label"
      :placeholder="placeholder"
      @close="showModal = false"
      @save="newItem"
      @remove="removeItem"
      @edit="swapItems"
    />
  </div>
</template>

<script>
import Button from '@/components/views/Button';
import Table from '@/components/tables/Table';
import AddItemModal from './GlossaryLinkedItemsModal';
import isEqual from 'lodash.isequal';

export default {
  name: 'GlossaryLinkedItems',
  components: {
    Button,
    Table,
    AddItemModal,
  },
  model: { prop: 'selectedItems' },
  props: {
    items: { type: Array, default: () => [] },
    selectedItems: { type: Array, default: () => [] },
    isLoading: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    header: {
      type: String,
      default() {
        return this.$t('GlossaryLinkedItems:Name');
      },
    },
    addButtonText: {
      type: String,
      default() {
        return this.$t('GlossaryLinkedItems:ButtonAdd');
      },
    },
    label: {
      type: String,
      default() {
        return this.$t('GlossaryLinkedItems:Name');
      },
    },
    placeholder: {
      type: String,
      default() {
        return this.$t('GlossaryLinkedItems:Placeholder');
      },
    },
  },
  data() {
    return {
      showModal: false,
      editingId: null,
    };
  },
  computed: {
    dataArr() {
      return this.selectedItems
        .map((id) => this.items.find((item) => item.value === id))
        .map((item) => [{ text: item.text }, { text: item.value }]);
    },
    headers() {
      const headers = [
        {
          text: this.header,
          width: '100%',
          type: 'text',
        },
      ];

      if (!this.readonly) {
        headers.push({
          text: '',
          class: 'col-edit',
          width: '',
          type: 'edit',
          icon: 'edit',
        });
      }

      return headers;
    },
    canAdd() {
      return this.items.length > this.selectedItems.length;
    },
  },
  methods: {
    add() {
      this.editingId = null;
      this.showModal = true;
    },
    edit(id) {
      this.editingId = id;
      this.showModal = true;
    },
    newItem(id) {
      this.$emit('input', [...this.selectedItems, id]);
    },
    removeItem(id) {
      this.$emit(
        'input',
        this.selectedItems.filter((itemId) => itemId !== id)
      );
    },
    swapItems(oldId, newId) {
      this.$emit('input', [
        ...this.selectedItems.filter((itemId) => itemId !== oldId),
        newId,
      ]);
    },
  },
  watch: {
    items(value, oldValue) {
      if (isEqual(value, oldValue)) return;
      const ids = value.map((item) => item.value);
      this.$emit(
        'input',
        this.selectedItems.filter((itemId) => ids.includes(itemId))
      );
    },
  },
};
</script>
