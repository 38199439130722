<!--Translated-->
<template>
  <div class="modal modal--long4x modal--analyzes">
    <div class="modalwrap2-topline marginbottom--mini">
      <div class="topline-minizone">
        <div class="previewwrap">
          <Button
            class="marginright--standart"
            text="Добавить анализ"
            size="s-standart"
            color="accent"
            icon="plus"
            @click="addAnalysis"
            :disabled="isSimpleView"
          />

          <div class="preview">
            <div class="">
              <!--<h4>ახალი ანალიზის</h4>-->
              <p>ახალი ანალიზის შედეგების დამატება</p>
            </div>
          </div>
        </div>

        <div class="previewwrap">
          <Button
            class=""
            text="Изменить вид"
            size="s-standart"
            :color="isSimpleView ? 'accent' : 'accentlightlines'"
            icon="shuffle"
            :is-loading="false"
            :loading-left="true"
            @click="isSimpleView = !isSimpleView"
          />
          <div class="preview">
            <div class="">
              <!--<h4>ანალიზის შედეგების</h4>-->
              <p>ანალიზის შედეგების შედარება წლების მიხედვით</p>
            </div>
          </div>
        </div>
      </div>

      <Breadcrumps :data="[{ title: $t('ClientAnalyzesModal:Title') }]" />

      <div class="topline-minizone">
        <Button
          size="s-standart"
          color="accentlightlines"
          icon="x"
          :is-loading="false"
          :loading-left="true"
          @click="$emit('close')"
        />
      </div>
    </div>
    <div class="decorline"></div>

    <div
      class="modalwrap2-content analyzes-wrap"
      ref="content"
      :class="{ 'analyzes-wrap--anotherview': isSimpleView }"
    >
      <template v-if="!isSimpleView">
        <div class="analyzes-list" ref="list">
          <!-- Last analysis need to have padding so dropdowns do not overflow -->
          <div
            v-for="(analysis, index) in analyses"
            :key="analysis.id"
            :style="{
              'padding-bottom': index === analyses.length - 1 ? '200px' : 0,
            }"
          >
            <ClientZoneAnalysis
              :analysis="analysis"
              :locked="false"
              :data-analysis-id="`analysis_${analysis.id}`"
            />
          </div>
        </div>
      </template>

      <ClientZoneAnalysesTotal :analyses="analyses" v-else />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mutationTypes } from '@/store/modules/clientsArea/clients';
import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';
import ClientZoneAnalysis from './ClientZoneAnalysis';
import ClientZoneAnalysesTotal from './ClientZoneAnalysesTotal';

export default {
  name: 'ModalClient2Analyzes',
  props: { zoneId: { type: [String, Number], required: true } },
  components: {
    Button,
    Breadcrumps,
    ClientZoneAnalysis,
    ClientZoneAnalysesTotal,
  },
  data() {
    return {
      isSimpleView: false,
    };
  },
  computed: {
    ...mapState({
      elements: (state) => state.clients2.elements,
      analyses(state) {
        return state.clients2.clientZonesAnalyzes
          .filter((e) => e.zone_id === this.zoneId)
          .reverse();
      },
      lastAddedItemId: (state) => state.clients2.lastAddedItemId,
    }),
  },
  methods: {
    addAnalysis() {
      this.$store.commit(mutationTypes.addBlankAnalysis, this.zoneId);
      this.$refs.content.scrollTop = 0;
    },
  },
  watch: {
    analyses({ length }) {
      if (length === 0) this.$emit('close');
    },
    lastAddedItemId(id) {
      const { list } = this.$refs;
      this.$nextTick(() => {
        const el = list.querySelector(`[data-analysis-id="${id}"]`);
        if (!el) return;

        list.scrollTop += el.offsetHeight;
        const nodes = [
          el.querySelector('.rightzone_blockwithhead-head'),
          ...el.querySelectorAll('thead th'),
        ];
        nodes.forEach((node) => node.classList.add('attention-to-new-item'));
        setTimeout(
          () =>
            nodes.forEach((node) =>
              node.classList.remove('attention-to-new-item')
            ),
          1000
        );

        el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      });
    },
  },
};
</script>

<style>
.analyzes-list .rightzone_blockwithhead-head,
.analyzes-list thead th {
  transition: background-color 1000ms;
}
</style>
