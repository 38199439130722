import { render, staticRenderFns } from "./ClientAnalysesTable.vue?vue&type=template&id=d4c58568&"
import script from "./ClientAnalysesTable.vue?vue&type=script&lang=js&"
export * from "./ClientAnalysesTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports