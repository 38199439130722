import axios from '@/api/axios';

export const getInstruction = (payload) => {
  return axios.get('/clientsArea/instructions/getInstruction', {
    category_id: payload?.category_id ?? 0,
    instructions: payload?.instructions ?? 0,
  });
};
export const getInstructions = (payload) => {
  return axios.get('/clientsArea/instructions/getInstructions', {
    instructions: payload?.instructions ?? 0,
  });
};
