<template>
  <div class="pagination">
    <Button
      v-if="countPages > 1"
      text=""
      size="s-mini"
      color="transpgray"
      icon="chevronleft"
      :is-loading="false"
      :loading-left="false"
      :disabled="activePageNum === 1"
      @click="activePageNum !== 1 ? activePageNum-- : ''"
    />

    <div v-for="(page, index) in pages" :key="index">
      <Button
        v-if="!page.isDots"
        @click="activePageNum = Number(page.text)"
        :text="page.text"
        size="s-mini"
        :color="page.selected ? 'accent' : 'accentlightlines'"
        icon=""
        :is-loading="false"
        :loading-left="false"
      />
      <div class="pagination-dots" v-if="page.isDots">...</div>
    </div>

    <Button
      v-if="countPages > 1"
      text=""
      size="s-mini"
      color="transpgray"
      icon="chevronright"
      :is-loading="false"
      :loading-left="false"
      :disabled="activePageNum === countPages"
      @click="activePageNum !== countPages ? activePageNum++ : ''"
    />
  </div>
</template>

<script>
import Button from '@/components/views/Button';
export default {
  name: 'AppPagination',
  components: { Button },
  props: {
    countPages: {
      type: Number,
      default: 8,
    },
    /*   activePage:{
      type: Number,
      default: 1
    },*/
    value: {
      default: 1,
    },
  },
  data() {
    return {
      activePageNum: this.value,
    };
  },
  computed: {
    pages() {
      let arr = [];
      if (this.countPages <= 7) {
        for (let i = 0; i < this.countPages; i++) {
          let p = {
            text: `${i + 1}`,
            selected: i + 1 === this.activePageNum,
            isDots: false,
          };
          arr.push(p);
        }
      } else {
        arr.push({
          text: `1`,
          selected: 1 === this.activePageNum,
          isDots: false,
        });
        if (this.activePageNum >= 1 && this.activePageNum <= 4) {
          arr.push({
            text: `2`,
            selected: 2 === this.activePageNum,
            isDots: false,
          });
          arr.push({
            text: `3`,
            selected: 3 === this.activePageNum,
            isDots: false,
          });
          arr.push({
            text: `4`,
            selected: 4 === this.activePageNum,
            isDots: false,
          });
          arr.push({
            text: `5`,
            selected: 5 === this.activePageNum,
            isDots: false,
          });
          arr.push({ text: `...`, selected: false, isDots: true });
          arr.push({
            text: this.countPages,
            selected: this.countPages === this.activePageNum,
            isDots: false,
          });
        } else {
          if (
            this.activePageNum <= this.countPages &&
            this.activePageNum > this.countPages - 3
          ) {
            arr.push({ text: `...`, selected: false, isDots: true });
            arr.push({
              text: this.countPages - 4,
              selected: this.countPages - 4 === this.activePageNum,
              isDots: false,
            });
            arr.push({
              text: this.countPages - 3,
              selected: this.countPages - 3 === this.activePageNum,
              isDots: false,
            });
            arr.push({
              text: this.countPages - 2,
              selected: this.countPages - 2 === this.activePageNum,
              isDots: false,
            });
            arr.push({
              text: this.countPages - 1,
              selected: this.countPages - 1 === this.activePageNum,
              isDots: false,
            });
            arr.push({
              text: this.countPages,
              selected: this.countPages === this.activePageNum,
              isDots: false,
            });
          } else {
            arr.push({ text: `...`, selected: false, isDots: true });

            arr.push({
              text: this.activePageNum - 1,
              selected: this.activePageNum - 1 === this.activePageNum,
              isDots: false,
            });
            arr.push({
              text: this.activePageNum,
              selected: this.activePageNum === this.activePageNum,
              isDots: false,
            });

            if (this.activePageNum + 1 <= this.countPages) {
              arr.push({
                text: this.activePageNum + 1,
                selected: this.activePageNum + 1 === this.activePageNum,
                isDots: false,
              });
            }

            arr.push({ text: `...`, selected: false, isDots: true });
            arr.push({
              text: this.countPages,
              selected: this.countPages === this.activePageNum,
              isDots: false,
            });
          }
        }

        //
        //
        // if(this.activePageNum <= this.countPages && this.activePageNum >= this.countPages - 4) {
        //   arr.push({text: this.countPages - 3, selected: this.countPages - 3 === this.activePageNum, isDots: false});
        //   arr.push({text: this.countPages - 2, selected: this.countPages - 2 === this.activePageNum, isDots: false});
        //   arr.push({text: this.countPages - 1, selected: this.countPages - 1 === this.activePageNum, isDots: false});
        // } else {
        //   arr.push({text: `...`, selected: false, isDots: true});
        // }
        // arr.push({text: this.countPages, selected: this.countPages === this.activePageNum, isDots: false});

        /*for(let i=0; i<7; i++){
          let p = {
            text: `${i+1}`,
            selected: i+1 === this.activePageNum,
            isDots: false
          }
          arr.push(p);
        }*/
      }

      return arr;
    },
  },
  watch: {
    activePageNum() {
      this.$emit('input', this.activePageNum);
    },
    value() {
      this.activePageNum = this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/vars.scss';

.pagination {
  max-height: $heightMedium;
  display: flex;

  & > * {
    margin-left: $marginMini;

    &:last-child {
      margin-left: $marginMicro;
    }
  }
}

.pagination-dots {
  padding-top: $marginMini;
  color: $colorTextGray;
  font-size: $marginMini;
  line-height: 10px;
  width: $heightMedium;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1800px) {
  .pagination {
    max-height: $heightMedium-k1600;

    & > * {
      margin-left: $marginMini-k1600;

      &:last-child {
        margin-left: $marginMicro-k1600;
      }
    }
  }
  .pagination-dots {
    padding-top: $marginMini-k1600;
    font-size: $marginMini-k1600;
    line-height: 10px;
    width: $heightMedium-k1600;
  }
}

@media screen and (max-width: 1360px) {
  .pagination {
    max-height: $heightMedium-k1360;

    & > * {
      margin-left: $marginMini-k1360;

      &:last-child {
        margin-left: $marginMicro-k1360;
      }
    }
  }
  .pagination-dots {
    padding-top: $marginMini-k1360;
    font-size: $marginMini-k1360;
    line-height: 10px;
    width: $heightMedium-k1360;
  }
}
</style>
