<template>
  <article class="rightzone rightzone--userarticleslist">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />
    </div>

    <div class="articleslist cblock__internalpadding el--borders-all el--rounded-all">

      <div class="articleslist_line">
        <p>Вредные объекты</p>
      </div>

      <div class="articleslist_line">
        <p>Принципы борьбы с сорняками</p>
      </div>

      <div class="articleslist_line">
        <p>Принципы создания программы защиты растений</p>
      </div>

      <div class="articleslist_line">
        <p>Каталоги</p>
      </div>

      <div class="articleslist_line">
        <p>Где купить</p>
      </div>

      <div class="articleslist_line">
        <p>Часто задаваемые вопросы</p>
      </div>

    </div>

    <UserFooter />
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import UserFooter from '@/pages/userproject/userComponents/UserFooter';
import {mapGetters, mapState} from "vuex";
import routeNames from "@/router/routeNames";

export default {
  name: 'UserArticlesList2',
  components: {
    UserFooter,
    Breadcrumps,
  },
  computed: {
    breadcrumpsArr() {
      return [
        { title: 'Статьи', route: routeNames.userHomePage },
        { title:  'Защита растений'},
      ];
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/vars.scss';
</style>
