import axios from '@/api/axios';

export const getEditInstructionData = (payload) => {
  return axios.get('/instructions/getEditInstructionData', {
    params: {
      categoryId: payload?.categoryId,
    },
  });
};
export const updateInstruction = (payload) => {
  return axios.post('/instructions/updateInstruction', payload);
};

export default {
  getEditInstructionData,
  updateInstruction,
};
