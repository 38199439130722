<!--Translated-->
<template>
  <Input
    type="dropdown"
    :state="disabled ? 'disabled' : locked || disabled2 ? 'locked' : ''"
    v-bind="$attrs"
    :dd-items="ddItems"
    :dd-selected-index="index"
    @selected="selected"
    :dd-max-elements="ddMaxElements"
    @ddOpen="$emit('ddStateChange', true)"
    @ddClose="$emit('ddStateChange', false)"
  />
</template>

<script>
import Input from '@/components/views/Input';

export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: { type: Object },
    disabled: { type: Boolean, default: false },
    disabled2: { type: Boolean, default: false },
    locked: { type: Boolean, default: false },
    notSelectedText: {
      type: String,
      default() {
        return this.$t('DropdownSelect:NullValueText');
      },
    },
    disableNull: { type: Boolean, default: false },
    ddMaxElements: { default: '7' },
  },
  components: { Input },
  data() {
    return {
      index: 0,
      item: null,
    };
  },
  methods: {
    selected({ index, item }, ignoreEvent = false) {
      this.index = index;
      this.item = item;
      if (!ignoreEvent) {
        this.$emit('input', index ? item : this.disableNull ? item : null);
        this.$emit('selected');
      }
    },
  },
  computed: {
    ddItems() {
      return this.disableNull
        ? this.options
        : [{ value: null, text: this.notSelectedText }, ...this.options];
    },
  },
  watch: {
    ddItems(newItems) {
      const containOldValue = newItems
        .map((item) => item.value)
        .some((value) => value === this.item?.value);
      if (!containOldValue) this.index = 0;
    },
    value: {
      handler(item) {
        if (!item) item = this.ddItems[0];
        const index = this.ddItems.findIndex(
          ({ value }) => value === item?.value
        );
        this.selected({ index, item }, true);
      },
      immediate: true,
    },
  },
};
</script>
