<!--Translated-->
<template>
  <div class="rightzone_table">
    <LoaderBlock v-if="templatesSearching" />
    <Table
      v-else-if="templates.length"
      :headers="headers"
      :dataArr="rows"
      @editClick="selectTemplate"
      :loading-count-items="4"
    >
      <template v-slot:warning-icons>
        <div v-svg="{ name: 'warningdiese' }"></div>
        <div class="fill-svg--accent" v-svg="{ name: 'defaultstar' }"></div>
      </template>
    </Table>
    <div v-else class="cwrap marginbottom--standart">
      <div class="cblock_bigtext">
        {{ $t('TemplatesList:NoTemplates1') }}
        <!--<br />
        {{ $t('TemplatesList:NoTemplates2') }}-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mutationTypes } from '@/store/modules/programs';

import LoaderBlock from '@/components/blocks/LoaderBlock';
import Table from '@/components/tables/Table';

export default {
  name: 'TemplatesList',
  components: { LoaderBlock, Table },
  props: { templates: { type: Array, required: true } },
  computed: {
    ...mapGetters(['geoPlainDataAsMap']),
    ...mapState({
      cultures: (state) => state.programs.cultures,
      templatesSearching: (state) => state.programs.templatesSearching,
    }),
    headers() {
      return [
        {
          text: '',
          class: 'col-warning',
          icon: 'warningdiese',
          type: 'warning',
        },
        {
          text: this.$t('TemplatesList:Table:Headers:0'), //'Название шаблона',
          width: '85%',
          type: 'text',
        },
        {
          text: this.$t('TemplatesList:Table:Headers:1'), //'Выбрать шаблон',
          class: '',
          width: '15%',
          type: 'select',
        },
      ];
    },
    rows() {
      return [...this.templates]
        .sort((a, b) => b.is_default - a.is_default)
        .map((t) =>
          [t.is_default, t.name, t.id].map((text) => ({
            text,
          }))
        );
    },
  },
  methods: {
    selectTemplate(id) {
      this.$store.commit(mutationTypes.selectTemplate, Number(id));
    },
  },
};
</script>
