<!-- Translated -->
<template>
  <div class="cblock__line">
    <div class="cblock__line-block wrapped-line">
      <Input
        v-for="(entry, index) in codes"
        :key="index"
        type="text"
        :placeholder="$t('ClientPlacesCodes:InputCode.Placeholder')"
        :label="
          $t('ClientPlacesCodes:InputCode.Label') +
          (codes.length > 1 ? ` ${index + 1}` : '')
        "
        size="standart"
        :value="entry.code"
        :state="readonly ? 'disabled2' : ''"
        @input="setPlaceCode(index, $event)"
        :customLeftButtonIcon="
          codes.length > 1 && !readonly ? 'delete' : undefined
        "
        customLeftButtonClass="QlZzUgE83"
        @customLeftButtonClick="removePlaceCode(index)"
      />
      <Button
        v-if="!readonly"
        :text="$t('ClientPlacesCodes:InputCode.ButtonAddCode')"
        size="wide"
        color="accentlightlines"
        icon="plus"
        @click="addPlaceCode"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mutationTypes } from '@/store/modules/clients';
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';

export default {
  name: 'ClientPlacesCodes',
  components: { Button, Input },
  props: {
    placeId: { type: [String, Number], required: true },
    readonly: { type: Boolean, default: false },
  },
  computed: mapState({
    codes(state) {
      return state.clients.clientPlacesCodes.filter(
        (e) => e.place_id === this.placeId
      );
    },
  }),
  methods: {
    addPlaceCode() {
      this.$store.commit(mutationTypes.addBlankPlaceCode, this.placeId);
    },
    setPlaceCode(index, value) {
      this.$store.commit(mutationTypes.setPlaceCode, {
        placeId: this.placeId,
        index,
        value,
      });
    },
    removePlaceCode(index) {
      this.$store.commit(mutationTypes.removePlaceCode, {
        placeId: this.placeId,
        index,
      });
    },
  },
};
</script>

<style>
.QlZzUgE83 path {
  fill: #999a997e !important;
}
.QlZzUgE83 path:hover {
  fill: #a93239 !important;
}
</style>

<style scoped>
.wrapped-line {
  flex-wrap: wrap;
  row-gap: 16px;
}
</style>
