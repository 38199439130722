import * as adminApi from '@/api/admin';

const state = {
  isUsersLoading: false,
  isGetUsersHasError: false,
  users: null,
  userContacts: null,
  contactTypes: null,
  roles: null,

  usersMapById: null,
  //usersContactsMapById:null,
  contactTypesMapById: null,
  rolesMapById: null,
  //compiledUsers: null

  isNewUserDataLoading: false,
  isGetNewUserDataHasError: false,

  isUpdateUserLoading: false,
  isUpdateUserHasError: false,
  isUpdateUserDone: false,
  updateUserErrorText: null,

  editUser: null,
  isEditUserDataLoading: false,
  isGetEditUserDataHasError: false,
  editUserContacts: null,

  isRolesLoading: false,
  isRolesLoadingHasError: false,

  isUpdateRoleLoading: false,
  isUpdateRoleHasError: false,
  isUpdateRoleDone: false,
  updateRoleErrorText: null,
  editRole: null,
  editRoleId: null,
};

export const mutationTypes = {
  getUsersStart: '[admin] getUsers start',
  getUsersSuccess: '[admin] getUsers success',
  getUsersFailure: '[admin] getUsers failure',

  getNewUserDataStart: '[admin] getNewUserData start',
  getNewUserDataSuccess: '[admin] getNewUserData success',
  getNewUserDataFailure: '[admin] getNewUserData failure',

  setUpdateUserStart: '[admin] setUpdateUser start',
  setUpdateUserSuccess: '[admin] setUpdateUser success',
  setUpdateUserFailure: '[admin] setUpdateUser failure',

  getEditUserDataStart: '[admin] getEditUserData start',
  getEditUserDataSuccess: '[admin] getEditUserData success',
  getEditUserDataFailure: '[admin] getEditUserData failure',

  getRolesStart: '[admin] getRoles start',
  getRolesSuccess: '[admin] getRoles success',
  getRolesFailure: '[admin] getRoles failure',

  setUpdateRoleStart: '[admin] setUpdateRole start',
  setUpdateRoleSuccess: '[admin] setUpdateRole success',
  setUpdateRoleFailure: '[admin] setUpdateRole failure',

  getEditRoleStart: '[admin] getEditRole start',
  getEditRoleSuccess: '[admin] getEditRole success',
  getEditRoleFailure: '[admin] getEditRole failure',
};
export const actionTypes = {
  getUsers: '[admin] getUsers',
  getNewUserData: '[admin] getNewUserData',
  setUpdateUser: '[admin] setUpdateUser',
  getEditUserData: '[admin] setEditUserData',
  getRoles: '[admin] getRoles',
  setUpdateRole: '[admin] setUpdateRole',
  getEditRole: '[admin] getEditRole',
};
const mutations = {
  [mutationTypes.getUsersStart](state) {
    state.isUsersLoading = true;
    state.isGetUsersHasError = false;
  },
  [mutationTypes.getUsersSuccess](state, payload) {
    state.isUsersLoading = false;
    state.users = payload.users;
    state.userContacts = payload.userContacts;
    state.contactTypes = payload.contactTypes;
    state.roles = payload.roles;
    compileUsersData(state);
  },
  [mutationTypes.getUsersFailure](state) {
    state.isUsersLoading = false;
    state.isGetUsersHasError = true;
  },
  [mutationTypes.getNewUserDataStart](state) {
    state.isNewUserDataLoading = true;
    state.isGetNewUserDataHasError = false;
  },
  [mutationTypes.getNewUserDataSuccess](state, payload) {
    state.isNewUserDataLoading = false;
    state.roles = payload.roles;
    state.contactTypes = payload.contactTypes;
    compileUsersData(state);
  },
  [mutationTypes.getNewUserDataFailure](state) {
    state.isNewUserDataLoading = false;
    state.isGetNewUserDataHasError = true;
  },
  [mutationTypes.setUpdateUserStart](state) {
    state.isUpdateUserLoading = true;
    state.isUpdateUserHasError = false;
    state.isUpdateUserDone = false;
    state.updateUserErrorText = null;
  },
  [mutationTypes.setUpdateUserSuccess](state, payload) {
    state.isUpdateUserLoading = false;
    if (payload.status && payload.status === 'ok') {
      //
    } else {
      state.isUpdateUserHasError = true;
      state.updateUserErrorText = payload?.message || 'Error';
    }
    state.isUpdateUserDone = true;
    //state.roles = payload.roles;
    //compileUsersData(state);
  },
  [mutationTypes.setUpdateUserFailure](state) {
    state.isUpdateUserLoading = false;
    state.isUpdateUserHasError = true;
    state.isUpdateUserDone = true;
  },
  [mutationTypes.getEditUserDataStart](state) {
    state.editUser = null;
    state.editUserContacts = null;
    state.isEditUserDataLoading = true;
    state.isGetEditUserDataHasError = false;
  },
  [mutationTypes.getEditUserDataSuccess](state, payload) {
    state.isEditUserDataLoading = false;
    state.roles = payload.roles;
    state.contactTypes = payload.contactTypes;
    state.editUser = payload.editUser;
    state.editUserContacts = payload.editUserContacts;
    compileUsersData(state);
  },
  [mutationTypes.getEditUserDataFailure](state) {
    state.isEditUserDataLoading = false;
    state.isGetEditUserDataHasError = true;
  },
  //Роли
  [mutationTypes.getRolesStart](state) {
    state.isRolesLoading = true;
    state.isRolesLoadingHasError = false;
  },
  [mutationTypes.getRolesSuccess](state, payload) {
    state.isRolesLoading = false;
    state.roles = payload.roles;
    compileUsersData(state);
  },
  [mutationTypes.getRolesFailure](state) {
    state.isRolesLoading = false;
    state.isRolesLoadingHasError = true;
  },
  [mutationTypes.setUpdateRoleStart](state) {
    state.isUpdateRoleLoading = true;
    state.isUpdateRoleHasError = false;
    state.isUpdateRoleDone = false;
    state.updateRoleErrorText = null;
  },
  [mutationTypes.setUpdateRoleSuccess](state, payload) {
    state.isUpdateRoleLoading = false;
    if (payload.status && payload.status === 'ok') {
      //
    } else {
      state.isUpdateRoleHasError = true;
      state.updateRoleErrorText = payload?.message || 'Error';
    }
    state.isUpdateRoleDone = true;
  },
  [mutationTypes.setUpdateRoleFailure](state) {
    state.isUpdateRoleLoading = false;
    state.isUpdateRoleHasError = true;
    state.isUpdateRoleDone = true;
  },
  [mutationTypes.getEditRoleStart](state, payload) {
    state.isRolesLoading = true;
    state.isRolesLoadingHasError = false;
    state.editRoleId = payload;
    state.editRole = null;
  },
  [mutationTypes.getEditRoleSuccess](state, payload) {
    state.isRolesLoading = false;
    state.roles = payload.roles;
    compileUsersData(state);
    if (
      state.rolesMapById &&
      state.editRoleId &&
      state.rolesMapById.has(Number(state.editRoleId))
    ) {
      state.editRole = state.rolesMapById.get(Number(state.editRoleId));
    } else {
      state.editRole = null;
    }
  },
  [mutationTypes.getEditRoleFailure](state) {
    state.isRolesLoading = false;
    state.isRolesLoadingHasError = true;
    state.editRole = null;
  },
};
function compileUsersData(state) {
  if (state.roles instanceof Array) {
    let rolesMap = new Map();
    state.roles.forEach((role) => {
      rolesMap.set(role.id, role);
    });
    state.rolesMapById = rolesMap;
  }
  if (state.users instanceof Array) {
    let usersMap = new Map();
    state.users.forEach((user) => {
      usersMap.set(user.id, user);
      user.contacts = [];
      if (state.rolesMapById.has(user?.role_id)) {
        user.role = state.rolesMapById.get(user.role_id);
      }
    });
    state.usersMapById = usersMap;
  }
  if (state.contactTypes instanceof Array) {
    let map = new Map();
    state.contactTypes.forEach((contact) => {
      map.set(contact.id, contact);
    });
    state.contactTypesMapById = map;
  }
  if (state.userContacts instanceof Array) {
    state.userContacts.forEach((userContact) => {
      if (state.usersMapById.has(userContact?.user_id)) {
        if (state.contactTypesMapById.has(userContact?.contact_type_id)) {
          userContact['contact_type'] = state.contactTypesMapById.get(
            userContact?.contact_type_id
          );
        }
        state.usersMapById.get(userContact.user_id).contacts.push(userContact);
      }
    });
  }
  if (state.editUser && state.editUserContacts) {
    if (!state.editUser.contacts) {
      state.editUser.contacts = [];
    }
    state.editUserContacts.forEach((userContact) => {
      if (state.contactTypesMapById.has(userContact?.contact_type_id)) {
        userContact['contact_type'] = state.contactTypesMapById.get(
          userContact?.contact_type_id
        );
      }
      state.editUser.contacts.push(userContact);
    });
  }
}
const actions = {
  /* [actionTypes.login](context, payload) {
         return new Promise(resolve => {
             context.commit(mutationTypes.loginStart)
             authApi
                 .login(payload)
                 .then(response => {
                     console.log(response);
                     setTimeout(()=>{
                         context.commit(mutationTypes.loginSuccess, response.data.user)
                         setItem('token', response.data.token)
                         resolve(response.data.user)
                     },3000)

                 })
                 .then( data => {
                     router.push('/')
                     resolve();
                     }
                 )
                 .catch(result => {
                     //console.log(result.response.status);
                     setTimeout(()=>{
                         context.commit(
                             mutationTypes.loginFailure
                         )
                     },3000)
                 })
         })
     },*/
  async [actionTypes.getUsers](context, payload) {
    context.commit(mutationTypes.getUsersStart);
    try {
      let response = await adminApi.getUsers(payload);
      //console.log(response);
      context.commit(mutationTypes.getUsersSuccess, response.data);
    } catch (error) {
      //console.log(error.response.status);
      context.commit(mutationTypes.getUsersFailure);
    }
  },
  async [actionTypes.getNewUserData](context, payload) {
    context.commit(mutationTypes.getNewUserDataStart);
    try {
      let response = await adminApi.getNewUsersData(payload);
      context.commit(mutationTypes.getNewUserDataSuccess, response.data);
    } catch (error) {
      //console.log(error.response.status);
      context.commit(mutationTypes.getNewUserDataFailure);
    }
  },
  async [actionTypes.setUpdateUser](context, payload) {
    context.commit(mutationTypes.setUpdateUserStart);
    try {
      let response = await adminApi.updateUser(payload);
      context.commit(mutationTypes.setUpdateUserSuccess, response.data);
    } catch (error) {
      //console.log(error.response.status);
      context.commit(mutationTypes.setUpdateUserFailure);
    }
  },
  async [actionTypes.getEditUserData](context, payload) {
    context.commit(mutationTypes.getEditUserDataStart);
    try {
      let response = await adminApi.getEditUserData(payload);
      context.commit(mutationTypes.getEditUserDataSuccess, response.data);
    } catch (error) {
      //console.log(error.response.status);
      context.commit(mutationTypes.getEditUserDataFailure);
    }
  },
  async [actionTypes.getRoles](context, payload) {
    context.commit(mutationTypes.getRolesStart);
    try {
      let response = await adminApi.getRoles(payload);
      //console.log(response);
      context.commit(mutationTypes.getRolesSuccess, response.data);
    } catch (error) {
      //console.log(error.response.status);
      context.commit(mutationTypes.getRolesFailure);
    }
  },
  async [actionTypes.setUpdateRole](context, payload) {
    context.commit(mutationTypes.setUpdateRoleStart);
    try {
      let response = await adminApi.updateRole(payload);
      context.commit(mutationTypes.setUpdateRoleSuccess, response.data);
    } catch (error) {
      //console.log(error.response.status);
      context.commit(mutationTypes.setUpdateRoleFailure);
    }
  },
  async [actionTypes.getEditRole](context, payload) {
    context.commit(mutationTypes.getEditRoleStart, payload);
    try {
      let response = await adminApi.getRoles(payload);
      context.commit(mutationTypes.getEditRoleSuccess, response.data);
    } catch (error) {
      //console.log(error.response.status);
      context.commit(mutationTypes.getEditRoleFailure);
    }
  },
};

export default {
  state,
  actions,
  mutations,
};
