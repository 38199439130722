<template>
  <article>
    <div class="cwrap cwrap--phase-program marginbottom--micro">
      <ProgramPhase
        v-for="phase in phases"
        :phase="phase"
        :key="phase.id"
        @dismissManual="showManual = false"
        :showManual="showManual"
      />
    </div>

    <ProgramTables v-if="phases.length && hasArea" />
  </article>
</template>

<script>
import { mapState } from 'vuex';

import ProgramPhase from './ProgramPhase';
import ProgramTables from './ProgramTables';

export default {
  name: 'UserDefenseProgram',
  components: {
    ProgramPhase,
    ProgramTables,
  },
  data() {
    return {
      showManual: true,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.userDefence.templateFetching,
      phases: (state) => state.userDefence.programPhases,
      hasArea: (state) => state.userDefence.program.place_area > 0,
    }),
  },
};
</script>
