import glossaryApi from '@/api/glossary';
import { mutationTypes as mutationTypesCategoriesCultures } from '@/store/modules/glossaryCategoriesCultures';
import { mutationTypes as mutationTypesElements } from '@/store/modules/glossaryElements';
import { mutationTypes as mutationTypesPests } from '@/store/modules/glossaryPests';

const state = {
  isCulturesLoading: false,
  isCulturesLoadingHasError: false,
  cultures: null,
  culturesMapById: null,

  isNewCultureLoading: false,
  isNewCultureLoadingHasError: false,
  newCultureErrorText: null,

  isUpdateCultureLoading: false,
  isUpdateCultureHasError: false,
  isUpdateCultureDone: false,
  updateCultureErrorText: null,

  editCultureId: null,
  editCulture: null,
  editCultureElements: null,
  editCultureSorts: null,
  editCulturePests: null,
  isEditCultureDataLoading: false,
  isGetEditCultureDataHasError: false,
};
export const mutationTypes = {
  getCulturesStart: '[glossary] getCultures start',
  getCulturesSuccess: '[glossary] getCultures success',
  getCulturesFailure: '[glossary] getCultures failure',

  getNewCultureStart: '[glossary] getNewCulture start',
  getNewCultureSuccess: '[glossary] getNewCulture success',
  getNewCultureFailure: '[glossary] getNewCulture failure',

  updateCultureStart: '[glossary] updateCulture start',
  updateCultureSuccess: '[glossary] updateCulture success',
  updateCultureFailure: '[glossary] updateCulture failure',

  getEditCultureStart: '[glossary] getEditCulture start',
  getEditCultureSuccess: '[glossary] getEditCulture success',
  getEditCultureFailure: '[glossary] getEditCulture failure',
};
export const actionTypes = {
  getCultures: '[glossary] getCultures',
  getNewCulture: '[glossary] getNewCulture',
  updateCulture: '[glossary] updateCulture',
  getEditCultureData: '[glossary] getEditCultureData',
};
const mutations = {
  [mutationTypes.getCulturesStart](state) {
    state.isCulturesLoading = true;
    state.isCulturesLoadingHasError = false;
    state.cultures = null;
    state.culturesMapById = new Map();
    state.editCulture = null;
    state.editCultureId = null;
    state.editCultureElements = null;
    state.editCultureSorts = null;
    state.editCulturePests = null;
    //state.editTypeStimulationId = typeStimulationId || null;
    //state.editTypeStimulation = null;
  },
  [mutationTypes.getCulturesSuccess](state, payload) {
    state.isCulturesLoading = false;
    state.cultures = payload.cultures;
    processCultures(state);
  },
  [mutationTypes.getCulturesFailure](state) {
    state.isCulturesLoading = false;
    state.isCulturesLoadingHasError = true;
  },

  [mutationTypes.getNewCultureStart](state) {
    state.isNewCultureLoading = true;
    state.isNewCultureLoadingHasError = false;
    state.newCultureErrorText = null;
  },
  [mutationTypes.getNewCultureSuccess](state) {
    state.isNewCultureLoading = false;
    //commit(mutationTypesCategoriesCultures.getCategoriesCulturesSuccess,payload);
    //state.cultures = payload.cultures;
    //processCultures(state);
  },
  [mutationTypes.getNewCultureFailure](state) {
    state.isNewCultureLoading = false;
    state.isNewCultureLoadingHasError = true;
  },

  [mutationTypes.updateCultureStart](state) {
    state.isUpdateCultureLoading = true;
    state.isUpdateCultureHasError = false;
    state.isUpdateCultureDone = false;
    state.updateCultureErrorText = null;
  },
  [mutationTypes.updateCultureSuccess](state, payload) {
    state.isUpdateCultureLoading = false;
    //state.categoriesCultures = payload.categoriesCultures;
    state.isUpdateCultureDone = true;
    if (payload.status && payload.status === 'ok') {
      //
    } else {
      state.isUpdateCultureHasError = true;
      state.updateCultureErrorText = payload?.message || 'Error';
    }
  },
  [mutationTypes.updateCultureFailure](state) {
    state.isUpdateCultureLoading = false;
    state.isUpdateCultureDone = true;
    state.isUpdateCultureHasError = true;
  },

  [mutationTypes.getEditCultureStart](state, cultureId) {
    state.isEditCultureDataLoading = true;
    state.isGetEditCultureDataHasError = false;
    state.editCultureId = cultureId;
    state.editCultureElements = null;
    state.editCultureSorts = null;
    state.editCulturePests = null;
    state.editCulture = null;
  },
  [mutationTypes.getEditCultureSuccess](state, payload) {
    state.isEditCultureDataLoading = false;
    state.editCulture = payload.culture;
    state.editCultureElements = payload.cultureElements;
    state.editCultureSorts = payload.cultureSorts || [];
    state.editCulturePests = payload.culturePests || [];
    processEditCultureElements(state);
    //commit(mutationTypesCategoriesCultures.getCategoriesCulturesSuccess,payload);
    //state.cultures = payload.cultures;
    //processCultures(state);
  },
  [mutationTypes.getEditCultureFailure](state) {
    state.isEditCultureDataLoading = false;
    state.isGetEditCultureDataHasError = true;
  },
};
function processCultures(state) {
  state.culturesMapById = new Map();
  if (state.cultures) {
    state.cultures.forEach((culture) => {
      state.culturesMapById.set(culture.id, culture);
      /*if(state.editTypeStimulationId && state.editTypeStimulationId == type.id){
              state.editTypeStimulation = type;
          }*/
    });
  }
}
function processEditCultureElements(state) {
  if (state.editCultureElements) {
    for (let i = 0; i < state.editCultureElements.length; i++) {
      let tm = 12 - state.editCultureElements[i].length;
      for (let j = 0; j < tm; j++) {
        state.editCultureElements[i].push(null);
      }
    }
  }
}

const actions = {
  async [actionTypes.getCultures](context, payload) {
    context.commit(mutationTypes.getCulturesStart, payload);
    try {
      let response = await glossaryApi.getCultures(payload);
      context.commit(mutationTypes.getCulturesSuccess, response.data);
      context.commit(
        mutationTypesCategoriesCultures.getCategoriesCulturesSuccess,
        response.data
      );
    } catch (error) {
      context.commit(mutationTypes.getCulturesFailure);
    }
  },
  async [actionTypes.getNewCulture](context, payload) {
    context.commit(mutationTypes.getNewCultureStart, payload);
    try {
      let response = await glossaryApi.getNewCultureData(payload);
      context.commit(
        mutationTypesCategoriesCultures.getCategoriesCulturesSuccess,
        response.data
      );
      context.commit(mutationTypesElements.setElementsSuccess, response.data);
      context.commit(mutationTypesPests.setPestsSuccess, response.data);
      context.commit(mutationTypes.getNewCultureSuccess, response.data);
    } catch (error) {
      context.commit(mutationTypes.getNewCultureFailure);
    }
  },
  async [actionTypes.updateCulture](context, payload) {
    context.commit(mutationTypes.updateCultureStart);
    try {
      let response = await glossaryApi.updateCulture(payload);
      context.commit(mutationTypes.updateCultureSuccess, response.data);
    } catch (error) {
      context.commit(mutationTypes.updateCultureFailure);
    }
  },
  async [actionTypes.getEditCultureData](context, payload) {
    context.commit(mutationTypes.getEditCultureStart, payload);
    try {
      let response = await glossaryApi.getEditCultureData(payload);
      context.commit(
        mutationTypesCategoriesCultures.getCategoriesCulturesSuccess,
        response.data
      );
      context.commit(mutationTypesElements.setElementsSuccess, response.data);
      context.commit(mutationTypesPests.setPestsSuccess, response.data);
      context.commit(mutationTypes.getEditCultureSuccess, response.data);
    } catch (error) {
      context.commit(mutationTypes.getEditCultureFailure);
    }
  },
};
export default {
  state,
  actions,
  mutations,
};
