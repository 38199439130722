<!--Translated-->
<template>
  <tbody :class="`tbl-color--${category.class}`">
    <tr
      class="row tbl_row--active"
      v-for="(product, index) in products"
      :key="product.id"
    >
      <td v-if="index === 0" class="tbl-cellproblem" :rowspan="products.length">
        <div class="tbl_info">
          <div class="tbl_info-wrap">
            <div v-svg="{ name: category.icon }"></div>
            <HoverTooltip
              :images="images"
              :header="pest.name"
              :text="pest.description"
              :link="pest.link"
            />
          </div>
          <div class="tbl_info-celltext">{{ pest.name }}</div>
        </div>
      </td>
      <td
        class="tbl-cellproduct"
        :class="{ 'tbl-cellproduct--grey': !pestsProductsAsMap[product.id] }"
      >
        <div class="tbl_info">
          <div class="tbl_info-wrap">
            <div
              v-if="product.is_active"
              v-svg="{ name: 'info' }"
              :key="'active'"
            />
            <div
              v-else
              v-svg="{ name: 'warningtriangle' }"
              :key="'no_active'"
              id="force-red"
            />
            <HoverTooltipProduct :product="product" />
          </div>
          <div class="tbl_info-celltext">{{ product.name }}</div>
        </div>
      </td>
      <td class="tbl-cellinpt">
        <InputNumber
          class="inputintable"
          type="number"
          label="-"
          :value="pestsProductsAsMap[product.id]"
          @input="onInput(product, $event)"
          :state="isSaving ? 'disabled' : readonly ? 'disabled2' : ''"
        />
      </td>
      <td class="tbl-cellprice">
        {{
          product.picking
            ? (
                (product.price / product.picking) *
                pestsProductsAsMap[product.id]
              ).toFixed(2)
            : '0.00'
        }}
      </td>
    </tr>

    <tr v-if="products.length === 0" class="row tbl_row--active">
      <td class="tbl-cellproblem" rowspan="1">
        <div class="tbl_info">
          <div class="tbl_info-wrap">
            <div v-svg="{ name: category.icon }"></div>
            <HoverTooltip
              :images="images"
              :header="pest.name"
              :text="pest.description"
              :link="pest.link"
            />
          </div>
          <div class="tbl_info-celltext">{{ pest.name }}</div>
        </div>
      </td>
      <td class="tbl-cellproduct" colspan="3" style="text-align: center">
        {{ $t('TemplateRowPest:NoProducts') }}
      </td>
    </tr>

    <tr class="tablelinedecor">
      <td colspan="4"></td>
    </tr>
  </tbody>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mutationTypes } from '@/store/modules/plantsCare';
import InputNumber from '@/components/views/wrappers/InputNumberWithCast';
import HoverTooltip from './HoverTooltip';
import HoverTooltipProduct from './HoverTooltipProduct';
import { mapPestCategoryToProductCategory } from './mapCategoryToPest';

export default {
  name: 'TemplateRowPest',
  components: { InputNumber, HoverTooltip, HoverTooltipProduct },
  props: {
    phase: { type: Object, required: true },
    pest: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(['plantsCareProductsAsMap', 'plantsCarePestsProductsAsMap']),
    ...mapState({
      isSaving: (state) => state.plantsCare.isSaving,
      waterQuantity(state) {
        return (
          state.plantsCare.templateWaterPhases.find(
            (p) => p.phase_id === this.phase.id
          )?.quantity ?? 0
        );
      },
      productsPestsSubcategories: (state) =>
        state.products.productsPestsSubcategories,
      category(state) {
        return state.products.productsPestsSubcategories.find(
          ({ id }) =>
            id === mapPestCategoryToProductCategory(this.pest.category_id)
        );
      },
      products(state) {
        return (
          state.plantsCare.productsPests
            .filter(({ pest_id }) => pest_id === this.pest.id)
            // .filter((product) => product.is_active)
            .map(({ product_id }) => this.plantsCareProductsAsMap[product_id])
            .filter(Boolean)
            .filter(
              (product) =>
                product.is_active ||
                state.plantsCare.templatePestsProducts.some(
                  ({ product_id }) => product_id === product.id
                )
            )
        );
      },
      images(state) {
        const cultureId = state.plantsCare.template.culture_id;
        const hasImagesBindedToCulture = state.plantsCare.images.some(
          (img) => img.meta_id === cultureId
        );

        return state.plantsCare.images
          .filter(
            (img) =>
              img.object_id === this.pest.id &&
              img.type === 0 &&
              (hasImagesBindedToCulture ? cultureId === img.meta_id : true)
          )
          .sort((a, b) => a.meta_id - b.meta_id);
      },
    }),
    pestsProductsAsMap() {
      const getValueById = (id) =>
        this.plantsCarePestsProductsAsMap[
          `phase_${this.phase.id}_pest_${this.pest.id}_product_${id}`
        ]?.quantity ?? '';
      return Object.fromEntries(
        this.products.map((p) => [p.id, getValueById(p.id)])
      );
    },
  },
  methods: {
    onInput(product, value) {
      this.$store.commit(mutationTypes.upsertPestProduct, {
        phase_id: this.phase.id,
        pest_id: this.pest.id,
        product_id: product.id,
        quantity: value,
      });
    },
  },
};
</script>
<style lang="scss">
@import '~@/scss/vars.scss';
.tbl-cellproduct--grey {
  & .tbl_info-celltext {
    color: $colorGray !important;
  }
  & svg * {
    fill: $colorGray !important;
  }
}
#force-red path,
#force-red:hover path {
  fill: #a93239 !important;
}
</style>
