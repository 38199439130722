<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('GlossaryCategoriesEdit:ButtonCancel')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'Categories' })"
        />
        <Button
          :text="$t('GlossaryCategoriesEdit:ButtonSave')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="isSaveButtonLoading"
          :loading-left="true"
          :disabled="isSaveButtonDisabled"
          @click="onSave"
        />
      </div>
    </div>

    <!--    КАТЕГОРИЯ КУЛЬТУРЫ-->

    <div class="cwrap cwrap--level1 marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'edit' }"
          ></div>
          <div class="chead_text">
            {{ $t('GlossaryCategoriesEdit:HeaderText') }}
          </div>
        </div>
      </div>

      <div
        class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                :placeholder="
                  $t('GlossaryCategoriesEdit:CategoryName.Placeholder')
                "
                :label="$t('GlossaryCategoriesEdit:CategoryName.Label')"
                icon=""
                size="long"
                color=""
                v-model="newCategory.name"
                :state="inputsState"
                @input="validationState.name = true"
              />
            </div>
          </div>

          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="textarea2x"
                :placeholder="
                  $t('GlossaryCategoriesEdit:CategoryDescription.Placeholder')
                "
                :label="$t('GlossaryCategoriesEdit:CategoryDescription.Label')"
                size="max"
                :state="inputsState"
                v-model="newCategory.description"
              />
            </div>
          </div>

          <div class="cblock__line" v-if="false">
            <div class="cblock__line-block">
              <RadioToggle
                type="disabled"
                :text1="$t('GlossaryCategoriesEdit:CategoryActive:Hide')"
                :text2="$t('GlossaryCategoriesEdit:CategoryActive:Active')"
                :label="$t('GlossaryCategoriesEdit:CategoryActive:Label')"
                :reverse="true"
                :disabled="isLoading || !canEdit"
                :locked="isLoading ? false : !canEdit"
                v-model="newCategory.is_active"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <Button
      v-if="canEdit"
      :text="$t('GlossaryCategoriesEdit:ButtonSave')"
      size="standart"
      color="accent"
      icon="save"
      :is-loading="isSaveButtonLoading"
      :loading-left="true"
      :disabled="isSaveButtonDisabled"
      @click="onSave"
    />

    <Modal
      v-if="showModalNotSaved"
      @backgroundClose="showModalNotSaved = false"
    >
      <ModalNotSaved
        v-model="showModalNotSaved"
        @confirm="onModalNotSavedConfirm"
      />
    </Modal>
  </article>
</template>

<script>
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import RadioToggle from '@/components/views/RadioToggle';
import Breadcrumps from '@/components/views/Breadcrumps';
import routeNames from '@/router/routeNames';
import ModalNotSaved from '@/components/modals/modalNotSaved';
import Modal from '@/pages/Modal';
import { mapState } from 'vuex';
import { actionTypes } from '@/store/modules/glossaryCategoriesCultures';

export default {
  name: 'GlossaryCategoriesEdit',
  components: { Breadcrumps, RadioToggle, Input, Button, Modal, ModalNotSaved },
  data() {
    return {
      newCategory: {
        id: null,
        name: '',
        description: '',
        is_active: 1,
      },
      validationState: {
        name: true,
      },
      showModalNotSaved: false,
      nextRouteConfirm: null,
      nextRoute: null,
    };
  },
  methods: {
    onModalNotSavedConfirm() {
      if (this.nextRoute) {
        this.nextRouteConfirm = true;
        this.$router.push(this.nextRoute);
      }
    },
    onSave() {
      let valOk = true;
      if (!this.newCategory.name) {
        this.validationState.name = false;
        valOk = false;
      }
      if (!valOk) {
        return;
      }
      //if(this.isEditUser){
      //this.$store.dispatch(actionTypes.setUpdateUser,{...this.newUser,id:this.$store.state.admin.editUser.id});
      //} else {
      this.$store.dispatch(
        actionTypes.updateCategoriesCultures,
        this.newCategory
      );
      //}
      //console.log(this.newUser)
    },
  },
  computed: {
    ...mapState({
      canEdit: (state) => state.auth.currentUserRole?.claim_glossary_edit === 1,
    }),
    inputsState() {
      if (this.isLoading) return 'disabled';
      if (!this.canEdit) return 'disabled2';
      return this.validationState.name ? '' : 'error';
    },
    // has side effects
    isSaveButtonDisabled() {
      let haveData = false;
      if (this.isEditCategory) {
        for (const key in this.newCategory) {
          if (
            // eslint-disable-next-line
            this.$store.state.glossaryCategoriesCultures.editCategory.hasOwnProperty(
              key
            )
          ) {
            if (
              this.newCategory[key] !==
              this.$store.state.glossaryCategoriesCultures.editCategory[key]
            ) {
              haveData = true;
              break;
            }
          }
        }
      } else {
        if (this.newCategory.name) {
          haveData = true;
        }
      }
      return this.canEdit ? !haveData : true;
    },
    isLoading() {
      return (
        this.$store.state.glossaryCategoriesCultures
          .isUpdateCategoriesCulturesLoading ||
        this.$store.state.glossaryCategoriesCultures.isCategoriesCulturesLoading
      );
    },
    isSaveButtonLoading() {
      return this.$store.state.glossaryCategoriesCultures
        .isUpdateCategoriesCulturesLoading;
    },
    isDone() {
      return this.$store.state.glossaryCategoriesCultures
        .isUpdateCategoriesCulturesDone;
    },
    isEditCategory() {
      //if(this.$store.state.admin.editUser){
      if (
        this.$store.state.glossaryCategoriesCultures.editCategory &&
        this.$route.name === routeNames.editCategory
      ) {
        return true;
      }
      return false;
    },
    editCategory() {
      return this.$store.state.glossaryCategoriesCultures.editCategory;
    },
    breadcrumpsArr() {
      if (this.$route.name === routeNames.editCategory)
        return [
          {
            title: this.$t('GlossaryCategoriesEdit:Title1'),
            route: routeNames.categories,
          },
          { title: this.$t('GlossaryCategoriesEdit:Title2') },
        ];
      else
        return [
          {
            title: this.$t('GlossaryCategoriesEdit:Title1'),
            route: routeNames.categories,
          },
          { title: this.$t('GlossaryCategoriesEdit:Title3') },
        ];
    },
  },
  watch: {
    isDone() {
      if (this.isDone) {
        if (
          !this.$store.state.glossaryCategoriesCultures
            .isUpdateCategoriesCulturesHasError
        ) {
          this.nextRouteConfirm = true;
          this.$router.push({ name: routeNames.categories });
        } else {
          this.$awn.alert(
            this.$store.state.glossaryCategoriesCultures
              .updateCategoriesCulturesErrorText
          );
          if (
            this.$store.state.glossaryCategoriesCultures.updateCategoriesCulturesErrorText.includes(
              'name'
            )
          ) {
            this.validationState.name = true;
          }
        }
      }
    },
    editCategory() {
      if (this.editCategory) {
        for (const key in this.newCategory) {
          if (
            // eslint-disable-next-line
            this.$store.state.glossaryCategoriesCultures.editCategory.hasOwnProperty(
              key
            )
          ) {
            this.newCategory[key] =
              this.$store.state.glossaryCategoriesCultures.editCategory[key];
          }
        }
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.nextRouteConfirm || !this.canEdit) {
      next();
    } else if (this.$route.name === routeNames.editCategory) {
      if (!this.isSaveButtonDisabled) {
        this.showModalNotSaved = true;
        this.nextRoute = to;
        next(false);
      } else {
        next();
      }
    } else {
      if (this.newCategory.name || this.newCategory.description) {
        this.showModalNotSaved = true;
        this.nextRoute = to;
        next(false);
      } else {
        next();
      }
    }
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.createinputswrap {
  width: auto;
}
</style>
