import { serverUrl } from '@/api/axios.js';

export const mapServerImage = (img) => ({
  type: 'old',
  id: img.id,
  src: `${serverUrl}${img.name}`.replace('uploads/', 'uploads/thumbs/'),
  is_main: img.is_main,
  name: img.name,
  meta_id: img.meta_id ?? null,
});
