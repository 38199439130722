<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('FertilizerDefaultList:Title') }]" />
      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('FertilizerDefaultList:ButtonAdd')"
          size="standart"
          color="accent"
          icon="plus"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: routeCreate })"
        />
      </div>
    </div>
    <LoaderBlock v-if="isLoading" />
    <template v-else>
      <DefaultProductsTable
        :title="$t('FertilizerDefaultList:TablepH:Title')"
        :products="productsPh"
        :key="1" />
      <DefaultProductsTable
        :title="$t('FertilizerDefaultList:TableEC:Title')"
        :products="productsEc"
        :key="2"
    /></template>
  </article>
</template>

<script>
import routeNames from '@/router/routeNames';
import api from '@/api/nutrition';

import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';
import DefaultProductsTable from './defaultProducts/DefaultProductsTable';
import LoaderBlock from '@/components/blocks/LoaderBlock';

export default {
  name: 'FertilizerDefaultList',
  components: {
    Button,
    Breadcrumps,
    DefaultProductsTable,
    LoaderBlock,
  },
  data() {
    return {
      routeCreate: routeNames.newFertilizerDefault,
      isLoading: false,
      defaultProducts: [],
      productsFertilizer: [],
      manufacturers: [],
      manufacturersCountries: [],
    };
  },
  methods: {
    onEditClick() {},
    mapProduct(p) {
      const productRef = this.productsFertilizer.find(
        ({ id }) => id === p.product_id
      );
      const manufacturer = this.manufacturers.find(
        ({ id }) => id === productRef?.manufacturer_id
      );
      const manufacturersCountry = this.manufacturersCountries.find(
        ({ id }) => id === productRef?.manufacturer_country_id
      );
      return {
        ...p,
        productRef,
        manufacturer: manufacturer?.name ?? '...',
        country: manufacturersCountry?.name ?? '...',
      };
    },
  },
  computed: {
    productsPh() {
      return this.defaultProducts
        .filter((p) => p.condition_id === 0)
        .map(this.mapProduct)
        .filter((p) => p.productRef);
    },
    productsEc() {
      return this.defaultProducts
        .filter((p) => p.condition_id === 1)
        .map(this.mapProduct)
        .filter((p) => p.productRef);
    },
  },
  async created() {
    this.isLoading = true;
    try {
      const res = await api.getDefaultProducts();
      this.defaultProducts = res.defaultProducts;
      this.productsFertilizer = res.productsFertilizer;
      if (res.manufacturers) this.manufacturers = res.manufacturers;
      if (res.manufacturersCountries)
        this.manufacturersCountries = res.manufacturersCountries;
    } catch (error) {
      this.$awn.alert(String(error));
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.table--type2 {
  tr td,
  th:nth-child(2) {
    text-align: center;
  }
}
</style>
