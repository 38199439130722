<template>
  <SystemPage>
    <Login v-if="content === 'login'" />
    <LoginNewPassword v-if="content === 'newPassword'" />
  </SystemPage>
</template>

<script>
import SystemPage from '@/pages/system/SystemPage';
import Login from '@/components/system/Login';
import LoginNewPassword from '@/components/system/LoginNewPassword';

export default {
  name: 'SystemPageWrap',
  components: {
    LoginNewPassword,
    SystemPage,
    Login,
  },
  props: {
    content: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped></style>
