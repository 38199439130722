<!--Translated-->
<template>
  <div class="cwrap">
    <div class="chead el--borders-top el--borders-sides el--rounded-top">
      <div class="chead__line-block">
        <div
          class="vsvg vsvg--standart chead_icon"
          v-svg="{ name: 'edit' }"
        ></div>
        <div class="chead_text">
          {{ $t('MFertilizerProgramDescription:Header') }}
        </div>
      </div>
    </div>

    <div
      class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
    >
      <div class="cblock__internal">
        <div class="cblock__line">
          <div class="cblock__line-block">
            <Input
              type="text"
              :placeholder="
                $t('MFertilizerProgramDescription:ProgramName.Placeholder')
              "
              :label="$t('MFertilizerProgramDescription:ProgramName.Label')"
              size="long"
              v-model="programName"
              :state="inputsState"
            />
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <Input
              type="text"
              :placeholder="
                $t('MFertilizerProgramDescription:Comment.Placeholder')
              "
              :label="$t('MFertilizerProgramDescription:Comment.Label')"
              size="long4x"
              v-model="programDescription"
              :state="inputsState"
            />
          </div>
        </div>

        <div class="cblock__line" v-if="canSeeClients && canEdit">
          <div class="cblock__line-block">
            <DropdownPlusSelect
              :placeholder="
                $t('MFertilizerProgramDescription:Search.Placeholder')
              "
              :label="$t('MFertilizerProgramDescription:Search.Label')"
              size="long4x"
              dd-max-elements="7"
              :ddMultiMode="true"
              :options="clientsFiltered"
              @filter="clientsFilter = $event"
              v-model="selectedClient"
              :disabled="disabled"
            />
            <Button
              text=""
              size="s-standart"
              color="accentlines"
              icon="user"
              :isLoading="clientFetching"
              :loadingLeft="true"
              :disabled="disabled || !program.client_id"
              @click="goToClientPage(program.client_id)"
            />
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <Input
              type="text"
              :placeholder="
                $t('MFertilizerProgramDescription:Code.Placeholder')
              "
              :label="$t('MFertilizerProgramDescription:Code.Label')"
              size="standart"
              v-model="clientCode"
              :state="inputsState"
            />
            <Input
              type="text"
              :placeholder="
                $t('MFertilizerProgramDescription:ClientName.Placeholder')
              "
              :label="$t('MFertilizerProgramDescription:ClientName.Label')"
              size="standart"
              v-model="clientName"
              :state="inputsState"
            />
            <DropdownSelectValue
              type="dropdown"
              :placeholder="
                $t('MFertilizerProgramDescription:ClientType.Placeholder')
              "
              :label="$t('MFertilizerProgramDescription:ClientType.Label')"
              size="standart"
              :options="clientTypes"
              v-model="clientType"
              :disabled="disabled"
              :locked="!canEdit"
            />
            <Input
              type="text"
              placeholder="0"
              :label="$t('MFertilizerProgramDescription:ClientDiscount.Label')"
              :state="disabled ? 'disabled' : 'disabled2'"
              size="standart"
              :value="clientDiscount"
            />
            <Button
              v-if="canSeeClients && !canEdit"
              text=""
              size="s-standart"
              color="accentlines"
              icon="user"
              :isLoading="clientFetching"
              :loadingLeft="true"
              :disabled="disabled || !program.client_id"
              @click="goToClientPage(program.client_id)"
            />
          </div>
        </div>

        <div class="cblock__line" v-if="!placesSelectDisabled && canEdit">
          <div class="cblock__line-block margintop--micro">
            <Button
              :text="
                clientFetching
                  ? this.$t('MFertilizerProgramDescription:Places:Loading')
                  : this.$t('MFertilizerProgramDescription:Places:Select')
              "
              size="standart2x"
              color="accent"
              icon="map"
              @click="showModalChoosePlace = true"
              :disabled="disabled || placesSelectDisabled"
            />
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <LocationSelect
              :geoEntries="geoMapped"
              :freeSelect="true"
              v-model="geoId"
              :disabled="disabled"
              :locked="!canEdit"
            />
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <Input
              type="text"
              :placeholder="
                $t('MFertilizerProgramDescription:Zones.Placeholder')
              "
              :label="$t('MFertilizerProgramDescription:Zones.Label')"
              size="long"
              v-model="zoneName"
              :state="inputsState"
            />

            <InputNumber
              :placeholder="
                $t('MFertilizerProgramDescription:Area.Placeholder')
              "
              :label="$t('MFertilizerProgramDescription:Area.Label')"
              size="standart"
              v-model="placeArea"
              :state="inputsState"
            />
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <CultureSelect
              :categories="cultureCategories"
              :cultures="cultures"
              v-model="cultureId"
              :disabled="disabled"
              :locked="!canEdit"
            />
            <CultureAgeSelect
              v-model="age"
              :disabled="disabled"
              :locked="!canEdit || selectedOneYearCulture"
            />

            <InputNumber
              placeholder="0"
              :label="$t('MFertilizerProgramDescription:PlantsPerHa.Label')"
              size="mini"
              v-model="plantsPerHa"
              :state="inputsState"
            />

            <Input
              type="text"
              placeholder="0"
              :label="$t('MFertilizerProgramDescription:PlantsAll.Label')"
              :state="disabled ? 'disabled' : 'disabled2'"
              size="mini"
              :value="totalPlantsCount"
            />
          </div>
        </div>

        <div class="cblock__line" v-if="sorts.length">
          <div class="cblock__line-block">
            <ChksetSort
              :options="sorts"
              v-model="selectedSorts"
              label="Сорта культуры"
              :disabled="disabled"
            />
          </div>
        </div>
      </div>
    </div>

    <ModalChoosePlace
      v-if="showModalChoosePlace"
      v-model="showModalChoosePlace"
      :clientPlaces="clientPlaces"
      :clientCultures="clientCultures"
      :clientZones="clientZones"
      :cultures="culturesRaw"
      :clientZonesAnalyzes="clientZonesAnalyzes"
      @close="showModalChoosePlace = false"
      @selected="clientPlaceSelected"
      @goToClient="goToClientPage(client.id)"
    />
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapState, mapGetters } from 'vuex';

import { staticData } from '@/store/modules/clients';
import { mutationTypes, actionTypes } from '@/store/modules/nutritionPrograms';
import { mapInputEntityWithMerge } from '@/components/adapters/mapInput';
import routeNames from '@/router/routeNames';

import Input from '@/components/views/Input';
import InputNumber from '@/components/views/wrappers/InputNumberWithCast';
import Button from '@/components/views/Button';
import DropdownPlusSelect from '@/components/views/wrappers/DropdownPlusSelect';
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';
import ChksetSort from '@/components/sets/ChksetSorts';
import LocationSelect from '@/components/views/LocationSelect';
import ModalChoosePlace from '@/components/modals/programs/ModalChoosePlace';
import CultureSelect from '@/components/views/CultureSelect';
import CultureAgeSelect from '@/components/views/CultureAgeSelect';

export default {
  name: 'ProgramDescription',
  components: {
    Input,
    InputNumber,
    Button,
    ChksetSort,
    DropdownPlusSelect,
    DropdownSelectValue,
    LocationSelect,
    ModalChoosePlace,
    CultureSelect,
    CultureAgeSelect,
  },
  data() {
    return {
      clientsFilter: '',
      clientsFiltered: [],
      selectedClient: null,
      ignoreNextReset: false,
      showModalChoosePlace: false,
      clientAutofillComplete: false,
    };
  },
  computed: {
    ...mapGetters(['geoMapped', 'geoEntities']),
    ...mapState({
      canEdit: (state) =>
        state.auth.currentUserRole?.claim_plants_nutrition_add === 1,
      canSeeClients: (state) =>
        state.auth.currentUserRole?.claim_clients_see === 1,
      isLoading: (state) => state.nutrition.isLoading,
      isSaving: (state) => state.nutrition.isSaving,
      hasPhases: (state) => Boolean(state.nutrition.programPhases.length),
      program: (state) => state.nutrition.program,
      clientsRaw: (state) =>
        state.clientsList.clients.map((c) =>
          Object.freeze({
            ...c,
            value: c.id,
            text: c.name,
            text1: c.name,
            text2: c.code,
            text3: staticData.clientType[c.type_id],
            searchString: [c.name, c.code].join(' ').toLowerCase(),
          })
        ),
      clientPlaces: (state) => state.nutrition.clientPlaces,
      clientCultures: (state) => state.nutrition.clientCultures,
      clientZones: (state) => state.nutrition.clientPlacesZones,
      culturesRaw: (state) => state.nutrition.cultures,
      clientZonesAnalyzes: (state) =>
        state.nutrition.clientZonesAnalyzes.filter(({ id }) =>
          state.nutrition.clientAnalyzesElements.some(
            (e) => e.analyse_id === id
          )
        ),
      elements: (state) => state.nutrition.elements,
      clientAnalyzesElements: (state) => state.nutrition.clientAnalyzesElements,
      clientFetching: (state) => state.nutrition.isClientFetching,
      client: (state) => state.nutrition.client,
      cultureCategories: (state) =>
        state.nutrition.categoriesCultures.map(mapInputEntityWithMerge),
      cultures: (state) =>
        state.nutrition.cultures.map(mapInputEntityWithMerge),
      sorts(state) {
        return state.nutrition.culturesSorts
          .filter((sort) => sort.culture_id === this.cultureId)
          .map((sort) => ({
            ...sort,
            text: sort.name,
            value: sort.id,
          }));
      },
      selectedSortsGet: (state) =>
        state.nutrition.programCulturesSorts.map((s) => s.sort_id),
    }),
    autoClientId() {
      if (!this.canSeeClients || this.isEditing) return null;
      const id = Number(this.$route.query.forClient);
      return Number.isInteger(id) ? id : null;
    },
    clients() {
      return this.autoClientId
        ? [...this.clientsRaw].sort(({ id }) =>
            id === this.autoClientId ? -1 : 0
          )
        : this.clientsRaw;
    },
    inputsState() {
      return this.disabled ? 'disabled' : !this.canEdit ? 'disabled2' : '';
    },
    selectedOneYearCulture() {
      return Boolean(
        this.culturesRaw.find((c) => c.id === this.cultureId)?.only_one_year
      );
    },
    clientDiscount: {
      get() {
        return this.program.client_discount;
      },
      set(value) {
        this.$store.commit(mutationTypes.setClientDiscount, value);
      },
    },
    selectedSorts: {
      get() {
        return this.selectedSortsGet;
      },
      set(value) {
        this.$store.commit(mutationTypes.setCultureSorts, value);
      },
    },
    programName: {
      get() {
        return this.program.name;
      },
      set(name) {
        this.$store.commit(mutationTypes.setProgramName, name);
      },
    },
    programDescription: {
      get() {
        return this.program.description;
      },
      set(value) {
        this.$store.commit(mutationTypes.setProgramDescription, value);
      },
    },
    clientName: {
      get() {
        return this.program.client_name;
      },
      set(name) {
        this.$store.commit(mutationTypes.setClientName, name);
      },
    },
    clientCode: {
      get() {
        return this.program.client_code;
      },
      set(code) {
        this.$store.commit(mutationTypes.setClientCode, code);
      },
    },
    clientTypes() {
      return staticData.clientType.map((text, value) => ({ text, value }));
    },
    clientType: {
      get() {
        return this.program.client_type;
      },
      set(type) {
        this.$store.commit(mutationTypes.setClientType, type);
      },
    },
    geoId: {
      get() {
        return this.program.geo_id;
      },
      set(id) {
        this.$store.commit(mutationTypes.setProgramGeo, id);
      },
    },
    zoneName: {
      get() {
        return this.program.zone_name;
      },
      set(name) {
        this.$store.commit(mutationTypes.setZoneName, name);
      },
    },
    placeArea: {
      get() {
        return this.program.place_area;
      },
      set(area) {
        this.$store.commit(mutationTypes.setPlaceArea, area);
      },
    },
    cultureId: {
      get() {
        return this.program.culture_id;
      },
      set(id) {
        this.$store.commit(mutationTypes.setCultureId, id);
      },
    },
    age: {
      get() {
        return this.program.culture_age ?? null;
      },
      set(age) {
        this.$store.commit(mutationTypes.setAge, age);
      },
    },
    plantsPerHa: {
      get() {
        return this.program.count_plants_per_ha;
      },
      set(value) {
        this.$store.commit(mutationTypes.setPlantsPerHa, value);
      },
    },
    totalPlantsCount() {
      return this.plantsPerHa === null || this.placeArea === null
        ? ''
        : this.plantsPerHa * this.placeArea;
    },
    existingClientSelected() {
      return Boolean(this.client);
    },
    placesSelectDisabled() {
      return this.isEditing
        ? true
        : this.existingClientSelected
        ? !this.clientPlaces.some((p) => p.geo_id)
        : true;
    },
    disabled() {
      return this.isLoading || this.isSaving;
    },
    isEditing() {
      return this.$route.name === routeNames.editDefenseProgram;
    },
  },
  methods: {
    updateClients: debounce(
      function () {
        const query = this.clientsFilter.toLowerCase().trim();
        const predicate =
          query === ''
            ? () => true
            : ({ searchString }) => searchString.includes(query);
        this.clientsFiltered = this.clients.filter(predicate).slice(0, 50);
      },
      100,
      { leading: true }
    ),
    validateClient() {
      if (this.selectedClient === null) return;
      const nameMatch = this.clientName === (this.selectedClient?.name ?? null);
      const codeMatch = this.clientCode === (this.selectedClient?.code ?? null);
      const typeMatch =
        this.clientType === (this.selectedClient?.type_id ?? null);
      if (!nameMatch || !codeMatch || !typeMatch) {
        this.ignoreNextReset = true;
        this.$nextTick(() => (this.ignoreNextReset = false));
        this.selectedClient = null;
        this.$store.commit(mutationTypes.setClientDiscount, null);
        this.$store.commit(mutationTypes.setClientId, null);
      }
    },
    clientPlaceSelected(place) {
      this.$store.commit(mutationTypes.setProgramGeo, place.geoId);
      this.$store.commit(mutationTypes.setZoneName, place.zone?.name ?? '');
      this.$store.commit(mutationTypes.setCultureId, place.cultureId);
      this.$store.commit(mutationTypes.setPlaceArea, place.area ?? null);
      this.$store.commit(mutationTypes.setAge, place.age ?? null);
      this.$store.commit(
        mutationTypes.setPlantsPerHa,
        place.plantsPerHa ?? null
      );

      const [analysis] = this.clientZonesAnalyzes
        .filter((a) => a.zone_id === place?.zone?.id)
        .sort((a, b) => b.date - a.date);

      const elements = this.clientAnalyzesElements.filter(
        ({ analyse_id }) => analyse_id === analysis?.id
      );

      for (const element of this.elements) {
        const elementData = elements.find(
          ({ element_id, depth }) => element_id === element.id && depth === 0
        ) ?? {
          element_id: element.id,
          depth: 0,
          quantity: null,
        };

        this.$store.commit(mutationTypes.upsertAnalysisElement, elementData);
      }
    },
    goToClientPage(id) {
      const routeData = this.$router.resolve({
        name: routeNames.editClient,
        params: { id },
      });
      window.open(routeData.href, '_blank');
    },
  },
  watch: {
    clients: {
      handler(clients) {
        this.updateClients();

        setTimeout(() => {
          if (clients?.length && !this.clientAutofillComplete) {
            const client = this.clients.find(
              ({ id }) => id === this.autoClientId
            );
            if (client) {
              this.selectedClient = client;
              this.clientAutofillComplete = true;
            }
          }
        }, 101);
      },
      immediate: true,
    },
    clientsFilter: {
      handler() {
        this.updateClients();
      },
      immediate: true,
    },
    selectedClient(client) {
      if (client?.id) {
        this.$store.dispatch(actionTypes.fetchClient, client.id);
      } else {
        this.$store.commit(mutationTypes.resetClient);
      }
      if (this.ignoreNextReset) return;
      this.$store.commit(mutationTypes.setClientName, client?.name ?? null);
      this.$store.commit(mutationTypes.setClientCode, client?.code ?? null);
      this.$store.commit(mutationTypes.setClientType, client?.type_id ?? null);
      this.$store.commit(
        mutationTypes.setClientDiscount,
        client?.discount ?? 0
      );
      this.$store.commit(mutationTypes.setClientId, client?.id ?? null);
      this.validateClient();
    },
    clientName() {
      this.validateClient();
    },
    clientCode() {
      this.validateClient();
    },
    clientType() {
      this.validateClient();
    },
    plantsPerHa() {
      this.$store.commit(mutationTypes.updatePlantsPerHaDeps);
    },
    selectedOneYearCulture: {
      handler(val) {
        if (val) this.age = 1;
      },
      immediate: true,
    },
  },
};
</script>
