<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('AdminInstructions:Title') }]" />
    </div>

    <div class="rightzone_table">
      <Table
        :headers="tableHeaders"
        :data-arr="dataArr"
        :table-loading="isTableLoading"
        :loading-count-items="10"
        @editClick="onEditClick"
      />
    </div>
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import Input from '@/components/views/Input';
import Button from '@/components/views/Button';
import Table from '@/components/tables/Table';
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';
import { mutationTypes } from '@/store/modules/filtersState';
import routeNames from '@/router/routeNames';

export default {
  name: 'AdminArticles',
  components: {
    DropdownSelectValue,
    Input,
    Button,
    Breadcrumps,
    Table,
  },
  data() {
    return {
      filter: {
        name: '',
        category: null,
        type: null,
      },
    };
  },
  computed: {
    articlesTypes() {
      return this.$store.state.articles.articlesTypes.map((v) => {
        return { text: v.name, value: v.id };
      });
    },
    tableHeaders() {
      return [
        {
          text: this.$t('AdminArticles:Filter:TableHeaders:0'),
          width: '100%',
          type: 'text',
        },
        {
          text: '',
          class: 'col-edit',
          width: '',
          type: 'edit',
          icon: 'edit',
        },
      ];
    },
    dataArr() {
      let arr = [];
      arr.push([
        { text: this.$t('UserAnalyzes:TableData:AnalyseType:1') }, //Воды
        { text: 1 },
      ]);
      arr.push([
        { text: this.$t('UserAnalyzes:TableData:AnalyseType:3') }, //Продукта
        { text: 3 },
      ]);
      arr.push([
        { text: this.$t('UserAnalyzes:TableData:AnalyseType:0') }, //Почвы
        { text: 0 },
      ]);
      arr.push([
        { text: this.$t('UserAnalyzes:TableData:AnalyseType:2') }, //Листа
        { text: 2 },
      ]);

      return arr;
    },
    isTableLoading() {
      return false;
    },
  },
  methods: {
    onEditClick(typeId) {
      this.$router.push({
        name: routeNames.editInstruction,
        params: { id: typeId },
      });
    },
  },
};
</script>

<style scoped></style>
