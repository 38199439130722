<template>
  <article class="rightzone rightzone--userarticleslist">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />
    </div>

    <div class="articleslist">
      <div class=" ">
        <div class="articleslist_line">
          <p>Системы мониторинга влажности почвы</p>
        </div>
        <div class="articleslist_line">
          <p>Метеостанции</p>
        </div>
        <div class="articleslist_line">
          <p>Типы сенсоров для контроля pH и EC поливочной воды</p>
        </div>
        <div class="articleslist_line">
          <p>Автоматические системы контроля</p>
        </div>
      </div>
    </div>

    <UserFooter />
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import UserFooter from '@/pages/userproject/userComponents/UserFooter';
import routeNames from "@/router/routeNames";

export default {
  name: 'UserArticlesList4',
  components: {
    UserFooter,
    Breadcrumps,
  },
  computed: {
    breadcrumpsArr() {
      return [
        { title: 'Статьи', route: routeNames.userHomePage },
        { title:  'Современные технологии & Другое'},
      ];
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/vars.scss';
</style>
