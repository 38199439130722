import { render, staticRenderFns } from "./ClientItemHeader.vue?vue&type=template&id=84b21614&scoped=true&"
import script from "./ClientItemHeader.vue?vue&type=script&lang=js&"
export * from "./ClientItemHeader.vue?vue&type=script&lang=js&"
import style0 from "./ClientItemHeader.vue?vue&type=style&index=0&id=84b21614&prod&lang=scss&"
import style1 from "./ClientItemHeader.vue?vue&type=style&index=1&id=84b21614&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84b21614",
  null
  
)

export default component.exports