<!--Translated-->
<template>
  <DropdownSelectValue
    :placeholder="$t('CultureAgeSelect:Select.Placeholder')"
    :label="$t('CultureAgeSelect:Select.Label')"
    :options="ages"
    v-model="ageInput"
    :disabled="disabled"
    :locked="locked"
  />
</template>

<script>
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';

export default {
  name: 'CultureAgeSelect',
  components: { DropdownSelectValue },
  props: ['value', 'disabled', 'locked'],
  computed: {
    ages() {
      return [...Array(11)].map((_, age) => ({
        text: age >= 10 ? '11+' : age + 1,
        value: age + 1,
      }));
    },
    ageInput: {
      get() {
        return this.value;
      },
      set(age) {
        this.$emit('input', age);
      },
    },
  },
};
</script>
