<!--Translated-->
<template>
  <div class="cwrap--level3">
    <ClientItemHeader
      class="level3 chead el--borders-sides"
      iconName="map"
      :text="`${$t('ClientZone:Header:Text1')} -  ${
        name || $t('ClientZone:Header:Text2')
      }`"
      :id="zone.id"
      type="zone"
    />

    <div
      v-show="isShown"
      class="cblock el--borders-all"
      :class="{ 'el--rounded-bottom': isLast }"
    >
      <div class="cblock__internal">
        <div class="cblock__line">
          <div class="cblock__line-block">
            <Input
              type="text"
              :placeholder="$t('ClientZone:ZoneName.Placeholder')"
              :label="$t('ClientZone:ZoneName.Label')"
              size="max"
              v-model="name"
            />
            <Input
              type="text"
              :placeholder="$t('ClientZone:ZomeDescription.Placeholder')"
              :label="$t('ClientZone:ZomeDescription.Label')"
              size="max"
              v-model="comment"
            />
          </div>
        </div>

        <div v-if="latestAnalysis">
          <ClientZoneAnalysis
            :analysis="latestAnalysis"
            :locked="true"
            @tableClicked="modalShown = true"
          />
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <Button
              :text="$t('ClientZone:ButtonAddAnalyse')"
              size="wide"
              color="accentlines"
              icon="plus"
              @click="addAnalysis"
            />
            <Button
              v-if="latestAnalysis"
              :text="$t('ClientZone:ButtonHistoryAnalyse')"
              size="wide"
              color="graylines"
              icon="info"
              @click="modalShown = true"
            />
          </div>
          <div class="cblock__line-block flex-end">
            <Button
              :text="$t('ClientZone:ButtonDeleteZone')"
              size="standart"
              color="alertlines"
              icon="delete"
              :is-loading="false"
              :loading-left="true"
              @click="deleteZone"
              :disabled="count < 2"
            />
          </div>
        </div>
      </div>
    </div>
    <Modal v-if="modalShown" @backgroundClose="modalShown = false">
      <ClientAnalyzesModal @close="modalShown = false" :zoneId="zone.id" />
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { confirmDelete } from '@/pages/productsclients/confirmDelete';
import { mutationTypes } from '@/store/modules/clientsArea/clients';

import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import ClientItemHeader from './ClientItemHeader';
import ClientZoneAnalysis from './ClientZoneAnalysis';
import ClientAnalyzesModal from './ClientAnalyzesModal';
import Modal from '@/pages/Modal';

export default {
  name: 'ClientZone',
  components: {
    Button,
    Input,
    ClientItemHeader,
    ClientZoneAnalysis,
    ClientAnalyzesModal,
    Modal,
  },
  props: {
    zone: { type: Object, required: true },
    count: { type: Number, required: true },
  },
  data() {
    return {
      modalShown: false,
    };
  },
  computed: {
    ...mapGetters(['client2DataHierarchy']),
    ...mapState({
      isShown(state) {
        return state.clients2.expandedHeadersStatuses[`zone_${this.zone.id}`];
      },
      isLast() {
        const thisItem = this.client2DataHierarchy.find(
          ({ id }) => id === this.zone.id
        );
        const nextItem = this.client2DataHierarchy.find(
          ({ order }) => order === thisItem.order + 1
        );
        return nextItem === undefined || nextItem.type === 'culture';
      },
      elements: (state) => state.clients2.elements,
      latestAnalysis(state) {
        return state.clients2.clientZonesAnalyzes
          .filter((e) => e.zone_id === this.zone.id)
          .sort((a, b) => b.date - a.date)[0];
      },
    }),

    name: {
      get() {
        return this.zone.name;
      },
      set(name) {
        this.$store.commit(mutationTypes.setZoneName, {
          id: this.zone.id,
          name,
        });
      },
    },
    comment: {
      get() {
        return this.zone.comment;
      },
      set(comment) {
        this.$store.commit(mutationTypes.setZoneComment, {
          id: this.zone.id,
          comment,
        });
      },
    },
  },
  methods: {
    deleteZone() {
      confirmDelete.call(
        this,
        this.$t('ClientZone:ModalConfirmDelete'),
        mutationTypes.deleteZone,
        this.zone.id
      );
    },
    addAnalysis() {
      this.$store.commit(mutationTypes.addBlankAnalysis, this.zone.id);
      this.modalShown = true;
    },
  },
};
</script>
