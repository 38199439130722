<!-- Translated -->
<template>
  <div
    @click="onClick"
    style="cursor: pointer"
    ref="header"
    v-show="!isHidden"
    :class="{
      'last-visible-header': isLastVisible,
      'is-expanded': isShown,
      'chead--collapsedgreen': !isShown,
    }"
    :data-item-id="id"
  >
    <div class="chead__line-block">
      <div
        v-if="iconName"
        class="vsvg vsvg--wide chead_icon"
        v-svg="{ name: iconName }"
      />
      <div class="chead_text">
        {{ text }}
      </div>
    </div>

    <div class="chead__line-block">
      <div
        class="vsvg vsvg--wide chead_chevron"
        v-svg="{ name: isShown ? 'arrowup' : 'arrowdown' }"
        :key="isShown"
      ></div>
    </div>
  </div>
</template>

<script>
import { mutationTypes } from '@/store/modules/clients';
import { mapGetters } from 'vuex';

export default {
  name: 'ClientItemHeader',
  props: {
    id: { type: [Number, String], required: true },
    iconName: { type: String },
    text: { type: String, default: '' },
    type: { type: String, required: true },
  },
  computed: {
    ...mapGetters(['clientDataHierarchy', 'clientHiddenHeaders']),
    entry() {
      return this.clientDataHierarchy.find((e) => e.id === this.id);
    },
    prefixedId() {
      return `${this.type}_${this.id}`;
    },
    isHidden() {
      return this.clientHiddenHeaders.includes(this.prefixedId);
    },
    isShown: {
      get() {
        return this.$store.state.clients.expandedHeadersStatuses[
          this.prefixedId
        ];
      },
      set(value) {
        this.$store.commit(mutationTypes.changeItemExpandedState, {
          id: this.id,
          type: this.type,
          value,
          getterRef: this.clientDataHierarchy,
        });
      },
    },
    isLastVisible() {
      const nextItem = this.clientDataHierarchy.find(
        ({ id, order, type }) =>
          order > this.entry.order &&
          !this.clientHiddenHeaders.includes(`${type}_${id}`)
      );
      return (
        !this.isHidden &&
        (nextItem === undefined || nextItem.type === 'culture')
      );
    },
  },
  methods: {
    onClick() {
      const { header } = this.$refs;
      const isPinned = header.classList.contains('is-pinned');
      let isShown = isPinned || !this.isShown;

      const el = header.parentElement.querySelector('.cblock');
      if (el) {
        const y = Math.floor(el.getBoundingClientRect().y);
        const level = Number(
          [...header.classList]
            .find((c) => c.startsWith('level'))
            .replace('level', '')
        );
        const { offsetHeight } = header;
        if (
          offsetHeight * level >= Math.round(y + level * 0.5) - 2 &&
          offsetHeight * level <= Math.round(y + level * 0.5) + 2
        )
          isShown = !this.isShown;
      }

      if (isShown) {
        setTimeout(() => {
          el?.scrollIntoView({
            behavior: 'smooth',
            block:
              el.offsetHeight > window.innerHeight * 0.7 ? 'start' : 'nearest',
            inline: 'start',
          });
        }, 50);
      }
      this.isShown = isShown;
    },
    setStyle() {
      if (this.entry && this.$refs.header) {
        this.$refs.header.style.zIndex = `${500 + this.entry.order}`;
      }
    },
  },
  watch: {
    entry() {
      this.setStyle();
    },
  },
  mounted() {
    this.setStyle();
  },
};
</script>

<style lang="scss">
@import '~@/scss/vars.scss';

@for $i from 1 through 3 {
  .cwrap--level#{$i} .cblock {
    scroll-margin-top: calc(#{($i)} * #{$heightMedium} - #{($i)} * 0.5px);
  }
}

@media screen and (max-width: 1800px) {
  @for $i from 1 through 3 {
    .cwrap--level#{$i} .cblock {
      scroll-margin-top: calc(
        #{($i)} * #{$heightMedium-k1600} - #{($i)} * 0.5px
      );
    }
  }
}

@media screen and (max-width: 1420px) {
  @for $i from 1 through 3 {
    .cwrap--level#{$i} .cblock {
      scroll-margin-top: calc(
        #{($i)} * #{$heightMedium-k1360} - #{($i)} * 0.5px
      );
    }
  }
}
</style>

<style lang="scss" scoped>
.is-pinned {
  border-top-left-radius: initial;
  border-top-right-radius: initial;
}
</style>

<style lang="scss">
@import '~@/scss/vars.scss';

.last-visible-header:not(.is-expanded) {
  border-bottom-right-radius: $borderRadiusStandart;
  border-bottom-left-radius: $borderRadiusStandart;
}

@media screen and (max-width: 1800px) {
  .last-visible-header:not(.is-expanded) {
    border-bottom-right-radius: $borderRadiusStandart-k1600;
    border-bottom-left-radius: $borderRadiusStandart-k1600;
  }
}

@media screen and (max-width: 1420px) {
  .last-visible-header:not(.is-expanded) {
    border-bottom-right-radius: $borderRadiusStandart-k1360;
    border-bottom-left-radius: $borderRadiusStandart-k1360;
  }
}
</style>
