import Vue from 'vue';
import { nanoid } from 'nanoid';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import api from '@/api/clientsArea/clients';
import { mutationTypes as geobaseMutationTypes } from '../glossaryGeobase';
import i18n from '@/i18n';
// import { diff } from 'deep-object-diff';

//translated

const clientEntitiesKeys = [
  'clientCultures',
  'clientPlaces',
  'clientPlacesZones',
  'clientPlacesCodes',
  'clientZonesAnalyzes',
  'clientAnalyzesElements',
  'clientAnalyzesFiles',
];

const createState = () => ({
  isLoading: false,
  isSubmiting: false,
  error: null,
  categoryCultures: [],
  cultures: [],
  contactTypes: [],
  elements: [],
  serverData: null,
  lastSaveTime: null,
  lastAddedItemId: null,
  expandedHeadersStatuses: {},
  ...Object.fromEntries(clientEntitiesKeys.map((key) => [key, []])),
});

const state = createState();

const getters = {
  client2CanSave(state) {
    if (state.isLoading || state.isSubmiting) return false;
    return !isEqual(
      Object.fromEntries(clientEntitiesKeys.map((key) => [key, state[key]])),
      state.serverData
    );
  },
  client2DataHierarchy(state) {
    const result = [];
    for (const culture of state.clientCultures) {
      const childrenOfCulture = [];
      result.push({
        id: culture.id,
        type: 'culture',
        children: childrenOfCulture,
      });

      const places = state.clientPlaces.filter(
        (p) => p.client_culture_id === culture.id
      );
      for (const place of places) {
        childrenOfCulture.push(`place_${place.id}`);
        const childrenOfPlace = [];
        result.push({ id: place.id, type: 'place', children: childrenOfPlace });

        const zones = state.clientPlacesZones.filter(
          (z) => z.place_id === place.id
        );
        for (const zone of zones) {
          childrenOfCulture.push(`zone_${zone.id}`);
          childrenOfPlace.push(`zone_${zone.id}`);
          result.push({ id: zone.id, type: 'zone' });
        }
      }
    }
    return result.map((e, index) => ({ ...e, order: index + 1 }));
  },
  client2HiddenHeaders(state, getters) {
    const result = new Set();
    for (const entry of getters.client2DataHierarchy) {
      if (
        !state.expandedHeadersStatuses[`${entry.type}_${entry.id}`] &&
        entry.children
      ) {
        entry.children.forEach((id) => result.add(id));
      }
    }
    return [...result];
  },
  client2HasEmptyCultures(state) {
    return state.clientCultures.some((c) => c.culture_id === null);
  },
};

const staticData = {
  clientType: [
    i18n.t('Vuex:clients:ClietTypes:0'),
    i18n.t('Vuex:clients:ClietTypes:1'),
  ], //['Пользователь', 'Дилер'],
  clientStatus: [
    i18n.t('Vuex:clients:ClietStatus:0'),
    i18n.t('Vuex:clients:ClietStatus:1'),
  ], //['Юридический', 'Физический'],
  irrigationSystemType: [
    i18n.t('Vuex:clients:IrrigationSystemType:0'),
    i18n.t('Vuex:clients:IrrigationSystemType:1'),
    i18n.t('Vuex:clients:IrrigationSystemType:2'),
    i18n.t('Vuex:clients:IrrigationSystemType:3'),
    i18n.t('Vuex:clients:IrrigationSystemType:4'),
    i18n.t('Vuex:clients:IrrigationSystemType:5'),
    i18n.t('Vuex:clients:IrrigationSystemType:6'),
  ] /*[
    'Барабан',
    'Пивот',
    'Спринклер',
    'Капелная',
    'Соматек',
    'Нерошаемый',
    'Неизвестно',
  ],*/,
};

const mutationTypes = {
  setDefaultState: '[clients 2] set default state',

  clientDataFetchingStart: '[clients 2] client data fetching start',
  clientDataFetchingSuccess: '[clients 2] client data fetching success',
  clientDataFetchingFailure: '[clients 2] client data fetching failure',

  clientSubmitStart: '[clients 2] submit start',
  clientSubmitSuccess: '[clients 2] submit success',
  clientSubmitFailure: '[clients 2] submit failure',

  addBlankCulture: '[clients 2] add blank culture',
  deleteCulture: '[clients 2] delete culture',
  setCultureId: '[clients 2] change culture id',

  addBlankPlace: '[clients 2] add blank place',
  deletePlace: '[clients 2] delete place',
  setPlaceGeoId: '[clients 2] set place geo id',
  setPlaceName: '[clients 2] set place name',
  setPlaceArea: '[clients 2] set place area',
  setPlacePlantsPerHa: '[clients 2] set place plants per ha',
  setPlaceYearOfBoarding: '[clients 2] set place year of boarding',
  setPlaceIrrigationSystemType: '[clients 2] set place irrigation system type',
  setPlaceIrrigationSystemDate: '[clients 2] set place irrigation system dates',
  addBlankPlaceCode: '[clients 2] add blank place code',
  removePlaceCode: '[clients 2] remove place code',
  setPlaceCode: '[clients 2] set place code',

  addBlankZone: '[clients 2] add blank zone',
  deleteZone: '[clients 2] delete zone',
  setZoneName: '[clients 2] set zone name',
  setZoneComment: '[clients 2] set zone comment',

  addBlankAnalysis: '[clients 2] add blank analysis',
  removeAnalysis: '[clients 2] delete analysis',
  setAnalysisElement: '[clients 2] set analysis element',
  setAnalysisDate: '[clients 2] set analysis date',
  setAnalysisComment: '[clients 2] set analysis comment',
  removeAnalysisElement: '[clients 2] remove analysis element',

  addFile: '[clients 2] add file',
  removeFile: '[clients 2] remove file',

  setLastSaveTime: '[clients 2] set last save time',

  changeItemExpandedState: '[clients 2] change item expanded state',

  garbageCollect: '[clients 2] garbage collect',
};

const actionTypes = {
  getClientData: '[clients 2] get client data',
  submitClientData: '[clients 2] submit client data',
};

const mutations = {
  [mutationTypes.setDefaultState](state) {
    Object.assign(state, createState());
  },

  [mutationTypes.clientDataFetchingStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.clientDataFetchingFailure](state, error) {
    state.isLoading = false;
    state.error = error;
  },
  [mutationTypes.clientDataFetchingSuccess](state, payload) {
    state.serverData = cloneDeep(
      Object.fromEntries(clientEntitiesKeys.map((key) => [key, state[key]]))
    );
    Object.keys(payload).forEach((key) => {
      if (state[key] !== undefined) {
        state[key] = payload[key];
        if (clientEntitiesKeys.includes(key)) {
          state.serverData[key] = cloneDeep(payload[key]);
        }
      }
    });
    state.isLoading = false;
    state.error = null;
  },

  [mutationTypes.clientSubmitStart](state) {
    state.isSubmiting = true;
    state.error = null;
  },
  [mutationTypes.clientSubmitFailure](state, error) {
    state.isSubmiting = false;
    state.error = error;
  },
  [mutationTypes.clientSubmitSuccess](state) {
    state.serverData = cloneDeep(
      Object.fromEntries(clientEntitiesKeys.map((key) => [key, state[key]]))
    );
    state.isSubmiting = false;
    state.error = null;
  },

  [mutationTypes.addBlankCulture](state, highlight = true) {
    const id = nanoid();
    state.clientCultures.push({ culture_id: null, id });
    Vue.set(state.expandedHeadersStatuses, `culture_${id}`, true);
    if (highlight) state.lastAddedItemId = id;
  },
  [mutationTypes.deleteCulture](state, payload) {
    state.clientCultures = state.clientCultures.filter(
      (culture) => culture !== payload
    );
  },
  [mutationTypes.setCultureId](state, { id, cultureId }) {
    const culture = state.clientCultures.find((e) => e.id === id);
    culture.culture_id = cultureId;
  },

  [mutationTypes.addBlankPlace](state, { cultureId, highlight }) {
    const id = nanoid();
    state.clientPlaces.push({
      id,
      geo_id: null,
      name: '',
      area: null,
      client_culture_id: cultureId,
      count_plants_per_ha: null,
      irrigation_system_type_id: null,
      irrigation_system_date: null,
      year_of_boarding: null,
    });
    state.clientPlacesCodes.push({ place_id: id, code: '' });
    Vue.set(state.expandedHeadersStatuses, `place_${id}`, true);
    if (highlight) {
      state.lastAddedItemId = id;
    }
  },
  [mutationTypes.deletePlace](state, payload) {
    state.clientPlaces = state.clientPlaces.filter(
      (place) => place !== payload
    );
  },
  [mutationTypes.setPlaceGeoId](state, { id, geoId }) {
    const place = state.clientPlaces.find((e) => e.id === id);
    place.geo_id = geoId;
  },
  [mutationTypes.setPlaceName](state, { id, name }) {
    const place = state.clientPlaces.find((e) => e.id === id);
    place.name = name;
  },
  [mutationTypes.setPlaceArea](state, { id, area }) {
    const place = state.clientPlaces.find((e) => e.id === id);
    place.area = area;
  },
  [mutationTypes.setPlacePlantsPerHa](state, { id, value }) {
    const place = state.clientPlaces.find((e) => e.id === id);
    place.count_plants_per_ha = value;
  },
  [mutationTypes.setPlaceYearOfBoarding](state, { id, year }) {
    const place = state.clientPlaces.find((e) => e.id === id);
    place.year_of_boarding = year;
  },
  [mutationTypes.setPlaceIrrigationSystemType](state, { id, typeId }) {
    const place = state.clientPlaces.find((e) => e.id === id);
    place.irrigation_system_type_id = typeId;
  },
  [mutationTypes.setPlaceIrrigationSystemDate](state, { id, date }) {
    const place = state.clientPlaces.find((e) => e.id === id);
    place.irrigation_system_date = date;
  },
  [mutationTypes.addBlankPlaceCode](state, payload) {
    state.clientPlacesCodes.push({ place_id: payload, code: '' });
  },
  [mutationTypes.removePlaceCode](state, { placeId, index }) {
    const item = state.clientPlacesCodes.filter((e) => e.place_id === placeId)[
      index
    ];
    state.clientPlacesCodes = state.clientPlacesCodes.filter((e) => e !== item);
  },
  [mutationTypes.setPlaceCode](state, { placeId, index, value }) {
    state.clientPlacesCodes.filter((e) => e.place_id === placeId)[index].code =
      value;
  },

  [mutationTypes.addBlankZone](state, { placeId, highlight }) {
    const id = nanoid();
    state.clientPlacesZones.push({
      place_id: placeId,
      id,
      name: '',
      comment: '',
    });
    Vue.set(state.expandedHeadersStatuses, `zone_${id}`, true);
    if (highlight) {
      state.lastAddedItemId = id;
    }
  },
  [mutationTypes.deleteZone](state, payload) {
    state.clientPlacesZones = state.clientPlacesZones.filter(
      (zone) => zone.id !== payload
    );
  },
  [mutationTypes.setZoneName](state, { id, name }) {
    const zone = state.clientPlacesZones.find((e) => e.id === id);
    zone.name = name;
  },
  [mutationTypes.setZoneComment](state, { id, comment }) {
    const zone = state.clientPlacesZones.find((e) => e.id === id);
    zone.comment = comment;
  },

  [mutationTypes.addBlankAnalysis](state, payload) {
    const id = nanoid();
    state.clientZonesAnalyzes.push({
      id,
      zone_id: payload,
      comment: '',
      date: null,
    });
    state.lastAddedItemId = `analysis_${id}`;
  },
  [mutationTypes.removeAnalysis](state, payload) {
    state.clientZonesAnalyzes = state.clientZonesAnalyzes.filter(
      (e) => e.id !== payload
    );
  },
  [mutationTypes.setAnalysisElement](
    state,
    { analyseId, elementId, depth, quantity, units_id }
  ) {
    if (quantity !== undefined) {
      const element = state.clientAnalyzesElements.find(
        (e) =>
          e.analyse_id === analyseId &&
          e.element_id === elementId &&
          e.depth === depth
      );
      if (element) {
        element.quantity = quantity;
      } else {
        state.clientAnalyzesElements.push({
          analyse_id: analyseId,
          element_id: elementId,
          depth,
          quantity,
          units_id: 1,
        });
      }
    }

    if (units_id !== undefined) {
      [0, 1].forEach((depth) => {
        const element = state.clientAnalyzesElements.find(
          (e) =>
            e.analyse_id === analyseId &&
            e.element_id === elementId &&
            e.depth === depth
        );
        if (element) {
          element.units_id = units_id;
        } else {
          state.clientAnalyzesElements.push({
            analyse_id: analyseId,
            element_id: elementId,
            depth,
            units_id,
            quantity: null,
          });
        }
      });
    }
  },
  [mutationTypes.setAnalysisDate](state, { id, date }) {
    const analysis = state.clientZonesAnalyzes.find((e) => e.id === id);
    analysis.date = date;
  },
  [mutationTypes.setAnalysisComment](state, { id, comment }) {
    const analysis = state.clientZonesAnalyzes.find((e) => e.id === id);
    analysis.comment = comment;
  },
  [mutationTypes.removeAnalysisElement](
    state,
    { analyseId, elementId, depth }
  ) {
    state.clientAnalyzesElements = state.clientAnalyzesElements.filter(
      (e) =>
        !(
          e.analyse_id == analyseId &&
          e.element_id === elementId &&
          e.depth === depth
        )
    );
  },

  [mutationTypes.addFile](state, { file, analysisId }) {
    state.clientAnalyzesFiles.push({
      file,
      id: nanoid(),
      name: file.name,
      analyse_id: analysisId,
      date_create: new Date().getTime(),
    });
  },
  [mutationTypes.removeFile](state, payload) {
    state.clientAnalyzesFiles = state.clientAnalyzesFiles.filter(
      ({ id }) => id !== payload
    );
  },

  [mutationTypes.setLastSaveTime](state, payload) {
    state.lastSaveTime = payload;
  },

  [mutationTypes.changeItemExpandedState](
    state,
    { id, type, value, getterRef, openChildren }
  ) {
    const item = getterRef.find((e) => e.id === id && e.type === type);
    Vue.set(state.expandedHeadersStatuses, `${item.type}_${id}`, value);
    if (!value || (value && openChildren)) {
      item?.children?.forEach((childId) =>
        Vue.set(state.expandedHeadersStatuses, childId, value)
      );
    }
  },

  [mutationTypes.garbageCollect](state) {
    const removeOrphans = (parrents, children, key) => {
      const ids = [...new Set(parrents.map((e) => e.id))];
      return children.filter((e) => ids.includes(e[key]));
    };

    state.clientPlaces = removeOrphans(
      state.clientCultures,
      state.clientPlaces,
      'client_culture_id'
    );
    state.clientPlacesCodes = removeOrphans(
      state.clientPlaces,
      state.clientPlacesCodes,
      'place_id'
    );
    state.clientPlacesZones = removeOrphans(
      state.clientPlaces,
      state.clientPlacesZones,
      'place_id'
    );
    state.clientZonesAnalyzes = removeOrphans(
      state.clientPlacesZones,
      state.clientZonesAnalyzes,
      'zone_id'
    );
    state.clientAnalyzesElements = removeOrphans(
      state.clientZonesAnalyzes,
      state.clientAnalyzesElements,
      'analyse_id'
    );
  },
};

const actions = {
  async [actionTypes.getClientData]({ commit, rootState }) {
    commit(mutationTypes.clientDataFetchingStart);
    try {
      const { timestamp } = rootState.glossaryGeobase;
      const res = await api.getEditClientData(timestamp);

      if (res.status === 'ok') {
        commit(mutationTypes.clientDataFetchingSuccess, res);
        commit(geobaseMutationTypes.getGeobaseSuccess, res.geoBase, {
          root: true,
        });
      } else {
        throw Error(res.message || 'Unknown error');
      }
    } catch (error) {
      commit(mutationTypes.clientDataFetchingFailure, error);
    }
  },
  async [actionTypes.submitClientData]({ commit, state, getters }) {
    if (state.isSubmiting) return;
    commit(mutationTypes.clientSubmitStart);
    commit(mutationTypes.garbageCollect);
    try {
      if (getters.clientHasEmptyCultures)
        throw Error(i18n.t('Vuex:clients:Errors:clientHasEmptyCultures'));

      const payload = Object.fromEntries(
        clientEntitiesKeys.map((key) => [key, state[key]])
      );
      const res = await api.submitClientData(payload);
      if (res.status === 'ok') {
        commit(mutationTypes.clientSubmitSuccess);
        commit(mutationTypes.setLastSaveTime, new Date().getTime());
      } else throw Error(res.message || 'Unknown error');
    } catch (error) {
      console.error(error);
      commit(mutationTypes.clientSubmitFailure, error);
    }
  },
};

export { mutationTypes, actionTypes, staticData, clientEntitiesKeys };

export default {
  state,
  actions,
  mutations,
  getters,
};
