<!--Translated-->
<template>
  <div class="cblock__line-block">
    <DropdownSelect
      :placeholder="
        locked
          ? $t('LocationSelect:NotSelected')
          : $t('LocationSelect:SelectCountry.Placeholder')
      "
      :label="$t('LocationSelect:SelectCountry.Label')"
      size="standart"
      :options="countries"
      v-model="values.country"
      :disabled="disabled"
      :locked="locked"
    />

    <component
      :is="freeSelect && type === 1 ? 'DropdownPlusSelect' : 'DropdownSelect'"
      v-if="type > 0"
      :placeholder="
        locked
          ? $t('LocationSelect:NotSelected')
          : $t('LocationSelect:SelectRegion.Placeholder')
      "
      :label="$t('LocationSelect:SelectRegion.Label')"
      size="standart"
      :options="regions"
      v-model="values.region"
      :disabled="regionsDisabled"
      :locked="locked"
    />

    <component
      :is="freeSelect && type === 2 ? 'DropdownPlusSelect' : 'DropdownSelect'"
      v-if="type > 1"
      :placeholder="
        locked
          ? $t('LocationSelect:NotSelected')
          : $t('LocationSelect:SelectMunicipalitet.Placeholder')
      "
      :label="$t('LocationSelect:SelectMunicipalitet.Label')"
      size="standart"
      :options="municipalities"
      v-model="values.municipality"
      :disabled="municipalitiesDisabled"
      :locked="locked"
    />

    <component
      :is="freeSelect && type > 2 ? 'DropdownPlusSelect' : 'DropdownSelect'"
      v-if="type > 2"
      :placeholder="
        locked
          ? $t('LocationSelect:NotSelected')
          : $t('LocationSelect:SelectCityVillage.Placeholder')
      "
      :label="$t('LocationSelect:SelectCityVillage.Label')"
      size="standart"
      :options="settlements"
      v-model="values.settlement"
      :disabled="settlementsDisabled"
      :locked="locked"
      :useDdItemsCut="true"
    />
  </div>
</template>

<script>
import DropdownSelect from '@/components/views/wrappers/DropdownSelect';
import DropdownPlusSelect from '@/components/views/wrappers/DropdownPlusSelect';

export default {
  name: 'LocationSelect',
  components: { DropdownSelect, DropdownPlusSelect },
  props: {
    geoEntries: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    locked: { type: Boolean, default: false },
    value: { type: Number, default: null },
    type: { type: Number, default: 3 },
    freeSelect: { type: Boolean, default: false },
  },
  data() {
    return {
      values: {
        country: null,
        region: null,
        municipality: null,
        settlement: null,
      },
      // previous chain as string since vue watchers pass old value
      // of non-primitive objects as reference
      previous: [null, null, null, null],
    };
  },
  computed: {
    countries() {
      return this.geoEntries.filter((e) => e.type === 0);
    },
    regions() {
      const countryId = this.values.country?.id;
      return this.geoEntries
        .filter((e) => e.type === 1)
        .filter((e) => (countryId ? e.parent_id === countryId : true));
    },
    availableRegionIds() {
      const selectedRegionId = this.values.region?.id ?? null;
      return selectedRegionId
        ? [selectedRegionId]
        : this.regions.map((e) => e.id);
    },
    municipalities() {
      return this.geoEntries
        .filter((e) => e.type === 2)
        .filter((e) => this.availableRegionIds.includes(e.parent_id));
    },
    availableMunicipalityIds() {
      const selectedRegionId = this.values.municipality?.id ?? null;
      return selectedRegionId
        ? [selectedRegionId]
        : this.municipalities.map((e) => e.id);
    },
    settlements() {
      return this.geoEntries
        .filter((e) => e.type > 2)
        .filter((e) => this.availableMunicipalityIds.includes(e.parent_id))
        .sort((a, b) => a.type - b.type);
      // .slice(0, 50);
    },
    regionsDisabled() {
      return this.disabled || (this.freeSelect ? false : !this.values.country);
    },
    municipalitiesDisabled() {
      return this.disabled || (this.freeSelect ? false : !this.values.region);
    },
    settlementsDisabled() {
      return (
        this.disabled || (this.freeSelect ? false : !this.values.municipality)
      );
    },
    placesChain() {
      return Object.values(this.values)
        .map((e) => e?.name ?? '-')
        .reverse()
        .join(' / ');
    },
  },
  watch: {
    value: {
      handler(id) {
        this.setEntry(id);
      },
      immediate: true,
    },
    geoEntries() {
      this.setEntry(this.value);
    },
    values: {
      handler(values) {
        const keys = Object.keys(values);
        const current = Object.values(values).map((v) => v?.id ?? null);

        // values can be changed in the middle of chain
        // we have to reset selected geos after changed one
        // and set correct ones before
        let deepestChangedId;
        let deepestChangedIndex;
        for (const [index, id] of current.entries()) {
          if (id !== this.previous[index]) {
            deepestChangedId = id;
            deepestChangedIndex = index;
          }
        }
        if (deepestChangedId !== undefined) {
          keys
            .filter((_, index) => index >= deepestChangedIndex)
            .forEach((key) => (this.values[key] = null));
          if (this.deepestChangedId !== null) this.setEntry(deepestChangedId);
        }

        this.previous = Object.values(values).map((v) => v?.id ?? null);

        // deepest id can be different from changed one
        const deepestId = Object.values(values)
          .map((item) => item?.id)
          .filter(Boolean)
          .slice(-1)[0];
        this.$emit('input', deepestId ?? null);
      },
      deep: true,
      immediate: true,
    },
    placesChain: {
      handler(value) {
        this.$emit('chainChanged', value);
      },
      immediate: true,
    },
  },
  methods: {
    setEntry(id) {
      const keys = [...Object.keys(this.values), 'settlement'];
      if (id === null) {
        this.values[keys[this.type]] = null;
      } else {
        const entry = this.geoEntries.find((e) => e.id === id);
        if (entry) {
          this.values[keys[entry.type]] = entry;
          if (entry.parent_id) this.setEntry(entry.parent_id);
        }
      }
    },
    reset() {
      Object.keys(this.values).forEach((key) => (this.values[key] = null));
    },
  },
};
</script>
