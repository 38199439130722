import instructionsApi from '@/api/instructions';
import i18n from '@/i18n';

const state = {
  isInstructionsLoading: false,
  isInstructionsLoadingHasError: false,
  isUpdateLoading: false,
  isUpdateLoadingHasError: false,
  isUpdateDone: false,
  updatingResId: null,
  editInstruction: null,
  editInstruction0: null,
  editInstruction1: null,
  editInstructionFiles: [],
  editInstructionFiles0: [],
  editInstructionFiles1: [],
};

export const mutationTypes = {
  getEditInstructionDataStart: '[instructions] getEditInstructionData start',
  getEditInstructionDataSuccess:
    '[instructions] getEditInstructionData success',
  getEditInstructionDataFailure:
    '[instructions] getEditInstructionData failure',

  updateInstructionStart: '[instructions] updateInstruction start',
  updateInstructionSuccess: '[instructions] updateInstruction success',
  updateInstructionFailure: '[instructions] updateInstruction failure',
};

export const actionTypes = {
  getEditInstructionData: '[instructions] getEditInstructionData',
  updateInstruction: '[instructions] updateInstruction',
};

const mutations = {
  [mutationTypes.getEditInstructionDataStart](state) {
    state.isInstructionsLoading = true;
    state.isInstructionsLoadingHasError = false;
    state.editInstruction = null;
    state.editInstruction0 = null;
    state.editInstruction1 = null;
    state.editInstructionFiles = [];
    state.editInstructionFiles0 = [];
    state.editInstructionFiles1 = [];
  },
  [mutationTypes.getEditInstructionDataSuccess](state, payload) {
    state.isInstructionsLoading = false;
    state.isInstructionsLoadingHasError = false;
    if (payload) {
      if (payload.instructions) {
        state.editInstruction = payload.instructions;
        if (payload.instructions.length === 2) {
          state.editInstruction0 = payload.instructions[0];
          state.editInstruction1 = payload.instructions[1];

          if (state.editInstruction0.link_youtube === null) {
            state.editInstruction0.link_youtube = '';
          }
          if (state.editInstruction1.link_youtube === null) {
            state.editInstruction1.link_youtube = '';
          }
          if (state.editInstruction0.text === null) {
            state.editInstruction0.text = '';
          }
          if (state.editInstruction1.text === null) {
            state.editInstruction1.text = '';
          }

          if (payload.files_links) {
            state.editInstructionFiles = payload.files_links;
            state.editInstructionFiles.forEach((file) => {
              if (file?.instruction_id === state.editInstruction0.id) {
                state.editInstructionFiles0.push(file);
              } else if (file?.instruction_id === state.editInstruction1.id) {
                state.editInstructionFiles1.push(file);
              }
            });
          }
        }
      }
      //state.products = sortProducts(payload.products);
      //state.productsDateCache = new Date().getTime();
      //processProducts(state);
    }
  },
  [mutationTypes.getEditInstructionDataFailure](state) {
    state.isInstructionsLoading = false;
    state.isInstructionsLoadingHasError = true;
  },
  //--------------------------------------------------
  [mutationTypes.updateInstructionStart](state) {
    state.isUpdateLoading = true;
    state.isUpdateLoadingHasError = false;
    state.isUpdateDone = false;
    state.updatingResId = null;
  },
  [mutationTypes.updateInstructionSuccess](state, payload) {
    state.isUpdateLoading = false;
    state.isUpdateLoadingHasError = false;
    state.isUpdateDone = true;
    if (payload.status && payload.status === 'ok') {
      if (payload.id !== undefined) {
        state.updatingResId = payload.id;
      }
    } else {
      state.isUpdateLoadingHasError = true;
      //state.updateCultureErrorText = payload?.message || 'Error';
    }
  },
  [mutationTypes.updateInstructionFailure](state) {
    state.isUpdateLoading = false;
    state.isUpdateDone = true;
    state.isUpdateLoadingHasError = true;
  },
};

const actions = {
  async [actionTypes.getEditInstructionData](context, payload) {
    context.commit(mutationTypes.getEditInstructionDataStart, payload);
    try {
      let response = await instructionsApi.getEditInstructionData({
        categoryId: payload.categoryId,
      });
      if (response.status !== 304) {
        context.commit(
          mutationTypes.getEditInstructionDataSuccess,
          response.data
        );
      }
    } catch (error) {
      console.log(error);
      context.commit(mutationTypes.getEditInstructionDataFailure);
    }
  },
  async [actionTypes.updateInstruction](context, payload) {
    context.commit(mutationTypes.updateInstructionStart, payload);
    try {
      let response = await instructionsApi.updateInstruction(payload);
      if (response.status !== 304) {
        context.commit(mutationTypes.updateInstructionSuccess, response.data);
      }
    } catch (error) {
      console.log(error);
      context.commit(mutationTypes.updateInstructionFailure);
    }
  },
};
export default {
  state,
  mutations,
  actions,
};
