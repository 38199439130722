<!--Translated-->
<template>
  <div
    v-show="phases.length"
    class="cwrap cwrap--fertilizertbl marginbottom--standart"
  >
    <table
      class="table--type2 table--period"
      :style="isSaving ? 'filter: grayscale(1); opacity: 0.7' : ''"
    >
      <thead>
        <tr>
          <th class="tbl-cellweeknum">#</th>
          <th class="tbl-cellperiod">
            <div class="tbl_info">
              <div class="tbl_info-wrap">
                <div v-svg="{ name: 'paycheck' }"></div>
                <div class="tbl_info-celltext">
                  {{ $t('FertilizerProgramPhases:Table:Headers:0') }}
                </div>
              </div>
            </div>
          </th>
          <th class="col-add">
            <div v-svg="{ name: '' }"></div>
          </th>
          <th class="tbl-cellproduct">
            {{ $t('FertilizerProgramPhases:Table:Headers:1') }}
          </th>
          <th class="tbl-cellinpt tbl-cellmethod">
            {{ $t('FertilizerProgramPhases:Table:Headers:2') }}
          </th>
          <th class="tbl-cellinpt tbl-cellkgtoga">
            {{ $t('FertilizerProgramPhases:Table:Headers:3') }}
          </th>
          <th class="tbl-cellinpt tbl-cellkgtoitem">
            {{ $t('FertilizerProgramPhases:Table:Headers:4') }}
          </th>
          <th class="tbl-cellprice">
            {{ $t('FertilizerProgramPhases:Table:Headers:5.1')
            }}<!--<br />
            {{ $t('FertilizerProgramPhases:Table:Headers:5.2') }}-->
          </th>
          <th class="col-delete unicol-colordark">
            <div v-svg="{ name: 'delete' }"></div>
          </th>
        </tr>
      </thead>

      <tbody v-for="(phase, weekIndex) in phases" :key="phase.id">
        <tr class="row" v-if="phasesProductsAsMap[phase.id].length === 0">
          <td class="tbl-cellweeknum" rowspan="1">{{ weekIndex + 1 }}</td>
          <td rowspan="1">
            {{ formatPeriod(phase.phase_start, phase.phase_end) }}
          </td>
          <td
            class="col-add"
            rowspan="1"
            :style="canEdit ? '' : 'pointer-events: none'"
            @click="openModal(phase.id)"
          >
            <div
              :style="canEdit ? '' : 'opacity: 0.3'"
              v-svg="{ name: 'plus2' }"
            ></div>
          </td>
          <td colspan="6" class="tbl_info-celltext" style="text-align: center">
            {{ $t('FertilizerProgramPhases:Table:NoData') }}
          </td>
        </tr>

        <template v-else>
          <tr
            v-for="(product, index) in phasesProductsAsMap[phase.id]"
            class="row"
            :class="{ 'tbl_row--active': product.count_per_ha !== null }"
            :key="product.id"
          >
            <td
              class="tbl-cellweeknum"
              v-if="index === 0"
              :rowspan="phasesProductsAsMap[phase.id].length"
            >
              {{ weekIndex + 1 }}
            </td>
            <td
              v-if="index === 0"
              :rowspan="phasesProductsAsMap[phase.id].length"
            >
              {{ formatPeriod(phase.phase_start, phase.phase_end) }}
            </td>
            <td
              class="col-add"
              v-if="index === 0"
              :rowspan="phasesProductsAsMap[phase.id].length"
              @click="openModal(phase.id)"
              :style="canEdit ? '' : 'pointer-events: none'"
            >
              <div
                :style="canEdit ? '' : 'opacity: 0.3'"
                v-svg="{ name: 'plus2' }"
              ></div>
            </td>
            <td class="tbl-cellproduct">
              <div class="tbl_info">
                <div class="tbl_info-wrap">
                  <div
                    v-if="product.productRef.is_active"
                    v-svg="{ name: 'info' }"
                    :key="'active'"
                  />
                  <div
                    v-else
                    v-svg="{ name: 'warningtriangle' }"
                    :key="'no_active'"
                    id="force-red"
                  />
                  <HoverTooltipProduct :product="product.productRef" />
                </div>

                <div class="tbl_info-celltext">
                  {{ product.productRef.name }}
                </div>
              </div>
            </td>
            <td class="tbl-cellinpt">
              <DropdownSelectValue
                :placeholder="
                  $t('FertilizerProgramPhases:Table:ProductMethod.Placeholder')
                "
                :label="$t('FertilizerProgramPhases:Table:ProductMethod.Label')"
                :options="methods"
                :value="product.method_id"
                @input="setProductMethodId(product, $event)"
                :disabled="isSaving"
                :locked="!canEdit"
              />
            </td>
            <td class="tbl-cellinpt">
              <InputNumber
                class="inputintable"
                type="number"
                placeholder="0.0"
                label="-"
                :value="product.count_per_ha"
                @input.native="setCountPerHa(product, $event)"
                :state="isSaving ? 'disabled' : canEdit ? '' : 'disabled2'"
              />
            </td>
            <td class="tbl-cellinpt">
              <InputNumber
                class="inputintable"
                type="number"
                placeholder="0.0"
                label="-"
                :value="product.count_per_plant"
                @input.native="setCountPerPlant(product, $event)"
                :state="isSaving ? 'disabled' : canEdit ? '' : 'disabled2'"
              />
            </td>
            <td class="tbl-cellprice">
              {{
                calcPrice(product).toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}
            </td>
            <td
              class="col-delete"
              :style="canEdit ? '' : 'pointer-events: none'"
              @click="deleteProduct(product)"
            >
              <div
                :style="canEdit ? '' : 'opacity: 0.3'"
                v-svg="{ name: 'delete' }"
              ></div>
            </td>
          </tr>
        </template>

        <!--Этот блок нужен последним в каждлм контейнере -->
        <tr class="tablelinedecor">
          <td colspan="9"></td>
        </tr>
      </tbody>
    </table>

    <FertilizerAddProductModal
      v-if="modalPhaseId !== null"
      :editingId="modalPhaseId"
      :alreadyAdded="alreadyAdded"
      @close="modalPhaseId = null"
      @save="addProduct"
    />
  </div>
</template>

<script>
import { format } from 'date-fns';
import { mapState, mapGetters } from 'vuex';

import InputNumber from '@/components/views/wrappers/InputNumberWithCast';
import FertilizerAddProductModal from './FertilizerAddProductModal';
import HoverTooltipProduct from './HoverTooltipFertilizer';
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';

import { mutationTypes, staticData } from '@/store/modules/nutritionPrograms';
import { mapInputEntityWithMerge } from '@/components/adapters/mapInput';

export default {
  name: 'FertilizerProgramPhases',
  components: {
    InputNumber,
    DropdownSelectValue,
    FertilizerAddProductModal,
    HoverTooltipProduct,
  },
  data() {
    return {
      modalPhaseId: null,
    };
  },
  computed: {
    ...mapGetters({
      productsEntriesAsMap: 'nutritionProductsEntriesAsMap',
      phasesProductsAsMap: 'nutritionPhasesProductsAsMap',
      totalProductList: 'nutritionTotalProductList',
    }),
    ...mapState({
      canEdit: (state) =>
        state.auth.currentUserRole?.claim_plants_nutrition_add === 1,
      isSaving: (state) => state.nutrition.isSaving,
      phases: (state) => state.nutrition.programPhases,
      products: (state) => state.nutrition.programFertilizerProducts,
      productsEntries: (state) => state.nutrition.productsFertilizer,
      plantsPerHa: (state) => state.nutrition.program.count_plants_per_ha,
      area: (state) => state.nutrition.program.place_area ?? 0,
    }),
    methods() {
      return staticData.methods.map(mapInputEntityWithMerge);
    },
    alreadyAdded() {
      return this.modalPhaseId === null
        ? []
        : this.phasesProductsAsMap[this.modalPhaseId].map((p) => p.product_id);
    },
  },
  methods: {
    formatPeriod(start, end) {
      return `${format(new Date(start), 'dd.MM.yyyy')} - ${format(
        new Date(end),
        'dd.MM.yyyy'
      )}`;
    },
    openModal(phaseId) {
      this.modalPhaseId = phaseId;
    },
    addProduct(productId) {
      this.$store.commit(mutationTypes.addProduct, {
        phaseId: this.modalPhaseId,
        productId,
      });
    },

    setCountPerHa({ id }, event) {
      this.$store.commit(mutationTypes.setProductCountPerHa, {
        productId: id,
        count: event.target.value ? Number(event.target.value) : 0,
      });
    },
    setCountPerPlant({ id }, event) {
      this.$store.commit(mutationTypes.setProductCountPerPlant, {
        productId: id,
        count: event.target.value ? Number(event.target.value) : 0,
      });
    },
    setProductMethodId({ id }, methodId) {
      this.$store.commit(mutationTypes.setProductMethodId, {
        productId: id,
        methodId,
      });
    },
    calcPrice(product) {
      if (!product.count_per_ha || !product.productRef.picking) return 0;
      return (
        product.count_per_ha *
        (product.productRef.price / product.productRef.picking)
      );
    },
    deleteProduct(product) {
      // console.log(product);
      this.$confirm({
        message:
          this.$t('FertilizerProgramPhases:ConfirmDeleteModal:Message1') +
          `<br>` +
          this.$t('FertilizerProgramPhases:ConfirmDeleteModal:Message2') +
          ` ${product.productRef.name}?`,
        buttons: {
          left: {
            text: this.$t(
              'FertilizerProgramPhases:ConfirmDeleteModal:ButtonDelete'
            ),
          },
          right: {
            text: this.$t(
              'FertilizerProgramPhases:ConfirmDeleteModal:ButtonCancel'
            ),
          },
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.commit(mutationTypes.removeProduct, product.id);
          }
        },
      });
    },
  },
};
</script>
