<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('Clients:Title') }]" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('Clients:ButtonAdd')"
          size="standart"
          color="accent"
          icon="plus"
          :is-loading="false"
          :loading-left="false"
          :disabled="!canCreate"
          @click="$router.push({ name: 'NewClient' })"
        />
      </div>
    </div>

    <div class="rightzone_block">
      <div class="filter">
        <div class="filter-inputszone">
          <div class="filterwrap filterwrap--clients">
            <div class="filterwrap_line">
              <Input
                type="text"
                :placeholder="$t('Clients:Filter:Name.Placeholder')"
                :label="$t('Clients:Filter:Name.Label')"
                size="long"
                v-model.trim="filters.name"
              />
              <DropdownSelect
                :placeholder="$t('Clients:Filter:Status.Placeholder')"
                :label="$t('Clients:Filter:Status.Label')"
                :options="statuses"
                v-model="filters.status"
              />
              <DropdownSelect
                :placeholder="$t('Clients:Filter:Type.Placeholder')"
                :label="$t('Clients:Filter:Type.Label')"
                :options="types"
                v-model="filters.type"
              />
            </div>

            <div class="filterwrap_line">
              <Input
                type="text"
                :placeholder="$t('Clients:Filter:Code.Placeholder')"
                :label="$t('Clients:Filter:Code.Label')"
                size="standart"
                v-model.trim="filters.code"
              />
              <Input
                type="text"
                :placeholder="$t('Clients:Filter:Number.Placeholder')"
                :label="$t('Clients:Filter:Number.Label')"
                size="standart"
                v-model="filters.phone"
              />
              <DropdownSelectValue
                :placeholder="$t('Clients:Filter:Regtype.Placeholder')"
                :label="$t('Clients:Filter:Regtype.Label')"
                :options="registrationMethods"
                v-model="filters.regMethod"
              />
              <DropdownPlusSelect
                :label="$t('Clients:Filter:Culture.Placeholder')"
                :placeholder="$t('Clients:Filter:Culture.Label')"
                size="standart"
                :options="cultures"
                v-model="filters.culture"
              />
            </div>

            <div class="filterwrap_line">
              <LocationSelect
                :geoEntries="geoMapped"
                v-model="filters.geo"
                :freeSelect="true"
                ref="location"
              />
            </div>
          </div>
        </div>

        <div class="filter-clearbtnzone">
          <Button
            class="filter_buttonclear"
            text="очистить"
            size="s-standart"
            icon="backspace"
            :color="predicates.length ? 'accentlines' : 'graylines'"
            :is-loading="false"
            :loading-left="true"
            @click="reset"
          />
        </div>
      </div>
    </div>

    <div class="rightzone_table">
      <Table
        :headers="headers"
        :dataArr="rows"
        @editClick="editClient"
        :tableLoading="isLoading"
        :loadingCountItems="10"
      />
    </div>
  </article>
</template>

<script>
import debounce from 'lodash.debounce';

import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import Table from '@/components/tables/Table';
import DropdownSelect from '@/components/views/wrappers/DropdownSelect';
import DropdownPlusSelect from '@/components/views/wrappers/DropdownPlusSelect';
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';
import LocationSelect from '@/components/views/LocationSelect';

import { mapGetters, mapState } from 'vuex';
import routeNames from '@/router/routeNames';
import { staticData } from '@/store/modules/clients';
import { mutationTypes } from '@/store/modules/filtersState';
//При изменении фильтра не забыть изменить базовый в вьюъексе (сохранение состояния)
const defaultFilters = {
  geo: null,
  code: '',
  name: '',
  phone: '',
  email: '',
  regMethod: null,
  type: null,
  status: null,
  culture: null,
};

const cache = new Map();

export default {
  name: 'AppClients',
  components: {
    Input,
    Table,
    Button,
    Breadcrumps,
    LocationSelect,
    DropdownSelect,
    DropdownPlusSelect,
    DropdownSelectValue,
  },
  data() {
    return {
      filters: { ...defaultFilters },
      clientsFiltered: [],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.clientsList.isLoading,
      cultures: (state) =>
        state.clientsList.cultures.map((c) => ({ text: c.name, value: c.id })),
      canCreate: (state) => state.auth.currentUserRole?.claim_clients_add === 1,
      canEdit: (state) => state.auth.currentUserRole?.claim_clients_edit === 1,
    }),
    ...mapGetters(['clientsList', 'geoMapped', 'culturesToClients']),
    /*filters: {
      get() {
        return this.$store.state.filtersState.filterClients;
      },
      set(payload) {
        console.log(payload);
        this.$store.commit(mutationTypes.setFilterClients, payload);
      },
    },*/
    statuses() {
      return staticData.clientStatus.map((text, value) => ({ text, value }));
    },
    types() {
      return staticData.clientType.map((text, value) => ({ text, value }));
    },
    registrationMethods() {
      return [
        { text: this.$t('Clients:RegTypes:0'), value: 0 },
        { text: this.$t('Clients:RegTypes:1'), value: 1 },
      ];
    },
    headers() {
      return [
        {
          type: 'warning',
          icon: 'warningdiese',
          class: 'col-warning',
        },
        {
          text: this.$t('Clients:TableHeaders:0'), //'ID код',
          type: 'text',
        },
        {
          text: this.$t('Clients:TableHeaders:1'), //'Имя клиента',
          type: 'text',
        },
        {
          text: this.$t('Clients:TableHeaders:2'), //'Email',
          type: 'text',
        },
        {
          text: this.$t('Clients:TableHeaders:3'), //'Телефон',
          type: 'text',
        },
        {
          text: this.$t('Clients:TableHeaders:4'), //'Юр. статус',
          type: 'text',
        },
        {
          text: this.$t('Clients:TableHeaders:5'), //'Тип',
          type: 'text',
        },
        {
          text: this.$t('Clients:TableHeaders:6'), //'Место',
          type: 'text',
        },
        {
          text: '',
          class: 'col-edit',
          type: 'edit',
          icon: this.canEdit ? 'edit' : 'passwordshow',
        },
      ];
    },
    rows() {
      return this.clientsFiltered.map(this.getRow);
    },
    nameFilterLowerCase() {
      return this.filters.name.toLowerCase();
    },
    emailFilterLowerCase() {
      return this.filters.phone.toLowerCase();
    },
    phoneFilterNormalized() {
      return this.filters.phone.replace(/\(|\)|\+|-|\s/g, '');
    },
    predicates() {
      const f = this.filters;
      return [
        f.code !== '' && ((c) => c.code.includes(f.code)),
        f.name !== '' &&
          ((c) => c.nameLowerCase.includes(this.nameFilterLowerCase)),
        f.phone !== '' &&
          ((c) =>
            c.emailsLowerCase.includes(this.emailFilterLowerCase) ||
            c.phones.includes(this.phoneFilterNormalized)),
        f.regMethod !== null && ((c) => (f.regMethod ? !c.login : c.login)),
        f.type !== null && ((c) => c.type_id === f.type.value),
        f.status !== null && ((c) => c.status_id === f.status.value),
        f.geo && ((c) => c.geoChain.includes(f.geo)),
        f.culture !== null &&
          ((c) => this.culturesToClients[f.culture.value].clients.has(c.id)),
      ].filter(Boolean);
    },
  },
  watch: {
    filters: {
      handler(val) {
        this.$store.commit(mutationTypes.setFilterClients, { ...val });
        this.applyFilters();
      },
      deep: true,
    },
    isLoading() {
      this.applyFilters();
    },
  },
  methods: {
    applyFilters: debounce(
      function () {
        this.clientsFiltered = this.predicates.length
          ? this.clientsList.filter((client) =>
              this.predicates.every((predicate) => predicate(client))
            )
          : this.clientsList;
      },
      200,
      { leading: true }
    ),
    geoSelected(id) {
      this.filters.geo = id;
    },
    editClient(id) {
      this.$router.push({
        name: routeNames.editClient,
        params: { id },
      });
    },
    mapRow(client) {
      return [
        client.attention,
        client.code,
        client.name,
        client.emails ?? this.$t('Clients:DataNotSet'),
        client.phones ?? this.$t('Clients:DataNotSet'),
        client.status ?? this.$t('Clients:DataNotSet'),
        client.type ?? this.$t('Clients:DataNotSet'),
        client.settlement ?? this.$t('Clients:DataNotSet'),
        client.id,
      ].map((text) => ({ text }));
    },
    getRow(client) {
      if (!cache.has(client.id)) {
        cache.set(client.id, this.mapRow(client));
      }
      return cache.get(client.id);
    },
    reset() {
      this.filters = { ...defaultFilters };
      this.$refs.location.reset();
    },
  },
  created() {
    for (let filterKey in this.filters) {
      this.filters[filterKey] =
        this.$store.state.filtersState.filterClients[filterKey];
    }
  },
  beforeCreate() {
    cache.clear();
  },
};
</script>
