<!-- Translated -->
<template>
  <Warningblock
    v-if="!isLoading && warning"
    @dismiss="onWarningDismiss"
    :showButton="canDismiss"
  >
    {{ $t('ClientWarning:Text') }}
  </Warningblock>
</template>

<script>
import { mapState } from 'vuex';
import { mutationTypes } from '@/store/modules/clients';
import Warningblock from '@/components/views/Warningblock';

export default {
  name: 'ClientWarning',
  components: { Warningblock },
  props: { canDismiss: { type: Boolean, default: true } },
  computed: mapState({
    isLoading: (state) => state.clients.isLoading,
    warning: (state) => state.clients.client.attention === 1,
  }),
  methods: {
    onWarningDismiss() {
      this.$store.commit(mutationTypes.setClientWarning);
    },
  },
};
</script>
