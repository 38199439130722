<template>
  <article class="rightzone rightzone--article">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />
    </div>

    <!-- П.1 Анализ почвы-->
    <div
      v-if="!isLoading"
      class="cwrap artclwrap el--borders-all el--rounded-all marginbottom--standart"
    >
      <div class="artclhead">
        <div class="artclhead-text">{{ article?.title }}</div>
      </div>

      <div class="artclblock">
        <div class="artclblock-textWYSIWYG" v-html="article?.text"></div>
      </div>
    </div>
    <LoaderBlock v-else />

    <!--Футтер-->
    <UserFooter />
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import UserFooter from '@/pages/userproject/userComponents/UserFooter';
import LocationSelect from '@/components/views/LocationSelect';
import Button from '@/components/views/Button';
import GuideAnalysisGeo from '@/pages/userproject/userArticles/Components/GuideAnalysisGeo';
import GuideAnalysisTrack from '@/pages/userproject/userArticles/Components/GuideAnalysisTrack';
import ComponentFile from '@/pages/userproject/userArticles/Components/ComponentFile';
import routeNames from '@/router/routeNames';
import LoaderBlock from '@/components/blocks/LoaderBlock';
import { mapGetters } from 'vuex';

export default {
  name: 'DemoArticle',
  components: {
    LoaderBlock,
    ComponentFile,
    GuideAnalysisTrack,
    GuideAnalysisGeo,
    LocationSelect,
    UserFooter,
    Breadcrumps,
    Button,
  },
  computed: {
    ...mapGetters(['geoCategoriesArticlesMapByStringId']),
    breadcrumpsArr() {
      return [
        {
          title:
            this.geoCategoriesArticlesMapByStringId.get(
              this.$route.params?.id ?? null
            )?.name ?? '',
          route: routeNames.userArticlesList,
          params: { id: this.$route.params?.id },
        },
        {
          title: this.article?.title ?? '',
        },
      ];
    },
    article() {
      return this.$store.state.userArticles.article;
    },
    isLoading() {
      return this.$store.state.userArticles.isArticleLoading;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/vars.scss';
</style>
