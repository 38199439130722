<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="ddBreadcrumps" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('GlossaryCulturesEdit:ButtonCancel')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'Cultures' })"
        />
        <Button
          :text="$t('GlossaryCulturesEdit:ButtonSave')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="isSaveButtonLoading"
          :loading-left="true"
          :disabled="isSaveButtonDisabled"
          @click="onSave"
        />
      </div>
    </div>

    <!--КУЛЬТУРЫ-->
    <!--Добавить культуру фильтр-->
    <div class="cwrap cwrap--level1 marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'edit' }"
          ></div>
          <div class="chead_text">
            {{ $t('GlossaryCulturesEdit:CulturesHeader') }}
          </div>
        </div>
      </div>
      <div
        class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                :placeholder="
                  $t('GlossaryCulturesEdit:CultureName.Placeholder')
                "
                :label="$t('GlossaryCulturesEdit:CultureName.Label')"
                icon=""
                :state="inputsState"
                size="long"
                color=""
                v-model="newCulture.name"
                @input="validationState.name = true"
              />
              <Input
                type="dropdown"
                :placeholder="
                  $t('GlossaryCulturesEdit:CultureCategory.Placeholder')
                "
                :label="$t('GlossaryCulturesEdit:CultureCategory.Label')"
                icon=""
                :state="inputsState"
                size=""
                color=""
                :dd-items="ddCategoriesCulturesItems"
                @selected="newCulture.category_id = $event.item.value"
                :dd-selected-index="ddCategorySelectedIndex"
                dd-max-elements="6"
              />
              <!-- <RadioToggle
                type="disabled"
                :text1="$t('GlossaryCulturesEdit:CultureActive:Hide')"
                :text2="$t('GlossaryCulturesEdit:CultureActive:Active')"
                :label="$t('GlossaryCulturesEdit:CultureActive:Label')"
                :reverse="true"
                :disabled="isLoading || !canEdit"
                :locked="isLoading ? false : !canEdit"
                v-model="newCulture.is_active"
              />-->
              <div
                class="radiotogglewrap"
                :class="{
                  'radiotogglewrap--disabled': !this.canEdit || this.isLoading,
                  'radiotogglewrap--onlyshow': !this.canEdit,
                  'radiotogglewrap--active': this.canEdit && tmp_only_one_year,
                }"
                :style="{ pointerEvents: !this.canEdit ? 'none' : '' }"
              >
                <fieldset class="inputradiotoggle">
                  <label class="radiotoggle">
                    <CheckBox
                      :text="$t('GlossaryCulturesEdit:CheckBoxOneYearText')"
                      color-checked="accentfill"
                      color-un-checked="gray"
                      v-model="tmp_only_one_year"
                      :disabled="!this.canEdit || isLoading"
                    />
                  </label>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Таблица элементов-->
    <div class="cwrap cwrap--level1 marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'elements' }"
          ></div>
          <div class="chead_text">
            {{ $t('GlossaryCulturesEdit:TableHeader') }}
          </div>
        </div>
      </div>
      <div class="cblock el--rounded-bottom marginbottom--standart">
        <Table
          :headers="tableElementsHeaders"
          :data-arr="
            newCulture.only_one_year ? dataArrElementsOneYear : dataArrElements
          "
          :table-loading="isLoading"
          :loading-count-items="11"
          :disable-pagination="true"
          :disable-hover="true"
          :locked="!canEdit"
          @input="onSetElementValue"
        />
      </div>
    </div>

    <!--СОРТА КУЛЬТУРЫ2-->
    <div class="cwrap cwrap--level1 marginbottom--standart">
      <!--заголовок-->
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'agriculture' }"
          ></div>
          <div class="chead_text">
            {{ $t('GlossaryCulturesEdit:CultureSortsHeader') }}
          </div>
        </div>
      </div>
      <!--пустой блок-->
      <div
        v-if="newCulture.sorts.length === 0"
        class="cblock el--borders-all el--rounded-bottom"
      >
        <div class="cblock__infoblock cblock__infoblock--nocontentyet">
          <p>{{ $t('GlossaryCulturesEdit:CultureSortsNoSorts') }}</p>
          <Button
            v-if="canEdit"
            :text="$t('GlossaryCulturesEdit:ButtonAddSort')"
            size="wider"
            color="accent"
            icon="plus"
            :is-loading="false"
            :loading-left="false"
            :disabled="isLoading"
            @click="
              modalSortTypeEdit = false;
              editSort = null;
              showModalSort = true;
            "
          />
        </div>
      </div>

      <template v-else>
        <!--Сорта культуры таблица-->
        <div class="cblock el--rounded-bottom">
          <Table
            :headers="tableSortsHeaders"
            :data-arr="dataArrSorts"
            :table-loading="isLoading"
            :loading-count-items="3"
            @editClick="onEditSort"
          >
            <Button
              v-if="canEdit"
              :text="$t('GlossaryCulturesEdit:ButtonAddSort')"
              size="wider"
              color="accentlines"
              icon="plus"
              :is-loading="false"
              :loading-left="false"
              @click="
                modalSortTypeEdit = false;
                editSort = null;
                showModalSort = true;
              "
            />
          </Table>
        </div>
      </template>
    </div>

    <!--ВРЕДНЫЕ ОБЪЕКТЫ-->
    <div class="cwrap cwrap--level1 marginbottom--standart">
      <LinkedPests
        :isLoading="isLoading"
        :pests="$store.state.glossaryPests.pests ?? []"
        v-model="newCulture.culturePests"
        :multiMode="true"
        :readonly="!canEdit"
      >
        <template v-slot:description>
          <p v-if="$store.state.glossaryPests.pests?.length">
            {{ $t('GlossaryCulturesEdit:PestsText1') }}
          </p>
          <p v-else>{{ $t('GlossaryCulturesEdit:PestsText2') }}</p>
        </template>
      </LinkedPests>
    </div>

    <Button
      v-if="canEdit"
      :text="$t('GlossaryCulturesEdit:ButtonSave')"
      size="standart"
      color="accent"
      icon="save"
      :is-loading="isSaveButtonLoading"
      :loading-left="true"
      :disabled="isSaveButtonDisabled"
      @click="onSave"
    />

    <Modal
      v-if="showModalNotSaved"
      @backgroundClose="showModalNotSaved = false"
    >
      <ModalNotSaved
        v-model="showModalNotSaved"
        @confirm="onModalNotSavedConfirm"
      />
    </Modal>
    <Modal v-if="showModalSort" @backgroundClose="showModalSort = false">
      <ModalGrocerySort
        v-model="showModalSort"
        :type="modalSortTypeEdit ? 'edit' : 'add'"
        :editSort="editSort"
        @addSort="onAddSort"
        @editSort="onEditSortDone"
        @deleteSort="onDeleteSortDone"
      />
    </Modal>
    <Modal v-if="showModalPests" @backgroundClose="showModalPests = false">
      <ModalGroceryBadObjectEdit
        v-model="showModalPests"
        :type="modalPestTypeEdit ? 'edit' : 'add'"
        :editCulturePest="editCulturePest"
        :exist-items="newCulture.culturePests"
        @addPest="onAddPest"
        @editPest="onEditPestDone"
        @deletePest="onDeletePestDone"
      />
    </Modal>
  </article>
</template>

<script>
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import Breadcrumps from '@/components/views/Breadcrumps';
import Table from '@/components/tables/Table';
import { mapState } from 'vuex';
import { actionTypes } from '@/store/modules/glossaryCultures';
import routeNames from '@/router/routeNames';
import Modal from '@/pages/Modal';
import ModalNotSaved from '@/components/modals/modalNotSaved';
import ModalGrocerySort from '@/components/modals/grocery/modalGrocerySort';
import textHelper from '@/helpers/textHelper';
import ModalGroceryBadObjectEdit from '@/components/modals/grocery/modalGroceryBadObject';
import LinkedPests from '@/pages/glossary/GlossaryLinkedPests';
import CheckBox from '@/components/CheckBox';

export default {
  name: 'GlossaryCulturesEdit',
  components: {
    CheckBox,
    ModalGroceryBadObjectEdit,
    ModalGrocerySort,
    Breadcrumps,
    Input,
    Button,
    Table,
    Modal,
    ModalNotSaved,
    LinkedPests,
  },
  data() {
    return {
      newCulture: {
        id: null,
        name: '',
        category_id: null,
        is_active: 1,
        elements: [],
        sorts: [],
        culturePests: [],
        only_one_year: 0,
      },
      isElementsHasChanges: false,
      validationState: {
        name: true,
      },
      showModalNotSaved: false,
      nextRouteConfirm: null,
      nextRoute: null,
      ddCategorySelectedIndex: 0,
      showModalSort: false,
      modalSortTypeEdit: false,
      editSort: null,
      filterPests: {
        name: '',
        ddPestsSelectedCategoryId: null,
      },
      ddPestsCategorySelectedIndex: 0,
      showModalPests: false,
      modalPestTypeEdit: false,
      editCulturePest: null,
    };
  },
  methods: {
    onModalNotSavedConfirm() {
      if (this.nextRoute) {
        this.nextRouteConfirm = true;
        this.$router.push(this.nextRoute);
      }
    },
    onSetElementValue(payload) {
      //console.log(payload)
      this.newCulture.elements[payload.row][payload.column - 1] = payload.value;
      this.isElementsHasChanges = true;
    },
    onSave() {
      let valOk = true;
      if (!this.newCulture.name) {
        this.validationState.name = false;
        valOk = false;
      }
      if (!valOk) {
        return;
      }
      this.$store.dispatch(actionTypes.updateCulture, this.newCulture);
    },
    onAddSort(payload) {
      this.newCulture.sorts.push({
        id: textHelper.generateRandomString(16),
        name: payload.value,
      });
    },
    onAddPest(payload) {
      let find = false;
      for (let i = 0; i < this.newCulture.culturePests.length; i++) {
        if (this.newCulture.culturePests[i].pest_id === payload.pest_id) {
          find = true;
          break;
        }
      }
      if (!find) {
        this.newCulture.culturePests.push({ pest_id: payload.pest_id });
      }
    },
    onEditSort(id) {
      if (this.isLoading) {
        return;
      }
      let sortIndex = -1;
      for (let i = 0; i < this.newCulture.sorts.length; i++) {
        if (this.newCulture.sorts[i]?.id === id) {
          sortIndex = i;
          break;
        }
      }
      if (sortIndex > -1) {
        this.editSort = this.newCulture.sorts[sortIndex];
        this.modalSortTypeEdit = true;
        this.showModalSort = true;
      }
    },
    onEditPest(id) {
      if (this.isLoading) {
        return;
      }
      let pestIndex = -1;
      for (let i = 0; i < this.newCulture.culturePests.length; i++) {
        if (this.newCulture.culturePests[i]?.pest_id === id) {
          pestIndex = i;
          break;
        }
      }
      if (pestIndex > -1) {
        this.editCulturePest = this.newCulture.culturePests[pestIndex];
        this.modalPestTypeEdit = true;
        this.showModalPests = true;
      }
    },
    onEditSortDone(payload) {
      if (this.isLoading) {
        return;
      }
      let sortIndex = -1;
      for (let i = 0; i < this.newCulture.sorts.length; i++) {
        if (this.newCulture.sorts[i]?.id === payload.id) {
          sortIndex = i;
          break;
        }
      }
      if (sortIndex > -1) {
        this.newCulture.sorts[sortIndex].name = payload.name;
      }
    },
    onDeleteSortDone(payload) {
      if (this.isLoading) {
        return;
      }

      let sortIndex = -1;
      for (let i = 0; i < this.newCulture.sorts.length; i++) {
        if (this.newCulture.sorts[i]?.id === payload.id) {
          sortIndex = i;
          break;
        }
      }
      if (sortIndex > -1) {
        this.newCulture.sorts.splice(sortIndex, 1);
      }
    },
    onEditPestDone(payload) {
      if (this.isLoading) {
        return;
      }
      let pestIndex = -1;
      console.log(
        `Новый пуст ид: ${payload.pest_id} старый был: ${payload.old_pest_id}`
      );
      console.log(this.newCulture.culturePests);
      for (let i = 0; i < this.newCulture.culturePests.length; i++) {
        if (this.newCulture.culturePests[i]?.pest_id === payload.old_pest_id) {
          pestIndex = i;
          break;
        }
      }
      if (pestIndex > -1) {
        let find = false;
        for (let i = 0; i < this.newCulture.culturePests.length; i++) {
          if (this.newCulture.culturePests[i].pest_id === payload.pest_id) {
            find = true;
            break;
          }
        }
        if (!find) {
          //this.newCulture.culturePests.splice(pestIndex,1);
          console.log(pestIndex);
          //this.newCulture.culturePests[pestIndex] = {pest_id: payload.id};
          this.$set(this.newCulture.culturePests, pestIndex, {
            pest_id: payload.pest_id,
          });
        }
      }
    },
    onDeletePestDone(payload) {
      if (this.isLoading) {
        return;
      }

      let pestIndex = -1;
      for (let i = 0; i < this.newCulture.culturePests.length; i++) {
        if (this.newCulture.culturePests[i]?.id === payload.id) {
          pestIndex = i;
          break;
        }
      }
      if (pestIndex > -1) {
        this.newCulture.culturePests.splice(pestIndex, 1);
      }
    },
  },
  computed: {
    ...mapState({
      canEdit: (state) => state.auth.currentUserRole?.claim_glossary_edit === 1,
    }),
    inputsState() {
      if (this.isLoading) return 'disabled';
      if (!this.canEdit) return 'disabled2';
      return this.validationState.name ? '' : 'error';
    },
    isLoading() {
      return (
        this.$store.state.glossaryCultures.isNewCultureLoading ||
        this.$store.state.glossaryCultures.isUpdateCultureLoading ||
        this.$store.state.glossaryCultures.isEditCultureDataLoading
      );
    },
    isSaveButtonLoading() {
      return this.$store.state.glossaryCultures.isUpdateCultureLoading;
    },
    isDone() {
      return this.$store.state.glossaryCultures.isUpdateCultureDone;
    },
    isSaveButtonDisabled() {
      let haveData = false;
      if (this.isEditCulture) {
        for (const key in this.newCulture) {
          if (
            // eslint-disable-next-line
            this.$store.state.glossaryCultures.editCulture.hasOwnProperty(key)
          ) {
            if (
              this.newCulture[key] !==
              this.$store.state.glossaryCultures.editCulture[key]
            ) {
              haveData = true;
              break;
            }
          }
        }
        this.isElementsHasChanges;
        // if (this.isElementsHasChanges) {
        // }
        if (
          !haveData &&
          this.$store.state.glossaryCultures.editCultureElements &&
          this.$store.state.glossaryCultures.editCultureElements instanceof
            Array
        ) {
          for (
            let i = 0;
            i < this.$store.state.glossaryCultures.editCultureElements.length;
            i++
          ) {
            if (
              this.$store.state.glossaryCultures.editCultureElements[
                i
              ] instanceof Array
            ) {
              for (
                let j = 0;
                j <
                this.$store.state.glossaryCultures.editCultureElements[i]
                  .length;
                j++
              ) {
                if (
                  Number(this.newCulture.elements[i][j]) !==
                  Number(
                    this.$store.state.glossaryCultures.editCultureElements[i][j]
                  )
                ) {
                  //console.log(i,j)
                  haveData = true;
                  break;
                }
              }
            }
          }
          if (!haveData) {
            try {
              for (let i = 0; i < this.newCulture.elements.length; i++) {
                for (let j = 0; j < this.newCulture.elements[i].length; j++) {
                  if (
                    Number(this.newCulture.elements[i][j]) !==
                    Number(
                      this.$store.state.glossaryCultures.editCultureElements[i][
                        j
                      ]
                    )
                  ) {
                    haveData = true;
                    //console.log(i,j)
                    break;
                  }
                }
              }
            } catch (e) {
              console.log(e);
            }
          }
          if (!haveData) {
            try {
              if (
                this.newCulture.sorts.length !=
                this.$store.state.glossaryCultures.editCultureSorts.length
              ) {
                haveData = true;
              } else {
                for (let i = 0; i < this.newCulture.sorts.length; i++) {
                  //console.log(this.newCulture.sorts[i].name)
                  //console.log(this.$store.state.glossaryCultures.editCultureSorts[i].name)
                  if (
                    this.newCulture.sorts[i].id !=
                      this.$store.state.glossaryCultures.editCultureSorts[i]
                        .id ||
                    this.newCulture.sorts[i].name !=
                      this.$store.state.glossaryCultures.editCultureSorts[i]
                        .name
                  ) {
                    haveData = true;
                    break;
                  }
                }
              }
            } catch (e) {
              console.log(e);
            }
          }
        }
        if (!haveData) {
          if (
            this.newCulture.culturePests.length !==
            this.$store.state.glossaryCultures.editCulturePests?.length
          ) {
            haveData = true;
            console.log(1);
          }
          if (
            !haveData &&
            this.newCulture.culturePests?.length > 0 &&
            this.$store.state.glossaryCultures.editCulturePests?.length > 0
          ) {
            for (let i = 0; i < this.newCulture.culturePests.length; i++) {
              let find = false;
              for (
                let j = 0;
                j < this.$store.state.glossaryCultures.editCulturePests.length;
                j++
              ) {
                if (
                  this.newCulture.culturePests[i].pest_id ===
                  this.$store.state.glossaryCultures.editCulturePests[j].pest_id
                ) {
                  find = true;
                  break;
                }
              }
              if (!find) {
                haveData = true;
                console.log(2);
              }
            }
          }
        }
      } else {
        if (this.newCulture.name && this.newCulture.category_id) {
          haveData = true;
        }
      }

      return this.canEdit ? !haveData : true;
    },
    ddCategoriesCulturesItems() {
      let arr = [
        { text: this.$t('GlossaryCulturesEdit:NotSelected'), value: null },
      ];
      if (this.$store.state.glossaryCategoriesCultures.categoriesCultures) {
        this.$store.state.glossaryCategoriesCultures.categoriesCultures.forEach(
          (category, index) => {
            arr.push({ text: category.name, value: category.id });
            if (this.isEditCulture) {
              if (this.newCulture.category_id === category.id) {
                this.ddCategorySelectedIndex = index + 1;
              }
            }
          }
        );
      }
      return arr;
    },
    ddPestsCategories() {
      let arr = [
        { text: this.$t('GlossaryCulturesEdit:NotSelected'), value: null },
      ];
      if (this.$store.state.glossaryPests.categoriesPests) {
        this.$store.state.glossaryPests.categoriesPests.forEach((category) => {
          arr.push({ text: category.name, value: category.id });
        });
      }
      return arr;
    },
    tableElementsHeaders() {
      let headers = [
        {
          text: this.$t('GlossaryCulturesEdit:TableHeaders:0'), //'Год',
          class: 'col-year bold',
          width: '7.69%',
          type: 'text',
        },
      ];
      if (this.$store.state.glossaryElements.elements) {
        this.$store.state.glossaryElements.elements.forEach((element) => {
          headers.push({
            text: element.name
              .replace('K', 'K₂O')
              .replace('P', 'P₂O₅')
              .replace('S', 'SO₃')
              .replace(/Ca|Mg/, '$&O'),
            class: 'col-element',
            width: '7.69%',
            type: 'input',
          });
        });
      } else {
        ['', '', '', '', '', '', '', '', '', '', ''].forEach(() => {
          headers.push({
            text: '',
            class: 'col-element',
            width: '7.69%',
            type: 'input',
          });
        });
      }

      return headers;
    },
    tablePestsHeaders() {
      let headers = [
        {
          text: this.$t('GlossaryCulturesEdit:Table2Headers:0'), //'Категория',
          class: 'col-culturesort',
          width: '30%',
          type: 'text',
        },
        {
          text: this.$t('GlossaryCulturesEdit:Table2Headers:1'), //'Вредные объекты',
          class: 'col-culturesort',
          width: '70%',
          type: 'text',
        },
      ];

      if (this.canEdit) {
        headers.push({
          text: '',
          class: 'col-edit',
          width: '',
          type: 'edit',
          icon: 'edit',
        });
      }

      return headers;
    },
    tableSortsHeaders() {
      let headers = [
        {
          text: this.$t('GlossaryCulturesEdit:Table3Headers:0'), //'Сорта культуры',
          class: 'col-culturesort',
          width: '100%',
          type: 'text',
        },
      ];

      if (this.canEdit) {
        headers.push({
          text: '',
          class: 'col-edit',
          width: '',
          type: 'edit',
          icon: 'edit',
        });
      }

      return headers;
    },
    dataArrSorts() {
      let arr = [];
      this.newCulture.sorts.forEach((sort) => {
        let tr = [];
        tr.push({ text: sort.name });
        tr.push({ text: sort.id });
        arr.push(tr);
      });
      return arr;
    },
    dataArrPests() {
      let arr = [];
      this.newCulture.culturePests.forEach((pest) => {
        let tr = [];
        let name = '';
        let category = '';
        let categoryId = null;
        if (this.$store.state.glossaryPests.pestsMapById.has(pest.pest_id)) {
          name = this.$store.state.glossaryPests.pestsMapById.get(
            pest.pest_id
          ).name;
          if (
            this.$store.state.glossaryPests.categoriesPestsMapById.has(
              this.$store.state.glossaryPests.pestsMapById.get(pest.pest_id)
                .category_id
            )
          ) {
            category =
              this.$store.state.glossaryPests.categoriesPestsMapById.get(
                this.$store.state.glossaryPests.pestsMapById.get(pest.pest_id)
                  .category_id
              ).name;
            categoryId = this.$store.state.glossaryPests.pestsMapById.get(
              pest.pest_id
            ).category_id;
          }
        }
        tr.push({ text: category });
        tr.push({ text: name });
        tr.push({ text: pest.pest_id });

        let filterIsActive = false;
        for (let filterKey in this.filterPests) {
          if (this.filterPests[filterKey]) {
            filterIsActive = true;
            break;
          }
        }
        if (filterIsActive) {
          let filterOk = true;
          if (
            this.filterPests.name &&
            !name.toLowerCase().includes(this.filterPests.name.toLowerCase())
          ) {
            filterOk = false;
          }
          if (
            this.filterPests.ddPestsSelectedCategoryId &&
            this.filterPests.ddPestsSelectedCategoryId !== categoryId
          ) {
            filterOk = false;
          }
          if (filterOk) {
            arr.push(tr);
          }
        } else {
          arr.push(tr);
        }
      });
      return arr;
    },
    elementsArr() {
      return this.$store.state.glossaryElements.elements;
    },
    dataArrElements() {
      let arr = [];
      //console.log('dataArrElements()');
      // TODO refactor
      // eslint-disable-next-line
      if (this.isElementsHasChanges) this.isElementsHasChanges = false;

      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, '11+'].forEach((year, index1) => {
        let tr = [];
        tr.push({ text: year });
        if (this.$store.state.glossaryElements.elements) {
          this.$store.state.glossaryElements.elements.forEach(
            (element, index2) => {
              if (this.newCulture.elements.length > index1) {
                if (this.newCulture.elements[index1]?.length > index2) {
                  tr.push({ text: this.newCulture.elements[index1][index2] });
                }
              }
              //tr.push({text: ''})
            }
          );
        }
        arr.push(tr);
      });
      return arr;
    },
    dataArrElementsOneYear() {
      let arr = [];
      //console.log('dataArrElements()');
      // TODO refactor
      // eslint-disable-next-line
      if (this.isElementsHasChanges) this.isElementsHasChanges = false;

      [1].forEach((year, index1) => {
        let tr = [];
        tr.push({ text: year });
        if (this.$store.state.glossaryElements.elements) {
          this.$store.state.glossaryElements.elements.forEach(
            (element, index2) => {
              if (this.newCulture.elements.length > index1) {
                if (this.newCulture.elements[index1]?.length > index2) {
                  tr.push({ text: this.newCulture.elements[index1][index2] });
                }
              }
              //tr.push({text: ''})
            }
          );
        }
        arr.push(tr);
      });
      return arr;
    },
    editCulture() {
      return this.$store.state.glossaryCultures.editCulture;
    },
    isEditCulture() {
      if (
        this.$store.state.glossaryCultures.editCulture &&
        this.$route.name === routeNames.editCulture
      ) {
        return true;
      }
      return false;
    },
    ddBreadcrumps() {
      if (this.isEditCulture) {
        return [
          { title: this.$t('GlossaryCulturesEdit:Title1'), route: 'Cultures' },
          { title: this.$t('GlossaryCulturesEdit:Title2') },
        ];
      } else {
        return [
          { title: this.$t('GlossaryCulturesEdit:Title1'), route: 'Cultures' },
          { title: this.$t('GlossaryCulturesEdit:Title3') },
        ];
      }
    },
    tmp_only_one_year: {
      get() {
        return this.newCulture.only_one_year;
      },
      set(val) {
        this.newCulture.only_one_year = val ? 1 : 0;
      },
    },
  },
  watch: {
    elementsArr() {
      if (this.elementsArr) {
        let arr = [];

        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, '11+'].forEach(() => {
          let tr = [];
          if (this.$store.state.glossaryElements.elements) {
            this.$store.state.glossaryElements.elements.forEach(() => {
              tr.push('');
            });
          }
          arr.push(tr);
        });

        //this.$set(this.newCulture.elements, arr);
        this.newCulture.elements = arr;
      }
    },
    isDone() {
      if (this.isDone) {
        if (!this.$store.state.glossaryCultures.isUpdateCultureHasError) {
          this.nextRouteConfirm = true;
          this.$router.push({ name: routeNames.cultures });
        } else {
          this.$awn.alert(
            this.$store.state.glossaryCultures.updateCultureErrorText
          );
          if (
            this.$store.state.glossaryCultures.updateCultureErrorText &&
            this.$store.state.glossaryCultures.updateCultureErrorText.includes(
              'name'
            )
          ) {
            this.validationState.name = true;
          }
        }
      }
    },
    editCulture() {
      if (this.editCulture) {
        for (const key in this.newCulture) {
          if (
            // eslint-disable-next-line
            this.$store.state.glossaryCultures.editCulture.hasOwnProperty(key)
          ) {
            this.newCulture[key] =
              this.$store.state.glossaryCultures.editCulture[key];
          }
        }
        if (
          this.$store.state.glossaryCultures.editCultureElements &&
          this.$store.state.glossaryCultures.editCultureElements instanceof
            Array
        ) {
          for (
            let i = 0;
            i < this.$store.state.glossaryCultures.editCultureElements.length;
            i++
          ) {
            if (
              this.$store.state.glossaryCultures.editCultureElements[
                i
              ] instanceof Array
            ) {
              for (
                let j = 0;
                j <
                this.$store.state.glossaryCultures.editCultureElements[i]
                  .length;
                j++
              ) {
                this.newCulture.elements[i][j] =
                  this.$store.state.glossaryCultures.editCultureElements[i][j];
              }
            }
          }
          this.isElementsHasChanges = true;
        }

        if (
          this.$store.state.glossaryCultures.editCultureSorts &&
          this.$store.state.glossaryCultures.editCultureSorts instanceof Array
        ) {
          for (
            let i = 0;
            i < this.$store.state.glossaryCultures.editCultureSorts.length;
            i++
          ) {
            this.newCulture.sorts.push({
              ...this.$store.state.glossaryCultures.editCultureSorts[i],
            });
          }
        }

        if (
          this.$store.state.glossaryCultures.editCulturePests &&
          this.$store.state.glossaryCultures.editCulturePests instanceof Array
        ) {
          for (
            let i = 0;
            i < this.$store.state.glossaryCultures.editCulturePests.length;
            i++
          ) {
            this.newCulture.culturePests.push({
              ...this.$store.state.glossaryCultures.editCulturePests[i],
            });
          }
        }
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.nextRouteConfirm || !this.canEdit) {
      next();
    } else if (this.$route.name === routeNames.editCulture) {
      if (!this.isSaveButtonDisabled) {
        this.showModalNotSaved = true;
        this.nextRoute = to;
        next(false);
      } else {
        next();
      }
    } else {
      if (this.newCulture.name) {
        this.showModalNotSaved = true;
        this.nextRoute = to;
        next(false);
      } else {
        next();
      }
    }
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>
