<-- Translated -->
<template>
  <Button
    :text="$t('ClientSearchButton:Text')"
    size="wide"
    color="accent"
    icon="search"
    :disabled="isLoading || isSearching || !code"
    :is-loading="isSearching"
    :loading-left="true"
    @click="search"
  />
</template>

<script>
import { mapState } from 'vuex';
import { actionTypes } from '@/store/modules/clients';
import Button from '@/components/views/Button';

export default {
  name: 'ClientSearchButton',
  components: { Button },
  computed: mapState({
    isLoading: (state) => state.clients.isLoading,
    isSearching: (state) => state.clients.isSearching,
    code: (state) => state.clients.client.code,
    userNotFoundCode: (state) => state.clients.userNotFoundCode,
  }),
  methods: {
    search() {
      this.$store.dispatch(actionTypes.searchClient, this.code);
    },
  },
  watch: {
    userNotFoundCode(code) {
      if (code) {
        this.$awn.success(this.$t('ClientSearchButton:MessageOk')); //'Клиент с ID не найден, можно создать нового.');
      }
    },
  },
};
</script>
