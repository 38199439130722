<-- Translated -->
<template>
  <div class="filter">
    <div class="filter-inputszone">
      <div class="filterwrap filterwrap--products">
        <div class="filterwrap_line">
          <Input
            type="text"
            :placeholder="$t('FilterProducts:Name.Placeholder')"
            :label="$t('FilterProducts:Name.Label')"
            icon="search"
            state=""
            size="long"
            color=""
            v-model="filter.nameOrId"
            @input="$emit('input', filter)"
          />

          <Input
            type="text"
            :placeholder="$t('FilterProducts:Substance.Placeholder')"
            :label="$t('FilterProducts:Substance.Label')"
            icon=""
            state=""
            size="standart"
            color=""
            v-model="filter.activeSubstance"
            @input="$emit('input', filter)"
          />
        </div>

        <div class="filterwrap_line">
          <!--Тип продукта -->
          <!--<Input
            type="dropdown"
            placeholder="Выберите..."
            label="Тип продукта"
            icon=""
            state=""
            size="standart"
            color=""
            :dd-items="ddProductsCategories"
            @selected="
              filter.typeProduct = $event.item.value;
              ddProductsCategoriesSelectedIndex = $event.index;
              filter.typePest = null;
              filter.typeFertilizer = null;
              filter.typeStimulation = null;
              $emit('input', filter);
            "
            :dd-selected-index="ddProductsCategoriesSelectedIndex"
          />-->
          <DropdownSelectValue
            :placeholder="$t('FilterProducts:Type.Placeholder')"
            :label="$t('FilterProducts:Type.Label')"
            size="standard"
            v-model="filter.typeProduct"
            :options="ddProductsCategories"
            @selected="
              filter.typePest = null;
              filter.typeFertilizer = null;
              filter.typeStimulation = null;
              $emit('input', filter);
            "
          />

          <!--Если Пестицид-->
          <!--<Input
            v-if="filter.typeProduct === 0"
            type="dropdown"
            placeholder="Выберите..."
            label="Вид пестицида"
            state=""
            size="standart"
            color=""
            icon="default3"
            @selected="
              filter.typePest = $event.item.value;
              ddProductsPestsSubcategoriesSelectedIndex = $event.index;
              $emit('input', filter);
            "
            dd-max-elements="7"
            :key="1"
            :dd-selected-index="ddProductsPestsSubcategoriesSelectedIndex"
            :dd-use-default-icon="true"
            :dd-items="[
              { text: 'Не выбрано', value: null, icon: '' },
              ...this.$store.state.products.productsPestsSubcategories.map(
                (val) => {
                  return {
                    text: val.name,
                    value: val.id,
                    icon: val.icon,
                    class: val.class,
                  };
                }
              ),
            ]"
          />-->
          <DropdownSelectValue
            v-if="filter.typeProduct === 0"
            :placeholder="$t('FilterProducts:Pest.Placeholder')"
            :label="$t('FilterProducts:Pest.Label')"
            size="standart"
            color=""
            icon="default3"
            @selected="$emit('input', filter)"
            dd-max-elements="7"
            :key="1"
            :dd-use-default-icon="true"
            :options="[
              ...this.$store.state.products.productsPestsSubcategories.map(
                (val) => {
                  return {
                    text: val.name,
                    value: val.id,
                    icon: val.icon,
                    class: val.class,
                  };
                }
              ),
            ]"
            v-model="filter.typePest"
          />

          <!--Если Стимулятор-->
          <!--<Input
            v-if="filter.typeProduct === 1"
            type="dropdown"
            placeholder="Выберите..."
            label="Тип стимуляции"
            icon=""
            state=""
            size="standart"
            color=""
            :dd-items="ddProductsStimulationSubcategories"
            dd-max-elements="7"
            @selected="
              filter.typeStimulation = $event.item.value;
              ddProductsStimulationSubcategoriesSelectedIndex = $event.index;
              $emit('input', filter);
            "
            :key="2"
            :dd-selected-index="ddProductsStimulationSubcategoriesSelectedIndex"
          />-->
          <DropdownSelectValue
            v-if="filter.typeProduct === 1"
            :placeholder="$t('FilterProducts:Stimulation.Placeholder')"
            :label="$t('FilterProducts:Stimulation.Label')"
            size="standart"
            color=""
            @selected="$emit('input', filter)"
            dd-max-elements="7"
            :key="2"
            :options="ddProductsStimulationSubcategories"
            v-model="filter.typeStimulation"
          />

          <!--Если Удобрения-->
          <!--<Input
            v-if="filter.typeProduct === 2"
            type="dropdown"
            placeholder="Выберите..."
            label="Виды удобрения"
            icon=""
            state=""
            size="standart"
            color=""
            :dd-items="ddProductsFertilizersSubcategories"
            dd-max-elements="7"
            @selected="
              filter.typeFertilizer = $event.item.value;
              ddProductsFertilizersSubcategoriesSelectedIndex = $event.index;
              $emit('input', filter);
            "
            :key="3"
            :dd-selected-index="ddProductsFertilizersSubcategoriesSelectedIndex"
          />-->
          <DropdownSelectValue
            v-if="filter.typeProduct === 2"
            :placeholder="$t('FilterProducts:Fertilizer.Placeholder')"
            :label="$t('FilterProducts:Fertilizer.Label')"
            size="standart"
            color=""
            @selected="$emit('input', filter)"
            dd-max-elements="7"
            :key="3"
            :options="ddProductsFertilizersSubcategories"
            v-model="filter.typeFertilizer"
          />

          <!--Производитель-->
          <!-- <Input
            type="dropdownPlus"
            placeholder="Введите производителя..."
            label="Производитель"
            icon=""
            state=""
            size="standart"
            color=""
            :dd-items="ddManufacturers"
            dd-max-elements="7"
            v-model="manufacturerValue"
            @selected="onSelectManufacturer"
            @ddClose="resetManufacturerValue"
            :ddPlusResetWatcher="ddPlusResetWatcherManufacture"
          />-->
          <DropdownPlusSelectValue
            :placeholder="$t('FilterProducts:Vendor.Placeholder')"
            :label="$t('FilterProducts:Vendor.Label')"
            size="standart"
            color=""
            @input="$emit('input', filter)"
            dd-max-elements="7"
            :options="ddManufacturers"
            v-model="filter.manufacture"
          />

          <!--Страна-Производитель-->
          <!--<Input
            type="dropdown"
            placeholder="Выберите страну..."
            label="Страна производитель"
            icon=""
            state=""
            size="standart"
            color=""
            :dd-items="ddManufacturersCountries"
            dd-max-elements="7"
            @selected="
              filter.manufactureCountry = $event.item.value;
              ddManufacturersCountriesSelectedIndex = $event.index;
              $emit('input', filter);
            "
            :dd-selected-index="ddManufacturersCountriesSelectedIndex"
          />-->
          <DropdownPlusSelectValue
            :placeholder="$t('FilterProducts:VendorCountry.Placeholder')"
            :label="$t('FilterProducts:VendorCountry.Label')"
            size="standart"
            color=""
            @input="$emit('input', filter)"
            dd-max-elements="7"
            :options="ddManufacturersCountries"
            v-model="filter.manufactureCountry"
          />
        </div>
      </div>
    </div>

    <div class="filter-clearbtnzone">
      <Button
        class="filter_buttonclear"
        text="очистить"
        size="s-standart"
        :color="isFilterActive ? 'accentlines' : 'graylines'"
        icon="backspace"
        :is-loading="false"
        :loading-left="true"
        @click="onClearFilter"
      />
    </div>
  </div>
</template>

<script>
import Input from '@/components/views/Input';
import Button from '@/components/views/Button';
import textHelper from '@/helpers/textHelper';
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';
import DropdownPlusSelectValue from '@/components/views/wrappers/DropdownPlusSelectValue';
export default {
  name: 'FilterProducts',
  components: { DropdownPlusSelectValue, DropdownSelectValue, Button, Input },
  props: {
    value: {
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      filter: {
        nameOrId: '',
        activeSubstance: '',
        typeProduct: null,
        typePest: null,
        typeStimulation: null,
        typeFertilizer: null,
        manufacture: null,
        manufactureCountry: null,
        ...this.value,
      },
      ddProductsCategoriesSelectedIndex: 0,
      ddProductsPestsSubcategoriesSelectedIndex: 0,
      ddProductsStimulationSubcategoriesSelectedIndex: 0,
      ddProductsFertilizersSubcategoriesSelectedIndex: 0,
      ddManufacturersCountriesSelectedIndex: 0,
      ddManufacturerReset: 0,
      manufacturerValue: '',
      lastManufacturerValue: '',
      ddPlusResetWatcherManufacture: 0,
    };
  },
  methods: {
    onSelectManufacturer($event) {
      this.filter.manufacture = $event.item.value;
      if ($event.item.value !== null) {
        this.manufacturerValue = $event.item.text;
        this.lastManufacturerValue = $event.item.text;
      } else {
        this.manufacturerValue = '';
        this.lastManufacturerValue = '';
      }
      this.$emit('input', this.filter);
    },
    resetManufacturerValue() {
      if (this.lastManufacturerValue !== this.ddProductsCategories[0].text) {
        this.manufacturerValue = this.lastManufacturerValue;
      } else {
        this.manufacturerValue = '';
      }
    },
    onClearFilter() {
      this.filter.manufactureCountry = null;
      this.filter.manufacture = null;
      this.filter.typeFertilizer = null;
      this.filter.typePest = null;
      this.filter.typeStimulation = null;
      this.filter.typeProduct = null;
      this.filter.activeSubstance = '';
      this.filter.nameOrId = '';

      this.ddProductsCategoriesSelectedIndex = 0;
      this.ddProductsPestsSubcategoriesSelectedIndex = 0;
      this.ddProductsStimulationSubcategoriesSelectedIndex = 0;
      this.ddProductsFertilizersSubcategoriesSelectedIndex = 0;
      this.ddManufacturersCountriesSelectedIndex = 0;
      this.ddManufacturerReset = true;
      this.ddPlusResetWatcherManufacture++;
      this.$emit('input', this.filter);
    },
  },
  computed: {
    ddProductsCategories() {
      return [
        //{ text: 'Не выбрано', value: null },
        ...this.$store.state.products.productsCategories.map((val) => {
          return { text: val.name, value: val.id };
        }),
      ];
    },
    ddProductsPestsSubcategories() {
      return [
        {
          text: this.$t('FilterProducts:NotSelected'),
          value: null,
          icon: null,
        },
        ...this.$store.state.products.productsPestsSubcategories.map((val) => {
          return { text: val.name, value: val.id };
        }),
      ];
    },
    ddProductsFertilizersSubcategories() {
      return [
        //{ text: 'Не выбрано', value: null },
        ...this.$store.state.products.productsFertilizersSubcategories.map(
          (val) => {
            return { text: val.name, value: val.id };
          }
        ),
      ];
    },
    ddProductsStimulationSubcategories() {
      return [
        //{ text: 'Не выбрано', value: null },
        ...this.$store.state.products.productsStimulationSubcategories.map(
          (val) => {
            return { text: val.name, value: val.id };
          }
        ),
      ];
    },
    ddManufacturers() {
      let res = []; //[{ text: 'Не выбрано', value: null }];
      if (this.$store.state.products.manufacturers) {
        res = [
          //res[0],
          ...[...this.$store.state.products.manufacturers]
            .filter((val) => {
              if (!this.manufacturerValue) {
                return true;
              }
              if (
                val.name
                  ?.toLowerCase()
                  .includes(this.manufacturerValue.toLowerCase())
              )
                return true;
            })
            .sort(textHelper.dynamicSort('name'))
            .map((val) => {
              return { text: val.name, value: val.id };
            }),
        ];
      }
      return res;
    },
    ddManufacturersCountries() {
      let res = []; //[{ text: 'Не выбрано', value: null }];
      if (this.$store.state.products.manufacturersCountries) {
        res = [
          //res[0],
          ...[...this.$store.state.products.manufacturersCountries]
            .sort(textHelper.dynamicSort('name'))
            .map((val) => {
              return { text: val.name, value: val.id };
            }),
        ];
      }
      return res;
    },
    isFilterActive() {
      return (
        this.filter.nameOrId ||
        this.filter.activeSubstance ||
        this.filter.typeProduct !== null ||
        this.filter.typePest !== null ||
        this.filter.typeStimulation !== null ||
        this.filter.typeFertilizer !== null ||
        this.filter.manufacture !== null ||
        this.filter.manufactureCountry !== null
      );
    },
  },
  created() {
    //this.ddProductsCategoriesSelectedIndex = this.filter.typeProduct ?? 0;
    //console.log(this.filter);
  },
};
</script>

<style scoped></style>
