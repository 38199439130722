<!--Translated-->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('UserFertilizers:Title') }]" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('UserFertilizers:ButtonAdd')"
          size="standart"
          color="accent"
          icon="plus"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'UserFertilizerProgramCreate' })"
        />
      </div>
    </div>

    <div class="rightzone_block">
      <div class="filter">
        <div class="filter-inputszone">
          <div class="filterwrap filterwrap--clients">
            <div class="filterwrap_line">
              <Input
                type="text"
                :placeholder="$t('UserFertilizers:Filter:Name.Placeholder')"
                :label="$t('UserFertilizers:Filter:Name.Label')"
                icon="search"
                size="long"
                v-model="filters.name"
              />
              <DropdownSelectValue
                :placeholder="$t('UserFertilizers:Filter:Culture.Placeholder')"
                :label="$t('UserFertilizers:Filter:Culture.Label')"
                :options="culturesFiltered"
                v-model="filters.culture"
              />
              <DropdownSelectValue
                :placeholder="$t('UserFertilizers:Filter:Region.Placeholder')"
                :label="$t('UserFertilizers:Filter:Region.Label')"
                :options="regionsFiltered"
                v-model="filters.geo"
              />
            </div>
          </div>
        </div>

        <div class="filter-clearbtnzone">
          <Button
            class="filter_buttonclear"
            text=""
            size="s-standart"
            icon="backspace"
            :is-loading="false"
            :loading-left="true"
            :color="filtersApplied ? 'accentlines' : 'graylines'"
            @click="reset"
          />
        </div>
      </div>
    </div>

    <div class="rightzone_table">
      <Table
        :headers="headers"
        :dataArr="rows"
        @editClick="editProgram"
        @printClick="printProgram"
        :tableLoading="isLoading"
        :loadingCountItems="10"
      >
        <template v-slot:warning-icons>
          <div v-svg="{ name: 'warningdiese' }"></div>
          <div v-svg="{ name: 'user' }"></div>
        </template>
      </Table>
    </div>

    <PrintModal
      v-if="selectedPrintId"
      @close="selectedPrintId = null"
      :selectedPrintId="selectedPrintId"
      :forNutrition="true"
      :clientArea="true"
    />
  </article>
</template>

<script>
import isEqual from 'lodash.isequal';
import uniqWith from 'lodash.uniqwith';
import { mapGetters, mapState } from 'vuex';

import routeNames from '@/router/routeNames';

import Breadcrumps from '@/components/views/Breadcrumps';
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import Table from '@/components/tables/Table';
import PrintModal from '@/components/modals/ModalPrint';

import { mapInputEntityWithMerge } from '@/components/adapters/mapInput';

const defaultFilters = {
  name: '',
  culture: null,
  geo: null,
};

export default {
  name: 'DefensePrograms',
  components: {
    Input,
    Button,
    Breadcrumps,
    Table,
    DropdownSelectValue,
    PrintModal,
  },
  data() {
    return {
      filters: { ...defaultFilters },
      selectedPrintId: null,
    };
  },
  computed: {
    ...mapGetters(['geoMapped', 'geoPlainDataAsMap']),
    ...mapState({
      isLoading: (state) => state.userNutritionProgramsList.isLoading,
      programs(state) {
        return state.userNutritionProgramsList.programs.map((p) => {
          let start = null;
          if (p.period_start !== null) {
            const date = new Date(p.period_start);
            start = {
              year: date.getUTCFullYear(),
              month: date.getUTCMonth(),
            };
          }
          return {
            ...p,
            start,
            culture: this.cultures.find(({ id }) => p.culture_id === id),
            region:
              this.geoPlainDataAsMap
                .get(p.geo_id)
                ?.order?.map(({ id }) => this.geoPlainDataAsMap.get(id))
                .find(({ type }) => type === 1) ?? null,
          };
        });
      },
      cultures: (state) =>
        state.userNutritionProgramsList.cultures.map(mapInputEntityWithMerge),
    }),
    headers() {
      return [
        {
          text: '',
          class: 'col-warning',
          icon: 'warningdiese',
          type: 'warning',
        },
        {
          text: this.$t('UserFertilizers:Table:Headers:0'),
          type: 'text',
          width: '32%',
        },
        {
          text: this.$t('UserFertilizers:Table:Headers:1'),
          type: 'text',
          width: '13%',
        },
        {
          text: this.$t('UserFertilizers:Table:Headers:2'),
          type: 'text',
          width: '9%',
        },
        {
          text: this.$t('UserFertilizers:Table:Headers:3'),
          type: 'text',
          width: '13%',
        },
        {
          text: this.$t('UserFertilizers:Table:Headers:4'),
          type: 'text',
          width: '10%',
        },
        {
          text: this.$t('UserFertilizers:Table:Headers:5'),
          type: 'text',
          width: '9%',
        },
        {
          class: 'col-edit unicol-colordark',
          type: 'print',
          icon: 'print',
        },
        {
          class: 'col-edit',
          type: 'edit',
          icon: 'edit',
        },
      ];
    },
    culturesFiltered() {
      return this.cultures.filter(({ id }) =>
        this.programs.some(({ culture }) => culture.id === id)
      );
    },
    regionsFiltered() {
      return uniqWith(
        this.programs.map((p) => p.region).filter(Boolean),
        (a, b) => a.id === b.id
      ).map(mapInputEntityWithMerge);
    },
    filtersApplied() {
      return !isEqual(this.filters, defaultFilters);
    },
    rows() {
      const f = this.filters;

      const mapToRow = (input) =>
        [
          0,
          input.name,
          input.culture?.name ?? '-',
          input.culture_age,
          input.region?.name,
          input.place_area,
          input.start
            ? `${String(input.start.month + 1).padStart(2, '0')}.${String(
                input.start.year
              ).padStart(2, '0')}`
            : null,
          input.id,
          input.id,
        ].map((text) => ({
          text,
        }));

      const filter = (row) =>
        row.name.includes(f.name) &&
        (f.geo ? row.region?.id === f.geo : true) &&
        (f.culture ? row.culture.id === f.culture : true);

      return this.programs.filter(filter).map(mapToRow);
    },
  },
  methods: {
    reset() {
      this.filters = { ...defaultFilters };
    },
    editProgram(id) {
      this.$router.push({
        name: routeNames.userFertilizerProgramEdit,
        params: { id },
      });
    },
    printProgram(id) {
      this.selectedPrintId = id;
    },
  },
};
</script>
