<!--Translated-->
<template>
  <table class="table--type2 table--defense">
    <thead>
      <tr>
        <th class="tbl-cellproblem">
          <div class="tbl_info">
            <div class="tbl_info-wrap">
              <div v-svg="{ name: 'badobject' }"></div>
              <div class="tbl_info-celltext">
                {{ $t('ProgramPestsTable:Header') }}
              </div>
            </div>
          </div>
        </th>
        <th class="tbl-cellproduct">{{ $t('ProgramPestsTable:Headers:0') }}</th>
        <th class="tbl-cellinpt">{{ $t('ProgramPestsTable:Headers:1') }}</th>
        <th class="tbl-cellprice">{{ $t('ProgramPestsTable:Headers:2') }}</th>
        <th
          v-if="phase.is_temporary && canEdit"
          class="col-delete unicol-colordark"
        >
          <div v-svg="{ name: 'delete' }"></div>
        </th>
      </tr>
    </thead>
    <template v-if="pests.length > 0">
      <ProgramRowPest
        v-for="pest in pests"
        :pest="pest"
        :phase="phase"
        :key="pest.id"
        @deletePest="deletePest"
      />
    </template>
    <tbody v-else>
      <tr class="row tbl_row--active row--message">
        <td class="tbl-cellproblem" :colspan="phase.is_temporary ? 5 : 4">
          <div v-if="phase.is_temporary">
            <div class="cblock__infoblock">
              <p>{{ $t('ProgramPestsTable:NoText') }}</p>
              <Button
                v-if="canEdit"
                :text="$t('ProgramPestsTable:ButtonAdd')"
                size="standart"
                color="accentlines"
                icon="plus"
                @click="$emit('addPest', phase.id)"
              />
            </div>
          </div>
          <div v-else>
            {{ $t('ProgramPestsTable:NoData') }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapState } from 'vuex';
import { mutationTypes } from '@/store/modules/programs';
import ProgramRowPest from './ProgramRowPest';
import Button from '@/components/views/Button';

export default {
  name: 'ProgramPestsTable',
  components: { Button, ProgramRowPest },
  props: { phase: { type: Object, required: true } },
  computed: {
    ...mapState({
      pests(state) {
        return [
          ...new Set(
            state.programs.programPestsProducts
              .filter(
                ({ program_phase_id }) => this.phase.id === program_phase_id
              )
              .map((p) => p.pest_id)
          ),
        ]
          .map((pestId) => state.programs.pests.find(({ id }) => id === pestId))
          .sort((a, b) => a.category_id - b.category_id);
      },
      canEdit: (state) =>
        state.auth.currentUserRole?.claim_plants_care_add === 1,
    }),
  },
  methods: {
    deletePest(id) {
      this.$confirm({
        message: this.$t('ProgramPestsTable:ConfirmDeleteModal:Message'),
        buttons: {
          left: {
            text: this.$t('ProgramPestsTable:ConfirmDeleteModal:ButtonLeft'),
            icon: 'delete',
          },
          right: {
            text: this.$t('ProgramPestsTable:ConfirmDeleteModal:ButtonRight'),
            icon: 'cancel',
          },
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.commit(mutationTypes.removePest, {
              id,
              phaseId: this.phase.id,
            });
          }
        },
      });
    },
  },
};
</script>
