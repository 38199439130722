import glossaryApi from '@/api/glossary';

const state = {
  /*Категории культур*/
  isCategoriesCulturesLoading: false,
  isCategoriesCulturesLoadingHasError: false,
  categoriesCultures: null,
  categoriesCulturesMapById: null,

  isUpdateCategoriesCulturesLoading: false,
  isUpdateCategoriesCulturesHasError: false,
  isUpdateCategoriesCulturesDone: false,
  updateCategoriesCulturesErrorText: null,

  editCategoryId: null,
  editCategory: null,
};
export const mutationTypes = {
  getCategoriesCulturesStart: '[glossary] getCategoriesCultures start',
  getCategoriesCulturesSuccess: '[glossary] getCategoriesCultures success',
  getCategoriesCulturesFailure: '[glossary] getCategoriesCultures failure',

  updateCategoriesCulturesStart: '[glossary] updateCategoriesCultures start',
  updateCategoriesCulturesSuccess:
    '[glossary] updateCategoriesCultures success',
  updateCategoriesCulturesFailure:
    '[glossary] updateCategoriesCultures failure',
};
export const actionTypes = {
  getCategoriesCultures: '[glossary] getCategoriesCultures',
  updateCategoriesCultures: '[glossary] updateCategoriesCultures',
};
const mutations = {
  [mutationTypes.getCategoriesCulturesStart](state, editCategoryId) {
    state.isCategoriesCulturesLoading = true;
    state.isCategoriesCulturesLoadingHasError = false;
    state.categoriesCultures = null;
    state.categoriesCulturesMapById = new Map();
    state.editCategoryId = editCategoryId || null;
    state.editCategory = null;
  },
  [mutationTypes.getCategoriesCulturesSuccess](state, payload) {
    state.isCategoriesCulturesLoading = false;
    state.categoriesCultures = payload.categoriesCultures;
    processCategories(state);
  },
  [mutationTypes.getCategoriesCulturesFailure](state) {
    state.isCategoriesCulturesLoading = false;
    state.isCategoriesCulturesLoadingHasError = true;
  },

  [mutationTypes.updateCategoriesCulturesStart](state) {
    state.isUpdateCategoriesCulturesLoading = true;
    state.isUpdateCategoriesCulturesHasError = false;
    state.isUpdateCategoriesCulturesDone = false;
    state.updateCategoriesCulturesErrorText = null;
  },
  [mutationTypes.updateCategoriesCulturesSuccess](state, payload) {
    state.isUpdateCategoriesCulturesLoading = false;
    //state.categoriesCultures = payload.categoriesCultures;
    state.isUpdateCategoriesCulturesDone = true;
    if (payload.status && payload.status === 'ok') {
      //
    } else {
      state.isUpdateCategoriesCulturesHasError = true;
      state.updateCategoriesCulturesErrorText = payload?.message || 'Error';
    }
  },
  [mutationTypes.updateCategoriesCulturesFailure](state) {
    state.isUpdateCategoriesCulturesLoading = false;
    state.isUpdateCategoriesCulturesDone = true;
    state.isCategoriesCulturesLoadingHasError = true;
  },
};
function processCategories(state) {
  state.categoriesCulturesMapById = new Map();
  if (state.categoriesCultures) {
    state.categoriesCultures.forEach((category) => {
      state.categoriesCulturesMapById.set(category.id, category);
      if (state.editCategoryId && state.editCategoryId == category.id) {
        state.editCategory = category;
      }
    });
  }
}

const actions = {
  async [actionTypes.getCategoriesCultures](context, payload) {
    context.commit(mutationTypes.getCategoriesCulturesStart, payload);
    try {
      let response = await glossaryApi.getCategoriesCultures(payload);
      context.commit(mutationTypes.getCategoriesCulturesSuccess, response.data);
    } catch (error) {
      context.commit(mutationTypes.getCategoriesCulturesFailure);
    }
  },
  async [actionTypes.updateCategoriesCultures](context, payload) {
    context.commit(mutationTypes.updateCategoriesCulturesStart);
    try {
      let response = await glossaryApi.updateCategoriesCultures(payload);
      context.commit(
        mutationTypes.updateCategoriesCulturesSuccess,
        response.data
      );
    } catch (error) {
      context.commit(mutationTypes.updateCategoriesCulturesFailure);
    }
  },
};
export default {
  state,
  actions,
  mutations,
};
