<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('GlossaryPhases:Title') }]" />

      <div class="rightzone_top-buttonswrap">
        <Button
          v-if="isShowAddButton"
          :text="$t('GlossaryPhases:ButtonAdd')"
          size="standart"
          color="accent"
          icon="plus"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'NewPhase' })"
        />
      </div>
    </div>

    <!--Фильтр-->
    <div class="cwrap cwrap--level1 marginbottom--standart">
      <div class="cblock el--borders-all el--rounded-all">
        <FilterGlossaryPhases v-model="filter" :cultures="data.cultures" />
      </div>
    </div>

    <!--(Табл)--->
    <div class="cwrap cwrap--level1 marginbottom--standart">
      <!--Таблица-->
      <div class="cblock">
        <Table
          :headers="tableHeaders"
          :data-arr="dataArr"
          :table-loading="request.isLoading"
          :loading-count-items="10"
          @editClick="onEditClick"
        />
      </div>
    </div>
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import FilterGlossaryPhases from '@/components/filtres/FilterGlossaryPhases';
import Table from '@/components/tables/Table';
import glossaryApi from '@/api/glossary';
import { monthNames } from '@/helpers/dateHelper';
import routeNames from '@/router/routeNames';
import { mapState } from 'vuex';
import { mutationTypes } from '@/store/modules/filtersState';
import { mutationTypes as mutationsTypesCultures } from '@/store/modules/glossaryCultures';

export default {
  name: 'GlossaryPhases',
  components: {
    Table,
    FilterGlossaryPhases,
    Button,
    Breadcrumps,
  },
  data() {
    return {
      request: {
        isLoading: false,
        hasError: false,
      },
      data: {
        phases: [],
        categoryCultures: [],
        cultures: [],
        images: [],
      },
      metaData: {
        culturesMapById: new Map(),
        imagesMapByPhaseId: new Map(),
      },
      filter: {
        name: '',
        culture: null,
      },
    };
  },
  methods: {
    prepareMetaData() {
      this.$store.commit(mutationsTypesCultures.getCulturesSuccess, this.data);
      this.metaData.culturesMapById = new Map();
      this.data.cultures?.forEach((val) => {
        this.metaData.culturesMapById.set(val?.id, val);
      });
      this.data.phases?.sort((a, b) => {
        if (a?.start_month === b?.start_month) {
          return a?.start_day - b?.start_day;
        } else {
          return a?.start_month - b?.start_month;
        }
      });
      this.metaData.imagesMapByPhaseId = new Map();
      this.data.phases?.forEach((val) => {
        this.metaData.imagesMapByPhaseId.set(val.id, []);
      });
      this.data.images?.forEach((val) => {
        if (!this.metaData.imagesMapByPhaseId.has(val?.object_id)) {
          this.metaData.imagesMapByPhaseId.set(val?.object_id, []);
        }
        this.metaData.imagesMapByPhaseId.get(val?.object_id)?.push(val);
      });
    },
    onEditClick(phaseId) {
      this.$router.push({
        name: routeNames.editPhase,
        params: { id: phaseId },
      });
    },
    getNameOfCultureByCultureId(id) {
      return this.metaData.culturesMapById?.get(id)?.name || '';
    },
    getDate(day, month) {
      return (
        ('0' + day)?.slice(-2) +
        ' - ' +
        this.$t('DayMonthSelect:Months:' + (month - 1))
      ); //monthNames[month - 1];
    },
  },
  computed: {
    ...mapState({
      canEdit: (state) => state.auth.currentUserRole?.claim_glossary_edit === 1,
    }),
    tableHeaders() {
      return [
        {
          text: this.$t('GlossaryPhases:TableHeaders:0'), //'Название',
          class: 'col--accent',
          width: '30%',
          type: 'text',
        },
        {
          text: this.$t('GlossaryPhases:TableHeaders:1'), //'Культура',
          width: '15%',
          type: 'text',
        },
        {
          text: this.$t('GlossaryPhases:TableHeaders:2'), //'Начало периода',
          width: '15%',
          type: 'text',
        },
        {
          text: this.$t('GlossaryPhases:TableHeaders:3'), //'Конец периода',
          width: '15%',
          type: 'text',
        },
        {
          text: this.$t('GlossaryPhases:TableHeaders:4'), //'Объем рабочего раствора на 1 га',
          width: '15%',
          class: 'col--center',
          type: 'text',
        },
        {
          text: this.$t('GlossaryPhases:TableHeaders:5'), //'Фото фазы',
          width: '10%',
          class: 'col--center',
          type: 'image',
        },
        // {
        //   text: this.$t('GlossaryPhases:TableHeaders:6'), //'Статус',
        //   class: 'col-status',
        //   type: 'status',
        // },
        {
          text: '',
          class: 'col-edit',
          width: '',
          type: 'edit',
          icon: this.canEdit ? 'edit' : 'passwordshow',
        },
      ];
    },
    dataArr() {
      let arr = [];

      this.data.phases?.forEach((phase) => {
        let tr = [];
        tr.push({ text: phase.name || '' });
        tr.push({
          text: this.getNameOfCultureByCultureId(phase?.culture_id) || '',
        });
        tr.push({
          text: this.getDate(phase.start_day, phase.start_month) || '',
        });
        tr.push({
          text: this.getDate(phase.end_day, phase.end_month) || '',
        });
        tr.push({ text: phase.volume_per_ha || '' });
        tr.push({ images: this.metaData.imagesMapByPhaseId.get(phase.id) });
        // tr.push({ text: phase.is_active === 1 ? 'on' : 'off' });
        tr.push({ text: phase.id });

        let filterIsActive = false;
        for (let filterKey in this.filter) {
          if (this.filter[filterKey]) {
            filterIsActive = true;
            break;
          }
        }
        if (filterIsActive) {
          let filerRes = true;

          if (this.filter.name !== '') {
            if (
              !(phase.name || '')
                .toLowerCase()
                .includes(this.filter.name.toLowerCase())
            ) {
              filerRes = false;
            }
          }
          if (this.filter.culture !== null) {
            if (
              /*!(this.getNameOfCultureByCultureId(phase?.culture_id) || '')
                .toLowerCase()
                .includes(this.filter.culture.toLowerCase())*/
              phase?.culture_id !== this.filter.culture
            ) {
              filerRes = false;
            }
          }
          if (filerRes) {
            arr.push(tr);
          }
        } else {
          arr.push(tr);
        }

        //arr.push(tr);
      });
      return arr;
    },
    isShowAddButton() {
      return (
        this.$store.state.auth?.currentUserRole?.claim_glossary_edit || false
      );
    },
  },
  watch: {
    filter: {
      handler(val) {
        this.$store.commit(mutationTypes.setFilterPhases, { ...val });
      },
      deep: true,
    },
  },
  async created() {
    try {
      /*
      return this.$store.state.filtersState.filterPhases;
      },
      set(payload) {
        this.$store.commit(mutationTypes.setFilterPhases, payload);
      },
      */
      this.data.cultures = this.$store.state.glossaryCultures.cultures;
      for (let filterKey in this.filter) {
        this.filter[filterKey] =
          this.$store.state.filtersState.filterPhases[filterKey];
      }

      this.request.isLoading = true;
      let response = await glossaryApi.getPhases();
      if (response.data.status === 'ok') {
        this.request.hasError = false;
        Object.keys(response.data).forEach((key) => {
          if (
            Object.keys(this.data).includes(key) &&
            response.data[key] instanceof Array
          ) {
            this.data[key] = response.data[key];
          }
        });
        this.prepareMetaData();
      } else {
        this.request.hasError = true;
      }
      this.request.isLoading = false;
    } catch (e) {
      this.request.hasError = true;
      this.request.isLoading = false;
      console.log(e);
    }
  },
};
</script>

<style lang="scss" scoped></style>
