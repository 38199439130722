import axios from '@/api/axios';

import { newTemplateFileds } from '@/store/modules/plantsCare';
import { serverKeysProgram } from '@/store/modules/programs';

const getTemplates = (timestamp, geoBase) =>
  axios
    .get('/plantsCare/getTemplates', {
      params: {
        categoriesCultures: timestamp,
        cultures: timestamp,
        templates: timestamp,
        geoBase,
      },
    })
    .then((res) => res.data);

const getNewTemplateData = async (timestamp, geoBase, products) => {
  const { data } = await axios.get('plantsCare/getNewTemplateData', {
    params: {
      ...Object.fromEntries(newTemplateFileds.map((key) => [key, timestamp])),
      geoBase,
      products,
    },
  });
  newTemplateFileds.forEach((key) => (data[key] ??= []));
  return data;
};

const getEditTemplateData = async (id, timestamp, geoBase, products) => {
  const { data } = await axios.get('plantsCare/getEditTemplateData', {
    params: {
      id,
      ...Object.fromEntries(newTemplateFileds.map((key) => [key, timestamp])),
      geoBase,
      products,
    },
  });
  data?.phases?.sort((a, b) =>
    a.start_month === b.start_month
      ? a.start_day - b.start_day
      : a.start_month - b.start_month
  );
  // newTemplateFileds.forEach((key) => (data[key] ??= []));
  return data;
};

const saveTemplateData = async (templateData) =>
  axios
    .post('plantsCare/updateTemplate', { templateData })
    .then((res) => res.data);

const getNewProgramData = async (timestamp, geoBase, clients) => {
  const { data } = await axios.get('plantsCare/getNewProgramData', {
    params: {
      ...Object.fromEntries(serverKeysProgram.map((key) => [key, timestamp])),
      geoBase,
      clients,
    },
  });
  serverKeysProgram.forEach((key) => (data[key] ??= []));
  return data;
};

const getEditProgramData = async (id, timestamp, geoBase, clients) => {
  const { data } = await axios.get('plantsCare/getEditProgramData', {
    params: {
      id,
      ...Object.fromEntries(serverKeysProgram.map((key) => [key, timestamp])),
      geoBase,
      clients,
    },
  });
  serverKeysProgram.forEach((key) => (data[key] ??= []));
  data?.phases?.sort((a, b) =>
    a.start_month === b.start_month
      ? a.start_day - b.start_day
      : a.start_month - b.start_month
  );
  return data;
};

const getClientData = (id) => {
  const controller = new AbortController();
  const promise = axios
    .get('plantsCare/getClientData', {
      signal: controller.signal,
      params: { id },
    })
    .then((res) => res.data);
  return { controller, promise };
};

const findTemplates = (params) => {
  const controller = new AbortController();
  const promise = axios
    .get('plantsCare/findTemplates', { signal: controller.signal, params })
    .then((res) => res.data);
  return { controller, promise };
};

const saveProgramData = async (programData) =>
  axios
    .post('plantsCare/updateProgram', { programData })
    .then((res) => res.data);

const getPrograms = async (programs = 0, geoBase = 0) =>
  axios
    .get('plantsCare/getPrograms', { params: { programs, geoBase } })
    .then((res) => res.data);

export default {
  getTemplates,
  getNewTemplateData,
  saveTemplateData,
  getEditTemplateData,
  getNewProgramData,
  getEditProgramData,
  getClientData,
  findTemplates,
  saveProgramData,
  getPrograms,
};
