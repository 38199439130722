<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('Users:Title') }]" />

      <div class="rightzone_top-buttonswrap">
        <Button
          v-if="isShowAddButton"
          :text="$t('Users:ButtonAdd')"
          size="standart"
          color="accent"
          icon="plus"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'NewUser' })"
        />
      </div>
    </div>

    <div class="rightzone_block">
      <FilterUsers v-model="filter" />
    </div>

    <div class="rightzone_table">
      <Table
        :headers="tableHeaders"
        :data-arr="dataArr"
        :table-loading="isTableLoading"
        :loading-count-items="10"
        @contactClick="onContactClick"
        @editClick="onEditClick"
      />

      <Modal
        v-if="showModalContacts"
        @backgroundClose="showModalContacts = false"
      >
        <ModalContacts v-model="showModalContacts" :user="modalUser" />
      </Modal>
    </div>
  </article>
</template>

<script>
import Button from '@/components/views/Button.vue';
import Breadcrumps from '@/components/views/Breadcrumps.vue';
import FilterUsers from '@/components/filtres/FilterUsers';
import Table from '@/components/tables/Table';
import ModalContacts from '@/components/modals/administration/ModalContacts';
import Modal from '@/pages/Modal';
import routeNames from '@/router/routeNames';
import { mapState } from 'vuex';

export default {
  name: 'AppUsers',
  components: {
    Modal,
    ModalContacts,
    FilterUsers,
    Button,
    Breadcrumps,
    Table,
  },
  data() {
    return {
      filter: {
        pn: '',
        name: '',
        full_name: '',
        username: '',
        status: '',
        role: '',
      },
      showModalContacts: false,
      modalUser: null,
    };
  },
  computed: {
    ...mapState({
      canEdit: (state) => state.auth.currentUserRole?.claim_users_edit === 1,
    }),
    tableHeaders() {
      return [
        {
          text: this.$t('Users:TableHeaders:0'), //'ID пользователя',
          class: 'col-userid',
          width: '17%',
          type: 'text',
        },
        {
          text: this.$t('Users:TableHeaders:1'), //'Логин',
          class: 'col-userlogin',
          width: '20%',
          type: 'text',
        },
        {
          text: this.$t('Users:TableHeaders:2'), //'Имя',
          class: 'col-username',
          width: '15%',
          type: 'text',
        },
        {
          text: this.$t('Users:TableHeaders:3'), //'Фамилия',
          class: 'col-usersurname',
          width: '15%',
          type: 'text',
        },
        {
          text: this.$t('Users:TableHeaders:4'), //'Дата рождения',
          class: 'col-userdate',
          width: '16%',
          type: 'text',
        },
        {
          text: this.$t('Users:TableHeaders:5'), //'Роль',
          class: 'col-userrole',
          width: '17%',
          type: 'text',
        },
        {
          text: this.$t('Users:TableHeaders:6'), //'Статус',
          class: 'col-status',
          width: '',
          type: 'status',
        },
        {
          text: '',
          class: 'col-contacts',
          width: '',
          type: 'contacts',
          icon: 'contacts',
        },
        {
          text: '',
          class: 'col-edit',
          width: '',
          type: 'edit',
          icon: this.canEdit ? 'edit' : 'passwordshow',
        },
      ];
    },
    isTableLoading() {
      return this.$store.state.admin.isUsersLoading;
    },
    dataArr() {
      let arr = [];

      if (this.$store.state.admin.users) {
        this.$store.state.admin.users.forEach((user) => {
          let tr = [];
          tr.push({ text: user.pn ? user.pn : '' });
          tr.push({ text: user.username ? user.username : '' });
          tr.push({ text: user.name ? user.name : '' });
          tr.push({ text: user.full_name ? user.full_name : '' });
          tr.push({
            text: user.birth_date
              ? new Date(user.birth_date).toLocaleDateString('ru-RU')
              : '',
          });
          tr.push({ text: user.role?.name ? user.role?.name : '' });
          tr.push({ text: user.is_blocked === 0 ? 'on' : 'alert' });
          tr.push({
            text: user.id ? user.id : '',
            count: user.contacts.length || 0,
          });
          tr.push({ text: user.id ? user.id : '' });

          let filterIsActive = false;
          for (let filterKey in this.filter) {
            if (this.filter[filterKey]) {
              filterIsActive = true;
              break;
            }
          }
          if (filterIsActive) {
            let filerRes = true;
            if (this.filter.pn) {
              if (
                !`${user.pn}`
                  .toLowerCase()
                  .includes(this.filter.pn.toLowerCase())
              ) {
                filerRes = false;
              }
            }
            if (this.filter.name) {
              if (
                !user.name
                  .toLowerCase()
                  .includes(this.filter.name.toLowerCase())
              ) {
                filerRes = false;
              }
            }
            if (this.filter.full_name) {
              if (
                !user.full_name
                  .toLowerCase()
                  .includes(this.filter.full_name.toLowerCase())
              ) {
                filerRes = false;
              }
            }
            if (this.filter.username) {
              if (
                !user.username
                  .toLowerCase()
                  .includes(this.filter.username.toLowerCase())
              ) {
                filerRes = false;
              }
            }
            if (this.filter.status) {
              if (user.is_blocked != this.filter.status) {
                filerRes = false;
              }
            }
            if (this.filter.role) {
              if (user.role_id != this.filter.role) {
                filerRes = false;
              }
            }
            if (filerRes) {
              arr.push(tr);
            }
          } else {
            arr.push(tr);
          }
        });
      }
      return arr;
    },
    /*filterArr(){
        return this.dataArr.filter((val)=>{
          console.log(val)
          return true;
        })
      }*/
    isShowAddButton() {
      return this.canEdit;
    },
  },
  methods: {
    onContactClick(userId) {
      this.modalUser = this.$store.state.admin.usersMapById.get(userId);
      this.showModalContacts = true;
    },
    onEditClick(userId) {
      let user = this.$store.state.admin.usersMapById.get(userId);
      if (user) {
        this.$router.push({
          name: routeNames.editUser,
          params: { id: user.id },
        });
      }
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped></style>
