import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/store/modules/auth';
import admin from '@/store/modules/admin';
import glossaryCategoriesCultures from '@/store/modules/glossaryCategoriesCultures';
import glossaryTypesStimulation from '@/store/modules/glossaryTypesStimulation';
import glossaryCultures from '@/store/modules/glossaryCultures';
import glossaryElements from '@/store/modules/glossaryElements';
import glossaryPests from '@/store/modules/glossaryPests';
import glossaryGeobase from '@/store/modules/glossaryGeobase';
import products from '@/store/modules/products';
import clients from '@/store/modules/clients';
import clientsList from '@/store/modules/clientsList';
import plantsCare from '@/store/modules/plantsCare';
import plantsCareTemplates from '@/store/modules/plantsCareTemplates';
import programs from '@/store/modules/programs';
import analyzes from '@/store/modules/analyzes';
import articles from '@/store/modules/articles';
import programsList from '@/store/modules/programsList';
import filtersState from '@/store/modules/filtersState';
import nutrition from '@/store/modules/nutritionPrograms';
import nutritionProgramsList from '@/store/modules/nutritionProgramsList';
import instructions from '@/store/modules/instructions';

import clientAuth from '@/store/modules/clientsArea/auth';
import clients2 from '@/store/modules/clientsArea/clients';
import userDefence from '@/store/modules/clientsArea/programs';
import userProgramsList from '@/store/modules/clientsArea/programsList';
import userArticles from '@/store/modules/clientsArea/articles';
import clientAnalyzes from '@/store/modules/clientsArea/analyzes';
import clientInstructions from '@/store/modules/clientsArea/instructions';
import myData from '@/store/modules/clientsArea/myData';
import userNutrition from '@/store/modules/clientsArea/nutritionPrograms';
import userNutritionProgramsList from '@/store/modules/clientsArea/nutritionProgramsList';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    admin,
    glossaryCategoriesCultures,
    glossaryTypesStimulation,
    glossaryCultures,
    glossaryElements,
    glossaryPests,
    glossaryGeobase,
    products,
    clients,
    clientsList,
    plantsCare,
    plantsCareTemplates,
    programs,
    programsList,
    analyzes,
    articles,
    instructions,
    filtersState,
    nutrition,
    nutritionProgramsList,
    clientAuth,
    clients2,
    userDefence,
    userProgramsList,
    userNutrition,
    userNutritionProgramsList,
    userArticles,
    myData,
    clientAnalyzes,
    clientInstructions,
  },
  // strict: process.env.NODE_ENV !== 'production',
});
