import axios from '@/api/axios';

import { newTemplateFileds } from '@/store/modules/plantsCare';
import { serverKeysProgram } from '@/store/modules/clientsArea/programs';

const getEditTemplateData = async (id, timestamp, geoBase, products) => {
  const { data } = await axios.get(
    'clientsArea/plantsCare/getEditTemplateData',
    {
      params: {
        id,
        ...Object.fromEntries(newTemplateFileds.map((key) => [key, timestamp])),
        geoBase,
        products,
      },
    }
  );
  data?.phases?.sort((a, b) =>
    a.start_month === b.start_month
      ? a.start_day - b.start_day
      : a.start_month - b.start_month
  );
  return data;
};

const getNewProgramData = async (timestamp, geoBase) => {
  const { data } = await axios.get('clientsArea/plantsCare/getNewProgramData', {
    params: {
      ...Object.fromEntries(serverKeysProgram.map((key) => [key, timestamp])),
      geoBase,
    },
  });
  serverKeysProgram.forEach((key) => (data[key] ??= []));
  return data;
};

const getEditProgramData = async (id, timestamp, geoBase) => {
  const { data } = await axios.get(
    'clientsArea/plantsCare/getEditProgramData',
    {
      params: {
        id,
        ...Object.fromEntries(serverKeysProgram.map((key) => [key, timestamp])),
        geoBase,
      },
    }
  );
  data?.phases?.sort((a, b) =>
    a.start_month === b.start_month
      ? a.start_day - b.start_day
      : a.start_month - b.start_month
  );
  serverKeysProgram.forEach((key) => (data[key] ??= []));
  return data;
};

const getClientData = () => {
  const controller = new AbortController();
  const promise = axios
    .get('clientsArea/plantsCare/getClientData', {
      signal: controller.signal,
    })
    .then((res) => res.data);
  return { controller, promise };
};

const findTemplates = (params) => {
  const controller = new AbortController();
  const promise = axios
    .get('clientsArea/plantsCare/findTemplates', {
      signal: controller.signal,
      params,
    })
    .then((res) => res.data);
  return { controller, promise };
};

const saveProgramData = async (programData) =>
  axios
    .post('clientsArea/plantsCare/updateProgram', { programData })
    .then((res) => res.data);

const getPrograms = async (programs = 0, geoBase = 0) =>
  axios
    .get('clientsArea/plantsCare/getPrograms', {
      params: { programs, geoBase },
    })
    .then((res) => res.data);

export default {
  getEditTemplateData,
  getNewProgramData,
  getEditProgramData,
  getClientData,
  findTemplates,
  saveProgramData,
  getPrograms,
};
