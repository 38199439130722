<!--Translated-->
<template>
  <table class="table--type2 table--defense">
    <thead>
      <tr>
        <th class="tbl-cellproblem">
          <div class="tbl_info">
            <div class="tbl_info-wrap">
              <div v-svg="{ name: 'stimulator' }"></div>
              <div class="tbl_info-celltext">
                {{ $t('ProgramTypesStimulationsTable:Header') }}
              </div>
            </div>
          </div>
        </th>
        <th class="tbl-cellproduct">
          {{ $t('ProgramTypesStimulationsTable:TableHeaders:0') }}
        </th>
        <th class="tbl-cellinpt">
          {{ $t('ProgramTypesStimulationsTable:TableHeaders:1') }}
        </th>
        <th class="tbl-cellprice">
          {{ $t('ProgramTypesStimulationsTable:TableHeaders:2') }}
        </th>
        <th
          v-if="phase.is_temporary && canEdit"
          class="col-delete unicol-colordark"
        >
          <div v-svg="{ name: 'delete' }"></div>
        </th>
      </tr>
    </thead>
    <template v-if="typesStimulations.length > 0">
      <RowStimulationType
        v-for="sType of typesStimulations"
        :sType="sType"
        :phase="phase"
        :key="sType.id"
        @deleteStimulationType="deleteStimulationType"
      />
    </template>
    <tbody v-else>
      <tr class="row tbl_row--active row--message">
        <td class="tbl-cellproblem" :colspan="phase.is_temporary ? 5 : 4">
          <div v-if="phase.is_temporary">
            <div class="cblock__infoblock">
              <p>
                {{
                  $t('ProgramTypesStimulationsTable:AddTypesStimulationText')
                }}
              </p>
              <Button
                v-if="canEdit"
                :text="
                  $t('ProgramTypesStimulationsTable:AddTypesStimulationButton')
                "
                size="standart"
                color="accentlines"
                icon="plus"
                @click="$emit('addStimulationType', phase.id)"
              />
            </div>
          </div>
          <div v-else>
            {{ $t('ProgramTypesStimulationsTable:NoText') }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapState } from 'vuex';
import { mutationTypes } from '@/store/modules/programs';
import Button from '@/components/views/Button';
import RowStimulationType from './ProgramRowStimulationType';

export default {
  name: 'ProgramTypesStimulationsTable',
  components: {
    Button,
    RowStimulationType,
  },
  props: { phase: { type: Object, required: true } },
  computed: {
    ...mapState({
      canEdit: (state) =>
        state.auth.currentUserRole?.claim_plants_care_add === 1,
      typesStimulations(state) {
        return [
          ...new Set(
            state.programs.programTypesStimulationProducts
              .filter(
                ({ program_phase_id }) => this.phase.id === program_phase_id
              )
              .map(({ type_stimulation_id }) =>
                state.programs.typesStimulation.find(
                  ({ id }) => id === type_stimulation_id
                )
              )
          ),
        ];
      },
    }),
  },
  methods: {
    deleteStimulationType(id) {
      this.$confirm({
        message: this.$t(
          'ProgramTypesStimulationsTable:ConfirmDeleteModal:Message'
        ),
        buttons: {
          left: {
            text: this.$t(
              'ProgramTypesStimulationsTable:ConfirmDeleteModal:ButtonLeft'
            ),
            icon: 'delete',
          },
          right: {
            text: this.$t(
              'ProgramTypesStimulationsTable:ConfirmDeleteModal:ButtonRight'
            ),
            icon: 'cancel',
          },
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.commit(mutationTypes.removeStimulationType, {
              id,
              phaseId: this.phase.id,
            });
          }
        },
      });
    },
  },
};
</script>
