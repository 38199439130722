<template>
  <article class="rightzone rightzone--article">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />
    </div>

    <!-- П.1 Анализ листа-->
    <div class="cwrap artclwrap el--borders-all el--rounded-all marginbottom--standart">

      <div class="artclhead">
        <div class="artclhead-num marginright--micro">
          01.
        </div>
        <div class="artclhead-text">
          აიღეთ ნიმუში
        </div>
      </div>

      <div class="artclblock">

        <div class="artclblock-media">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/wHMIgZXDA1o" frameborder="0" allowfullscreen></iframe>
          </div>
        </div>


        <!--Блок Файл ПДФ-->
        <div class="margintop--standart">

          <ComponentFile
              text="Инструкции по отбору проб листьев"
              icon="filepdf"
              link="https://drive.google.com/file/d/1ZeURruADZxq9Wbz6psDN6YaohZ8AGOCk/view?usp=sharing"
          />

        </div>




      </div>

    </div>


    <!-- П.2 Маркировка листа -->
    <div class="cwrap artclwrap el--borders-all el--rounded-all  marginbottom--standart">

      <div class="artclhead">
        <div class="artclhead-num marginright--micro">
          02.
        </div>
        <div class="artclhead-text">
          ეტიკეტების ნიმუშები
        </div>
      </div>

      <div class="artclblock">

        <!--Блок Файл ПДФ-->

        <div class="margintop--standart">
          <ComponentFile
              text="Инструкции по отбору образцов листьев"
              icon="filepdf"
              link="https://drive.google.com/file/d/1yORTNJR3rr5a1Z87R39XNoMa_rpVheOD/view?usp=sharing"
          />
        </div>
      </div>

    </div>


    <!--Блок Гео-->
    <GuideAnalysisGeo/>

    <!--Блок Отслеживайте-->
    <GuideAnalysisTrack/>


    <!--Футтер-->
    <UserFooter />
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import UserFooter from '@/pages/userproject/userComponents/UserFooter';
import LocationSelect from "@/components/views/LocationSelect";
import Button from "@/components/views/Button";
import GuideAnalysisGeo from "@/pages/userproject/userArticles/Components/GuideAnalysisGeo";
import GuideAnalysisTrack from "@/pages/userproject/userArticles/Components/GuideAnalysisTrack";
import ComponentFile from "@/pages/userproject/userArticles/Components/ComponentFile";
import routeNames from "@/router/routeNames";

export default {
  name: 'UserAnalysesInstruction3',
  components: {
    ComponentFile,
    GuideAnalysisTrack,
    GuideAnalysisGeo,
    LocationSelect,
    UserFooter,
    Breadcrumps,
    Button
  },
  computed: {
    breadcrumpsArr() {
      return [
        { title: 'Все нализы', route: routeNames.userAnalyzes },
        { title:  'Инструкция - Анализ листа'},
      ];
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/vars.scss';





</style>
