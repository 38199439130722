<!--Translated-->
<template>
  <article class="rightzone rightzone--defence-newprogram">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />

      <div class="rightzone_top-buttonswrap">
        <!--<Button
          text="Инструкция"
          size="s-standart"
          color="graylines"
          icon="info"
          :is-loading="false"
          :loading-left="false"
          @click="goToGuide"
        />-->
        <Button
          :text="$t('UserDefenceProgramEdit:TopButtonUndo')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: routeBack })"
        />
        <Button
          :text="$t('UserDefenceProgramEdit:TopButtonSave')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="isSaving"
          :loading-left="true"
          :disabled="isLoading || !udCanSave"
          @click="saveProgram"
        />
      </div>
    </div>

    <LoaderBlock v-if="isLoading" />
    <template v-else>
      <UserDefenceProgramDescription />

      <TemplatesList v-if="!programHasPhases" :templates="templates" />

      <!-- <SelectedTemplateBlock
        v-if="!isEditing && selectedTemplate"
        :text="selectedTemplate.name"
        :canCancel="true"
        @click="cancelTemplateSelection"
      /> -->

      <LoaderBlock v-if="templateFetching" style="margin-top: 30px" />

      <DefenseProgram v-if="programHasPhases" />

      <div class="cwrap" v-if="programHasPhases">
        <div class="cblock__line">
          <div class="cblock__line-block">
            <Button
              :text="$t('UserDefenceProgramEdit:TopButtonSave')"
              size="standart"
              color="accent"
              icon="save"
              :is-loading="isSaving"
              :loading-left="true"
              :disabled="isLoading || !udCanSave"
              @click="saveProgram"
            />
            <Button
              text="print"
              size="s-standart"
              color="accentlines"
              icon="print"
              @click="printProgram"
              :disabled="!programHasPhases || udCanSave"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditing && !(isLoading || isSaving)"
        class="cwrap marginbottom--standart margintop--standart"
      >
        <div class="chead el--borders-top el--borders-sides el--rounded-top">
          <div class="chead__line-block">
            <div
              class="vsvg vsvg--standart chead_icon"
              v-svg="{ name: 'delete' }"
            ></div>
            <div class="chead_text">
              {{ $t('UserDefenceProgramEdit:DeleteText') }}
            </div>
          </div>
        </div>

        <div v-if="isEditing" class="cblock el--borders-all el--rounded-bottom">
          <div class="cblock__infoblock">
            <p>
              {{ $t('UserDefenceProgramEdit:DeleteText2') }}
              {{ $t('UserDefenceProgramEdit:DeleteText3') }}
            </p>

            <Button
              :text="$t('UserDefenceProgramEdit:DeleteButton')"
              size="standart"
              color="alertlines"
              icon="userdelete"
              @click="deleteProgram"
            />
          </div>
        </div>
      </div>
    </template>
    <PrintModal
      v-if="selectedPrintId"
      :clientArea="true"
      @close="selectedPrintId = null"
      :selectedPrintId="selectedPrintId"
    />
  </article>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import {
  mutationTypes,
  actionTypes,
} from '@/store/modules/clientsArea/programs';
import { createConfirmCallback } from '@/helpers/confirmModalHelper';

import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';
import routeNames from '@/router/routeNames';
import UserDefenceProgramDescription from './userDefenceComponents/UserDefenceProgramDescription';
import TemplatesList from './userDefenceComponents/TemplatesList';
import DefenseProgram from './userDefenceComponents/DefenseProgram';
// import SelectedTemplateBlock from '@/pages/programs/components/SelectedTemplateBlock';
import LoaderBlock from '@/components/blocks/LoaderBlock';
import PrintModal from '@/components/modals/ModalPrint';

export default {
  name: 'UserDefenseProgramEdit',
  components: {
    UserDefenceProgramDescription,
    Button,
    Breadcrumps,
    TemplatesList,
    DefenseProgram,
    // SelectedTemplateBlock,
    LoaderBlock,
    PrintModal,
  },
  data() {
    return { nextRouteConfirm: false, selectedPrintId: null };
  },
  computed: {
    ...mapGetters(['udCanSave', 'udWasEdited']),
    ...mapState({
      templates: (state) => state.userDefence.templates,
      selectedTemplate: (state) => state.userDefence.selectedTemplate,
      program: (state) => state.userDefence.program,
      programHasPhases: (state) =>
        Boolean(state.userDefence.programPhases.length),
      isLoading: (state) => state.userDefence.isLoading,
      templateFetching: (state) => state.userDefence.templateFetching,
      isSaving: (state) => state.userDefence.isSaving,
      error: (state) => state.userDefence.error,
    }),
    isEditing() {
      return this.$route.name === routeNames.userDefenceProgramEdit;
    },
    routeBack() {
      return routeNames.userDefence;
    },
    breadcrumpsArr() {
      return [
        {
          title: this.$t('UserDefenceProgramEdit:Title'),
          route: this.routeBack,
        },
        {
          title: this.isEditing
            ? this.$t('UserDefenceProgramEdit:TitleEdit')
            : this.$t('UserDefenceProgramEdit:TitleCreate'),
        },
      ];
    },
  },
  watch: {
    error: {
      handler(error) {
        if (error) {
          this.$awn.alert(String(error));
          throw error;
        }
      },
      immediate: true,
    },
    selectedTemplate(template) {
      if (template) {
        this.$store.dispatch(actionTypes.fetchTemplate, template.id);
      }
    },
  },
  methods: {
    printProgram() {
      this.selectedPrintId = this.program.id;
    },
    cancelTemplateSelection() {
      this.$confirm({
        message: this.$t('UserDefenceProgramEdit:ModalConfirmCT:Message'),
        buttons: {
          left: {
            text: this.$t('UserDefenceProgramEdit:ModalConfirmCT:ButtonOk'),
            icon: 'tick',
            color: 'accentlines',
          },
          right: {
            text: this.$t('UserDefenceProgramEdit:ModalConfirmCT:ButtonUndo'),
            icon: 'cancel',
            color: 'graylines',
          },
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.commit(mutationTypes.selectTemplate, null);
          }
        },
      });
    },
    saveProgram() {
      this.$store
        .dispatch(actionTypes.saveProgram)
        .then(this.afterSaveNavifation);
    },
    afterSaveNavifation() {
      this.$confirm({
        message:
          this.$t('UserDefenceProgramEdit:ModalSave:Message1') +
          '<br/>' +
          this.$t('UserDefenceProgramEdit:ModalSave:Message2'),
        buttons: {
          left: {
            text: this.$t('UserDefenceProgramEdit:ModalSave:ButtonGo'),
            icon: 'tick',
            color: 'accentlines',
          },
          right: {
            text: this.$t('UserDefenceProgramEdit:ModalSave:ButtonNotGo'),
            icon: 'cancel',
            color: 'graylines',
          },
        },
        callback: (confirm) => {
          if (confirm) {
            this.nextRouteConfirm = true;
            this.$router.push({ name: routeNames.userDefence });
          } else {
            if (this.isEditing) {
              this.$store.commit(mutationTypes.resetState);
              this.$store.dispatch(
                actionTypes.fetchProgram,
                this.$store.state.userDefence.savedRedirectId
              );
            } else {
              this.nextRouteConfirm = true;
              this.$router.push({
                name: routeNames.userDefenceProgramEdit,
                params: { id: this.$store.state.userDefence.savedRedirectId },
              });
            }
          }
        },
      });
    },
    deleteProgram() {
      this.$confirm({
        message: this.$t('UserDefenceProgramEdit:ModalDelete:Message'),
        buttons: {
          left: {
            text: this.$t('UserDefenceProgramEdit:ModalDelete:ButtonDelete'),
            icon: 'delete',
          },
          right: {
            text: this.$t('UserDefenceProgramEdit:ModalDelete:ButtonUndo'),
            icon: 'cancel',
          },
        },
        callback: (confirm) => {
          if (confirm) {
            this.nextRouteConfirm = true;
            this.$store
              .dispatch(actionTypes.deleteProgram)
              .then(() => this.$router.push({ name: this.routeBack }));
          }
        },
      });
    },
    goToGuide() {
      const { href } = this.$router.resolve({
        name: routeNames.userGuideFertilizer,
      });
      window.open(href, '_blank');
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.nextRouteConfirm || !this.udWasEdited) {
      next();
    } else {
      this.$confirm({
        message:
          this.$t('UserDefenceProgramEdit:ModalRouteLeave:Message1') +
          '<br />' +
          this.$t('UserDefenceProgramEdit:ModalRouteLeave:Message2'),
        buttons: {
          left: {
            text: this.$t('UserDefenceProgramEdit:ModalRouteLeave:ButtonGo'),
            icon: 'delete',
            color: 'alertlines',
          },
          right: {
            text: this.$t('UserDefenceProgramEdit:ModalRouteLeave:ButtonUndo'),
            icon: 'cancel',
            color: 'accentlightlines',
          },
        },
        callback: createConfirmCallback(next),
      });
    }
  },
};
</script>
