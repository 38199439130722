<!--Translated-->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('DefenseTemplates:Title') }]" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('DefenseTemplates:ButtonAdd')"
          size="standart"
          color="accent"
          icon="plus"
          :is-loading="false"
          :loading-left="false"
          :disabled="!canEdit"
          @click="$router.push({ name: 'NewDefenseTemplate' })"
        />
      </div>
    </div>

    <div class="rightzone_block">
      <div class="filter">
        <div class="filter-inputszone">
          <div class="filterwrap filterwrap--clients">
            <div class="filterwrap_line">
              <Input
                type="text"
                :placeholder="$t('DefenseTemplates:Filter:Name.Placeholder')"
                :label="$t('DefenseTemplates:Filter:Name.Label')"
                icon="search"
                size="long"
                v-model="filters.name"
              />
              <DropdownSelect
                :placeholder="$t('DefenseTemplates:Filter:Status.Placeholder')"
                :label="$t('DefenseTemplates:Filter:Status.Label')"
                :notSelectedText="
                  $t('DefenseTemplates:Filter:Status.NotSelected')
                "
                :options="[
                  {
                    text: $t('DefenseTemplates:Filter:Status.Active'),
                    value: 1,
                  },
                  { text: $t('DefenseTemplates:Filter:Status.Hide'), value: 0 },
                ]"
                v-model="filters.status"
              />
              <DropdownSelect
                :placeholder="$t('DefenseTemplates:Filter:Default.Placeholder')"
                :label="$t('DefenseTemplates:Filter:Default.Label')"
                :notSelectedText="
                  $t('DefenseTemplates:Filter:Default.NotSelected')
                "
                :options="[
                  {
                    text: $t('DefenseTemplates:Filter:Default.OnlyDefault'),
                    value: 1,
                  },
                ]"
                v-model="filters.template"
              />
            </div>

            <div class="filterwrap_line">
              <CultureSelect
                ref="culture"
                :categories="categories"
                :cultures="cultures"
                :category="filters.cultureCategory"
                v-model="filters.culture"
                @categorySelect="filters.cultureCategory = $event"
              />
              <CultureAgeSelect v-model="filters.age" />
              <CalcMethodSelect v-model="filters.calcMethod" />
            </div>

            <div class="filterwrap_line">
              <LocationSelect
                ref="location"
                :geoEntries="geoMapped"
                :freeSelect="true"
                :type="2"
                v-model="filters.geo"
              />
            </div>
          </div>
        </div>

        <div class="filter-clearbtnzone">
          <Button
            class="filter_buttonclear"
            text="очистить"
            size="s-standart"
            icon="backspace"
            :is-loading="false"
            :loading-left="true"
            :color="filtersApplied ? 'accentlines' : 'graylines'"
            @click="reset"
          />
        </div>
      </div>
    </div>

    <div class="rightzone_table">
      <Table
        :headers="headers"
        :dataArr="rows"
        @editClick="editTemplate"
        :tableLoading="isLoading"
        :loadingCountItems="10"
      >
        <template v-slot:warning-icons>
          <div v-svg="{ name: 'warningdiese' }" />
          <div class="fill-svg--accent" v-svg="{ name: 'defaultstar' }" />
        </template>
      </Table>
    </div>
  </article>
</template>

<script>
import isEqual from 'lodash.isequal';
import { mapGetters, mapState } from 'vuex';

import { mapInputEntityWithMerge } from '@/components/adapters/mapInput';
import { staticData } from '@/store/modules/plantsCare';
import routeNames from '@/router/routeNames';

import Table from '@/components/tables/Table';
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import DropdownSelect from '@/components/views/wrappers/DropdownSelectValue';
import CultureSelect from '@/components/views/CultureSelect';
import LocationSelect from '@/components/views/LocationSelect';
import CultureAgeSelect from '@/components/views/CultureAgeSelect';
import CalcMethodSelect from '@/components/views/CalcMethodSelect';
import { mutationTypes } from '@/store/modules/filtersState';

//При изменении фильтра не забыть изменить базовый в вьюъексе (сохранение состояния)
const defaultFilters = {
  name: '',
  status: null,
  template: null,
  cultureCategory: null,
  culture: null,
  age: null,
  calcMethod: null,
  geo: null,
};

export default {
  name: 'DefenseTemplates',
  components: {
    Button,
    Breadcrumps,
    Input,
    DropdownSelect,
    CultureSelect,
    LocationSelect,
    Table,
    CultureAgeSelect,
    CalcMethodSelect,
  },
  data() {
    return {
      filters: { ...defaultFilters },
    };
  },
  computed: {
    ...mapGetters(['geoMapped', 'geoPlainDataAsMap']),
    ...mapState({
      isLoading: (state) => state.plantsCareTemplates.isLoading,
      categories: (state) =>
        state.plantsCareTemplates.categoriesCultures.map(
          mapInputEntityWithMerge
        ),
      canEdit: (state) =>
        state.auth.currentUserRole?.claim_plants_care_edit === 1,
      cultures: (state) =>
        state.plantsCareTemplates.cultures.map(mapInputEntityWithMerge),
      rows(state) {
        const f = this.filters;

        const mapper = (row) => ({
          ...row,
          culture: this.cultures.find(({ id }) => row.culture_id === id),
          methodName:
            staticData.calculationMethodsShort[row.calculation_method_id],
          regionName: this.geoPlainDataAsMap.get(row.geo_id)?.name,
          geoChain:
            this.geoPlainDataAsMap.get(row.geo_id)?.order?.map((e) => e.id) ??
            [],
        });

        const mapToRow = (input) =>
          [
            input.is_default,
            input.name,
            input.culture.name,
            input.culture_age,
            input.methodName,
            input.regionName,
            input.is_active ? 'on' : 'off',
            input.id,
          ].map((text, index) => ({
            text: index === 2 && text === 0 ? '< 1' : text,
          }));

        const filter = (row) =>
          row.name.includes(f.name) &&
          (f.status === null ? true : row.is_active === f.status) &&
          (f.template === null ? true : f.template === row.is_default) &&
          (f.culture === null
            ? f.cultureCategory === null
              ? true
              : this.cultures
                  .filter(
                    ({ category_id }) => category_id === f.cultureCategory
                  )
                  .some((c) => c.id === row.culture.id)
            : f.culture === row.culture_id) &&
          (f.age === null
            ? true
            : f.age === 11
            ? f.age <= row.culture_age
            : f.age === row.culture_age) &&
          (f.calcMethod === null
            ? true
            : f.calcMethod == row.calculation_method_id) &&
          (f.geo === null ? true : row.geoChain.includes(f.geo));

        return state.plantsCareTemplates.templates
          .map(mapper)
          .filter(filter)
          .sort((a, b) => b.is_default - a.is_default)
          .map(mapToRow);
      },
    }),
    headers() {
      return [
        {
          text: '',
          class: 'col-warning',
          icon: 'warningdiese',
          type: 'warning',
        },
        {
          text: this.$t('DefenseTemplates:Filter:TableHeaders:0'), //'Название шаблона',
          width: '35%',
          type: 'text',
        },
        {
          text: this.$t('DefenseTemplates:Filter:TableHeaders:1'), //'Культура',
          type: 'text',
          width: '13%',
        },
        {
          text: this.$t('DefenseTemplates:Filter:TableHeaders:2'), //'Возраст',
          width: '8%',
          type: 'text',
        },
        {
          text: this.$t('DefenseTemplates:Filter:TableHeaders:3'), //'Метод',
          width: '8%',
          type: 'text',
        },
        {
          text: this.$t('DefenseTemplates:Filter:TableHeaders:4'), //'Место',
          type: 'text',
        },
        {
          text: this.$t('DefenseTemplates:Filter:TableHeaders:5'), //'Статус',
          class: 'col-status',
          type: 'status',
        },
        {
          text: '',
          class: 'col-edit',
          width: '',
          type: 'edit',
          icon: this.canEdit ? 'edit' : 'passwordshow',
        },
      ];
    },
    filtersApplied() {
      return !isEqual(this.filters, defaultFilters);
    },
  },
  methods: {
    reset() {
      this.filters = { ...defaultFilters };
      this.$refs.location.reset();
      this.$refs.culture.reset();
    },
    editTemplate(id) {
      this.$router.push({
        name: routeNames.editDefenseTemplate,
        params: { id },
      });
    },
  },
  watch: {
    filters: {
      handler(val) {
        this.$store.commit(mutationTypes.setFilterPlantsCareTemplates, {
          ...val,
        });
      },
      deep: true,
    },
  },
  created() {
    // console.log(this.filters);
    for (let filterKey in this.filters) {
      this.filters[filterKey] =
        this.$store.state.filtersState.filterPlantsCareTemplates[filterKey];
    }
  },
};
</script>

<style lang="scss">
@import '~@/scss/vars.scss';

.fill-svg--accent {
  & svg * {
    fill: $colorAccent !important;
  }
}
</style>
