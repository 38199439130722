<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps
        :data="[
          { title: $t('GlossaryRegions:Title'), route: routeBack },
          { title },
        ]"
      />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('GlossaryRegionsNew:ButtonCancel')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          :disabled="isUpdating"
          @click="$router.push({ name: routeBack })"
        />

        <Button
          :text="$t('GlossaryRegionsNew:ButtonSave')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="true"
          :loading-left="isUpdating"
          :disabled="cantSubmit"
          @click="save"
          :isLoading="isUpdating"
        />
      </div>
    </div>

    <div class="cwrap cwrap--level1 marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'edit' }"
          ></div>
          <div class="chead_text">{{ title }}</div>
        </div>
      </div>

      <div class="cblock el--borders-all el--rounded-bottom">
        <div class="cblock__internal">
          <template v-for="(parent, index) in parents">
            <div
              class="cblock__line"
              :key="parent.id"
              v-if="index <= parentType"
            >
              <div class="cblock__line-block">
                <Input
                  v-if="index + 2 === parents.length"
                  type="dropdown"
                  :placeholder="makePlaceholder(parent.type)"
                  :label="makeName(parent.type)"
                  size="long"
                  :dd-items="allChildren"
                  :v-model="selectedParent"
                  :dd-selected-index="selectedParentIndex"
                  @selected="parentSelected"
                  :state="isLoading || isUpdating ? 'disabled' : ''"
                />
                <Input
                  v-else
                  type="dropdown"
                  :placeholder="makePlaceholder(parent.type)"
                  :label="makeName(parent.type)"
                  icon=""
                  size="long"
                  color=""
                  :dd-items="makeList(index)"
                  state="disabled"
                />
              </div>
            </div>
          </template>

          <div class="cblock__line" v-if="serverType > 2">
            <div class="cblock__line-block">
              <RadioToggle
                name="settlementType"
                :text1="$t('GlossaryRegions:City')"
                :text2="$t('GlossaryRegions:Village')"
                :label="$t('GlossaryRegions:Type')"
                isTypeBlocked=" "
                isTypeDisabled=" "
                v-model="settlementType"
                :disabled="isLoading || isUpdating"
              />
            </div>
          </div>
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                :placeholder="$t('GlossaryRegionsNew:NewText')"
                :label="geoName"
                v-model="name"
                size="long"
                :state="isLoading || isUpdating ? 'disabled' : ''"
              />
            </div>
          </div>

          <!--<div class="cblock__line">
            <div class="cblock__line-block">
              <RadioToggle
                name="isActive"
                :text1="$t('GlossaryRegionsNew:Active:Hide')"
                :text2="$t('GlossaryRegionsNew:Active:Active')"
                :label="$t('GlossaryRegionsNew:Active:Label')"
                reverse="true"
                v-model="is_active"
                :disabled="isLoading || isUpdating"
              />
            </div>
          </div>-->
        </div>
      </div>
    </div>
    <Button
      :text="$t('GlossaryRegionsNew:ButtonSave')"
      size="standart"
      color="accent"
      icon="save"
      :is-loading="true"
      :loading-left="isUpdating"
      :disabled="cantSubmit"
      @click="save"
      :isLoading="isUpdating"
    />
    <Modal
      v-if="showModalNotSaved"
      @backgroundClose="showModalNotSaved = false"
    >
      <modalNotSaved
        v-model="showModalNotSaved"
        @confirm="onModalNotSavedConfirm"
      />
    </Modal>
  </article>
</template>

<script>
import { mapState } from 'vuex';
import { actionTypes } from '@/store/modules/glossaryGeobase';
import routeNames from '@/router/routeNames';
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import RadioToggle from '@/components/views/RadioToggle';
import { mapGeoEntity } from './GlossaryRegions';
import Modal from '@/pages/Modal';
import modalNotSaved from '@/components/modals/modalNotSaved';

export const keys = ['parent_id', 'name', 'type', 'is_active'];
/*export const typeTexts = [
  'Страна',
  'Регион',
  'Муниципалитет',
  'Населенный пункт',
  'Населенный пункт',
];
export const typeTextsSelect = typeTexts.map((e) =>
  e.toLowerCase().replace('страна', 'страну')
);*/

export default {
  name: 'GlossaryRegionsEdit',
  components: {
    RadioToggle,
    Input,
    Button,
    Breadcrumps,
    Modal,
    modalNotSaved,
  },
  data() {
    return {
      routeBack: routeNames.regions,
      id: null,
      parent_id: null,
      name: '',
      type: null,
      is_active: null,
      parents: [],
      selectedParent: null,
      selectedParentIndex: 0,
      settlementType: null,
      showModalNotSaved: false,
      nextRoute: null,
      nextRouteConfirm: false,
      notSelectedItem: {
        value: null,
        text: this.$t('GlossaryRegions:NotSelected'),
      },
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.glossaryGeobase.isGeobaseLoading,
      hasLoadingError: (state) =>
        state.glossaryGeobase.isGeobaseLoadingHasError,
      canEdit: (state) => state.auth.currentUserRole?.claim_glossary_edit,
      serverData: (state) => state.glossaryGeobase.geobaseRaw,
      isUpdating: (state) => state.glossaryGeobase.isGeobaseUpdating,
      isUpdatingDone: (state) => state.glossaryGeobase.isGeobaseUpdatingDone,
      updateError: (state) => state.glossaryGeobase.geobaseUpdatingError,
    }),
    typeTexts() {
      return [
        this.$t('GlossaryRegionsNew:Arr:0'), //'Страна',
        this.$t('GlossaryRegionsNew:Arr:1'), //'Регион',
        this.$t('GlossaryRegionsNew:Arr:2'), //'Муниципалитет',
        this.$t('GlossaryRegionsNew:Arr:3'), //'Населенный пункт',
        this.$t('GlossaryRegionsNew:Arr:3'), //'Населенный пункт',
      ];
    },
    typeTextsSelect() {
      return this.typeTexts.map((e) =>
        e
          .toLowerCase()
          .replace(
            this.$t('GlossaryRegionsNew:Arr:Country1'),
            this.$t('GlossaryRegionsNew:Arr:Country2')
          )
          .replace(
            this.$t('GlossaryRegionsNew:Arr:1'),
            this.$t('GlossaryRegionsNew:Arr:Region2')
          )
          .replace(
            this.$t('GlossaryRegionsNew:Arr:2'),
            this.$t('GlossaryRegionsNew:Arr:Mun2')
          )
          .replace(
            this.$t('GlossaryRegionsNew:Arr:3'),
            this.$t('GlossaryRegionsNew:Arr:NP2')
          )
      );
    },
    title() {
      if (!this.serverEntry) return '';
      return `${this.typeTextsSelect[this.serverEntry.type]} ${this.$t(
        'GlossaryRegionsEdit:Edit'
      )}`;
    },
    geoName() {
      return this.serverEntry ? this.typeTexts[this.serverEntry.type] : '';
    },
    serverEntry() {
      return this.serverData.find(
        (e) => Number(this.$route.params.id) === e.id
      );
    },
    serverType() {
      return this.serverEntry?.type;
    },
    parentType() {
      return this.serverType - (this.serverType > 3 ? 2 : 1);
    },
    allChildren() {
      const parent = this.parents.find((e) => e.type === this.parentType);
      if (!parent) return [];
      const grandParent = this.parents.find((e) => e.id === parent.parent_id);
      return [
        this.notSelectedItem,
        ...this.serverData
          .filter((e) =>
            grandParent ? e.parent_id === grandParent.id : e.type === 0
          )
          .map((e) => mapGeoEntity(e)),
      ];
    },
    wasEdited() {
      if (!this.serverEntry) return false;
      return !keys.every((key) => this[key] === this.serverEntry[key]);
    },
    cantSubmit() {
      return (
        !this.wasEdited ||
        !this.name ||
        (this.type !== 0 && !this.selectedParent?.value)
      );
    },
  },
  watch: {
    serverEntry: {
      handler(entry) {
        if (entry) this.reset(entry);
      },
      immediate: true,
    },
    allChildren: {
      handler(children) {
        const index = children.findIndex(
          (e) => e.value === this.serverEntry?.parent_id
        );
        this.selectedParentIndex = index;
        this.selectedParent = children[index];
      },
      immediate: true,
    },
    settlementType(val) {
      if (val === 0) this.type = 3;
      if (val === 1) this.type = 4;
    },
    updateError(val) {
      console.error(val);
    },
    hasLoadingError() {
      console.error('has loading error');
    },
    isUpdatingDone() {
      if (this.isUpdatingDone) {
        this.nextRouteConfirm = true;
        this.$router.push({ name: routeNames.regions });
      }
    },
  },
  methods: {
    findAllParents(id, acc = []) {
      const item = this.serverData.find((e) => e.id === id);
      acc.push(item);
      return item.parent_id
        ? this.findAllParents(item.parent_id, acc)
        : acc.reverse();
    },
    makePlaceholder(type) {
      return `${this.$t('GlossaryRegionsEdit:Select')} ${
        this.typeTextsSelect[type]
      }...`;
    },
    makeName(type) {
      return this.typeTexts[type];
    },
    makeList(index) {
      const child = this.parents[index + 1];
      if (!child) return [];
      return this.serverData
        .filter((e) => e.id === child.parent_id)
        .map((e) => mapGeoEntity(e));
    },
    parentSelected({ index, item }) {
      this.selectedParentIndex = index;
      this.selectedParent = item;
      if (item?.value) {
        this.parent_id = item.value;
      }
    },
    reset(entry) {
      keys.forEach((key) => (this[key] = entry[key]));
      this.parents = this.findAllParents(entry.id, []);
      if (entry.type === 3) this.settlementType = 0;
      else if (entry.type === 4) this.settlementType = 1;
    },
    save() {
      if (this.isUpdating) return;
      const payload = {
        id: this.serverEntry.id,
        ...Object.fromEntries(keys.map((k) => [k, this[k]])),
      };
      this.$store.dispatch(actionTypes.updateGeobase, payload);
    },
    onModalNotSavedConfirm() {
      if (this.nextRoute) {
        this.nextRouteConfirm = true;
        this.$router.push(this.nextRoute);
      }
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
  beforeRouteLeave(to, from, next) {
    if (this.nextRouteConfirm || !this.canEdit || !this.wasEdited) {
      next();
    } else {
      this.showModalNotSaved = true;
      this.nextRoute = to;
      next(false);
    }
  },
};
</script>
