<!--Translated-->
<template>
  <tbody>
    <tr
      class="row"
      :class="{ 'tbl_row--active': getSelectedState(product) }"
      v-for="(product, index) in productsFiltered"
      :key="product.id"
    >
      <td
        class="tbl-cellproblem"
        v-if="index === 0"
        :rowspan="productsFiltered.length"
      >
        <div class="tbl_info">
          <div class="tbl_info-wrap">
            <div v-svg="{ name: 'info' }"></div>
            <HoverTooltip
              :header="sType.name"
              :text="sType.description"
              :noSubstance="true"
            />
          </div>
          <div class="tbl_info-celltext">{{ sType.name }}</div>
        </div>
      </td>

      <td class="tbl-cellproduct">
        <div class="tbl_info">
          <div class="tbl_info-wrap">
            <div v-if="!phase.is_temporary" class="tbl_checkboxproduct">
              <CheckBox
                color-checked="accentfill"
                color-un-checked="gray"
                @input="onChange(product, $event)"
                :value="getSelectedState(product)"
              />
            </div>
            <div v-else v-svg="{ name: 'info' }"></div>
            <UserHoverTooltipProductDefence
              :product="product"
              :noSubstance="true"
            />
          </div>
          <div
            class="tbl_info-celltext"
            @click="onChange(product, true)"
            :style="calcProductNameStyle(product)"
          >
            {{ product.name }}
          </div>
        </div>
      </td>
      <td class="tbl-cellprice" v-if="!phase.is_temporary">
        {{ productsAsMap[product.id] }}
      </td>
      <td v-else class="tbl-cellinpt">
        <InputNumber
          class="inputintable"
          label="-"
          :value="productsAsMap[product.id]"
          @input="onInput(product, $event)"
          :state="isSaving ? 'disabled' : ''"
        />
      </td>
      <td class="tbl-cellprice">
        {{
          product.picking
            ? (
                (product.price / product.picking) *
                productsAsMap[product.id]
              ).toFixed(2)
            : '0.00'
        }}
      </td>
      <td
        v-if="index === 0 && phase.is_temporary"
        :rowspan="productsFiltered.length"
        class="col-delete"
        @click="$emit('deleteStimulationType', sType.id)"
      >
        <div v-svg="{ name: 'delete' }"></div>
      </td>
    </tr>

    <tr class="row tbl_row--active" v-if="productsFiltered.length === 0">
      <td class="tbl-cellproblem" rowspan="1">
        <div class="tbl_info">
          <div class="tbl_info-wrap">
            <div v-svg="{ name: 'info' }"></div>
            <HoverTooltip
              :header="sType.name"
              :text="sType.description"
              :noSubstance="true"
            />
          </div>
          <div class="tbl_info-celltext">{{ sType.name }}</div>
        </div>
      </td>
      <td class="tbl-cellproduct" colspan="3" style="text-align: center">
        {{ $t('ProgramRowStimulationType:NoData') }}
      </td>
    </tr>

    <tr class="tablelinedecor">
      <td :colspan="phase.is_temporary ? 5 : 4"></td>
    </tr>
  </tbody>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { mutationTypes } from '@/store/modules/clientsArea/programs';

import InputNumber from '@/components/views/wrappers/InputNumberWithCast';
import HoverTooltip from '@/pages/programs/components/HoverTooltip';
import CheckBox from '@/components/CheckBox';
import UserHoverTooltipProductDefence from '@/pages/userproject/userPrograms/userDefenceComponents/HoverTooltipProduct';

export default {
  name: 'UserProgramRowStimulationType',
  props: {
    phase: { type: Object, required: true },
    sType: { type: Object, required: true },
  },
  components: {
    UserHoverTooltipProductDefence,
    InputNumber,
    CheckBox,
    HoverTooltip,
  },
  computed: {
    ...mapGetters(['plantsCareProductsAsMap', 'udSTypesProductsAsMap']),
    ...mapState({
      isSaving: (state) => state.userDefence.isSaving,
      programTypesStimulationProducts: (state) =>
        state.userDefence.programTypesStimulationProducts,
      products(state) {
        return state.userDefence.productsTypesStimulation
          .filter(
            ({ type_stimulation_id }) => type_stimulation_id === this.sType.id
          )
          .map(({ product_id }) => this.plantsCareProductsAsMap[product_id])
          .filter(Boolean)
          .filter(
            (product) =>
              product.is_active ||
              state.userDefence.programTypesStimulationProducts.some(
                (p) =>
                  p.product_id === product.id &&
                  p.quantity &&
                  (p.is_selected ||
                    state.userDefence.serverData.programTypesStimulationProducts.find(
                      ({ id }) => id === p.id
                    )?.is_selected)
              )
          );
      },
    }),
    productsAsMap() {
      const getValueById = (id) =>
        this.udSTypesProductsAsMap[
          `phase_${this.phase.id}_stype_${this.sType.id}_product_${id}`
        ]?.quantity ?? '';
      return Object.fromEntries(
        this.productsFiltered.map((p) => [p.id, getValueById(p.id)])
      );
    },
    productsFiltered() {
      const products = this.programTypesStimulationProducts
        .filter(
          (p) =>
            p.type_stimulation_id === this.sType.id &&
            p.program_phase_id === this.phase.id
        )
        .map((p) => p.product_id);
      return this.products.filter((p) => products.includes(p.id));
    },
  },
  methods: {
    onInput(product, value) {
      this.$store.commit(mutationTypes.setStimulationTypeProductQuantity, {
        program_phase_id: this.phase.id,
        type_stimulation_id: this.sType.id,
        product_id: product.id,
        quantity: value,
      });
    },
    onChange(product) {
      this.$store.commit(mutationTypes.setSelectedProduct, {
        product_id: product.id,
        object_id: this.sType.id,
        program_phase_id: this.phase.id,
        forStimulationType: true,
      });
    },
    calcProductNameStyle(product) {
      const style = {};
      const selected = this.getSelectedState(product);
      style.cursor = this.phase.is_temporary ? 'auto' : 'pointer';
      if (!selected) style.color = '#a8a9ad';
      return style;
    },
    getSelectedState(product) {
      return this.programTypesStimulationProducts.find(
        (p) =>
          p.type_stimulation_id === this.sType.id &&
          p.product_id === product.id &&
          p.program_phase_id === this.phase.id
      ).is_selected;
    },
  },
};
</script>
