<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('GlossaryRegions:Title') }]" />

      <div class="rightzone_top-buttonswrap"></div>
    </div>

    <div class="cwrap cwrap--level1 marginbottom--standart">
      <div class="cblock el--borders-all el--rounded-all">
        <div class="cblock__internal">
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="dropdownPlus"
                :placeholder="$t('GlossaryRegions:Find.Placeholder')"
                label="-"
                icon="search"
                size="long3x"
                color="accent"
                v-model="metaInput"
                :ddItems="metaItems"
                :ddSelectedIndex="metaSelectedIndex"
                @selected="metaSelect"
                dd-max-elements="5"
                :state="isLoading ? 'disabled' : ''"
              />
            </div>
            <div class="cblock__line-block">
              <Button
                class="filter_buttonclear"
                text="очистить"
                size="s-standart"
                color="graylines"
                icon="backspace"
                :is-loading="false"
                :loading-left="true"
                @click="clearMetaInput"
                :disabled="isLoading"
              />
            </div>
          </div>

          <div class="cblock__line">
            <h3 class="margintop--standart">
              {{ $t('GlossaryRegions:FindAlter') }}
            </h3>
          </div>

          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="dropdown"
                :placeholder="$t('GlossaryRegions:Country.Placeholder')"
                :label="$t('GlossaryRegions:Country.Label')"
                size="long"
                disabled="true"
                :dd-items="countries"
                @selected="countrySelect"
                v-model="selectedCountry"
                :ddSelectedIndex="selectedCountryIndex"
                :state="isLoading ? 'disabled' : ''"
              />

              <Button
                v-if="isShowAddButton"
                :text="$t('GlossaryRegions:EditButton')"
                size="s-standart"
                color="graylines"
                icon="edit"
                :is-loading="false"
                :loading-left="true"
                :disabled="!canEditCountry || isLoading"
                @click="edit(selectedCountry.value)"
              />
              <Button
                v-if="isShowAddButton"
                :text="$t('GlossaryRegions:AddButton')"
                size="standart"
                color="accentlightlines"
                icon="plus"
                :is-loading="false"
                :loading-left="true"
                @click="create('country')"
                :disabled="isLoading"
              />
            </div>
          </div>
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="dropdown"
                :placeholder="$t('GlossaryRegions:Region.Placeholder')"
                :label="$t('GlossaryRegions:Region.Label')"
                size="long"
                :dd-items="regions"
                @selected="regionSelect"
                v-model="selectedRegion"
                :ddSelectedIndex="selectedRegionIndex"
                :state="isLoading ? 'disabled' : ''"
                dd-max-elements="7"
              />
              <Button
                v-if="isShowAddButton"
                :text="$t('GlossaryRegions:EditButton')"
                size="s-standart"
                color="graylines"
                icon="edit"
                :is-loading="false"
                :loading-left="true"
                :disabled="!canEditRegion || isLoading"
                @click="edit(selectedRegion.value)"
              />
              <Button
                v-if="isShowAddButton"
                :text="$t('GlossaryRegions:AddButton')"
                size="standart"
                color="accentlightlines"
                icon="plus"
                :is-loading="false"
                :loading-left="true"
                @click="create('region')"
                :disabled="isLoading"
              />
            </div>
          </div>
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="dropdown"
                :placeholder="$t('GlossaryRegions:Municipalitet.Placeholder')"
                :label="$t('GlossaryRegions:Municipalitet.Label')"
                size="long"
                :dd-items="municipalities"
                @selected="municipalitySelect"
                v-model="selectedMunicipality"
                :ddSelectedIndex="selectedMunicipalityIndex"
                :state="isLoading ? 'disabled' : ''"
                dd-max-elements="7"
              />
              <Button
                v-if="isShowAddButton"
                class=""
                :text="$t('GlossaryRegions:EditButton')"
                size="s-standart"
                color="graylines"
                icon="edit"
                :is-loading="false"
                :loading-left="true"
                :disabled="!canEditMunicipality || isLoading"
                @click="edit(selectedMunicipality.value)"
              />
              <Button
                v-if="isShowAddButton"
                class=""
                :text="$t('GlossaryRegions:AddButton')"
                size="standart"
                color="accentlightlines"
                icon="plus"
                :is-loading="false"
                :loading-left="true"
                @click="create('municipality')"
                :disabled="isLoading"
              />
            </div>
          </div>
          <div class="cblock__line">
            <div class="cblock__line-block">
              <RadioToggle
                type=""
                :text1="$t('GlossaryRegions:City')"
                :text2="$t('GlossaryRegions:Village')"
                :label="$t('GlossaryRegions:Type')"
                isTypeBlocked=" "
                isTypeDisabled=" "
                v-model="settlementType"
                :disabled="isLoading"
              />
            </div>
          </div>
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="dropdown"
                :placeholder="$t('GlossaryRegions:Point.Placeholder')"
                :label="$t('GlossaryRegions:Point.Label')"
                size="long"
                :dd-items="settlements"
                @selected="settlementSelect"
                v-model="selectedSettlement"
                :ddSelectedIndex="selectedSettlementIndex"
                :state="isLoading ? 'disabled' : ''"
                dd-max-elements="7"
              />
              <Button
                v-if="isShowAddButton"
                class=""
                :text="$t('GlossaryRegions:EditButton')"
                size="s-standart"
                color="graylines"
                icon="edit"
                :is-loading="false"
                :loading-left="true"
                :disabled="!canEditSettlement || isLoading"
                @click="edit(selectedSettlement.value)"
              />
              <Button
                v-if="isShowAddButton"
                class=""
                :text="$t('GlossaryRegions:AddButton')"
                size="standart"
                color="accentlightlines"
                icon="plus"
                :is-loading="false"
                :loading-left="true"
                @click="create('settlement')"
                :disabled="isLoading"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import RadioToggle from '@/components/views/RadioToggle';

export const mapGeoEntity = (e, key = 'name') => ({
  text: e[key],
  value: e.id,
});

export default {
  name: 'GlossaryRegions',
  data() {
    return {
      metaInput: '',
      selectedCountry: null,
      selectedRegion: null,
      selectedMunicipality: null,
      selectedSettlement: null,
      metaSelectedIndex: 0,
      selectedCountryIndex: 0,
      selectedRegionIndex: 0,
      selectedMunicipalityIndex: 0,
      selectedSettlementIndex: 0,
      settlementType: null,
      notSelectedItem: {
        value: null,
        text: this.$t('GlossaryRegions:NotSelected'),
      },
    };
  },
  components: { RadioToggle, Input, Button, Breadcrumps },
  computed: {
    ...mapState({
      isLoading: (state) => state.glossaryGeobase.isGeobaseLoading,
      hasError: (state) => state.glossaryGeobase.isGeobaseLoadingHasError,
      canEdit: (state) => state.auth.currentUserRole?.claim_glossary_edit,
    }),
    ...mapGetters(['geoEntities', 'geoPlainData']),
    metaItems() {
      return [
        this.notSelectedItem,
        ...this.geoPlainData
          .map((e) => mapGeoEntity(e, 'text'))
          .filter(({ text }) =>
            text.toLowerCase().includes(this.metaInput.toLowerCase())
          ),
      ];
    },
    countries() {
      return [
        this.notSelectedItem,
        ...this.geoPlainData
          .filter((e) => e.type === 0)
          .map((e) => mapGeoEntity(e)),
      ];
    },
    regions() {
      return [
        this.notSelectedItem,
        ...this.findChildrenById(this.selectedCountry?.value).map((e) =>
          mapGeoEntity(e)
        ),
      ];
    },
    municipalities() {
      return [
        this.notSelectedItem,
        ...this.findChildrenById(this.selectedRegion?.value).map((e) =>
          mapGeoEntity(e)
        ),
      ];
    },
    settlements() {
      const settlements = this.findChildrenById(
        this.selectedMunicipality?.value
      ).filter((e) =>
        this.settlementType === null
          ? true
          : this.settlementType === 0
          ? e.type === 3
          : e.type === 4
      );
      return [
        this.notSelectedItem,
        ...settlements.map((e) => ({ ...mapGeoEntity(e), type: e.type })),
      ];
    },
    canEditCountry() {
      return !this.isLoading && this.canEdit && this.selectedCountry?.value;
    },
    canEditRegion() {
      return !this.isLoading && this.canEdit && this.selectedRegion?.value;
    },
    canEditMunicipality() {
      return (
        !this.isLoading && this.canEdit && this.selectedMunicipality?.value
      );
    },
    canEditSettlement() {
      return !this.isLoading && this.canEdit && this.selectedSettlement?.value;
    },
    isShowAddButton() {
      return (
        this.$store.state.auth?.currentUserRole?.claim_glossary_edit || false
      );
    },
  },
  methods: {
    findChildrenById(id) {
      return this.geoPlainData.find((e) => e.id === id)?.children || [];
    },
    metaSelect({ index }) {
      this.metaSelectedIndex = index;
      const data = this.geoPlainData.find(
        (e) => e.id == this.metaItems[index]?.value
      );

      if (data) {
        if (data.length === 1) this.clearRegionSelect();
        else if (data.length === 2) this.clearMunicipalitySelect();
        else if (data.length === 3) this.clearSettlementSelect();

        const processItem = (id) => {
          const item = this.geoPlainData.find((e) => e.id === id);
          const search = (e) => e.value === item.id;

          if (item.type === 0) {
            const countryIndex = this.countries.findIndex(search);
            this.countrySelect({
              index: countryIndex,
              item: this.countries[countryIndex],
            });
          } else if (item.type === 1) {
            const regionIndex = this.regions.findIndex(search);
            this.regionSelect({
              index: regionIndex,
              item: this.regions[regionIndex],
            });
          } else if (item.type === 2) {
            const municipalityIndex = this.municipalities.findIndex(search);
            this.municipalitySelect({
              index: municipalityIndex,
              item: this.municipalities[municipalityIndex],
            });
          } else {
            this.settlementType = null;
            const settlement = this.settlements.find(search);
            this.settlementType = settlement.type === 3 ? 0 : 1;
            const settlementIndex = this.settlements.findIndex(search);
            const item = this.settlements[settlementIndex];
            this.settlementSelect({
              index: settlementIndex,
              item,
            });
          }
        };

        data.order.forEach((item) => processItem(item.id));
      }
    },
    clearMetaInput() {
      this.metaSelectedIndex = 0;
      this.metaInput = '';
    },
    countrySelect({ index, item }) {
      if (this.selectedCountry?.value !== item?.value) {
        this.selectedCountry = item;
        this.selectedCountryIndex = index;
        this.clearRegionSelect();
        this.clearMunicipalitySelect();
        this.clearSettlementSelect();
      }
    },
    clearCountrySelect() {
      this.selectedCountry = null;
      this.selectedCountryIndex = 0;
    },
    regionSelect({ item, index }) {
      if (this.selectedRegion?.value !== item?.value) {
        this.selectedRegion = item;
        this.selectedRegionIndex = index;
        this.clearMunicipalitySelect();
        this.clearSettlementSelect();
      }
    },
    clearRegionSelect() {
      this.selectedRegion = null;
      this.selectedRegionIndex = 0;
    },
    municipalitySelect({ item, index }) {
      if (this.selectedMunicipality?.value !== item?.value) {
        this.selectedMunicipality = item;
        this.selectedMunicipalityIndex = index;
        this.clearSettlementSelect();
      }
    },
    clearMunicipalitySelect() {
      this.selectedMunicipality = null;
      this.selectedMunicipalityIndex = 0;
    },
    settlementSelect({ item, index }) {
      if (this.selectedSettlement?.value !== item?.value) {
        this.selectedSettlement = item;
        this.selectedSettlementIndex = index;
      }
    },
    clearSettlementSelect() {
      this.selectedSettlement = null;
      this.selectedSettlementIndex = 0;
    },
    edit(id) {
      this.$router.push(`/regions/${id}/edit`);
    },
    create(type) {
      this.$router.push(`/regions/${type}/add`);
    },
  },
  watch: {
    settlementType(val) {
      const settlement = this.geoPlainData.find(
        (e) => e.id === this.selectedSettlement?.value
      );
      if (settlement) {
        if (
          (val === 1 && settlement.type === 3) ||
          (val === 0 && settlement.type === 4)
        ) {
          this.clearSettlementSelect();
        }
      }
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.btn--disabled.btn--sqr_standart {
  @import '~@/scss/vars.scss';
  cursor: auto;
  background-color: transparent;
  color: $colorTextGrayLight;
  border: $borderButton solid $colorLinesGrayLight;

  & .btn_text {
    display: none;
  }

  & .btn_righticon {
    display: none;
  }

  & .svgfill {
    fill: $colorGrayLight;
  }

  &:hover {
    background-color: transparent;
    color: $colorTextGrayLight;
    border: $borderButton solid $colorLinesGrayLight;

    & .svgfill {
      fill: $colorGrayLight;
    }
  }
}
</style>
