<template>
  <dropdown-select
    v-bind="$attrs"
    :options="options"
    v-model="value1"
    @selected="selected"
  />
</template>

<script>
import DropdownSelect from '@/components/views/wrappers/DropdownSelect';
export default {
  name: 'DropdownSelectValue',
  components: { DropdownSelect },
  props: ['value', 'options', 'resetValueOnOptionsChange'],
  computed: {
    value1: {
      get() {
        return this.options.find((v) => v.value === this.value) ?? null;
      },
      set(newValue) {
        this.$emit(
          'input',
          newValue === null
            ? null
            : this.options.find(({ value }) => value === newValue.value)
                ?.value ?? null
        );
      },
    },
  },
  methods: {
    selected() {
      this.$emit('selected');
    },
  },
  watch: {
    options(options) {
      if (
        this.resetValueOnOptionsChange &&
        !options.some(({ value }) => this.value === value)
      ) {
        this.$emit('input', null);
      }
    },
  },
};
</script>

<style scoped></style>
