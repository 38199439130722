<-- Translated -->
<template>
  <div class="cblock cblock_client-analysis margintop--standart">
    <div
      class="cblock__line"
      @click="$emit('tableClicked')"
      :style="{ cursor: locked ? 'pointer' : 'initial' }"
    >
      <Table
        class="tablewrap--analysiselements tablewrap--analyzeselements"
        :header-text="`${this.$t(
          'ClientZoneAnalysis:DateAnalyse'
        )} ${dateString}`"
        :headers="headers"
        :data-arr="rows"
        disable-pagination="true"
        disable-hover="true"
        @input="onElementInput"
      >
        <template
          v-for="(header, index) in headers"
          v-slot:[getSlotName(header.text)]
        >
          <div v-if="index" :key="header.text" class="arrow-input-wrapper">
            <ArrowDropdown
              v-if="!locked"
              :options="header.units"
              :value="rows[0].at(index)?.unitsId ?? 1"
              :disabled="header.text === 'pH'"
              @input="onElementUnitInput({ column: header.id, value: $event })"
            />
            <span v-else>
              {{ getUnitsText(index, rows[0].at(index)?.unitsId ?? 1) }}
            </span>
          </div>
        </template>
      </Table>
    </div>

    <div class="cblock__line">
      <div class="cblock__line-block">
        <InputDate
          :label-text="$t('ClientZoneAnalyses:InputDate')"
          :locked="locked"
          v-model="date"
          :scrollToDropdown="true"
        />
        <Input
          type="text"
          :placeholder="
            locked
              ? $t('ClientZoneAnalyses:Comment:NoComment')
              : $t('ClientZoneAnalyses:Comment:WriteComment')
          "
          :label="$t('ClientZoneAnalyses:Comment:Label')"
          size="max"
          :state="locked ? 'disabled2' : ''"
          v-model="comment"
        />
        <Button
          v-if="!locked"
          :text="$t('ClientZoneAnalyses:ButtonDelete')"
          size="s-standart"
          icon="delete"
          color="alertlines"
          :is-loading="false"
          :loading-left="true"
          @click="removeAnalysis"
        />
      </div>
    </div>

    <ClientZoneAnalysisFiles :locked="locked" :analysisId="analysis.id" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mutationTypes } from '@/store/modules/clients';

import {
  units,
  elementPossibleUnits,
} from '@/pages/programs/elementMeasureUnits.js';

import Table from '@/components/tables/Table';
import InputDate from '@/components/views/InputDate';
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import ArrowDropdown from '@/components/views/ArrowDropdown';

import ClientZoneAnalysisFiles from './ClientZoneAnalysisFiles';
import { confirmDelete } from '../confirmDelete';

export default {
  name: 'ClientZoneTableElements',
  components: {
    Table,
    InputDate,
    Button,
    Input,
    ClientZoneAnalysisFiles,
    ArrowDropdown,
  },
  props: {
    analysis: { type: Object, required: true },
    locked: { type: Boolean, default: true },
  },
  data() {
    return {
      forceUpdateRows: null,
      units,
    };
  },
  computed: {
    ...mapState({
      elements: (state) => state.clients.elements,
      inputElements(state) {
        return state.clients.clientAnalyzesElements.filter(
          (e) => e.analyse_id === this.analysis.id
        );
      },
    }),
    headers() {
      const depthHeader = {
        text: this.$t('ClientZoneAnalyses:DepthHeader'),
        class: 'bold',
        type: 'text',
        width: '12%',
        units: [],
      };
      const elementsHeaders = this.elements.map((e) => ({
        id: e.id,
        text: e.name,
        class: 'col-element',
        type: this.locked ? 'text' : 'input',
        width: `${(100 - 12) / this.elements.length}%`,
        units:
          this.unitsData.find(({ element }) => element.id === e.id)
            ?.unitsData ?? [],
      }));
      return [depthHeader, ...elementsHeaders];
    },
    unitsData() {
      const unitsDataList = elementPossibleUnits.map((e) => ({
        ...e,
        units: [units[0], ...e.units].map((unit) => ({
          ...unit,
          ...units.find(({ id }) => id === unit.id),
        })),
      }));

      unitsDataList.push({
        elementId: 13,
        units: [{ id: 1, text: '', multiplier: 1 }],
      });

      unitsDataList.push({
        elementId: 14,
        units: [
          { id: 1, text: 'μS/cm', multiplier: 1 },
          { id: 2, text: 'ms/cm', multiplier: 0.001 },
        ],
      });

      return this.elements.map((element) => ({
        element,
        unitsData: unitsDataList
          .find(({ elementId }) => elementId === element.id)
          ?.units?.map((u) => ({ ...u, value: u.id })),
      }));
    },
    rows() {
      this.forceUpdateRows;
      const mapElementToText = (id, depth) =>
        this.inputElements.find((e) => e.element_id === id && depth === e.depth)
          ?.quantity ?? null;

      const MapElementToUnit = (id) =>
        this.inputElements.find((e) => e.element_id === id)?.units_id ?? 1;

      return [...Array(2)].map((_, depth) => [
        { text: `${this.$t('ClientZoneAnalyses:DepthHeader')} ${depth + 1}` },
        ...this.elements.map((e) => ({
          text: mapElementToText(e.id, depth),
          unitsId: MapElementToUnit(e.id),
        })),
      ]);
    },
    date: {
      get() {
        return this.analysis.date;
      },
      set(date) {
        this.$store.commit(mutationTypes.setAnalysisDate, {
          id: this.analysis.id,
          date,
        });
      },
    },
    dateString() {
      return this.date ? new Date(this.date).toLocaleDateString('ru-RU') : '…';
    },
    comment: {
      get() {
        return this.analysis.comment;
      },
      set(comment) {
        this.$store.commit(mutationTypes.setAnalysisComment, {
          id: this.analysis.id,
          comment,
        });
      },
    },
  },
  methods: {
    onElementInput({ column, row, value }) {
      this.forceUpdateRows = Math.random();
      const payload = {
        elementId: column,
        analyseId: this.analysis.id,
        depth: row,
        quantity: value,
      };
      this.$store.commit(mutationTypes.setAnalysisElement, payload);
    },
    onElementUnitInput({ column, value }) {
      this.forceUpdateRows = Math.random();
      const payload = {
        elementId: column,
        analyseId: this.analysis.id,
        units_id: value,
      };
      this.$store.commit(mutationTypes.setAnalysisElement, payload);
    },
    getSlotName(el) {
      return 'slot_' + el;
    },
    getUnitsText(index, id) {
      // pH
      if (index === 13) return '';
      // EC
      if (index === 14) return id === 1 ? 'μS/cm' : 'ms/cm';
      return this.units.find((u) => u.id === id)?.text;
    },
    removeAnalysis() {
      confirmDelete.call(
        this,
        this.$t('ClientZoneAnalyses:ConfirmDeleteAnalyse'),
        mutationTypes.removeAnalysis,
        this.analysis.id
      );
    },
  },
};
</script>

<style scoped>
.arrow-input-wrapper {
  margin-top: 3px;
}
</style>
