<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('AnalyzesEdit:Buttons:Undo')"
          size="standart"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'Analyzes' })"
        />
        <Button
          :text="$t('AnalyzesEdit:Buttons:Save')"
          size="standart"
          color="accent"
          icon="save"
          :is-loading="isUpdating"
          :loading-left="true"
          :disabled="isSaveButtonDisabled"
          @click="onSave"
        />
      </div>
    </div>

    <div class="cwrap marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'user' }"
          ></div>
          <div class="chead_text">
            {{ $t('AnalyzesEdit:Headers:ClientData') }}
          </div>
        </div>
      </div>
      <div
        class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line" v-if="canEdit">
            <div class="cblock__line-block">
              <DropdownPlusSelect
                :placeholder="$t('AnalyzesEdit:ClientFind.Placeholder')"
                :label="$t('AnalyzesEdit:ClientFind.Label')"
                size="long4x"
                icon="search"
                dd-max-elements="7"
                :ddMultiMode="true"
                :options="clientsData.clientsFiltered"
                @filter="clientsData.clientsFilter = $event"
                v-model="clientsData.selectedClient"
                :disabled="isLoading"
              />
              <Button
                v-if="canSeeClients"
                :text="$t('AnalyzesEdit:ClientButton')"
                size="s-standart"
                color="accentlines"
                icon="user"
                :disabled="isLoading || clientsData.selectedClient === null"
                @click="onClientButtonClick"
              />
            </div>
          </div>
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                placeholder=""
                :label="$t('AnalyzesEdit:ClientData:Id.Label')"
                icon=""
                :state="stateInputReadonly"
                size="standart"
                color=""
                v-model="clientsData.selectedClientData.id"
              />
              <Input
                type="text"
                placeholder=""
                :label="$t('AnalyzesEdit:ClientData:Name.Label')"
                icon=""
                :state="stateInputReadonly"
                size="long"
                color=""
                v-model="clientsData.selectedClientData.name"
              />
              <Input
                type="text"
                placeholder=""
                :label="$t('AnalyzesEdit:ClientData:Type.Label')"
                icon=""
                :state="stateInputReadonly"
                size="standart"
                color=""
                v-model="clientsData.selectedClientData.type"
              />
              <Button
                v-if="!canEdit && canSeeClients"
                :text="$t('AnalyzesEdit:ClientButton')"
                size="s-standart"
                color="accentlines"
                icon="user"
                :disabled="isLoading || clientsData.selectedClient === null"
                @click="onClientButtonClick"
              />
              <!--      <Input
                        type="text"
                        placeholder="Поиск по клиентам..."
                        label="% скидки"
                        icon=""
                        state="disabled2"
                        size="standart"
                        color=""
                    />-->
            </div>
          </div>
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="text"
                placeholder=""
                :label="$t('AnalyzesEdit:ClientData:Login.Label')"
                icon=""
                :state="stateInputReadonly"
                size="long"
                color=""
                v-model="clientsData.selectedClientData.login"
              />

              <!--      <Input
                        type="text"
                        placeholder="Поиск по клиентам..."
                        label="% скидки"
                        icon=""
                        state="disabled2"
                        size="standart"
                        color=""
                    />-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cwrap marginbottom--standart">
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'edit' }"
          ></div>
          <div class="chead_text">
            {{ $t('AnalyzesEdit:Headers:OrderData') }}
          </div>
        </div>
      </div>
      <div
        class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
      >
        <div class="cblock__internal">
          <div class="cblock__line">
            <div class="cblock__line-block">
              <DropdownSelectValue
                :placeholder="
                  $t('AnalyzesEdit:OrderData:AnalyzeType.Placeholder')
                "
                :label="$t('AnalyzesEdit:OrderData:AnalyzeType.Label')"
                size="standart"
                dd-max-elements="5"
                :disabled="isLoading"
                :options="ddAnalyzesTypes"
                v-model="newAnalyse.type_id"
                :locked="!canEdit"
              />
              <InputDate
                :label-text="$t('AnalyzesEdit:OrderData:Date.Label')"
                size="Standart"
                v-model="newAnalyse.date_create"
                :state="stateInput"
                :locked="!canEdit"
              />
              <Input
                type="text"
                :label="$t('AnalyzesEdit:OrderData:Status.Label')"
                icon=""
                :state="stateInputReadonly"
                size="long"
                color=""
                v-model="statusText"
              />
            </div>
          </div>
          <div class="cblock__line">
            <div class="cblock__line-block">
              <Input
                type="textarea2x"
                :placeholder="
                  $t('AnalyzesEdit:OrderData:Description.Placeholder')
                "
                :label="$t('AnalyzesEdit:OrderData:Description.Label')"
                size="long4x"
                :state="stateInput"
                v-model="newAnalyse.comment"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Button
      v-if="canEdit"
      :text="$t('AnalyzesEdit:Buttons:Save')"
      size="standart"
      color="accent"
      icon="save"
      :is-loading="isUpdating"
      :loading-left="true"
      :disabled="isSaveButtonDisabled"
      @click="onSave"
      class="marginbottom--standart"
    />
    <!--БЛОК - удалить-->
    <div
      class="cwrap marginbottom--standart"
      v-if="$route.name === 'EditAnalyse' && canEdit"
    >
      <!--ЗАГОЛОВОК-->
      <div class="chead el--borders-top el--borders-sides el--rounded-top">
        <div class="chead__line-block">
          <div
            class="vsvg vsvg--standart chead_icon"
            v-svg="{ name: 'delete' }"
          ></div>
          <div class="chead_text">{{ $t('AnalyzesEdit:Headers:Delete') }}</div>
        </div>
      </div>

      <!--ОПИСАНИЕ-->
      <div class="cblock el--borders-all el--rounded-bottom">
        <div class="cblock__infoblock">
          <p>
            {{ $t('AnalyzesEdit:Delete:Text:0') }}<br />
            {{ $t('AnalyzesEdit:Delete:Text:1') }}
          </p>

          <Button
            :text="$t('AnalyzesEdit:Delete:Button')"
            size="standart"
            color="alertlines"
            icon="userdelete"
            :loading-left="true"
            :is-loading="isDeleting"
            @click="onDelete"
          />
        </div>
      </div>
    </div>

    <!--<Modal
      v-if="showModalNotSaved"
      @backgroundClose="showModalNotSaved = false"
    >
      <ModalNotSaved
        v-model="showModalNotSaved"
        @confirm="onModalNotSavedConfirm"
      />
    </Modal>-->
  </article>
</template>

<script>
//ПРОВЕРИТЬ, ЗА ОСНОВУ ВЗЯТА СТРАНИЦА СТИМУЛЯЦИЙ
import { createConfirmCallback } from '@/helpers/confirmModalHelper';

import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import Breadcrumps from '@/components/views/Breadcrumps';
import routeNames from '@/router/routeNames';
import { actionTypes } from '@/store/modules/analyzes';
import InputDate from '@/components/views/InputDate';
import { mapState } from 'vuex';
import { staticData } from '@/store/modules/clients';
import debounce from 'lodash.debounce';
import DropdownPlusSelect from '@/components/views/wrappers/DropdownPlusSelect';
import { staticData as clientsStaticData } from '@/store/modules/clients';
import DropdownSelectValue from '@/components/views/wrappers/DropdownSelectValue';
import * as analyzesHelper from '@/helpers/analyzesHelper';

export default {
  name: 'AnalyzesEdit',
  components: {
    DropdownSelectValue,
    InputDate,
    Input,
    Button,
    Breadcrumps,

    DropdownPlusSelect,
  },
  data() {
    return {
      state: {
        nextRouteConfirm: false,
        nextRoute: null,
      },
      clientsData: {
        clientsFilter: '',
        clientsFiltered: [],
        selectedClient: null,
        selectedClientData: {
          id: '',
          name: '',
          type: '',
          login: '',
        },
      },
      newAnalyse: {
        id: null,
        client_id: null,
        date_create: null,
        type_id: null,
        status: null,
        comment: '',
      },
    };
  },
  methods: {
    onSave() {
      this.$store.dispatch(actionTypes.updateAnalyse, this.newAnalyse);
    },
    onDelete() {
      this.$confirm({
        message:
          this.$t('AnalyzesEdit:ModalConfirmDelete:Text:0') +
          '<br/>' +
          this.$t('AnalyzesEdit:ModalConfirmDelete:Text:1'),
        buttons: {
          left: {
            text: this.$t('AnalyzesEdit:ModalConfirmDelete:ButtonDelete'), //'Удалить',
            icon: 'tick',
            color: 'alertlines',
          },
          right: {
            text: this.$t('AnalyzesEdit:ModalConfirmDelete:ButtonUndo'), //'Отмена',
            icon: 'cancel',
            color: 'graylines',
          },
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch(actionTypes.deleteAnalyse, this.newAnalyse);
          }
        },
      });
    },
    updateClients: debounce(
      function () {
        const query = this.clientsData.clientsFilter.toLowerCase().trim();
        const predicate =
          query === ''
            ? () => true
            : ({ searchString }) => searchString.includes(query);
        this.clientsData.clientsFiltered = this.clients
          .filter(predicate)
          .slice(0, 50);
      },
      100,
      { leading: true }
    ),
    onClientButtonClick() {
      if (this.clientsData.selectedClient !== null) {
        let routeData = this.$router.resolve({
          name: routeNames.editClient,
          params: { id: this.clientsData.selectedClient?.id },
        });
        window.open(routeData.href, '_blank');
      }
    },
  },
  computed: {
    ...mapState({
      clients: (state) =>
        state.clientsList.clients.map((c) =>
          Object.freeze({
            ...c,
            value: c.id,
            text: c.name,
            text1: c.name,
            text2: c.code,
            text3: staticData.clientType[c.type_id],
            searchString: [c.name, c.code].join(' ').toLowerCase(),
          })
        ),
      canEdit: (state) => state.auth.currentUserRole?.claim_analyse_edit === 1,
      canSeeClients: (state) =>
        state.auth.currentUserRole?.claim_clients_see === 1,
    }),
    autoClientId() {
      if (!this.canSeeClients || this.$route.name !== routeNames.newAnalyse) {
        return null;
      }
      const id = Number(this.$route.query.forClient);
      return Number.isInteger(id) ? id : null;
    },
    clientsMap() {
      return new Map(
        this.clients?.map((object) => {
          return [object.id, object];
        })
      );
    },
    isSaveButtonDisabled() {
      if (!this.canEdit) {
        return true;
      }
      if (this.isLoading) {
        return true;
      }
      if (!this.newAnalyse.date_create) {
        return true;
      }
      if (this.newAnalyse.type_id === null) {
        return true;
      }
      if (!this.clientsData.selectedClient) {
        return true;
      }
      if (this.$route.name === routeNames.editAnalyse) {
        return !this.hasChanges;
      }
      return false;
    },
    hasChanges() {
      if (this.editAnalyse) {
        console.log(this.newAnalyse.date_create, this.editAnalyse.date_create);
        if (
          this.newAnalyse.type_id !== this.editAnalyse.type_id ||
          this.newAnalyse.client_id !== this.editAnalyse.client_id ||
          this.newAnalyse.date_create !== this.editAnalyse.date_create ||
          this.newAnalyse.comment !== this.editAnalyse.comment
        ) {
          return true;
        }
      }
      return false;
    },
    isLoading() {
      return (
        this.$store.state.analyzes.isAnalyzesLoading ||
        this.$store.state.analyzes.isUpdateLoading
      );
    },
    isUpdating() {
      return this.$store.state.analyzes.isUpdateLoading;
    },
    isDeleting() {
      return this.$store.state.analyzes.isDeleteLoading;
    },
    isUpdateDone() {
      return this.$store.state.analyzes.isUpdateDone;
    },
    isDeleteDone() {
      return this.$store.state.analyzes.isDeleteDone;
    },
    breadcrumpsArr() {
      if (this.$route.name === routeNames.editAnalyse)
        return [
          { title: this.$t('AnalyzesEdit:Title:0'), route: 'Analyzes' },
          { title: this.$t('AnalyzesEdit:Title:1') },
        ];
      else
        return [
          { title: this.$t('AnalyzesEdit:Title:0'), route: 'Analyzes' },
          { title: this.$t('AnalyzesEdit:Title:2') },
        ];
    },
    stateInput() {
      return this.isLoading ? 'disabled' : !this.canEdit ? 'disabled2' : '';
    },
    stateInputReadonly() {
      return this.isLoading ? 'disabled' : 'disabled2';
    },
    ddAnalyzesTypes() {
      return this.$store.state.analyzes.analyzesTypes.map((v) => {
        return { text: v.name, value: v.id };
      });
    },
    statusText() {
      return analyzesHelper.getAnalyseStatus(this.newAnalyse);
    },
    editAnalyse() {
      return this.$store.state.analyzes.editAnalyse;
    },
  },
  watch: {
    clients: {
      handler() {
        this.updateClients();
        setTimeout(() => {
          if (this.clients?.length && this.autoClientId) {
            const client = this.clientsMap.get(this.autoClientId);
            if (client) this.clientsData.selectedClient = client;
          }
        }, 101);
      },
      immediate: true,
    },
    'clientsData.clientsFilter': {
      handler() {
        this.updateClients();
      },
      immediate: true,
    },
    'clientsData.selectedClient': {
      handler() {
        if (this.clientsData.selectedClient !== null) {
          if (this.clientsData.selectedClient.code) {
            this.clientsData.selectedClientData.id =
              this.clientsData.selectedClient.code;
            this.newAnalyse.client_id = this.clientsData.selectedClient.id;
          } else {
            this.clientsData.selectedClientData.id = '';
            this.newAnalyse.client_id = null;
          }
          if (this.clientsData.selectedClient.id) {
            this.newAnalyse.client_id = this.clientsData.selectedClient.id;
          } else {
            this.newAnalyse.client_id = null;
          }
          if (this.clientsData.selectedClient.name) {
            this.clientsData.selectedClientData.name =
              this.clientsData.selectedClient.name;
          } else {
            this.clientsData.selectedClientData.name = '';
          }
          if (
            this.clientsData.selectedClient.type_id !== null &&
            clientsStaticData.clientType.length >
              this.clientsData.selectedClient.type_id
          ) {
            this.clientsData.selectedClientData.type =
              clientsStaticData.clientType[
                this.clientsData.selectedClient.type_id
              ];
          } else {
            this.clientsData.selectedClientData.type = '';
          }
          if (this.clientsData.selectedClient.login) {
            this.clientsData.selectedClientData.login =
              this.clientsData.selectedClient.login;
          } else {
            this.clientsData.selectedClientData.login = '';
          }
          if (
            !this.clientsData.clientsFiltered?.find((v) => {
              if (v.id === this.clientsData.selectedClient?.id) {
                return true;
              }
            })
          ) {
            this.clientsData.clientsFiltered?.push(
              this.clientsData.selectedClient
            );
          }
        } else {
          this.clientsData.selectedClientData.id = '';
          this.clientsData.selectedClientData.name = '';
          this.clientsData.selectedClientData.type = '';
          this.clientsData.selectedClientData.login = '';
          this.newAnalyse.client_id = null;
        }
      },
      immediate: true,
    },
    isUpdateDone() {
      if (this.isUpdateDone && !this.isDeleteDone)
        this.$confirm({
          message:
            this.$t('AnalyzesEdit:ModalSaved:Text:0') +
            '<br/>' +
            this.$t('AnalyzesEdit:ModalSaved:Text:1'),
          buttons: {
            left: {
              text: this.$t('AnalyzesEdit:ModalSaved:ButtonOk'), //'Перейти',
              icon: 'tick',
              color: 'accentlines',
            },
            right: {
              text: this.$t('AnalyzesEdit:ModalSaved:ButtonUndo'), //'Остаться',
              icon: 'cancel',
              color: 'graylines',
            },
          },
          callback: (confirm) => {
            if (confirm) {
              this.state.nextRouteConfirm = true;
              this.$router.push({ name: routeNames.analyzes });
            } else {
              if (this.$route.name === routeNames.editAnalyse) {
                this.state.nextRouteConfirm = true;
                this.$router.go();
              } else {
                if (this.$store.state.analyzes?.updatingResId) {
                  this.state.nextRouteConfirm = true;
                  this.$router.push({
                    name: routeNames.editAnalyse,
                    params: { id: this.$store.state.analyzes?.updatingResId },
                  });
                } else {
                  this.state.nextRouteConfirm = true;
                  this.$router.go();
                }
              }
            }
          },
        });
    },
    isDeleteDone() {
      if (this.isDeleteDone) {
        this.state.nextRouteConfirm = true;
        this.$router.push({ name: routeNames.analyzes });
      }
    },
    editAnalyse() {
      if (this.editAnalyse) {
        this.newAnalyse.id = this.editAnalyse?.id;
        this.newAnalyse.type_id = this.editAnalyse?.type_id;
        this.newAnalyse.comment = this.editAnalyse?.comment;
        this.newAnalyse.date_create = this.editAnalyse?.date_create;
        this.newAnalyse.client_id = this.editAnalyse?.client_id;

        if (this.clientsMap?.has(this.newAnalyse.client_id)) {
          this.clientsData.selectedClient = this.clientsMap.get(
            this.newAnalyse.client_id
          );
        }
      }
    },
  },
  created() {
    if (this.$route.name === routeNames.newAnalyse) {
      this.newAnalyse.date_create = new Date().getTime();
    }
  },
  beforeRouteLeave(to, from, next) {
    const showConfirm = () =>
      this.$confirm({
        message:
          this.$t('AnalyzesEdit:ModalNotSaved:Text:0') +
          '<br>' +
          this.$t('AnalyzesEdit:ModalNotSaved:Text:1'),
        callback: createConfirmCallback(next),
      });

    if (this.isDeleteDone || this.state.nextRouteConfirm || !this.canEdit) {
      next();
    } else if (this.$route.name === routeNames.editAnalyse) {
      if (!this.isSaveButtonDisabled && this.canEdit) {
        showConfirm();
      } else {
        next();
      }
    } else {
      if (
        this.newAnalyse.client_id !== null ||
        this.newAnalyse.comment ||
        this.newAnalyse.type_id
      ) {
        //this.nextRoute = to;
        showConfirm();
      } else {
        next();
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
