<!--Translated-->
<template>
  <div
    ref="phase"
    class="cblock phase phase--opened el--borders-all el--rounded-all margintop--standart"
    :class="{ 'phase--complete': isActive }"
  >
    <PhaseHeader
      :phase="phase"
      :expanded="expanded"
      :readonly="readonly"
      @toogle="expanded = !expanded"
    />
    <div class="cblock__internalpadding phase_tableswrap" v-if="expanded">
      <PhasePestsTable :phase="phase" :readonly="readonly" />
      <PhaseTypesStimulationsTable :phase="phase" :readonly="readonly" />

      <div class="flex-end">
        <Button
          class="btn--chevrontogle"
          size="s-standart"
          :color="expanded ? 'accentlightlines' : 'accent'"
          :icon="expanded ? 'chevronup' : 'chevrondown'"
          :key="expanded"
          @click="collapseAndScroll"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Button from '@/components/views/Button';
import PhaseHeader from './TemplatePhaseHeader';
import PhasePestsTable from './TemplatePestsTable';
import PhaseTypesStimulationsTable from './TemplateTypesStimulationsTable';

export default {
  name: 'TemplatePhase',
  props: {
    innitiallyExpanded: { type: Boolean, default: false },
    phase: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
  },
  components: {
    PhaseHeader,
    PhasePestsTable,
    PhaseTypesStimulationsTable,
    Button,
  },
  data() {
    return {
      expanded: false,
    };
  },
  created() {
    this.expanded = this.innitiallyExpanded;
  },
  computed: mapState({
    isActive(state) {
      const predicate = ({ phase_id, quantity }) =>
        phase_id === this.phase.id && quantity !== '';
      const waterPhaseFilled =
        state.plantsCare.templateWaterPhases.some(predicate);
      const hasPests = state.plantsCare.templatePestsProducts.some(predicate);
      const hasSTypes =
        state.plantsCare.templateTypesStimulationProducts.some(predicate);
      return waterPhaseFilled && (hasPests || hasSTypes);
    },
  }),
  methods: {
    collapseAndScroll() {
      this.expanded = false;
      this.$refs.phase.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>
