<!--Translated-->
<template>
  <div class="flex">
    <DropdownSelect
      type="dropdown"
      class="marginright--standart"
      :placeholder="$t('CultureSelect:SelectCategory.Placeholder')"
      :label="$t('CultureSelect:SelectCategory.Label')"
      size="standart"
      :options="categories"
      v-model="selectedCategory"
      :disabled="disabled"
      :locked="locked"
    />
    <DropdownPlusSelect
      :placeholder="$t('CultureSelect:SelectCulture.Placeholder')"
      :label="$t('CultureSelect:SelectCulture.Label')"
      size="standart"
      :options="culturesFiltered"
      ddSelectModeIgnoreExists="true"
      v-model="selectedCulture"
      :disabled="disabled"
      :locked="locked"
    />
  </div>
</template>

<script>
import DropdownSelect from '@/components/views/wrappers/DropdownSelect';
import DropdownPlusSelect from '@/components/views/wrappers/DropdownPlusSelect';

export default {
  name: 'CultureSelect',
  components: { DropdownSelect, DropdownPlusSelect },
  props: {
    disabled: { type: Boolean, default: false },
    locked: { type: Boolean, default: false },
    value: { type: Number, default: null },
    category: { type: Number, default: null },
    categories: { type: Array, default: () => [] },
    cultures: { type: Array, default: () => [] },
  },
  data() {
    return {
      selectedCategory: null,
      selectedCulture: null,
    };
  },
  computed: {
    culturesFiltered() {
      return this.cultures
        .filter((e) =>
          this.selectedCategory
            ? e.category_id === this.selectedCategory.value
            : true
        )
        .map((e) => ({
          ...e,
          exists: e.value !== null && e !== this.selectedCulture && e.exists,
        }));
    },
  },
  watch: {
    selectedCulture(culture) {
      this.$emit('input', culture?.id ?? null);
    },
    value: {
      handler(id) {
        const culture = this.cultures.find((e) => e.value === id) ?? null;
        this.selectedCulture = culture;
        if (culture) {
          const category =
            this.categories.find((e) => e.value === culture?.category_id) ??
            null;
          this.selectedCategory = category;
        }
      },
      immediate: true,
    },
    selectedCategory(category) {
      this.$emit('categorySelect', category?.id ?? null);
      if (category?.id !== this.selectedCulture?.category_id) {
        this.selectedCulture = null;
      }
    },
    category: {
      handler(data) {
        if (this.selectedCulture === null) {
          const category =
            this.categories.find((e) => e.value === data) ?? null;
          this.selectedCategory = category;
        }
      },
      immediate: true,
    },
  },
  methods: {
    reset() {
      this.selectedCategory = null;
    },
  },
};
</script>
