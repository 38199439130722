<-- Translated -->
<template>
  <Modal @backgroundClose="$emit('close')">
    <div class="modal modal--long">
      <div class="modalwrap2-topline">
        <div class="topline-minizone"></div>
        <Breadcrumps
          :data="[
            {
              title: editingId
                ? $t('GlossaryLinkedItemsModal:TitleEdit')
                : headerAdd || $t('GlossaryLinkedItemsModal:TitleAdd'),
            },
          ]"
        />
        <div class="topline-minizone">
          <Button
            size="s-standart"
            color="accentlightlines"
            icon="x"
            :is-loading="false"
            :loading-left="true"
            @click="$emit('close')"
          />
        </div>
      </div>

      <div class="modalwrap2-content">
        <div
          class="modalwrap3-inputs margintop--standart marginbottom--standart"
        >
          <DropdownPlusSelect
            :label="label"
            :placeholder="placeholder"
            size="max"
            :autofocus="true"
            v-model="selectedItem"
            :options="itemsWithExistence"
            dd-max-elements="6"
          />
        </div>

        <div class="modalwrap3-buttons">
          <Button
            v-if="editingId"
            :text="$t('GlossaryLinkedItemsModal:ButtonDelete')"
            size="wide"
            color="alertlines"
            icon="delete"
            :is-loading="false"
            :loading-left="true"
            :disabled="!canDelete"
            @click="remove"
          />
          <Button
            :text="$t('GlossaryLinkedItemsModal:ButtonCancel')"
            size="wide"
            color="graylines"
            icon="cancel"
            :is-loading="false"
            :loading-left="true"
            @click="$emit('close')"
          />
          <Button
            :text="$t('GlossaryLinkedItemsModal:ButtonSave')"
            size="wide"
            color="accent"
            icon="save"
            :is-loading="false"
            :loading-left="true"
            :disabled="!canSave"
            @click="save"
          />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/pages/Modal';
import Breadcrumps from '@/components/views/Breadcrumps';
import Button from '@/components/views/Button';
import DropdownPlusSelect from '@/components/views/wrappers/DropdownPlusSelect';

export default {
  components: {
    Modal,
    Breadcrumps,
    Button,
    DropdownPlusSelect,
  },
  props: {
    editingId: { type: Number, default: null },
    items: { type: Array, default: () => [] },
    selectedItems: { type: Array, default: () => [] },
    label: {
      type: String,
      default() {
        return this.$t('GlossaryLinkedItemsModal:Name');
      },
    },
    placeholder: { type: String, default: '' },
    headerAdd: { type: String },
  },
  data() {
    return {
      selectedItem: null,
    };
  },
  computed: {
    itemsWithExistence() {
      return this.items.map((item) => ({
        ...item,
        exists: this.selectedItems.some((id) => item.value === id),
      }));
    },
    canSave() {
      return this.selectedItem !== null && !this.selectedItem.exists;
    },
    canDelete() {
      return this.editingId === this.selectedItem?.value;
    },
  },
  methods: {
    save() {
      if (this.editingId) {
        this.$emit('edit', this.editingId, this.selectedItem.value);
      } else {
        this.$emit('save', this.selectedItem.value);
      }
      this.$emit('close');
    },
    remove() {
      this.$emit('remove', this.editingId);
      this.$emit('close');
    },
  },
  created() {
    if (this.editingId) {
      this.selectedItem =
        this.itemsWithExistence.find(({ value }) => value == this.editingId) ||
        null;
    }
  },
};
</script>
