import glossaryApi from '@/api/glossary';

const state = {
  isTypesStimulationLoading: false,
  isTypesStimulationLoadingHasError: false,
  typesStimulation: null,
  typesStimulationMapById: null,

  isUpdateTypeStimulationLoading: false,
  isUpdateTypeStimulationHasError: false,
  isUpdateTypeStimulationDone: false,
  updateTypeStimulationErrorText: null,

  editTypeStimulationId: null,
  editTypeStimulation: null,
};
export const mutationTypes = {
  getTypesStimulationStart: '[glossary] getTypesStimulation start',
  getTypesStimulationSuccess: '[glossary] getTypesStimulation success',
  getTypesStimulationFailure: '[glossary] getTypesStimulation failure',

  updateTypeStimulationStart: '[glossary] updateTypeStimulation start',
  updateTypeStimulationSuccess: '[glossary] updateTypeStimulation success',
  updateTypeStimulationFailure: '[glossary] updateTypeStimulation failure',
};
export const actionTypes = {
  getTypesStimulation: '[glossary] getTypesStimulation',
  updateTypeStimulation: '[glossary] updateTypeStimulation',
};
const mutations = {
  [mutationTypes.getTypesStimulationStart](state, typeStimulationId) {
    state.isTypesStimulationLoading = true;
    state.isTypesStimulationLoadingHasError = false;
    state.typesStimulation = null;
    state.typesStimulationMapById = new Map();
    state.editTypeStimulationId = typeStimulationId || null;
    state.editTypeStimulation = null;
  },
  [mutationTypes.getTypesStimulationSuccess](state, payload) {
    state.isTypesStimulationLoading = false;
    state.typesStimulation = payload.typesStimulation;
    processTypesStimulation(state);
  },
  [mutationTypes.getTypesStimulationFailure](state) {
    state.isTypesStimulationLoading = false;
    state.isTypesStimulationLoadingHasError = true;
  },

  [mutationTypes.updateTypeStimulationStart](state) {
    state.isUpdateTypeStimulationLoading = true;
    state.isUpdateTypeStimulationHasError = false;
    state.isUpdateTypeStimulationDone = false;
    state.updateTypeStimulationErrorText = null;
  },
  [mutationTypes.updateTypeStimulationSuccess](state, payload) {
    state.isUpdateTypeStimulationLoading = false;
    //state.categoriesCultures = payload.categoriesCultures;
    state.isUpdateTypeStimulationDone = true;
    if (payload.status && payload.status === 'ok') {
      //
    } else {
      state.isUpdateTypeStimulationHasError = true;
      state.updateTypeStimulationErrorText = payload?.message || 'Error';
    }
  },
  [mutationTypes.updateTypeStimulationFailure](state) {
    state.isUpdateTypeStimulationLoading = false;
    state.isUpdateTypeStimulationDone = true;
    state.isUpdateTypeStimulationHasError = true;
  },
};
function processTypesStimulation(state) {
  state.typesStimulationMapById = new Map();
  if (state.typesStimulation) {
    state.typesStimulation.forEach((type) => {
      state.typesStimulationMapById.set(type.id, type);
      if (
        state.editTypeStimulationId &&
        state.editTypeStimulationId == type.id
      ) {
        state.editTypeStimulation = type;
      }
    });
  }
}

const actions = {
  async [actionTypes.getTypesStimulation](context, payload) {
    context.commit(mutationTypes.getTypesStimulationStart, payload);
    try {
      let response = await glossaryApi.getTypesStimulation(payload);
      context.commit(mutationTypes.getTypesStimulationSuccess, response.data);
    } catch (error) {
      context.commit(mutationTypes.getTypesStimulationFailure);
    }
  },
  async [actionTypes.updateTypeStimulation](context, payload) {
    context.commit(mutationTypes.updateTypeStimulationStart);
    try {
      let response = await glossaryApi.updateTypeStimulation(payload);
      context.commit(mutationTypes.updateTypeStimulationSuccess, response.data);
    } catch (error) {
      context.commit(mutationTypes.updateTypeStimulationFailure);
    }
  },
};
export default {
  state,
  actions,
  mutations,
};
