export function confirmDelete(message, action, payload) {
  this.$confirm({
    message,
    buttons: {
      left: {
        text: this.$t('UserDefenceProgramEdit:ModalDelete:ButtonDelete'),
        icon: 'delete',
      },
      right: {
        text: this.$t('UserDefenceProgramEdit:ModalDelete:ButtonUndo'),
        icon: 'cancel',
      },
    },
    callback: (confirm) => {
      if (confirm) {
        this.$store.commit(action, payload);
      }
    },
  });
}
