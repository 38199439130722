const map = {
  4: 0, // Грибы -> Фунгициды
  2: 1, // Насекомые -> Инсектициды
  5: 2, // Клещи -> Акарициды
  3: 3, // Сорняки -> Гербициды
  1: 4, // Бактериальный -> Бактерициды
  6: 5, // Другое -> Другое
};

export const mapPestCategoryToProductCategory = (category) => map[category];
