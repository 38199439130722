import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { nanoid } from 'nanoid';
import deepFreeze from 'deep-freeze';
// import { diff } from 'deep-object-diff';

import api from '@/api/clientsArea/programs';
import { mutationTypes as geobaseMutationTypes } from '../glossaryGeobase';
import { mutationTypes as plantsCareMutationTypes } from '../plantsCare';

const serverKeysProgram = [
  'categoriesCultures',
  'cultures',
  'culturesSorts',
  'images',
  'manufacturers',
  'manufacturersCountries',
  'pests',
  'typesStimulation',
  'phases',
  'phasesTypesStimulation',
  'phasesPests',
  'productsPests',
  'productsTypesStimulation',
];

const serverKeysClient = [
  'clientPlaces',
  'clientCultures',
  'clientPlacesZones',
];

const editableKeysProgram = [
  'programCulturesSorts',
  'programPhases',
  'programPestsProducts',
  'programTypesStimulationProducts',
];

const defaultProgram = {
  name: '',
  description: '',
  client_id: null,
  client_name: null,
  client_type: null,
  client_discount: 0,
  client_code: null,
  geo_id: null,
  zone_name: '',
  place_area: null,
  culture_id: null,
  culture_age: null,
  calculation_method_id: null,
  template_id: null,
  template_name: '',
};

const createState = () => ({
  isLoading: false,
  isSaving: false,
  isClientFetching: false,
  clientFetchAbortController: null,
  templatesSearching: false,
  templatesSearchAbortController: null,
  templateFetching: false,
  templateFetchAbortController: null,
  error: null,
  timestamp: 0,
  serverData: {
    program: { ...defaultProgram },
    ...Object.fromEntries(editableKeysProgram.map((key) => [key, []])),
  },
  program: { ...defaultProgram },
  ...Object.fromEntries(
    [...serverKeysProgram, ...editableKeysProgram].map((key) => [key, []])
  ),
  client: null,
  ...Object.fromEntries(serverKeysClient.map((key) => [key, []])),
  templates: [],
  selectedTemplate: null,
  savedRedirectId: null,
  lastInsertedPhaseId: null,
});

const state = createState();

const mutationTypes = {
  resetState: '[user defence] reset state',

  programFetchingStart: '[user defence] program fetching start',
  programFetchingFailure: '[user defence] program fetching failure',
  programFetchingSuccess: '[user defence] program fetching success',

  setClientCode: '[user defence] set program code',
  setProgramName: '[user defence] set program name',
  setProgramDescription: '[user defence] set program description',
  setClientId: '[user defence] set client id',
  setClientDiscount: '[user defence] set client discount',
  setProgramGeo: '[user defence] set program geo',
  setZoneName: '[user defence] set zone name',
  setPlaceArea: '[user defence] set place area',
  setCultureId: '[user defence] set culture id',
  setAge: '[user defence] set age',
  setCalculationMethod: '[user defence] set calculation method',
  setCultureSorts: '[user defence] set culture sorts',
  deleteId: '[user defence] delete id',

  clientFetchingStart: '[user defence] client fetching start',
  clientFetchingFailure: '[user defence] client fetching failure',
  clientFetchingSuccess: '[user defence] client fetching success',
  clientFetchingSetAbortController:
    '[user defence] client fetching set abort controller',
  resetClient: '[user defence] reset client',

  templatesSearchStart: '[user defence] tempaltes search start',
  templatesSearchFailure: '[user defence] tempaltes search failure',
  templatesSearchSuccess: '[user defence] tempaltes search success',
  templatesSearchSetAbortController:
    '[user defence]  tempaltes search set abort controller',
  selectTemplate: '[user defence] select template',

  templateFetchingStart: '[user defence] template fetching start',
  templateFetchingFailure: '[user defence] template fetching failure',
  templateFetchingSuccess: '[user defence] template fetching success',

  setSelectedProduct: '[user defence] set selected product',

  addTemporaryPhase: '[user defence] add temporary phase',
  removeTemporaryPhase: '[user defence] remove temporary phase',
  addPest: '[user defence] add pest',
  addStimulationType: '[user defence] add stimulation type',
  removePest: '[user defence] remove pest',
  removeStimulationType: '[user defence] remove stimulation type',

  setComment: '[user defence] set comment',
  setWaterPhase: '[user defence] set water phase',
  setPestProductQuantity: '[user defence] set pest product quantity',
  setStimulationTypeProductQuantity:
    '[user defence] set stimulation type product quantity',

  saveProgramStart: '[user defence] save program start',
  saveProgramFailure: '[user defence] save program failure',
  saveProgramSuccess: '[user defence] save program success',
};

const actionTypes = {
  fetchProgram: '[user defence] fetch program',
  fetchClient: '[plant care p] fetch client',
  templatesSearch: '[user defence] tempaltes search',
  fetchTemplate: '[user defence] fetch template',
  saveProgram: '[user defence] save program',
  deleteProgram: '[user defence] delete program',
};

const getters = {
  udPestsProductsAsMap(state) {
    return Object.fromEntries(
      state.programPestsProducts.map((p) => [
        `phase_${p.program_phase_id}_pest_${p.pest_id}_product_${p.product_id}`,
        p,
      ])
    );
  },
  udSTypesProductsAsMap(state) {
    return Object.fromEntries(
      state.programTypesStimulationProducts.map((p) => [
        `phase_${p.program_phase_id}_stype_${p.type_stimulation_id}_product_${p.product_id}`,
        p,
      ])
    );
  },
  udPricesMap(state, { plantsCareProductsAsMap }) {
    return Object.fromEntries(
      state.programPhases.map((phase) => [
        phase.id,
        [
          ...state.programPestsProducts,
          ...state.programTypesStimulationProducts,
        ]
          .filter(
            (product) =>
              product.program_phase_id === phase.id && product.is_selected
          )
          .reduce((acc, cur) => {
            const product = plantsCareProductsAsMap[cur.product_id];
            const multiplier = product.picking
              ? product.price / product.picking
              : 0;
            return acc + (cur.quantity ?? 0) * multiplier;
          }, 0),
      ])
    );
  },
  udWasEdited(state) {
    const serverData = {
      ...state.serverData,
      program: { ...state.serverData.program, client_id: undefined },
    };
    // console.log(
    //   diff(serverData, {
    //     program: { ...state.program, client_id: undefined },
    //     ...Object.fromEntries(
    //       editableKeysProgram.map((key) => [key, state[key]])
    //     ),
    //   })
    // );
    return !isEqual(serverData, {
      program: { ...state.program, client_id: undefined },
      ...Object.fromEntries(
        editableKeysProgram.map((key) => [key, state[key]])
      ),
    });
  },
  udCanSave(state, getters) {
    return (
      state.program.name &&
      state.programPhases.length &&
      state.program.place_area &&
      getters.udWasEdited
    );
  },
};

const mutations = {
  [mutationTypes.resetState](state) {
    state.isLoading = false;
    state.error = null;
    state.program = { ...defaultProgram };
    editableKeysProgram.forEach((key) => (state[key] = []));
    state.selectedTemplate = null;
    state.templates = [];
    state.client = null;
    state.serverData = {
      program: { ...defaultProgram },
      ...Object.fromEntries(editableKeysProgram.map((key) => [key, []])),
    };
  },
  [mutationTypes.programFetchingStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.programFetchingFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },
  [mutationTypes.programFetchingSuccess](state, payload) {
    state.isLoading = false;
    state.error = null;
    state.timestamp = new Date().getTime();

    serverKeysProgram.forEach((key) => {
      if (payload[key]?.length) state[key] = payload[key];
    });

    ['program', ...editableKeysProgram].forEach((key) => {
      if (payload[key]) {
        state[key] = cloneDeep(payload[key]);
        state.serverData[key] = cloneDeep(payload[key]);
      }
    });
  },

  [mutationTypes.setProgramName](state, payload) {
    state.program.name = payload;
  },
  [mutationTypes.setProgramDescription](state, payload) {
    state.program.description = payload;
  },
  [mutationTypes.setClientCode](state, payload) {
    state.program.client_code = payload;
  },
  [mutationTypes.setClientId](state, payload) {
    state.program.client_id = payload;
  },
  [mutationTypes.setClientDiscount](state, payload) {
    state.program.client_discount = payload ?? 0;
  },
  [mutationTypes.setProgramGeo](state, payload) {
    state.program.geo_id = payload;
  },
  [mutationTypes.setZoneName](state, payload) {
    state.program.zone_name = payload;
  },
  [mutationTypes.setPlaceArea](state, payload) {
    state.program.place_area = payload;
  },
  [mutationTypes.setCultureId](state, payload) {
    state.program.culture_id = payload;
  },
  [mutationTypes.setAge]({ program }, payload) {
    program.culture_age = payload > 11 ? 11 : payload;
  },
  [mutationTypes.setCalculationMethod]({ program }, payload) {
    program.calculation_method_id = payload === null ? null : Number(payload);
  },
  [mutationTypes.setCultureSorts](state, payload) {
    state.programCulturesSorts = payload.map((sort_id) => ({ sort_id }));
  },
  [mutationTypes.deleteId](state) {
    delete state.program.id;
  },

  [mutationTypes.clientFetchingStart](state) {
    state.isClientFetching = true;
    state.error = null;
  },
  [mutationTypes.clientFetchingFailure](state, payload) {
    if (payload?.message === 'canceled') return;
    state.error = payload;
    state.isClientFetching = false;
  },
  [mutationTypes.clientFetchingSuccess](state, payload) {
    state.error = null;
    state.isClientFetching = false;
    ['client', ...serverKeysClient].forEach(
      (key) => (state[key] = payload[key] ?? [])
    );
  },
  [mutationTypes.clientFetchingSetAbortController](state, payload) {
    state.clientFetchAbortController?.abort('canceled');
    state.clientFetchAbortController = payload;
  },
  [mutationTypes.resetClient](state) {
    state.client = null;
    serverKeysClient.forEach((key) => (state[key] = []));
  },

  [mutationTypes.templatesSearchStart](state) {
    state.templatesSearching = true;
    state.error = null;
  },
  [mutationTypes.templatesSearchFailure](state, payload) {
    if (payload?.message === 'canceled') return;
    state.templatesSearching = false;
    state.error = payload;
  },
  [mutationTypes.templatesSearchSuccess](state, payload) {
    state.templatesSearching = false;
    state.templates = payload;
    state.selectedTemplate = null;
    if (state.templates.length === 1) {
      state.selectedTemplate = state.templates[0];
      state.program.template_id = state.templates[0].id;
      state.program.template_name = state.templates[0].name ?? '';
    }
  },
  [mutationTypes.templatesSearchSetAbortController](state, payload) {
    state.templatesSearchAbortController?.abort('canceled');
    state.templatesSearchAbortController = payload;
  },
  [mutationTypes.selectTemplate](state, payload) {
    if (payload === null) {
      state.selectedTemplate = null;
      editableKeysProgram.forEach((key) => (state[key] = []));
      state.program.template_id = null;
      state.program.template_name = null;
      return;
    }
    const template = state.templates.find(({ id }) => id === payload);
    if (template) {
      state.selectedTemplate = template;
      state.program.template_id = template.id;
      state.program.template_name = template.name ?? '';
    }
  },

  [mutationTypes.templateFetchingStart](state) {
    state.templateFetching = true;
    state.error = null;
  },
  [mutationTypes.templateFetchingFailure](state, payload) {
    state.templateFetching = false;
    state.error = payload;
  },
  [mutationTypes.templateFetchingSuccess](state, payload) {
    serverKeysProgram.forEach(
      (key) => payload[key] && (state[key] = payload[key])
    );
    const phases = payload.phases.map((p) => ({
      ...p,
      id: nanoid(),
      originalId: p.id,
      is_temporary: 0,
    }));

    state.programPestsProducts = [];
    state.programTypesStimulationProducts = [];
    for (const phase of phases) {
      const water = payload.templateWaterPhases.find(
        (wp) => wp.phase_id === phase.originalId
      )?.quantity;
      if (water) phase.volume_per_ha = water;

      phase.main_image =
        payload.images.find(
          (img) =>
            img.is_main && img.object_id === phase.originalId && img.type === 1
        )?.name ?? null;

      const pestsProducts = payload.templatePestsProducts
        .filter(({ phase_id }) => phase_id === phase.originalId)
        .map((pp) => ({
          id: nanoid(),
          program_phase_id: phase.id,
          pest_id: pp.pest_id,
          product_id: pp.product_id,
          quantity: pp.quantity,
          is_selected: 0,
        }));
      state.programPestsProducts = [
        ...state.programPestsProducts,
        ...pestsProducts,
      ];

      const tsProducts = payload.templateTypesStimulationProducts
        .filter(({ phase_id }) => phase_id === phase.originalId)
        .map((tsp) => ({
          id: nanoid(),
          program_phase_id: phase.id,
          type_stimulation_id: tsp.type_stimulation_id,
          product_id: tsp.product_id,
          quantity: tsp.quantity,
          is_selected: 0,
        }));
      state.programTypesStimulationProducts = [
        ...state.programTypesStimulationProducts,
        ...tsProducts,
      ];
    }

    state.programPhases = phases.filter(
      ({ id }) =>
        state.programPestsProducts.some((p) => p.program_phase_id === id) ||
        state.programTypesStimulationProducts.some(
          (p) => p.program_phase_id === id
        )
    );
    state.templateFetching = false;
  },

  [mutationTypes.setSelectedProduct](
    state,
    { object_id, product_id, program_phase_id, forStimulationType }
  ) {
    const list = forStimulationType
      ? state.programTypesStimulationProducts
      : state.programPestsProducts;

    const product = list.find(
      (p) =>
        p.product_id === product_id &&
        p.program_phase_id === program_phase_id &&
        object_id === (forStimulationType ? p.type_stimulation_id : p.pest_id)
    );
    product.is_selected = product.is_selected ? 0 : 1;
  },

  [mutationTypes.addTemporaryPhase](state, payload) {
    let index = state.programPhases.findIndex(({ id }) => id === payload);
    const phase = {
      ...state.programPhases[index],
      id: nanoid(),
      is_temporary: 1,
      comment: '',
      volume_per_ha: null,
    };
    while (state.programPhases[index + 1]?.is_temporary === 1) {
      index += 1;
    }
    state.programPhases.splice(index + 1, 0, phase);
    state.lastInsertedPhaseId = phase.id;
  },
  [mutationTypes.removeTemporaryPhase](state, payload) {
    state.programPhases = state.programPhases.filter(
      ({ id }) => id !== payload
    );
    state.programPestsProducts = state.programPestsProducts.filter(
      ({ program_phase_id }) => program_phase_id !== payload
    );
    state.programTypesStimulation = state.productsTypesStimulation.filter(
      ({ program_phase_id }) => program_phase_id !== payload
    );
  },
  [mutationTypes.addPest](state, { id, phaseId }) {
    state.productsPests
      .filter((e) => e.pest_id === id)
      .forEach(({ pest_id, product_id }) =>
        state.programPestsProducts.push({
          program_phase_id: phaseId,
          pest_id,
          product_id,
          quantity: undefined,
          is_selected: 1,
          // TODO: price
        })
      );
  },
  [mutationTypes.addStimulationType](state, { id, phaseId }) {
    state.productsTypesStimulation
      .filter((e) => e.type_stimulation_id === id)
      .forEach(({ type_stimulation_id, product_id }) =>
        state.programTypesStimulationProducts.push({
          program_phase_id: phaseId,
          type_stimulation_id,
          product_id,
          quantity: undefined,
          is_selected: 1,
          // TODO: price
        })
      );
  },
  [mutationTypes.removePest](state, { id, phaseId }) {
    state.programPestsProducts = state.programPestsProducts.filter(
      (p) => !(p.pest_id === id && p.program_phase_id === phaseId)
    );
  },
  [mutationTypes.removeStimulationType](state, { id, phaseId }) {
    state.programTypesStimulationProducts =
      state.programTypesStimulationProducts.filter(
        (p) => !(p.type_stimulation_id === id && p.program_phase_id === phaseId)
      );
  },

  [mutationTypes.setComment](state, { id, comment }) {
    state.programPhases.find((p) => p.id === id).comment = comment;
  },
  [mutationTypes.setWaterPhase](state, { id, value }) {
    state.programPhases.find((p) => p.id === id).volume_per_ha = value;
  },
  [mutationTypes.setPestProductQuantity](
    state,
    { product_id, pest_id, program_phase_id, quantity }
  ) {
    state.programPestsProducts.find(
      (p) =>
        p.product_id === product_id &&
        p.pest_id === pest_id &&
        p.program_phase_id === program_phase_id
    ).quantity = quantity;
  },
  [mutationTypes.setStimulationTypeProductQuantity](
    state,
    { product_id, type_stimulation_id, program_phase_id, quantity }
  ) {
    state.programTypesStimulationProducts.find(
      (p) =>
        p.product_id === product_id &&
        p.type_stimulation_id === type_stimulation_id &&
        p.program_phase_id === program_phase_id
    ).quantity = quantity;
  },

  [mutationTypes.saveProgramStart](state) {
    state.isSaving = true;
    state.error = null;
  },
  [mutationTypes.saveProgramFailure](state, payload) {
    state.isSaving = false;
    state.error = payload;
  },
  [mutationTypes.saveProgramSuccess](state, payload) {
    state.isSaving = false;
    state.error = null;
    state.savedRedirectId = payload;
    state.serverData = cloneDeep(
      Object.fromEntries(
        ['program', ...editableKeysProgram].map((key) => [key, state[key]])
      )
    );
  },
};

const actions = {
  async [actionTypes.fetchProgram]({ commit, rootState }, payload) {
    commit(mutationTypes.programFetchingStart);
    try {
      const timestampGeobase = rootState.glossaryGeobase.timestamp;
      // const timestampClients = rootState.clientsList.timestampClients;
      const { promise } = api.getClientData();
      const [res, resClient] = await Promise.all([
        payload
          ? api.getEditProgramData(
              payload,
              state.timestamp,
              timestampGeobase
              // timestampClients
            )
          : api.getNewProgramData(
              state.timestamp,
              timestampGeobase
              // timestampClients
            ),
        promise,
      ]);

      if (res.status === 'ok' && resClient.status === 'ok') {
        if (res.programPhases) {
          res.programPhases.sort((a, b) => a.order - b.order);
        }

        commit(mutationTypes.programFetchingSuccess, deepFreeze(res));
        if (res.geoBase) {
          commit(
            geobaseMutationTypes.getGeobaseSuccess,
            deepFreeze(res.geoBase),
            {
              root: true,
            }
          );
        }

        commit(mutationTypes.setClientId, resClient.client.id);
        commit(mutationTypes.setClientDiscount, resClient.client.discount ?? 0);
        commit(mutationTypes.clientFetchingSuccess, resClient);

        if (res.products?.length) {
          commit(plantsCareMutationTypes.setProducts, res, {
            root: true,
          });
        }
      } else {
        throw Error(res.message || 'Unknown error');
      }
    } catch (err) {
      commit(mutationTypes.programFetchingFailure, err);
    }
  },
  async [actionTypes.templatesSearch]({ commit }, payload) {
    commit(mutationTypes.templatesSearchStart);
    try {
      const { controller, promise } = api.findTemplates(payload);
      commit(mutationTypes.templatesSearchSetAbortController, controller);
      const res = await promise;
      if (res.status === 'ok') {
        commit(
          mutationTypes.templatesSearchSuccess,
          Object.freeze(res.templates ?? [])
        );
      } else throw Error(res.message || 'Unknown error');
    } catch (err) {
      commit(mutationTypes.templatesSearchFailure, err);
    }
  },
  async [actionTypes.fetchTemplate]({ commit, rootState }, payload) {
    commit(mutationTypes.templateFetchingStart);
    try {
      const res = await api.getEditTemplateData(
        payload,
        0,
        Number(new Date()),
        rootState.plantsCare.productsTimestamp
      );
      if (res.status === 'ok') {
        commit(mutationTypes.templateFetchingSuccess, deepFreeze(res));
        if (res.products?.length) {
          commit(plantsCareMutationTypes.setProducts, res, {
            root: true,
          });
        }
      } else throw Error(res.message || 'Unknown error');
    } catch (err) {
      commit(mutationTypes.templateFetchingFailure, err);
    }
  },
  async [actionTypes.saveProgram]({ commit, state }) {
    commit(mutationTypes.saveProgramStart);
    try {
      const payload = Object.fromEntries(
        ['program', ...editableKeysProgram].map((key) => [key, state[key]])
      );
      payload.programPhases = payload.programPhases.map((p, i) => ({
        ...p,
        order: i + 1,
      }));
      const res = await api.saveProgramData(payload);
      if (res.status === 'ok') {
        commit(mutationTypes.saveProgramSuccess, res.id);
      } else throw Error(res.message || 'Unknown error');
    } catch (err) {
      commit(mutationTypes.saveProgramFailure, err);
    }
  },
  async [actionTypes.deleteProgram]({ commit, state }) {
    commit(mutationTypes.saveProgramStart);
    try {
      const res = await api.saveProgramData({
        delete: true,
        program: { id: state.program.id },
      });
      if (res.status !== 'ok') throw Error(res.message || 'Unknown error');
      commit(mutationTypes.saveProgramSuccess, {});
    } catch (err) {
      commit(mutationTypes.saveProgramFailure, err);
    }
  },
};

export { mutationTypes, actionTypes, serverKeysProgram };

export default {
  state,
  actions,
  mutations,
  getters,
};
