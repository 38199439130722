<!--Translated-->
<template>
  <div class="cwrap">
    <div class="chead el--borders-top el--borders-sides el--rounded-top">
      <div class="chead__line-block">
        <div
          class="vsvg vsvg--standart chead_icon"
          v-svg="{ name: 'edit' }"
        ></div>
        <div class="chead_text">
          {{ $t('FertilizerProgramDescription:Title') }}
        </div>
      </div>
    </div>

    <div
      class="cblock el--borders-all el--rounded-bottom marginbottom--standart"
    >
      <div class="cblock__internal">
        <div class="cblock__line">
          <div class="cblock__line-block">
            <Input
              type="text"
              :placeholder="
                $t('FertilizerProgramDescription:ProgramName.Placeholder')
              "
              :label="$t('FertilizerProgramDescription:ProgramName.Label')"
              size="long"
              v-model="programName"
              :state="disabled ? 'disabled' : ''"
            />
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <Input
              type="text"
              :placeholder="
                $t('FertilizerProgramDescription:Comment.Placeholder')
              "
              :label="$t('FertilizerProgramDescription:Comment.Label')"
              size="long4x"
              v-model="programDescription"
              :state="disabled ? 'disabled' : ''"
            />
          </div>
        </div>

        <div class="cblock__line" v-if="clientPlaces.length > 0">
          <div class="cblock__line-block margintop--micro">
            <Button
              :text="$t('FertilizerProgramDescription:ButtonPlaces')"
              size="standart2x"
              color="accent"
              icon="map"
              @click="showModalChoosePlace = true"
              :disabled="disabled || placesSelectDisabled"
            />
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <LocationSelect
              :geoEntries="geoMapped"
              :freeSelect="true"
              v-model="geoId"
              :disabled="disabled"
            />
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <Input
              type="text"
              :placeholder="$t('FertilizerProgramDescription:Zone.Placeholder')"
              :label="$t('FertilizerProgramDescription:Zone.Label')"
              size="long"
              v-model="zoneName"
              :state="disabled ? 'disabled' : ''"
            />

            <InputNumber
              :placeholder="$t('FertilizerProgramDescription:Area.Placeholder')"
              :label="$t('FertilizerProgramDescription:Area.Label')"
              size="standart"
              v-model="placeArea"
              :state="disabled ? 'disabled' : ''"
            />
            <Input
              type="text"
              placeholder="0"
              :label="$t('UserDefenceProgramDescription:Discount')"
              :state="disabled ? 'disabled' : 'disabled2'"
              size="standart"
              :value="clientDiscount"
            />
          </div>
        </div>

        <div class="cblock__line">
          <div class="cblock__line-block">
            <CultureSelect
              :categories="cultureCategories"
              :cultures="cultures"
              v-model="cultureId"
              :disabled="disabled"
              :locked="selectedOneYearCulture"
            />
            <CultureAgeSelect v-model="age" :disabled="disabled" />

            <InputNumber
              placeholder="0"
              :label="$t('FertilizerProgramDescription:CountPlants.Label')"
              size="mini"
              v-model="plantsPerHa"
              :state="disabled ? 'disabled' : ''"
            />

            <Input
              type="text"
              placeholder="0"
              :label="$t('FertilizerProgramDescription:CountPlantsAll.Label')"
              :state="disabled ? 'disabled' : 'disabled2'"
              size="mini"
              :value="totalPlantsCount"
            />
          </div>
        </div>

        <div class="cblock__line" v-if="sorts.length">
          <div class="cblock__line-block">
            <ChksetSort
              :options="sorts"
              v-model="selectedSorts"
              :label="$t('FertilizerProgramDescription:Sotrs')"
              :disabled="disabled"
            />
          </div>
        </div>
      </div>
    </div>

    <ModalChoosePlace
      v-if="showModalChoosePlace"
      v-model="showModalChoosePlace"
      :clientPlaces="clientPlaces"
      :clientCultures="clientCultures"
      :clientZones="clientZones"
      :cultures="culturesRaw"
      :clientZonesAnalyzes="clientZonesAnalyzes"
      @close="showModalChoosePlace = false"
      @selected="clientPlaceSelected"
      :showClientLink="false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { mutationTypes } from '@/store/modules/clientsArea/nutritionPrograms';
import { mapInputEntityWithMerge } from '@/components/adapters/mapInput';
import routeNames from '@/router/routeNames';

import Input from '@/components/views/Input';
import InputNumber from '@/components/views/wrappers/InputNumberWithCast';
import Button from '@/components/views/Button';
import ChksetSort from '@/components/sets/ChksetSorts';
import LocationSelect from '@/components/views/LocationSelect';
import ModalChoosePlace from '@/components/modals/programs/ModalChoosePlace';
import CultureSelect from '@/components/views/CultureSelect';
import CultureAgeSelect from '@/components/views/CultureAgeSelect';

export default {
  name: 'UserProgramDescription',
  components: {
    Input,
    InputNumber,
    Button,
    ChksetSort,
    LocationSelect,
    ModalChoosePlace,
    CultureSelect,
    CultureAgeSelect,
  },
  data() {
    return {
      showModalChoosePlace: false,
    };
  },
  computed: {
    ...mapGetters(['geoMapped', 'geoEntities']),
    ...mapState({
      isLoading: (state) => state.userNutrition.isLoading,
      isSaving: (state) => state.userNutrition.isSaving,
      hasPhases: (state) => Boolean(state.userNutrition.programPhases.length),
      program: (state) => state.userNutrition.program,
      clientPlaces: (state) => state.userNutrition.clientPlaces,
      clientCultures: (state) => state.userNutrition.clientCultures,
      clientZones: (state) => state.userNutrition.clientPlacesZones,
      culturesRaw: (state) => state.userNutrition.cultures,
      clientZonesAnalyzes: (state) =>
        state.userNutrition.clientZonesAnalyzes.filter(({ id }) =>
          state.userNutrition.clientAnalyzesElements.some(
            (e) => e.analyse_id === id
          )
        ),
      elements: (state) => state.userNutrition.elements,
      clientAnalyzesElements: (state) =>
        state.userNutrition.clientAnalyzesElements,
      clientFetching: (state) => state.userNutrition.isClientFetching,
      client: (state) => state.userNutrition.client,
      cultureCategories: (state) =>
        state.userNutrition.categoriesCultures.map(mapInputEntityWithMerge),
      cultures: (state) =>
        state.userNutrition.cultures.map(mapInputEntityWithMerge),
      sorts(state) {
        return state.userNutrition.culturesSorts
          .filter((sort) => sort.culture_id === this.cultureId)
          .map((sort) => ({
            ...sort,
            text: sort.name,
            value: sort.id,
          }));
      },
      selectedSortsGet: (state) =>
        state.userNutrition.programCulturesSorts.map((s) => s.sort_id),
    }),
    selectedOneYearCulture() {
      return Boolean(
        this.culturesRaw.find((c) => c.id === this.cultureId)?.only_one_year
      );
    },
    clientDiscount: {
      get() {
        return this.program.client_discount;
      },
      set(value) {
        this.$store.commit(mutationTypes.setClientDiscount, value);
      },
    },
    selectedSorts: {
      get() {
        return this.selectedSortsGet;
      },
      set(value) {
        this.$store.commit(mutationTypes.setCultureSorts, value);
      },
    },
    programName: {
      get() {
        return this.program.name;
      },
      set(name) {
        this.$store.commit(mutationTypes.setProgramName, name);
      },
    },
    programDescription: {
      get() {
        return this.program.description;
      },
      set(value) {
        this.$store.commit(mutationTypes.setProgramDescription, value);
      },
    },
    geoId: {
      get() {
        return this.program.geo_id;
      },
      set(id) {
        this.$store.commit(mutationTypes.setProgramGeo, id);
      },
    },
    zoneName: {
      get() {
        return this.program.zone_name;
      },
      set(name) {
        this.$store.commit(mutationTypes.setZoneName, name);
      },
    },
    placeArea: {
      get() {
        return this.program.place_area;
      },
      set(area) {
        this.$store.commit(mutationTypes.setPlaceArea, area);
      },
    },
    cultureId: {
      get() {
        return this.program.culture_id;
      },
      set(id) {
        this.$store.commit(mutationTypes.setCultureId, id);
      },
    },
    age: {
      get() {
        return this.program.culture_age ?? null;
      },
      set(age) {
        this.$store.commit(mutationTypes.setAge, age);
      },
    },
    plantsPerHa: {
      get() {
        return this.program.count_plants_per_ha;
      },
      set(value) {
        this.$store.commit(mutationTypes.setPlantsPerHa, value);
      },
    },
    totalPlantsCount() {
      return this.plantsPerHa === null || this.placeArea === null
        ? ''
        : this.plantsPerHa * this.placeArea;
    },
    existingClientSelected() {
      return Boolean(this.client);
    },
    placesSelectDisabled() {
      return !this.clientPlaces.some((p) => p.geo_id);
    },
    disabled() {
      return this.isLoading || this.isSaving;
    },
    isEditing() {
      return this.$route.name === routeNames.userFertilizerProgramEdit;
    },
  },
  methods: {
    clientPlaceSelected(place) {
      this.$store.commit(mutationTypes.setAnalysisFoundState, false);
      this.$store.commit(mutationTypes.setProgramGeo, place.geoId);
      this.$store.commit(mutationTypes.setZoneName, place.zone?.name ?? '');
      this.$store.commit(mutationTypes.setCultureId, place.cultureId);
      this.$store.commit(mutationTypes.setPlaceArea, place.area ?? null);
      this.$store.commit(mutationTypes.setAge, place.age ?? null);
      this.$store.commit(
        mutationTypes.setPlantsPerHa,
        place.plantsPerHa ?? null
      );

      const [analysis] = this.clientZonesAnalyzes
        .filter((a) => a.zone_id === place?.zone?.id)
        .sort((a, b) => b.date - a.date);

      const elements = this.clientAnalyzesElements.filter(
        ({ analyse_id }) => analyse_id === analysis?.id
      );

      if (!elements.length) {
        this.$store.commit(mutationTypes.setAnalysisFoundState, true);
      }

      for (const element of this.elements) {
        const elementData = elements.find(
          ({ element_id, depth }) => element_id === element.id && depth === 0
        ) ?? {
          element_id: element.id,
          depth: 0,
          quantity: null,
        };

        this.$store.commit(mutationTypes.upsertAnalysisElement, elementData);
      }
    },
  },
  watch: {
    plantsPerHa() {
      this.$store.commit(mutationTypes.updatePlantsPerHaDeps);
    },
    selectedOneYearCulture: {
      handler(val) {
        if (val) this.age = 1;
      },
      immediate: true,
    },
  },
};
</script>
