export const mapInputEntity = (e, key = 'name') => ({
  text: e[key],
  value: e.id,
});

export const mapInputEntityUnary = (e) => mapInputEntity(e);

export const mapInputEntityWithMerge = (e) => ({
  ...e,
  ...mapInputEntityUnary(e),
});
