<template>
  <div
    style="cursor: pointer"
    class="chead el--borders-top el--borders-sides el--rounded-top last-visible-header"
    :class="{ 'is-expanded': isShown, 'chead--collapsedgreen': !isShown }"
    @click="$emit('click')"
  >
    <div class="chead__line-block">
      <div
        class="vsvg vsvg--standart chead_icon"
        v-svg="{ name: iconName }"
      ></div>
      <div class="chead_text">{{ title }}</div>
    </div>
    <div class="chead__line-block">
      <div
        class="vsvg vsvg--wide chead_chevron vsvg-auto"
        v-svg="{ name: isShown ? 'arrowup' : 'arrowdown' }"
        :key="isShown"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClientTableHeader',
  props: {
    isShown: { type: Boolean, default: false },
    title: { type: String, required: true },
    iconName: { type: String, required: true },
  },
};
</script>
