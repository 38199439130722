import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import groupBy from 'lodash.groupby';
import deepFreeze from 'deep-freeze';
import { nanoid } from 'nanoid';
import { eachWeekOfInterval, nextSunday } from 'date-fns';
import i18n from '@/i18n';
// import { diff } from 'deep-object-diff';

import api from '@/api/nutrition';
import { elementPossibleUnits } from '@/pages/programs/elementMeasureUnits';

import { mutationTypes as geobaseMutationTypes } from './glossaryGeobase';
import { mutationTypes as clientsMutaionTypes } from './clientsList';
import { mutationTypes as plantsCareMutationTypes } from './plantsCare';

const staticData = {
  methods: [
    { id: 1, name: i18n.t('Vuex:clientsArea:nutritionPrograms:Methods:1') }, //'Капельно' },
    { id: 2, name: i18n.t('Vuex:clientsArea:nutritionPrograms:Methods:2') }, //'В почву' },
  ],
};

const serverKeysProgram = [
  'categoriesCultures',
  'cultures',
  'culturesSorts',
  'productsFertilizer',
  'productsFertilizerSubcategories',
  'productsFertilizerElements',
  'elements',
  'manufacturers',
  'manufacturersCountries',
  'defaultProducts',
  'currentManagerContacts',
];

const serverKeysClient = [
  'client',
  'clientPlaces',
  'clientCultures',
  'clientPlacesZones',
  'clientZonesAnalyzes',
  'clientAnalyzesElements',
];

const editableKeysProgram = [
  'programCulturesSorts',
  'programAnalyze',
  'programPhases',
  'programFertilizerProducts',
];

const defaultProgram = {
  name: '',
  description: '',
  client_id: null,
  client_name: null,
  client_type: null,
  client_discount: 0,
  client_code: null,
  geo_id: null,
  zone_name: '',
  place_area: null,
  culture_id: null,
  culture_age: null,
  count_plants_per_ha: null,
  period_start: null,
  period_end: null,
  period_description: null,
  rate_dollar: null,
  rate_euro: null,
};

const createState = () => ({
  isLoading: false,
  isSaving: false,
  isClientFetching: false,
  clientFetchAbortController: null,
  isCultureFetching: false,
  cultureFetchAbortController: null,
  cultureData: [],
  error: null,
  timestamp: 0,
  serverData: {
    program: { ...defaultProgram },
    ...Object.fromEntries(editableKeysProgram.map((key) => [key, []])),
  },
  program: { ...defaultProgram },
  ...Object.fromEntries(
    [...serverKeysProgram, ...editableKeysProgram].map((key) => [key, []])
  ),
  currentManager: null,
  client: null,
  ...Object.fromEntries(serverKeysClient.map((key) => [key, []])),
  savedRedirectId: null,
  lastInsertedPhaseId: null,
});

const state = createState();

const mutationTypes = {
  resetState: '[plants care n] reset state',

  programFetchingStart: '[plants care n] program fetching start',
  programFetchingFailure: '[plants care n] program fetching failure',
  programFetchingSuccess: '[plants care n] program fetching success',

  setClientCode: '[plants care n] set program code',
  setProgramName: '[plants care n] set program name',
  setProgramDescription: '[plants care n] set program description',
  setClientName: '[plants care n] set client name',
  setClientType: '[plants care n] set client type',
  setClientId: '[plants care n] set client id',
  setClientDiscount: '[plants care n] set client discount',
  setProgramGeo: '[plants care n] set program geo',
  setZoneName: '[plants care n] set zone name',
  setPlaceArea: '[plants care n] set place area',
  setCultureId: '[plants care n] set culture id',
  setAge: '[plants care n] set age',
  setPlantsPerHa: '[plants care n] set plants per ha',
  setCultureSorts: '[plants care n] set culture sorts',
  setPeriod: '[plants care n] set period',
  setPeriodComment: '[plants care n] set period comment',
  upsertAnalysisElement: '[plants care n] upsert analysis element',
  setRateEU: '[plants care n] set EU exchange rate',
  setRateUSD: '[plants care n] set USD exchange rate',
  deleteId: '[plants care n] delete id',
  updatePlantsPerHaDeps: '[plants care n] update plants per ha deps',

  cultureDataFetchStart: '[plants care n] culture data fetch start',
  cultureDataFetchFailure: '[plants care n] culture data fetch failure',
  cultureDataFetchSuccess: '[plants care n] culture data fetch success',
  cultureDataFetchSetAbortController:
    '[plants care n] culture data fetch set abot controller',

  clientFetchingStart: '[plants care n] client fetching start',
  clientFetchingFailure: '[plants care n] client fetching failure',
  clientFetchingSuccess: '[plants care n] client fetching success',
  clientFetchingSetAbortController:
    '[plants care n] client fetching set abort controller',
  resetClient: '[plants care n] reset client',

  createPhases: '[plants care n] create phases',

  addProduct: '[plants care n] add product',
  removeProduct: '[plants care n] remove product',
  setProductMethodId: '[plants care n] set product method id',
  setProductCountPerHa: '[plants care n] set product count per ha',
  setProductCountPerPlant: '[plants care n] set product count per plant',
  fillAutoProducts: '[plants care n] fill auto products',

  saveProgramStart: '[plants care n] save program start',
  saveProgramFailure: '[plants care n] save program failure',
  saveProgramSuccess: '[plants care n] save program success',
};

const actionTypes = {
  fetchProgram: '[plants care n] fetch program',
  fetchClient: '[plant care n] fetch client',
  fetchCultureData: '[plants care n] fetch culture data',
  saveProgram: '[plants care n] save program',
  deleteProgram: '[plants care n] delete program',
};

const getters = {
  nutritionElementsValuesAsMap(state) {
    const selectedCulture = state.cultures.find(
      ({ id }) => state.program.culture_id === id
    );
    const age = selectedCulture?.only_one_year ? 1 : state.program.culture_age;

    return Object.fromEntries(
      state.elements.map((el, index) => {
        const element = state.programAnalyze.find(
          ({ element_id }) => element_id === el.id
        );
        const quantity = element?.quantity ?? null;
        const unitsId = element?.units_id ?? 1;
        const unitsData = elementPossibleUnits.find(
          ({ elementId }) => el.id === elementId
        );
        const multiplier =
          unitsData?.units?.find(({ id }) => id === unitsId)?.multiplier ?? 1;
        const quantityMgKg = quantity ? quantity * multiplier : 0;

        return [
          el.id,
          {
            quantity,
            units_id: unitsId,
            quantityMgKg,
            demand:
              age === null
                ? null
                : state.cultureData?.at(age - 1)?.at(index) ?? null,
          },
        ];
      })
    );
  },
  nutritionProductsEntriesAsMap(state) {
    return Object.fromEntries(state.productsFertilizer.map((p) => [p.id, p]));
  },
  nutritionPhasesProductsAsMap(state, getters) {
    const result = {};
    for (const phase of state.programPhases) {
      result[phase.id] = [];
    }
    for (const product of state.programFertilizerProducts) {
      result[product.program_phase_id].push({
        ...product,
        productRef: getters.nutritionProductsEntriesAsMap[product.product_id],
      });
    }
    return result;
  },
  nutritionTotalProductList(state, getters) {
    const round = (num) => {
      const result = Math.round(num);
      return result === 0 && num > 0 ? 1 : result;
    };

    const mapProducts = (products) => {
      const totalPerHa = products.reduce((a, b) => a + b.count_per_ha, 0);
      const totalForArea = totalPerHa * (state.program.place_area ?? 0);
      const productLink =
        getters.nutritionProductsEntriesAsMap[products[0].product_id];
      const packages = productLink.picking
        ? totalForArea / productLink.picking
        : 0;
      const packagesInt = round(packages);
      return {
        products,
        totalPerHa,
        totalForArea,
        productLink,
        packages,
        packagesInt,
      };
    };

    return Object.values(groupBy(state.programFertilizerProducts, 'product_id'))
      .map(mapProducts)
      .filter(
        ({ packagesInt }) =>
          Number.isSafeInteger(packagesInt) && packagesInt > 0
      );
  },
  nutritionTotalProductListAsMap(_, getters) {
    return Object.fromEntries(
      getters.nutritionTotalProductList.map((p) => [
        p.products[0].product_id,
        p,
      ])
    );
  },
  nutritionProductsFertilizerElementsByProduct() {
    return groupBy(state.productsFertilizerElements, 'product_id');
  },
  nutritionProductsByElement(state, getters) {
    const entries = groupBy(state.productsFertilizerElements, 'element_id');
    const result = {};
    for (const products of Object.values(entries)) {
      let q = 0;
      for (const product of products) {
        const entry =
          getters.nutritionTotalProductListAsMap[product.product_id];
        if (!entry) continue;
        q += (entry.totalPerHa * product.quantity) / 100;
      }
      result[products[0].element_id] = q;
    }
    return result;
  },
  nutritionProgramEdited(state) {
    return !isEqual(state.serverData, {
      program: state.program,
      ...Object.fromEntries(
        editableKeysProgram.map((key) => [key, state[key]])
      ),
    });
  },
  nutritionCanSaveProgram(state, getters) {
    // console.log(
    //   diff(state.serverData, {
    //     program: state.program,
    //     ...Object.fromEntries(
    //       editableKeysProgram.map((key) => [key, state[key]])
    //     ),
    //   })
    // );
    return (
      state.program.name &&
      state.programPhases.length &&
      state.program.place_area &&
      getters.nutritionProgramEdited
    );
  },
};

const mutations = {
  [mutationTypes.resetState](state) {
    state.isLoading = false;
    state.error = null;
    state.program = { ...defaultProgram };
    editableKeysProgram.forEach((key) => (state[key] = []));
    state.client = null;
  },
  [mutationTypes.programFetchingStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.programFetchingFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },
  [mutationTypes.programFetchingSuccess](state, payload) {
    state.isLoading = false;
    state.error = null;
    state.timestamp = new Date().getTime();

    serverKeysProgram.forEach((key) => {
      if (payload[key]?.length) state[key] = payload[key];
    });
    if (payload.currentManager) state.currentManager = payload.currentManager;

    ['program', ...editableKeysProgram].forEach((key) => {
      if (payload[key]) {
        state[key] = cloneDeep(payload[key]);
        state.serverData[key] = cloneDeep(payload[key]);
      }
    });
  },

  [mutationTypes.setProgramName](state, payload) {
    state.program.name = payload;
  },
  [mutationTypes.setProgramDescription](state, payload) {
    state.program.description = payload;
  },
  [mutationTypes.setClientCode](state, payload) {
    state.program.client_code = payload;
  },
  [mutationTypes.setClientName](state, payload) {
    state.program.client_name = payload;
  },
  [mutationTypes.setClientType](state, payload) {
    state.program.client_type = payload;
  },
  [mutationTypes.setClientId](state, payload) {
    state.program.client_id = payload;
  },
  [mutationTypes.setClientDiscount](state, payload) {
    state.program.client_discount = payload ?? 0;
  },
  [mutationTypes.setProgramGeo](state, payload) {
    state.program.geo_id = payload;
  },
  [mutationTypes.setZoneName](state, payload) {
    state.program.zone_name = payload;
  },
  [mutationTypes.setPlaceArea](state, payload) {
    state.program.place_area = payload;
  },
  [mutationTypes.setCultureId](state, payload) {
    if (payload !== state.program.culture_id || payload === null) {
      state.programCulturesSorts = [];
      state.program.culture_id = payload;
    }
  },
  [mutationTypes.setAge]({ program }, payload) {
    program.culture_age = payload > 11 ? 11 : payload;
  },
  [mutationTypes.setPlantsPerHa]({ program }, payload) {
    program.count_plants_per_ha = payload;
  },
  [mutationTypes.setCultureSorts](state, payload) {
    state.programCulturesSorts = payload.map((sort_id) => ({ sort_id }));
  },
  [mutationTypes.setPeriod](state, payload) {
    state.program.period_start = new Date(
      Date.UTC(
        payload.selectedYearStart,
        payload.selectedMonthStart,
        payload.selectedDayStart
      )
    ).getTime();
    state.program.period_end = new Date(
      Date.UTC(
        payload.selectedYearEnd,
        payload.selectedMonthEnd,
        payload.selectedDayEnd
      )
    ).getTime();
  },
  [mutationTypes.setPeriodComment](state, payload) {
    state.program.period_description = payload;
  },
  [mutationTypes.upsertAnalysisElement](
    state,
    { element_id, quantity, units_id }
  ) {
    if (quantity !== undefined) {
      let el = state.programAnalyze.find((e) => e.element_id === element_id);
      if (el) {
        el.quantity = quantity;
      } else {
        state.programAnalyze.push({
          id: nanoid(),
          units_id: units_id || 1,
          program_id: state.program.id,
          element_id,
          quantity,
        });
      }
    }

    if (units_id !== undefined) {
      let el = state.programAnalyze.find((e) => e.element_id === element_id);
      if (el) {
        el.units_id = units_id;
      } else {
        state.programAnalyze.push({
          id: nanoid(),
          units_id,
          program_id: state.program.id,
          element_id,
          quantity: null,
        });
      }
    }

    state.programAnalyze = state.programAnalyze.filter(
      ({ quantity, units_id }) => quantity !== null || units_id
    );
  },
  [mutationTypes.setRateEU](state, payload) {
    state.program.rate_euro = payload;
  },
  [mutationTypes.setRateUSD](state, payload) {
    state.program.rate_dollar = payload;
  },
  [mutationTypes.deleteId](state) {
    delete state.program.id;
    delete state.program.client_id;
    delete state.program.client_discount;
  },
  [mutationTypes.updatePlantsPerHaDeps](state) {
    state.programFertilizerProducts.forEach((p) => {
      if (p.count_per_ha === null) {
        p.count_per_plant = null;
      } else {
        const result = convertPlansToHa(
          state.program.count_plants_per_ha,
          p.count_per_ha
        );
        if (Number.isFinite(result)) {
          p.count_per_plant = result;
        }
      }
    });
  },

  [mutationTypes.cultureDataFetchStart](state) {
    state.isCultureFetching = true;
    state.error = null;
  },
  [mutationTypes.cultureDataFetchFailure](state, payload) {
    if (payload?.message === 'canceled') return;
    state.error = payload;
    state.isCultureFetching = false;
  },
  [mutationTypes.cultureDataFetchSuccess](state, payload) {
    state.isCultureFetching = false;
    state.error = null;
    state.cultureData = payload;
  },
  [mutationTypes.cultureDataFetchSetAbortController](state, payload) {
    state.cultureFetchAbortController?.abort('canceled');
    state.cultureFetchAbortController = payload;
  },

  [mutationTypes.clientFetchingStart](state) {
    state.isClientFetching = true;
    state.error = null;
  },
  [mutationTypes.clientFetchingFailure](state, payload) {
    if (payload?.message === 'canceled') return;
    state.error = payload;
    state.isClientFetching = false;
  },
  [mutationTypes.clientFetchingSuccess](state, payload) {
    state.error = null;
    state.isClientFetching = false;
    ['client', ...serverKeysClient].forEach(
      (key) => (state[key] = payload[key] ?? [])
    );
  },
  [mutationTypes.clientFetchingSetAbortController](state, payload) {
    state.clientFetchAbortController?.abort('canceled');
    state.clientFetchAbortController = payload;
  },
  [mutationTypes.resetClient](state) {
    state.client = null;
    serverKeysClient.forEach((key) => (state[key] = []));
  },

  [mutationTypes.createPhases]({ program }) {
    state.programPhases = [];
    state.programFertilizerProducts = [];
    //console.log({ start: program.period_start, end: program.period_end });

    const weeks = eachWeekOfInterval(
      { start: program.period_start, end: program.period_end },
      { weekStartsOn: 1 }
    ).map((start) => ({ start, end: nextSunday(start) }));

    if (state.programPhases.length > 0) {
      const oldStart = state.programPhases.at(0).phase_start;
      const oldEnd = state.programPhases.at(-1).phase_start;
      const newStart = weeks.at(0).start.getTime();
      const newEnd = weeks.at(-1).start.getTime();
      if (oldStart === newStart && oldEnd === newEnd) return;
    }

    for (const week of weeks) {
      state.programPhases.push({
        id: nanoid(),
        phase_start: week.start.getTime(),
        phase_end: week.end.getTime(),
      });
    }
  },

  [mutationTypes.addProduct](state, { phaseId, productId }) {
    state.programFertilizerProducts.push({
      id: nanoid(),
      program_phase_id: phaseId,
      product_id: productId,
      method_id: 1,
      count_per_ha: null,
      count_per_plant: null,
      price: null,
    });
  },
  [mutationTypes.removeProduct](state, payload) {
    state.programFertilizerProducts = state.programFertilizerProducts.filter(
      (p) => p.id !== payload
    );
  },
  [mutationTypes.setProductMethodId](state, { productId, methodId }) {
    state.programFertilizerProducts.find(
      ({ id }) => id === productId
    ).method_id = methodId;
  },
  [mutationTypes.setProductCountPerHa](state, { productId, count }) {
    const product = state.programFertilizerProducts.find(
      ({ id }) => id === productId
    );
    product.count_per_ha = count;

    if (count === null) {
      product.count_per_plant = null;
    } else {
      const countPerPlant = convertPlansToHa(
        state.program.count_plants_per_ha,
        count
      );
      product.count_per_plant = countPerPlant;
    }
  },
  [mutationTypes.setProductCountPerPlant](state, { productId, count }) {
    const product = state.programFertilizerProducts.find(
      ({ id }) => id === productId
    );
    product.count_per_plant = count;

    if (count === null) {
      product.count_per_ha = null;
    } else {
      const countPerHa = convertHaToPlants(
        state.program.count_plants_per_ha,
        count
      );
      product.count_per_ha = countPerHa;
    }
  },
  [mutationTypes.fillAutoProducts](state) {
    if (state.programPhases.length < 1) return;

    state.programFertilizerProducts = state.programFertilizerProducts.filter(
      (p) => p.is_auto !== 1
    );

    let productsCondition1 = state.defaultProducts
      .filter((p) => p.condition_id === 0)
      .map(cloneDeep);
    let productsCondition2 = state.defaultProducts
      .filter((p) => p.condition_id === 1)
      .map(cloneDeep);
    [...productsCondition1, ...productsCondition2].forEach((p) => {
      delete p.id;
      p.method_id = 1;
      p.count_per_plant = null;
      p.price = null;
      p.is_auto = 1;
      p.productRef = state.productsFertilizer.find(
        ({ id }) => id === p.product_id
      );
    });
    productsCondition1 = productsCondition1.filter(
      (p) => p.productRef?.is_active
    );
    productsCondition2 = productsCondition2.filter(
      (p) => p.productRef?.is_active
    );
    [...productsCondition1, ...productsCondition2].forEach(
      (p) => delete p.productRef
    );

    const pH = state.programAnalyze.find(
      ({ element_id }) => element_id === 13
    )?.quantity;

    const ECRef = state.programAnalyze.find(
      ({ element_id }) => element_id === 14
    );
    let EC = ECRef?.quantity;

    if (EC && ECRef?.units_id === 2) {
      EC *= 0.0001;
    }

    let phasesToInject;

    const startDate = new Date(state.programPhases.at(0).phase_start);
    const programStartsAtSeptemberOrLater = startDate.getMonth() > 7;
    const year = programStartsAtSeptemberOrLater
      ? startDate.getFullYear() + 1
      : startDate.getFullYear();
    const april15ts = new Date(year, 3, 15).getTime();

    if (pH && pH > 7.6) {
      const injectProduct1Times = pH < 8 ? 3 : 5;
      const injectEveryNweek = injectProduct1Times === 5 ? 2 : 3;

      phasesToInject = state.programPhases
        .filter((p) => p.phase_start >= april15ts)
        .filter((_, index) => index % injectEveryNweek === 0);
      if (phasesToInject.length > injectProduct1Times)
        phasesToInject.length = injectProduct1Times;

      for (const phase of phasesToInject) {
        for (const product of productsCondition1) {
          state.programFertilizerProducts.push({
            id: nanoid(),
            program_phase_id: phase.id,
            ...product,
          });
        }
      }
    }

    if (EC && EC > 1.2) {
      let phases;
      if (phasesToInject?.length > 0) {
        const indexes = state.programPhases
          .map((p, i) => (phasesToInject.includes(p) ? i + 1 : null))
          .filter((i) => i !== null && state.programPhases[i]);

        while (indexes.length < 5) {
          indexes.push(indexes.at(-1) + 2);
        }
        phases = indexes.map((i) => state.programPhases[i]).filter(Boolean);
      } else {
        const injectProduct1Times = 5;
        const injectEveryNweek = 2;

        phases = state.programPhases
          .filter((p) => p.phase_start >= april15ts)
          .filter((_, index) => index % injectEveryNweek === 0);
        if (phases.length > injectProduct1Times)
          phases.length = injectProduct1Times;
      }
      if (phases) {
        for (const phase of phases) {
          for (const product of productsCondition2) {
            // state.programFertilizerProducts =
            //   state.programFertilizerProducts.filter(
            //     (p) => p.id !== product.id && p.program_phase_id !== phase.id
            //   );
            state.programFertilizerProducts.push({
              id: nanoid(),
              program_phase_id: phase.id,
              ...product,
            });
          }
        }
      }
    }
  },

  [mutationTypes.saveProgramStart](state) {
    state.isSaving = true;
    state.error = null;
  },
  [mutationTypes.saveProgramFailure](state, payload) {
    state.isSaving = false;
    state.error = payload;
  },
  [mutationTypes.saveProgramSuccess](state, payload) {
    state.isSaving = false;
    state.error = null;
    state.savedRedirectId = payload;
    state.serverData = cloneDeep(
      Object.fromEntries(
        ['program', ...editableKeysProgram].map((key) => [key, state[key]])
      )
    );
  },
};

const actions = {
  async [actionTypes.fetchProgram]({ commit, rootState }, payload) {
    commit(mutationTypes.programFetchingStart);
    try {
      const timestampGeobase = rootState.glossaryGeobase.timestamp;
      const timestampClients = rootState.clientsList.timestampClients;
      const res = await (payload
        ? api.getEditProgramData(
            payload,
            state.timestamp,
            timestampGeobase,
            timestampClients
          )
        : api.getNewProgramData(
            state.timestamp,
            timestampGeobase,
            timestampClients
          ));

      if (res.status === 'ok') {
        if (res.programPhases) {
          res.programPhases.sort((a, b) => a.order - b.order);
        }

        commit(mutationTypes.programFetchingSuccess, deepFreeze(res));
        if (res.geoBase) {
          commit(
            geobaseMutationTypes.getGeobaseSuccess,
            deepFreeze(res.geoBase),
            {
              root: true,
            }
          );
        }

        if (res.clients) {
          commit(clientsMutaionTypes.setClients, res.clients, { root: true });
        }

        if (res.products?.length) {
          commit(plantsCareMutationTypes.setProducts, res, {
            root: true,
          });
        }
      } else {
        throw Error(res.message || 'Unknown error');
      }
    } catch (err) {
      commit(mutationTypes.programFetchingFailure, err);
    }
  },
  async [actionTypes.fetchClient]({ commit }, payload) {
    commit(mutationTypes.clientFetchingStart);
    try {
      const { controller, promise } = api.getClientData(payload);
      commit(mutationTypes.clientFetchingSetAbortController, controller);
      const res = await promise;

      if (res.status === 'ok') {
        if (res.client === null) throw Error('Client not found');
        commit(mutationTypes.clientFetchingSuccess, deepFreeze(res));
      } else {
        throw Error(res.message || 'Unknown error');
      }
    } catch (err) {
      commit(mutationTypes.clientFetchingFailure, err);
    }
  },
  async [actionTypes.fetchCultureData]({ commit }, payload) {
    commit(mutationTypes.cultureDataFetchStart);
    try {
      const { controller, promise } = api.getCultureData(payload);
      commit(mutationTypes.cultureDataFetchSetAbortController, controller);
      const res = await promise;
      if (res.status === 'ok') {
        commit(mutationTypes.cultureDataFetchSuccess, res.cultureElements);
      } else throw Error(res.message || 'Unknown error');
    } catch (err) {
      commit(mutationTypes.cultureDataFetchFailure, err);
    }
  },
  async [actionTypes.saveProgram]({ commit, state }) {
    commit(mutationTypes.saveProgramStart);
    try {
      const payload = Object.fromEntries(
        ['program', ...editableKeysProgram].map((key) => [key, state[key]])
      );
      const res = await api.saveProgramData(payload);
      if (res.status === 'ok') {
        commit(mutationTypes.saveProgramSuccess, res.id);
      } else throw Error(res.message || 'Unknown error');
    } catch (err) {
      commit(mutationTypes.saveProgramFailure, err);
    }
  },
  async [actionTypes.deleteProgram]({ commit, state }) {
    commit(mutationTypes.saveProgramStart);
    try {
      const payload = Object.fromEntries(
        ['program', ...editableKeysProgram].map((key) => [key, state[key]])
      );
      payload.delete = true;
      const res = await api.saveProgramData(payload);
      if (res.status !== 'ok') throw Error(res.message || 'Unknown error');
      commit(mutationTypes.saveProgramSuccess, {});
    } catch (err) {
      commit(mutationTypes.saveProgramFailure, err);
    }
  },
};

function round(value, minimumFractionDigits, maximumFractionDigits) {
  const formattedValue = value.toLocaleString('en', {
    useGrouping: false,
    minimumFractionDigits,
    maximumFractionDigits,
  });
  return Number(formattedValue);
}

function convertPlansToHa(plantsPerHa, gramms) {
  const result = (gramms / plantsPerHa) * 1000;
  return round(result, 0, 2);
}
function convertHaToPlants(plantsPerHa, kg) {
  const result = (plantsPerHa / 1000) * kg;
  return round(result, 0, 2);
}

export {
  mutationTypes,
  actionTypes,
  serverKeysProgram,
  staticData,
  convertPlansToHa,
  convertHaToPlants,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
