var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filter"},[_c('div',{staticClass:"filter-inputszone"},[_c('div',{staticClass:"filterwrap filterwrap--clients"},[_c('div',{staticClass:"filterwrap_line"},[_c('DropdownSelectValue',{attrs:{"type":"dropdown","placeholder":_vm.$t('FilterAnalyzesList:AnalyzeType.Placeholder'),"label":_vm.$t('FilterAnalyzesList:AnalyzeType.Label'),"icon":"","state":"","size":"standart","color":"","dd-max-elements":"5","options":_vm.analyzesTypes ?? []},model:{value:(_vm.filter.analyseTypeId),callback:function ($$v) {_vm.$set(_vm.filter, "analyseTypeId", $$v)},expression:"filter.analyseTypeId"}}),_c('DropdownSelectValue',{attrs:{"type":"dropdown","placeholder":_vm.$t('FilterAnalyzesList:AnalyzeStatus.Placeholder'),"label":_vm.$t('FilterAnalyzesList:AnalyzeStatus.Label'),"icon":"","state":"","size":"long","color":"","dd-max-elements":7,"options":[
            {
              text: _vm.$t('FilterAnalyzesList:AnalyzeStatus.Values:0'), //'Образец получен'
              value: 0,
            },
            {
              text: _vm.$t('FilterAnalyzesList:AnalyzeStatus.Values:1'), //'Доставлен в центральный офис'
              value: 1,
            },
            {
              text: _vm.$t('FilterAnalyzesList:AnalyzeStatus.Values:2'), //'Образец обрабатывается'
              value: 2,
            },
            {
              text: _vm.$t('FilterAnalyzesList:AnalyzeStatus.Values:3'), //'Доставлен в любораторию',
              value: 3,
            },
            {
              text: _vm.$t('FilterAnalyzesList:AnalyzeStatus.Values:4'), //'Анализируется',
              value: 4,
            },
            {
              text: _vm.$t('FilterAnalyzesList:AnalyzeStatus.Values:5'), //'Готов',
              value: 5,
            },
          ]},model:{value:(_vm.filter.analyseStatus),callback:function ($$v) {_vm.$set(_vm.filter, "analyseStatus", $$v)},expression:"filter.analyseStatus"}})],1),_c('div',{staticClass:"filterwrap_line"},[_c('Input',{attrs:{"type":"text","placeholder":_vm.$t('FilterAnalyzesList:ClientName.Placeholder'),"label":_vm.$t('FilterAnalyzesList:ClientName.Label'),"icon":"","state":"","size":"long","color":""},model:{value:(_vm.filter.clientName),callback:function ($$v) {_vm.$set(_vm.filter, "clientName", $$v)},expression:"filter.clientName"}}),_c('Input',{attrs:{"type":"text","placeholder":_vm.$t('FilterAnalyzesList:ClientId.Placeholder'),"label":_vm.$t('FilterAnalyzesList:ClientId.Label'),"icon":"","state":"","size":"Standart","color":""},model:{value:(_vm.filter.clientCode),callback:function ($$v) {_vm.$set(_vm.filter, "clientCode", $$v)},expression:"filter.clientCode"}})],1),_c('div',{staticClass:"filterwrap_line"},[_c('LocationSelect',{ref:"location",attrs:{"geoEntries":_vm.geoMapped,"freeSelect":true},model:{value:(_vm.filter.geo),callback:function ($$v) {_vm.$set(_vm.filter, "geo", $$v)},expression:"filter.geo"}})],1)])]),_c('div',{staticClass:"filter-clearbtnzone"},[_c('Button',{staticClass:"filter_buttonclear",attrs:{"text":"очистить","size":"s-standart","color":_vm.isFilterActive ? 'accentlines' : 'graylines',"icon":"backspace","is-loading":false,"loading-left":true},on:{"click":_vm.onClearFilter}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }