import { render, staticRenderFns } from "./GlossaryTypeStimulations.vue?vue&type=template&id=0e04ba96&scoped=true&"
import script from "./GlossaryTypeStimulations.vue?vue&type=script&lang=js&"
export * from "./GlossaryTypeStimulations.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e04ba96",
  null
  
)

export default component.exports