<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: $t('AnalyzesList:Title') }]" />

      <div class="rightzone_top-buttonswrap">
        <Button
          v-if="isShowAddButton"
          :text="$t('AnalyzesList:ButtonAdd')"
          size="standart"
          color="accent"
          icon="plus"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'NewAnalyse' })"
        />
      </div>
    </div>

    <div class="rightzone_block">
      <FilterAnalyzesList v-model="filter" />
    </div>

    <div class="rightzone_table">
      <Table
        class="table--analyzeslist"
        :headers="tableHeaders"
        :data-arr="filteredDataArr"
        :table-loading="isTableLoading"
        :loading-count-items="8"
        @editClick="onEditClick"
        @linkToClick="onLinkToClick"
      />
    </div>
  </article>
</template>

<script>
import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';
import routeNames from '@/router/routeNames';
import FilterAnalyzesList from '@/components/filtres/FilterAnalyzesList';
import Table from '@/components/tables/Table';
import * as dateHelper from '@/helpers/dateHelper';
import * as analyzesHelper from '@/helpers/analyzesHelper';
import { mutationTypes } from '@/store/modules/filtersState';
import { mapState } from 'vuex';

export default {
  name: 'AnalyzesList',
  components: {
    FilterAnalyzesList,
    Breadcrumps,
    Button,
    Table,
  },
  data() {
    return {
      filter: {
        isActive: false,
        geo: null,
        analyseTypeId: null,
        analyseStatus: null,
        clientName: '',
        clientCode: '',
      },
      filteredDataArr: [],
    };
  },
  methods: {
    onEditClick(typeId) {
      this.$router.push({
        name: routeNames.editAnalyse,
        params: { id: typeId },
      });
    },
    onLinkToClick(clientId) {
      let routeData = this.$router.resolve({
        name: routeNames.editClient,
        params: { id: clientId },
      });
      window.open(routeData.href, '_blank');
    },
    setFilteredDataArr() {
      if (this.filter.isActive) {
        this.filteredDataArr = this.dataArr.filter((analyseData) => {
          if (this.filter.clientCode !== '') {
            if (
              !`${analyseData[3].text}`
                .toLowerCase()
                .includes(`${this.filter.clientCode}`.toLowerCase())
            ) {
              return false;
            }
          }
          if (this.filter.clientName !== '') {
            if (
              !`${analyseData[2].text}`
                .toLowerCase()
                .includes(`${this.filter.clientName}`.toLowerCase())
            ) {
              return false;
            }
          }
          if (this.filter.analyseTypeId !== null) {
            if (analyseData[1].id !== this.filter.analyseTypeId) {
              return false;
            }
          }
          if (this.filter.analyseStatus !== null) {
            if (analyseData[4].id !== this.filter.analyseStatus) {
              return false;
            }
          }
          if (this.filter.geo !== null) {
            if (
              !this.$store.getters.getClientsMap
                .get(analyseData[3].id)
                ?.geoChain?.includes(this.filter.geo)
            ) {
              return false;
            }
          }
          return true;
        });
      } else {
        this.filteredDataArr = this.dataArr;
      }
    },
  },
  computed: {
    /*filter: {
      get() {
        return this.$store.state.filtersState.filterAnalyzes;
      },
      set(payload) {
        this.$store.commit(mutationTypes.setFilterAnalyzes, payload);
      },
    },*/
    ...mapState({
      canEdit: (state) => state.auth.currentUserRole?.claim_analyse_edit === 1,
      canSeeClients: (state) =>
        state.auth.currentUserRole?.claim_clients_see === 1,
    }),
    tableHeaders() {
      const headers = [
        {
          text: this.$t('AnalyzesList:TableHeaders:0'), //'Дата заявки',
          class: 'tbl-celldate',
          width: '8%',
          type: 'text',
        },
        {
          text: this.$t('AnalyzesList:TableHeaders:1'), //'Тип анализа',
          class: 'tbl-cellanalyzetype',
          width: '14%',
          type: 'text',
        },
        {
          text: this.$t('AnalyzesList:TableHeaders:2'), //'Имя, фамилия',
          class: 'tbl-cellname',
          width: '42%',
          type: 'text',
        },
        {
          text: this.$t('AnalyzesList:TableHeaders:3'), //'ID клиента',
          class: 'tbl-cellid',
          width: '18%',
          type: 'text',
        },
        {
          text: this.$t('AnalyzesList:TableHeaders:4'), //'Статус',
          class: 'col-tbl-cellanalyzestatus',
          width: '28%',
          type: 'text',
        },
      ];

      if (this.canSeeClients) {
        headers.push({
          text: '',
          class: 'col-edit',
          width: '',
          type: 'linkto',
          icon: 'linkto',
        });
      }

      headers.push({
        text: '',
        class: 'col-edit',
        width: '',
        type: 'edit',
        icon: this.canEdit ? 'edit' : 'passwordshow',
      });

      return headers;
    },
    isTableLoading() {
      return this.$store.state.analyzes.isAnalyzesLoading;
    },
    dataArr() {
      let arr = [];

      if (this.$store.state.analyzes.analyzes) {
        this.$store.state.analyzes.analyzes.forEach((analyse) => {
          let tr = [];
          let client = this.$store.getters.getClientsMap.get(analyse.client_id);

          tr.push({ text: dateHelper.formatDate(analyse.date_create) ?? '' });
          tr.push({
            text:
              analyse.type_id !== null
                ? this.$store.state.analyzes.analyzesTypesMap.get(
                    analyse.type_id
                  )?.name
                : '',
            id: analyse.type_id,
          });
          tr.push({ text: client?.name ?? '' });
          tr.push({ text: client?.code ?? '', id: client?.id });
          tr.push({
            text: analyse.date_create
              ? analyzesHelper.getAnalyseStatus(analyse)
              : '',
            id: analyzesHelper.getAnalyseStatusId(analyse),
          });
          if (this.canSeeClients) tr.push({ text: analyse.client_id ?? '' });
          tr.push({ text: analyse.id ?? '' });
          arr.push(tr);
        });
      }

      return arr;
    },
    isShowAddButton() {
      return this.canEdit;
    },
  },
  watch: {
    dataArr() {
      this.setFilteredDataArr();
    },
    filter: {
      handler(val) {
        this.$store.commit(mutationTypes.setFilterAnalyzes, { ...val });
        this.setFilteredDataArr();
      },
      deep: true,
    },
  },
  created() {
    for (let filterKey in this.filter) {
      this.filter[filterKey] =
        this.$store.state.filtersState.filterAnalyzes[filterKey];
    }
    this.setFilteredDataArr();
  },
};
</script>
