<!--Translated-->
<template>
  <div
    :class="{
      'inptwrap--type-text': inputType.isTypeText,
      'inptwrap--type-password': inputType.isTypePassword,
      'inptwrap--type-textarea': inputType.isTypeTextArea,
      'inptwrap--type-textarea--height2x': inputType.isTypeTextArea2x,
      'inptwrap--type-text': inputType.isTypeNumber,
      'inptwrap--type-dropdown':
        inputType.isTypeDropDown || inputType.isTypeDropDownPlus,
      'inptwrap--type-dropdown--show': showDD,
      ['inptwrap--type-dropdown--height' + ddMaxElements]: ddMaxElements,
      'inptwrap--nolable': !isSetLabelText,
      'inptwrap--iconsleft': iconC !== '' || ddHasIcon,
      'inptwrap--complete': isInputFocused || isInputCompleted,
      'inptwrap--error': isInputError,
      'inptwrap--locked': isInputDisabled && !isClassForInputDisabled2,

      'inptwrap--color_accenticons': isColorAccent,
      'inptwrap--width_long': isSizeLong,
      'inptwrap--width_long3x': isSizeLong3x,
      'inptwrap--width_long4x': isSizeLong4x,

      'inptwrap--width_max': isSizeMax,
      'inptwrap--width_nano': isSizeNano,
      'inptwrap--width_mini': isSizeMini,
      'inptwrap--width_micro': isSizeMicro,
      'inptwrap--color_accentlightlines': isColorAccentLightLines,
      'inptwrap--color_graylines': isColorGraylines,
      'inptwrap--color_graylightlines': isColorGrayLightlines,
      'inptwrap--type-fordate': isForDate,
      'inptwrap--onlyshow': isClassForInputDisabled2,
      'inptwrap--disable_completed_class': disableCompletedClass,
      [this.inptwrapClass]: true,
    }"
    class="inptwrap"
  >
    <label v-if="labelText !== '-'" class="lable">
      {{ labelText }}
    </label>
    <!--<div v-html="require('!!raw-loader!@/assets/icons/svg-contactaddress.svg').default"></div>-->

    <div class="inptline" v-click-outside="onClickOutSide">
      <div
        v-if="iconC !== ''"
        class="inptline_iconzone inptline_iconzone--left"
      >
        <div v-svg="{ name: iconC }" :key="iconC" />
      </div>

      <div class="inptline_inputzone">
        <input
          v-if="inputType.isTypeText"
          class="inpt inpt-text"
          type="text"
          :placeholder="placeholder"
          :disabled="isInputDisabled"
          @focusin="isFocused = true"
          @focusout="isFocused = false"
          @input="onInput"
          @click="$emit('click')"
          :value="value"
          :autocomplete="autocomplete"
          :name="name"
          :readonly="tmpReadOnly"
          ref="input"
          @keypress="isLetter($event)"
        />
        <input
          v-if="inputType.isTypePassword"
          class="inpt inpt-password"
          :type="isShowPassword ? 'text' : 'password'"
          :placeholder="placeholder"
          :disabled="isInputDisabled"
          @focusin="isFocused = true"
          @focusout="isFocused = false"
          @input="onInput"
          @click="$emit('click')"
          :autocomplete="autocomplete"
          :name="name"
          :value="value"
          :readonly="tmpReadOnly"
        />
        <input
          v-if="inputType.isTypeNumber"
          class="inpt inpt-number"
          type="text"
          :placeholder="placeholder"
          :disabled="isInputDisabled"
          @focusin="
            isFocused = true;
            $emit('focus');
          "
          @focusout="isFocused = false"
          @input="onInput"
          @click="$emit('click')"
          @blur="$emit('blur')"
          :autocomplete="autocomplete"
          :name="name"
          :readonly="tmpReadOnly"
          :value="value"
          @keypress="typeNumberFilter($event)"
        />
        <textarea
          v-if="inputType.isTypeTextArea"
          class="inpt inpt-textarea"
          :placeholder="placeholder"
          :disabled="isInputDisabled"
          @focusin="isFocused = true"
          @focusout="isFocused = false"
          @input="onInput"
          @click="$emit('click')"
          :autocomplete="autocomplete"
          :name="name"
          :readonly="tmpReadOnly"
          :value="value"
        >
        </textarea>
        <input
          v-if="inputType.isTypeDropDown"
          class="inpt inpt-text"
          type="text"
          :placeholder="placeholder"
          :disabled="isInputDisabled"
          @focusin="isFocused = true"
          @focusout="isFocused = false"
          @input="onInput"
          @click="showDD = !showDD"
          :value="inputValue"
          :autocomplete="autocomplete"
          :name="name"
          readonly
        />
        <input
          v-if="inputType.isTypeDropDownPlus"
          class="inpt inpt-text"
          type="text"
          :placeholder="placeholder"
          :disabled="isInputDisabled"
          @focusin="isFocused = true"
          @focusout="isFocused = false"
          @input="onDDInput"
          @click="onDDInputClick"
          :value="inputValue"
          :autocomplete="autocomplete"
          :name="name"
          :readonly="ddPlusReadOnly"
          ref="input"
        />
      </div>

      <div
        v-if="
          inputType.isTypeDropDown ||
          inputType.isTypeDropDownPlus ||
          inputType.isTypePassword
        "
        class="inptline_iconzone inptline_iconzone--right"
        @click="
          showPassword = !showPassword;
          $emit('showPass', showPassword);
        "
        :style="{
          pointerEvents:
            inputType.isTypeDropDown || inputType.isTypeDropDownPlus
              ? 'none'
              : 'auto',
        }"
      >
        <div
          v-if="inputType.isTypePassword && !isShowPassword"
          v-svg="{ name: 'passwordshow' }"
          :key="'passwordshow'"
        />
        <!--Тут нужно вторую иконку название написать-->
        <div
          v-if="inputType.isTypePassword && isShowPassword"
          v-svg="{ name: 'passwordhide' }"
          :key="'passwordhide'"
        />

        <!-- <div
          v-if="customLeftButtonIcon"
          v-svg="{ name: customLeftButtonIcon }"
          :key="customLeftButtonIcon"
          @click="$emit('customLeftButtonClick')"
        />-->

        <div
          v-if="inputType.isTypeDropDown || inputType.isTypeDropDownPlus"
          v-svg="{ name: showDD ? 'arrowup' : 'arrowdown' }"
          :key="showDD"
        />
      </div>
      <div
        v-if="customLeftButtonIcon"
        class="inptline_iconzone inptline_iconzone--right"
        @click="$emit('customLeftButtonClick')"
        style="display: flex !important; cursor: pointer"
      >
        <div
          v-svg="{ name: customLeftButtonIcon }"
          :key="customLeftButtonIcon"
          :class="customLeftButtonClass"
        />
      </div>
    </div>

    <div class="inptdroplist" v-if="showDD">
      <div class="inptdroplist_wrap">
        <div
          v-for="(item, index) in ddItemsCut"
          :key="index"
          class="inptdroplist_line"
          :class="
            item.class ? item.class : ddUseDefaultIcon ? 'default-icon' : ''
          "
          @click="onDDItemClick(index)"
        >
          <!--<svg class="inptdroplist_line-icon inptdroplist_line-icon&#45;&#45;left" width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_412_662)">
                  <path fill-rule="evenodd"
                        class="svgfill"
                        clip-rule="evenodd"
                        d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19.6 8.25L13.06 12.34C12.41 12.75 11.59 12.75 10.94 12.34L4.4 8.25C4.15 8.09 4 7.82 4 7.53C4 6.86 4.73 6.46 5.3 6.81L12 11L18.7 6.81C19.27 6.46 20 6.86 20 7.53C20 7.82 19.85 8.09 19.6 8.25Z"
                        fill="#9F9F9F"/>
                </g>
                <defs>
                  <clipPath id="clip0_412_662">
                    <rect width="20" height="16" fill="white" transform="translate(2 4)"/>
                  </clipPath>
                </defs>
            </svg>-->
          <div
            v-if="item.icon || ddUseDefaultIcon"
            v-svg="{ name: item.icon || ddDefaultIconName }"
            class="inptdroplist_line-icon inptdroplist_line-icon--left"
          ></div>
          <template v-if="ddMultiMode && item.value !== null">
            <span class="inptdroplist_line-text">{{ item.text1 }}</span>
            <span class="inptdroplist_line-text">{{ item.text2 }}</span>
            <span class="inptdroplist_line-text">{{ item.text3 }}</span>
          </template>
          <span
            v-if="!ddMultiMode || item.value === null"
            class="inptdroplist_line-text"
          >
            {{ item.text }}
            <span
              v-if="item.exists"
              class="inptdroplist_line-text"
              style="margin-left: -15px; font-size: 0.9em"
            >
              {{ $t('Input:AllreadyHasItemText') }}
            </span>
          </span>

          <!--<svg class="inptdroplist_line-icon inptdroplist_line-icon&#45;&#45;right" width="24" height="24" viewBox="0 0 24 24" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_412_662)">
              <path fill-rule="evenodd"
                    class="svgfill"
                    clip-rule="evenodd"
                    d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19.6 8.25L13.06 12.34C12.41 12.75 11.59 12.75 10.94 12.34L4.4 8.25C4.15 8.09 4 7.82 4 7.53C4 6.86 4.73 6.46 5.3 6.81L12 11L18.7 6.81C19.27 6.46 20 6.86 20 7.53C20 7.82 19.85 8.09 19.6 8.25Z"
                    fill="#9F9F9F"/>
            </g>
            <defs>
              <clipPath id="clip0_412_662">
                <rect width="20" height="16" fill="white" transform="translate(2 4)"/>
              </clipPath>
            </defs>
          </svg>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppInput',
  components: {},
  props: {
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    state: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'standard',
    },
    color: {
      type: String,
      default: '',
    },
    value: {
      default: '',
    },
    ddItems: {
      default() {
        return [];
      },
    },
    ddItemsCutNum: {
      default: 50,
    },
    useDdItemsCut: {
      default: false,
    },
    ddSelectedIndex: {
      type: Number,
      default: 0,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    name: {
      type: String,
      default: '',
    },
    maxLength: {
      default: 0,
    },
    max: {
      type: Number,
    },
    ddMaxElements: {},
    autofocus: {
      default: false,
    },
    ddDisableClickOutside: { default: false },
    showPass: { default: false },
    ddPlusResetWatcher: { default: 0 },
    onlyEnglishAndNumber: { default: false },
    ddSelectModeIgnoreExists: { default: false },
    customLeftButtonIcon: { default: '' },
    customLeftButtonClass: { default: '' },
    ddUseDefaultIcon: { default: false },
    ddDefaultIconName: {
      default: 'default3',
    },
    ddMultiMode: { default: false },
    disableCompletedClass: { default: false },
  },
  data() {
    return {
      isForDate: false,
      isFocused: false,
      showPassword: this.showPass,
      showDD: false,
      inputValue: '',
      tmpReadOnly: this.autocomplete.includes('new-'),
      iconC: this.icon,
      inptwrapClass: '',
      ddPlusReadOnly: false,
    };
  },
  methods: {
    onInput(event) {
      if (this.maxLength > 0) {
        event.target.value = event.target.value.slice(0, this.maxLength);
      }
      if (this.inputType.isTypeNumber) {
        if (this.max && Number(event.target.value) > this.max) {
          event.target.value = event.target.value.slice(0, 1);
        }
        if (event.target.value.startsWith('00')) {
          event.target.value = event.target.value.replace(/0{2,}/, '0');
        }
        if (event.target.value === '.' || event.target.value === ',') {
          event.target.value = '0.';
        }
      }
      this.$emit('input', event.target.value);
    },
    onDDInput(event) {
      this.inputValue = event.target.value;
      this.$emit('input', event.target.value);
      if (this.inputType.isTypeDropDownPlus) {
        this.showDD = true;
      }
    },
    onDDItemClick(index, ignoreEvent) {
      if (this.ddSelectModeIgnoreExists && this.ddItems[index].exists) {
        return;
      }
      if (this.ddItems[index].value !== null) {
        this.inputValue = this.ddItems[index].text;
        if (this.ddItems[index]?.icon) {
          this.iconC = this.ddItems[index]?.icon;
        } else {
          this.iconC = '';
          if (this.ddUseDefaultIcon) {
            this.iconC = this.ddDefaultIconName;
          }
        }
        if (this.ddItems[index]?.class) {
          this.inptwrapClass = this.ddItems[index]?.class;
        } else {
          this.inptwrapClass = '';
          if (this.ddUseDefaultIcon) {
            this.inptwrapClass = 'default-icon';
          }
        }
      } else {
        this.inputValue = '';
        this.iconC = '';
        this.inptwrapClass = '';
        if (this.ddUseDefaultIcon) {
          this.iconC = this.ddDefaultIconName;
          this.inptwrapClass = 'default-icon';
        }
      }
      if (this.ddMultiMode) {
        if (this.icon) {
          this.iconC = this.icon;
        }
      }

      if (!ignoreEvent) {
        this.showDD = false;
        this.$emit('selected', { index: index, item: this.ddItems[index] });
      }
    },
    onClickOutSide() {
      if (!this.ddDisableClickOutside) {
        this.showDD = false;
      }
    },
    onDDInputClick() {
      this.showDD = true;
    },
    isLetter(e) {
      if (!this.onlyEnglishAndNumber) {
        return true;
      }
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z1-9._]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    typeNumberFilter(e) {
      // let char = String.fromCharCode(e.keyCode); // Get the character

      const NUMBER_DOT_COMMA = /^[\d.]*$/;
      let fieldHasCommaOrDot = false;
      if (this.value !== null) {
        fieldHasCommaOrDot =
          `${this.value}`.includes('.') || `${this.value}`.includes(',');
      }
      const keyIsCommaOrDot = e.key === '.' || e.key === ',';

      if (e.key === '.') {
        this.$forceUpdate();
      }

      if (
        !NUMBER_DOT_COMMA.test(e.key) ||
        (keyIsCommaOrDot && fieldHasCommaOrDot)
      ) {
        e.preventDefault();
        return false;
      }
      /* if (/^[1-9.\_]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text*/
    },
  },
  computed: {
    inputType() {
      return {
        isTypeText: this.type === 'text',
        isTypePassword: this.type === 'password',
        isTypeNumber: this.type === 'number',
        isTypeTextArea: this.type === 'textarea' || this.type === 'textarea2x',
        isTypeTextArea2x: this.type === 'textarea2x',
        isTypeDropDown: this.type === 'dropdown',
        isTypeDropDownPlus: this.type === 'dropdownPlus',
      };
    },
    labelText() {
      return this.label;
    },
    isSetLabelText() {
      if (this.label === '-') {
        return false;
      }
      return this.label;
    },
    isInputFocused() {
      if (this.isInputDisabled) return false;
      return this.isFocused;
    },
    isInputCompleted() {
      if (this.disableCompletedClass) return false;
      if (this.isInputDisabled) return false;
      if (this.inputType.isTypeDropDown && this.value?.value === null)
        return false;
      if (this.value !== '' && this.value !== null) return true;
      if (this.inputValue !== '' && this.inputValue !== null) return true;
      return this.state === 'completed';
    },
    isInputError() {
      if (this.isInputDisabled) return false;
      return this.state === 'error';
    },
    isInputDisabled() {
      return (
        this.state === 'disabled' ||
        this.state === 'disabled2' ||
        this.state === 'locked'
      );
    },
    isClassForInputDisabled2() {
      return this.state === 'disabled2' || this.state === 'locked';
    },

    isSizeLong() {
      return this.size === 'long';
    },
    isSizeLong3x() {
      return this.size === 'long3x';
    },
    isSizeLong4x() {
      return this.size === 'long4x';
    },
    isSizeMax() {
      return this.size === 'max';
    },
    isSizeStandart() {
      return this.size === 'standart';
    },
    isSizeMini() {
      return this.size === 'mini';
    },
    isSizeMicro() {
      return this.size === 'micro';
    },
    isSizeNano() {
      return this.size === 'nano';
    },
    isColorAccent() {
      if (this.isInputDisabled) return false;
      return this.color === 'accent';
    },
    isColorGraylines() {
      if (this.isInputDisabled) return false;
      return this.color === 'grayLines';
    },
    isColorGrayLightlines() {
      if (this.isInputDisabled) return false;
      return this.color === '' || this.color === 'grayLightLines';
    },
    isColorAccentLightLines() {
      if (this.isInputDisabled) return false;
      return this.color === 'accentLightLines';
    },
    isShowPassword() {
      return this.showPassword;
    },
    ddHasIcon() {
      if (this.inputType.isTypeDropDown) {
        if (this.ddItems) {
          for (let i = 0; i < this.ddItems.length; i++) {
            if (this.ddItems[i]?.icon) {
              return true;
            }
          }
        } else {
          return false;
        }
      }
      return false;
    },
    ddItemsCut() {
      if (!this.useDdItemsCut) {
        return this.ddItems;
      }
      if (this.ddItems && this.ddItems.length > this.ddItemsCutNum) {
        return this.ddItems.slice(0, 50);
      } else return this.ddItems;
    },
  },
  watch: {
    ddSelectedIndex() {
      this.onDDItemClick(this.ddSelectedIndex, true);
    },
    showDD() {
      if (this.showDD) {
        this.$emit('ddOpen', {});
      } else {
        this.$emit('ddClose', {});
      }
    },
    value() {
      if (this.inputType.isTypeDropDownPlus) {
        this.inputValue = this.value;
      } else if (this.inputType.isTypeDropDown) {
        this.inputValue = this.value?.text || '';
      }
    },
    showPass() {
      this.showPassword = this.showPass;
    },
    ddPlusResetWatcher() {
      if (this.inputType.isTypeDropDownPlus) {
        this.onDDItemClick(0);
      }
    },
  },
  created() {
    if (
      this.ddItems &&
      this.ddItems[this.ddSelectedIndex] &&
      this.ddItems[this.ddSelectedIndex].value !== null
    ) {
      this.onDDItemClick(this.ddSelectedIndex);
    }
    if (this.tmpReadOnly) {
      setTimeout(() => {
        this.tmpReadOnly = false;
      }, 350);
    }
    if (this.autofocus) {
      this.$nextTick(() => {
        try {
          this.$refs.input.focus();
        } catch (e) {
          console.log(e);
        }
      });
    }
  },
};
</script>
