<!--Translated-->
<template>
  <tbody :class="`tbl-color--${category.class}`">
    <tr
      class="row"
      :class="{ 'tbl_row--active': getSelectedState(product) }"
      v-for="(product, index) in productsFiltered"
      :key="product.id"
    >
      <td
        v-if="index === 0"
        class="tbl-cellproblem"
        :rowspan="productsFiltered.length"
      >
        <div class="tbl_info">
          <div class="tbl_info-wrap">
            <div v-svg="{ name: category.icon }"></div>
            <HoverTooltip
              :images="images"
              :header="pest.name"
              :text="pest.description"
              :link="pest.link"
              :noSubstance="true"
            />
          </div>
          <div class="tbl_info-celltext">{{ pest.name }}</div>
        </div>
      </td>
      <td class="tbl-cellproduct">
        <div class="tbl_info">
          <div class="tbl_info-wrap">
            <div v-if="!phase.is_temporary" class="tbl_checkboxproduct">
              <CheckBox
                color-checked="accentfill"
                color-un-checked="gray"
                @input="onChange(product, $event)"
                :value="getSelectedState(product)"
              />
            </div>
            <div v-else v-svg="{ name: 'info' }"></div>
            <UserHoverTooltipProductDefence
              :product="product"
              :noSubstance="true"
            />
          </div>
          <div
            class="tbl_info-celltext"
            @click="onChange(product, true)"
            :style="calcProductNameStyle(product)"
          >
            {{ product.name }}
          </div>
        </div>
      </td>
      <td class="tbl-cellprice" v-if="!phase.is_temporary">
        {{ pestsProductsAsMap[product.id] }}
      </td>
      <td v-else class="tbl-cellinpt">
        <InputNumber
          class="inputintable"
          label="-"
          :value="pestsProductsAsMap[product.id]"
          @input="onInput(product, $event)"
          :state="isSaving ? 'disabled' : ''"
        />
      </td>
      <td class="tbl-cellprice">
        {{
          product.picking
            ? (
                (product.price / product.picking) *
                pestsProductsAsMap[product.id]
              ).toFixed(2)
            : '0.00'
        }}
      </td>
      <td
        v-if="index === 0 && phase.is_temporary"
        :rowspan="productsFiltered.length"
        class="col-delete"
        @click="$emit('deletePest', pest.id)"
      >
        <div v-svg="{ name: 'delete' }"></div>
      </td>
    </tr>

    <tr v-if="productsFiltered.length === 0" class="row tbl_row--active">
      <td class="tbl-cellproblem" rowspan="1">
        <div class="tbl_info">
          <div class="tbl_info-wrap">
            <div v-svg="{ name: category.icon }"></div>
            <HoverTooltip
              :images="images"
              :header="pest.name"
              :text="pest.description"
              :link="pest.link"
              :noSubstance="true"
            />
          </div>
          <div class="tbl_info-celltext">{{ pest.name }}</div>
        </div>
      </td>
      <td class="tbl-cellproduct" colspan="3" style="text-align: center">
        {{ $t('ProgramRowPest:NoData') }}
      </td>
    </tr>

    <tr class="tablelinedecor">
      <td :colspan="phase.is_temporary ? 5 : 4"></td>
    </tr>
  </tbody>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { mutationTypes } from '@/store/modules/clientsArea/programs';
import InputNumber from '@/components/views/wrappers/InputNumberWithCast';
import CheckBox from '@/components/CheckBox';
import HoverTooltip from '@/pages/programs/components/HoverTooltip';
import { mapPestCategoryToProductCategory } from '@/pages/programs/components/mapCategoryToPest';
import UserHoverTooltipProductDefence from '@/pages/userproject/userPrograms/userDefenceComponents/HoverTooltipProduct';

export default {
  name: 'UserProgramRowPest',
  components: {
    UserHoverTooltipProductDefence,
    InputNumber,
    HoverTooltip,
    CheckBox,
  },
  props: {
    phase: { type: Object, required: true },
    pest: { type: Object, required: true },
  },
  computed: {
    ...mapGetters(['plantsCareProductsAsMap', 'udPestsProductsAsMap']),
    ...mapState({
      isSaving: (state) => state.userDefence.isSaving,
      productsPestsSubcategories: (state) =>
        state.products.productsPestsSubcategories,
      category(state) {
        return state.products.productsPestsSubcategories.find(
          ({ id }) =>
            id === mapPestCategoryToProductCategory(this.pest.category_id)
        );
      },
      products(state) {
        return state.userDefence.productsPests
          .filter(({ pest_id }) => pest_id === this.pest.id)
          .map(({ product_id }) => this.plantsCareProductsAsMap[product_id])
          .filter(Boolean)
          .filter(
            (product) =>
              product.is_active ||
              state.userDefence.programPestsProducts.some(
                (p) =>
                  p.product_id === product.id &&
                  p.quantity &&
                  (p.is_selected ||
                    state.userDefence.serverData.programPestsProducts.find(
                      ({ id }) => id === p.id
                    )?.is_selected)
              )
          );
      },
      programPestsProducts: (state) => state.userDefence.programPestsProducts,
      images(state) {
        const cultureId = state.userDefence.program.culture_id;
        const hasImagesBindedToCulture = state.userDefence.images.some(
          (img) => img.meta_id === cultureId
        );

        return state.userDefence.images
          .filter(
            (img) =>
              img.object_id === this.pest.id &&
              img.type === 0 &&
              (hasImagesBindedToCulture ? cultureId === img.meta_id : true)
          )
          .sort((a, b) => a.meta_id - b.meta_id);
      },
    }),
    pestsProductsAsMap() {
      const getValueById = (id) =>
        this.udPestsProductsAsMap[
          `phase_${this.phase.id}_pest_${this.pest.id}_product_${id}`
        ]?.quantity ?? '';
      return Object.fromEntries(
        this.productsFiltered.map((p) => [p.id, getValueById(p.id)])
      );
    },
    productsFiltered() {
      const products = this.programPestsProducts
        .filter(
          (p) =>
            p.pest_id === this.pest.id && p.program_phase_id === this.phase.id
        )
        .map((p) => p.product_id);
      return this.products.filter((p) => products.includes(p.id));
    },
  },
  methods: {
    getSelectedState(product) {
      return this.programPestsProducts.find(
        (p) =>
          p.pest_id === this.pest.id &&
          p.product_id === product.id &&
          p.program_phase_id === this.phase.id
      ).is_selected;
    },
    calcProductNameStyle(product) {
      const style = {};
      const selected = this.getSelectedState(product);
      style.cursor = this.phase.is_temporary ? 'auto' : 'pointer';
      if (!selected) style.color = '#a8a9ad';
      return style;
    },
    onInput(product, value) {
      this.$store.commit(mutationTypes.setPestProductQuantity, {
        program_phase_id: this.phase.id,
        pest_id: this.pest.id,
        product_id: product.id,
        quantity: value,
      });
    },
    onChange(product) {
      this.$store.commit(mutationTypes.setSelectedProduct, {
        product_id: product.id,
        object_id: this.pest.id,
        program_phase_id: this.phase.id,
      });
    },
  },
};
</script>
