<!--Translated-->
<template>
  <article
    class="systempage systempage--userfirstvisit pagebcg pagebcg--06 pagebcg--anim"
  >
    <div class="systempage_top">
      <div class="sidemenu-logo">
        <!--        <img alt="logo" src="@/assets/sidemenu/sidemenu_logo.svg" />-->
      </div>

      <div class="rightzone_top-buttonswrap">
        <Button
          :text="$t('UserStartscreen:Button:Authorization.Text')"
          size="standart"
          color="accentlines"
          icon="key"
          @click="
            showModalSelectCulture = false;
            showModal = true;
            showPic6 = true;
            showFormReg = false;
            showFormLogin = true;
          "
        />
      </div>
    </div>

    <div class="systempage_center">
      <div
        class="firstvisitcomposition"
        :class="{ 'easter-fruitcomposition': animEasterFruitComposition }"
      >
        <div
          class="firstvisitcomposition-logo"
          @click="animEasterFruitComposition = !animEasterFruitComposition"
        >
          <img alt="logo" src="@/assets/sysmeta/android-chrome-512x512.png" />
        </div>

        <div class="fruitscomposition">
          <div
            class="fruitscomposition-item"
            style="--i: 0"
            @click="
              showModal = true;
              showFade1 = true;
              showPic1 = true;
              showFormReg = true;
              showFormLogin = false;
            "
            @mouseover="isHovering = true"
            @mouseout="isHovering = false"
            :class="{
              animstoponhoverfruititem: stopAnimation,
            }"
          >
            <div class="fruitscomposition-item_antispinwrap">
              <div class="fruitscomposition-item_innerspinner">
                <img
                  alt="logo"
                  src="@/assets/userpanel/img/userculturespreview/apple-min.png"
                />
                <p>{{ $t('UserStartscreen:Fruits:Apple') }}</p>
                <div class="vsvg" v-svg="{ name: 'usercompositionmask' }"></div>
                <transition name="animModalAuthFade" appear>
                  <div
                    class="fruitscomposition-item_fade"
                    role="dialog"
                    v-if="showFade1"
                  ></div>
                </transition>
              </div>
            </div>
          </div>
          <div
            class="fruitscomposition-item"
            style="--i: 1"
            @click="
              showModal = true;
              showFade2 = true;
              showPic2 = true;
              showFormReg = true;
              showFormLogin = false;
            "
            @mouseover="isHovering = true"
            @mouseout="isHovering = false"
            :class="{
              animstoponhoverfruititem: stopAnimation,
            }"
          >
            <div class="fruitscomposition-item_antispinwrap">
              <div class="fruitscomposition-item_innerspinner">
                <img
                  alt="logo"
                  src="@/assets/userpanel/img/userculturespreview/walnut-min.png"
                />
                <p>{{ $t('UserStartscreen:Fruits:Nut') }}</p>
                <div class="vsvg" v-svg="{ name: 'usercompositionmask' }"></div>
                <transition name="animModalAuthFade" appear>
                  <div
                    class="fruitscomposition-item_fade"
                    role="dialog"
                    v-if="showFade2"
                  ></div>
                </transition>
              </div>
            </div>
          </div>
          <div
            class="fruitscomposition-item"
            style="--i: 2"
            @click="
              showModal = true;
              showFade3 = true;
              showPic3 = true;
              showFormReg = true;
              showFormLogin = false;
            "
            @mouseover="isHovering = true"
            @mouseout="isHovering = false"
            :class="{
              animstoponhoverfruititem: stopAnimation,
            }"
          >
            <div class="fruitscomposition-item_antispinwrap">
              <div class="fruitscomposition-item_innerspinner">
                <img
                  alt="logo"
                  src="@/assets/userpanel/img/userculturespreview/blueberry-min.png"
                />
                <p>{{ $t('UserStartscreen:Fruits:Blueberry') }}</p>
                <div class="vsvg" v-svg="{ name: 'usercompositionmask' }"></div>
                <transition name="animModalAuthFade" appear>
                  <div
                    class="fruitscomposition-item_fade"
                    role="dialog"
                    v-if="showFade3"
                  ></div>
                </transition>
              </div>
            </div>
          </div>
          <div
            class="fruitscomposition-item"
            style="--i: 3"
            @click="
              showModal = true;
              showFade4 = true;
              showPic4 = true;
              showFormReg = true;
              showFormLogin = false;
            "
            @mouseover="isHovering = true"
            @mouseout="isHovering = false"
            :class="{
              animstoponhoverfruititem: stopAnimation,
            }"
          >
            <div class="fruitscomposition-item_antispinwrap">
              <div class="fruitscomposition-item_innerspinner">
                <img
                  alt="logo"
                  src="@/assets/userpanel/img/userculturespreview/grape-min.png"
                />
                <p>{{ $t('UserStartscreen:Fruits:Grape') }}</p>
                <div class="vsvg" v-svg="{ name: 'usercompositionmask' }"></div>
                <transition name="animModalAuthFade" appear>
                  <div
                    class="fruitscomposition-item_fade"
                    role="dialog"
                    v-if="showFade4"
                  ></div>
                </transition>
              </div>
            </div>
          </div>
          <div
            class="fruitscomposition-item"
            style="--i: 4"
            @click="
              showModal = true;
              showFade5 = true;
              showPic5 = true;
              showFormReg = true;
              showFormLogin = false;
            "
            @mouseover="isHovering = true"
            @mouseout="isHovering = false"
            :class="{
              animstoponhoverfruititem: stopAnimation,
            }"
          >
            <div class="fruitscomposition-item_antispinwrap">
              <div class="fruitscomposition-item_innerspinner">
                <img
                  alt="logo"
                  src="@/assets/userpanel/img/userculturespreview/peach-min.png"
                />
                <p>{{ $t('UserStartscreen:Fruits:Peach') }}</p>
                <div class="vsvg" v-svg="{ name: 'usercompositionmask' }"></div>
                <transition name="animModalAuthFade" appear>
                  <div
                    class="fruitscomposition-item_fade"
                    role="dialog"
                    v-if="showFade5"
                  ></div>
                </transition>
              </div>
            </div>
          </div>
          <div
            class="fruitscomposition-item"
            style="--i: 5"
            @click="showModalSelectCulture = true"
            @mouseover="isHovering = true"
            @mouseout="isHovering = false"
            :class="{
              animstoponhoverfruititem: stopAnimation,
            }"
          >
            <div class="fruitscomposition-item_antispinwrap">
              <div class="fruitscomposition-item_innerspinner">
                <img
                  alt="logo"
                  src="@/assets/userpanel/img/userculturespreview/other-min.png"
                />
                <p>{{ $t('UserStartscreen:Fruits:Other') }}</p>
                <div class="vsvg" v-svg="{ name: 'usercompositionmask' }"></div>
                <transition name="animModalAuthFade" appear>
                  <div
                    class="fruitscomposition-item_fade"
                    role="dialog"
                    v-if="showFade6"
                  ></div>
                </transition>
              </div>
            </div>
          </div>
          <div
            class="fruitscomposition-item"
            style="--i: 6"
            @click="
              showModal = true;
              showFade7 = true;
              showPic7 = true;
              showFormReg = true;
              showFormLogin = false;
            "
            @mouseover="isHovering = true"
            @mouseout="isHovering = false"
            :class="{
              animstoponhoverfruititem: stopAnimation,
            }"
          >
            <div class="fruitscomposition-item_antispinwrap">
              <div class="fruitscomposition-item_innerspinner">
                <img
                  alt="logo"
                  src="@/assets/userpanel/img/userculturespreview/almond-min.png"
                />
                <p>{{ $t('UserStartscreen:Fruits:Almond') }}</p>
                <div class="vsvg" v-svg="{ name: 'usercompositionmask' }"></div>

                <transition name="animModalAuthFade" appear>
                  <div
                    class="fruitscomposition-item_fade"
                    role="dialog"
                    v-if="showFade7"
                  ></div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="systempage_bottom">
      <div>
        <div class="sidemenu-logoleft">
          <img
            alt="logo"
            src="@/assets/userpanel/img/userlogin/cartlislogo.png"
          />
        </div>
      </div>

      <div class="systempage_bottom-text">
        {{ $t('UserStartscreen:SelectCulture') }}
      </div>
      <div class="sidemenu-logoright marginright--nano">
        <img alt="logo" src="@/assets/userpanel/img/userlogin/usaidlogo.png" />
      </div>
    </div>

    <!--    Модальное авторизации на весь экран-->
    <!--    Предзагрузка картинок-->
    <template>
      <img
        src="@/assets/loginpage/login_logo.svg"
        rel="prefetch"
        style="display: none"
      />
      <img
        src="@/assets/userpanel/img/userculturesimages/loginapple.png"
        rel="prefetch"
        style="display: none"
      />
      <img
        src="@/assets/userpanel/img/userculturesimages/loginwalnut.png"
        rel="prefetch"
        style="display: none"
      />
      <img
        src="@/assets/userpanel/img/userculturesimages/loginblueberry.png"
        rel="prefetch"
        style="display: none"
      />
      <img
        src="@/assets/userpanel/img/userculturesimages/logingrape.png"
        rel="prefetch"
        style="display: none"
      />
      <img
        src="@/assets/userpanel/img/userculturesimages/loginpeach.png"
        rel="prefetch"
        style="display: none"
      />
      <img
        src="@/assets/userpanel/img/userculturesimages/loginother.png"
        rel="prefetch"
        style="display: none"
      />
      <img
        src="@/assets/userpanel/img/userculturesimages/loginalmond.png"
        rel="prefetch"
        style="display: none"
      />
    </template>
    <transition name="animModalAuthUser">
      <div class="modalfull" role="dialog" v-if="showModal">
        <div class="modalfull-left">
          <img
            v-if="showPic1"
            alt="fruit"
            src="@/assets/userpanel/img/userculturesimages/loginapple.png"
          />
          <img
            v-if="showPic2"
            alt="fruit"
            src="@/assets/userpanel/img/userculturesimages/loginwalnut.png"
          />
          <img
            v-if="showPic3"
            alt="fruit"
            src="@/assets/userpanel/img/userculturesimages/loginblueberry.png"
          />
          <img
            v-if="showPic4"
            alt="fruit"
            src="@/assets/userpanel/img/userculturesimages/logingrape.png"
          />
          <img
            v-if="showPic5"
            alt="fruit"
            src="@/assets/userpanel/img/userculturesimages/loginpeach.png"
          />
          <img
            v-if="showPic6"
            alt="fruit"
            src="@/assets/userpanel/img/userculturesimages/loginother.png"
          />
          <img
            v-if="showPic7"
            alt="fruit"
            src="@/assets/userpanel/img/userculturesimages/loginalmond.png"
          />
        </div>

        <div class="modalfull-right">
          <div class="modal-authmaxwidth" v-if="showFormReg">
            <UserRegister @onRegister1Ok="onRegister1Ok" />
            <div class="modal-bottomtext margintop--standart">
              <span>
                {{ $t('UserStartscreen:Registration:AskAccount') }}
              </span>
              <span
                @click="
                  showFormReg = false;
                  showFormLogin = true;
                "
                >{{ $t('UserStartscreen:Registration:Enter') }}</span
              >
            </div>
          </div>

          <div class="modal-authmaxwidth" v-if="showFormLogin">
            <UserLogin />
            <div class="modal-bottomtext margintop--standart">
              <span>{{ $t('UserStartscreen:Registration:AskAccount2') }} </span>
              <span
                @click="
                  showFormLogin = false;
                  showFormReg = true;
                "
                >{{ $t('UserStartscreen:Registration:Register') }}</span
              >
            </div>
          </div>
          <Button
            icon="x"
            size="s-standart"
            @click="
              showModal = false;
              showFade1 = false;
              showFade2 = false;
              showFade3 = false;
              showFade4 = false;
              showFade5 = false;
              showFade6 = false;
              showFade7 = false;
              showPic1 = false;
              showPic2 = false;
              showPic3 = false;
              showPic4 = false;
              showPic5 = false;
              showPic6 = false;
              showPic7 = false;
            "
            color="accentlines"
          />
        </div>
      </div>
    </transition>

    <!--    Модальное при клике на овощ "Другое"-->
    <div class="modalbackground" v-if="showModalSelectCulture">
      <div class="modalbackgroundWrap">
        <div class="modal modal--long">
          <div class="modalwrap2-topline">
            <div class="topline-minizone"></div>

            <Breadcrumps
              :data="[
                { title: $t('UserStartscreen:ModalSelectCulture:Title') },
              ]"
            />

            <div class="topline-minizone">
              <Button
                text=""
                size="s-standart"
                color="accentlightlines"
                icon="x"
                @click="
                  showModalSelectCulture = false;
                  showFade6 = false;
                "
              />
            </div>
          </div>

          <div class="modalwrap2-content">
            <div
              class="modalwrap3-inputs margintop--standart marginbottom--standart"
            >
              <DropdownPlusSelectValue
                :placeholder="
                  $t('UserStartscreen:ModalSelectCulture:Input.Placeholder')
                "
                :label="$t('UserStartscreen:ModalSelectCulture:Input.Label')"
                icon=""
                state=""
                size="long3x"
                color=""
                :ddMaxElements="6"
                :options="cultures"
                v-model="userSelectedCulture"
              />
            </div>

            <div class="modalwrap3-buttons">
              <Button
                :text="$t('UserStartscreen:ModalSelectCulture:ButtonUndo')"
                size="wide"
                color="graylines"
                icon="cancel"
                @click="
                  showModalSelectCulture = false;
                  showFade6 = false;
                "
              />
              <Button
                :text="$t('UserStartscreen:ModalSelectCulture:ButtonNext')"
                size="wide"
                color="accent"
                icon="tick"
                @click="
                  showModalSelectCulture = false;
                  showModal = true;
                  showPic6 = true;
                  showFormReg = true;
                  showFormLogin = false;
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    Модальное с другими данными клиента"-->
    <div class="modalbackground" v-if="showModalUserRegData">
      <div class="modalbackgroundWrap">
        <div class="modal modal--long4x">
          <div class="modalwrap2-topline">
            <div class="topline-minizone"></div>

            <Breadcrumps
              :data="[{ title: $t('UserStartscreen:ModalReg2:Title') }]"
            />

            <div class="topline-minizone">
              <Button
                text=""
                size="s-standart"
                color="accentlightlines"
                icon="x"
                :disabled="isLoading"
                @click="closeModalRegStep2"
              />
            </div>
          </div>

          <div class="modalwrap2-content">
            <div class="modalwrap3-inputs margintop--standart">
              <Input
                type="text"
                :placeholder="$t('UserStartscreen:ModalReg2:Name.Placeholder')"
                :label="$t('UserStartscreen:ModalReg2:Name.Label')"
                icon=""
                :state="isLoading ? 'disabled' : ''"
                size="long"
                color=""
                v-model="regStep2Data.name"
              />
              <Input
                type="text"
                :placeholder="$t('UserStartscreen:ModalReg2:Code.Placeholder')"
                :label="$t('UserStartscreen:ModalReg2:Code.Label')"
                icon=""
                :state="isLoading ? 'disabled' : ''"
                size="Standart"
                color=""
                v-model="regStep2Data.code"
              />
              <Input
                type="text"
                :placeholder="$t('UserStartscreen:ModalReg2:Phone.Placeholder')"
                :label="$t('UserStartscreen:ModalReg2:Phone.Label')"
                icon=""
                :state="isLoading ? 'disabled' : ''"
                size="Standart"
                color=""
                v-model="regStep2Data.phone"
              />
            </div>
            <div class="modalwrap3-inputs margintop--standart">
              <!--Страна-->
              <LocationSelect
                :geoEntries="geoMapped"
                v-model="regStep2Data.geo_id"
                :freeSelect="true"
                :disabled="isLoading"
                ref="location"
              />
            </div>
            <div class="modalwrap3-inputs margintop--mini">
              <Input
                type="text"
                :placeholder="
                  $t('UserStartscreen:ModalReg2:Address.Placeholder')
                "
                :label="$t('UserStartscreen:ModalReg2:Address.Label')"
                icon=""
                :state="isLoading ? 'disabled' : ''"
                size="long4x"
                color=""
                v-model="regStep2Data.address"
              />
            </div>
            <div class="modalwrap3-buttons margintop--standart">
              <Button
                :text="$t('UserStartscreen:ModalReg2:ButtonSkip.Text')"
                size="wide"
                color="graylines"
                icon="cancel"
                :disabled="isLoading"
                @click="onRegister2Ok"
              />

              <Button
                :text="$t('UserStartscreen:ModalReg2:ButtonFinish.Text')"
                size="wide"
                color="accent"
                icon="tick"
                :loading-left="true"
                :is-loading="isLoading"
                @click="onRegister2Ok"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import Input from '@/components/views/Input';
import UserLogin from '@/pages/userproject/userComponents/UserLogin';
import UserRegister from '@/pages/userproject/userComponents/UserRegister';
import Button from '@/components/views/Button';
import Modal from '@/pages/Modal';
import routeNames from '@/router/routeNames';
import { mapGetters } from 'vuex';
import LocationSelect from '@/components/views/LocationSelect';
import * as adminApi from '@/api/clientsArea/admin';
import { setItem } from '@/helpers/persistanceStorage';
import DropdownPlusSelectValue from '@/components/views/wrappers/DropdownPlusSelectValue';

export default {
  name: 'UserStartscreen',
  components: {
    DropdownPlusSelectValue,
    LocationSelect,
    UserRegister,
    UserLogin,
    Input,
    Button,
    Modal,
    Breadcrumps,
  },

  data() {
    return {
      showModal: false,
      showModalSelectCulture: false,
      showModalUserRegData: false,

      showFormReg: true,
      showFormLogin: false,

      showFade1: false,
      showFade2: false,
      showFade3: false,
      showFade4: false,
      showFade5: false,
      showFade6: false,
      showFade7: false,
      showPic1: false,
      showPic2: false,
      showPic3: false,
      showPic4: false,
      showPic5: false,
      showPic6: false,
      showPic7: false,
      stopSpin: false,
      isHovering: false,
      animEasterFruitComposition: false,

      regStep1Data: {
        login: '',
        password: '',
      },
      regStep2Data: {
        name: '',
        code: '',
        phone: '',
        geo_id: null,
        address: '',
      },
      isLoading: false,
      userSelectedCulture: null,
    };
  },
  methods: {
    onRegister1Ok(data) {
      this.showModalUserRegData = true;
      this.showModal = false;
      this.showFade1 = false;
      this.showFade2 = false;
      this.showFade3 = false;
      this.showFade4 = false;
      this.showFade5 = false;
      this.showFade6 = false;
      this.showFade7 = false;
      this.showPic1 = false;
      this.showPic2 = false;
      this.showPic3 = false;
      this.showPic4 = false;
      this.showPic5 = false;
      this.showPic6 = false;
      this.showPic7 = false;
      this.regStep1Data.login = data.login;
      this.regStep1Data.password = data.password;
    },
    closeModalRegStep2() {
      this.showModalUserRegData = false;
    },
    async onRegister2Ok() {
      this.isLoading = true;
      let data = {
        regData: {
          login: this.regStep1Data.login,
          password: this.regStep1Data.password,
          code: this.regStep2Data.code,
          geo_id: this.regStep2Data.geo_id,
          name: this.regStep2Data.name,
          address: this.regStep2Data.address,
        },
        clientContacts: [],
      };
      if (this.regStep2Data.phone) {
        data.clientContacts.push({
          contact_type_id: 1,
          value: this.regStep2Data.phone,
        });
      }
      try {
        let result = await adminApi.registration(data);
        if (result.data?.status === 'ok') {
          setItem('token', result.data?.token);
          this.$router.push({ name: 'UserHomePage' });
        } else {
          this.$awn.alert(
            this.$t('UserStartscreen:Error:TextStart') + result.data?.error ??
              this.$t('UserStartscreen:Error:TextEnd')
          );
        }
      } catch (e) {
        console.log(e);
      }
      this.isLoading = false;
    },
  },
  computed: {
    ...mapGetters(['geoMapped']),
    stopAnimation() {
      return this.isHovering || this.showModal || this.showModalUserRegData;
    },
    cultures() {
      return (
        this.$store.state.glossaryCultures.cultures?.map((c) => {
          return { value: c.id, text: c.name };
        }) || []
      );
    },
  },
  created() {},
};
</script>

<style lang="scss">
@import '~@/scss/vars.scss';

/*


.systempage--userstartscreen {
  width: 100%;
  min-height: 100vh;
  background-color: $colorWhite;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

*/

.pagebcg {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.pagebcg--06 {
  background-image: url('~@/assets/bcg/bcg_06.jpg');
}

.pagebcg--anim {
  animation-duration: 3s;
  animation-name: animPageBcg;
  animation-timing-function: ease-in-out;

  @keyframes animPageBcg {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

/*.modal--fullscreen{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: #f28b20;
  border-radius: 0;
}*/

/*@keyframes fullmodashow {
  from {
    position: absolute;
    width: 1vw;
    height: 1vh;
    z-index: 0;
  }
  to {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: $colorCartlis04;
  }
}*/

/*.button2 {
  border: none;
  color: #FFF;
  background: #42b983;
  appearance: none;
  font: inherit;
  font-size: 1.8rem;
  padding: .5em 1em;
  border-radius: .3em;
  cursor: pointer;
}*/

/*.modal-overlay {
  content: '';

  position: absolute !important;
  z-index: 995;
  left: 0;
  right: 0;
  background-color: #f28b20;
  width: 96px;//120px;
  height: 96px;//120px;
  min-height: 96px;//120px;
  border-radius: 100%;
  transform: scale(100);
}*/
</style>
