let waitingForUserChoice = false;

export const createConfirmCallback = (nextFn) => {
  if (waitingForUserChoice) nextFn(false);
  waitingForUserChoice = true;

  return (confirm) => {
    waitingForUserChoice = false;
    nextFn(confirm ? undefined : false);
  };
};
