import glossaryApi from '@/api/glossary';
import { arrayToTree } from 'performant-array-to-tree';

const state = {
  isGeobaseLoading: false,
  isGeobaseLoadingHasError: false,
  geobaseRaw: [],

  isGeobaseUpdating: false,
  geobaseUpdatingError: null,
  isGeobaseUpdatingDone: false,

  timestamp: 0,
};

const makeGeoEntitiesFlat = (tree) => {
  const result = [];
  const iter = (entries, accum) => {
    for (const entry of entries) {
      const newAccum = [...accum, { name: entry.data.name, id: entry.data.id }];
      result.push({
        order: newAccum,
        id: entry.data.id,
        text: [...newAccum]
          .map((e) => e.name)
          .reverse()
          .join(' / '),
        type: entry.data.type,
        name: entry.data.name,
        children: entry.children.map((e) => e.data),
      });
      if (entry.children.length) iter(entry.children, newAccum);
    }
  };
  iter(tree, []);
  return result;
};

const getters = {
  geoEntities(state) {
    return arrayToTree(state.geobaseRaw, { parentId: 'parent_id' });
  },

  geoEntitiesActive(state) {
    return arrayToTree(
      state.geobaseRaw.filter((e) => e.is_active),
      { parentId: 'parent_id' }
    );
  },

  geoPlainData(_, getters) {
    return makeGeoEntitiesFlat(getters.geoEntities);
  },

  geoPlainDataActive(_, getters) {
    return makeGeoEntitiesFlat(getters.geoEntitiesActive);
  },

  geoPlainDataAsMap(_, getters) {
    return new Map(getters.geoPlainData.map((e) => [e.id, e]));
  },

  geoMapped(state) {
    return state.geobaseRaw.map((e) => ({ ...e, value: e.id, text: e.name }));
  },
};

const mutationTypes = {
  getGeobaseStart: '[glossary] getGeobase start',
  getGeobaseSuccess: '[glossary] getGeobase success',
  getGeobaseFailure: '[glossary] getGeobase failure',

  updateGeobaseStart: '[glossary] updateGeobase start',
  updateGeobaseSuccess: '[glossary] updateGeobase success',
  updateGeobaseFailure: '[glossary] updateGeobase failure',

  createGeobaseStart: '[glossary] createGeobase start',
  createGeobaseSuccess: '[glossary] createGeobase success',
  createGeobaseFailure: '[glossary] createGeobase failure',
};

const actionTypes = {
  getGeobase: '[glossary] getGeobase',
  updateGeobase: '[glossary] updateGeobase',
  createGeobase: '[glossaty] createGeobase',
};

const mutations = {
  [mutationTypes.getGeobaseStart](state) {
    state.isGeobaseLoading = true;
    state.isGeobaseLoadingHasError = false;
  },
  [mutationTypes.getGeobaseFailure](state) {
    state.isGeobaseLoading = false;
    state.isGeobaseLoadingHasError = true;
  },
  [mutationTypes.getGeobaseSuccess](state, entities) {
    state.isGeobaseLoading = false;
    if (entities) {
      // с бека могут прийти не страны без родителя
      state.geobaseRaw = entities.filter(
        (e) => e.type === 0 || (e.type > 0 && e.parent_id != null)
      );
      state.timestamp = new Date().getTime();
    }
  },
  [mutationTypes.updateGeobaseStart](state) {
    state.isGeobaseUpdating = true;
    state.isGeobaseUpdatingDone = false;
    state.isGeobaseUpdatingError = null;
  },
  [mutationTypes.updateGeobaseFailure](state, error) {
    state.isGeobaseUpdating = false;
    state.isGeobaseUpdatingHasError = error;
  },
  [mutationTypes.updateGeobaseSuccess](state, payload) {
    state.isGeobaseUpdating = false;
    state.isGeobaseUpdatingDone = true;
    const target = state.geobaseRaw.find((e) => e.id === payload.id);
    Object.entries(payload).forEach(([k, v]) => (target[k] = v));
    state.timestamp = 0;
  },
};

const actions = {
  async [actionTypes.getGeobase]({ commit }) {
    commit(mutationTypes.getGeobaseStart);
    try {
      const res = await glossaryApi.getGeobase(state.timestamp);
      commit(mutationTypes.getGeobaseSuccess, res);
    } catch (error) {
      commit(mutationTypes.getGeobaseFailure);
    }
  },
  async [actionTypes.updateGeobase]({ commit }, payload) {
    commit(mutationTypes.updateGeobaseStart);
    try {
      await glossaryApi.updateGeobase(payload);
      commit(mutationTypes.updateGeobaseSuccess, payload);
    } catch (error) {
      commit(mutationTypes.updateGeobaseFailure, error);
    }
  },
};

export { mutationTypes, actionTypes };

export default {
  state,
  actions,
  mutations,
  getters,
};
