<template>
  <!-- USE class warningblock--fatality to red wqrning -->
  <div class="warningblock warningblock--warning">
    <div class="rightzone_block warningblock-internal">
      <div class="warningblock-colorline"></div>

      <div class="warningblock-iconzone marginleft--standart marginright--mini">
        <div v-svg="{ name: 'bulb' }"></div>
      </div>
      <div class="warningblock-text">
        <span> {{ text }}</span>
      </div>
    </div>

    <div class="warningblock-buttonzone marginleft--standart">
      <button
        class="btn btn--width_auto btn--color_warninglines btn--playaudio"
        @click.prevent="sharedTrack.toogle()"
        :disabled="!sharedTrack.ready"
        :class="{ 'btn--playaudio--clicked': sharedTrack.isPlaying }"
      >
        <div class="btn-view1">
          <span class="btn_lefticon">
            <div v-svg="{ name: 'play' }" />
          </span>
          <span class="btn_text"> მისმინე </span>
        </div>

        <div class="btn-view2">
          <span class="btn_text"> გაჩერდი </span>
          <span class="btn_righticon">
            <svg
              id="wave"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 38.05"
            >
              <path
                id="Line_1"
                data-name="Line 1"
                d="M0.91,15L0.78,15A1,1,0,0,0,0,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H0.91Z"
              />
              <path
                id="Line_2"
                data-name="Line 2"
                d="M6.91,9L6.78,9A1,1,0,0,0,6,10V28a1,1,0,1,0,2,0s0,0,0,0V10A1,1,0,0,0,7,9H6.91Z"
              />
              <path
                id="Line_3"
                data-name="Line 3"
                d="M12.91,0L12.78,0A1,1,0,0,0,12,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H12.91Z"
              />
              <path
                id="Line_4"
                data-name="Line 4"
                d="M18.91,10l-0.12,0A1,1,0,0,0,18,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H18.91Z"
              />
              <path
                id="Line_5"
                data-name="Line 5"
                d="M24.91,15l-0.12,0A1,1,0,0,0,24,16v6a1,1,0,0,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H24.91Z"
              />
              <path
                id="Line_6"
                data-name="Line 6"
                d="M30.91,10l-0.12,0A1,1,0,0,0,30,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H30.91Z"
              />
              <path
                id="Line_7"
                data-name="Line 7"
                d="M36.91,0L36.78,0A1,1,0,0,0,36,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H36.91Z"
              />
              <path
                id="Line_8"
                data-name="Line 8"
                d="M42.91,9L42.78,9A1,1,0,0,0,42,10V28a1,1,0,1,0,2,0s0,0,0,0V10a1,1,0,0,0-1-1H42.91Z"
              />
              <path
                id="Line_9"
                data-name="Line 9"
                d="M48.91,15l-0.12,0A1,1,0,0,0,48,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H48.91Z"
              />
            </svg>
          </span>
        </div>
      </button>
    </div>

    <div
      v-if="infoButtonVisible"
      class="warningblock-buttonzone marginleft--standart"
    >
      <Button
        text=""
        size="s-standart"
        color="warninglines"
        icon="info"
        @click="1"
      />
    </div>

    <div class="warningblock-buttonzone marginleft--standart">
      <Button
        text=""
        size="s-standart"
        color="graylines"
        icon="x"
        @click="
          sharedTrack.stop();
          $emit('dismiss');
        "
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/views/Button';
import { reactive } from 'vue';

const audios = ['audio/audio_manual_1.m4a', 'audio/audio_manual_2.m4a'];

const shared = audios.map((file) => {
  let audio;

  const instance = reactive({
    isPlaying: false,
    ready: false,
    init() {
      audio = new Audio(file);
      audio.addEventListener('canplaythrough', () => {
        this.ready = true;
      });
      audio.addEventListener('ended', () => this.stop());
    },
    play() {
      audio.currentTime = 0;
      audio.play();
      this.isPlaying = true;
    },
    stop() {
      audio.pause();
      this.isPlaying = false;
    },
    toogle() {
      this.isPlaying ? this.stop() : this.play();
    },
  });

  return instance;
});

export default {
  name: 'WarningBlockAudio',
  components: { Button },
  props: {
    phaseVisible: { type: Boolean, required: true },
    trackNumber: { type: Number, default: 1 },
    infoButtonVisible: { type: Boolean, default: true },
    text: { type: String, default: 'მოუსმინეთ მოკლე აუდიო სახელმძღვანელოს' },
  },
  data() {
    return {
      shared,
      isShown: true,
    };
  },
  created() {
    this.sharedTrack.init();
  },
  computed: {
    sharedTrack() {
      return this.shared[this.trackNumber - 1];
    },
  },
  watch: {
    phaseVisible(value) {
      if (!value) this.sharedTrack.stop();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/vars.scss';

.btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: $borderRadiusStandart;
  font-size: $fontsizeBigButtons;
  line-height: $lineFontsizeBigButtons;
  font-weight: $fontNormal;
  user-select: none;
  padding-left: $marginMicro;
  padding-right: $marginMicro;

  & .btn_lefticon {
    & > * {
      width: $iconSizeStandart;
      height: $iconSizeStandart;
    }
  }

  & .btn_text {
    margin-left: $marginMicro2;
    margin-right: $marginMicro2;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  & .btn_righticon {
    display: flex;
    align-items: center;
    width: 5ch;
    height: 5ch;

    & > * {
      width: 5ch;
      height: 5ch;
    }
  }
}

//colors
.btn--color_warning {
  background-color: $colorWarning;
  color: $colorTextWhite;
  border: $borderButton solid $colorWarning;

  & * {
    fill: $colorWhite;
  }

  &:hover {
    background-color: transparent;
    color: $colorWarning;
    border: $borderButton solid $colorWarning;

    & * {
      fill: $colorWarning;
    }
  }
}
.btn--color_warninglines {
  background-color: transparent;
  color: $colorWarning;
  border: $borderButton solid $colorWarning;

  & * {
    fill: $colorWarning;
  }

  &:hover {
    background-color: $colorWarning;
    color: $colorTextWhite;
    border: $borderButton solid $colorWarning;

    & * {
      fill: $colorWhite;
    }
  }
}

.btn--color_gray {
  background-color: $colorGray;
  color: $colorTextWhite;
  border: $borderButton solid $colorGray;

  & * {
    fill: $colorWhite;
  }

  &:hover {
    background-color: transparent;
    color: $colorTextGray;
    border: $borderButton solid $colorLinesGray;

    & * {
      fill: $colorGray;
    }
  }
}
.btn--color_graylines {
  background-color: transparent;
  color: $colorTextGray;
  border: $borderButton solid $colorLinesGray;

  & * {
    fill: $colorGray;
  }

  &:hover {
    background-color: $colorGray;
    color: $colorTextWhite;
    border: $borderButton solid $colorLinesGray;

    & * {
      fill: $colorWhite;
    }
  }
}

.btn--color_accent {
  background-color: $colorAccent;
  color: $colorTextWhite;
  border: $borderButton solid $colorAccent;

  & * {
    fill: $colorWhite;
  }

  &:hover {
    background-color: $colorAccentDark;
    border: $borderButton solid $colorAccentDark;
  }
}
.btn--color_accentlines {
  background-color: transparent;
  color: $colorTextAccent;
  border: $borderButton solid $colorLinesAccent;

  & * {
    fill: $colorAccent;
  }

  &:hover {
    background-color: $colorTextAccent;
    color: $colorTextWhite;
    border: $borderButton solid $colorLinesAccent;

    & * {
      fill: $colorWhite;
    }
  }
}

.btn--width_auto {
  min-width: $widthMini;
  height: $heightStandart;
}

.btn--disabled {
  cursor: auto;
  background-color: transparent;
  color: $colorTextGrayLight;
  border: $borderButton solid $colorGrayLight;

  & * {
    fill: $colorGrayLight;
  }

  &:hover {
    background-color: transparent;
    color: $colorTextGrayLight;
    border: $borderButton solid $colorGrayLight;

    & * {
      fill: $colorGrayLight;
    }
  }
}

.btn--playaudio {
  overflow: hidden;

  & .btn-view1 {
    display: flex;
    justify-content: center;
    //background-color: red;
    min-width: 100%;
    //margin-left: 100%;
    transform: translateX(50%);
    transition: transform 1s;
  }

  & .btn-view2 {
    display: flex;
    justify-content: center;
    //background-color: #629b33;
    min-width: 100%;
    transform: translateX(50%);
    transition: transform 1s;
  }

  .iconwave {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  #wave {
    fill: #fff !important;
  }

  @for $i from 1 through 9 {
    #Line_#{$i} {
      animation: pulse 1s infinite;
      animation-delay: $i * 0.15s;
    }
  }

  @keyframes pulse {
    0% {
      transform: scaleY(1);
      transform-origin: 50% 50%;
    }

    50% {
      transform: scaleY(0.7);
      transform-origin: 50% 50%;
    }

    100% {
      transform: scaleY(1);
      transform-origin: 50% 50%;
    }
  }
}
.btn--playaudio--clicked {
  overflow: hidden;

  & .btn-view1 {
    //display: none;
    transform: translateX(-50%);
    transition: transform 1s;
  }

  & .btn-view2 {
    transform: translateX(-50%);
    transition: transform 1s;
  }
}

// Состояние лоадинг
.btn--loading {
  cursor: wait;
  background-color: $colorGray !important;
  border: $borderButton solid $colorLinesGray;

  &:hover {
    background-color: inherit;
    border: inherit;
    color: $colorTextWhite;

    & * {
      fill: $colorWhite;
    }
  }

  & .loader {
    display: block;
    width: $iconSizeStandart;
    height: $iconSizeStandart;
    border-radius: 50%;
    background-color: transparent;
  }
}

@media screen and (max-width: 1800px) {
  .btn {
    border-radius: $borderRadiusStandart-k1600;
    font-size: $fontsizeBigButtons-k1600;
    line-height: $lineFontsizeBigButtons-k1600;
    padding-left: $marginMicro-k1600;
    padding-right: $marginMicro-k1600;

    & .btn_lefticon {
      & > * {
        width: $iconSizeStandart-k1600;
        height: $iconSizeStandart-k1600;

        & > * {
          width: 100%;
          height: 100%;
        }
      }
    }
    & .btn_text {
      margin-left: $marginMicro2-k1600;
      margin-right: $marginMicro2-k1600;
    }
    & .btn_righticon {
      display: flex;
      align-items: center;
      width: 5ch;
      height: 5ch;

      & > * {
        width: 5ch;
        height: 5ch;
      }
    }
  }

  .btn--width_auto {
    min-width: $widthMini-k1600;
    height: $heightStandart-k1600;
  }

  // Состояние лоадинг
  .btn--loading {
    & .loader {
      width: $iconSizeStandart-k1600;
      height: $iconSizeStandart-k1600;
    }
  }
}

@media screen and (max-width: 1420px) {
  .btn {
    border-radius: $borderRadiusStandart-k1360;
    font-size: $fontsizeBigButtons-k1360;
    line-height: $lineFontsizeBigButtons-k1360;
    padding-left: $marginMicro-k1360;
    padding-right: $marginMicro-k1360;

    & .btn_lefticon {
      & > * {
        width: $iconSizeStandart-k1360;
        height: $iconSizeStandart-k1360;

        & > * {
          width: 100%;
          height: 100%;
        }
      }
    }
    & .btn_text {
      margin-left: $marginMicro2-k1360;
      margin-right: $marginMicro2-k1360;
    }
    & .btn_righticon {
      display: flex;
      align-items: center;
      width: 5ch;
      height: 5ch;

      & > * {
        width: 5ch;
        height: 5ch;
      }
    }
  }

  .btn--width_auto {
    min-width: $widthMini-k1360;
    height: $heightStandart-k1360;
  }

  // Состояние лоадинг
  .btn--loading {
    & .loader {
      display: flex;
      width: $iconSizeStandart-k1360;
      height: $iconSizeStandart-k1360;
    }
  }
}
</style>
