var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"rightzone"},[_c('div',{staticClass:"rightzone_top"},[_c('Breadcrumps',{attrs:{"data":[{ title: _vm.$t('AdminArticles:Title') }]}}),_c('div',{staticClass:"rightzone_top-buttonswrap"},[_c('Button',{attrs:{"text":_vm.$t('AdminArticles:ButtonAdd'),"size":"standart","color":"accent","icon":"plus","is-loading":false,"loading-left":false},on:{"click":function($event){return _vm.$router.push({ name: 'NewArticle' })}}})],1)],1),_c('div',{staticClass:"rightzone_block"},[_c('div',{staticClass:"filter"},[_c('div',{staticClass:"filter-inputszone"},[_c('div',{staticClass:"filterwrap filterwrap--adwbanners"},[_c('div',{staticClass:"filterwrap_line"},[_c('Input',{attrs:{"type":"text","placeholder":_vm.$t('AdminArticles:Filter:Name.Placeholder'),"label":_vm.$t('AdminArticles:Filter:Name.Label'),"icon":"","state":"","size":"long","color":""},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}}),_c('DropdownSelectValue',{attrs:{"type":"dropdown","placeholder":_vm.$t('AdminArticles:Filter:Category.Placeholder'),"label":_vm.$t('AdminArticles:Filter:Category.Label'),"size":"standart","options":[
                {
                  text: _vm.$t('UserHomePage:ArticlesCategories:Defence.Title'),
                  value: 0,
                },
                {
                  text: _vm.$t(
                    'UserHomePage:ArticlesCategories:Fertilizer.Title'
                  ),
                  value: 1,
                },
                {
                  text: _vm.$t(
                    'UserHomePage:ArticlesCategories:Irrigation.Title'
                  ),
                  value: 2,
                },
                {
                  text: _vm.$t('UserHomePage:ArticlesCategories:More.Title'),
                  value: 3,
                },
              ]},model:{value:(_vm.filter.category),callback:function ($$v) {_vm.$set(_vm.filter, "category", $$v)},expression:"filter.category"}}),_c('DropdownSelectValue',{attrs:{"placeholder":_vm.$t('AdminArticles:Filter:Type:Placeholder'),"label":_vm.$t('AdminArticles:Filter:Type:Label'),"size":"standart","options":_vm.articlesTypes},model:{value:(_vm.filter.type),callback:function ($$v) {_vm.$set(_vm.filter, "type", $$v)},expression:"filter.type"}})],1)])]),_c('div',{staticClass:"filter-clearbtnzone"},[_c('Button',{staticClass:"filter_buttonclear",attrs:{"text":"очистить","size":"s-standart","color":_vm.filterIsActive ? 'accentlines' : 'graylines',"icon":"backspace","is-loading":false,"loading-left":true},on:{"click":_vm.clearFilter}})],1)])]),_c('div',{staticClass:"rightzone_table"},[_c('Table',{attrs:{"headers":_vm.tableHeaders,"data-arr":_vm.dataArr,"table-loading":_vm.isTableLoading,"loading-count-items":10},on:{"editClick":_vm.onEditClick}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }