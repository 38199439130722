import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  ge: require('./i18n/ge.json'),
  ru: require('./i18n/ru.json'),
};

const i18n = new VueI18n({
  locale: 'ge',
  messages: messages,
});

export default i18n;
