<template>
  <div class="cblock el--borders-all el--rounded-bottom" :class="{ disabled }">
    <div class="cblock__internal addimagewrap">
      <div
        class="addimage-imagezone"
        v-for="(image, index) in images"
        :key="image.name"
      >
        <div
          class="addimage-image"
          :style="{
            backgroundImage: `url(${image.src})`,
          }"
        >
          <Button
            v-if="!readonly"
            class="addimages-image_btndelete"
            text=" "
            size="s-micro"
            color="alertlines"
            icon="x"
            :is-loading="false"
            :loading-left="true"
            @click="deleteImage(image.name)"
          />

          <Button
            v-if="!readonly"
            class="addimages-image_btnprimary"
            text=" "
            size="s-micro"
            color="accentlines"
            icon="tick"
            :is-loading="false"
            :loading-left="true"
            @click="setMain(index)"
          />
        </div>

        <!--only for first child by css-->
        <div class="mainphotolable">
          {{ $t('ImagesSelect:MainPhotoTitle') }}
        </div>
      </div>

      <div class="addimage-imagezone" v-if="canAddMore && !readonly">
        <label
          :class="{
            'addimage-button': true,
            'addimage-button--firstimg': isEmpty,
            'addimage-button--moreimg': !isEmpty,
          }"
        >
          <input
            type="file"
            accept="image/png, image/jpeg"
            multiple
            @change="fileSelected"
            :disabled="disabled"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/views/Button';

export default {
  name: 'FilesSelect',
  components: { Button },
  model: { prop: 'images' },
  props: {
    maxImages: { type: Number, default: 8 },
    images: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  computed: {
    isEmpty() {
      return this.images.length === 0;
    },
    canAddMore() {
      return this.images.length < this.maxImages;
    },
  },
  methods: {
    fileSelected(event) {
      const copy = [...this.images];
      for (const file of event.target.files) {
        const alreadyInList = copy.some((f) => f.name === file.name);
        if (!alreadyInList && copy.length < this.maxImages) {
          copy.push({
            type: 'new',
            name: file.name,
            file,
            src: window.URL.createObjectURL(file),
            is_main: copy.length === 0 ? 1 : 0,
          });
        }
      }
      event.target.value = '';
      this.$emit('input', copy);
    },
    deleteImage(name) {
      this.$emit(
        'input',
        this.images.filter((f) => f.name !== name)
      );
    },
    setMain(index) {
      const image = this.images[index];
      image.is_main = 1;
      this.$emit('input', [
        image,
        ...this.images
          .filter((img) => img !== image)
          .map((img) => ({ ...img, is_main: 0 })),
      ]);
    },
  },
  watch: {
    images: {
      handler(value) {
        const hasNoMain = value.every((img) => !img.is_main);
        if (hasNoMain && value.length > 0) {
          this.$emit(
            'input',
            value.map((img, index) => ({ ...img, is_main: index ? 0 : 1 }))
          );
          return;
        }

        const mainNotFirst = value[0]?.is_main === 0;
        if (mainNotFirst) {
          this.$emit(
            'input',
            [...value].sort((a, b) => b.is_main - a.is_main)
          );
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
label {
  display: inline-block;
}
input[type='file'] {
  display: none;
}
.addimage-image {
  background-size: cover;
}
.disabled > * {
  pointer-events: none;
  filter: grayscale(1);
  opacity: 0.5;
}
</style>
