<!--Translated-->
<template>
  <div class="cwrap cwrap--level1 marginbottom--standart" v-show="!isLoading">
    <!-- This somehow fixes scroll jump in chrome when culture header near top of the page -->
    <p
      id="fix-scroll-jump"
      v-if="isFirst"
      style="color: white; user-select: none; height: 0.5px"
    >
      _
    </p>
    <ClientItemHeader
      class="level1 chead el--borders-top el--borders-sides el--rounded-top"
      iconName="agriculture"
      :text="`${$t('ClientCulture:HeaderText')} -  ${selectedCultureName}`"
      :id="culture.id"
      type="culture"
    />

    <div
      v-show="isShown"
      class="cblock el--borders-all"
      :class="{ 'el--rounded-bottom': places.length === 0 }"
    >
      <div class="cblock__internal">
        <div class="cblock__line">
          <CultureSelect
            class="cblock__line-block"
            :categories="categories"
            :cultures="culturesAll"
            v-model="cultureId"
          />
        </div>
        <div class="el__decorline margintop--standart"></div>
        <div class="cblock__line">
          <div class="cblock__line-block">
            <Button
              :text="$t('ClientCulture:ButtonAddPlace')"
              size="wider"
              color="accent"
              icon="farm"
              @click="addPlace"
            />
          </div>
          <div class="cblock__line-block flex-end">
            <Button
              :text="$t('ClientCulture:ButtonRemoveCulture')"
              size="standart"
              color="alertlines"
              icon="delete"
              :is-loading="false"
              :loading-left="true"
              @click="deleteCulture"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="cwrap">
      <ClientPlace
        v-for="place in places"
        :key="place.id"
        :place="place"
        :count="places.length"
        :noYear="oneYearCulture"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mutationTypes } from '@/store/modules/clientsArea/clients';
import { mapInputEntityWithMerge } from '@/components/adapters/mapInput';
import { confirmDelete } from '@/pages/productsclients/confirmDelete';

import Button from '@/components/views/Button';
import CultureSelect from '@/components/views/CultureSelect';
import ClientItemHeader from './ClientItemHeader';
import ClientPlace from './ClientPlace';

export default {
  name: 'Client2Culture',
  props: {
    culture: { type: Object, reuired: true },
    count: { type: Number, required: true },
    isFirst: { type: Boolean, default: false },
  },
  components: {
    Button,
    CultureSelect,
    ClientItemHeader,
    ClientPlace,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.clients2.isLoading,
      isShown(state) {
        return state.clients2.expandedHeadersStatuses[
          `culture_${this.culture.id}`
        ];
      },
      categories: (state) =>
        state.clients2.categoryCultures.map(mapInputEntityWithMerge),
      culturesAll(state) {
        return state.clients2.cultures
          .map((c) => ({
            ...c,
            exists: this.takenCulturesIds.includes(c.id),
          }))
          .map(mapInputEntityWithMerge);
      },
      takenCulturesIds: (state) =>
        state.clients2.clientCultures.map((c) => c.culture_id),
      places(state) {
        return state.clients2.clientPlaces.filter(
          (place) => place.client_culture_id === this.culture.id
        );
      },
    }),
    cultureId: {
      get() {
        return this.culture.culture_id ?? null;
      },
      set(id) {
        this.$store.commit(mutationTypes.setCultureId, {
          id: this.culture.id,
          cultureId: id,
        });
      },
    },
    selectedCulture() {
      return this.culturesAll.find((c) => c.id === this.cultureId);
    },
    selectedCultureName() {
      return this.selectedCulture?.name ?? this.$t('ClientCulture:NotSelected');
    },
    oneYearCulture() {
      return Boolean(this.selectedCulture?.only_one_year);
    },
  },
  methods: {
    deleteCulture() {
      confirmDelete.call(
        this,
        this.$t('ClientCulture:ConfirmDelete'),
        mutationTypes.deleteCulture,
        this.culture
      );
    },
    addPlace(highlight = true) {
      this.$store.commit(mutationTypes.addBlankPlace, {
        cultureId: this.culture.id,
        highlight,
      });
    },
  },
  watch: {
    places: {
      handler({ length }) {
        if (length === 0) this.addPlace(false);
      },
      immediate: true,
    },
  },
};
</script>
