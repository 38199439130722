import Vue from 'vue';
import App from './App.vue';
import router from './router/router';
import store from './store';
import i18n from '@/./i18n';
require('./directives/Svg');
require('./directives/ClickOutside');
require('./directives/Sticky');
import VueAWN from 'vue-awesome-notifications';
import ModalConfirm from './plugins/modal-confirm/';
import wysiwyg from 'vue-wysiwyg';

//https://github.com/chmln/vue-wysiwyg
Vue.use(wysiwyg, { hideModules: { image: true, table: true } });

/*import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2';

Vue.use(TawkMessengerVue, {
  propertyId: '6300f49754f06e12d88fc2f0',
  widgetId: '1gatrmshb',
});*/

Vue.config.productionTip = false;
Vue.use(VueAWN, {});

Vue.use(ModalConfirm);
Vue.component('modal-confirm', ModalConfirm.default);

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

export default router;
