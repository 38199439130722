import deepFreeze from 'deep-freeze';

import api from '@/api/nutrition';
import { mutationTypes as geobaseMutationTypes } from './glossaryGeobase';

const createState = () => ({
  timestamp: 0,
  isLoading: false,
  error: null,
  programs: [],
  categoriesCultures: [],
  cultures: [],
});

const state = createState();

const mutationTypes = {
  fetchStart: '[plants care n pl] programs fetch start',
  fetchSuccess: '[plants care n pl] programs fetch success',
  fetchFailure: '[plants care n pl] programs fetch failure',
};

const actionTypes = {
  fetchPrograms: '[plants care n pl] fetch programs',
};

const mutations = {
  [mutationTypes.fetchStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.fetchFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },
  [mutationTypes.fetchSuccess](state, payload) {
    state.timestamp = new Date().getTime();
    if (payload.programs) {
      const managers = new Map();
      payload.users?.forEach((user) => managers.set(user.id, user));
      const clients = new Map();
      payload.clientsCreators?.forEach((user) => clients.set(user.id, user));

      for (const program of payload.programs) {
        let creatorName = '';
        if (program.creator_status) {
          const client = clients.get(program.client_id);
          if (client.name) creatorName += client.name;
          creatorName += creatorName ? ` (${client.login})` : client.login;
        } else {
          const manager = managers.get(program.creator_user_id);
          creatorName = (manager.name ?? '') + ' ' + (manager.full_name ?? '');
          if (creatorName === ' ') creatorName = manager.username ?? '';
        }
        program.creatorName = creatorName;
      }

      state.programs = deepFreeze(payload.programs);
      ['cultures', 'categoriesCultures'].forEach(
        (key) => (state[key] = payload[key])
      );
    }
    state.error = null;
    state.isLoading = false;
  },
};

const actions = {
  async [actionTypes.fetchPrograms]({ commit, state, rootState }) {
    commit(mutationTypes.fetchStart);
    try {
      const timestampGeobase = rootState.glossaryGeobase.timestamp;
      const res = await api.getPrograms(state.timestamp, timestampGeobase);
      if (res.status === 'ok') {
        commit(mutationTypes.fetchSuccess, res);
        if (res.geoBase) {
          commit(
            geobaseMutationTypes.getGeobaseSuccess,
            deepFreeze(res.geoBase),
            {
              root: true,
            }
          );
        }
      } else {
        throw Error(res.message || 'Unknown error');
      }
    } catch (err) {
      commit(mutationTypes.fetchFailure, err);
    }
  },
};

export { mutationTypes, actionTypes };

export default {
  state,
  actions,
  mutations,
};
