<!--Translated-->
<template>
  <div class="cwrap">
    <div class="cblock marginbottom--standart cblock--tbl-calcelement">
      <div class="tablewrap tablewrap--analysiselements">
        <table
          class="table--type1 table--elements table--elements--analysescalc"
        >
          <thead>
            <tr>
              <th class="tbl-cellproblem">
                <div class="tbl_info">
                  <div class="tbl_info-wrap">
                    <div v-svg="{ name: 'elements' }"></div>
                    <div class="tbl_info-celltext">
                      {{ $t('FertilizerProgramAnalysis:Elements') }}
                    </div>
                  </div>
                </div>
              </th>
              <th
                class="tbl-element"
                v-for="element in elementsData"
                :key="element.id"
              >
                <div>{{ element.name }}</div>

                <ArrowDropdown
                  v-if="element.unitsData"
                  :options="element.unitsData"
                  :value="element.units_id || 1"
                  @input="onUnitInput(element, $event)"
                  :disabled="element.id === 13 || isSaving"
                />
              </th>
            </tr>
          </thead>

          <tbody>
            <tr class="row">
              <td class="">
                {{ $t('FertilizerProgramAnalysis:AnalyzesResults') }}
              </td>
              <td
                class="tbl-cellinpt"
                v-for="element in elementsData"
                :key="element.id"
              >
                <InputNumber
                  class="inputintable"
                  type="number"
                  placeholder="0.0"
                  label="-"
                  :value="element.quantity"
                  @input="onInput(element, $event)"
                  @focus="ignoreInput = false"
                  :state="isSaving ? 'disabled' : ''"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      ref="savetableheight"
      class="cblock marginbottom--standart"
      :class="{ 'stickied-saveheight': scrolled }"
    >
      <div class="tablewrap tablewrap--analysiselements">
        <table
          v-if="elements.length > 0"
          class="table--type1 table--elements"
          :class="{ stickied: scrolled }"
          :style="isSaving ? 'filter: grayscale(1); opacity: 0.7' : ''"
        >
          <thead>
            <tr>
              <th class="tbl-cellproblem">
                <div class="tbl_info">
                  <div class="tbl_info-wrap">
                    <div v-svg="{ name: 'elements' }"></div>
                    <div class="tbl_info-celltext">
                      {{ $t('FertilizerProgramAnalysis:Elements') }}
                    </div>
                  </div>
                </div>
              </th>
              <th
                class="tbl-element"
                v-for="element in elementsData"
                :key="element.id"
              >
                <div>
                  {{ getOxydeName(element.name) }}
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr class="row">
              <td>{{ $t('FertilizerProgramAnalysis:PlantsNeeds') }}</td>
              <template v-for="element in elementsData">
                <td :key="element.id">
                  {{
                    element.id !== 13 && element.id !== 14
                      ? round(element.demandWithAnalysis)
                      : ''
                  }}
                </td>
              </template>
            </tr>
            <tr class="row">
              <td>{{ $t('FertilizerProgramAnalysis:Results') }}</td>
              <td
                v-for="element in elementsData"
                :key="element.id"
                :class="
                  element.demandWithAnalysis > unProductsByElement[element.id]
                    ? 'cell--bad'
                    : 'cell--good'
                "
              >
                {{
                  unProductsByElement[element.id]
                    ? round(unProductsByElement[element.id])
                    : ''
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { calcDemandWithAnalysis } from '@/pages/programs/fertilizers/analysesData';

import { mutationTypes } from '@/store/modules/clientsArea/nutritionPrograms';
import InputNumber from '@/components/views/wrappers/InputNumberWithCast';
import ArrowDropdown from '@/components/views/ArrowDropdown';
import {
  units,
  elementPossibleUnits,
} from '@/pages/programs/elementMeasureUnits.js';
import { getOxydeName } from '@/pages/programs/fertilizers/analysesData.js';

export default {
  name: 'UserFertilizerProgramAnalysis',
  components: { ArrowDropdown, InputNumber },
  data() {
    return {
      scrolled: false,
      ignoreInput: true,
    };
  },
  computed: {
    ...mapGetters(['unElementsValuesAsMap', 'unProductsByElement']),
    ...mapState({
      isSaving: (state) => state.userNutrition.isSaving,
      elements: (state) => state.userNutrition.elements,
      hasCultureData: (state) => state.userNutrition.cultureData.length > 0,
    }),
    elementsData() {
      const unitsDataList = elementPossibleUnits.map((e) => ({
        ...e,
        units: [units[0], ...e.units].map((unit) => ({
          ...unit,
          ...units.find(({ id }) => id === unit.id),
        })),
      }));

      unitsDataList.push({
        elementId: 13,
        units: [{ id: 1, text: '', multiplier: 1 }],
      });

      unitsDataList.push({
        elementId: 14,
        units: [
          { id: 1, text: 'μS/cm', multiplier: 1 },
          { id: 2, text: 'ms/cm', multiplier: 0.001 },
        ],
      });

      return this.elements.map((element) => {
        const ref = this.unElementsValuesAsMap[element.id];
        return {
          ...element,
          ...ref,
          demandWithAnalysis: calcDemandWithAnalysis(
            element.id,
            ref.quantityMgKg,
            ref.demand
          ),
          unitsData: unitsDataList
            .find(({ elementId }) => elementId === element.id)
            ?.units?.map((u) => ({ ...u, value: u.id })),
        };
      });
    },
    pH() {
      return this.elementsData.find(({ id }) => id === 13)?.quantity ?? null;
    },
    EC() {
      return this.elementsData.find(({ id }) => id === 14)?.quantity ?? null;
    },
  },
  methods: {
    getOxydeName,
    onInput({ id }, quantity) {
      this.$store.commit(mutationTypes.upsertAnalysisElement, {
        element_id: id,
        quantity,
      });
    },
    onUnitInput({ id }, units_id) {
      this.$store.commit(mutationTypes.upsertAnalysisElement, {
        element_id: id,
        units_id,
      });
    },
    handleScroll() {
      const { top } = this.$refs.savetableheight.getBoundingClientRect();
      let { bottom } = document
        .querySelector('.table--period')
        .getBoundingClientRect();
      if (!this.scrolled) bottom -= 120;
      this.scrolled = top < 0 && bottom > 0;
    },
    round(num) {
      return Math.round(num * 10_000) / 10_000;
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    pH() {
      if (!this.ignoreInput) this.$store.commit(mutationTypes.fillAutoProducts);
      this.$store.commit(mutationTypes.updatePlantsPerHaDeps);
    },
    EC() {
      if (!this.ignoreInput) this.$store.commit(mutationTypes.fillAutoProducts);
      this.$store.commit(mutationTypes.updatePlantsPerHaDeps);
    },
  },
};
</script>
