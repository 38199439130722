

<template>
  <!--ПО ФАКТУ ОДНА, НУЖНО НА ОСНОВЕ ОДНОГО-->

  <!-- вредные объекты - Добавить-->
  <div v-if="type === 'add'" class="modal modal--long">
    <div class="modalwrap2-topline">
      <div class="topline-minizone"></div>

      <Breadcrumps :data="[{ title: 'Добавить' }]" />

      <div class="topline-minizone">
        <Button
            text=""
            size="s-standart"
            color="accentlightlines"
            icon="x"
            :is-loading="false"
            :loading-left="true"
            @click="$emit('input', false)"
        />
      </div>
    </div>

    <div class="modalwrap2-content">
      <div class="modalwrap3-inputs margintop--standart marginbottom--standart">
        <Input
            type="dropdown"
            placeholder="Выберите категорию"
            label="Категория"
            icon=""
            state=""
            size=""
            color=""
            :dd-items="ddPestsCategories"
            :dd-selected-index="ddPestsCategorySelectedIndex"
            dd-max-elements="5"
            @selected="filter.pestCategoryId = $event.item.value"
        />

        <Input
            type="dropdownPlus"
            label="Название вредного объекта"
            icon=""
            state=""
            size="long"
            color=""
            autofocus="true"
            dd-max-elements="5"
            :dd-items="ddPestsItems"
            v-model="filter.pestName"
            :dd-disable-click-outside="true"
            @selected="
            selectedItem = {
              pest_id: $event.item.value,
              name: $event.item.text,
            }
          "
            @ddOpen="isDDOpen = true"
            @ddClose="isDDOpen = false"
            placeholder="Выберите вредный объект"
        />
      </div>

      <div class="modalwrap3-buttons">
        <Button
            text="Отмена"
            size="wide"
            color="graylines"
            icon="cancel"
            :is-loading="false"
            :loading-left="true"
            @click="$emit('input', false)"
        />
        <Button
            text="Сохранить"
            size="wide"
            color="accent"
            icon="save"
            :is-loading="false"
            :loading-left="true"
            :disabled="isSaveDisabled"
            @click="onSave"
        />
      </div>
    </div>
  </div>

  <!-- вредные объекты - Редактировать-->
  <div v-else class="modal modal--long">
    <div class="modalwrap2-topline">
      <div class="topline-minizone"></div>

      <Breadcrumps :data="[{ title: 'Редактировать' }]" />

      <div class="topline-minizone">
        <Button
            text=""
            size="s-standart"
            color="accentlightlines"
            icon="x"
            :is-loading="false"
            :loading-left="true"
            @click="$emit('input', false)"
        />
      </div>
    </div>

    <div class="modalwrap2-content">
      <div class="modalwrap3-inputs margintop--standart marginbottom--standart">
        <Input
            type="dropdown"
            placeholder="Выберите категорию"
            label="Категория"
            icon=""
            state=""
            size=""
            color=""
            :dd-items="ddPestsCategories"
            :dd-selected-index="ddPestsCategorySelectedIndex"
            dd-max-elements="5"
            @selected="filter.pestCategoryId = $event.item.value"
        />

        <Input
            type="dropdownPlus"
            placeholder=""
            label="Название вредного объекта"
            icon=""
            state=""
            size="long"
            color=""
            :autofocus="false"
            dd-max-elements="5"
            :dd-items="ddPestsItems"
            v-model="filter.pestName"
            :dd-disable-click-outside="true"
            :dd-selected-index="ddPestsSelectedIndex"
            @selected="
            selectedItem = {
              pest_id: $event.item.value,
              name: $event.item.text,
            }
          "
            @ddOpen="isDDOpen = true"
            @ddClose="isDDOpen = false"
        />
      </div>

      <div class="modalwrap3-buttons">
        <Button
            text="Удалить"
            size="wide"
            color="alertlines"
            icon="delete"
            :is-loading="false"
            :loading-left="true"
            @click="onDeleteClick"
        />
        <Button
            text="Отмена"
            size="wide"
            color="graylines"
            icon="cancel"
            :is-loading="false"
            :loading-left="true"
            @click="$emit('input', false)"
        />
        <Button
            text="Сохранить"
            size="wide"
            color="accent"
            icon="save"
            :is-loading="false"
            :loading-left="true"
            :disabled="isSaveDisabled"
            @click="onSave"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import Breadcrumps from '@/components/views/Breadcrumps';

export default {
  name: 'modalGroceryBadObjectEdit',
  components: { Button, Input, Breadcrumps },
  props: {
    type: {
      default: 'add',
    },
    editCulturePest: {
      default() {
        return {};
      },
    },
    existItems: {
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      ddPestsCategorySelectedIndex: 0,
      ddPestsSelectedIndex: 0,
      filter: {
        pestName: '',
        pestCategoryId: null,
      },
      selectedItem: null,
      isDDOpen: false,
    };
  },
  computed: {
    existItemsSet() {
      let set = new Set();
      if (this.existItems && this.existItems instanceof Array) {
        this.existItems.forEach((item) => {
          set.add(item.pest_id);
        });
      }
      return set;
    },
    ddPestsCategories() {
      let arr = [{ text: 'Не выбрано', value: null }];
      if (this.$store.state.glossaryPests.categoriesPests) {
        this.$store.state.glossaryPests.categoriesPests.forEach((category) => {
          arr.push({ text: category.name, value: category.id });
        });
      }
      return arr;
    },
    ddPestsItems() {
      let arr = [];
      if (this.$store.state.glossaryPests.pests) {
        let filterIsActive = false;
        for (let filterKey in this.filter) {
          if (this.filter[filterKey]) {
            filterIsActive = true;
            break;
          }
        }

        if (!filterIsActive) {
          arr.push({ text: 'Не выбрано', value: null });
        }
        this.$store.state.glossaryPests.pests.forEach((pest) => {
          if (filterIsActive) {
            let filerRes = true;
            if (
                this.filter.pestName &&
                !pest.name
                    .toLowerCase()
                    .includes(this.filter.pestName.toLowerCase())
            ) {
              filerRes = false;
            }

            if (
                filerRes &&
                this.filter.pestCategoryId &&
                this.filter.pestCategoryId !== pest.category_id
            ) {
              filerRes = false;
            }

            if (filerRes) {
              arr.push({ text: pest.name, value: pest.id });
            }
          } else {
            arr.push({ text: pest.name, value: pest.id });
          }
        });
      } else {
        arr.push({ text: 'Не выбрано', value: null });
      }
      arr.forEach((item) => {
        if (this.existItemsSet.has(item.value)) {
          //item.text += " (уже добавлен)"
          item.exists = true;
        }
      });
      return arr;
    },
    isSaveDisabled() {
      if (this.isDDOpen) {
        return true;
      }
      if (this.selectedItem === null || this.selectedItem?.pest_id === null) {
        return true;
      }
      if (this.type !== 'add') {
        if (!this.selectedItem) {
          return true;
        }
        if (this.editCulturePest?.pest_id === this.selectedItem?.pest_id) {
          return true;
        }
      }
      if (this.existItems && this.selectedItem) {
        for (let i = 0; i < this.existItems.length; i++) {
          if (this.existItems[i].pest_id === this.selectedItem.pest_id) {
            return true;
          }
        }
      }
      return false;
    },
  },
  methods: {
    onSave() {
      if (this.type === 'add') {
        this.$emit('addPest', {
          pest_id: this.selectedItem.pest_id,
        });
      } else {
        this.$emit('editPest', {
          pest_id: this.selectedItem.pest_id,
          old_pest_id: this.editCulturePest.pest_id,
        });
      }
      this.$emit('input', false);
    },
    onDeleteClick() {
      this.$emit('deletePest', {
        pest_id: this.editCulturePest.pest_id,
        id: this.editCulturePest.id,
      });
      this.$emit('input', false);
    },
  },
  created() {
    if (this.type !== 'add') {
      let editPestCategory = 0;
      this.$store.state.glossaryPests.pests.forEach((pest) => {
        if (pest.id === this.editCulturePest.pest_id) {
          editPestCategory = pest.category_id;
        }
      });
      this.ddPestsCategories.forEach((pestCategory, index) => {
        if (pestCategory.value === editPestCategory) {
          this.ddPestsCategorySelectedIndex = index;
        }
      });
      this.ddPestsItems.forEach((item, index) => {
        if (item.value === this.editCulturePest.pest_id) {
          this.ddPestsSelectedIndex = index;
        }
      });
    }
  },
};
</script>

<style scoped></style>