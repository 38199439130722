<template>
  <div class="rightzone">
    <div class="cwrap">
      <div class="cblock cblock-icons">
        <Button
          size="wide"
          text="agriculture"
          color="accentlightlines"
          icon="analyzesicon_leaves"
        />
        <Button
          size="wide"
          text="agriculture"
          color="accentlightlines"
          icon="analyzesicon_product"
        />

        <Button
          size="wide"
          text="agriculture"
          color="accentlightlines"
          icon="analyzesicon_soil"
        />
        <Button
          size="wide"
          text="agriculture"
          color="accentlightlines"
          icon="analyzesicon_water"
        />
        <Button
          size="wide"
          text="agriculture"
          color="accentlightlines"
          icon="agriculture"
        />
        <Button
          size="wide"
          text="arrowdown"
          color="accentlightlines"
          icon="arrowdown"
        />
        <Button
          size="wide"
          text="arrowright"
          color="accentlightlines"
          icon="arrowright"
        />
        <Button
          size="wide"
          text="arrowup"
          color="accentlightlines"
          icon="arrowup"
        />
        <Button
          size="wide"
          text="backspace"
          color="accentlightlines"
          icon="backspace"
        />

        <Button size="wide" text="bulb" color="accentlightlines" icon="bulb" />

        <Button
          size="wide"
          text="calendar"
          color="accentlightlines"
          icon="calendar"
        />

        <Button
          size="wide"
          text="cancel"
          color="accentlightlines"
          icon="cancel"
        />

        <Button
          size="wide"
          text="chevrondown"
          color="accentlightlines"
          icon="chevrondown"
        />
        <Button
          size="wide"
          text="chevronleft"
          color="accentlightlines"
          icon="chevronleft"
        />
        <Button
          size="wide"
          text="chevronright"
          color="accentlightlines"
          icon="chevronright"
        />
        <Button
          size="wide"
          text="chevronup"
          color="accentlightlines"
          icon="chevronup"
        />
        <Button
          size="wide"
          text="contactaddress"
          color="accentlightlines"
          icon="contactaddress"
        />
        <Button
          size="wide"
          text="contactfacebook"
          color="accentlightlines"
          icon="contactfacebook"
        />
        <Button
          size="wide"
          text="contactlinked"
          color="accentlightlines"
          icon="contactlinked"
        />

        <Button
          size="wide"
          text="contactlinked"
          color="accentlightlines"
          icon="contactinstagram"
        />

        <Button
          size="wide"
          text="contactlinked"
          color="accentlightlines"
          icon="contactyoutube"
        />

        <Button
          size="wide"
          text="contactmail"
          color="accentlightlines"
          icon="contactmail"
        />

        <Button
          size="wide"
          text="contactphone"
          color="accentlightlines"
          icon="contactphone"
        />

        <Button
          size="wide"
          text="contacts"
          color="accentlightlines"
          icon="contacts"
        />

        <Button
          size="wide"
          text="contactviber"
          color="accentlightlines"
          icon="contactviber"
        />

        <Button
          size="wide"
          text="contactwebsite"
          color="accentlightlines"
          icon="contactwebsite"
        />

        <Button
          size="wide"
          text="contactwhatsapp"
          color="accentlightlines"
          icon="contactwhatsapp"
        />
        <Button size="wide" text="copy" color="accentlightlines" icon="copy" />
        <Button
          size="wide"
          text="default3"
          color="accentlightlines"
          icon="default3"
        />
        <Button
          size="wide"
          text="defaultstar"
          color="accentlightlines"
          icon="defaultstar"
        />
        <Button
          size="wide"
          text="delete"
          color="accentlightlines"
          icon="delete"
        />
        <Button size="wide" text="edit" color="accentlightlines" icon="edit" />

        <Button
          size="wide"
          text="elements"
          color="accentlightlines"
          icon="elements"
        />
        <Button
          size="wide"
          text="elements1"
          color="accentlightlines"
          icon="elements1"
        />
        <Button
          size="wide"
          text="elements3"
          color="accentlightlines"
          icon="elements3"
        />
        <Button size="wide" text="farm" color="accentlightlines" icon="farm" />

        <Button
          size="wide"
          text="fertilizer"
          color="accentlightlines"
          icon="fertilizer"
        />
        <Button size="wide" text="file" color="accentlightlines" icon="file" />
        <Button
          size="wide"
          text="filter"
          color="accentlightlines"
          icon="filter"
        />
        <Button size="wide" text="info" color="accentlightlines" icon="info" />
        <Button size="wide" text="key" color="accentlightlines" icon="key" />
        <Button size="wide" text="link" color="accentlightlines" icon="link" />
        <Button
          size="wide"
          text="linkto"
          color="accentlightlines"
          icon="linkto"
        />

        <Button size="wide" text="map" color="accentlightlines" icon="map" />
        <Button
          size="wide"
          text="password"
          color="accentlightlines"
          icon="password"
        />
        <Button
          size="wide"
          text="passwordhide"
          color="accentlightlines"
          icon="passwordhide"
        />
        <Button
          size="wide"
          text="passwordshow"
          color="accentlightlines"
          icon="passwordshow"
        />
        <Button
          size="wide"
          text="paycheck"
          color="accentlightlines"
          icon="paycheck"
        />

        <Button
          size="wide"
          text="pdffile"
          color="accentlightlines"
          icon="pdffile"
        />
        <Button
          size="wide"
          text="pesticidebacteria"
          color="accentlightlines"
          icon="pesticidebacteria"
        />
        <Button
          size="wide"
          text="pesticidebug"
          color="accentlightlines"
          icon="pesticidebug"
        />
        <Button
          size="wide"
          text="pesticidemite"
          color="accentlightlines"
          icon="pesticidemite"
        />
        <Button
          size="wide"
          text="pesticidemushroom"
          color="accentlightlines"
          icon="pesticidemushroom"
        />
        <Button
          size="wide"
          text="pesticideother"
          color="accentlightlines"
          icon="pesticideother"
        />

        <Button
          size="wide"
          text="pesticideplant"
          color="accentlightlines"
          icon="pesticideplant"
        />
        <Button
          size="wide"
          text="phases_flask"
          color="accentlightlines"
          icon="phases_flask"
        />
        <Button
          size="wide"
          text="phases_flower"
          color="accentlightlines"
          icon="phases_flower"
        />
        <Button
          size="wide"
          text="phases_money"
          color="accentlightlines"
          icon="phases_money"
        />
        <Button
          size="wide"
          text="phases_timetable"
          color="accentlightlines"
          icon="phases_timetable"
        />

        <Button
          size="wide"
          text="photo"
          color="accentlightlines"
          icon="photo"
        />
        <Button size="wide" text="plus" color="accentlightlines" icon="plus" />
        <Button
          size="wide"
          text="plus2"
          color="accentlightlines"
          icon="plus2"
        />
        <Button
          size="wide"
          text="print"
          color="accentlightlines"
          icon="print"
        />
        <Button
          size="wide"
          text="retry"
          color="accentlightlines"
          icon="retry"
        />

        <Button size="wide" text="save" color="accentlightlines" icon="save" />
        <Button
          size="wide"
          text="search"
          color="accentlightlines"
          icon="search"
        />
        <Button
          size="wide"
          text="settings"
          color="accentlightlines"
          icon="settings"
        />
        <Button
          size="wide"
          text="shuffle"
          color="accentlightlines"
          icon="shuffle"
        />
        <Button
          size="wide"
          text="stimulator"
          color="accentlightlines"
          icon="stimulator"
        />

        <Button size="wide" text="tick" color="accentlightlines" icon="tick" />
        <Button
          size="wide"
          text="tick2"
          color="accentlightlines"
          icon="tick2"
        />
        <Button size="wide" text="time" color="accentlightlines" icon="time" />
        <Button size="wide" text="user" color="accentlightlines" icon="user" />
        <Button
          size="wide"
          text="userdelete"
          color="accentlightlines"
          icon="userdelete"
        />

        <Button
          size="wide"
          text="warningdiese"
          color="accentlightlines"
          icon="warningdiese"
        />
        <Button
          size="wide"
          text="warningtriangle"
          color="accentlightlines"
          icon="warningtriangle"
        />
        <Button
          size="wide"
          text="warningtriangle"
          color="accentlightlines"
          icon="waiting"
        />
        <Button size="wide" text="x" color="accentlightlines" icon="x" />

        <Button
          size="wide"
          text="fruitapple"
          color="accentlightlines"
          icon="fruitapple"
        />
        <Button
          size="wide"
          text="fruitcarrot"
          color="accentlightlines"
          icon="fruitcarrot"
        />

        <Button
          size="wide"
          text="fruitcherry"
          color="accentlightlines"
          icon="fruitcherry"
        />
        <Button
          size="wide"
          text="fruitcorn"
          color="accentlightlines"
          icon="fruitcorn"
        />

        <Button
          size="wide"
          text="fruitother"
          color="accentlightlines"
          icon="fruitother"
        />
        <Button
          size="wide"
          text="fruitstrawberry"
          color="accentlightlines"
          icon="fruitstrawberry"
        />

        <Button
          size="wide"
          text="fruitwatermelon"
          color="accentlightlines"
          icon="fruitwatermelon"
        />
        <Button
          size="wide"
          text="fruitwheat"
          color="accentlightlines"
          icon="fruitwheat"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/views/Button';

export default {
  name: 'DemoIcons',
  components: { Button },
  methods: {
    printWindow: function () {
      window.print();
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/vars.scss';

.cblock-icons {
  padding-top: 142px;
  //width: 800px;
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: $marginNano !important;
    justify-content: flex-start !important;

    filter: grayscale(1);

    &:hover {
      filter: grayscale(0) hue-rotate(190deg);
    }
  }
}
</style>
