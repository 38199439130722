<-- Translated -->
<template>
  <Button
    :text="$t('ClientUpdateButton:Text')"
    size="wide"
    color="accentlines"
    icon="retry"
    @click="update"
    :disabled="disabled"
    :is-loading="isUpdating"
    :loading-left="true"
  />
</template>

<script>
import { mapState } from 'vuex';
import { actionTypes } from '@/store/modules/clients';
import Button from '@/components/views/Button';

export default {
  name: 'ClientUpdateButton',
  props: { id: { type: [String, Number] } },
  components: { Button },
  data() {
    return {
      isUpdating: false,
    };
  },
  computed: mapState({
    disabled(state) {
      return (
        state.clients.isLoading || state.clients.isSearching || this.isUpdating
      );
    },
  }),
  methods: {
    update() {
      this.$confirm({
        message:
          this.$t('ClientUpdateButton:Message1') +
          '<br/>' +
          this.$t('ClientUpdateButton:Message2'),
        buttons: {
          left: {
            text: this.$t('ClientUpdateButton:ButtonUpdate'),
            icon: 'tick',
            color: 'accentlines',
          },
          right: {
            text: this.$t('ClientUpdateButton:ButtonCancel'),
            icon: 'cancel',
            color: 'graylines',
          },
        },
        callback: (confirm) => {
          if (confirm) {
            this.isUpdating = true;
            this.$store
              .dispatch(actionTypes.getClientData, {
                id: this.id,
                forceUpdate: true,
              })
              .finally(() => (this.isUpdating = false));
          }
        },
      });
    },
  },
};
</script>
