import axios from '@/api/axios';

export const checkLogin = (payload) => {
  return axios.post('/clientsArea/admin/checkLoginExist', {
    login: payload,
  });
};
export const registration = (payload) => {
  return axios.post('/clientsArea/admin/registration', payload);
};

export const login = (payload) => {
  return axios.post('/clientsArea/admin/checkLogin', payload);
};

export const getMyData = (payload) => {
  return axios.get('/clientsArea/admin/getMyData', {
    params: {
      geoBase: payload?.geoBase ?? 0,
    },
  });
};
export const setMyData = (payload) => {
  return axios.post('/clientsArea/admin/setMyData', payload);
};

export const getBanner = () =>
  axios.get('/clientsArea/banners/getBanner').then((r) => r.data);
