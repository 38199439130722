<template>
  <div class="cwrap cwrap--level1 marginbottom--standart">
    <ClientTableHeader
      :title="title"
      iconName="defence"
      :isShown="isShown"
      @click="isShown = !isShown"
    />

    <div class="cblock el--rounded-bottom" v-show="isShown">
      <Table
        class="cblock__internal-table"
        :headers="headers"
        :dataArr="rows"
        :tableLoading="isLoading"
        :loadingCountItems="10"
        @editClick="editProgram"
        @printClick="printProgram"
      >
      </Table>
      <Button
        class="margintop--standart"
        :text="$t('ClientProgramsTable:ButtonNewText')"
        size="wide"
        color="accentlines"
        icon="plus"
        @click="createProgram"
        :disabled="!clientId"
      />
    </div>
    <PrintModal
      v-if="selectedPrintId"
      @close="selectedPrintId = null"
      :selectedPrintId="selectedPrintId"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { staticData } from '@/store/modules/plantsCare';
import routeNames from '@/router/routeNames';

import Button from '@/components/views/Button';
import Table from '@/components/tables/Table';
import ClientTableHeader from './ClientTableHeader';
import PrintModal from '@/components/modals/ModalPrint';

export default {
  name: 'ClientProgramsTable',
  components: { Button, Table, ClientTableHeader, PrintModal },
  data() {
    return { isShown: false, selectedPrintId: null };
  },
  computed: {
    ...mapGetters(['geoPlainDataAsMap']),
    ...mapState({
      isLoading: (state) => state.clients.isLoading,
      canEdit: (state) =>
        state.auth.currentUserRole?.claim_plants_care_add === 1,
      programs: (state) => state.clients.clientPlantsCarePrograms ?? [],
      cultures: (state) => state.clients.cultures,
      clientId: (state) => state.clients.client?.id ?? null,
      clientName: (state) => state.clients.client?.name ?? '',
    }),
    headers() {
      return [
        {
          text: this.$t('DefensePrograms:TableHeaders:1'), //'Название',
          type: 'text',
          width: '44%',
        },
        {
          text: this.$t('DefensePrograms:TableHeaders:2'), //'Культура',
          type: 'text',
          width: '13%',
        },
        {
          text: this.$t('DefensePrograms:TableHeaders:3'), //'Возраст',
          type: 'text',
          width: '10%',
        },
        {
          text: this.$t('DefensePrograms:TableHeaders:4'), //'Метод',
          type: 'text',
          width: '10%',
        },
        {
          text: this.$t('DefensePrograms:TableHeaders:5'), //'Место',
          type: 'text',
          width: '13%',
        },
        {
          text: this.$t('DefensePrograms:TableHeaders:6'), //'Площадь',
          type: 'text',
          width: '10%',
        },
        {
          class: 'col-edit unicol-colordark',
          type: 'print',
          icon: 'print',
        },
        {
          class: 'col-edit',
          type: 'edit',
          icon: this.canEdit ? 'edit' : 'passwordshow',
        },
      ];
    },
    rows() {
      return this.programs.map((p) =>
        [
          p.name,
          this.cultures.find(({ id }) => p.culture_id === id)?.name ?? '',
          p.culture_age,
          staticData.calculationMethodsShort[p.calculation_method_id],
          this.geoPlainDataAsMap.get(p.geo_id)?.name,
          p.place_area,
          p.id,
          p.id,
        ].map((text) => ({ text }))
      );
    },
    title() {
      return `${this.clientName} - ${this.$t('ClientProgramsTable:Title')} (${
        this.programs.length
      })`;
    },
  },
  methods: {
    editProgram(id) {
      const routeData = this.$router.resolve({
        name: routeNames.editDefenseProgram,
        params: { id },
      });
      window.open(routeData.href, '_blank');
    },
    printProgram(id) {
      this.selectedPrintId = id;
    },
    createProgram() {
      const routeData = this.$router.resolve({
        name: routeNames.newDefenseProgram,
        query: { forClient: this.clientId },
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
