<!--Translated-->
<template>
  <tbody>
    <tr
      class="row tbl_row--active"
      v-for="(product, index) in products"
      :key="product.id"
    >
      <td class="tbl-cellproblem" v-if="index === 0" :rowspan="products.length">
        <div class="tbl_info">
          <div class="tbl_info-wrap">
            <div v-svg="{ name: 'info' }"></div>
            <HoverTooltip :header="sType.name" :text="sType.description" />
          </div>
          <div class="tbl_info-celltext">{{ sType.name }}</div>
        </div>
      </td>

      <td
        class="tbl-cellproduct"
        :class="{ 'tbl-cellproduct--grey': !productsAsMap[product.id] }"
      >
        <div class="tbl_info">
          <div class="tbl_info-wrap">
            <div
              v-if="product.is_active"
              v-svg="{ name: 'info' }"
              :key="'active'"
            />
            <div
              v-else
              v-svg="{ name: 'warningtriangle' }"
              :key="'no_active'"
              id="force-red"
            />
            <HoverTooltipProduct :product="product" />
          </div>
          <div class="tbl_info-celltext">{{ product.name }}</div>
        </div>
      </td>
      <td class="tbl-cellinpt">
        <InputNumber
          class="inputintable"
          label="-"
          :value="productsAsMap[product.id]"
          @input="onInput(product, $event)"
          :state="isSaving ? 'disabled' : readonly ? 'disabled2' : ''"
        />
      </td>
      <td class="tbl-cellprice">
        {{
          product.picking
            ? (
                (product.price / product.picking) *
                productsAsMap[product.id]
              ).toFixed(2)
            : '0.00'
        }}
      </td>
    </tr>

    <tr class="row tbl_row--active" v-if="products.length === 0">
      <td class="tbl-cellproblem" rowspan="1">
        <div class="tbl_info">
          <div class="tbl_info-wrap">
            <div v-svg="{ name: 'info' }"></div>
            <HoverTooltip :header="sType.name" :text="sType.description" />
          </div>
          <div class="tbl_info-celltext">{{ sType.name }}</div>
        </div>
      </td>
      <td class="tbl-cellproduct" colspan="3" style="text-align: center">
        {{ $t('TemplateRowStimulationType:NoProducts') }}
      </td>
    </tr>

    <tr class="tablelinedecor">
      <td colspan="4"></td>
    </tr>
  </tbody>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { mutationTypes } from '@/store/modules/plantsCare';
import InputNumber from '@/components/views/wrappers/InputNumberWithCast';
import HoverTooltip from './HoverTooltip';
import HoverTooltipProduct from './HoverTooltipProduct';

export default {
  name: 'TemplateRowStimulationType',
  props: {
    phase: { type: Object, required: true },
    sType: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
  },
  components: { InputNumber, HoverTooltip, HoverTooltipProduct },
  computed: {
    ...mapGetters(['plantsCareProductsAsMap', 'plantsCareSTypesProductsAsMap']),
    ...mapState({
      isSaving: (state) => state.plantsCare.isSaving,
      products(state) {
        return state.plantsCare.productsTypesStimulation
          .filter(
            ({ type_stimulation_id }) => type_stimulation_id === this.sType.id
          )
          .map(({ product_id }) => this.plantsCareProductsAsMap[product_id])
          .filter(Boolean)
          .filter(
            (product) =>
              product.is_active ||
              state.plantsCare.templateTypesStimulationProducts.some(
                ({ product_id }) => product_id === product.id
              )
          );
      },
    }),
    productsAsMap() {
      const getValueById = (id) =>
        this.plantsCareSTypesProductsAsMap[
          `phase_${this.phase.id}_stype_${this.sType.id}_product_${id}`
        ]?.quantity ?? '';
      return Object.fromEntries(
        this.products.map((p) => [p.id, getValueById(p.id)])
      );
    },
  },
  methods: {
    onInput(product, value) {
      this.$store.commit(mutationTypes.upsertStimulationType, {
        phase_id: this.phase.id,
        type_stimulation_id: this.sType.id,
        product_id: product.id,
        quantity: value,
      });
    },
  },
};
</script>
