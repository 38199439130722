<-- Translated -->
<template>
  <div class="modalwrap1 modalwrap1--newpassword">
    <div class="modalwrap2-topline">
      <div class="topline-minizone">
        <Button
          text=""
          size="s-standart"
          color="accentlightlines"
          icon="chevronleft"
          :is-loading="false"
          :loading-left="true"
          @click="logout"
        />
      </div>

      <Breadcrumps :data="[{ title: $t('LoginNewPassword:Title') }]" />

      <div class="topline-minizone"></div>
    </div>

    <form class="modalwrap2-content" method="get" @submit.prevent="onSave">
      <div class="modalwrap3-text">
        <p>
          {{ $t('LoginNewPassword:MainText') }}
        </p>
      </div>

      <div class="modalwrap3-inputs">
        <Input
          class="marginbottom--mini"
          type="password"
          :placeholder="$t('LoginNewPassword:NewPassword1')"
          label="-"
          icon="password"
          :state="isLoading ? 'disabled' : validationError ? 'error' : ''"
          size="max"
          color=""
          :show-pass="showPass"
          @showPass="showPass = $event"
          v-model="password"
          @input="validationError = false"
        />

        <Input
          class="marginbottom--mini"
          type="password"
          :placeholder="$t('LoginNewPassword:NewPassword2')"
          label="-"
          icon="password"
          :state="isLoading ? 'disabled' : validationError ? 'error' : ''"
          size="max"
          color=""
          :show-pass="showPass"
          @showPass="showPass = $event"
          v-model="repeatPassword"
          @input="validationError = false"
        />
      </div>

      <div class="modalwrap3-buttons">
        <Button
          class="margintop--standart"
          :text="$t('LoginNewPassword:SaveButton')"
          size="max"
          color="accent"
          icon="save"
          :is-loading="isLoading"
          :loading-left="true"
        />
      </div>
    </form>
  </div>
</template>

<script>
import Button from '@/components/views/Button';
import Input from '@/components/views/Input';
import Breadcrumps from '@/components/views/Breadcrumps';
import { actionTypes } from '@/store/modules/auth';
import { mapState } from 'vuex';
export default {
  name: 'LoginNewPassword',
  components: {
    Breadcrumps,
    Input,
    Button,
  },
  data() {
    return {
      validationError: false,
      showPass: false,
      password: '',
      repeatPassword: '',
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.auth.isSubmitting,
    }),
  },
  methods: {
    logout() {
      this.$store.dispatch(actionTypes.logout);
    },
    onSave() {
      if (this.password !== this.repeatPassword) {
        this.validationError = true;
        this.$awn.alert(this.$t('LoginNewPassword:ErrorPasswordNotEqual'));
        return;
      }
      if (this.password.length < 5) {
        this.validationError = true;
        this.$awn.alert(this.$t('LoginNewPassword:ErrorPasswordLength'));
        return;
      }
      this.$store.dispatch(actionTypes.setPassword, {
        password: this.password,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
