<!--Translated-->
<template>
  <article class="rightzone rightzone--article">
    <div class="rightzone_top">
      <Breadcrumps :data="breadcrumpsArr" />
    </div>

    <LoaderBlock v-if="isLoading" />
    <template v-else>
      <!-- П.1 Анализ почвы-->
      <div
        class="cwrap artclwrap el--borders-all el--rounded-all marginbottom--standart"
      >
        <div class="artclhead">
          <div class="artclhead-num marginright--micro">01.</div>
          <div class="artclhead-text">აიღეთ ნიმუში</div>
        </div>

        <div class="artclblock">
          <div v-if="block0.link_youtube" class="artclblock-media">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                class="embed-responsive-item"
                :src="block0.link_youtube"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <div
            v-if="block0.text"
            class="artclblock-text artclblock-textWYSIWYG"
          >
            <p v-html="block0.text"></p>
          </div>

          <!--Блок Файл ПДФ-->

          <div v-if="block0.files.length > 0" class="margintop--standart">
            <ComponentFile
              v-for="(file, index) in block0.files"
              :key="index"
              :file="file"
            />
          </div>
        </div>
      </div>

      <!-- П.2 Маркировка почвы -->
      <div
        class="cwrap artclwrap el--borders-all el--rounded-all marginbottom--standart"
      >
        <div class="artclhead">
          <div class="artclhead-num marginright--micro">02.</div>
          <div class="artclhead-text">ნიმუშის ეტიკეტირება</div>
        </div>

        <div class="artclblock">
          <div v-if="block1.link_youtube" class="artclblock-media">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                class="embed-responsive-item"
                :src="block1.link_youtube"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <div
            v-if="block1.text"
            class="artclblock-text artclblock-textWYSIWYG"
          >
            <p v-html="block1.text"></p>
          </div>

          <!--Блок Файл ПДФ-->

          <div v-if="block1.files.length > 0" class="margintop--standart">
            <ComponentFile
              v-for="(file, index) in block1.files"
              :key="index"
              :file="file"
            />
          </div>
        </div>
      </div>

      <!--Блок Гео-->
      <GuideAnalysisGeo />

      <!--Блок Отслеживайте-->
      <GuideAnalysisTrack />

      <!--Футтер-->
      <UserFooter />
    </template>
  </article>
</template>

<script>
import Breadcrumps from '@/components/views/Breadcrumps';
import UserFooter from '@/pages/userproject/userComponents/UserFooter';
import LocationSelect from '@/components/views/LocationSelect';
import Button from '@/components/views/Button';
import GuideAnalysisGeo from '@/pages/userproject/userArticles/Components/GuideAnalysisGeo';
import GuideAnalysisTrack from '@/pages/userproject/userArticles/Components/GuideAnalysisTrack';
import ComponentFile from '@/pages/userproject/userArticles/Components/ComponentFile';
import routeNames from '@/router/routeNames';
import { mapGetters } from 'vuex';
import clientInstructions from '@/store/modules/clientsArea/instructions';
import LoaderBlock from '@/components/blocks/LoaderBlock';

export default {
  name: 'UserAnalysesInstruction2',
  components: {
    LoaderBlock,
    ComponentFile,
    GuideAnalysisTrack,
    GuideAnalysisGeo,
    LocationSelect,
    UserFooter,
    Breadcrumps,
    Button,
  },
  data() {
    return {
      block0: {
        id: null,
        link_youtube: null,
        text: null,
        link_image: null,
        files: [],
      },
      block1: {
        id: null,
        link_youtube: null,
        text: null,
        link_image: null,
        files: [],
      },
    };
  },
  computed: {
    ...mapGetters(['analyzesClientTypesMapById']),
    breadcrumpsArr() {
      return [
        {
          title: this.$t('UserAnalysesInstruction:Title'),
          route: routeNames.userAnalyzes,
        },
        {
          title:
            this.$t('UserAnalysesInstruction:Title2') +
            ' - ' +
            this.analyzesClientTypesMapById.has(this.$route.params.id)
              ? this.analyzesClientTypesMapById.get(this.$route.params.id)
                  ?.title ?? ''
              : '',
        },
      ];
    },
    isLoading() {
      return this.$store.state.clientInstructions.isInstructionsLoading;
    },
    instructions() {
      return this.$store.state.clientInstructions.instructions;
    },
    instructionsFilesLinks() {
      return this.$store.state.clientInstructions.instructionsFilesLinks;
    },
  },
  methods: {
    setDataForBlock(blockNum) {
      if (this.$store.state.clientInstructions.instructions) {
        let instr = this.$store.state.clientInstructions.instructions.find(
          (v) => {
            return (
              v.category_id === Number(this.$route.params.id) &&
              v.block_id === blockNum
            );
          }
        );
        if (instr) {
          this['block' + blockNum].id = instr?.id;
          this['block' + blockNum].link_image = instr?.link_image;
          this['block' + blockNum].link_youtube = instr?.link_youtube;
          this['block' + blockNum].text = instr?.text;
          this['block' + blockNum].files.splice(
            0,
            this['block' + blockNum].files.length
          );
        }
        if (
          this['block' + blockNum].id &&
          this.$store.state.clientInstructions.instructionsFilesLinks
        ) {
          this.$store.state.clientInstructions.instructionsFilesLinks.forEach(
            (v) => {
              if (v.instruction_id === this['block' + blockNum].id) {
                this['block' + blockNum].files.push(v);
              }
            }
          );
        }
      }
    },
  },
  watch: {
    instructions: {
      handler(val) {
        this.setDataForBlock(0);
        this.setDataForBlock(1);
      },
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/vars.scss';
</style>
