<template>
  <div class="breadhead">
    <div
      v-for="(obj, index) in data"
      :key="index"
      class="breadhead_sub"
      @click="onClick(index)"
    >
      <!-- <svg
        width="6"
        height="12"
        viewBox="0 0 6 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="svgicon"
          d="M6 6L4.64275e-07 11.1962L9.18537e-07 0.803848L6 6Z"
        />
      </svg>-->

      <div
        class="vsvg vsvg--standart chead_icon"
        v-svg="{ name: 'arrowright' }"
      ></div>

      <span>
        {{ obj.title }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppBreadcrumps',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onClick(index) {
      if (this.data && this.data[index] && this.data[index].route) {
        try {
          this.$router.push({
            name: this.data[index].route,
            params: this.data[index].params ?? {},
          });
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/vars.scss';

.breadhead {
  display: flex;
  height: $heightStandart;
  border-top: 1px solid $colorLinesAccentLight;
  border-bottom: 1px solid $colorLinesAccentLight;
  padding-left: $marginStandart;
  padding-right: $marginStandart;
  box-sizing: border-box;
  user-select: none;
}

.breadhead_sub {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fontsizeSubhead;
  line-height: $lineFontsizeSubhead;

  & > div {
    transform: translateY(1px);
    margin-left: $marginMicro;
    margin-right: $marginMicro - 3px;
    width: $iconSizeMicro - 0px;
    height: $iconSizeMicro - 0px;

    & svg {
      width: inherit;
      height: inherit;
    }
    & svg * {
      fill: $colorGray;
    }
  }

  & > span {
    cursor: pointer;
    color: $colorTextGray;
    font-weight: $fontNormal;
  }

  &:hover {
    & span {
      color: $colorTextAccent;
    }
  }

  &:first-child {
    & > div {
      display: none;
    }
  }

  &:last-child {
    & > span {
      cursor: auto;
      color: $colorTextAccent;
    }
  }
}

@media screen and (max-width: 1800px) {
  .breadhead {
    height: $heightStandart-k1600;
    padding-left: $marginStandart-k1600;
    padding-right: $marginStandart-k1600;
  }

  .breadhead_sub {
    font-size: $fontsizeSubhead-k1600;
    line-height: $lineFontsizeSubhead-k1600;

    & > div {
      transform: translateY(1px);
      margin-left: $marginMicro-k1600;
      margin-right: $marginMicro-k1600 - 3px;
      width: $iconSizeMicro-k1600 - 0px;
      height: $iconSizeMicro-k1600 - 0px;
    }
  }
}

@media screen and (max-width: 1420px) {
  .breadhead {
    height: $heightStandart-k1360;
    padding-left: $marginStandart-k1360;
    padding-right: $marginStandart-k1360;
  }

  .breadhead_sub {
    font-size: $fontsizeSubhead-k1360;
    line-height: $lineFontsizeSubhead-k1360;

    & > div {
      transform: translateY(1px);
      margin-left: $marginMicro-k1360;
      margin-right: $marginMicro-k1360 - 3px;
      width: $iconSizeMicro-k1360 - 0px;
      height: $iconSizeMicro-k1360 - 0px;
    }
  }
}
</style>
