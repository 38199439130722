<!-- Translated -->
<template>
  <article class="rightzone">
    <div class="rightzone_top">
      <Breadcrumps :data="[{ title: this.$t('GlossaryCultures:Title') }]" />

      <div class="rightzone_top-buttonswrap">
        <Button
          v-if="isShowAddButton"
          :text="$t('GlossaryCultures:ButtonAdd')"
          size="standart"
          color="accent"
          icon="plus"
          :is-loading="false"
          :loading-left="false"
          @click="$router.push({ name: 'NewCulture' })"
        />
      </div>
    </div>

    <div class="rightzone_block">
      <FilterGlossaryCulture v-model="filter" />
    </div>

    <div class="rightzone_table">
      <Table
        :headers="tableHeaders"
        :data-arr="dataArr"
        :table-loading="isTableLoading"
        :loading-count-items="8"
        @editClick="onEditClick"
      />
    </div>
  </article>
</template>

<script>
import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';
import FilterGlossaryCulture from '@/components/filtres/FilterGlossaryCulture';
import routeNames from '@/router/routeNames';
import Table from '@/components/tables/Table';
import { mapState } from 'vuex';
import { mutationTypes } from '@/store/modules/filtersState';

export default {
  name: 'GlossaryCultures',
  components: {
    FilterGlossaryCulture,
    Breadcrumps,
    Button,
    Table,
  },
  methods: {
    onEditClick(culturesId) {
      let culture =
        this.$store.state.glossaryCultures.culturesMapById.get(culturesId);
      if (culture) {
        this.$router.push({
          name: routeNames.editCulture,
          params: { id: culture.id },
        });
      }
    },
  },
  data() {
    return {
      filter: {
        name: '',
        category_id: '',
      },
    };
  },
  computed: {
    ...mapState({
      canEdit: (state) => state.auth.currentUserRole?.claim_glossary_edit === 1,
    }),
    tableHeaders() {
      return [
        {
          text: this.$t('GlossaryCultures:TableHeaders:0'), //'Название',
          class: 'col--accent',
          width: '60%',
          type: 'text',
        } /*
        {
          text: this.$t('GlossaryCultures:TableHeaders:1'), //'Описание',
          class: 'col--roledescription',
          width: '60%',
          type: 'text',
        },*/,
        {
          text: this.$t('GlossaryCultures:TableHeaders:2'), //'Категория',
          class: 'col-roledescription',
          width: '40%',
          type: 'text',
        },
        // {
        //   text: this.$t('GlossaryCultures:TableHeaders:3'), //'Статус',
        //   class: 'col-status',
        //   width: '',
        //   type: 'status',
        // },
        {
          text: '',
          class: 'col-edit',
          width: '',
          type: 'edit',
          icon: this.canEdit ? 'edit' : 'passwordshow',
        },
      ];
    },
    isTableLoading() {
      return this.$store.state.glossaryCultures.isCulturesLoading;
    },
    dataArr() {
      let arr = [];

      if (this.$store.state.glossaryCultures.cultures) {
        this.$store.state.glossaryCultures.cultures.forEach((culture) => {
          let tr = [];
          tr.push({ text: culture.name ? culture.name : '' });
          /*tr.push({ text: culture.description ? culture.description : '' });*/
          if (
            this.$store.state.glossaryCategoriesCultures
              .categoriesCulturesMapById !== null &&
            this.$store.state.glossaryCategoriesCultures.categoriesCulturesMapById.has(
              culture.category_id
            )
          ) {
            tr.push({
              text: this.$store.state.glossaryCategoriesCultures.categoriesCulturesMapById.get(
                culture.category_id
              ).name
                ? this.$store.state.glossaryCategoriesCultures.categoriesCulturesMapById.get(
                    culture.category_id
                  ).name
                : '',
            });
          } else {
            tr.push({ text: culture.category_id ? culture.category_id : '' });
          }
          // tr.push({ text: culture.is_active === 1 ? 'on' : 'off' });
          tr.push({ text: culture.id ? culture.id : '' });

          let filterIsActive = false;
          for (let filterKey in this.filter) {
            if (this.filter[filterKey]) {
              filterIsActive = true;
              break;
            }
          }
          if (filterIsActive) {
            let filerRes = true;
            if (this.filter.name) {
              if (
                !`${culture.name}`
                  .toLowerCase()
                  .includes(this.filter.name.toLowerCase()) &&
                !`${culture.description || ''}`
                  .toLowerCase()
                  .includes(this.filter.name.toLowerCase())
              ) {
                filerRes = false;
              }
            }
            if (this.filter.category_id) {
              if (this.filter.category_id !== culture.category_id) {
                filerRes = false;
              }
            }
            if (filerRes) {
              arr.push(tr);
            }
          } else {
            arr.push(tr);
          }
        });
      }
      return arr;
    },
    isShowAddButton() {
      return (
        this.$store.state.auth?.currentUserRole?.claim_glossary_edit || false
      );
    },
  },
  watch: {
    filter: {
      handler(val) {
        this.$store.commit(mutationTypes.setFilterCultures, { ...val });
      },
      deep: true,
    },
  },
  created() {
    for (let filterKey in this.filter) {
      this.filter[filterKey] =
        this.$store.state.filtersState.filterCultures[filterKey];
    }
    window.scrollTo(0, 0);
  },
};
</script>
