<-- Translated -->
<template>
  <!--УДАЛИТЬ КОНТАКТ-->
  <div class="modal modal--long">
    <div class="modalwrap2-topline">
      <div class="topline-minizone"></div>

      <Breadcrumps :data="[{ title: $t('ModalContactEdit:Title') }]" />

      <div class="topline-minizone">
        <Button
          text=""
          size="s-standart"
          color="accentlightlines"
          icon="x"
          :is-loading="false"
          :loading-left="true"
          @click="$emit('input', false)"
        />
      </div>
    </div>

    <div class="modalwrap2-content">
      <div class="modalwrap3-inputs margintop--standart marginbottom--standart">
        <Input
          type="dropdown"
          :placeholder="$t('ModalContactEdit:ContactType.Placeholder')"
          :label="$t('ModalContactEdit:ContactType.Label')"
          icon=""
          state=""
          size=""
          color=""
          :dd-items="contactTypesDDItems"
          :dd-selected-index="selectedContactTypeIndex"
          @selected="contactData.contact_type_id = $event.item.value"
          dd-max-elements="6"
        />
        <Input
          type="text"
          :placeholder="$t('ModalContactEdit:Value.Placeholder')"
          :label="$t('ModalContactEdit:Value.Label')"
          icon=""
          state=""
          size="long"
          color=""
          v-model="contactData.value"
        />
      </div>
      <div class="modalwrap3-inputs marginbottom--standart">
        <Input
          type="text"
          :placeholder="$t('ModalContactEdit:Comment.Placeholder')"
          :label="$t('ModalContactEdit:Comment.Label')"
          icon=""
          state=""
          size="max"
          color=""
          v-model="contactData.comment"
        />
      </div>

      <div class="modalwrap3-buttons">
        <Button
          :text="$t('ModalContactEdit:ButtonDelete')"
          size="wide"
          color="alertlines"
          icon="delete"
          :is-loading="false"
          :loading-left="true"
          @click="onDeleteClick"
        />

        <Button
          :text="$t('ModalContactEdit:ButtonCancel')"
          size="wide"
          color="graylines"
          icon="cancel"
          :is-loading="false"
          :loading-left="true"
          @click="$emit('input', false)"
        />
        <Button
          :text="$t('ModalContactEdit:ButtonSave')"
          size="wide"
          color="accent"
          icon="save"
          :is-loading="false"
          :loading-left="true"
          :disabled="hasChanges"
          @click="onSaveClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/views/Input';
import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';

export default {
  name: 'ModalContactEdit',
  components: { Input, Button, Breadcrumps },
  props: {
    value: {
      default: false,
    },
    contact: {
      default: {},
    },
  },
  data() {
    return {
      contactData: { ...this.contact },
      selectedContactTypeIndex: 0,
    };
  },
  methods: {
    onSaveClick() {
      this.$emit('onSave', this.contactData);
      this.$emit('input', false);
    },
    onDeleteClick() {
      this.$emit('onDelete', this.contactData);
      this.$emit('input', false);
    },
  },
  computed: {
    contactTypesDDItems() {
      let arr = [];
      if (this.$store.state.admin.contactTypes) {
        this.$store.state.admin.contactTypes.forEach((item, index) => {
          arr.push({
            text: item.name || item.name_ru,
            value: item.id,
            icon: item.icon,
          });
          if (item.id == this.contactData.contact_type_id) {
            this.selectedContactTypeIndex = index;
          }
        });
      }
      return arr;
    },
    hasChanges() {
      let res = true;
      for (const key in this.contact) {
        if (
          // eslint-disable-next-line
          this.contactData.hasOwnProperty(key) &&
          this.contactData[key] !== this.contact[key]
        ) {
          res = false;
          break;
        }
      }
      return res;
    },
  },
};
</script>

<style lang="scss" scoped></style>
