import i18n from '@/i18n';

const state = {
  /*Вредные объекты*/
  categoriesPests: [
    { id: 1, name: i18n.t('Vuex:glossaryPests:categoriesPests:1') }, //'Бактериальный' },
    { id: 2, name: i18n.t('Vuex:glossaryPests:categoriesPests:2') }, //'Насекомые' },
    { id: 3, name: i18n.t('Vuex:glossaryPests:categoriesPests:3') }, //'Сорняки' },
    { id: 4, name: i18n.t('Vuex:glossaryPests:categoriesPests:4') }, //'Грибы' },
    { id: 5, name: i18n.t('Vuex:glossaryPests:categoriesPests:5') }, //'Клещи' },
    { id: 6, name: i18n.t('Vuex:glossaryPests:categoriesPests:6') }, //'Другое' },
  ],
  categoriesPestsMapById: new Map(),
  pests: null,
  pestsMapById: new Map(),
};
export const mutationTypes = {
  setPestsSuccess: '[glossary] setElements success',
};
export const actionTypes = {};
const mutations = {
  [mutationTypes.setPestsSuccess](state, payload) {
    state.pests = payload.pests;
    processPests(state);
  },
};
function processPests(state) {
  state.pestsMapById = new Map();
  if (state.pestsMapById && state.pests instanceof Array) {
    state.pests.forEach((pest) => {
      state.pestsMapById.set(pest.id, pest);
    });
  }
  if (state.categoriesPestsMapById.size === 0) {
    state.categoriesPests.forEach((category) => {
      state.categoriesPestsMapById.set(category.id, category);
    });
  }
}

const actions = {};
export default {
  state,
  actions,
  mutations,
};
