<template>
  <article>
    <!--Прикрепленный ПДФ-->
    <div class="cwrap artclwrap artclwrap--file">
      <!--переменная ссылка на файл-->
      <a v-bind:href="file.link || ''" class="artclhead" target="_blank">
        <div class="artclhead-num marginright--standart">
          <!--переменная иконка дефолтная-->
          <div
            class="vsvg"
            v-svg="{ name: file.type === 1 ? 'filedocx' : 'filepdf' }"
          />

          <!--переменная иконка pdf
          <div class="vsvg"
               v-svg="{ name: 'filepdf' }"
          >

          </div>-->

          <!--переменная иконка docx
          <div class="vsvg"
               v-svg="{ name: 'filedocx' }"
          >

          </div>-->
        </div>
        <div class="artclhead-text">
          <!--переменное название файла-->
          {{ file.title || 'attach_file' }}
        </div>
      </a>
    </div>
  </article>
</template>

<script>
import Input from '@/components/views/Input';
import Button from '@/components/views/Button';

export default {
  name: 'ComponentFile',
  components: { Button, Input },
  props: {
    file: {
      default() {
        return {};
      },
    },
  },
  computed: {},
};
</script>

<style scoped></style>
