import Vue from 'vue';

const observer = new IntersectionObserver(
  ([e]) => {
    if (e.isIntersecting) {
      e.target.classList.add('is-pinned');
    } else {
      e.target.classList.remove('is-pinned');
    }
  },
  { threshold: [0, 1], rootMargin: '0px 0px -100% 0px' }
);
Vue.directive('sticky', {
  bind: function (el) {
    observer.observe(el);
  },
  unbind: function (el) {
    observer.unobserve(el);
  },
});
