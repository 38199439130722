import deepFreeze from 'deep-freeze';

import api from '@/api/clientsArea/programs';
import { mutationTypes as geobaseMutationTypes } from '../glossaryGeobase';

const createState = () => ({
  timestamp: 0,
  isLoading: false,
  error: null,
  programs: [],
  categoriesCultures: [],
  cultures: [],
});

const state = createState();

const mutationTypes = {
  fetchStart: '[ud plants care pl] programs fetch start',
  fetchSuccess: '[ud plants care pl] programs fetch success',
  fetchFailure: '[ud plants care pl] programs fetch failure',
};

const actionTypes = {
  fetchPrograms: '[ud plants care pl] fetch programs',
};

const mutations = {
  [mutationTypes.fetchStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.fetchFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },
  [mutationTypes.fetchSuccess](state, payload) {
    state.timestamp = new Date().getTime();
    if (payload.programs) state.programs = payload.programs;
    ['cultures', 'categoriesCultures'].forEach(
      (key) => (state[key] = payload[key])
    );
    state.error = null;
    state.isLoading = false;
  },
};

const actions = {
  async [actionTypes.fetchPrograms]({ commit, state, rootState }) {
    commit(mutationTypes.fetchStart);
    try {
      const timestampGeobase = rootState.glossaryGeobase.timestamp;
      const res = await api.getPrograms(state.timestamp, timestampGeobase);
      if (res.status === 'ok') {
        commit(mutationTypes.fetchSuccess, deepFreeze(res));
        if (res.geoBase) {
          commit(
            geobaseMutationTypes.getGeobaseSuccess,
            deepFreeze(res.geoBase),
            {
              root: true,
            }
          );
        }
      } else {
        throw Error(res.message || 'Unknown error');
      }
    } catch (err) {
      commit(mutationTypes.fetchFailure, err);
    }
  },
};

export { mutationTypes, actionTypes };

export default {
  state,
  actions,
  mutations,
};
