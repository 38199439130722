import productsApi from '@/api/products';
import i18n from '@/i18n';

const state = {
  isProductsLoading: false,
  isProductsLoadingHasError: false,
  products: null,
  productsFertilizers: null,
  productsTypesStimulation: null,
  productsFertilizersSetMapByProductId: null,
  productsTypesStimulationSetMapByProductId: null,
  manufacturers: null,
  manufacturersCountries: null,
  productsDateCache: 0,
  productsCategories: [
    { id: 0, name: i18n.t('Vuex:products:productsCategories:0') }, //'Пестицид' },
    { id: 1, name: i18n.t('Vuex:products:productsCategories:1') }, //'Стимулятор' },
    { id: 2, name: i18n.t('Vuex:products:productsCategories:2') }, //name: 'Удобрение' },
  ],
  productsPestsSubcategories: [
    {
      name: i18n.t('Vuex:products:productsPestsSubcategories:0'), //'Фунгициды',
      id: 0,
      icon: 'pesticidemushroom',
      class: 'pesticide-fungicides',
    },
    {
      name: i18n.t('Vuex:products:productsPestsSubcategories:1'), //'Инсектициды',
      id: 1,
      icon: 'pesticidebug',
      class: 'pesticide-insecticides',
    },
    {
      name: i18n.t('Vuex:products:productsPestsSubcategories:2'), //'Акарициды',
      id: 2,
      icon: 'pesticidemite',
      class: 'pesticide-acaricides',
    },
    {
      name: i18n.t('Vuex:products:productsPestsSubcategories:3'), //'Гербициды',
      id: 3,
      icon: 'pesticideplant',
      class: 'pesticide-herbicides',
    },
    {
      name: i18n.t('Vuex:products:productsPestsSubcategories:4'), //'Бактерициды',
      id: 4,
      icon: 'pesticidebacteria',
      class: 'pesticide-bactericides',
    },
    {
      name: i18n.t('Vuex:products:productsPestsSubcategories:5'), //'Другой',
      id: 5,
      icon: 'pesticideother',
      class: 'pesticide-other',
    },
  ],
  productsFertilizersSubcategories: [
    { name: 'N აზოტიანი', nameRu: 'Азотный N', id: 0 },
    { name: 'P ფოსფორიანი', nameRu: 'P Фосфорный', id: 1 },
    { name: 'K კალიუმიანი', nameRu: 'Калийные K', id: 2 },
    { name: 'Ca კალციუმიანი', nameRu: 'Кальций Ca', id: 3 },
    { name: 'Mg მაგნიუმიანი', nameRu: 'Магний Mg', id: 4 },
    { name: 'S გოგირდშემცველი', nameRu: 'Сера S', id: 5 },
    { name: 'NPK კომპლექსური', nameRu: 'Сложный', id: 6 },
    { name: 'მიკროელემენტები', nameRu: 'Микроэлементы', id: 7 },
    { name: 'სხვა', nameRu: 'Другой', id: 8 },
  ],
  /*productsStimulationSubcategories: [
    { name: 'Антистресс', id: 0 },
    { name: 'Стимуляция вегетации', id: 1 },
    { name: 'Корректор', id: 2 },
    { name: 'Клей', id: 3 },
    { name: 'Стимуляция плода', id: 4 },
    { name: 'Стимуляция цветения', id: 5 },
    { name: 'Стимуляция сахара', id: 6 },
    { name: 'Другой', id: 7 },
  ],*/
  productsStimulationSubcategories: [],
  productsPickingUnits: [
    {
      name: i18n.t('Vuex:products:productsPickingUnits:0'), //'Кг',
      id: 0,
    },
    {
      name: i18n.t('Vuex:products:productsPickingUnits:1'), //'Литры',
      id: 1,
    },
  ],
  productsCategoriesMapById: new Map(),
  productsPestsSubcategoriesMapById: new Map(),
  productsFertilizersSubcategoriesMapById: new Map(),
  productsStimulationSubcategoriesMapById: new Map(),

  editProductCache: null,
};

export const mutationTypes = {
  getProductsStart: '[products] getProducts start',
  getProductsSuccess: '[products] getProducts success',
  getProductsFailure: '[products] getProducts failure',
  setEditProductCache: '[products] setEditProductCache',
  resetEditProductCache: '[products] resetEditProductCache',
};
export const actionTypes = {
  getProducts: '[products] getProducts',
};
const mutations = {
  [mutationTypes.getProductsStart](state) {
    state.isProductsLoading = true;
    state.isProductsLoadingHasError = false;
    //state.products = null;
  },
  [mutationTypes.getProductsSuccess](state, payload) {
    state.isProductsLoading = false;
    if (payload) {
      state.products = sortProducts(payload.products);
      state.productsFertilizers = payload.productsFertilizers || [];
      state.productsTypesStimulation = payload.productsTypesStimulation || [];
      state.productsStimulationSubcategories = payload.typesStimulation || [];
      state.manufacturers = payload.manufacturers;
      state.manufacturersCountries = payload.manufacturersCountries;
      state.productsDateCache = new Date().getTime();
      processProducts(state);
    }
  },
  [mutationTypes.getProductsFailure](state) {
    state.isProductsLoading = false;
    state.isProductsLoadingHasError = true;
  },
  [mutationTypes.setEditProductCache](state, payload) {
    state.editProductCache = payload;
  },
  [mutationTypes.resetEditProductCache](state) {
    state.editProductCache = null;
  },
};

function sortProducts(products) {
  if (products instanceof Array) {
    products = products.sort((a, b) => {
      return b?.attention - a?.attention;
    });
  }
  return products;
}

function processProducts(state) {
  state.productsCategoriesMapById = new Map();
  state.productsCategories.forEach((val) => {
    state.productsCategoriesMapById.set(val.id, val.name);
  });
  state.productsPestsSubcategories.forEach((val) => {
    state.productsPestsSubcategoriesMapById.set(val.id, val.name);
  });
  state.productsFertilizersSubcategories.forEach((val) => {
    state.productsFertilizersSubcategoriesMapById.set(val.id, val.name);
  });
  state.productsStimulationSubcategoriesMapById = new Map();
  if (state.productsStimulationSubcategories) {
    state.productsStimulationSubcategories.forEach((val) => {
      state.productsStimulationSubcategoriesMapById.set(val.id, val.name);
    });
  }

  state.productsFertilizersSetMapByProductId = new Map();
  if (state.productsFertilizers) {
    state.productsFertilizers.forEach((val) => {
      if (!state.productsFertilizersSetMapByProductId.has(val.product_id)) {
        state.productsFertilizersSetMapByProductId.set(
          val.product_id,
          new Set()
        );
      }
      state.productsFertilizersSetMapByProductId
        .get(val.product_id)
        .add(val.fertilizer_subcategory_id);
    });
  }
  state.productsTypesStimulationSetMapByProductId = new Map();
  if (state.productsTypesStimulation) {
    state.productsTypesStimulation.forEach((val) => {
      if (
        !state.productsTypesStimulationSetMapByProductId.has(val.product_id)
      ) {
        state.productsTypesStimulationSetMapByProductId.set(
          val.product_id,
          new Set()
        );
      }
      state.productsTypesStimulationSetMapByProductId
        .get(val.product_id)
        .add(val.type_stimulation_id);
    });
  }
}
const actions = {
  async [actionTypes.getProducts](context, payload) {
    context.commit(mutationTypes.getProductsStart, payload);
    try {
      let response = await productsApi.getProducts(state.productsDateCache);
      if (response.status !== 304) {
        context.commit(mutationTypes.getProductsSuccess, response.data);
      }
    } catch (error) {
      console.log(error);
      context.commit(mutationTypes.getProductsFailure);
    }
  },
};
export default {
  state,
  mutations,
  actions,
};
