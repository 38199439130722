import * as articlesApi from '@/api/clientsArea/articles';
import i18n from '@/i18n';

//Translated

const state = {
  categoriesArticles: [
    {
      id: 0,
      name: i18n.t('UserHomePage:ArticlesCategories:Defence.Title'),
      translate: 'Защита растений',
    },
    {
      id: 1,
      name: i18n.t('UserHomePage:ArticlesCategories:Fertilizer.Title'),
      translate: 'Питание растений',
    },
    {
      id: 2,
      name: i18n.t('UserHomePage:ArticlesCategories:Irrigation.Title'),
      translate: 'Ирригационные системы',
    },
    {
      id: 3,
      name: i18n.t('UserHomePage:ArticlesCategories:More.Title'),
      translate: 'Современные технологии и другое',
    },
  ],
  articles: [],
  articlesMapById: new Map(),
  timestampArticles: 0,
  isArticlesLoading: false,
  isArticlesLoadingHasError: false,

  article: null,
  isArticleLoading: false,
  isArticleLoadingHasError: false,
};

const getters = {
  geoCategoriesArticlesMapById(state, getters) {
    return new Map(state.categoriesArticles.map((e) => [e.id, e]));
  },
  geoCategoriesArticlesMapByStringId(state, getters) {
    return new Map(state.categoriesArticles.map((e) => [String(e.id), e]));
  },
};

export const mutationTypes = {
  getArticlesStart: '[client articles] getArticles start',
  getArticlesSuccess: '[client articles] getArticles success',
  getArticlesFailure: '[client articles] getArticles failure',

  getArticleStart: '[client articles] getArticle start',
  getArticleSuccess: '[client articles] getArticle success',
  getArticleFailure: '[client articles] getArticle failure',
};
export const actionTypes = {
  getArticles: '[client articles] getArticles',
  getArticle: '[client articles] getArticle',
};

const mutations = {
  [mutationTypes.getArticlesStart](state) {
    state.isArticlesLoading = true;
    state.isArticlesLoadingHasError = false;
    //state.products = null;
  },
  [mutationTypes.getArticlesSuccess](state, payload) {
    state.isArticlesLoading = false;
    if (payload && payload?.articles) {
      state.articles = payload.articles || state.articles;
      state.timestampArticles = new Date().getTime();
      processArticles(state);
    }
  },
  [mutationTypes.getArticlesFailure](state) {
    state.isArticlesLoading = false;
    state.isArticlesLoadingHasError = true;
  },

  [mutationTypes.getArticleStart](state) {
    state.isArticleLoading = true;
    state.isArticleLoadingHasError = false;
    //state.products = null;
  },
  [mutationTypes.getArticleSuccess](state, payload) {
    state.isArticleLoading = false;
    //Проверка, что статья - текстовая
    if (payload && payload?.article && payload?.article?.type_id === 2) {
      state.article = payload.article ?? null;
    }
  },
  [mutationTypes.getArticleFailure](state) {
    state.isArticleLoading = false;
    state.isArticleLoadingHasError = true;
  },
};

function processArticles(state) {
  state.articlesMapById = new Map();
  state.articles.forEach((val) => {
    state.articlesMapById.set(val.id, val);
  });
}

const actions = {
  async [actionTypes.getArticles](context, payload) {
    context.commit(mutationTypes.getArticlesStart, payload);
    try {
      let response = await articlesApi.getArticles({
        articles: state.timestampArticles,
      });
      if (response.status !== 304) {
        context.commit(mutationTypes.getArticlesSuccess, response.data);
      }
    } catch (error) {
      console.log(error);
      context.commit(mutationTypes.getArticlesFailure);
    }
  },
  async [actionTypes.getArticle](context, payload) {
    context.commit(mutationTypes.getArticleStart, payload);
    try {
      let response = await articlesApi.getArticle(payload);
      if (response.status !== 304) {
        context.commit(mutationTypes.getArticleSuccess, response.data);
      }
    } catch (error) {
      console.log(error);
      context.commit(mutationTypes.getArticleFailure);
    }
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
