<!--Translated-->
<template>
  <!--УНИ ВЫЙТИ БЕЗ СОХРАНЕНИЯ-->
  <div class="modal">
    <div class="modalwrap2-topline">
      <div class="topline-minizone"></div>

      <Breadcrumps :data="[{ title: title }]" />

      <div class="topline-minizone">
        <Button
          text=""
          size="s-standart"
          color="accentlightlines"
          icon="x"
          :is-loading="false"
          :loading-left="true"
          @click="$emit('input', false)"
        />
      </div>
    </div>

    <div class="modalwrap2-content">
      <div class="modalwrap3-text margintop--standart marginbottom--standart">
        <p class="text-mini" v-html="message"></p>
      </div>

      <div class="modalwrap3-buttons">
        <Button
          :text="leftBtn.text"
          size="wide"
          :color="leftBtn.color"
          :icon="leftBtn.icon"
          :is-loading="false"
          :loading-left="true"
          @click="$emit('confirm', true)"
        />
        <Button
          :text="rightBtn.text"
          size="wide"
          :color="rightBtn.color"
          :icon="rightBtn.icon"
          :is-loading="false"
          :loading-left="true"
          @click="$emit('input', false)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/views/Button';
import Breadcrumps from '@/components/views/Breadcrumps';

export default {
  name: 'modalNotSaved',
  props: {
    leftButtonParams: {},
    rightButtonParams: {},
    message: {
      default() {
        return (
          this.$t('modalNotSaved:Message:Default:Line:1') +
          '<br />' +
          this.$t('modalNotSaved:Message:Default:Line:2')
        );
      },
    },
    title: {
      default() {
        return this.$t('modalNotSaved:Message:Default:Title');
      },
    },
  },
  data() {
    return {
      leftBtn: {
        text: this.$t('modalNotSaved:Message:Default:LeftButton'),
        icon: 'delete',
        color: 'alertlines',
        ...this.leftButtonParams,
      },
      rightBtn: {
        text: this.$t('modalNotSaved:Message:Default:RightButton'),
        icon: 'cancel',
        color: 'accentlightlines',
        ...this.rightButtonParams,
      },
    };
  },
  components: { Button, Breadcrumps },
};
</script>

<style scoped lang="scss"></style>
