<!--Translated-->
<template>
  <table class="table--type2 table--defense">
    <thead>
      <tr>
        <th class="tbl-cellproblem">
          <div class="tbl_info">
            <div class="tbl_info-wrap">
              <div v-svg="{ name: 'stimulator' }"></div>
              <div class="tbl_info-celltext">
                {{ $t('TemplateTypesStimulationsTable:Headers:0') }}
              </div>
            </div>
          </div>
        </th>
        <th class="tbl-cellproduct">
          {{ $t('TemplateTypesStimulationsTable:Headers:1') }}
        </th>
        <th class="tbl-cellinpt">
          {{ $t('TemplateTypesStimulationsTable:Headers:2') }}
        </th>
        <th class="tbl-cellprice">
          {{ $t('TemplateTypesStimulationsTable:Headers:3') }}
        </th>
      </tr>
    </thead>
    <template v-if="typesStimulations.length > 0">
      <TemplateRowStimulationType
        v-for="type of typesStimulations"
        :sType="type"
        :phase="phase"
        :key="type.id"
        :readonly="readonly"
      />
    </template>
    <tbody v-else>
      <tr class="row tbl_row--active row--message">
        <td class="tbl-cellproblem" colspan="4">
          <div>{{ $t('TemplateTypesStimulationsTable:NoTypes') }}</div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapState } from 'vuex';
import TemplateRowStimulationType from './TemplateRowStimulationType';

export default {
  name: 'TemplateTypesStimulationsTable',
  components: {
    TemplateRowStimulationType,
  },
  props: {
    phase: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      typesStimulations(state) {
        const findStimultaionType = ({ type_stimulation_id }) =>
          state.plantsCare.typesStimulation.find(
            ({ id }) => id === type_stimulation_id
          );

        return state.plantsCare.phasesTypesStimulation
          .filter(({ phase_id }) => phase_id === this.phase.id)
          .map(findStimultaionType)
          .filter(Boolean);
      },
    }),
  },
};
</script>
