<template>
  <div class="radiotogglewrap">
    <label class="lable"> Сделать по умолчанию </label>
    <fieldset class="inputradiotoggle">
      <template>
        <label class="radiotoggle">
          <CheckBox
            text="Шаблон по умолчанию"
            color-checked="accentfill"
            color-un-checked="gray"
          />
        </label>
      </template>
    </fieldset>
  </div>
</template>

<script>
import CheckBox from '@/components/CheckBox';
export default {
  name: 'CheckToggle',
  components: { CheckBox },
  props: {
    name: {
      type: String,
      default: 'name',
    },
    type: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    text1: {
      type: String,
      default: '',
    },
    text2: {
      type: String,
      default: '',
    },
    value: {
      default: 0,
    },
    active: {
      default: false,
    },
    disabled: {
      default: false,
    },
    reverse: {
      default: false,
    },
  },
  data() {
    return {
      activeD: this.active,
    };
  },
  methods: {
    onChange(el, val) {
      if (this.disabled) {
        return;
      }
      if (val) {
        this.$emit('input', el);
      }
    },
  },
  computed: {
    isTypeBlocked() {
      return this.type === 'blocked';
    },
    isTypeDisabled() {
      return this.type === 'disabled';
    },
    isActive() {
      return !!(this.activeD || this.active);
    },
  },
};
</script>
