import axios from '@/api/axios';

const getProducts = (payload) => {
  return axios.get('/products/getProducts', { params: { d: payload } });
};
const getNewProductData = (payload) => {
  return axios.get('/products/getNewProductData', {
    params: { pests: payload?.pests },
  });
};
const getEditProductData = (payload) => {
  return axios.get('/products/getEditProductData', {
    params: {
      id: payload?.id,
      code: payload?.code,
      forceUpdate: payload?.forceUpdate,
      pests: payload?.pests,
    },
  });
};
const updateProduct = (payload) => {
  return axios.post('/products/updateProduct', {
    productData: payload,
  });
};
const deleteProduct = (payload) => {
  return axios.post('/products/updateProduct', {
    productData: payload,
    delete: true,
  });
};

export default {
  getProducts,
  getNewProductData,
  getEditProductData,
  updateProduct,
  deleteProduct,
};
